import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";
import {Typography} from "@mui/material";
import React from "react";

 const Notabene = (props:any) => {
    return (
        <Typography variant="caption"  sx={{fontStyle: 'italic', m: 1, color:'#C62828'}} gutterBottom>
            <FormattedMessage id={messages.la_valeur}/> ({props.libelle}) {props.contrainte} !
        </Typography>
    );
}

export default Notabene;