import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material";
import MainMenu from "./MainMenu";

const drawerWidth = 240;
const useStyles = makeStyles((theme:any) => {
    return {
        user: {
            justifyContent: 'center',
        },
        drawer: {
            width: drawerWidth,
        },
        drawerWrap: {
            position: 'relative',
            border: 'none',
            background: 'none',
        },
        drawerPaper: {
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            border: 'none',
            background: 'none',
            color: theme.palette.text.primary,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        swipeDrawerPaper: {
            width: drawerWidth,
        },
        opened: {
            '& $primary, & $icon': {
                color: theme.palette.primary.main,
            },
        },
        drawerPaperClose: {
            color: alpha(theme.palette.text.secondary, 0.45),
            width: theme.spacing(9),
            position: 'absolute',
            overflowX: 'hidden',
            transition: theme.transitions.create(['width', 'background-color'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '& $user': {
                justifyContent: 'flex-start',
            },
            '& $bigAvatar': {
                width: 40,
                height: 40,
            },
            '& nav': {
                display: 'none',
            },
            '&:hover': {
                width: drawerWidth,
                marginRight:theme.spacing(2),
                backgroundColor: '#f7f7fa',
                '& nav': {
                    display: 'block',
                },
            },
            '& $brand': {
                display: 'none',
            },
            /*'& $profile': {
                flexDirection: 'row',
                top: theme.spacing(6),
                padding: theme.spacing(0.5),
                textAlign: 'left',
                '& button': {
                    width: 'auto',
                },
            },*/
            '& $avatar': {
                marginRight: theme.spacing(3),
            },
            '& $menuContainer': {
                '&$menuContainer': {
                    paddingTop: theme.spacing(10),
                    paddingBottom: 0,
                },
            },
        },
        drawerInner: {
            // Make the items inside not wrap when transitioning:
            height: '100%',
            position: 'relative',
            backgroundColor: '#f7f7fa',
        },
        drawerInnerOpen : {
            height: '100%',
            position: 'relative',
            width: drawerWidth,
            marginRight:theme.spacing(2),
            backgroundColor: '#f7f7fa',
        },
        drawerInnerMobile: {
            // Make the items inside not wrap when transitioning:
            height: '100%',
            backgroundColor:
                theme.palette.type === 'dark'
                    ? alpha(theme.palette.background.paper, 0.75)
                    : alpha(theme.palette.background.paper, 0.95),
        },
        drawerHeader: {
            zIndex: 1,
            position: 'relative',
            //minHeight: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
                //paddingTop: theme.spacing(8),
            },
        },
        avatar: {
            margin: '10px 12px',
        },
        bigAvatar: {
            width: 80,
            height: 80,
            boxShadow: theme.shadows[2],
        },
        brandBar: {
            transition: theme.transitions.create(['width', 'margin', 'background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '&:after': {
                transition: theme.transitions.create(['box-shadow'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        darker: {
            background: 'none',
        },
        nested: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            margin: `${theme.spacing(0.5)}px 0`,
            paddingLeft: theme.spacing(7),
        },
        child: {
            '& a': {
                paddingLeft: theme.spacing(8),
            },
        },
        title: {
            textTransform: 'uppercase',
            paddingLeft: theme.spacing(7),
            marginTop: theme.spacing(3),
            display: 'block',
            lineHeight: '28px',
        },
        dense: {
            padding: theme.spacing(),
            '& > $title:first-child': {
                margin: '0',
            },
            '& $head': {
                paddingLeft: theme.spacing(7),
                backgroundColor: 'red',
            },
        },
        active: {
            backgroundColor:
                theme.palette.type === 'dark' ? alpha(theme.palette.primary.main, 0.24) : theme.palette.primary.light,
            '& $primary': {
                //color: theme.palette.common.white,
                color: theme.palette.primary.main,
            },
            '& $icon svg': {
                fill: theme.palette.primary.main,
            },
            '&:hover, &:focus': {
                backgroundColor:
                    theme.palette.type === 'dark' ? alpha(theme.palette.primary.main, 0.24) : theme.palette.primary.light,
            },
        },
        nolist: {
            listStyle: 'none',
            '& .css-h4y409-MuiList-root': {
                paddingRight: 13,
            },
        },
        primary: {
            whiteSpace: 'nowrap',
        },
        icon: {
            minWidth: theme.spacing(5),
            '& .material-icons': {
                color: alpha(theme.palette.text.secondary, 0.40),
            },
        },
        iconed: {
            '&$opened': {
                '& .material-icons': {
                    color: theme.palette.primary.main,
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: 5,
                    height: theme.spacing(6),
                    top: 0,
                    left: 0,
                    background: alpha(theme.palette.primary.main, 0.5),
                },
            },
        },
        /* TEXT ICON MENU ET ICON MENU LEFTSIDEBAR */
        head: {
            padding: `${theme.spacing(1)}px 0`,
            margin: `${theme.spacing(1)}px 0`,
            borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
            paddingLeft: theme.spacing(3),
            '&$iconed': {
                paddingLeft: theme.spacing(3),
                color: alpha(theme.palette.text.secondary, 0.50),
            },
            '& svg[class^="MuiSvgIcon"]': {
                left: -10,
                position: 'relative',
                marginTop: 2,
                color: alpha(theme.palette.text.secondary, 0.50),
            },
        },
        headCapital: {
            padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(9)}px`,
            left: theme.spacing(1) * -2,
            position: 'relative',
            textTransform: 'uppercase',
            borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
            margin: `${theme.spacing(1)}px`,
            '& span': {
                fontSize: 14,
            },
        },
        copyright: {
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            padding: theme.spacing(2),
            position: 'fixed',
            [theme.breakpoints.up('lg')]: {
                background: 'none',
                position: 'absolute',
            },
            left: theme.spacing(3),
            lineHeight: '24px',
        },
        brand: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 10px 5px',
            height: 64,
            position: 'relative',
            textDecoration: 'none',
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            color: theme.palette.text.primary,
            '& img': {
                width: 30,
                marginRight: 10,
            },
        },
        /*brandBig: {
            paddingTop: theme.spacing(4),
            position: 'relative',
            textAlign: 'center',
            '& img': {
                width: 68,
            },
            '& h3': {
                fontSize: 18,
                marginTop: theme.spacing(2),
                fontWeight: 500,
                color: theme.palette.text.primary,
            },
        },*/
        /*profile: {
            height: 120,
            width: '100%',
            display: 'flex',
            fontSize: 14,
            padding: 10,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            position: 'absolute',
            margin: `${theme.spacing(2)}px 0`,
            zIndex: 0,
            '& h4': {
                fontSize: 18,
                marginBottom: 0,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 110,
            },
            '& button': {
                fontSize: 12,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: 110,
                display: 'block',
                overflow: 'hidden',
                textTransform: 'capitalize',
                padding: 0,
                minHeight: 20,
                marginTop: 4,
            },
        },*/
        statusMenu: {
            '& li': {
                width: 100,
            },
        },
        dotStatus: {
            width: theme.spacing(1),
            height: theme.spacing(1),
            display: 'inline-block',
            borderRadius: '50%',
            marginRight: theme.spacing(0.5),
        },
        rounded: {},
        landingNav: {},
        withProfile: {},
        menuContainer: {
            overflow: 'auto',
            height: 'calc(100% - 64px)',
            width: drawerWidth,
            position: 'fixed',
            display: 'block',
            padding: `${theme.spacing(5)}px 0`,
            '&$withProfile': {
                paddingTop: theme.spacing(10),
            },
            '&$landingNav': {
                [theme.breakpoints.up('lg')]: {
                    paddingTop: theme.spacing(5),
                },
                [theme.breakpoints.down('lg')]: {
                    height: 'calc(100% - 80px)',
                    paddingTop: theme.spacing(2),
                },
            },
            '&$rounded': {
                paddingRight: theme.spacing(1.5),
                '& a': {
                    borderRadius: theme.spacing(),
                    marginLeft: 5,
                    color: alpha(theme.palette.text.secondary, 0.50),
                },
                '& $opened': {
                    '&:before': {
                        background: theme.palette.primary.main,
                    },
                },
            },
            '&::-webkit-scrollbar': {
                width: 6,
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            '&:hover': {
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    border: '1px solid rgba(255,255,255,0.4)',
                },
            },
        },
        divider: {
            marginTop: theme.spacing(1),
        },
    }
});

function SidebarContent(props:any) {
    const classes = useStyles();
    const {
        drawerPaper,
        theme,
        setPageLoaded,
    } = props;

    return (
        <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : classes.drawerInnerOpen)}>
            <div
                id="sidebar"
                className={classNames(classes.menuContainer, classes.rounded, classes.withProfile)}
            >
                <MainMenu
                    theme={theme}
                    classes={classes}
                    setPageLoaded={setPageLoaded}
                />
            </div>
        </div>
    );
}

export default SidebarContent;
