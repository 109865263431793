import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from "@mui/material/Alert";
import {isBlank, isNotBlank} from "../../../helpers/helpers";

export const withSnackbar = (WrappedComponent:any) => {
    return (props:any) => {
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("I'm a custom snackbar");
        const [duration, setDuration] = useState(3000);
        const [severity, setSeverity] = useState<any>('success');

        const showMessage = (message:string, severity = 'success', duration = 3000) => {
            setMessage(message);
            setSeverity(severity);
            setDuration(duration);
            setOpen(isNotBlank(message));
        };

        const handleClose = (event:any, reason:any) => {
            if (reason === "clickaway") {
                return;
            }
            setOpen(false);
        };

        return (
            <>
                <WrappedComponent {...props} snackbarShowMessage={showMessage} />
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    autoHideDuration={duration}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Slide}
                    sx={{marginTop:6}}
                >
                    <Alert variant="filled" onClick={() => handleClose}  severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </>
        );
    };
};
