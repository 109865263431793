import React, {useEffect, useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export function BarSimple(props:any) {
    const {data, legend} = props;


    useEffect(() => {

    }, []);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <div>
                <BarChart
                    width={490}
                    height={390}
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={'ndn'} fill="#8884d8" name={legend.ndn} />
                    <Bar dataKey={'dn'} fill="#82ca9d" name={legend.dn} />
                </BarChart>
            </div>
        </ResponsiveContainer>

    )
}
