import _ from "lodash";
import * as yup from "yup";

export const SCOLARITEVERSEMENTECHEANCIERENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteVersementEcheancierEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteVersementEcheancierEnfantDataImpl);
        item = {...itemInit, ...item} ;

        // @ts-ignore
        item.montantForm            = new Intl.NumberFormat("fr-FR").format(item.montant ?? 0) ;
        item.montantFactureForm     = new Intl.NumberFormat("fr-FR").format(item.montantFacture ?? 0) ;
        item.montantPayerForm       = new Intl.NumberFormat("fr-FR").format(item.montantPayer ?? 0) ;
        item.montantSoldeForm       = new Intl.NumberFormat("fr-FR").format(item.montantSolde ?? 0) ;
        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteVersementEcheancierEnfant) => item,
};
export  const scolariteVersementEcheancierEnfantDataImpl: ScolariteVersementEcheancierEnfant = {
    id: null,
    scolariteVersementId: null,
    scolariteEcheancierEnfantId: 0,
    nom: ``,
    code:``,
    montantForm:'',
    montantFactureForm:'',
    montantPayerForm:'',
    montantSoldeForm:'',
    laDateEcheance: 0,
    montant: 0,
    montantSolde: 0,
    montantFacture: 0,
    montantPayer: 0,
    disabled:true,
}

export const scolariteVersementEcheancierEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    scolariteVersementId: yup.number().nullable(),
    scolariteEcheancierEnfantId: yup.number().nullable(),
    nom: yup.string(),
    code: yup.string().nullable(),
    laDateEcheance: yup.number().nonNullable().default(0).notRequired(),
    montant: yup.number().nonNullable().default(0).notRequired(),
    montantFacture: yup.number().nonNullable().default(0).notRequired(),
    montantSolde: yup.number().nonNullable().default(0).notRequired(),
    montantPayer: yup.number().nonNullable().default(0).notRequired(),
    montantForm:  yup.mixed<any>().notRequired(),
    montantFactureForm:  yup.mixed<any>().notRequired(),
    montantPayerForm:  yup.mixed<any>().notRequired(),
    montantSoldeForm:  yup.mixed<any>().notRequired(),
    disabled:  yup.boolean().default(true),
});

export type ScolariteVersementEcheancierEnfant = yup.InferType<typeof scolariteVersementEcheancierEnfantSchema>;
