import React from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import usePhotoVariables from "./businesslogic/AlbumPhotoVariables";
import {albumPictureDataImpl, albumPictureSchema} from "../../constants/type/AlbumPicture";
import albumphotoStyles from "./jss/albumphoto-jss";
import albumPhotoBusinessLogic from "./businesslogic/AlbumPhotoBusinessLogic";
import AlbumPhotoList from "./AlbumPhotoList";
import AlbumPhotoEditForm from "./AlbumPhotoEditForm";
import AlbumPhotoDeleteForm from "./AlbumPhotoDeleteForm";

const useBusinessLogic = (props: any): any => {
    const styleClasses = albumphotoStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(albumPictureDataImpl),
        // @ts-ignore
        resolver: yupResolver(albumPictureSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...usePhotoVariables({styleClasses, ...props})
    }
};

export const AlbumPhotoContext = React.createContext({});

function AlbumPhoto(props:any){
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();

    React.useEffect(() => {
        moment.locale(logic.activeLang);
        albumPhotoBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);
    return (
        <div className={logic.styleClasses.root}>

            <AlbumPhotoContext.Provider value={logic}>
                <AlbumPhotoList />
            </AlbumPhotoContext.Provider>

            <AlbumPhotoContext.Provider value={logic}>
                <AlbumPhotoEditForm />
            </AlbumPhotoContext.Provider>
            <AlbumPhotoContext.Provider value={logic}>
                <AlbumPhotoDeleteForm />
            </AlbumPhotoContext.Provider>

        </div>
    );
}
export default withSnackbar(AlbumPhoto);