import React from "react";
import {DocumentScolaire, documentScolaireDataImpl} from "../../../constants/type/DocumentScolaire";
import _ from "lodash";
import {BASEURL} from "../../../config/AppUrl";
import {useDispatch, useSelector} from "react-redux";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import {
    DocumentScolaireFichier,
    documentScolaireFichierDataImpl
} from "../../../constants/type/DocumentScolaireFichier";
import {Fonction} from "../../../constants/type/Fonction";
import {Employee} from "../../../constants/type/Employee";
import {Profils} from "../../../constants/type/Profils";

const useDocumentVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const [openFolderEditDialogForm, setOpenFolderEditDialogForm] = React.useState<boolean>(false);
    const [openFolderDeleteDialogForm, setOpenFolderDeleteDialogForm] = React.useState<boolean>(false);
    const [openFileDeleteDialogForm, setOpenFileDeleteDialogForm] = React.useState<boolean>(false);
    const [processingFolderStateNormBtn, setProcessingFolderStateNormBtn] = React.useState<boolean>(false);
    const [numeriChange, setNumeriChange] = React.useState<number>(0);

    const profilsRef : React.MutableRefObject<Profils[]> = React.useRef<Profils[]>([]);
    const jobsRef : React.MutableRefObject<Map<number,Fonction>> = React.useRef<Map<number,Fonction>>(new Map());
    const employeesRef : React.MutableRefObject<Employee[]> = React.useRef<Employee[]>([]);
    const employeesByFonctionRef : React.MutableRefObject<Employee[]> = React.useRef<Employee[]>([]);
    const teachersListRef : React.MutableRefObject<Employee[]> = React.useRef<Employee[]>([]);
    const isTeacherRef = React.useRef<boolean>(false);
    const classeIdRef = React.useRef<number>(0);


    const [docFolders, setDocFolders] = React.useState<DocumentScolaire[]>([]);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [showFiles, setShowFiles] = React.useState<boolean>(false);
    const [docFoldersSelected, setDocFoldersSelected] = React.useState<DocumentScolaire[]>([]);
    const [docFolderSel, setDocFolderSel] = React.useState<DocumentScolaire>(_.cloneDeep(documentScolaireDataImpl));
    const [docFileSel, setDocFileSel] = React.useState<DocumentScolaireFichier>(_.cloneDeep(documentScolaireFichierDataImpl));
    const newFolderRef = React.useRef<boolean>(true);
    const classeRoomRef  = React.useRef<ClasseRoom[]>([]);
    const menuItemSelectsRef = React.useRef<any[]>([]);
    const folderPictureRef  = React.useRef<string>(BASEURL+'/images/folder.png');
    const documentPictureRef  = React.useRef<string>(BASEURL+'/images/document.png');
    const backPictureRef = React.useRef<DocumentScolaireFichier>(_.cloneDeep(documentScolaireFichierDataImpl));
    backPictureRef.current.iconForm =BASEURL+'/images/goback.png';
    // @ts-ignore
    backPictureRef.current.nom = activeLang === 'en' ? `Go back to the folders` : `Retour aux dossiers`;


    const filePictureMapRef = React.useRef<Map<string, string>>(new Map([
        ['doc', BASEURL+'/images/doc.png'],
        ['docx', BASEURL+'/images/docx.png'],
        ['jpg', BASEURL+'/images/jpg.png'],
        ['pdf', BASEURL+'/images/pdf.png'],
        ['png', BASEURL+'/images/png.png'],
        ['ppt', BASEURL+'/images/ppt.png'],
        ['pptx', BASEURL+'/images/pptx.png'],
        ['xls', BASEURL+'/images/xls.png'],
        ['xlsx', BASEURL+'/images/xlsx.png']
    ]));
    const destinatairesMapRef = React.useRef<Map<string, string>>(new Map([]));

    return {
        snackbarShowMessage,theme,openFolderDeleteDialogForm,setOpenFolderDeleteDialogForm,openFileDeleteDialogForm,setOpenFileDeleteDialogForm,
        docFolders,setDocFolders,docFoldersSelected,setDocFoldersSelected,docFolderSel,setDocFolderSel,
        folderPictureRef,documentPictureRef,filePictureMapRef,userRef,activeLang,styleClasses,
        classeRoomRef,destinatairesMapRef,showFiles,setShowFiles,openFolderEditDialogForm,setOpenFolderEditDialogForm,
        processingFolderStateNormBtn,setProcessingFolderStateNormBtn,newFolderRef,menuItemSelectsRef,docFileSel,setDocFileSel,
        backPictureRef,jobsRef,employeesRef,employeesByFonctionRef,teachersListRef,isTeacherRef,profilsRef,classeIdRef,
        showContent,setShowContent,dataCaches,  numeriChange, setNumeriChange, dispatch
    }
}
export default useDocumentVariables;