import _ from "lodash";
import moment from "moment/moment";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";

export const MINICLUB_CONSTANT = {
    convertToFrontEnd: (logic:any, item:MiniClub) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(miniClubDataImpl);
        item = {...itemInit, ...item} ;

        item.dateDebutForm    = moment(item.dateDebut);
        item.dateFinForm    = moment(item.dateFin);
        return item ;
    },
    convertToBackEnd: (logic:any, item:MiniClub) => item,
};

export  const miniClubDataImpl: MiniClub = {
    id: null,
    title: '',
    dateDebut: 0,
    dateFin: 0,
    placeLimit:0,
    details: '',
    uriPublicite: '',
    prix: 0,
    classeMiniClubs: [],
    photoMiniClubs: [],
    inscritMiniClubs: [],
    common:  _.cloneDeep(commonDataImp),
    dateDebutForm: moment(),
    dateFinForm: moment(),
}

export const miniClubSchema = yup.object().shape({
    id: yup.number().nullable(),
    title: yup.string(),
    dateDebut: yup.number(),
    dateFin: yup.number(),
    placeLimit: yup.number(),
    details: yup.string(),
    uriPublicite: yup.string(),
    prix: yup.number(),
    classeMiniClubs: yup.array().of(yup.mixed<any>()).nonNullable(),
    photoMiniClubs: yup.array().of(yup.mixed<any>()).nonNullable(),
    inscritMiniClubs: yup.array().of(yup.mixed<any>()).nonNullable(),
    common:  yup.mixed<Common>(),
    dateDebutForm:  yup.mixed<moment.Moment>().default(moment()),
    dateFinForm:  yup.mixed<moment.Moment>().default(moment()),
});
export type MiniClub = yup.InferType<typeof miniClubSchema>;