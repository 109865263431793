import {Child} from "../../../constants/type/Child";
import {AlbumPicture} from "../../../constants/type/AlbumPicture";
import {Employee} from "../../../constants/type/Employee";
import _ from "lodash";

const albumPhotoStateUpdate:any = {
    teachersListRef: (logic:any, val: Employee[]) => logic.teachersListRef.current   = val,
    teacherRef: (logic:any, val: Employee | null | undefined) => logic.teacherRef.current   = val,
    isTeacherRef: (logic:any, val: boolean) => logic.isTeacherRef.current   = val,
    classeIdRef: (logic:any, val: number) => logic.classeIdRef.current   = val,
    childSel: (logic:any, val:Child) => logic.setChildSel(val),
    childrenList: (logic:any, val:Child[]) => {
        if(logic.isTeacherRef.current){
            const classeRoomId = logic.classeIdRef.current;
            val = _.filter(val, item => item.eleves[0]?.classe?.id === classeRoomId)
        }
        logic.setChildrenList(val);
    },
    photoList: (logic:any, val:AlbumPicture[]) => logic.setPhotoList(val),
    photosIdsSelected: (logic:any, val:number[]) => logic.setPhotosIdsSelected(val),
    photosSelected: (logic:any, val:AlbumPicture[]) => logic.setPhotosSelected(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    showPhotoContent: (logic:any, val:boolean) => logic.setShowTableContent(val),
    openFileDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenFileDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    showForm: (logic:any, val:boolean) => logic.setShowForm(val),

}
export default albumPhotoStateUpdate ;