import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@mui/styles";
import styles from "../config-table-jss";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import DishDialogForm from "../../canteen/DishDialogForm";
import {CONSTANT, DISH_TYPE} from "../../../constants";
import {useForm} from "react-hook-form";
import {request, uploadFileRequest} from "../../../helpers/axios_helpers";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../helpers/errorManager";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import _ from "lodash";

const useStyles = makeStyles(styles);
type dishListType = {
    id: number;
    nom:string,
    typePlat: string,
};

type dishFormValues = {
    id: number;
    typePlat: string,
    nom: string,
}

function Dish(props:any){
    const { theme, locale, snackbarShowMessage } = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const [dishData, setDishData] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [dish, setDish] = useState<any>('');
    const [stateDish, setStateDish] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'Dish',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<dishListType>(mytableState), []);


    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleDishTypeChange = (event:any) => {
        setDish(event.target.value)
    };

    const form = useForm<dishFormValues>({
        defaultValues: {
            id: 0,
            typePlat: '',
            nom: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const addDish = () => {
        setStateDish(true);
        setDish('');
        setValue('id', 0);
        setValue('typePlat', '');
        setValue('nom', '');

        handleClickOpen();
    };

    const editDish = (row:any) => {
        setStateDish(false);
        const dishFind: any = dishData[row.index];

        DISH_TYPE.map((dishType:any) => {
            if(dishType.key === dishFind.typePlat) {
                setDish(dishType);
            }
        });
        setValue('id', dishFind.id);
        setValue('typePlat', dishFind.typePlat);
        setValue('nom', dishFind.nom);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        uploadFileRequest(method, url, dataToSend).then((response) => {
            if(stateDish) {
                setDishData([response.data, ...dishData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                dishData.find((dishFind:any, index:any) => {
                    if(dishFind.id === data.id) {
                        dishData[index].nom = data.nom;
                        dishData[index].typePlat = data.typePlat;
                        setDishData([...dishData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: dishFormValues) => {
        let formData:any = new FormData();
        formData.append("photo", null);

        if(dish !== ''){
            const dataToSend = {
                typePlat: dish.key,
                nom: data.nom,
                details:'',
                photo:'',
                common: CONSTANT.common
            }

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));
            sendData(data, 'POST', "/extra/platcantine", formData);
        }

    }

    const onSubmitEditForm = (data: dishFormValues) => {
        let formData:any = new FormData();
        formData.append("photo", null);

        if(dish !== '') {
            const dishFind: any = dishData.find((dish:any) => dish.id === data.id);
            dishFind.nom = data.nom;
            dishFind.typePlat = dish.key;
            data.typePlat = dish.key;

            formData.append('model', new Blob([JSON.stringify(dishFind)], {
                type: "application/json"
            }));
            sendData(data, 'POST', "/extra/platcantine", formData);
        }
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataDishSelectedId:any = [];
        rows.map((row:any) => {
            const data = dishData[row.index];
            dataSelected.push(data);
            dataDishSelectedId.push(data.id);
        });

        request('DELETE', '/extra/platcantine/bouquet', dataDishSelectedId).then((response) => {
            const fileList = dishData.filter((dish:any) => {
                if(!dataSelected.includes(dish)){
                    return dish;
                }
            });
            setDishData([...fileList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }




    const columns = useMemo<MRT_ColumnDef<dishListType>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: intl.formatMessage({id: messages.title_dish_config}),
                size: 700,
            },
            {
                accessorKey: 'typePlat',
                header: intl.formatMessage({id: messages.dish_type_config}),
            },
        ],
        [activeLang],
    );
    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization: localization,
        columns,
        data: dishData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addDish}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editDish(row)}
            />
        )
    });

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", "/extra/platcantine", {}).then((response) => {
            let dishListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.platCantineDTOModelList : [];
            dishListRequest = dishListRequest.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });
            setDishData([...dishListRequest]);
            setTableDataLoading(false);

        }).catch((error) => {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            // Error message
            setTableDataLoading(false);
        })
    }, []);

    useEffect(() => {
        return () => logicMRT.storeState(tableConfig.getState());
    }, []);

    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />

            <DishDialogForm
                title={ stateDish ? <FormattedMessage id={messages.dialog_title_dish_config} />: <FormattedMessage id={messages.dialog_title_edit_dish} /> }
                theme={theme}
                locale={locale}
                open={open}
                setOpen={setOpen}
                dish={dish}
                handleDishTypeChange={handleDishTypeChange}
                register={register}
                onSubmit={stateDish ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />

        </div>
    )
}

export default withSnackbar(Dish)
