import {processingEmployees, processingJobs} from "../../../helpers/loader_helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../constants/type/ClasseRoom";
import {request, uploadFileRequest} from "../../../helpers/axios_helpers";
import _ from "lodash";
import {RoleUser} from "../../../constants/type/RoleUser";
import {
    DocumentScolaire,
    DOCUMENTSCOLAIRE_CONSTANT,
    documentScolaireDataImpl
} from "../../../constants/type/DocumentScolaire";
import {FormattedMessage} from "react-intl";
import React from "react";
import schoolDocStateUpdate from "./SchoolDocumentStateUpdate";
import {MenuItem} from "@mui/material";
import {
    DocumentScolaireFichier,
    DOCUMENTSCOLAIREFICHIER_CONSTANT
} from "../../../constants/type/DocumentScolaireFichier";
import {checkTeacherUser, isPositiveValue} from "../../../helpers/helpers";
import {Profils, PROFILS_CONSTANT} from "../../../constants/type/Profils";
import {Fonction} from "../../../constants/type/Fonction";
import {Employee} from "../../../constants/type/Employee";
import rdvStateUpdate from "../../appointment/businesslogic/RdvStateUpdate";
import {getErrorMessage} from "../../../helpers/errorManager";
import {mixLoad} from "../../../helpers/loader_cache_helpers";

const schoolDocumentBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['classes', 'profils', 'fonctions', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logic = processingEmployees(logic, itememployees);

            const teachers: Employee[] = [];
            logic.employeesByFonctionRef.current.forEach((e:Employee) => {
                if(_.isEmpty(e.teacherClasses)){
                    return ;
                }
                teachers.push(e);
            });
            logic.classeRoomRef.current = _.filter(_.cloneDeep(logic.classeRoomRef.current), (item) => {
                return _.findIndex(teachers, (emp) => {
                    return emp?.teacherClasses[0]?.classeId === item.id;
                })  >= 0;
            });
            rdvStateUpdate.teachersListRef(logic, teachers);
            const isTeacher = checkTeacherUser(logic, rdvStateUpdate);

            //const teachername = logic.activeLanguageRef.current === 'en' ? `Teacher` : `Educatrice`;
            logic.menuItemSelectsRef.current = [];
            logic.destinatairesMapRef.current.set("ADMIN_0", "Admin");
            logic.menuItemSelectsRef.current.push(<MenuItem key="ADMIN_0" value="ADMIN_0" sx={{fontWeight:400}}>Admin</MenuItem>);  ;

            if(isTeacher){
                logic.classeRoomRef.current.forEach((value:ClasseRoom) => {
                    if(logic.classeIdRef.current === value.id){
                        const cle = `CLASSE_${value.id}`;
                        logic.destinatairesMapRef.current.set(cle, value.nom);
                        logic.menuItemSelectsRef.current.push(<MenuItem key={cle} value={cle} sx={{fontWeight:400}}>{value.nom}</MenuItem>);
                    }
                });
            }else {
                logic.classeRoomRef.current.forEach((value:ClasseRoom) => {
                    const cle = `CLASSE_${value.id}`;
                    logic.destinatairesMapRef.current.set(cle, value.nom);
                    logic.menuItemSelectsRef.current.push(<MenuItem key={cle} value={cle} sx={{fontWeight:400}}>{value.nom}</MenuItem>);
                });
            }

        };

        schoolDocStateUpdate.showContent(logic, false);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            schoolDocumentBusinessLogic.loadDocuments(logic).then(() => {});
        }).catch(e => {
            schoolDocStateUpdate.showContent(logic, true);
        });
        /*loadClasseRooms(logic).then(value => {
           
            schoolDocumentBusinessLogic.loadDocuments(logic).then(() => {});
        });*/
    },
    envoieFichier: (logic:any, docId:number|null, files:any[], setUploadingMsg:any) => {
        if(_.isNull(docId)){
            return ;
        }

        let formData:any = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('docs', files[i]);
        }

        setUploadingMsg(true);
        return new Promise<boolean>(resolve =>
            uploadFileRequest('PUT',  `/extra/documents/${docId}/fichiers`, formData).then((response) => {
                const dataSaved = DOCUMENTSCOLAIRE_CONSTANT.convertToFrontEnd(logic, response.data);

                schoolDocStateUpdate.updateFolderToList(logic, dataSaved);
                schoolDocStateUpdate.docFolderSel(logic, dataSaved);
                resolve(true);
                setUploadingMsg(false);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
                setUploadingMsg(false);
            })
        );
    },
    saveFolderEdit: async (logic:any, messages:any, toSend:DocumentScolaire):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(toSend.id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            request(method, `/extra/documents${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = DOCUMENTSCOLAIRE_CONSTANT.convertToFrontEnd(logic, response.data);

                schoolDocStateUpdate.docFolderSel(logic, _.cloneDeep(dataSaved));

                if(method === 'POST'){
                    schoolDocStateUpdate.addFolderToList(logic, dataSaved);
                    schoolDocStateUpdate.goInDocFolderSel(logic, dataSaved);
                }else {
                    schoolDocStateUpdate.updateFolderToList(logic, dataSaved);
                }

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    renameFile: async (logic:any, messages:any, toSend:DocumentScolaireFichier):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if(_.isNumber(toSend.id) === false){
                return ;
            }
            let params = `/${toSend.documentScolaireId}/fichier/${toSend.id}/rename`;


            request('PUT', `/extra/documents${params}`, toSend).then((response) => {
                const dataSaved = DOCUMENTSCOLAIREFICHIER_CONSTANT.convertToFrontEnd(logic, logic.docFolderSel, response.data);

                schoolDocStateUpdate.docFileSel(logic, _.cloneDeep(dataSaved));
                schoolDocStateUpdate.updateFileToList(logic, dataSaved);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    renameFolder: async (logic:any, messages:any, toSend:DocumentScolaire):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if(_.isNumber(toSend.id) === false){
                return ;
            }
            let params = `/${toSend.id}/rename`;


            request('PUT', `/extra/documents${params}`, toSend).then((response) => {
                const dataSaved = DOCUMENTSCOLAIRE_CONSTANT.convertToFrontEnd(logic, response.data);

                schoolDocStateUpdate.docFolderSel(logic, _.cloneDeep(dataSaved));
                schoolDocStateUpdate.updateFolderToList(logic, dataSaved);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteFolder: async (logic:any, messages:any, toSend:DocumentScolaire):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if(isPositiveValue(toSend.id) === false){
                return ;
            }
            let params = `/${toSend.id}`;


            request('DELETE', `/extra/documents${params}`, toSend).then((response) => {
                // @ts-ignore
                schoolDocStateUpdate.docFolderSel(logic, _.cloneDeep(documentScolaireDataImpl));

                schoolDocumentBusinessLogic.loadDocuments(logic).then(() => {});

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteFile: async (logic:any, messages:any, docFolderId:number|null, filesIds:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if(_.isNumber(docFolderId) === false){
                return ;
            }
            let params = `/${docFolderId}/fichiers`;


            request('DELETE', `/extra/documents${params}`, filesIds).then((response) => {
                const folderUpdated = DOCUMENTSCOLAIRE_CONSTANT.convertToFrontEnd(logic, response.data);
                schoolDocStateUpdate.goInDocFolderSel(logic, folderUpdated);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    handleAddFolder: (logic:any) => {
        schoolDocStateUpdate.docFolderSel(logic, _.cloneDeep(documentScolaireDataImpl));
        schoolDocStateUpdate.openFolderEditDialogForm(logic, true);
    },
    handleDeleteFolder: (logic:any) => {
        if(_.isNull(logic.docFolderSel.id)){
            return ;
        }
        schoolDocStateUpdate.openFolderDeleteDialogForm(logic, true);
    },
    handleUpdateFolder: (logic:any) => {
        if(_.isNull(logic.docFolderSel.id)){
            return ;
        }
        schoolDocStateUpdate.docFolderSel(logic, logic.docFolderSel);
        schoolDocStateUpdate.openFolderEditDialogForm(logic, true);
    },
    handleDeleteFile: (logic:any) => {
        if(_.isNull(logic.docFileSel.id)){
            return ;
        }
        schoolDocStateUpdate.openFileDeleteDialogForm(logic, true);
    },
    loadDocuments: (logic: any):any => {
        schoolDocStateUpdate.showContent(logic, false);
        let path = `/${logic.userRef.current.id}`;
        const classeId = logic.userRef.current.userDetails.personDetails.teacherClasses[0]?.classeId ?? 0;
        const role     = logic.userRef.current.role ;
        if(classeId > 0){
            path    = `/teacher/${classeId}/${logic.userRef.current.id}`;
        } else if(role !== RoleUser.ADMIN && role !== RoleUser.SUPER_ADMIN ){
            path    = `/employee/${logic.userRef.current.id}`;
        }
        return new Promise<boolean>(resolve =>
            request("GET", `/extra/documents${path}`, {}).then((response) => {
                const items: DocumentScolaire[] = response.data._embedded?.documentScolaireDTOModelList ?? [];
                const datas = _.map(items, item => DOCUMENTSCOLAIRE_CONSTANT.convertToFrontEnd(logic, item));
                schoolDocStateUpdate.docFolders(logic, _.sortBy(datas, ['nom'])) ;
                schoolDocStateUpdate.showContent(logic, true);
                resolve(true);
            }).catch((error) => {
                schoolDocStateUpdate.showContent(logic, true);
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    }
}

export default schoolDocumentBusinessLogic ;