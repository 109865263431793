export enum DataEtat {
    OK = 'OK',
    ADDW = 'ADDW',
    ADD = 'ADD',
    UPW = 'UPW',
    UP = 'UP',
    DELW = 'DELW',
    DEL = 'DEL',
    READ = 'READ',
    CLOS = 'CLOS',
}