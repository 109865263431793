import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import classNames from "classnames";
import {TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import DialogForm from "../../../components/ui/form/DialogForm";

function SchoolPeriodForm(props:any) {
    const {
        theme,
        locale,
        title,
        activeLanguage,
        open,
        setOpen,
        onSubmit,
        classes,
        register,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange,
        schoolYear
    } = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    id="year-period"
                    label={<FormattedMessage id={messages.year_period}/>}
                    size="small"
                    color={"secondary"}
                    value={schoolYear.nom}
                    disabled
                    //error={!!errors.period?.message}
                    //helperText={errors.username?.message}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    id="title-period"
                    size="small"
                    color={"secondary"}
                    label={<FormattedMessage id={messages.title_school_period}/>}
                    {...register("period", {
                        required: "Username is required"
                    })}
                    //error={!!errors.period?.message}
                    //helperText={errors.username?.message}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                    <DatePicker
                        label={<FormattedMessage id={messages.start_day} />}
                        value={startDate}
                        onChange={handleStartDateChange}
                        slotProps={{ textField: { color: 'secondary' } }}
                    />

                </LocalizationProvider>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                    {/*<Controller
                                control={control}
                                name='startPeriod'
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <DatePicker
                                        label={<FormattedMessage id={messages.end_day} />}
                                        value={endDate}
                                        minDate={startDate}
                                        onChange={handleEndDateChange}
                                        //onChange={(date:any) => field.onChange(date)}
                                        //value={field}
                                    />
                                )}
                            />*/}
                    <DatePicker
                        label={<FormattedMessage id={messages.end_day} />}
                        value={endDate}
                        minDate={startDate}
                        onChange={handleEndDateChange}
                        slotProps={{ textField: { color: 'secondary' } }}

                    />
                </LocalizationProvider>
            </div>


        </DialogForm>
    );
}

export default SchoolPeriodForm;
