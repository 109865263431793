import {CreneauRdv} from "../../../constants/type/CreneauRdv";
import {CreneauRdvEmployee} from "../../../constants/type/CreneauRdvEmployees";
import {LectureRdv, Rdv} from "../../../constants/type/Rdv";
import {CreneauRdvEnfantParent} from "../../../constants/type/CreneauRdvEnfantParent";
import {RdvType} from "../../../constants/type/RdvType";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import _ from "lodash";
import InputField from "../../../components/ui/form/InputField";
import rdvPresetBusinessLogic from "./RdvPresetBusinessLogic";


const EmployeeRapportConfirmRdvToggle= (props:any):any =>{
    const handleEmployeeCommentChange = (event:any) => rdvPresetBusinessLogic.handleEmployeeCommentChange(props.logicRacine, event.target.value);
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvPresetLectureBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine, event, value);

    return <>
        <div className={props.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.comment} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                placeholder={props.logicRacine.activeLanguageRef.current[0] === 'en' ? 'Comment' : 'Commentaire'}
                onChange={handleEmployeeCommentChange}
                value={props.logicRacine.creneauRdvEmployeePreset.commentaire}
            />
        </div>
        <div className={props.styleClasses.inputField}>
            <ToggleButtonGroup
                color="primary"
                value={props.logicRacine.creneauRdvEmployeePreset.meetingStatus}
                exclusive
                onChange={handleEmployeeMeetingStatusChange}
                aria-label="Platform"
            >
                <ToggleButton value={RdvEtat.DONE}>
                    <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.DONE)}`]} />
                </ToggleButton>
                <ToggleButton value={RdvEtat.NOT_RESPECTED}>
                    <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.NOT_RESPECTED)}`]} />
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    </>
};

const EmployeeNotHeldToggle= (props:any) =>{
    const handleEmployeeCommentChange = (event:any) => rdvPresetBusinessLogic.handleEmployeeCommentChange(props.logicRacine, event.target.value);
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvPresetLectureBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine, event, value);
    return <>
        <div className={props.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.comment} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                placeholder={props.logicRacine.activeLanguageRef.current[0] === 'en' ? 'Comment' : 'Commentaire'}
                onChange={handleEmployeeCommentChange}
                value={props.logicRacine.creneauRdvEmployeePreset.commentaire}
            />
        </div>
        <div className={props.styleClasses.inputField}>
            <ToggleButtonGroup
                color="primary"
                value={props.logicRacine.creneauRdvEmployeePreset.meetingStatus}
                exclusive
                onChange={handleEmployeeMeetingStatusChange}
                aria-label="Platform"
            >
                <ToggleButton value={RdvEtat.NOT_HELD}>
                    <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.NOT_HELD)}`]} />
                </ToggleButton>
            </ToggleButtonGroup>
        </div>

    </>
};

export const employeePresetToggle = (props:any) => {
    switch (props.component) {
        case 'employeeRapportConfirmRdvToggle':
            return <EmployeeRapportConfirmRdvToggle {...props} />;
        case 'employeeNotHeldToggle':
            return <EmployeeNotHeldToggle {...props} />;
        default:
            return <></>;
    }
}

const rdvPresetLectureBusinessLogic: any = {

    lire:(logicRacine:any,
                          rdv:Rdv,
                          creneauRdv:CreneauRdv,
                          creneauRdvEmployee:CreneauRdvEmployee,
                          creneauRdvEnfantParent:CreneauRdvEnfantParent,
                          lectureRdv:LectureRdv): LectureRdv  => {

        if(rdv.meetingType !== RdvType.PRESET){
            return lectureRdv ;
        }
        if(_.isNull(rdv.id) || _.isUndefined(rdv.id)){
            return lectureRdv ;
        }

        lectureRdv.showDeleteBtn = rdv.meetingStatus === RdvEtat.WAIT ;

        if(_.isNull(creneauRdvEmployee) || _.isUndefined(creneauRdvEmployee)){
            return lectureRdv ;
        }
        if(_.isNull(creneauRdvEnfantParent) || _.isUndefined(creneauRdvEnfantParent)){
            return lectureRdv ;
        }

        lectureRdv.parentOutcomeShow = false ;
        lectureRdv.employeeOutcomeShow = true ;
        lectureRdv  = rdvPresetLectureBusinessLogic.lireBeforeMeeting(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent, lectureRdv);
        lectureRdv  = rdvPresetLectureBusinessLogic.lireAfterMeeting(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent, lectureRdv);

        if(_.isNull(rdv.id) || _.isUndefined(rdv.id) || rdv.id < 1){
            lectureRdv.employeeOutcomeShow = false ;
        }
        lectureRdv.showDeleteBtn = rdv.meetingStatus === RdvEtat.WAIT ;
        lectureRdv.title= rdv.objet;
        lectureRdv.meetingStatus = rdv.meetingStatus;
        lectureRdv.timeSlotStyle = logicRacine.styleClasses.eventContainerNormal;
        lectureRdv.subtitle = `appointment_${_.toLower(creneauRdv.meetingStatus)}`;

        return lectureRdv;
    },
    lireAfterMeeting:(logicRacine:any,
                      rdv:Rdv,
                      creneauRdv:CreneauRdv,
                      creneauRdvEmployee:CreneauRdvEmployee,
                      creneauRdvEnfantParent:CreneauRdvEnfantParent,
                      lectureRdv:LectureRdv): LectureRdv => {

        if(logicRacine.statusAfterMeetingViewPresetRef.current === false){
            return lectureRdv;
        }

        lectureRdv.parentOutcomeShow = false ;

        if(rdv.meetingStatus === RdvEtat.CONFIRM){
            lectureRdv.parentOutcomeShow = true ;
            //Rapport d'un rendez-vous confirmé
            //lectureRdv.employeeToggle  = rdvPresetLectureBusinessLogic.employeeRapportConfirmRdvToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = 'employeeRapportConfirmRdvToggle';
        }
        else {
            //Rapport d'un rendez-vous non-confirmé et non annulé
           // lectureRdv.employeeToggle  = rdvPresetLectureBusinessLogic.employeeNotHeldToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = 'employeeNotHeldToggle';
        }
        return lectureRdv ;
    },
    lireBeforeMeeting(logicRacine:any,
                      rdv:Rdv,
                      creneauRdv:CreneauRdv,
                      creneauRdvEmployee:CreneauRdvEmployee,
                      creneauRdvEnfantParent:CreneauRdvEnfantParent,
                      lectureRdv:LectureRdv): LectureRdv{
        if(logicRacine.statusBeforeMeetingViewPresetRef.current){
            return lectureRdv;
        }

        lectureRdv.subtitle = 'rdv_read_title_employee_cancel';

        return lectureRdv ;
    }
};

export default rdvPresetLectureBusinessLogic ;