import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import classNames from "classnames";
import DialogForm from "../../../components/ui/form/DialogForm";
import {MenuItem, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";

function CanteenWeekForm(props:any) {
    const {
        theme,
        activeLanguage,
        title,
        open,
        setOpen,
        classes,
        register,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange,
        onSubmit,
        dataPeriod,
        handlePeriodChange,
        period
    } = props;
    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.school_period} />}
                    value={period.id || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    onChange={(a:any) => handlePeriodChange(a)}
                    inputProps={register('periodId', {
                        required: 'Period is required',
                    })}
                >
                    {dataPeriod.map((option:any) => (
                        <MenuItem key={option.id} value={option.id} sx={{fontWeight:400}}>
                            {option.period}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.title_canteen_week}/>}
                    size="small"
                    color={"secondary"}
                    {...register("weekName", {
                        required: "Week name is required"
                    })}
                    //error={!!errors.period?.message}
                    //helperText={errors.username?.message}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                    <DatePicker
                        label={<FormattedMessage id={messages.start_day} />}
                        value={startDate}
                        onChange={handleStartDateChange}
                        slotProps={{ textField: { color: 'secondary' } }}
                    />
                </LocalizationProvider>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                    <DatePicker
                        label={<FormattedMessage id={messages.end_day} />}
                        value={endDate}
                        minDate={startDate}
                        onChange={handleEndDateChange}
                        slotProps={{ textField: { color: 'secondary' } }}
                    />
                </LocalizationProvider>
            </div>

        </DialogForm>
    );
}

export default CanteenWeekForm;
