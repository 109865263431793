import {DataEtat} from "./DataEtat";

export const commonDataImp: Common =  {
    id: 0,
    compositeId: {},
    etat: DataEtat.OK,
    miseajour: 0,
    editable: true,
    editeur: 0,
    tag: null,
    metadata: "{}",
    libelle: ''
}

export type Common = {
    id: number;
    compositeId: any;
    etat: DataEtat;
    miseajour: number;
    editable: true;
    editeur: number;
    tag: any;
    metadata: string;
    libelle: string;
}