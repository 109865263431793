import React, {useEffect, useMemo, useRef, useState} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {
    Autocomplete,
    Avatar,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import classNames from "classnames";
import scolariteStyles from "../jss/scolarite-jss";
import useScolaritePaiementVariables from "./businesslogic/ScolaritePaiementVariables";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {
    columnsVersement,
    excelVersementDataMapping,
    ScolariteVersement
} from "../../../constants/type/ScolariteVersement";
import _ from "lodash";
import scolaritePaiementStateUpdate from "./businesslogic/ScolaritePaiementStateUpdate";
import {Child, childDataImpl} from "../../../constants/type/Child";
import scolaritePaiementBusinessLogic from "./businesslogic/ScolaritePaiementBusinessLogic";
import Chip from "@mui/material/Chip";
import PrintIcon from "@mui/icons-material/Print";
import SchoolingRecuPdfForm from "./SchoolingRecuPdfForm";
import {isPositiveValue} from "../../../helpers/helpers";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {GroupEnfant} from "../../../constants/type/GroupEnfant";
import {
    getDefaultMRTOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {columnsEcheanciersGrouping, EcheancierGrouping} from "../../../constants/type/EcheancierGrouping";
import {excelFraisOneShotDataMapping} from "../../../constants/type/ScolariteSansEcheanceEnfant";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();



    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useScolaritePaiementVariables({styleClasses, ...props})
    }
};

export const SchoolingContext = React.createContext({});
function SchoolingPaymentList(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);

    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const menuLeaveTimerRef = useRef<any>();
    const searchPlaceholderRef =  useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');
    const [numeriChange, setNumeriChange] = useState<number>(moment().valueOf());




    const handleOnScolariteAnneChange = (event: any) => {
        scolaritePaiementStateUpdate.idScolariteAnneeSel(logic, parseInt(event.target.value));
        setNumeriChange(moment().valueOf());
    };

    const handleScolariteType = (event: any) => {
        const selection: string[] = event.target.value;
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteTypeIds = [] ;

        if(selection.includes("0")){
            if(logic.dataSel.scolariteTypeIds.length !== logic.scolariteTypeRef.current.length){
                logic.scolariteTypeRef.current.forEach((st:any) => {
                    data.scolariteTypeIds.push(st.id);
                });
            }
        }
        else {
            selection.forEach((idloop:string) => {
                if(!isPositiveValue(idloop)){
                    return ;
                }
                data.scolariteTypeIds.push(_.toInteger(idloop));
            });
        }

        scolaritePaiementStateUpdate.dataSel(logic, data);
        setNumeriChange(moment().valueOf());
    };

    const setChild = (item:Child | null) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(childDataImpl);
        }
        item = _.isEmpty(item) ? _.cloneDeep(childDataImpl) : item;
        scolaritePaiementStateUpdate.childSelected(logic, item);
        setNumeriChange(moment().valueOf());
    }
    const handleChildChange = (event: React.SyntheticEvent, value: Child) => setChild(value);



    const imprimer = async (row:MRT_Row<any>) => {
        const data:any = logic.versements[row.index];
        scolaritePaiementStateUpdate.versementSel(logic, data);
        scolaritePaiementStateUpdate.openPDFDialogForm(logic, true);
    }

    const columns = useMemo<MRT_ColumnDef<ScolariteVersement>[]>(
        // @ts-ignore
        () => [...columnsVersement({intl:intl})
        ], [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.versements,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                showAddButton={false}
                showDeleteButton={false}
                export_xls_title={export_xls}
                xls_nom_fichier="Tuition_Payment"
                excelDataMapping={excelVersementDataMapping({
                    rows: table?.getPrePaginationRowModel()?.rows,
                    columns: table?.getVisibleFlatColumns(),
                    intl,
                    infoSpace: ``,
                    prefix:'',
                    table,
                })}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editIcon={<PrintIcon />}
                editRow={() => imprimer(row)}
                styleClasses={styleClasses}
            />
        )
    });

    const scolariteAnneeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteAnneesRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteAnneesRef?.current]) ;

    const scolariteTypeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteTypeRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight:400}}>
                <Checkbox checked={logic.dataSel.scolariteTypeIds.includes(item.id)} />
                <ListItemText primary={item.nom} />
            </MenuItem>
        ));
    }, [logic.scolariteTypeRef?.current, logic.dataSel.scolariteTypeIds]) ;



    useEffect(() => {
        clearTimeout(menuLeaveTimerRef.current);
        if(isPositiveValue(logic.idScolariteAnneeSel) === false){
            return ;
        }
        if(isPositiveValue(logic.childSelected.id) === false){
            scolaritePaiementBusinessLogic.loadlastVersements(logic, logic.idScolariteAnneeSel);
            return ;
        }
        scolaritePaiementStateUpdate.versements(logic, []);
        menuLeaveTimerRef.current = setTimeout(function() {
            const groupEnfant : GroupEnfant =  _.find(logic.groupEnfantsRef.current, {'enfantId': logic.childSelected.id, 'scolariteAnneeId': logic.idScolariteAnneeSel});
            if(_.isObject(groupEnfant) === false){
                return ;
            }
            scolaritePaiementBusinessLogic.loadVersements(logic, logic.childSelected.id, logic.dataSel.scolariteTypeIds, groupEnfant.groupesectionId, logic.idScolariteAnneeSel);
        }, 2000);
        return () => {
        };
    }, [numeriChange, logic.paiementListInited]);


    useEffect(() => {
        moment.locale(logic.activeLang);
        scolaritePaiementBusinessLogic.initPostConstruct(logic);

       // return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);

    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="paymentlist"/>
            </Box>

            <div className={styleClasses.tableContainer}>
                {
                    logic.contentLoaded === false &&
                    <Box  sx={{ width: '100%', height:'200px', textAlign: 'center', alignContent:'center', verticalAlign:'middle' }}>
                        <CircularProgress color="primary" />
                    </Box>
                }
                {
                    logic.contentLoaded &&
                    <>
                        <Box className={styleClasses.searchBox}>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12} sx={{mt:2}}>
                                    <FormControl
                                        sx={{
                                            display: { xs: 'block', sm: 'inline-block'},
                                            marginBottom: { xs: 2},
                                            marginRight: { sm: 2 },
                                            width:'100%',
                                        }}
                                    >
                                        <Autocomplete
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '100%',
                                                    fontWeight: 'normal',
                                                }
                                            }}
                                            defaultValue=""
                                            options={logic.childrenClasseRef.current}
                                            value={logic.childSelected}
                                            autoHighlight
                                            getOptionLabel={(opt:any) => opt.childNomPrenomForm}
                                            isOptionEqualToValue={(opt:any, value:any) => opt.id === value.id}
                                            // @ts-ignore
                                            groupBy={(opt:any) => opt.eleves?.[0]?.classe.nom ?? ''}
                                            loading={false}
                                            onChange={handleChildChange}
                                            renderOption={(props, opt:any) => (
                                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                                    <Avatar alt="avatar"  src={opt.childphotoForm} sx={{mr: 2}}/>
                                                    {opt.childNomPrenomForm}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<FormattedMessage id={messages.choose_child} />}
                                                    required
                                                    color="secondary"
                                                    variant="outlined"
                                                    sx={{marginTop: 0.4, width: '100% !important'}}
                                                    size="small"
                                                    placeholder={searchPlaceholderRef.current}
                                                    inputProps={{
                                                        ...params.inputProps
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        sx={{
                                            display: { xs: 'block', sm: 'inline-block'},
                                            marginBottom: { xs: 2},
                                            marginRight: { sm: 2 },
                                            mt:2,
                                            width:'100%',
                                        }}
                                    >
                                        <TextField
                                            select
                                            label={<FormattedMessage id={messages.scolaritee_annee}/>}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '100%',
                                                    fontWeight: 'normal',
                                                }
                                            }}
                                            color={"secondary"}
                                            value={logic.idScolariteAnneeSel}
                                            onChange={handleOnScolariteAnneChange}
                                            inputProps={{
                                                required: "Required field",
                                                min: 1,
                                            }}
                                        >
                                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                            {scolariteAnneeMenuItems}
                                        </TextField>
                                    </FormControl>

                                </Grid>

                                <Grid item md={5} xs={12} >
                                    <InputLabel id="demo-multiple-checkbox-label"><FormattedMessage id={messages.fees_type} /></InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        size="small"
                                        fullWidth
                                        sx={{ width: { xs: '100% !important'}, }}
                                        color={"secondary"}
                                        value={logic.dataSel.scolariteTypeIds}
                                        onChange={handleScolariteType}
                                        input={<OutlinedInput label={<FormattedMessage id={messages.fees_type} />} />}
                                        renderValue={(selected:any) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value:any) => (
                                                    <Chip key={value} label={logic.scolariteTypeMapRef.current.get(value)?.nom ?? ``} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight:400}}>{logic.activeLang === 'en' ? 'All type of fees' : 'Tous les types frais'}</MenuItem>
                                        {scolariteTypeMenuItems}
                                    </Select>
                                </Grid>

                                <Grid item md={5} xs={12}> </Grid>
                            </Grid>

                            <Box sx={{display:  logic.showDetailsTableContent && logic.dataSel.versementReference.groupEnfantId > 0 ? 'block' : 'none' }}>
                                <Grid container spacing={2}>
                                    <Grid item md={4} xs={12}>
                                        <Box className={styleClasses.childDetails}>
                                            <Box className={classNames(styleClasses.childInfoBox)}>
                                                <Avatar className={styleClasses.customAvatar}
                                                        src={logic.childSelected.childphotoForm}
                                                        sx={{width:56, height:56}}
                                                        alt={logic.childSelected.childNomPrenomForm}
                                                />
                                                <Box className={styleClasses.customTitle} letterSpacing={1} sx={{ml:1}}>
                                                    <Box>
                                                        <Typography className={styleClasses.titleChildInfo} variant="subtitle1">
                                                            {logic.childSelected.childNomPrenomForm}
                                                        </Typography>
                                                        <span style={{textTransform: "capitalize"}}>{logic.childSelected.classeNameForm}</span>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <Box className={styleClasses.amountDetails}>
                                            <Grid container spacing={2}>
                                                <Grid item md={12} xs={12}>
                                                    <Typography
                                                        variant={"subtitle2"}
                                                        color={"green"}
                                                        sx={{ textAlign:'center' }}
                                                    >
                                                        <FormattedMessage id={messages.schooling_total_payment} /> : {logic.dataSel.montantPayerForm} FCFA
                                                    </Typography>
                                                </Grid>

                                                <Grid item md={6} xs={12} sx={{ paddingRight:1}}>
                                                    <Typography
                                                        variant={"subtitle2"}
                                                        sx={{ textAlign:'left' }}
                                                    >
                                                        <FormattedMessage id={messages.schooling_total_montant} />
                                                    </Typography>

                                                    <Typography
                                                        variant={"subtitle2"}
                                                        sx={{ textAlign:'left' }}
                                                    >
                                                        {logic.dataSel.montantFactureForm} FCFA
                                                    </Typography>
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <Typography
                                                        variant={"subtitle2"}
                                                        color={"red"}
                                                        sx={{ textAlign:'left' }}
                                                    >
                                                        <FormattedMessage id={messages.schooling_total_restant} />
                                                    </Typography>
                                                    <Typography
                                                        variant={"subtitle2"}
                                                        color={"red"}
                                                        sx={{ textAlign:'left' }}
                                                    >
                                                        {logic.dataSel.montantSoldeForm} FCFA
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item md={5} xs={12}> </Grid>

                                </Grid>
                            </Box>

                        </Box>

                        <MaterialReactTable
                            table={logic.tableConfigRef.current}
                            key={logic.tableKeyValue} />
                    </>
                }

            </div>

            <SchoolingContext.Provider value={logic}>
                <SchoolingRecuPdfForm />
            </SchoolingContext.Provider>

        </div>
    );
}

export default withSnackbar(SchoolingPaymentList);
