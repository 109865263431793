export default [
    {
        key: 1,
        name_en: 'Session 1',
        name_fr: 'Session 1',
    },
    {
        key: 2,
        name_en: 'Session 2',
        name_fr: 'Session 2',
    },
]
