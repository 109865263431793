export const REGISTRATION_FILES_FR = [
    {
        id: 1,
        value: 'Re-inscription',
    },
    {
        id: 2,
        value: 'Droits à l\'image',
    },
    {
        id: 3,
        value: 'Contrat Financier',
    },
    {
        id: 4,
        value: 'Décharge de responsabilité',
    },
    {
        id: 5,
        value: 'Protocole Santé',
    },
    {
        id: 6,
        value: 'Règlement intérieur',
    },

];

export const REGISTRATION_FILES_EN = [
    {
        id: 1,
        value: 'Re-registration',
    },
    {
        id: 2,
        value: 'Image Rights',
    },
    {
        id: 3,
        value: 'Financial Contract',
    },
    {
        id: 4,
        value: 'Liability disclaimer',
    },
    {
        id: 5,
        value: 'Health protocol',
    },
    {
        id: 6,
        value: 'Rules of procure',
    },
]
