import {createSlice} from "@reduxjs/toolkit";
import * as yup from "yup";

export const cacheTableImpl = {
    tag: '',
    lastUpdate: -1,
    data: [],
};
export const cacheTableSchema = yup.object().shape({
    tag: yup.string().required(),
    lastUpdate: yup.number().required(),
    data: yup.array().of(yup.mixed<Object>()).nonNullable().default([]).required(),
});

export type CacheTable = yup.InferType<typeof cacheTableSchema>;

interface CacheTableState { dataCaches: CacheTable[] }  ;

const initialState = { dataCaches: []} satisfies CacheTableState as CacheTableState;

const remoteDBCacheSlice = createSlice({
    name: 'cacheDB',
    initialState: initialState,
    reducers:{
        clearDataCache: (state) => {
            return initialState;
        },
        setTableCaches:(state, action ) => {
            state.dataCaches = action.payload;
        }
    }
})

export const { clearDataCache, setTableCaches } = remoteDBCacheSlice.actions;

export default remoteDBCacheSlice.reducer;
