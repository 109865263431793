import React, { useRef, useState } from "react";
import {CreneauRdv, creneauRdvDataImp} from "../../../constants/type/CreneauRdv";
import moment from "moment/moment";
import {
    LectureRdv,
    lectureRdvDataImpl,
    Rdv,
    RdvFilter,
    rdvFilterDataImpl,
    rdvNormalDataImpl
} from "../../../constants/type/Rdv";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import {Child} from "../../../constants/type/Child";
import {Fonction} from "../../../constants/type/Fonction";
import {Employee} from "../../../constants/type/Employee";
import _ from "lodash";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import {CreneauRdvEmployee, creneauRdvEmployeeDataImp} from "../../../constants/type/CreneauRdvEmployees";
import {CreneauRdvEnfantParent, creneauRdvEnfantParentDataImp} from "../../../constants/type/CreneauRdvEnfantParent";
import {Parent} from "../../../constants/type/Parent";
import {useDispatch, useSelector} from "react-redux";
import {Profils} from "../../../constants/type/Profils";
import {useIntl} from "react-intl";
import {ReportInfo, reportInfoDataImpl} from "./PopperBusinessLogic";


const useRdvVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;


    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const intl = useIntl() ;
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const websocketSubscriptMapRef = useRef<Map<string,any>>(new Map<string,any>());
    const websocketClientRef = useRef<any>({});
    const [showContent, setShowContent] = useState<boolean>(false);
    const activeLanguageRef = useRef<any[]>(props.locale.split('-'));
    const [creneauRdvListInCalendar, setCreneauRdvListInCalendar] = useState<CreneauRdv[]>([]);
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    const [minDate, setMinDate] = useState<Date>(moment().startOf('month').toDate());
    const [maxDate, setMaxDate] = useState<Date>(moment().endOf('month').toDate());
    const [rdvList, setRdvList] = useState<Rdv[]>([]);
    const rdvStoreRef = useRef<Rdv[]>([]);
    const classeRoomRef  = useRef<ClasseRoom[]>([]);
    const childrenRef  = useRef<Child[]>([]);
    const childrenClasseRef  = useRef<Child[]>([]);
    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const employeesRef  = useRef<Employee[]>([]);
    const employeesByFonctionRef  = useRef<Employee[]>([]);
    const teachersListRef  = useRef<Employee[]>([]);
    const profilsRef = useRef<Profils[]>([]);
    const isTeacherRef = useRef<boolean>(false);
    const classeIdRef = useRef<number>(0);
    const calendarRef  = useRef<any|undefined>(undefined);
    const eventCreneauSelectedRef  = useRef<CreneauRdv>(_.cloneDeep(creneauRdvDataImp));

    const [openPresetDialogForm, setOpenPresetDialogForm] = useState<boolean>(false);
    const [openFilterDialogForm, setOpenFilterDialogForm] = useState<boolean>(false);
    const newPresetRef  = useRef<boolean>(false);
    const newNormalRef  = useRef<boolean>(false);
    const [rdvEdit, setRdvEdit] = useState<Rdv>(_.cloneDeep(rdvNormalDataImpl));
    const [rdvToolbarSelect, setRdvToolbarSelect] = useState<Rdv>(_.cloneDeep(rdvNormalDataImpl));
    const [minuteStep, setMinuteStep] = useState<number>(60);// in minutes
    const [filterRdv, setFilterRdv] = useState<RdvFilter>(_.cloneDeep(rdvFilterDataImpl));

    const reportInfoRef = useRef<ReportInfo>(_.cloneDeep(reportInfoDataImpl));
    const anchorRef = useRef<any>(null);
    const rdvNormalDureeRef = useRef<number>(moment.duration({minutes:20}).asMilliseconds());
    const rdvNormalTimeRef = useRef<number[]>([
        moment.duration('15:30').asMilliseconds(),
        moment.duration('15:50').asMilliseconds(),
        moment.duration('16:10').asMilliseconds(),
    ]);
    const rdvStatusStyleRef  = useRef<Map<RdvEtat, any>>(new Map([
        [RdvEtat.CANCEL, styleClasses.eventCancel],
        [RdvEtat.CONFIRM, styleClasses.eventValidate],
        [RdvEtat.DONE, styleClasses.eventDone],
        [RdvEtat.NOT_HELD, styleClasses.eventNotRespected],
        [RdvEtat.NOT_RESPECTED, styleClasses.eventNotRespected],
        [RdvEtat.REPORT, styleClasses.eventReport],
        [RdvEtat.REPORT_CONFIRM, styleClasses.eventReportConfirm],
        [RdvEtat.PARTIAL_CONFIRM, styleClasses.eventPartialConfirm],
        [RdvEtat.WAIT, styleClasses.eventPending],
    ]));
    const indicatorRef = useRef<Map<string, number>>(new Map([
        [RdvEtat.CANCEL.valueOf(), 0],
        [RdvEtat.CONFIRM.valueOf(), 0],
        [RdvEtat.DONE.valueOf(), 0],
        [RdvEtat.NOT_RESPECTED.valueOf(), 0],
        [RdvEtat.REPORT.valueOf(), 0],
        [RdvEtat.REPORT_CONFIRM.valueOf(), 0],
        [RdvEtat.PARTIAL_CONFIRM.valueOf(), 0],
        [RdvEtat.WAIT.valueOf(), 0],
        ['eleve_total', 0],
        ['creneau_total', 0],
    ]));

    const [savingStateBtn, setSavingStateBtn] = useState<boolean>(false);
    const classeRef = useRef<ClasseRoom | null>(null);
    const classeNumRef = useRef<number>(0);
    const deadlineDateRef = useRef<string>('');

    const [savingStatePresetBtn, setSavingStatePresetBtn] = useState<boolean>(false);
    const [showContentPreset, setShowContentPreset] = useState<boolean>(false);
    const [lectureRdvPreset, setLectureRdvPreset] = useState<LectureRdv | null>(_.cloneDeep(lectureRdvDataImpl));
    const [startFormPreset, setStartFormPreset] = useState<moment.Moment>(moment());
    const [endFormPreset, setEndFormPreset] = useState<moment.Moment>(moment().add(1, 'days'));
    const [classeIdPreset, setClasseIdPreset] = useState<number>(0);
    const [rdvCancelOpenBeforeMeetingPreset, setRdvEtatCheckedBeforeMeetingPreset] = useState<boolean>(false);
    const [statusAfterMeetingPreset, setStatusAfterMeetingPreset] = useState<RdvEtat>(RdvEtat.DONE);
    const editDisablePresetRef = useRef<boolean>(false);
    const statusBeforeMeetingViewPresetRef = useRef<boolean>(true);
    const statusAfterMeetingViewPresetRef = useRef<boolean>(true);
    const [creneauRdvEmployeePreset, setCreneauRdvEmployeePreset] = useState<CreneauRdvEmployee | null | undefined>(null);
    const creneauRdvEnfantParentPresetRef = useRef<CreneauRdvEnfantParent | null | undefined>(null);

    const [openNormalDialogForm, setOpenNormalDialogForm] = useState<boolean>(false);
    const [savingStateNormBtn, setSavingStateNormBtn] = useState<boolean>(false);
    const [showContentNorm, setShowContentNorm] = useState<boolean>(false);
    const [lectureRdvNorm, setLectureRdvNorm] = useState<LectureRdv | null>(_.cloneDeep(lectureRdvDataImpl));
    const [crenoDayEmployeeNorm, setCrenoDayEmployeeNormForm] = useState<moment.Moment>(moment());
    const [dayNormForm, setDayNormForm] = useState<moment.Moment>(moment().startOf('day'));
    const [startNormForm, setStartNormForm] = useState<moment.Moment>(moment());
    const [endNormForm, setEndNormForm] = useState<moment.Moment>(moment().add(30, 'minutes'));
    const [childNormSelected, setChildNormSelected] = useState<Child | null | undefined>(null);
    const [childNormList, setChildNormList] = useState<Child[]>([]);
    const [employeeNormSelected, setEmployeeNormSelected] = useState<Employee | null | undefined>(null);
    const teacherRef = useRef<Employee | null | undefined>(null);
    const [childNormDisable, setChildNormDisable] = useState<boolean>(true);
    const creneauRdvEnfantParentNormRef = useRef<CreneauRdvEnfantParent | null | undefined>(_.cloneDeep(creneauRdvEnfantParentDataImp));
    const [creneauRdvEmployeeNorm, setCreneauRdvEmployeeNorm] = useState<CreneauRdvEmployee | null | undefined>(_.cloneDeep(creneauRdvEmployeeDataImp));
    const parentNormRef = useRef<Parent | null | undefined>(null);
    const [editDisableNorm, setEditDisableNorm] = useState<boolean>(false);

    const statusBeforeMeetingViewNormRef = useRef<boolean>(true);
    const statusAfterMeetingViewNormRef = useRef<boolean>(true);
    const isFormDisableForCurrentUserRef = useRef<boolean>(false);
    const searchPlaceholderNormRef = useRef<string>( activeLanguageRef.current[0] === 'en' ? 'Search by name' : 'Recherche par nom');


    const [expandNormalPast, setExpandNormalPast] = useState<boolean>(false);
    const [expandNormalNext, setExpandNormalNext] = useState<boolean>(false);
    const [expandPresetPast, setExpandPresetPast] = useState<boolean>(false);
    const [expandPresetNext, setExpandPresetNext] = useState<boolean>(false);
    const [expandPreset, setExpandPreset] = useState<boolean>(false);
    const [expandNormal, setExpandNormal] = useState<boolean>(true);
    const [openRdvList, setOpenRdvList] = useState<boolean>(false);
    const [listItemUpdate, setListItemUpdate] = useState<number>(0);

    return {
        userRef,websocketClientRef,profilsRef,intl,editDisableNorm, setEditDisableNorm,
        expandNormalPast,setExpandNormalPast,expandNormalNext,setExpandNormalNext,expandPresetPast,setExpandPresetPast,
        expandPresetNext,setExpandPresetNext,expandPreset,setExpandPreset,expandNormal,setExpandNormal,openRdvList,setOpenRdvList,
        classeRoomRef,childrenRef,childrenClasseRef,employeesRef,employeesByFonctionRef,teachersListRef,listItemUpdate,setListItemUpdate,
        theme,styleClasses,newNormalRef,rdvList,setRdvList, jobsRef,eventCreneauSelectedRef, activeLanguageRef, calendarRef,
        minuteStep, creneauRdvListInCalendar, setCreneauRdvListInCalendar,isFormDisableForCurrentUserRef,
        rdvEdit, setRdvEdit, rdvStoreRef,  setMinuteStep,filterRdv,setFilterRdv,teacherRef,isTeacherRef,
        openPresetDialogForm, setOpenPresetDialogForm, openNormalDialogForm, setOpenNormalDialogForm, snackbarShowMessage,
        calendarDate, setCalendarDate, minDate, maxDate,rdvNormalTimeRef,rdvNormalDureeRef,classeIdRef,
        setMinDate, setMaxDate,rdvToolbarSelect, setRdvToolbarSelect,setShowContent, showContent,newPresetRef
        ,openFilterDialogForm,setOpenFilterDialogForm,savingStateBtn,setSavingStateBtn,reportInfoRef,anchorRef,rdvStatusStyleRef,classeRef,classeNumRef,deadlineDateRef,indicatorRef,
        savingStatePresetBtn,setSavingStatePresetBtn,showContentPreset,setShowContentPreset,lectureRdvPreset,setLectureRdvPreset,
        startFormPreset,setStartFormPreset,endFormPreset,setEndFormPreset,classeIdPreset,setClasseIdPreset,
        rdvCancelOpenBeforeMeetingPreset,setRdvEtatCheckedBeforeMeetingPreset,statusAfterMeetingPreset,setStatusAfterMeetingPreset,
        editDisablePresetRef,statusBeforeMeetingViewPresetRef,statusAfterMeetingViewPresetRef,creneauRdvEmployeePreset,setCreneauRdvEmployeePreset,
        creneauRdvEnfantParentPresetRef,savingStateNormBtn,setSavingStateNormBtn,showContentNorm,setShowContentNorm,lectureRdvNorm,setLectureRdvNorm,crenoDayEmployeeNorm,
        setCrenoDayEmployeeNormForm,dayNormForm,setDayNormForm,startNormForm,setStartNormForm,endNormForm,setEndNormForm,childNormSelected, setChildNormSelected, childNormList,setChildNormList, employeeNormSelected,  setEmployeeNormSelected,
        childNormDisable,setChildNormDisable,creneauRdvEnfantParentNormRef,creneauRdvEmployeeNorm,setCreneauRdvEmployeeNorm
        ,parentNormRef,statusBeforeMeetingViewNormRef,statusAfterMeetingViewNormRef
        ,searchPlaceholderNormRef,websocketSubscriptMapRef,pageForm,dataCaches, dispatch

    }
}
export default useRdvVariables;
