import React, {useEffect} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import ScolariteFraisGroupEditForm from "./ScolariteFraisGroupEditForm";
import ScolariteFraisGroupList from './ScolariteFraisGroupList';
import ScolariteAutresfraisDeleteForm from "./ScolariteFraisGroupDeleteForm";
import {scolariteGroupDataImpl, scolariteGroupSchema} from '../../../constants/type/ScolariteGroup';
import useScolariteClasseVariables from './businesslogic/ScolariteFraisGroupVariables';
import scolariteFraisGroupBusinessLogic from "./businesslogic/ScolariteFraisGroupBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(scolariteGroupDataImpl),
        // @ts-ignore
        resolver: yupResolver(scolariteGroupSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useScolariteClasseVariables({styleClasses, ...props})
    }
};
export const ScolariteToutFraisContext = React.createContext({});
function ScolariteFraisGroup(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const classes = logic.styleClasses;

    useEffect(() => {
        moment.locale(logic.activeLang);
        scolariteFraisGroupBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);



    return (
        <div className={classes.root}>
            <Box className={classes.buttonContainer}>
                <SchoolingSubMenu classes={classes} selected="scolaritedesfrais"/>
            </Box>


            <ScolariteToutFraisContext.Provider value={logic}>
                <ScolariteFraisGroupList />
            </ScolariteToutFraisContext.Provider>
            <ScolariteToutFraisContext.Provider value={logic}>
                <ScolariteFraisGroupEditForm />
            </ScolariteToutFraisContext.Provider>
            <ScolariteToutFraisContext.Provider value={logic}>
                <ScolariteAutresfraisDeleteForm />
            </ScolariteToutFraisContext.Provider>
        </div>
    );
}

export default withSnackbar(ScolariteFraisGroup);
