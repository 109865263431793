import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {
    columnsScolariteGroup,
    excelScolariteGroupDataMappingPlugin,
    ScolariteGroup,
    SCOLARITEGROUP_CONSTANT,
    scolariteGroupCustomOptionsPlugins,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";

export const SCOLARITEECHEANCIER_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteEcheancier) : ScolariteEcheancier => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteEcheancierDataImpl);
        const data : ScolariteEcheancier = {...itemInit, ...item} ;

        data.ladateForm          = moment(data.ladate);
        // @ts-ignore
        data.liredateForm        = data.ladateForm.format("L");
        // @ts-ignore
        data.rappeldateForm      = isPositiveValue(data.rappeldate) ? moment(data.rappeldate) : null;

        // @ts-ignore
        data.scolariteGroup         = SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, data.scolariteGroup);
        data.scolariteGroupForm     = data.scolariteGroup;
        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteEcheancier) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};
export  const scolariteEcheancierDataImpl: ScolariteEcheancier = {
    id: null,
    ladate: moment().startOf('day').valueOf(),
    nom: ``,
    code: ``,
    scolariteTypeTag: null,
    scolariteGroup: _.cloneDeep(scolariteGroupDataImpl),
    scolariteGroupForm: _.cloneDeep(scolariteGroupDataImpl),
    IdsToDelete: [],
    montant: 0,
    rappeldate: null,
    ladateForm: moment(),
    liredateForm: '',
    rappeldateForm: null,
    common: _.cloneDeep(commonDataImp),
}

export const scolariteEcheancierSchema = yup.object().shape({
    id: yup.number().nullable().notRequired(),
    nom: yup.string().required(),
    code: yup.string().nullable().notRequired(),
    ladate: yup.number().nullable().notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    scolariteGroup: yup.mixed<ScolariteGroup>().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().notRequired(),
    montant: yup.number().default(0).min(1).required(),
    rappeldate: yup.number().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().nullable().required(),
    liredateForm: yup.string().nonNullable().notRequired(),
    common: yup.mixed<Common>(),
});


export const excelScolariteEcheancierDataMapping = (
    {rows=[], columns=[], intl={}, table=[]}:{ rows:any[]; intl:any;  table: any; columns: any[];}
) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj = excelScolariteGroupDataMappingPlugin({obj, row:data, columns, intl, table, prefix:`scolariteGroupForm.`, infoSpace: intl.formatMessage({id:messages.class_fees})});

        if(isColumnVisible(columns, 'nom')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, 'code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, 'montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        if(isColumnVisible(columns, 'liredateForm')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
        }
        return obj;
    });
}

export const excelScolariteEcheancierDataMappingPlugin =({obj={}, row={}, columns=[], table=[], intl={}, prefix=``, infoSpace=``}:{obj:any; row:any; intl:any; infoSpace:string;  table: any; prefix:string; columns: any[];}) => {
    const data = row?.scolariteEcheancierForm;
    if(_.isObject(data) === false){
        return obj ;
    }
    obj = excelScolariteGroupDataMappingPlugin({obj, row:data, columns, table, intl, prefix:`scolariteGroupForm.`, infoSpace:intl.formatMessage({id:messages.class_fees})});

    if(isColumnVisible(columns, `${prefix}nom`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, `${prefix}code`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    if(isColumnVisible(columns, `${prefix}montant`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
    }
    if(isColumnVisible(columns, `${prefix}liredateForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
    }
    return obj;
}

export const echeancierCustomOptions = {
    columnVisibility : {
        ...scolariteGroupCustomOptionsPlugins.columnVisibility,
    }
} ;

export const echeancierCustomOptionsPlugin = {
    columnVisibility : {
        ...echeancierCustomOptions.columnVisibility,
    }
} ;

export const columnsEcheanciers = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsScolariteGroup({intl: intl, nomSpace: intl.formatMessage({id:messages.class_fees})}
        ),
        {
            accessorKey: 'nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            accessorKey: 'code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`,
        },
    ];
} ;


export const columnsEcheanciersPlugin = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsScolariteGroup({intl: intl, nomSpace: intl.formatMessage({id:messages.class_fees})}
        ),
        {
            accessorKey: 'scolariteEcheancierForm.nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            accessorKey: 'scolariteEcheancierForm.code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            accessorKey: 'scolariteEcheancierForm.montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
        {
            accessorKey: 'scolariteEcheancierForm.liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`,
        },
    ];
} ;

export type ScolariteEcheancier = yup.InferType<typeof scolariteEcheancierSchema>;

