import React, {useContext} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import {useForm} from "react-hook-form";
import messages from "../messages/general/messages";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";
import {FormattedMessage} from "react-intl";
import {documentScolaireFichierSchema} from "../../constants/type/DocumentScolaireFichier";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FileDeleteForm = (props:any) => {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.docFileSel),
        // @ts-ignore
        resolver: yupResolver(documentScolaireFichierSchema),
    }));
    const {errors} = formRef.current.formState;

    const onSubmit = async (data: FormData) => {
        schoolDocStateUpdate.processingFolderStateNormBtn(logic, true);
        const ids = [logic.docFileSel.id];

        // @ts-ignore
        //const toSend = DOCUMENTSCOLAIRE_CONSTANT.convertToBackEnd(dataToSend);
        schoolDocumentBusinessLogic.deleteFile(
            logic,
            messages,
            logic.docFileSel.documentScolaireId,
            ids).then((response:any) => {
            schoolDocStateUpdate.openFileDeleteDialogForm(logic, false);
            schoolDocStateUpdate.processingFolderStateNormBtn(logic, false);
        }).catch((e:any) => schoolDocStateUpdate.processingFolderStateNormBtn(logic, false));
    };

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingFolderStateNormBtn}
            title={<FormattedMessage id={messages.doc_file_delete} />}
            open={logic.openFileDeleteDialogForm}
            setOpen={logic.setOpenFileDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                <FormattedMessage id={messages.doc_file_todelete} /> <span style={{fontWeight:'bold'}}>{logic.docFileSel.nom}</span> ?
            </div>
        </DialogFormKiller>
    );
}

export default FileDeleteForm ;