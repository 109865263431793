import {getRequest} from "../helpers/axios_helpers";

function ExchangeLibraryService() {
    return {
        getAllBooks: async () => {
            const result = await getRequest(`/extra/bibliotheque`);
            return result._embedded !== undefined ? result._embedded?.livreDTOModelList : [];
        }
    }
}

export default ExchangeLibraryService;