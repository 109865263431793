import React, {useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../config-table-jss";
import Box from "@mui/material/Box";
import ScolariteSubMenu from "../ScolariteSubMenu";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {useForm} from "react-hook-form";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Icon, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ScolaritePaymentPeriodForm from "./ScolaritePaymentPeriodForm";
import moment, {Moment} from "moment";
import _ from "lodash";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type PaymentPeriodType = {
    id: number;
    schooling: string,
    nom: string,
    period: string,
    amount: number,
};

type PaymentPeriodFormValues = {
    id:number,
    schooling: string,
    nom: string,
    period: Moment,
    amount: string,
}

const data:PaymentPeriodType[] = [
    {
        id:1,
        schooling: 'Scolarité',
        nom: 'Periode P1',
        period: '02/04/2023',
        amount: 4500000,
    },
    {
        id:2,
        schooling: 'Scolarité',
        nom: 'Periode P2',
        period: '02/05/2023',
        amount: 4500000,
    },
    {
        id:3,
        schooling: 'Scolarité',
        nom: 'Periode P3',
        period: '08/06/2023',
        amount: 4500000,
    },
    {
        id:4,
        schooling: 'Scolarité',
        nom: 'Periode P4',
        period: '07/12/2023',
        amount: 4500000,
    }
];

function ScolaritePaymentPeriod(props:any) {
    const { theme, locale, } = props;
    const activeLanguage = locale.split('-');
    const classes = {...useStyles(), ...useStyles2()};
    const [paymentPeriodData, setPaymentPeriodData] = useState(data);
    const [statePaymentPeriod, setStatePaymentPeriod] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [schooling, setSchooling] = useState('');
    const [periodDate, setPeriodDate]:any = useState(moment(new Date()))

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handlePeriodDateChange = (date:any) => {
        setPeriodDate(date);
    };

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.schooling} />,
        <FormattedMessage id={messages.schooling_rappel_payment} />,
        <FormattedMessage id={messages.schooling_period_payment} />,
        <FormattedMessage id={messages.schooling_amount} />,
    ];

    const columns = useMemo<MRT_ColumnDef<PaymentPeriodType>[]>(
        () => [
            {
                accessorKey: 'schooling',
                header: columnsHeaders[0],
            },
            {
                accessorKey: 'nom',
                header: columnsHeaders[1],
                //size:300
            },
            {
                accessorKey: 'period',
                header: columnsHeaders[2],
            },
            {
                accessorKey: 'amount',
                header: columnsHeaders[3],
            }

        ],
        [],
    );

    const form = useForm<PaymentPeriodFormValues>({
        defaultValues: {
            id: 0,
            schooling: '',
            nom: '',
            period: '',
            amount:'',
        }
    });
    const { control, register, handleSubmit, formState, reset, setValue } = form;
    const { errors } = formState;

    const handleSchoolingChange = (event:any) => {
        setSchooling(event.target.value);
    }

    const addPaymentPeriod = () => {
        setStatePaymentPeriod(true);
        setValue('schooling', '');
        setValue('nom', '');
        setPeriodDate(moment(new Date()));
        //setValue('period', '');
        setValue('amount', '');
        handleClickOpen();
    }

    const editPaymentPeriod = (row:any) => {
        setStatePaymentPeriod(false);
        const data:any = paymentPeriodData[row.index];
        moment.locale(activeLanguage[0]);
        //const montant:any = new Intl.NumberFormat('fr-FR').format(4500000);

        setValue('id', data.id );
        setValue('schooling', data.schooling);
        setValue('nom', data.nom );
        //setValue('period', data.period);
        const periodDate = moment(data.period, activeLanguage[0] === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').toDate();
        setPeriodDate(moment(periodDate));
        setValue('amount', data.montant);

        handleClickOpen();
    }

    const onSubmit = () => {

    }

    const onSubmitEditForm = () => {

    }

    return (
        <div className={classes.root}>
            <Box className={classes.buttonContainer}>
                {/*<ChildSubMenu classes={classes} selected="information"/>*/}
                <ScolariteSubMenu classes={classes} selected="paymentPeriod" />
            </Box>


            <div className={classes.tableContainer}>
                <MaterialReactTable
                    columns={columns}
                    data={paymentPeriodData}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact'
                    }}
                    localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing
                    // editDisplayMode="row"
                    //onEditingRowSave={handleSaveRow}
                    enableRowSelection
                    positionActionsColumn="last"
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={classes.topToolbarActionsButton}
                            sx={{ display: 'flex',  flexWrap: 'wrap' }}
                        >
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="secondary"
                                    sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                    onClick={() => addPaymentPeriod()}
                            >
                                <Icon sx={{marginRight:1}}>add</Icon>
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            >
                                <Icon sx={{marginRight:1}}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>



                            {/*<IconButton title={export_xls}>
                                <img width="24" height="24" src={IMGS.excelIcon} alt="Excel icon" />
                            </IconButton>

                            <IconButton title={export_pdf}>
                                <img width="24" height="24" src={IMGS.pdfIcon} alt="PDF icon" />
                            </IconButton>*/}
                        </Box>
                    )}

                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} />}>
                                <IconButton
                                    onClick={() => editPaymentPeriod(row)}
                                    sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />

                <ScolaritePaymentPeriodForm
                    theme={theme}
                    activeLanguage={activeLanguage}
                    title={statePaymentPeriod ? <FormattedMessage id={messages.dialog_title_schooling_payment_period} /> : <FormattedMessage id={messages.dialog_edit_title_schooling_payment_period} /> }
                    open={open}
                    setOpen={setOpen}
                    classes={classes}
                    register={register}
                    onSubmit={statePaymentPeriod ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                    schooling={schooling}
                    handleSchoolingChange={handleSchoolingChange}
                    periodDate={periodDate}
                    handlePeriodDateChange={handlePeriodDateChange}
                />

            </div>

        </div>
    );
}

export default ScolaritePaymentPeriod;
