import React, {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {ScolariteType} from "../../../../constants/type/ScolariteType";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {Profils} from "../../../../constants/type/Profils";
import {
    fraisOneShotCustomOptions,
    ScolariteSansEcheanceEnfant,
    scolariteSansEcheanceEnfantDataImpl
} from "../../../../constants/type/ScolariteSansEcheanceEnfant";
import {Child, childDataImpl} from "../../../../constants/type/Child";
import {Employee} from "../../../../constants/type/Employee";
import moment from "moment";
import {Groupesection} from "../../../../constants/type/Groupesection";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, groupEnfantDataImpl} from "../../../../constants/type/GroupEnfant";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useFraisOneShotEnfantVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];


    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());


    const teachersListRef  = useRef<Employee[]>([]);
    const scolariteTypeMapRef  = useRef<Map<number, ScolariteType>>(new Map());
    const childrenRef = useRef<Child[]>([]);
    const childrenClasseRef : React.MutableRefObject<Child[]> = React.useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());
    const profilsRef  = useRef<Profils[]>([]);
    const groupesectionsRef = useRef<Groupesection[]>([]);
    const groupesectionMapRef  = useRef<Map<number, Groupesection>>(new Map());
    const scolariteTypeRef = useRef<ScolariteType[]>([]);
    const scolariteGroupsRef = useRef<ScolariteGroup[]>([]);
    const scolariteGroupMapRef  = useRef<Map<number, ScolariteGroup>>(new Map());
    const scolariteGroupOptionalRef = useRef<ScolariteGroup[]>([]);
    const [scolariteGroupIdListSel, setScolariteGroupIdListSel] = useState<number[]>([]);
    const scolariteAnneesRef = useRef<ScolariteAnnee[]>([]);
    const groupEnfantsRef = useRef<GroupEnfant[]>([]);


    const [scolariteAnneeSel, setScolariteAnneeSel] = useState<ScolariteAnnee>();
    const [groupSel, setGroupSel] = useState<Groupesection>();
    const [scolariteGroupSel, setScolariteGroupSel] = useState<ScolariteGroup>();
    const [numeriChange, setNumeriChange] = useState<number>(moment().valueOf());
    const [childSelected, setChildSelected] = useState<Child | null | undefined>(_.cloneDeep(childDataImpl));
    const [dataList, setDataList] = useState<ScolariteSansEcheanceEnfant[]>([]);
    const [childrenGroupList, setChildrenGroupList] = useState<GroupEnfant[]>([]);
    const [dataSel, setDataSel] = useState<ScolariteSansEcheanceEnfant>(_.cloneDeep(scolariteSansEcheanceEnfantDataImpl));
    const [dataLoading, seDataLoading] = useState<boolean>(true);
    const [totalMontantEcheancier, setTotalMontantEcheancier] = useState<number>(0);
    const [montantFrais, setMontantFrais] = useState<number>(0);
    const [groupEnfantSel, setGroupEnfantSel] = useState<GroupEnfant>(_.cloneDeep(groupEnfantDataImpl));
    const [scolariteGroupList, setScolariteGroupList] = useState<ScolariteGroup[]>([]);
    const [checkedList, setCheckedList] = useState<readonly number[]>([]);
    const [leftList, setLeftList] = useState<readonly GroupEnfant[]>([]);
    const [rightList, setRightList] = useState<readonly GroupEnfant[]>([]);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);


    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,
        dataList,setDataList,childrenGroupList,setChildrenGroupList,dataSel,setDataSel,scolariteGroupsRef,
        tableKeyValue,setTableKeyValue,scolariteTypeRef,groupesectionsRef,
        groupesectionMapRef,scolariteGroupOptionalRef,dataLoading, seDataLoading,
        totalMontantEcheancier,setTotalMontantEcheancier,profilsRef,childrenRef,teachersListRef,childrenMapRef,
        childSelected,setChildSelected,numeriChange,setNumeriChange,scolariteTypeMapRef,scolariteGroupMapRef,
        scolariteAnneesRef,scolariteGroupList,setScolariteGroupList,
        scolariteAnneeSel,setScolariteAnneeSel,groupEnfantSel,setGroupEnfantSel,childrenClasseRef,
        groupEnfantsRef,scolariteGroupIdListSel,setScolariteGroupIdListSel,dataCaches, dispatch,
        groupSel, setGroupSel, setScolariteGroupSel, scolariteGroupSel,montantFrais, setMontantFrais,
        rightList, setRightList,leftList, setLeftList,checkedList, setCheckedList,
        processingStateNormBtn, setProcessingStateNormBtn
    }
}
export default useFraisOneShotEnfantVariables;