import React, {useContext} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import messages from "../messages/general/messages";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FolderDeleteForm = (props:any) => {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;

    const onSubmit = async (data: FormData) => {
          schoolDocumentBusinessLogic.deleteFolder(
            logic,
            messages,
            logic.docFolderSel).then((response:any) => {
            schoolDocStateUpdate.openFolderDeleteDialogForm(logic, false);
            schoolDocStateUpdate.processingFolderStateNormBtn(logic, false);
        }).catch((e:any) => schoolDocStateUpdate.processingFolderStateNormBtn(logic, false));
    };

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingFolderStateNormBtn}
            title={<FormattedMessage id={messages.doc_folder_delete} />}
            open={logic.openFolderDeleteDialogForm}
            setOpen={logic.setOpenFolderDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: {xs:'100% !important'} }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.doc_folder_todelete} /> <span style={{fontWeight:'bold'}}>{logic.docFolderSel.nom}</span> ?
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default FolderDeleteForm ;