export const GENDER_EN = [
    {
        key: 'HOMME',
        name: 'Boy',
    },
    {
        key: 'FEMME',
        name: 'Girl',
    },
]

export const GENDER_FR = [
    {
        key: 'HOMME',
        name: 'Garçon',
    },
    {
        key: 'FEMME',
        name: 'Fille',
    },
];

export const GENDER_MAP = new Map<string,string>([
    ['HOMME_EN', 'Boy'],
    ['FEMME_EN', 'Girl'],
    ['HOMME_FR', 'Garçon'],
    ['FEMME_FR', 'Fille'],
]);
