import React, {useState} from 'react';
import {styled} from "@mui/styles";
import {Grid, Paper, ToggleButton} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import Button from "@mui/material/Button";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import moment from "moment";

const ProductItem:any = styled(Paper)(({ theme }:{theme:any}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    paddingRight:10,
    paddingLeft: 15,
    width:'100%',
    marginBottom: 10
}));

function ProductOrderItem(props:any) {
    const {classes, orderProduct, handleOrderDelete, marketingService} = props;
    const [selected, setSelected] = useState(orderProduct.livrer);

    const handleDelivery = async (status:boolean) => {
        setSelected(status);
        try {
            const dataToSend = {
                ...orderProduct,
                livrer: status,
            }
            const response = await marketingService.setProductDelivery(`/extra/commercevente/${orderProduct.id}`, dataToSend);

            const quantity = status ? (orderProduct?.produit?.quantite - orderProduct.quantite) : (orderProduct?.produit?.quantite + orderProduct.quantite);
            const productUpdated = {
                ...orderProduct?.produit,
                quantite: quantity > 0 ? quantity : 0,
            };
            await marketingService.updateProduct('PUT', `/extra/commerceproduit/${productUpdated?.id}`, productUpdated, null);
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <ProductItem>
            <Grid container>
                <Grid item md={11} xs={12}>

                    <div className={classes.childMiniItemName}>
                        <div>
                            {`${orderProduct.nomParent} ${orderProduct.prenomParent} `}
                            (<FormattedMessage id={messages.order_identity_product}/> {`${orderProduct.nomEnfant} ${orderProduct.prenomEnfant}`})
                        </div>
                        <div><FormattedMessage id={messages.date_order_product}/>  : {moment(orderProduct.dateVente).format("L")}</div>
                        <div><FormattedMessage id={messages.marketing_product_quantity}/> : {orderProduct.quantite}</div>
                    </div>
                </Grid>
                <Grid item md={1} xs={12}>
                   {/* New*/}
                </Grid>
                <Grid item md={12} xs={12}>
                    <FormattedMessage id={messages.marketing_product_delivery} /> :{` `}
                    <ToggleButton
                        value="check"
                        selected={selected}
                        style={selected ? {marginLeft:2, backgroundColor: 'green'} : {marginLeft:2}}
                        //color={"primary"}
                        onChange={() => {
                            handleDelivery(!selected);
                        }}
                        sx={{marginLeft:2}}
                    >
                        {selected ? <Check style={{color: 'white'}} /> : <Check />}
                    </ToggleButton>
                </Grid>
                <Grid item md={12} xs={12} sx={{textAlign: 'right', mt:2}}>
                    <Button variant={"text"} sx={{textTransform: 'none'}} onClick={() => handleOrderDelete(orderProduct)}>
                        <Close /><FormattedMessage id={messages.marketing_product_delete} />
                    </Button>
                </Grid>
            </Grid>
        </ProductItem>
    );
}

export default ProductOrderItem;
