import React, {useEffect, useState} from 'react'
import {Divider, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import KpIndicatorWidget from "../components/ui/statistic/KpIndicatorWidget";
import {AccountBalanceWallet, AutoStories, People} from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentIcon from "@mui/icons-material/Payment";
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import PapperBlock from "../components/ui/papperBlock/PapperBlock";
import TotalAmountWidget from "../components/ui/statistic/TotalAmountWidget";
import PieCustomLabel from "../components/ui/statistic/PieCustomLabel";
import AnalyticSchoolingChartWidget from "../components/ui/statistic/AnalyticSchoolingChartWidget";
import {BarSimple} from "../components/ui/statistic/BarSimple";
import {FormattedMessage} from "react-intl";
import messages from "./messages/general/messages";
import SchoolingService from "../service/SchoolingService";
import ChildrenService from "../service/ChildrenService";
import AppointmentService from "../service/AppointmentService";
import moment from "moment";
import {getMonthsListBarByDate} from "../helpers/helpers";
import PeriodService from "../service/PeriodService";
import ExchangeLibraryService from "../service/ExchangeLibraryService";

const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            flexGrow: 1,
        },
        spacingContainer: {
            marginBottom: 40
        },
        spacingItem: {
            marginBottom: '20px !important'
        },
        amountIcon: {
            color: theme.palette.common.white,
            opacity: 0.7,
            fontSize: '64px !important',
        },
        schoolingWrap: {
            overflow: 'auto',
        },
        schoolingFluid: {
            width: '100%',
        },
        spacingSchool: {
            paddingLeft: 10,
            paddingRight: 10
        },
        rootCounter: {
            flexGrow: 1,
        },
        rootCounterFull: {
            flexGrow: 1,
        },
        boxIcon: {
            '& $counterIcon': {
                fontSize: 84,
            },
        },
        counterIcon: {
            color: theme.palette.common.black,
            opacity: 0.7,
            fontSize: '84px !important',
        },
    }
})

export default function Dashboard(props:any) {
     const { theme, locale } = props;
    const activeLanguage = locale.split('-');
    const classes = useStyles();
    const [turnover, setTurnover] = useState(0);
    const [schoolingTurnover, setSchoolingTurnover] = useState(0);
    const [schoolFeesTurnover, setSchoolFeesTurnover] = useState(0);
    const [childrenList, setChildrenList] = useState([]);
    const [childrenNumber, setChildrenNumber] = useState(0);
    const [childPercentByGender, setChildPercentByGender] = useState([]);
    const [tuitionPie, setTuitionPie] = useState([]);
    const [rdvBarChartData, setRdvBarChartData] = useState<any>([]);
    const [schoolingComparativeData, setSchoolingComparativeData] = useState<any>([]);
    const [bookNumber, setBookNumber] = useState(0);
    const [countCurve, setCountCurve] = useState(0);

    const LEGEND_FR = {
        dn: 'Effectué',
        ndn: 'Non effectué'
    }

    const LEGEND_EN = {
        dn: 'Done',
        ndn: 'Not done'
    }

    const LEGEND_SCHOOLING_FR = {
        deadline: 'CA previsionnel',
        payment: 'CA réel'
    }

    const LEGEND_SCHOOLING_EN = {
        deadline: 'Forecast turnover',
        payment: 'Actual turnover'
    }

    const paymentService = SchoolingService();
    const childrenService = ChildrenService();
    const rdvService = AppointmentService();
    const periodService = PeriodService();
    const exchangeLibraryService = ExchangeLibraryService();

    useEffect(() => {
        (async () => {
            //console.log(countCurve)

            try {
                const turnoverData = await paymentService.getAllPayment();
                const turnover = paymentService.getTurnoverSum(turnoverData);
                setTurnover(turnover);

                const schoolingFees = await paymentService.getAllSchoolingFees();
                if(schoolingFees.length > 0){
                    const scolariteTypeFind = schoolingFees.find((scolariteType:any) => scolariteType.common.tag === "schooling");
                    const schoolingSum = paymentService.getSchoolingTurnoverSum(turnoverData, scolariteTypeFind.id);
                    setSchoolingTurnover(schoolingSum);

                    const schoolFeesSum = paymentService.getFeesTurnoverSum(turnoverData, scolariteTypeFind.id);
                    setSchoolFeesTurnover(schoolFeesSum);

                    //GET SUM OF SCHOOL FEES BY FEES
                    const tuitionFeesSum:any = paymentService.getTuitionFeesSum(
                        turnoverData,
                        turnover,
                        scolariteTypeFind.id,
                        schoolingSum,
                        schoolingFees,
                        activeLanguage[0]
                    );

                    setTuitionPie(tuitionFeesSum);
                }

                //EXCHANGE LIBRARY
                const bookListReq = await exchangeLibraryService.getAllBooks();
                setBookNumber(bookListReq.length);

                const resultCount = await childrenService.getAllChildrenData();
                const childrenCount = resultCount._embedded !== undefined ? resultCount._embedded.enfantDTOModelList : [];
                setChildrenNumber(childrenCount.length);
                setChildrenList(childrenCount);

                //COMPARATIVE CURVE TUITION TURNOVER
                const periodList = await periodService.getAllSchoolPeriod();
                let dataPeriodList = periodList.sort(function (a:any, b:any) {
                    return a.dateDebut - b.dateDebut;
                });

                if(dataPeriodList.length > 0){
                    const startSchoolPeriod = dataPeriodList[0];
                    const limit = dataPeriodList.length - 1;
                    const endSchoolPeriod = dataPeriodList[limit];

                    //const listPaymentDeadline:any = []; //await paymentService.getAllDeadline();
                    const listPaymentDeadline =  await paymentService.getAllChildEcheancierDeadline();

                    const paymentListReq = await paymentService.getAllPayment();
                    const schoolingFees = await paymentService.getAllSchoolingFees();
                    if(schoolingFees.length > 0) {
                        const scolariteTypeFind = schoolingFees.find((scolariteType: any) => scolariteType.common.tag === "schooling");
                        const paymentListComp = paymentListReq.filter((payment:any) => payment.scolariteTypeId === scolariteTypeFind.id);

                        const paymentListFormated = paymentService.getSchoolingPaymentDashboard(paymentListComp);
                        const comparativeData:any = paymentService.getSchoolingComparativeList(startSchoolPeriod.dateDebut, endSchoolPeriod.dateFin, listPaymentDeadline, paymentListFormated);
                        setSchoolingComparativeData(comparativeData);
                    }
                }

                const childByGender:any = childrenService.getChildrenNumberByGender(childrenCount, activeLanguage[0]);
                setChildPercentByGender(childByGender);

                const rdvListReq = await rdvService.getAllAppointment();
                const today = moment();
                const rdvListMonths = getMonthsListBarByDate(today.valueOf());
                const barChartData = rdvService.getBarChartData(rdvListReq, rdvListMonths);
                setRdvBarChartData(barChartData);

            }
            catch (error){
                console.log(error);
            }
        })()

    }, [countCurve]);
    //[countCurve, activeLanguage, childrenService, periodService, paymentService, rdvService, exchangeLibraryService]

    return (
        <div>
            <Grid container spacing={3} className={classNames(classes.root, classes.spacingContainer)}>
                <Grid item xs={12}>
                    <div className={classes.rootCounterFull}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="primary-dark"
                                    number={new Intl.NumberFormat('fr-FR').format(turnover)}
                                    devise="FCFA"
                                    moment={<FormattedMessage id={messages.until_today} />}
                                    title={<FormattedMessage id={messages.schooling_dashbord} />}
                                >
                                    <AccountBalanceWallet className={classes.counterIcon}  />
                                </KpIndicatorWidget>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="secondary-main"
                                    number={new Intl.NumberFormat('fr-FR').format(bookNumber)}
                                    moment={<FormattedMessage id={messages.this_year_dashboard} />}
                                    title={<FormattedMessage id={messages.exchange_book_list} />}
                                >
                                    <AutoStories className={classes.counterIcon} />
                                </KpIndicatorWidget>
                            </Grid>
                            {/*<Grid item xs={12} md={3}>
                                <KpIndicatorWidget
                                    color="secondary-main"
                                    number="0"
                                    moment={<FormattedMessage id={messages.this_week_dashboard} />}
                                    title={<FormattedMessage id={messages.allergies} />}
                                >
                                    <EventNote className={classes.counterIcon} />
                                </KpIndicatorWidget>
                            </Grid>*/}
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="secondary-main"
                                    number={new Intl.NumberFormat('fr-FR').format(childrenNumber)}
                                    moment={<FormattedMessage id={messages.this_year_dashboard} />}
                                    title={<FormattedMessage id={messages.children_dashboard} />}
                                >
                                    <People className={classes.counterIcon} />
                                </KpIndicatorWidget>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {/* 2nd Section */}
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} className={classes.spacingItem}>
                    <Grid item md={12} xs={12}>
                        <AnalyticSchoolingChartWidget
                            data={schoolingComparativeData}
                            legend={activeLanguage[0] === 'en' ? LEGEND_SCHOOLING_EN : LEGEND_SCHOOLING_FR}
                            countCurve={countCurve}
                            width={"100%"}
                            height={"100%"}
                            setCountCurve={setCountCurve}
                        />
                    </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                    <PapperBlock whiteBg noMargin overflowX title={<FormattedMessage id={messages.title_amount_box} />} icon="payment" desc="">
                        <Grid item md={12} xs={12} sm={12}>
                            <div className={classes.spacingSchool}>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} xs={12} md={12}>
                                        <TotalAmountWidget
                                            color="secondary-main"
                                            title={<FormattedMessage id={messages.total_amount_schooling_and_fees} />}
                                            amount={new Intl.NumberFormat('fr-FR').format(turnover)}
                                            devise="FCFA"
                                        >
                                            <AccountBalanceWalletIcon className={classes.amountIcon} />
                                        </TotalAmountWidget>
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginBottom: '20px' }} />

                                <Grid container spacing={3}>
                                    <Grid item sm={12} xs={12}>
                                        <TotalAmountWidget
                                            color="fourth-main"
                                            title={<FormattedMessage id={messages.total_amount_schooling} />}
                                            amount={new Intl.NumberFormat('fr-FR').format(schoolingTurnover)}
                                            devise="FCFA"
                                        >
                                            <PaymentIcon className={classes.amountIcon} />
                                        </TotalAmountWidget>
                                    </Grid>
                                </Grid>
                                <Divider style={{marginBottom: '20px'}} />

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TotalAmountWidget
                                            color="thirdly-main"
                                            title={<FormattedMessage id={messages.total_amount_additionalfees} />}
                                            amount={new Intl.NumberFormat('fr-FR').format(schoolFeesTurnover)}
                                            devise="FCFA"
                                        >
                                            <HorizontalSplitIcon className={classes.amountIcon} />
                                        </TotalAmountWidget>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </PapperBlock>
                </Grid>

                <Grid item md={4} xs={12}>
                    <PapperBlock whiteBg noMargin title={<FormattedMessage id={messages.payment_tuition_type} />} icon="group" desc="">
                        <Grid item md={12} xs={12}>
                            <div className={classes.spacingSchool}>
                               {/* <PieCustomLabel data={childPercentByGender}  />*/}
                                <PieCustomLabel data={tuitionPie} />
                            </div>
                        </Grid>
                    </PapperBlock>
                </Grid>

                <Grid item md={4} xs={12}>
                    <PapperBlock whiteBg noMargin title={<FormattedMessage id={messages.appointment_dashboard_box} />} icon="timeline" desc="">
                        <Grid item md={12} xs={12}>
                            <div className={classes.spacingSchool}>
                                <BarSimple
                                    data={rdvBarChartData}
                                    legend={activeLanguage[0] === 'en' ? LEGEND_EN : LEGEND_FR}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </Grid>
                    </PapperBlock>
                </Grid>


            </Grid>

        </div>
    )
}
