import React, {useContext} from "react";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../general/messages";
import {Box, Grid, MenuItem, Select, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import InputField from "../../../components/ui/form/InputField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import {MessageCenterContext} from "./MessageCenter";
import spaceRoomBusinessLogic from "./businessLogic/SpaceRoomBusinessLogic";
import {isPositiveValue} from "../../../helpers/helpers";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const NewTicketForm = (props:any) => {
    const logicRef: any = useContext(MessageCenterContext);
    const styleClasses = logicRef.styleClasses ;

    const handleChildrenSelected = (event: any, object:any) => spaceRoomBusinessLogic.handleChildrenSelected(logicRef, event);
    const onSubmit = async () => {/*
        const lang = logicRef.activeLanguageRef.current[0] ;
        if(_.isEmpty(logicRef.newChipSpaces)){
            logicRef.snackbarShowMessage(lang === "en" ? "Please select a child" : "Veuillez choisir un enfant", 'error');
            return ;
        }
        if(_.size(logicRef.newTicketSubject) > 1){
            logicRef.snackbarShowMessage(lang === "en" ? "Please write the subject of the ticket" : "Veuillez renseigner le titre du ticket", 'error');
            return ;
        }*/
        spaceRoomBusinessLogic.sendMessageForNewSpaces(logicRef,'');
    }
    React.useEffect(() => {
        if(logicRef.openNewTicketDialog){
            spaceRoomBusinessLogic.updateChildrenSelected(logicRef, []);
            logicRef.setNewTicketSubject('');
        } else {
            logicRef.setNewMessage(false);
            return ;
        }
    }, [logicRef.openFolderEditDialogForm]);
    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logicRef.theme}
            width={true}
            savingBtn={true}
            savingStateBtn={logicRef.processingFolderStateNormBtn}
            title={<FormattedMessage id={messages.compose_ticket} />}
            open={logicRef.openNewTicketDialog}
            setOpen={logicRef.setOpenNewTicketDialog}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: {xs:'100% !important'} }}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <div
                                className={logicRef.chatStyles.inputField}
                                style={{
                                    display: logicRef.hideClasseRoomSelectRef.current ? 'none':'flex',
                                    alignItems:'right',
                                    justifyContent:'right' }}>
                                <TextField
                                    select
                                    label={<FormattedMessage id={messages.dialog_input_classroom} />}
                                    sx={{ width: { xs: '100% !important'}, float:'right', marginRight:2 }}
                                    color={"secondary"}
                                    value={logicRef.classeRoomSelected.id ?? 0}
                                    onChange={(event: Object) => spaceRoomBusinessLogic.handleClasseRoomChange(logicRef, event)}
                                >
                                    <MenuItem key="0" value="0" sx={{fontWeight:400}}>All</MenuItem>
                                    {
                                        logicRef.classeRoomRef.current.map( (data:any) => (
                                            // @ts-ignore
                                            <MenuItem key={data.id} value={data.id} sx={{fontWeight:400}}>
                                                {data.nom}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl sx={{xs: 'block', width:'96%' }}>
                                <InputLabel id="send_to">{<FormattedMessage id={messages.send_to} />}</InputLabel>
                                <Select
                                    required
                                    labelId="send_to"
                                    id="send_to"
                                    multiple
                                    size="small"
                                    disabled={!isPositiveValue(logicRef.classeRoomSelected.id)}
                                    value={logicRef.newChipSpaces}
                                    onChange={handleChildrenSelected}
                                    input={<OutlinedInput label="Chip" />}
                                    renderValue={(selected:any) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value:any) =>(
                                                <Chip key={value.enfantParentIdForm} label={`${value.enfantNom}. Parent ${value.parentNom}`} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                    sx={{ fontWeight:400, p:0 }}
                                >
                                    {logicRef.childrenSelectList}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div
                                className={logicRef.chatStyles.inputField}
                                style={{display:"flex", alignItems:'right', justifyContent:'right' }}>
                                <InputField
                                    required
                                    label={<FormattedMessage id={messages.object_message} />}
                                    size="small"
                                    color={"secondary"}
                                    width={'100% !important'}
                                    inputProps={ { 'aria-label': 'description' }}
                                    value={logicRef.newTicketSubject}
                                    onChange={(event: any) => spaceRoomBusinessLogic.handleNewTicketSubjectChange(logicRef, event)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
        </DialogFormKiller>
    );
}

export default NewTicketForm ;