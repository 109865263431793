import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {ScolariteType} from "../../../../constants/type/ScolariteType";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {Profils} from "../../../../constants/type/Profils";
import {
    echeancierEnfantCustomOptions,
    ScolariteEcheancierEnfant,
    scolariteEcheancierEnfantDataImpl
} from "../../../../constants/type/ScolariteEcheancierEnfant";
import {Child, childDataImpl} from "../../../../constants/type/Child";
import {Employee} from "../../../../constants/type/Employee";
import moment from "moment";
import {Groupesection} from "../../../../constants/type/Groupesection";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, groupEnfantDataImpl} from "../../../../constants/type/GroupEnfant";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useScolariteEcheancierEnfantVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'ScolariteEcheancierEnfant', updateId:1323, customOptions:echeancierEnfantCustomOptions}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<ScolariteEcheancierEnfant>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openGenerateDialogForm, setOpenGenerateDialogForm] = useState<boolean>(false);
    const [openEditDetailsDialogForm, setOpenEditDetailsDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());


    const teachersListRef = useRef<Employee[]>([]);
    const scolariteTypeMapRef  = useRef<Map<number, ScolariteType>>(new Map());
    const childrenRef = useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());
    const profilsRef  = useRef<Profils[]>([]);
    const groupesectionsRef = useRef<Groupesection[]>([]);
    const groupesectionMapRef  = useRef<Map<number, Groupesection>>(new Map());
    const scolariteTypeRef = useRef<ScolariteType[]>([]);
    const scolariteGroupsRef = useRef<ScolariteGroup[]>([]);
    const scolariteGroupsMapRef = useRef<Map<number, ScolariteGroup>>(new Map());
    const scolariteFraisGroupOptionalRef = useRef<ScolariteGroup[]>([]);
    const scolariteAnneesRef = useRef<ScolariteAnnee[]>([]);
    const groupEnfantsRef = useRef<GroupEnfant[]>([]);

    const [numeriChange, setNumeriChange] = useState<number>(moment().valueOf());
    const [childSelected, setChildSelected] = useState<Child | null | undefined>(_.cloneDeep(childDataImpl));
    const [dataList, setDataList] = useState<ScolariteEcheancierEnfant[]>([]);
    const [dataListSelected, setDataListSelected] = useState<ScolariteEcheancierEnfant[]>([]);
    const [dataSel, setDataSel] = useState<ScolariteEcheancierEnfant>(_.cloneDeep(scolariteEcheancierEnfantDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);
    const [totalMontantEcheancier, setTotalMontantEcheancier] = useState<number>(0);
    const [scolariteGroupIdListSel, setScolariteGroupIdListSel] = useState<number[]>([]);
    const [idScolariteAnneeSel, setIdScolariteAnneeSel] = useState<number[]>([]);
    const [groupEnfantSel, setGroupEnfantSel] = useState<GroupEnfant>(_.cloneDeep(groupEnfantDataImpl));
    const [scolariteGroupList, setScolariteGroupList] = useState<ScolariteGroup[]>([]);
    const [scolaEcheancierDetails, setScolaEcheancierDetails] = useState<ScolariteEcheancierEnfant[]>([]);


    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,scolariteGroupsRef,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,scolariteTypeRef,groupesectionsRef,
        groupesectionMapRef,openEditDetailsDialogForm,setOpenEditDetailsDialogForm,scolariteFraisGroupOptionalRef,
        totalMontantEcheancier,setTotalMontantEcheancier,profilsRef,childrenRef,teachersListRef,childrenMapRef,
        scolariteGroupIdListSel,setScolariteGroupIdListSel,scolariteGroupsMapRef,idScolariteAnneeSel,setIdScolariteAnneeSel,
        childSelected,setChildSelected,numeriChange,setNumeriChange,scolariteAnneesRef,groupEnfantsRef,
        scolariteTypeMapRef,openGenerateDialogForm,setOpenGenerateDialogForm,dataCaches, dispatch,
        groupEnfantSel, setGroupEnfantSel,scolariteGroupList,setScolariteGroupList,
        scolaEcheancierDetails, setScolaEcheancierDetails,
        tableConfigRef,
        ...logicMRT
    }
}
export default useScolariteEcheancierEnfantVariables;