import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import moment from "moment";
import {Parent, parentDataImpl} from "./Parent";
import {Livre, livreDataImpl} from "./Livre";
import {Child, childDataImpl} from "./Child";
import messages from "../../page/messages/general/messages";
import {FormattedMessage} from "react-intl";
import React from "react";
import {Badge} from "@mui/material";
import {isPositiveValue} from "../../helpers/helpers";
import {Employee, employeeDataImpl} from "./Employee";


export const LIVREEMPRUNT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:LivreEmprunt) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(livreEmpruntDataImpl);
        item = {...itemInit, ...item};

        item.dateDemandeForm        =  isPositiveValue(item.dateDemande) ? moment(item.dateDemande) : null;
        item.dateEmpruntForm        =  isPositiveValue(item.dateEmprunt) ? moment(item.dateEmprunt) : null;
        item.dateRetourForm         =  isPositiveValue(item.dateRetour) ? moment(item.dateRetour) : null;
        item.deadlineRetourForm     =  isPositiveValue(item.deadlineRetour) ? moment(item.deadlineRetour) : null;
        item.confirmEmpruntForm     =  isPositiveValue(item.dateEmprunt);
        item.confirmRetourForm      =  isPositiveValue(item.dateRetour);
        // @ts-ignore
        item.horsdelaiForm          =  _.isNull(item.dateRetour) && isPositiveValue(item.dateEmprunt) && isPositiveValue(item.deadlineRetour) && item.deadlineRetour < moment().valueOf();
        item.lireHorsdelaiForm      =  item.horsdelaiForm ? <Badge badgeContent={<FormattedMessage id={messages.oui} />} color="error" /> : `-`;
        item.lireDateDemandeForm    =  _.isObject(item.dateDemandeForm) ? item.dateDemandeForm?.clone().format('L') : `-`;
        item.lireDateEmpruntForm    =  _.isObject(item.dateEmpruntForm) ? item.dateEmpruntForm?.clone().format('L') : `-`;
        item.lireDateRetourForm     =  _.isObject(item.dateRetourForm) ? item.dateRetourForm?.clone().format('L') : `-`;
        item.lireDeadlineRetourForm =  _.isObject(item.deadlineRetourForm) ? item.deadlineRetourForm?.clone().format('L') : `-`;
        item.livre  = _.find(logic.livresRef?.current, {id:item.livreId}) ?? _.cloneDeep(livreDataImpl);

        if(isPositiveValue(item.enfantId)){
            const enfant: Child = _.find(logic.childrenRef.current, {id:item.enfantId}) ;
            if(_.isObject(enfant)){
                item.childForm = enfant;
                // @ts-ignore
                item.nomenfantForm =  enfant.childNomPrenomForm;
                item.emprunteurForm = `(p) ${item.nomenfantForm}`;
                // @ts-ignore
                enfant.enfantParents?.forEach(enfantparent => {
                    if(_.isNull(enfantparent) || _.isUndefined(enfantparent)){
                        return  ;
                    }
                    if(enfantparent.parent?.id === item?.parentId){
                        // @ts-ignore
                        item.parentForm   = enfantparent.parent ;
                        item.nomparentForm = `${item.parentForm.person.nom} ${item.parentForm.person.prenom}`;
                        item.emprunteurForm = `(p) ${item.nomparentForm}`;
                    }
                });
            }
        }

        if(isPositiveValue(item.employeeId)){
            const employee: Employee = _.find(logic.employeesRef.current, {id:item.employeeId})  ;
            if(_.isObject(employee)){
                item.employeeForm   = employee;
                // @ts-ignore
                item.emprunteurForm = employee.nameForm;
            }

        }


        return item ;
    },
    convertToBackEnd: (logic:any, item:LivreEmprunt) => {
        // @ts-ignore
        item.dateDemande    = item.dateDemandeForm?.valueOf();
        item.dateEmprunt    = item.confirmEmpruntForm ? item.dateEmpruntForm?.valueOf() : null;
        item.deadlineRetour = item.deadlineRetourForm?.valueOf();
        item.dateRetour     = item.confirmRetourForm ? item.dateRetourForm?.valueOf(): null;
        return item ;
    },
};

export  const livreEmpruntDataImpl: LivreEmprunt = {
    id: null,
    dateEmprunt:0,
    dateRetour: 0,
    dateDemande: 0,
    deadlineRetour: 0,
    parentId: 0,
    enfantId: 0,
    livreId: 0,
    employeeId: 0,
    quantite:1,
    nomparentForm: ``,
    nomenfantForm: ``,
    emprunteurForm:``,
    lireDateEmpruntForm: ``,
    lireDateDemandeForm: ``,
    lireDateRetourForm: ``,
    lireDeadlineRetourForm: ``,
    lireHorsdelaiForm: ``,
    livre: _.cloneDeep(livreDataImpl),
    dateEmpruntForm: moment(),
    dateRetourForm: moment(),
    dateDemandeForm: moment(),
    deadlineRetourForm: moment(),
    employeeForm: _.cloneDeep(employeeDataImpl),
    parentForm: _.cloneDeep(parentDataImpl),
    childForm: _.cloneDeep(childDataImpl),
    isbn: ``,
    horsdelaiForm: false,
    confirmEmpruntForm: false,
    confirmRetourForm: false,
    common:  _.cloneDeep(commonDataImp),
}

export const livreEmpruntSchema = yup.object().shape({
    id: yup.number().nullable().notRequired(),
    dateEmprunt: yup.number().nullable().notRequired(),
    dateDemande: yup.number().nullable().notRequired(),
    dateRetour: yup.number().nullable().notRequired(),
    deadlineRetour: yup.number().nullable().notRequired(),
    parentId: yup.number().notRequired(),
    enfantId: yup.number().notRequired(),
    livreId: yup.number().notRequired(),
    employeeId: yup.number().notRequired(),
    quantite: yup.number().notRequired(),
    livre: yup.mixed<Livre>().notRequired(),
    emprunteurForm: yup.string().notRequired(),
    nomparentForm: yup.string().notRequired(),
    nomenfantForm: yup.string().notRequired(),
    isbn: yup.string().notRequired(),
    lireHorsdelaiForm: yup.object().notRequired(),
    lireDateEmpruntForm: yup.string().nullable().default(``).notRequired(),
    lireDateDemandeForm: yup.string().nullable().default(``).notRequired(),
    lireDateRetourForm: yup.string().nullable().default(``).notRequired(),
    lireDeadlineRetourForm: yup.string().nullable().default(``).notRequired(),
    dateEmpruntForm: yup.mixed<moment.Moment>().default(moment()).nullable().notRequired(),
    dateDemandeForm: yup.mixed<moment.Moment>().default(moment()).nullable().notRequired(),
    dateRetourForm: yup.mixed<moment.Moment>().nullable().notRequired(),
    deadlineRetourForm: yup.mixed<moment.Moment>().nullable().notRequired(),
    parentForm: yup.mixed<Parent>().notRequired(),
    employeeForm: yup.mixed<Employee>().notRequired(),
    childForm: yup.mixed<Child>().notRequired(),
    horsdelaiForm: yup.boolean().default(false).notRequired(),
    confirmEmpruntForm: yup.boolean().default(false).notRequired(),
    confirmRetourForm: yup.boolean().default(false).notRequired(),
    common:  yup.mixed<Common>(),
});


export const columnsLivreEmprunt = (props:any) => {
    const {intl} = props ;
    return [
        // @ts-ignore
        {
            accessorKey: 'livre.photoForm',
            header: intl.formatMessage({id: messages.table_h_photo}),
            size: 60,
            grow:false,
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', alignItems:"center", width:'60'}}>
                    <img
                        loading="lazy"
                        width="48"
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        style={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: 'livre.nom',
            header: intl.formatMessage({id: messages.table_h_livre}),
            size: 140,
        },
        {
            accessorKey: 'emprunteurForm',
            header: intl.formatMessage({id: messages.emprunteur}),
        },
        {
            accessorKey: 'quantite',
            header: intl.formatMessage({id: messages.table_h_quantite}),
            size: 110,
        },
        {
            accessorKey: 'lireDateEmpruntForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_date}),
            size: 120,
        },
        {
            accessorKey: 'lireDeadlineRetourForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_deadline}),
            size: 120,
        },
        {
            accessorKey: 'lireDateRetourForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_retour}),
            size: 120,
        },
        {
            accessorKey: 'lireHorsdelaiForm',
            header: intl.formatMessage({id: messages.table_h_horsdelai}),
            size: 90,
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', alignItems:"center", width:'80'}}>
                    {
                        // @ts-ignore
                        cell.getValue<any>()
                    }
                </div>
            ),
        },

    ];
} ;


export const columnsListDesEmprunts = (props:any) => {
    const {intl} = props ;
    return [
        // @ts-ignore
        {
            accessorKey: 'livre.photoForm',
            header: intl.formatMessage({id: messages.table_h_photo}),
            size: 60,
            grow:false,
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', alignItems:"center", width:'60'}}>
                    <img
                        loading="lazy"
                        width="48"
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        style={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: 'livre.nom',
            header: intl.formatMessage({id: messages.table_h_livre}),
        },
        {
            accessorKey: 'emprunteurForm',
            header: intl.formatMessage({id: messages.emprunteur}),
        },
        {
            accessorKey: 'lireDateDemandeForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_datedemande}),
            size: 110,
        },
        {
            accessorKey: 'lireDateEmpruntForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_date}),
            size: 120,
        },
        {
            accessorKey: 'lireDeadlineRetourForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_deadline}),
            size: 120,
        },
        {
            accessorKey: 'lireDateRetourForm',
            header: intl.formatMessage({id: messages.table_h_emprunt_retour}),
            size: 120,
        },
        {
            accessorKey: 'lireHorsdelaiForm',
            header: intl.formatMessage({id: messages.table_h_horsdelai}),
            size: 120,
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', alignItems:"center", width:'80'}}>
                    {
                        // @ts-ignore
                        cell.getValue<any>()
                    }
                </div>
            ),
        },

    ];
} ;

export type LivreEmprunt = yup.InferType<typeof livreEmpruntSchema>;