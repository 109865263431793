import React, {useEffect, useRef} from 'react';
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import moment from "moment/moment";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import useGroupEnfantVariables from "./businesslogic/GroupEnfantVariables";
import groupEnfantBusinessLogic from "./businesslogic/GroupEnfantBusinessLogic";
import GroupEnfantEnfantList from "./GroupEnfantList";
import GroupEnfantEnfantEditForm from "./GroupEnfantEditForm";
import GroupEnfantEnfantDeleteForm from "./GroupEnfantDeleteForm";
import {groupEnfantDataImpl, groupEnfantSchema} from "../../../constants/type/GroupEnfant";
import GroupEnfantAddForm from "./GroupEnfantAddForm";
import Box from "@mui/material/Box";
import SchoolingSubMenu from "../SchoolingSubMenu";

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = useRef<any>(useForm({
        defaultValues:_.cloneDeep(groupEnfantDataImpl),
        // @ts-ignore
        resolver: yupResolver(groupEnfantSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useGroupEnfantVariables({styleClasses, ...props})
    }
};

export const GroupEnfantContext = React.createContext({});

function GroupEnfantPage(props:any) {
    const logic = useBusinessLogic(props);
    const styleClasses = logic.styleClasses;

    useEffect(() => {
        moment.locale(logic.activeLang);
        groupEnfantBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);



    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="groupsenfants"/>
            </Box>

            <GroupEnfantContext.Provider value={logic}>
                <GroupEnfantEnfantList />
            </GroupEnfantContext.Provider>
            <GroupEnfantContext.Provider value={logic}>
                <GroupEnfantAddForm />
            </GroupEnfantContext.Provider>
            <GroupEnfantContext.Provider value={logic}>
                <GroupEnfantEnfantEditForm />
            </GroupEnfantContext.Provider>
            <GroupEnfantContext.Provider value={logic}>
                <GroupEnfantEnfantDeleteForm />
            </GroupEnfantContext.Provider>
        </div>
    );
}

export default withSnackbar(GroupEnfantPage);
