import React, {useContext} from "react";
import _ from "lodash";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import {SecuriteParentContext} from "./SecuriteParent";
import messages from "../../messages/general/messages";
import {CompteStatut, compteStatutDataImpl} from "../../../constants/type/CompteStatut";
import {AccountEtat} from "../../../constants/type/AccountEtat";
import securiteParentBusinessLogic from "./businesslogic/SecuriteParentBusinessLogic";
import {RoleUser} from "../../../constants/type/RoleUser";


const DialogCompteParentEditForm = (props:any) => {
    const logic: any = useContext(SecuriteParentContext);
    const styleClasses = logic.styleClasses ;

    const genererCompte = () => {

        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }


        const compteList: CompteStatut[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            const compte = _.cloneDeep(compteStatutDataImpl);
            compte.userId = null ;
            compte.username = null ;
            compte.email = item.person.email ;
            compte.personNom = item.person.nom ;
            compte.personPrenom = item.person.prenom ;
            compte.personId = item.person.id ;
            compte.sexe = item.person.sexe ;
            compte.roleUser = RoleUser.PARENT ;
            compte.accountStatus = AccountEtat.OK ;
            compteList.push(compte);
        });
        securiteParentBusinessLogic.generateAccounts(logic, messages, compteList);
    }

    const activerCompte = () => {

        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }

        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userId) === false){
                return ;
            }
            compteList.push(item.userId);
        })
        securiteParentBusinessLogic.activerAccounts(logic, messages, compteList);
    }

    const suspendreCompte = () => {
        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }

        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userId) === false){
                return ;
            }
            compteList.push(item.userId);
        })
        securiteParentBusinessLogic.suspendreAccounts(logic, messages, compteList);
    }

    const supprimerCompte = () => {
        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }

        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userId) === false){
                return ;
            }
            compteList.push(item.userId);
        })
        securiteParentBusinessLogic.supprimerAccounts(logic, messages, compteList);
    }
    const onSubmit = async () => {
        switch (logic.confirmMessage){
            case 'generer_compte_question':
                genererCompte();
                break;

            case 'activer_compte_question':
                activerCompte();
                break;

            case 'suspendre_compte_question':
                suspendreCompte();
                break;

            case 'supprimer_compte_question':
                supprimerCompte();
                break;
        }
    };

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.gestion_compte} />}
            open={logic.openConfirmDialogForm}
            setOpen={logic.setConfirmDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={() => onSubmit()}>
                <Box  sx={{ width: {xs:'100% !important'} }}>
                    <div className={styleClasses?.inputField} style={{textAlign:'center', color:'black'}}>
                        {<FormattedMessage id={messages[logic.confirmMessage]} />}
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default DialogCompteParentEditForm ;