import React from 'react';
import SondageList from "./SondageList";

// @ts-ignore
import {Client} from '@stomp/stompjs';
import sondageStyles from "./jss/sondaget-jss";
import msgCenterStyles from "./jss/message-jss";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import useMessageCenterVariables from "./businessLogic/SondageVariables";
import sondageListStyles from "./jss/conversation-jss";
import sondageHeaderStyles from "./jss/sondageheader-jss";
import SondageResponsesSpace from "./SondageResponsesSpace";
import sondageBusinessLogic from "./businessLogic/SondageBusinessLogic";
import SondageEditForm from "./SondageEditForm";


export const SondageCenterContext = React.createContext({});


const useBusinessLogic = (props: any): any => {
    return {
        theme: props.theme,
        msgCenterStyles: msgCenterStyles(),
        spaceListStyles: sondageListStyles(),
        chatStyles: sondageStyles(),
        chatHeaderStyles: sondageHeaderStyles(),
        ...useMessageCenterVariables(props)
    };
}
function SondageCenter(props:any) {
    const logic = useBusinessLogic(props);

    React.useEffect(() => {
        if(logic.pageForm){
            sondageBusinessLogic.initPostConstruct(logic);
        }
    }, [logic.pageForm]);

    React.useEffect(() => {
        sondageBusinessLogic.initPostConstruct(logic);
    }, []);

    return (
        <div className={logic.msgCenterStyles.root}>
            <SondageCenterContext.Provider value={logic}>
                <SondageList />
                <SondageResponsesSpace />
            </SondageCenterContext.Provider>
            <SondageCenterContext.Provider value={logic}>
                <SondageEditForm />
            </SondageCenterContext.Provider>
        </div>
    )
}

export default withSnackbar(SondageCenter);
