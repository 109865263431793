import React, {useState} from "react";
import styles from "./form/user-jss";
import {makeStyles} from "@mui/styles";
import {BASEURL, IMS_WEBSITE} from "../../config/AppUrl";
import Paper from "@mui/material/Paper";
import {Button, InputAdornment, InputLabel, OutlinedInput, TextField, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {TLoginFormProps} from "../../constants/type/connection/connectionType";

const useStyles = makeStyles(styles);

function LoginForm({ errorMessage, sending, onSubmit, register, errors }: TLoginFormProps) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: any) => event.preventDefault();

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.containerSide}>
                    <div className={classes.opening}>
                        <div className={classes.sideFormWrap} style={{position: 'relative'}}>
                            <div style={{textAlign: "center"}} className={classes.space}>
                                <a href={`${IMS_WEBSITE}`} target="_blank" rel="noreferrer">
                                    <img src={`${BASEURL}/images/logo.png`} alt="Logo IMS" width="180"/>
                                </a>
                            </div>
                            <Paper className={classes.sideWrap}>
                                <div className={classes.topBar}>
                                    <Typography variant="h4" className={classes.title}>
                                        <FormattedMessage id={messages.title_form_login}/>
                                    </Typography>
                                </div>
                                <section className={classes.pageFormSideWrap}>
                                    <form onSubmit={onSubmit} noValidate>
                                        <div className={classes.errorMessage} style={{textAlign: 'center'}}>
                                            {errorMessage}
                                        </div>
                                        <div className={classes.space}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    label={<FormattedMessage id={messages.mail_number_form_login}/>}
                                                    type="text"
                                                    variant="outlined"
                                                    {...register ? register("username", {
                                                        required: "Username is required"
                                                    }) : ""}
                                                    error={errors && !!errors.username?.message}
                                                />
                                                <div className={classes.errorHelper}>
                                                    {errors && errors.username?.message as string}
                                                </div>
                                            </FormControl>
                                        </div>
                                        <div className={classes.space}>
                                            <FormControl className={classes.formControl} required={true}>
                                                <InputLabel htmlFor="outlined-adornment-password" color={errors && !!errors.password?.message ? 'error' : "primary"}>
                                                    <FormattedMessage id={messages.password_form_login} />
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                sx={{mt: 0}}
                                                            >
                                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label={<FormattedMessage id={messages.password_form_login}/>}
                                                    {...register ? register("password", {
                                                        required: "Password is required"
                                                    }) : ""}
                                                    error={errors && !!errors.password?.message}
                                                />
                                                <div className={classes.errorHelper}>{errors && errors.password?.message  as string}</div>
                                            </FormControl>
                                        </div>
                                        {/*<div className={classes.space}>
                                            <div className={classes.optArea}>
                                                <div className={classes.label}> </div>
                                                <Link
                                                    className={classes.buttonLink}
                                                    href="/reset-password"
                                                    underline="none"
                                                    color="inherit"
                                                >
                                                    <FormattedMessage id={messages.forgot_password_form_login}/>
                                                </Link>
                                            </div>
                                        </div>*/}
                                        <div className={classes.btnArea}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={sending}
                                                fullWidth
                                                color="primary"
                                                size="large"
                                            >
                                                <FormattedMessage id={messages.connection_form_login}/>
                                            </Button>
                                        </div>
                                    </form>

                                </section>

                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;
