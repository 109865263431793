import React, {useContext, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import _, {isEmpty} from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, InputAdornment, List, Typography} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {MenuDataToDisplay} from "../../constants/type/MenuPlatCantine";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import {BibliothequeContext} from "../bibliotheque/livre/Bibliotheque";
import {ChilDayContext} from "./ChildDayAtIms";
import messages from "../messages/general/messages";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import InputField from "../../components/ui/form/InputField";
import moment from "moment/moment";
import {CONSTANT, IMGS} from "../../constants";
import {MenuCantineJour, menuCantineJourDataImpl} from "../../constants/type/MenuCantineJour";
import {request} from "../../helpers/axios_helpers";
import {getErrorMessage} from "../../helpers/errorManager";
import {isNotObject, isPositiveValue} from "../../helpers/helpers";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {BASEURL_RESOURCES} from "../../config/AppUrl";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DialogMenuPlatChoiceForm = (props:any) => {
    const logic: any = useContext(ChilDayContext);
    const styleClasses = logic.styleClasses ;
    const intl = useIntl() ;


    const onSubmit = async () => {
        const lang = logic.activeLang ;
        if(!isPositiveValue(logic.idNewMenuCantineSel)){
            logic.snackbarShowMessage(lang === "en" ? "Please choose a menu" : "Veuillez choisir un menu", 'error');
            return ;
        }
        if(!isPositiveValue(logic.menuJourData?.id)){
            logic.snackbarShowMessage(lang === "en" ? "Please choose a date" : "Veuillez selectionner une date", 'error');
            return ;
        }

        if(!isPositiveValue(logic.menuCantineDataRef.current?.id)){
            logic.snackbarShowMessage(lang === "en" ? "Aucun menu programmé ce jour" : "No menu scheduled this day", 'error');
            return ;
        }

        logic.setProcessingLivreStateNormBtn(true);
        const menucantinejourUpdate = {
            menuJourId : logic.menuJourData?.id,
            menuCantineIdOld : logic.menuCantineDataRef.current?.id,
            menuCantineIdNew : logic.idNewMenuCantineSel,
        };

        request('PUT', `/extra/menujour/updatemenufromimsday`, menucantinejourUpdate).then((response) => {
            logic.updateCantineMenuDay(logic.imsDay);
            logic.setProcessingLivreStateNormBtn(false);
            logic.setOpenMenuEditDialogForm(false);
        }).catch((error) => {
            logic.setProcessingLivreStateNormBtn(false);
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }


    const onSetMenuCantine = async (itemenuId:number) => {
        logic.setIdNewMenuCantineSel(itemenuId);
        const newlist = _.map(logic.menuDataToDisplayListRef.current, item => {
            item.isMenuOfTheDay = itemenuId === item.id ;
            return item;
        });
        logic.menuDataToDisplayListRef.current = _.reverse(_.sortBy(newlist, ['isMenuOfTheDay']));
        logic.setMenuDataToDisplayList(logic.menuDataToDisplayListRef.current);
    };

    const placeholder = logic.activeLang === 'en' ? 'Search a dish' : 'Recherche de plat';

    const handleSearchMenu = (event:any) => {
        const searchTerm = event.target.value !== undefined ? event.target.value : null;
        logic.setSearchMenuTerm(searchTerm);
        let listMenu:MenuDataToDisplay[] = _.cloneDeep(logic.menuDataToDisplayListRef.current);
        if(searchTerm.length > 1){
            const tofind = _.lowerCase(searchTerm) ;
            listMenu = _.filter(logic.menuDataToDisplayListRef.current, item => {
                return _.includes(item.nom?.toLowerCase(), tofind) ||
                    _.includes(item.starterDish?.toLowerCase(), tofind) ||
                    _.includes(item.dish?.toLowerCase(), tofind) ||
                    _.includes(item.dessert?.toLowerCase(), tofind)
            });

        }
        logic.setMenuDataToDisplayList(listMenu);
    }

    const menuContentDisplay = useMemo<any>(() => {
        return _.map(logic.menuDataToDisplayList, itemenu => {
            const labelId = `checkbox-list-label-${itemenu.id}`;
            const couleur = itemenu.isMenuOfTheDay? '#E3F2FD' : '#fff' ;
            const checkedStatus = itemenu.isMenuOfTheDay ? <DoneAllIcon sx={{color: 'green', marginTop: 10, marginLeft: 1, fontSize: 20}}/> : <></>;
            return (
                <ListItem
                    key={itemenu.id}
                    disablePadding
                    sx={{border:'1px solid #cecece', mb:2, borderRadius:2, overflow:"auto", backgroundColor:couleur}}
                >
                    <ListItemButton onClick={(e: any) => onSetMenuCantine(itemenu.id)}>
                        <div style={{display: 'flex', width: '90%'}}>
                            <Box className={styleClasses.toMenuBoxImg}>
                                <img
                                    className={styleClasses.imgItem}
                                    loading="lazy"
                                    src={itemenu.photo}
                                />
                            </Box>
                            <div className={styleClasses.toMenuContentCanteen}>
                                <Typography>{itemenu.nom}</Typography>
                                <div style={{marginTop: 10}}>
                                    <i><FormattedMessage
                                        id={messages.starter_menu_year}/> :</i> {itemenu.starterDish}<br/><br/>
                                    <i><FormattedMessage id={messages.dish_menu_year}/> :</i> {itemenu.dish}<br/><br/>
                                    <i><FormattedMessage id={messages.dessert_menu_year}/> :</i> {itemenu.dessert}
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '5%'}}>
                            <Box sx={{marginTop: {xs: 0, sm: 0, flex: 1}}}>
                                {checkedStatus}
                            </Box>
                        </div>
                    </ListItemButton>
                </ListItem>
            );
        })
    }, [logic.menuDataToDisplayList, logic.idNewMenuCantineSel]);


    useEffect(() => {
        if (logic.openMenuEditDialogForm === false) {
            logic.setMenuDataToDisplayList(_.cloneDeep(logic.menuDataToDisplayListRef.current));
            logic.setProcessingLivreStateNormBtn(false);
            logic.setOpenMenuEditDialogForm(false);
            logic.setSearchMenuTerm('');
            return;
        }
        onSetMenuCantine(logic.menuCantineDataRef.current?.id);
        return () => {
        };
    }, [logic.openMenuEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingLivreStateNormBtn}
            title={<FormattedMessage id={messages.add_to_menu_year}/>}
            open={logic.openMenuEditDialogForm}
            setOpen={logic.setOpenMenuEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
            <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                <InputField
                    placeholder={placeholder}
                    size="small"
                    color={"secondary"}
                    value={logic.searchMenuTerm}
                    onChange={(a: any) => handleSearchMenu(a)}
                />
            </div>
            <Box sx={{textAlign: 'right', marginBottom: 2}}><FormattedMessage
                id={messages.total_menu_year}/> : {logic.menuDataToDisplayList.length}</Box>

            <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
                {menuContentDisplay}
            </List>
        </DialogFormKiller>
    );
}




export default DialogMenuPlatChoiceForm;