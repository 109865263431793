import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";


export const ENFANTPROFILS_CONSTANT = {
    convertToFrontEnd: (logic:any, item:EnfantProfils) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(enfantProfilsDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:EnfantProfils) => item,
};

export  const enfantProfilsDataImpl: EnfantProfils = {
    id: null,
    enfantId: 0,
    profilsId: 0,
    common: _.cloneDeep(commonDataImp),
}

export const enfantProfilsSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number().nonNullable(),
    profilsId: yup.number().nonNullable(),
    common: yup.mixed<Common>(),
});

export type EnfantProfils = yup.InferType<typeof enfantProfilsSchema>;
