import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment/moment";
import {Badge} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";
import {isPositiveValue} from "../../helpers/helpers";

export const SONDAGE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Sondage) => {
        const itemInit = _.cloneDeep(sondageDataImpl);
        item = {...itemInit, ...item} ;


        item.ladateForm     = isPositiveValue(item.ladate) ? moment(item.ladate) : null;
        // @ts-ignore
        item.liredateForm  = item.ladateForm.format("L");
        item.terminerForm  =  item.terminer ? <Badge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
        item.notifierForm  =  item.notifier ? <Badge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;

        item.classesIdForm = _.map(_.split(item.classes, ','), (s:string) => _.toNumber(s));

        // @ts-ignore
        return item ;
    },
    convertToBackEnd: (logic:any, item:Sondage) => {
        item.classes = _.join(item.classesIdForm, ',');
        return item ;
    },
};

export  const sondageDataImpl: Sondage = {
    id: null,
    ladate: 0,
    totalReponses: 0,
    nom: '',
    photo: '',
    question: '',
    terminer: false,
    notifier: true,
    terminerForm: '',
    notifierForm: '',
    classes: '',
    ladateForm: null,
    liredateForm:'',
    classesIdForm:[],
    common: _.cloneDeep(commonDataImp)
}

export const sondageSchema = yup.object().shape({
    id: yup.number().nullable(),
    ladate: yup.number().nullable(),
    totalReponses: yup.number(),
    nom: yup.string().required(),
    photo: yup.string(),
    question: yup.string().required(),
    terminer: yup.boolean(),
    notifier: yup.boolean(),
    classes: yup.string(),
    classesIdForm: yup.array().of(yup.number()).nonNullable().required(),
    liredateForm: yup.string(),
    ladateForm: yup.object().nullable(),
    terminerForm: yup.mixed<any>().nullable(),
    notifierForm: yup.mixed<any>().nullable(),
    common: yup.mixed<Common>()
});

export type Sondage = yup.InferType<typeof sondageSchema>;