import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {DocumentScolaire} from "./DocumentScolaire";

export const DOCUMENTSCOLAIRERECEIVER_CONSTANT = {
    convertToFrontEnd: (logic:any, itemDoc:DocumentScolaire, item:DocumentScolaireReceiver) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(documentScolaireReceiverDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:DocumentScolaireReceiver) => item,
};

export  const documentScolaireReceiverDataImpl: DocumentScolaireReceiver = {
    id: null,
    documentScolaireId: 0,
    receiverGroup: 'ADMIN',
    receiverId: 0,
    usersIdDeleted: '',
    common: _.cloneDeep(commonDataImp)
}

export const documentScolaireReceiverSchema = yup.object().shape({
    id: yup.number().nullable(),
    documentScolaireId: yup.number().nonNullable(),
    receiverGroup: yup.string().nonNullable().default('ADMIN'),
    receiverId: yup.number().nonNullable().default(0),
    usersIdDeleted: yup.string().default(''),
    common: yup.mixed<Common>()
});

export type DocumentScolaireReceiver = yup.InferType<typeof documentScolaireReceiverSchema>;