import React, {Dispatch, SetStateAction, useState} from "react";
import Button from "@mui/material/Button";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import LoadingButton from "@mui/lab/LoadingButton";
import MenuYearService from "../../service/MenuYearService";
import SaveIcon from "@mui/icons-material/Save";

interface ButtonProps {
    handleNewDish: () => any;
    handleAddNewMenu: () => any;
    handleAddToMenu: () => any;
    generateWeekAndMenu: () => any
    loadingButtonGenerate: boolean,
    statusMenuApp: boolean,
    setStatusMenuApp: Dispatch<SetStateAction<boolean>>,
}

function YearMenuAction({handleNewDish, handleAddNewMenu, handleAddToMenu, generateWeekAndMenu, loadingButtonGenerate, statusMenuApp, setStatusMenuApp}:ButtonProps){
    const [pause, setPause] = useState(statusMenuApp);
    const menuYearService = MenuYearService();

    const handlePause = async () => {
        try {
            //setPause(!pause);
            await menuYearService.updateStatusMenuYear(!statusMenuApp);
            setStatusMenuApp(!statusMenuApp);
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <>
            <Button
                variant={"contained"}
                sx={{ textTransform:'none', width: {xs:'100%', sm:'auto'}, mb:{ xs:2 }  }}
                color={"primary"}
                onClick={handleAddToMenu}
            >
                <FormattedMessage id={messages.add_to_menu_year} />
            </Button>
            <Button
                sx={{ marginLeft:{sm:3}, textTransform:'none', width: {xs:'100%', sm:'auto'}, mb:{ xs:2 }  }}
                color={"primary"}
                variant={"contained"}
                onClick={() => handleAddNewMenu()}
            >
                <FormattedMessage id={messages.add_new_menu_year} />
            </Button>

            <Button
                sx={{ marginLeft:{sm:3}, textTransform:'none', width: {xs:'100%', sm:'auto'}, mb:{ xs:2 }  }}
                color={"primary"}
                variant={"contained"}
                onClick={() => handleNewDish()}
            >
                <FormattedMessage id={messages.add_dish_year} />
            </Button>

            <LoadingButton
                loading={loadingButtonGenerate}
                loadingPosition="start"
                variant={"contained"}
                onClick={() => generateWeekAndMenu() }
                color={"primary"}
                //sx={{textTransform: 'none'}}
                sx={{ marginLeft:{sm:3}, textTransform:'none', width: {xs:'100%', sm:'auto'}, mb:{ xs:2 }  }}
                size="small"
                startIcon={<SaveIcon />}
            >
                <FormattedMessage id={messages.generate_menu_other_week} />
            </LoadingButton>

            <Button
                sx={{ marginLeft:{sm:3}, textTransform:'none', width: {xs:'100%', sm:'auto'}, mb:{ xs:2 } }}
                color={statusMenuApp ? "success" : "warning" }
                variant={"contained"}
                //disabled={pause}
                onClick={handlePause}
            >

                {!statusMenuApp && (
                    <>
                        <PauseIcon />
                        <FormattedMessage id={messages.pause_menu_year} />
                    </>

                )}
                {statusMenuApp && (
                    <>
                        <PlayArrowIcon />
                        <FormattedMessage id={messages.play_menu_year} />
                    </>

                )}
            </Button>

        </>
    )
}


export default YearMenuAction;
