import {LivreCategorie} from "../../../../constants/type/LivreCategorie";

const categoreLivreStateUpdate:any = {
    livreCategorieList: (logicRacine:any, val:LivreCategorie[]) => logicRacine.setLivreCategorieList(val),
    livreCategorieSel: (logicRacine:any, val:LivreCategorie) => logicRacine.setLivreCategorieSel(val),
    tableDataLoading: (logicRacine:any, val:boolean) => logicRacine.setTableDataLoading(val),
    openDialog: (logicRacine:any, val:boolean) => logicRacine.setOpenDialog(val),
    savingStateBtn: (logicRacine:any, val:boolean) => logicRacine.setSavingStateBtn(val),
    tableKeyValue: (logicRacine:any, val:number) => logicRacine.setTableKeyValue(val),
}

export default categoreLivreStateUpdate ;