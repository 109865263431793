import * as yup from "yup";
import {UserChatModel, userChatModelDataImpl} from "./UserChatModel";
import _ from "lodash";
import {FilDiscussion} from "./FilDiscussion";

export const SPACEINITIATIONMODEL_CONSTANT = {
    convertToFrontEnd: (item:SpaceInitiationModel) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(spaceInitiationModelImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:SpaceInitiationModel) => item,
};

export  const spaceInitiationModelImpl: SpaceInitiationModel = {
    userChatModel: _.cloneDeep(userChatModelDataImpl),
    message: '',
    filDiscussions:  [],
}

export const spaceInitiationModelSchema = yup.object().shape({
    userChatModel: yup.mixed<UserChatModel>().default(_.cloneDeep(userChatModelDataImpl)),
    message: yup.string().default(''),
    filDiscussions: yup.array().of(yup.mixed<FilDiscussion>()).default([]),
});
export type SpaceInitiationModel = yup.InferType<typeof spaceInitiationModelSchema>;