import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";


export const CHILDSTUFF_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ChildStuff) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(childStuffDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ChildStuff) => item,
};

export  const childStuffDataImpl: ChildStuff = {
    id: null,
    nom: '',
    quantite: 0,
    activer:false,
    common: _.cloneDeep(commonDataImp)
}

export const childStuffSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    quantite: yup.number().min(0).required(),
    activer : yup.boolean(),
    common: yup.mixed<Common>()
});

export type ChildStuff = yup.InferType<typeof childStuffSchema>;