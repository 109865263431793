import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {Genre} from "./Genre";
import messages from "../../page/messages/general/messages";
import {Avatar, Box} from "@mui/material";
import {stringToColor} from "../../helpers/helpers";
import React from "react";

export const ALERTMESSAGEPARENT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:AlertMessageParent) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(alertMessageParentDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:AlertMessageParent) => item,
};

export  const alertMessageParentDataImpl: AlertMessageParent = {
    id: null,
    alertMessageId: null,
    enfantId: 0,
    parentId: 0,
    parentUserId: 0,
    lue: 0,
    envoyer: 0,
    enfantNom: ``,
    enfantClasse: ``,
    parentNom: ``,
    parentPhone: ``,
    firebaseToken: ``,
    parentSexe: Genre.HOMME,
    common: _.cloneDeep(commonDataImp)
}

export const alertMessageParentSchema = yup.object().shape({
    id: yup.number().nullable(),
    alertMessageId: yup.number().nullable().notRequired(),
    enfantId: yup.number().nonNullable().required(),
    parentId: yup.number().nonNullable().required(),
    parentUserId: yup.number().nonNullable().required(),
    lue: yup.number().nullable().notRequired(),
    envoyer: yup.number().nullable().notRequired(),
    enfantNom: yup.string().nullable().required(),
    enfantFirstName: yup.string().nullable().notRequired(),
    enfantLastName: yup.string().nullable().notRequired(),
    enfantClasse: yup.string().nullable().required(),
    parentNom: yup.string().nullable().required(),
    parentPhone: yup.string().nullable().required(),
    firebaseToken: yup.string().nullable().required(),
    parentSexe: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.HOMME).required(),
    common: yup.mixed<Common>(),
});


export const alertMessageParentCustomOptions = {
    columnVisibility : {
        'enfantLastName':false,
        'enfantFirstName':false,
        'parentPhone':false,
    }
}


export const columnsAlertMessageParent = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const activeLang = intl.locale;
    return [
        {
            accessorKey: 'enfantNom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            accessorKey: 'enfantLastName',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            accessorKey: 'enfantFirstName',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            accessorKey: 'enfantClasse',
            header: `${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`,
            size: 120,
        },
        {
            accessorKey: 'parentNom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.parent_child})}`,
            size: 100,
        },
        {
            accessorKey: 'parentPhone',
            header: `${infoSpace} ${intl.formatMessage({id:messages.phone_number_child})}`,
        },

    ];
} ;

export type AlertMessageParent = yup.InferType<typeof alertMessageParentSchema>;