import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import {LivreCategorie, livreCategorieDataImpl} from "./LivreCategorie";
import _ from "lodash";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {Badge} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";


export const LIVRE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Livre) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(livreDataImpl);
        item = {...itemInit, ...item} ;

        const livrecategorie = _.find(logic.livreCategorieRef.current, {id:item.livreCategorieId}) ;
        if(_.isObject(livrecategorie)){
            item.livreCategorieForm = livrecategorie;
        }

        // @ts-ignore
        item.photoForm = `${BASEURL_RESOURCES}/${item.photo}`;

        item.availableForm  =  item.available ? <Badge badgeContent={<FormattedMessage id={messages.disponible} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.indisponible} />} color="error" />;
        return item ;
    },
    convertToBackEnd: (logic:any, item:Livre) => {
        // @ts-ignore
        item.livreCategorieId = item.livreCategorieForm.id ?? 0 ;
        return item ;
    },
};

export  const livreDataImpl: Livre = {
    id: null,
    livreCategorieId:0,
    nom: '',
    auteur: '',
    agemin: 1,
    agemax: 12,
    langue: ``,
    empruntMaxJour:15,
    prix:0,
    quantiteDispo:0,
    quantite: 1,
    available: true,
    photo: '',
    photoForm: '',
    donateurs: ``,
    description: ``,
    availableForm: ``,
    livreCategorieForm: _.cloneDeep(livreCategorieDataImpl),
    common:  _.cloneDeep(commonDataImp),
}

export const livreSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    livreCategorieId: yup.number().nonNullable().required(),
    auteur: yup.string().nonNullable().required(),
    agemin: yup.number().min(1).default(1).required(),
    agemax: yup.number().default(12).required().required(),
    langue: yup.string().nonNullable().required(),
    empruntMaxJour: yup.number().min(1).default(15).required(),
    prix: yup.number().min(0).required(),
    quantiteDispo: yup.number(),
    quantite: yup.number().min(1).default(1).required(),
    available: yup.boolean().default(true).notRequired(),
    photo: yup.string().nullable(),
    photoForm: yup.string(),
    description: yup.string().nullable(),
    donateurs:  yup.string().notRequired(),
    livreCategorieForm:yup.mixed<LivreCategorie>(),
    availableForm:yup.mixed<any>(),
    common:  yup.mixed<Common>(),
});


export const columnsLivre = (props:any) => {
    const {intl} = props ;
    return [
        // @ts-ignore
        {
            accessorKey: 'photoForm',
            header: intl.formatMessage({id:messages.table_h_photo}),
            size: 60,
            grow:false,
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', alignItems:"center", width:'60'}}>
                    <img
                        loading="lazy"
                        width="48"
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        style={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: 'livreCategorieForm.nom',
            header: intl.formatMessage({id:messages.table_h_catlivre}),
            size: 120,
        },
        {
            accessorKey: 'nom',
            header: intl.formatMessage({id:messages.table_h_nom}),
        },
        {
            accessorKey: 'auteur',
            header: intl.formatMessage({id:messages.table_h_auteur}),
        },
        {
            accessorKey: 'langue',
            header: intl.formatMessage({id:messages.table_h_langue}),
            size: 90,
        },
        {
            accessorKey: 'quantite',
            header: intl.formatMessage({id:messages.table_h_quantite}),
            size: 120,
        },
        {
            accessorKey: 'quantiteDispo',
            header: intl.formatMessage({id:messages.en_stock}),
            size: 120,
        },
        {
            accessorKey: 'empruntMaxJour',
            header: intl.formatMessage({id:messages.table_h_empruntmaxjour}),
            size: 120,
        },

    ];
} ;


export type Livre = yup.InferType<typeof livreSchema>;