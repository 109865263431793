import React from 'react';
import {TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DialogForm from "../../../components/ui/form/DialogForm";

function ImageRightsForm(props:any) {
    const { theme, title, open, setOpen, classes, onSubmit, register, } = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classes.inputField}>
                <TextField
                    label={<FormattedMessage id={messages.image_rights}/>}
                    size="small"
                    color={"secondary"}
                    {...register("name", {
                        required: "Name is required"
                    })}
                />
            </div>
        </DialogForm>
    );
}

export default ImageRightsForm;
