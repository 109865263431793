import {RdvEtat} from "./RdvEtat";
import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import * as yup from "yup";
import _ from "lodash";
import {Employee} from "./Employee";


export const CRENEAURDVEMPLOYEE_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:CreneauRdvEmployee|null) => {
        if(_.isNull(item)){
            return item ;
        }
        const itemInit = _.cloneDeep(creneauRdvEmployeeDataImp);
        item = {...itemInit, ...item} ;

        item.startForm    = moment(item.dateDebut);
        item.endForm      = moment(item.dateFin);
        item.employeeForm = _.find(logicRacine.employeesRef.current, {id:item?.employeeId});
        // @ts-ignore
       return item;
    },
    convertToBackEnd: (item:CreneauRdvEmployee) => {
        item.dateDebut          = item.startForm.valueOf();
        item.dateFin            = item.endForm.valueOf();
        return item ;
    }
};

export const creneauRdvEmployeeDataImp: CreneauRdvEmployee =  {
    id: null,
    meetingStatus: RdvEtat.WAIT,
    creneauRdvId:null,
    employeeId: 0,
    fonctionEmployeeId: 0,
    userId:0,
    dateDebut: 0,
    dateFin: 0,
    startForm: moment(),
    endForm: moment().add(1, 'minutes'),
    commentaire: '',
    common: _.cloneDeep(commonDataImp),
    employeeForm:null,
}

export const creneauRdvEmployeeSchema = yup.object().shape({
    id : yup.number().nullable(),
    meetingStatus: yup.mixed<RdvEtat>().required().oneOf(Object.values(RdvEtat)),
    creneauRdvId: yup.number().nullable(),
    employeeId: yup.number().min(1),
    fonctionEmployeeId: yup.number().min(0),
    userId: yup.number().min(0),
    dateDebut: yup.number().min(1),
    dateFin: yup.number().min(1),
    startForm : yup.mixed<moment.Moment>().required(),
    endForm : yup.mixed<moment.Moment>().required(),
    commentaire: yup.string().default(''),
    common: yup.mixed<Common>().required(),
    employeeForm: yup.mixed<Employee>().nullable().default(null),
});

export type CreneauRdvEmployee = yup.InferType<typeof creneauRdvEmployeeSchema>;