import {deleteRequest, getRequest, uploadFilePostRequest, uploadFileRequestAsync} from "../helpers/axios_helpers";
import {TMiniClubData, TMiniClubFormValues} from "../constants/type/miniclub/miniclubType";
import moment from "moment";
import {CONSTANT} from "../constants";

const MiniClubService = () => {

    return {
        getAllMiniClubData: async () => {
            return await getRequest('/extra/miniclubs');
        },
        reformatMiniClubData: (data:TMiniClubData, activeLanguage:string) => {
            return data.map((dataClub: TMiniClubData) => {
                const miniClubTime = `${
                    moment(dataClub.dateDebut).format("DD/MM/YYYY HH:mm")
                } - ${
                    moment(dataClub.dateFin).format("DD/MM/YYYY HH:mm")
                }`;
                const miniClubPrice = new Intl.NumberFormat('fr-FR').format(dataClub.prix);

                return {
                    ...dataClub,
                    id: dataClub.id,
                    title: dataClub.title,
                    startTime: moment(dataClub.dateDebut).format(activeLanguage === 'en' ? "MM/DD/YYYY HH:mm" : "DD/MM/YYYY - HH:mm"),
                    endTime: moment(dataClub.dateFin).format(activeLanguage === 'en' ? "MM/DD/YYYY HH:mm" : "DD/MM/YYYY - HH:mm"),
                    timeMiniClub: miniClubTime,
                    description: dataClub.details,
                    image: dataClub.uriPublicite,
                    price: miniClubPrice,
                    placeLimit: `${dataClub.inscritMiniClubs.length}/${dataClub.placeLimit}`,
                    originalObject: dataClub
                }
            })
        },
        postNewMiniClubData: async (data:TMiniClubFormValues, file:any) => {
            let formData:any = new FormData();
            formData.append("photo", file);

            const classroomTab = data.classroom.map((classroom:any) => {
                return {
                    classeId: classroom.id,
                    common: CONSTANT.common
                }
            })

            const dataToSend = {
                //id: 0,
                title: data.title,
                dateDebut: data.dateDebut,
                dateFin: data.dateFin,
                placeLimit: data.placeLimit,
                details: data.details,
                uriPublicite: "",
                prix: data.prix,
                common: CONSTANT.common,
                classeMiniClubs: classroomTab,
                photoMiniClubs:[],
                inscritMiniClubs:[],
            };

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));

            return await uploadFilePostRequest('/extra/miniclubs', formData);
        },
        updateMiniClub: async (method:string, url:string, data:any, file:any) => {
            let formData:any = new FormData();
            formData.append("photo", file);

            formData.append('model', new Blob([JSON.stringify(data)], {
                type: "application/json"
            }));

            return await uploadFileRequestAsync(method, url, formData);
        },
        deleteMiniClub: async (data:any) => {
            return await deleteRequest('/extra/miniclubs/bouquet', data);
        },
        reformatMiniClubDataForExportXLX: (data:any, registeredList:any) => {
            return registeredList.map((child: any) => {
                return {
                    "Nom mini club": data?.title,
                    "Date début": moment(data.dateDebut).format("DD/MM/YYYY HH:mm"),
                    "Date fin": moment(data.dateFin).format("DD/MM/YYYY HH:mm"),
                    "Nom et prénom": `${child?.person?.nom} ${child?.person?.prenom}`,
                    "Classe": child?.eleves[0]?.classe?.nom,
                    "Prix": new Intl.NumberFormat('fr-FR').format(data.prix),
                }
            });
        }
    }
};

export default MiniClubService;
