import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {PlatCantineData, PLATCANTINEDATA_CONSTANT} from "./PlatCantineData";
import _ from "lodash";
import {GenrePlat} from "./GenrePlat";


export const MENUCANTINEDATA_CONSTANT = {
    convertToFrontEnd: (item:MenuCantineData) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(dataMenuCantineDataImpl);
        item = {...itemInit, ...item} ;

        item.platCantineDataMap = new Map([
            [GenrePlat.ENTREE, ''],
            [GenrePlat.PLAT, ''],
            [GenrePlat.DESSERT, ''],
        ]);
        // @ts-ignore
        item.platCantineDatas   = _.map(item.platCantineDatas,  PLATCANTINEDATA_CONSTANT.convertToFrontEnd);
        // @ts-ignore
        item.platCantineDatas.forEach( data => {
            if(_.isNull(data) || _.isUndefined(data)){
                return ;
            }
            // @ts-ignore
            item.platCantineDataMap.set(data.typePlat, `${data.nom}. ${data.details ?? ''}`);
        });
       return item ;
    },
    convertToBackEnd: (item:MenuCantineData) => item,
};

export  const dataMenuCantineDataImpl: MenuCantineData = {
    id: 0,
    nom: '',
    couleur: '',
    photo: '',
    platCantineDatas: [],
    platCantineDataMap: new Map(),
    common:  _.cloneDeep(commonDataImp),
}

export const menuCantineDataSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nullable(),
    couleur: yup.string().nullable(),
    photo: yup.string().nullable(),
    platCantineDatas:  yup.array().of(yup.mixed<PlatCantineData>()).nonNullable(),
    platCantineDataMap:  yup.mixed<Map<GenrePlat, string>>().default(new Map()).nonNullable(),
    common:  yup.mixed<Common>(),
});
export type MenuCantineData = yup.InferType<typeof menuCantineDataSchema>;