import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";


export const GROUPENFANTPROFILS_CONSTANT = {
    convertToFrontEnd: (logic:any, item:GroupEnfantProfils) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(groupEnfantProfilsDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:GroupEnfantProfils) => item,
};

export  const groupEnfantProfilsDataImpl: GroupEnfantProfils = {
    id: null,
    profilsId: null,
    groupEnfantId: null,
    common: _.cloneDeep(commonDataImp)
}

export const groupEnfantProfilsSchema = yup.object().shape({
    id: yup.number().nullable(),
    profilsId: yup.number().nullable(),
    groupEnfantId: yup.number().nullable(),
    common: yup.mixed<Common>()
});

export type GroupEnfantProfils = yup.InferType<typeof groupEnfantProfilsSchema>;
