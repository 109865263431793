import React, {useContext, useEffect, useState} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import {Box, Button, Grid, IconButton} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from '@mui/material/styles';
import LoadingButton from "@mui/lab/LoadingButton";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputField from "../../components/ui/form/InputField";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import _ from "lodash";
import {DocumentScolaireFichier, DOCUMENTSCOLAIREFICHIER_CONSTANT} from "../../constants/type/DocumentScolaireFichier";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {isPositiveValue} from "../../helpers/helpers";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function DocumentFileToolBar(props: any) {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses;

    const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);
    const [disabledInput, setDisabledInput] = useState<boolean>(false);

    const [uploadingMsg, setUploadingMsg] = useState<boolean>(false);
    //const textBtnFileAttachedRef = React.useRef<string>(logic.activeLanguageRef.current[0] === 'en' ? 'Upload file' : 'Uploader un fichier');

    const handleGoOut = () => schoolDocStateUpdate.goOutDocFolderSel(logic);
    const handleDelete = () => schoolDocumentBusinessLogic.handleDeleteFile(logic);
    const handleFileChange = (event: any) => {
        const files = event.target.files;
        schoolDocumentBusinessLogic.envoieFichier(logic, logic.docFolderSel.id, files, setUploadingMsg);
    }
    const handleNomChange = ( event: any) => {
        const data = _.cloneDeep(logic.docFileSel);
        data.nom = event.target.value;
        schoolDocStateUpdate.docFileSel(logic, data);
    };
    const handleSave = async () => {
        schoolDocStateUpdate.processingFolderStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: DocumentScolaireFichier = _.cloneDeep(logic.docFileSel);

        // @ts-ignore
        const toSend = DOCUMENTSCOLAIREFICHIER_CONSTANT.convertToBackEnd(logic, dataToSend);
        schoolDocumentBusinessLogic.renameFile(
            logic,
            messages,
            toSend).then((response:any) => {
            schoolDocStateUpdate.processingFolderStateNormBtn(logic, false);
        }).catch((e:any) => schoolDocStateUpdate.processingFolderStateNormBtn(logic, false));
    };

    useEffect(() => {
        setDisableSaveBtn(logic.docFileSel.nom.length < 2);
        setDisabledInput(!isPositiveValue(logic.docFileSel.id));
        return () => {};
    }, [logic.docFileSel]);

    if (logic.showFiles === false) {
        return <></>;
    }
    return (
        <div style={{display: "flex"}}>
            <Box sx={{width: 2/3, textAlign: 'left'}}>
                <IconButton
                    aria-label="back"
                    color="secondary"
                    onClick={handleGoOut}
                    sx={{marginRight: 2}}>
                    <ArrowBackIcon />
                </IconButton>
                <LoadingButton
                    loading={uploadingMsg}
                    component="label"
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<CloudUploadIcon/>}
                    size="large"
                    color={"primary"}
                    sx={{textTransform: 'none', mt:0.5, mr: { sm: 2 } }}
                >
                    <FormattedMessage id={messages.doc_upload_file} />
                    <input
                        id="file-upload"
                        type="file"
                        hidden={true}
                        onChange={handleFileChange}
                    />
                </LoadingButton>
                <Button
                    component="label"
                    size="small"
                    color={"error"}
                    variant="contained"
                    sx={{textTransform: 'none', mt:0.5, mr: { sm: 2 } }}
                    onClick={handleDelete}
                    disabled={disabledInput}
                    startIcon={<DeleteForeverIcon />}>
                    <FormattedMessage id={messages.doc_del_file} />
                </Button>

            </Box>

            <Box sx={{width: 1/3, textAlign: 'right'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField} style={{display:"flex", alignItems:'right', justifyContent:'right' }}>
                            <InputField
                                label={<FormattedMessage id={messages.doc_file} />}
                                size="small"
                                color={"secondary"}
                                InputProps={{
                                    rows: 2,
                                    multiline: true,
                                    inputComponent: 'textarea',
                                }}
                                disabled={disabledInput}
                                value={logic.docFileSel.nom}
                                sx={{ width: 1, float:'right', marginRight:4, }}
                                onChange={handleNomChange}
                            />
                            {
                                logic.docFileSel.isOwnerForm  &&
                                <>
                                    <IconButton
                                        aria-label="save"
                                        color="secondary"
                                        disabled={disableSaveBtn}
                                        onClick={handleSave}
                                        sx={{float:'right', marginRight:4}}>
                                        {
                                            logic.savingStateBtn &&
                                            <RefreshIcon />
                                        }
                                        {
                                            !logic.savingStateBtn &&
                                            <SaveIcon />
                                        }
                                    </IconButton>
                                </>
                            }

                        </div>
                        <div className={styleClasses.inputField}
                             style={{display:"flex", fontStyle:'italic', alignItems:'left', justifyContent:'left'}}>
                            <FormattedMessage id={messages.doc_folder} />&nbsp;<span style={{fontWeight:'bold'}}> {logic.docFolderSel.nom}</span>: {logic.docFolderSel.documentScolaireFichiers.length} <FormattedMessage
                            id={messages.doc_file}/>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default DocumentFileToolBar ;