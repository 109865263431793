import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {ScolariteEcheancier, SCOLARITEECHEANCIER_CONSTANT} from "../../../../constants/type/ScolariteEcheancier";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import scolariteEcheancierStateUpdate from "./ScolariteEcheancierStateUpdate";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {EcheancierGrouping, ECHEANCIERGROUPING_CONSTANT} from "../../../../constants/type/EcheancierGrouping";
import {
    EcheancierGroupingDetails,
    ECHEANCIERGROUPINGDETAILS_CONSTANT
} from "../../../../constants/type/EcheancierGroupingDetails";

const scolariteEcheancierBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolariteannees', 'scolaritegroups'];

        const convertToFrontEnd = (data:any) => {

            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsg : ScolariteGroup[]   = data.scolaritegroups.content ?? [];
            const itemsgfilter = _.filter(itemsg, { 'echeance': true});
            logic.scolariteGroupsRef.current  = _.sortBy(_.map(itemsgfilter,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

        };

        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            scolariteEcheancierBusinessLogic.loadScolariteEcheanciers(logic);
        }).catch(e => {
            scolariteEcheancierStateUpdate.tableDataLoading(logic, false);
        });
    },
    formatEcheanciersGrouping: (logic:any, rows: ScolariteEcheancier[]) : EcheancierGrouping[] => {

        const echeanciersGroupMap: Map<number, EcheancierGrouping> = new Map();

        for(const echeancier of rows) {
            const echeancierFormat : ScolariteEcheancier = SCOLARITEECHEANCIER_CONSTANT.convertToFrontEnd(logic, echeancier);

            if(_.isObject(echeancierFormat.scolariteGroup) === false){
                continue ;
            }

            // @ts-ignore
            const scolGroupId = echeancierFormat.scolariteGroup.id ?? 0 ;

            // @ts-ignore
            const echeancierGroup = echeanciersGroupMap.get(scolGroupId) ?? ECHEANCIERGROUPING_CONSTANT.convertScolariteGroupToFrontEnd(logic, echeancierFormat.scolariteGroup);

            echeancierGroup.echeanciersDetailsForm?.push(ECHEANCIERGROUPINGDETAILS_CONSTANT.convertScolariteEcheancierToFrontEnd(logic, echeancierFormat));

            // @ts-ignore
            echeancierGroup.sumEcheancierDetail += echeancierFormat.montant ;
            echeanciersGroupMap.set(scolGroupId, echeancierGroup);
        }
        return Array.from(echeanciersGroupMap.values()) ;
    },
    saveEdit: async (logic:any, messages:any, toSends:any[], id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';

            request(method, `/extra/scolaritecheancier/bouquet`, toSends).then((response) => {

                scolariteEcheancierBusinessLogic.loadScolariteEcheanciers(logic);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadScolariteEcheanciers: (logic:any) => {
        scolariteEcheancierStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritecheancier`, {}).then((response) => {
                const items : ScolariteEcheancier[]   = response.data._embedded?.scolariteEcheancierDTOModelList ?? [];
                const datas = scolariteEcheancierBusinessLogic.formatEcheanciersGrouping(logic, items);
                // @ts-ignore
                const datasOrder = _.sortBy(datas, ['nom', 'ladate']);
                scolariteEcheancierStateUpdate.dataList(logic, datasOrder) ;
                scolariteEcheancierStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteEcheancierStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolariteEcheancierStateUpdate.tableDataLoading(logic, false);
                resolve(false);
            })
        );
    },
    loadScolariteEcheanciersDetails: (logic:any, scolariteGroupId:number) => {

        scolariteEcheancierStateUpdate.showDetailsTableContent(logic, false);
       // const scolariteTypeId = logic.dataSel.scolariteTypeId ;
       // const classeId = logic.dataSel.classeId ;
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritecheancier/scolaritegroup/${scolariteGroupId}`, {}).then((response) => {
                const items : ScolariteEcheancier[]   = response.data._embedded?.scolariteEcheancierDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITEECHEANCIER_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']);

                scolariteEcheancierStateUpdate.scolaEcheancierDetails(logic, itemsfrontend) ;
                scolariteEcheancierStateUpdate.tableDetailsKeyValue(logic, new Date().getTime());
                scolariteEcheancierStateUpdate.showDetailsTableContent(logic, true);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolariteEcheancierStateUpdate.showDetailsTableContent(logic, true);
                resolve(false);
            })
        );
    },
    deleteDetails: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/scolaritecheancier/bouquet`, ids).then((response) => {
                // @ts-ignore
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/scolaritecheancier/bouquet`, ids).then((response) => {
                // @ts-ignore
               /* const datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITEECHEANCIER_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);
                scolariteEcheancierStateUpdate.dataListSelected(logic, []);
                scolariteEcheancierStateUpdate.dataList(logic, itemsfrontend) ;*/
                scolariteEcheancierBusinessLogic.loadScolariteEcheanciers(logic);
                scolariteEcheancierStateUpdate.dataListSelected(logic, []);
                scolariteEcheancierStateUpdate.datasDeleteSelected(logic, []);
                scolariteEcheancierStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteEcheancierStateUpdate.tableDataLoading(logic, false);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:EcheancierGroupingDetails[]):Promise<boolean> => {
        const ids:number[] = []
        toSends.forEach((l:EcheancierGroupingDetails) => {
            if(_.isNumber(l.id)){ ids.push(l.id);}
        });
        return scolariteEcheancierBusinessLogic.delete(logic, messages, ids);
    }
}
export default scolariteEcheancierBusinessLogic ;