import React, {useState} from "react";
import {useSelector} from "react-redux";

import _ from "lodash";
import {EventNotification, eventNotificationDataImpl} from "../../../constants/type/EventNotification";
import {Pagination, paginationImp} from "../../../constants/type/LivreFiltre";

const useEventNotificationVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    const activeLanguageRef = React.useRef<any[]>(props.locale.split('-'));

    const [pagination, setPagination] = React.useState<Pagination>(_.cloneDeep(paginationImp));
    const [processingStateNormBtn, setProcessingStateNormBtn] = React.useState<boolean>(false);
    const [processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn] = React.useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const editDisableRef = React.useRef<boolean>(true);


    const [dataList, setDataList] = React.useState<EventNotification[]>([]);
    const [dataListSelected, setDataListSelected] = React.useState<EventNotification[]>([]);
    const [dataSel, setDataSel] = React.useState<EventNotification>(_.cloneDeep(eventNotificationDataImpl));
    const [showTableContent, setShowTableContent] = React.useState<boolean>(true);
    const [contentLoaded, setContentLoaded] = React.useState<boolean>(false);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLanguageRef,
        processingStateNormBtn,setProcessingStateNormBtn,pagination,setPagination,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,
        tableKeyValue,setTableKeyValue,showTableContent,setShowTableContent,editDisableRef,
        contentLoaded,setContentLoaded,processingSubmitStateNormBtn,setProcessingSubmitStateNormBtn
    }
}
export default useEventNotificationVariables;