import {Grid, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {RdvPeriode} from "../../constants/type/Rdv";
import React, {useContext} from "react";
import {AppointmentPresetContext} from "./Appointment";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import DialogForm from "../../components/ui/form/DialogFormKiller";
import _ from "lodash";
import {RdvEtat} from "../../constants/type/RdvEtat";
import colorfull from "../../data/colorfull";
import {RdvType} from "../../constants/type/RdvType";
import Box from "@mui/material/Box";
import rdvToolBarBusinessLogic from "./businesslogic/RdvToolBarBusinessLogic";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";

const color = {
    main: colorfull[2],
    secondary: colorfull[3],
    third: colorfull[0],
    fourth: colorfull[1],
    fifth: colorfull[6],
};


const RdvFilterForm = (props:any) => {
    const logicRacine: any = useContext(AppointmentPresetContext);


    const handleMeetingStatusChange = (event: React.MouseEvent<HTMLElement>, value: any[]) => {
        const trie = _.cloneDeep(logicRacine.filterRdv);
        trie.meetingStatus = value;
        rdvStateUpdate.filterRdv(logicRacine, trie);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, trie, false);
    }
    const handleMeetingTypeChange = (event: React.MouseEvent<HTMLElement>, value: any[]) => {
        const trie = _.cloneDeep(logicRacine.filterRdv);
        trie.meetingType = value;
        rdvStateUpdate.filterRdv(logicRacine, trie);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, trie, false);
    }
    const handleMeetingPeriodChange = (event: React.MouseEvent<HTMLElement>, value: any[]) => {
        const trie = _.cloneDeep(logicRacine.filterRdv);
        trie.periode = value;
        rdvStateUpdate.filterRdv(logicRacine, trie);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, trie, false);
    }



    return (
        <DialogForm
            title={<FormattedMessage id={messages.rdv_filter_title} />}
            showSubmitBtn={false}
            open={logicRacine.openFilterDialogForm}
            setOpen={logicRacine.setOpenFilterDialogForm}
            theme={logicRacine.theme}
            btnCloseLabel={messages.dialog_close}>

            <Box  sx={{ width: '100%' }}>
                <Grid container spacing={2} sx={{display: 'block' }}>
                    <Grid item md={12} xs={12}>
                        <div className={logicRacine.styleClasses.containerTitle} style={{marginBottom:15}}>
                            <div>
                                <Typography className={logicRacine.styleClasses.title}>
                                    <FormattedMessage id={messages.rdv_type} /> :
                                </Typography>
                            </div>
                            <ToggleButtonGroup
                                color="primary"
                                value={logicRacine.filterRdv.meetingType}
                                onChange={handleMeetingTypeChange}
                                aria-label="Platform"
                                sx={{width:'100%'}}
                            >
                                <ToggleButton value={RdvType.NORMAL} sx={{width:'50%'}}>
                                    <FormattedMessage id={messages.type_normal} />
                                </ToggleButton>
                                <ToggleButton value={RdvType.PRESET} sx={{width:'50%'}}>
                                    <FormattedMessage id={messages.type_preset} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div className={logicRacine.styleClasses.containerTitle} style={{marginBottom:15}}>
                            <div>
                                <Typography className={logicRacine.styleClasses.title}>
                                    <FormattedMessage id={messages.period} /> :
                                </Typography>
                            </div>
                            <ToggleButtonGroup
                                color="primary"
                                value={logicRacine.filterRdv.periode}
                                onChange={handleMeetingPeriodChange}
                                aria-label="Platform"
                                sx={{width:'100%'}}
                            >
                                <ToggleButton value={RdvPeriode.PAST} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_past} />
                                </ToggleButton>
                                <ToggleButton value={RdvPeriode.CURRENT} sx={{width:'34%'}}>
                                    <FormattedMessage id={messages.appointment_current} />
                                </ToggleButton>
                                <ToggleButton value={RdvPeriode.UPCOMING} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_upcoming} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div className={logicRacine.styleClasses.containerTitle} style={{marginBottom:15}}>
                            <div>
                                <Typography className={logicRacine.styleClasses.title}>
                                    <FormattedMessage id={messages.rdv_status_libelle} /> :
                                </Typography>
                            </div>
                            <ToggleButtonGroup
                                color="primary"
                                value={logicRacine.filterRdv.meetingStatus}
                                onChange={handleMeetingStatusChange}
                                aria-label="Platform"
                                sx={{width:'99%', flexWrap: "wrap" }}
                            >
                                <ToggleButton value={RdvEtat.CANCEL} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_cancel} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.CONFIRM} sx={{width:'34%'}}>
                                    <FormattedMessage id={messages.appointment_confirm} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.DONE} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_done} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.NOT_HELD} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_not_held} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.REPORT} sx={{width:'34%'}}>
                                    <FormattedMessage id={messages.appointment_report} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.WAIT} sx={{width:'33%'}}>
                                    <FormattedMessage id={messages.appointment_wait} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.PARTIAL_CONFIRM} sx={{width:'50%'}}>
                                    <FormattedMessage id={messages.appointment_partial_confirm} />
                                </ToggleButton>
                                <ToggleButton value={RdvEtat.NOT_RESPECTED} sx={{width:'50%'}}>
                                    <FormattedMessage id={messages.appointment_not_respected} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </DialogForm>
    );
}
export default RdvFilterForm ;
