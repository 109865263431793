import {amber, indigo, lightBlue, lightGreen, orange, pink, purple, red, teal} from "@mui/material/colors";

const colorfull = [
    indigo.A200,
    purple[500],
    lightBlue[500],
    pink.A200,
    lightGreen[600],
    red.A700,
    orange[600],
    teal[500],
    purple[500],
    amber[600],
];
export default colorfull;
