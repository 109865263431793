import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import _ from "lodash";
import {Child, columnsChild} from "../../constants/type/Child";
import albumPhotoStateUpdate from "./businesslogic/AlbumPhotoStateUpdate";
import {AlbumPhotoContext} from "./AlbumPhoto";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function AlbumPhotoList() {
    const logic: any = useContext(AlbumPhotoContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    //const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    //const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';


    const editItem = (row:MRT_Row<Child>) => {
        const data:Child = logic.childrenList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        albumPhotoStateUpdate.childSel(logic, _.cloneDeep(data));
        albumPhotoStateUpdate.showForm(logic, true);
    };


    const columns = useMemo<MRT_ColumnDef<Child>[]>(
        // @ts-ignore
        () => [...columnsChild({intl:intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.childrenList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                styleClasses={styleClasses}
                showAddButton={false}
                showDeleteButton={false}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });


    if(logic.showForm){
        return <></>;
    }

    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default AlbumPhotoList ;
