import {Rdv, RDV_CONSTANT} from "../../../constants/type/Rdv";
import _ from "lodash";
import {RdvType} from "../../../constants/type/RdvType";
import {request} from "../../../helpers/axios_helpers";
import {FormattedMessage} from "react-intl";
import React from "react";
import {CreneauRdv} from "../../../constants/type/CreneauRdv";
import rdvStateUpdate from "./RdvStateUpdate";
import moment from "moment/moment";
import rdvToolBarBusinessLogic from "./RdvToolBarBusinessLogic";
import {processingChildren, processingEmployees, processingJobs} from "../../../helpers/loader_helpers";
import rdvPresetBusinessLogic from "./RdvPresetBusinessLogic";
import {SlotInfo} from "react-big-calendar";
import messages from "../../messages/general/messages";
import {Employee} from "../../../constants/type/Employee";
import {Client} from "@stomp/stompjs";
import {SOCKET_URL} from "../../../config/AppUrl";
import {checkTeacherUser, isPositiveValue, WEBSOCKET_RECONNECTDELAY} from "../../../helpers/helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../constants/type/Profils";
import {Child} from "../../../constants/type/Child";
import {Fonction} from "../../../constants/type/Fonction";
import {getErrorMessage} from "../../../helpers/errorManager";
import {pageFormImpl, setPageForm} from "../../../features/page/pageSlice";
import {mixLoad} from "../../../helpers/loader_cache_helpers";

const rdvBusinessLogic: any = {
    runWebSocket: (logicRacine:any) => {
        const user          = logicRacine.userRef.current;
        const websocketClientRef = logicRacine.websocketClientRef;
        websocketClientRef.current = new Client({
            brokerURL: SOCKET_URL,
            connectHeaders: {id: user.uuid},
            reconnectDelay: WEBSOCKET_RECONNECTDELAY,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => rdvBusinessLogic.onConnected(logicRacine),
            onDisconnect: () => rdvBusinessLogic.onDisconnected(logicRacine)
        });

        websocketClientRef.current.activate();
    },
    onConnected: (logicRacine:any) => {
        console.log("Connected!!");
        const websocketClientRef = logicRacine.websocketClientRef;
        const websocketSubscriptMapRef = logicRacine.websocketSubscriptMapRef;
        const subNotifications = websocketClientRef.current?.subscribe(`/notifications/tag/rdv`, function (payload:any) {
        }, {'content-type': 'application/json'});
        websocketSubscriptMapRef.current.set('subNotifications', subNotifications);
    },
    onDisconnected: (logicRacine:any) => {
        console.log("onDisconnected");
    },
    initPostConstruct: (logicRacine:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['classes', 'profils', 'fonctions', 'enfants', 'admin-teacher'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logicRacine.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);

            const itemsp : Profils[]   = data.profils.content ?? [];
            logicRacine.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logicRacine, item)), ['nom']);


            const itemchilds : Child[]   = data.enfants.content ?? [];
            logicRacine = processingChildren(logicRacine, itemchilds);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logicRacine = processingJobs(logicRacine, itemsprof);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logicRacine = processingEmployees(logicRacine, itememployees);

            const teachers: Employee[] = [];
            logicRacine.employeesByFonctionRef.current.forEach((e:Employee) => {
                if(_.isEmpty(e.teacherClasses)){
                    return ;
                }
                teachers.push(e);
            });
            logicRacine.classeRoomRef.current = _.filter(_.cloneDeep(logicRacine.classeRoomRef.current), (item) => {
                return _.findIndex(teachers, (emp) => {
                    return emp?.teacherClasses[0]?.classeId === item.id;
                })  >= 0;
            });
            rdvStateUpdate.teachersListRef(logicRacine, teachers);
            checkTeacherUser(logicRacine, rdvStateUpdate);
        };

        rdvStateUpdate.showContent(logicRacine, false);
        mixLoad(logicRacine, requestbody, convertToFrontEnd).then(value => {
            rdvBusinessLogic.loadRdvs(logicRacine).then((value:any) => {
                rdvBusinessLogic.runWebSocket(logicRacine);

                if(_.isObject(logicRacine.pageForm)){
                    // @ts-ignore
                    if(logicRacine.pageForm.cle === 'appointment' && isPositiveValue(logicRacine.pageForm.id) ){
                        const dataId = logicRacine.pageForm.id ;
                        logicRacine.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                        // @ts-ignore
                        rdvBusinessLogic.loadRdv(logicRacine, dataId).then((data:any) => {
                            if(isPositiveValue(data.id)){
                                const rdv = RDV_CONSTANT.convertToFrontEnd(logicRacine, data);
                                if(rdv.meetingType ===  RdvType.NORMAL){
                                    rdvBusinessLogic.handleClickEditThisCrenoRdv(logicRacine, rdv.creneauRdvs[0]);
                                }else {
                                    rdvToolBarBusinessLogic.handleRdvChange(logicRacine, rdv);
                                }
                            }
                        });
                        rdvStateUpdate.showContent(logicRacine, true);
                    }else{
                        rdvStateUpdate.showContent(logicRacine, true);
                    }
                }else{
                    rdvStateUpdate.showContent(logicRacine, true);
                }

            });
        }).catch(e => {
            rdvStateUpdate.showContent(logicRacine, true);
        });
       /* loadClasseRooms(logicRacine).then(value => {});
        loadProfils(logicRacine).then((val:any)=>{
            loadChildren(logicRacine).then((value:boolean) =>
                {
                    logicRacine.childrenClasseRef.current = _.cloneDeep(logicRacine.childrenRef.current);
                    logicRacine.childrenClasseRef.current = logicRacine.childrenClasseRef.current
                        // @ts-ignore
                        .sort((a, b) => (a.eleves[0]?.classe?.nom?.localeCompare(b.eleves[0]?.classe?.nom) || a.person.nom.localeCompare(b.person.nom)) );

                    loadJobs(logicRacine, filterApi).then(value =>
                        loadEmployees(logicRacine, filterApi).then( value =>{
                                const teachers: Employee[] = [];
                                logicRacine.employeesByFonctionRef.current.every((e:Employee) => {
                                    if(_.isEmpty(e.teacherClasses) === false){
                                        teachers.push(e);
                                    }
                                })
                                rdvStateUpdate.teachersListRef(logicRacine, teachers);
                                rdvBusinessLogic.checkTeacherUser(logicRacine);
                                rdvBusinessLogic.loadRdvs(logicRacine).then((value:any) => {
                                    rdvBusinessLogic.runWebSocket(logicRacine);
                                    rdvStateUpdate.showContent(logicRacine, true);
                                });
                            }
                        ))
                }
            );*/

    },
    loadRdv: (logicRacine:any, id:number) => {
        return new Promise<Rdv>(resolve => {
            request("GET",  `/extra/rdv/${id}`, {}).then((response) => {
                resolve(response.data);
            });
        });
    },
    /*
    checkTeacherUser: (logicRacine:any) => {
        if(logicRacine.userRef?.current?.role !== RoleUser.TEACHER){
            rdvStateUpdate.isTeacherRef(logicRacine, false);
            return ;
        }
        rdvStateUpdate.isTeacherRef(logicRacine, true);
        const employeeList = _.cloneDeep(logicRacine.employeesByFonctionRef.current);
        const sz = employeeList.length;
        let employee:Employee;
        const person = logicRacine.userRef.current?.userDetails?.personDetails?.person ;
        for (let i = 0; i < sz; i++) {
            employee = employeeList[i];
            if(employee.id !== person.id){
                continue ;
            }
            rdvStateUpdate.teacherRef(logicRacine, employee);
            break ;
        }
        const classeId = logicRacine.userRef.current?.userDetails?.personDetails?.teacherClasses[0]?.classeId ?? 0;
        rdvStateUpdate.classeIdRef(logicRacine, classeId);
    },*/
    saveRdv: async (logicRacine:any, messages:any, toSend:Rdv):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNull(toSend.id) ? 'POST' : 'PUT';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }
            if(method === 'POST'){
                if(toSend.meetingType === RdvType.NORMAL){
                    params =  `/normal`;
                }
            }

            request(method, `/extra/rdv${params}`, toSend).then((response) => {
                // @ts-ignore
                const rdvSaved = RDV_CONSTANT.convertToFrontEnd(logicRacine, response.data);

                rdvBusinessLogic.updateRdvList(logicRacine, _.cloneDeep(logicRacine.rdvStoreRef.current), rdvSaved);

                if(method === 'POST'){
                    rdvStateUpdate.rdvToolbarSelect(logicRacine, rdvSaved);
                    rdvStateUpdate.rdvEdit(logicRacine, rdvSaved);
                }else {
                    if(isPositiveValue(logicRacine.rdvToolbarSelect?.id)){
                        rdvStateUpdate.rdvToolbarSelect(logicRacine, rdvSaved);
                    }
                    if(isPositiveValue(logicRacine.rdvEdit?.id)){
                        rdvStateUpdate.rdvEdit(logicRacine, rdvSaved);
                    }
                }

                logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    updateRdvList: (logicRacine:any, rdvs:Rdv[], newRdv:Rdv|null) => {
        let events: CreneauRdv[] = [];
        if(_.isNull(newRdv) === false){
            // @ts-ignore
            const index = _.findIndex(rdvs, {id:newRdv.id ?? 0});
            if(index < 0){
                // @ts-ignore
                rdvs.unshift(newRdv);
            }else {
                // @ts-ignore
                rdvs[index] = newRdv ;
            }

            events = _.cloneDeep(logicRacine.creneauRdvListInCalendar) ?? [];
            // @ts-ignore
            newRdv.creneauRdvs.forEach(creno => {
                const indexfind = _.findIndex(events, {
                    'id': creno?.id
                });
                if(indexfind >= 0){
                    // @ts-ignore
                    events[indexfind] = creno ;
                } else {
                    // @ts-ignore
                    events.unshift(creno);
                }
            });

        } else {
            // @ts-ignore
            events    = _.flatMap(rdvs, (item:Rdv) => item.creneauRdvs);
        }

        rdvStateUpdate.creneauRdvListInCalendar(logicRacine, _.cloneDeep(events));
        rdvStateUpdate.rdvStoreRef(logicRacine, rdvs);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, logicRacine.filterRdv, false);
    },
    isRdvDeadLineReach: (logicRacine:any, rdv:Rdv | null):boolean => {
        if(_.isNull(rdv) || _.isUndefined(rdv)){
            return false ;
        }
        const deadlineupdate = rdv.startForm.clone().subtract(rdv.deadlineUpdate);
        return moment().isSameOrAfter(deadlineupdate);
    },
    loadRdvs: (logicRacine:any, filterApi:any) => {
        return new Promise<boolean>(resolve =>{
            let params = '';
            if(logicRacine.isTeacherRef.current){
                params = `/teacher/${logicRacine.teacherRef?.current?.id}/classe/${logicRacine.classeIdRef?.current}`;
            }
            request("GET",  `/extra/rdv${params}`, {}).then((response) => {
                let rdvs : Rdv[]        = response.data._embedded?.rdvDTOModelList ?? [];
                rdvs                    = _.map(rdvs, item => RDV_CONSTANT.convertToFrontEnd(logicRacine, item));
                rdvBusinessLogic.updateRdvList(logicRacine, rdvs, null);
                resolve(true);
            }).catch((error) => {
                console.error(error);
            })
        });
    },
    updateDeletedCreneaux: (logicRacine:any, creneauRdvs:CreneauRdv[]|null) => {
        if(_.isNull(creneauRdvs)){
            return ;
        }
        const events: CreneauRdv[] = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
        creneauRdvs.forEach(creno => {
            _.remove(events, function(event) {
                // @ts-ignore
                return creno.id === event.id;
            });
        });
        rdvStateUpdate.creneauRdvListInCalendar(logicRacine, _.cloneDeep(events));
    },
    updateDeletedRdvList: (logicRacine:any, rdvs:Rdv[], rdvstodelete:number[]) => {
        _.remove(rdvs, function (rdv) {
            return _.indexOf(rdvstodelete, rdv.id) >= 0 ;
        });
        rdvStateUpdate.rdvStoreRef(logicRacine, rdvs);
    },
    handleClickEditThisRdv: (logicRacine:any, rdv:Rdv) => {
        if(_.isNull(rdv) || _.isUndefined(rdv)){
            return;
        }
       rdvStateUpdate.rdvEdit(logicRacine, _.cloneDeep(rdv));
        if(rdv.meetingType === RdvType.NORMAL){
            rdvStateUpdate.newNormalRef(logicRacine, false);
            rdvStateUpdate.openNormalDialogForm(logicRacine, true);
            return ;
        }
        rdvStateUpdate.newPresetRef(logicRacine, false);
        rdvStateUpdate.openPresetDialogForm(logicRacine, true);
    },
    handleClickEditThisCrenoRdv: (logicRacine:any, evtcreno:CreneauRdv) => {
        rdvStateUpdate.eventCreneauSelectedRef(logicRacine, evtcreno);
        const openPopper = rdvBusinessLogic.selectCreneauEventProcessing(logicRacine, evtcreno);
        if(openPopper === false){
            return;
        }
        if(_.isNull(evtcreno) || _.isUndefined(evtcreno)){
            return;
        }
        const rdvId = evtcreno.rdvId ;
        const item =  _.find(logicRacine.rdvStoreRef.current, {id:rdvId}) ;

        if(_.isNull(item) || _.isUndefined(item)){
            return;
        }
        // @ts-ignore
       rdvBusinessLogic.handleClickEditThisRdv(logicRacine, item);
    },
    selectCreneauEventProcessing: (logicRacine:any, eventCreneau: CreneauRdv) => {
        let openPopper: boolean  = true;
        if (rdvBusinessLogic.isRdvDeadLineReach(logicRacine, logicRacine.rdvEdit)) { return openPopper;}
        if (rdvPresetBusinessLogic.isRdvPresetCreneauDeletable(logicRacine, logicRacine.rdvEdit)) {
            openPopper = ! rdvPresetBusinessLogic.deletePresetCreneauRdvListInCalendarEventCreation(logicRacine, logicRacine.rdvEdit, eventCreneau);
        }
        return openPopper;
    },
    deleteRdv: async (logicRacine:any, rdvIds: number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/rdv/bouquet`, rdvIds).then((response) => {
                rdvBusinessLogic.updateDeletedRdvList(logicRacine, _.cloneDeep(logicRacine.rdvStoreRef.current), rdvIds);

                const  creneauRdvListInCalendarTodelete    = _.flatMap(logicRacine.rdvList, (item:Rdv) => {
                    return _.indexOf(rdvIds, item.id) ? item.creneauRdvs : [];
                });

                rdvBusinessLogic.updateDeletedCreneaux(logicRacine, creneauRdvListInCalendarTodelete);
                rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, logicRacine.filterRdv, false);
                logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logicRacine.snackbarShowMessage(error.response.data?.message, 'error')
                    : logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
                resolve(false);
            });
        });

    },
    handleOnNavigate:(logicRacine:any, date: Date) => rdvStateUpdate.calendarDate(logicRacine, date),
    handleSelectSlot:(logicRacine:any, slotInfo: SlotInfo) => rdvPresetBusinessLogic.selectSlotPresetProcessing(logicRacine, slotInfo, logicRacine.rdvEdit, logicRacine.minuteStep),

}
export default rdvBusinessLogic;
