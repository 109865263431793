import React from 'react';
import DialogForm from "../../../../components/ui/form/DialogForm";
import {MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import classNames from "classnames";

function ScolariteForm(props:any) {
    const { theme, title, open, setOpen, classes, onSubmit, register, handleProfileChange, profile} = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.schooling} />}
                    size="small"
                    color={"secondary"}
                    {...register("nom", {
                        required: "Schooling name is required"
                    })}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.schooling_profile} />}
                    value={profile || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    onChange={(a:any) => handleProfileChange(a)}
                    inputProps={{
                        ...register('profile', {
                            required: 'Profile is required',
                        })
                    }}
                >
                    <MenuItem value="0" sx={{fontWeight:400}}>
                        Tous les profils
                    </MenuItem>
                    {/*{USER_ROLE.map((option:any, index) => (
                        <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                            {option.name}
                        </MenuItem>
                    ))}*/}
                </TextField>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.schooling_amount} />}
                    type={"number"}
                    size="small"
                    color={"secondary"}
                    {...register("montant", {
                        required: "Schooling amount is required"
                    })}
                />
            </div>

        </DialogForm>
    );
}

export default ScolariteForm;
