
const styles = (theme:any) => ({
    root: {
        padding: theme.spacing(1),
        paddingTop:0,
        fontSize: '14px',
        '& .css-13b16ow-MuiPaper-root':{
            paddingTop:theme.spacing(2),
        },
        '& .css-reg68z-MuiTableRow-root': {
            '& .MuiTableCell-head': {
                background: 'rgba(255, 255, 255, 1)',
                fontWeight:500,
                color: theme.palette.text.primary,
                borderBottom:'1px solid #E8EAF6',
            }
        },
        '& .css-1g4dxbm-MuiTableRow-root': {
            '& .MuiTableCell-body':{
                borderBottom:'1px solid #E8EAF6',
            }
        },
        '& .MuiInputBase-adornedStart':{
            '& .css-i4bv87-MuiSvgIcon-root': {
                paddingLeft: 10,
                color: theme.palette.text.secondary,
                fontSize: '1.2rem',
            },
            '& .css-3bi8m8-MuiInputBase-input-MuiInput-input': {
                fontWeight: 400
            }
        },
        '& .css-s1hexn-MuiInputBase-input-MuiInput-input':{
            fontWeight: 400,
            padding:7,
            paddingLeft:10,
            paddingRight:10,
            '& .css-i4bv87-MuiSvgIcon-root': {
                width: '0.8em',
                height: '0.8em',
            }
        },
        '& .MuiInputAdornment-positionEnd':{
            fontSize: '1rem',
        },
        '& .css-1ptdrvf-MuiTypography-root': {
            fontWeight: 400,
            fontSize: '0.9rem',
        },
        '& .css-vq5z02-MuiTypography-root': {
            fontWeight: 400,
        },
        '& .css-e4az1k-MuiDivider-root ':{
            borderWidth: 1,
        },
        '& .css-pg1rqg-MuiTableRow-root.Mui-selected':{
            backgroundColor: '#fff',
            '& .MuiTableCell-body':{
                borderBottom:'1px solid #E8EAF6',
            },
        },
        '& .css-1wwketd-MuiInputBase-root-MuiInput-root':{
            fontWeight: 400,
            border: '1px solid rgba(0,0,0,0.15)',
            borderRadius: '6px'
        },
    },
    inputField: {
        '& .css-ov5o72-MuiTypography-root': {
            fontWeight: 'normal',
        },
        '& .MuiOutlinedInput-input':{
            fontWeight: 'normal',
            fontSize: '0.97rem',
        },
        '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
            paddingLeft:0,
            paddingRight:0
        },
    },
    inputFieldMarginBottom:{
        marginBottom: 15,
    },
    topToolbarActionsButton: {},
    iconButtonLabel:{},
    iconButton:{
        color:'white',
        width:25,
        height:25,
        padding:5,
        borderRadius:30,
        backgroundColor:theme.palette.primary.main,
        cursor:'pointer'
    },
    dialogAvatar:{
        width:125,
        margin:'auto',
        overflow:'hidden',
        display:'flex',
        marginBottom:20,
        '& $iconButtonLabel':{
            position:"relative",
            top:33,
            left:-20,
        },
        '& $iconButton:hover':{
            backgroundColor:theme.palette.primary.light,
        }
    },
    stepper:{
        backgroundColor: theme.palette.grey[100],
        padding:10,
        borderRadius:5
    },
    stepContent: {
        paddingLeft:4,
        paddingRight:10,
        minHeight:200,
    },
    childStackAvatar: {
        verticalAlign:'middle',
        marginRight:10
    },
    childStackItemName:{
        display:'inline-block',
        width:'150px'
    },
    childMiniAvatar: {
        verticalAlign:'top',
        marginRight:10
    },
    childMiniItemName:{
        display:'inline-block',
    },
    btnStep:{},
    actionBackNext:{
        display: 'flex',
        //flexDirection: 'row',
        paddingTop: 15,
        '& $btnStep':{
            textTransform:'none',
            color: theme.palette.text.secondary,
            fontWeight:'normal'
        }
    },
    errorHelper:{
        color: '#FF0000',
        fontSize: 13,
        paddingTop:7,
        paddingLeft: 2,
    },
    errorMessage: {
        flex:1,
        color: '#FF0000',
        fontSize: 14,
        marginBottom: 15,
    }

});

export default styles;
