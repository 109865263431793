import {request} from "./axios_helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../constants/type/ClasseRoom";
import _ from "lodash";
import {Child, CHILD_CONSTANT} from "../constants/type/Child";
import {MENUJOURDATA_CONSTANT} from "../constants/type/MenuJourData";
import {ImsDay, IMSDAY_CONSTANT} from "../constants/type/ImsDay";
import {Employee, EMPLOYEE_CONSTANT} from "../constants/type/Employee";
import {Fonction, FONCTION_CONSTANT} from "../constants/type/Fonction";
import {LivreCategorie, LIVRECATEGORIE_CONSTANT} from "../constants/type/LivreCategorie";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../constants/type/ScolariteType";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../constants/type/ScolariteGroup";
import {Profils, PROFILS_CONSTANT} from "../constants/type/Profils";
import {ChildStuff, CHILDSTUFF_CONSTANT} from "../constants/type/ChildStuff";
import {Livre} from "../constants/type/Livre";
import {RoleUser} from "../constants/type/RoleUser";
import {PlatCantine, PLATCANTINE_CONSTANT} from "../constants/type/PlatCantine";
import {MenuCantine, MENUCANTINE_CONSTANT} from "../constants/type/MenuCantine";
import {MenuPlatCantine, MENUPLATCANTINE_CONSTANT} from "../constants/type/MenuPlatCantine";

export const mixLoadEnfantsEmployees = (logic:any, fn:any) => {
    return new Promise<boolean>(resolve => {
        request("GET",  `/corebase/mix/enfants-employee`, {}).then((response) => {
            fn(response.data);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });
}

export const loadProfils = (logic:any) => {
    return new Promise<boolean>(resolve => {
        if(_.isObject(logic?.profilsRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET",  `/corebase/profils`, {}).then((response) => {
            const items : Profils[]   = response.data._embedded?.profilsDTOModelList ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(items,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });
}
export const loadScolariteFraisClasse = (logic:any) => {
    return new Promise<boolean>(resolve =>{
        if(_.isObject(logic?.scolariteGroupsRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET",  `/extra/scolaritedesfrais`, {}).then((response) => {
            const items : ScolariteGroup[]   = response.data._embedded?.scolariteGroupDTOModelList ?? [];
            logic.scolariteGroupsRef.current  = _.sortBy(_.map(items,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });


}

export const loadScolariteType = (logic:any) => {
    return new Promise<boolean>(resolve =>{
        if(_.isObject(logic?.scolariteTypeRef?.current) === false){
            resolve(true);
        }
        request("GET",  `/extra/scolaritelistfrais`, {}).then((response) => {
            const items : ScolariteType[]   = response.data._embedded?.scolariteTypeDTOModelList ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(items,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });

}
export const loadScolariteFrais = (logic:any) => {
    return new Promise<boolean>(resolve =>{
        request("GET",  `/extra/scolaritelistfrais`, {}).then((response) => {
            if(_.isObject(logic?.scolariteAutreFraisRef?.current) === false){
                resolve(true);
                return ;
            }
            const items : ScolariteType[]   = response.data._embedded?.scolariteTypeDTOModelList ?? [];
            const itemsfilters = _.filter(items, function(item:ScolariteType) {
                return item.autrefrais;
            });
            logic.scolariteAutreFraisRef.current  = _.sortBy(_.map(itemsfilters,  (itemfiltre:ScolariteType) => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, itemfiltre)), ['nom']);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });


}

export const loadLivresCategorie = (logic:any) => {
    return new Promise<boolean>(resolve =>{
        if(_.isObject(logic?.livreCategorieRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET",  `/corebase/categorielivre`, {}).then((response) => {
            const items : LivreCategorie[]   = response.data._embedded?.livreCategorieDTOModelList ?? [];
            logic.livreCategorieRef.current  = _.sortBy(_.map(items,  LIVRECATEGORIE_CONSTANT.convertToFrontEnd), ['nom']);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });
}

export const loadClasseRooms = (logic:any) => {
    return new Promise<boolean>(resolve =>{
        if(_.isObject(logic?.classeRoomRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET",  `/corebase/classes`, {}).then((response) => {
            const items : ClasseRoom[]   = response.data._embedded?.classeDTOModelList ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(items,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });
}

export const processingPlatCantine = (logic:any, items : PlatCantine[]) =>{
    const itemsFrom = _.map(items, PLATCANTINE_CONSTANT.convertToFrontEnd);
    const itemmap = new Map<number, PlatCantine>();
    // @ts-ignore
    itemsFrom.forEach(item => itemmap.set(item.id, item));
    logic.platCantineMapRef.current = itemmap;

    return logic ;
}

export const processingMenuCantine = (logic:any, items : MenuCantine[]) =>{
    const itemsFrom = _.map(items, MENUCANTINE_CONSTANT.convertToFrontEnd);
    const itemmap = new Map<number, MenuCantine>();
    // @ts-ignore
    itemsFrom.forEach(item => itemmap.set(item.id, item));
    logic.menuCantineMapRef.current = itemmap;

    return logic ;
}

export const processingMenuPlatCantine = (logic:any, items : MenuPlatCantine[]) =>{
    logic.menuPlatCantineListRef.current = _.map(items, MENUPLATCANTINE_CONSTANT.convertToFrontEnd);
    return logic ;
}

export const processingJobs = (logic:any, items : Fonction[]) =>{
    const itemsFrom = _.map(items, FONCTION_CONSTANT.convertToFrontEnd);
    const fonctionmap = new Map<number, Fonction>();
    // @ts-ignore
    itemsFrom.forEach(item => fonctionmap.set(item.id, item));
    logic.jobsRef.current = fonctionmap;

    return logic ;
}

export const processingChildStuffs = (logic:any, items : ChildStuff[]) =>{
    const itemsFrom = _.map(items, item => CHILDSTUFF_CONSTANT.convertToFrontEnd(logic, item));
    logic.childStuffListRef.current = _.sortBy(itemsFrom, ['nom']) ;
    return logic ;
}

export const processingEmployees = (logic:any, items : Employee[]) =>{
    const itemsmp = _.map(items, item => EMPLOYEE_CONSTANT.convertToFrontEnd(logic, item));

    logic.employeesRef.current = _.filter(itemsmp, function (item) {
        return ! _.isEmpty(item.fonctions);
    });

    if(_.isObject(logic?.employeesByFonctionRef?.current)){
        const itemsclone = _.cloneDeep(logic.employeesRef.current);
        // @ts-ignore
        logic.employeesByFonctionRef.current = itemsclone
            // @ts-ignore
            .sort((a, b) => (a.fonctions[0]?.fonctionId - b.fonctions[0]?.fonctionId || a.person.nom.localeCompare(b.person.nom)));

    }

    return logic ;
}

export const processingChildren = (logic:any, items : Child[]) =>{
    const itemsfilter  = _.filter(items, function(item) {
        if(_.isNull(item.eleves)){
            return false ;
        }
        // @ts-ignore
        if(item.eleves.length < 1){
            return false ;
        }
        return true;
    });
    const itemsclone  = _.map(itemsfilter, item => CHILD_CONSTANT.convertToFrontEnd(logic, item));
    logic.childrenRef.current = itemsclone
        // @ts-ignore
        .sort((a, b) => (a.person.nom.localeCompare(b.person.nom) || a.person.prenom.localeCompare(b.person.prenom)));

    if(_.isObject(logic?.childrenClasseRef?.current)){
        logic.childrenClasseRef.current = _.cloneDeep(logic.childrenRef.current);
        logic.childrenClasseRef.current = logic.childrenClasseRef.current
            // @ts-ignore
            .sort((a, b) => (a.eleves[0]?.classe?.nom?.localeCompare(b.eleves[0]?.classe?.nom) || a.person.nom.localeCompare(b.person.nom)) );
    }
    if(_.isObject(logic?.childrenMapRef?.current)){
       const datamap: Map<number, Child> = new Map();
       for (const child of logic.childrenRef.current) {
           datamap.set(child.id, child);
       }
       logic.childrenMapRef.current = datamap ;
    }
    return logic ;
}

export const loadChildren = (logic:any) => {
    return new Promise<boolean>(resolve => {
        if(_.isObject(logic?.childrenRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET",  `/corebase/enfants`, {}).then((response) => {
            const items : Child[]   = response.data._embedded?.enfantDTOModelList ?? [];
            const itemsfilter  = _.filter(items, function(item) {
                if(_.isNull(item.eleves)){
                    return false ;
                }
                // @ts-ignore
                if(item.eleves.length < 1){
                    return false ;
                }
                return true;
            });
            const itemsclone  = _.map(itemsfilter, item => CHILD_CONSTANT.convertToFrontEnd(logic, item));
            logic.childrenRef.current = itemsclone
                // @ts-ignore
                .sort((a, b) => (a.person.nom.localeCompare(b.person.nom) || a.person.prenom.localeCompare(b.person.prenom)));

            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        });
    });
}

export const loadLivresPagination = (logic:any, page:number, size:number, search:string) => {

    const filterApi = {
        page:page,
        size:size,
        operator: "OR",
        filters:[
            {
                key: "nom",
                operator: "CONTAIN",
                field_type: "STRING",
                value: search
            },
            {
                key: "auteur",
                operator: "CONTAIN",
                field_type: "STRING",
                value: search
            }
        ]
    };
    const params = btoa(JSON.stringify(filterApi));
    return new Promise<Livre[]>(resolve => {
        if(!_.isEmpty(search) && search.length === 1){
            resolve([]);
            return;
        }
        request("GET",  `/extra/bibliotheque/pagination`, params).then((response) => {
            const items : Livre[]   = response.data._embedded?.livreDTOModelList ?? [];
            const itemfilter = _.filter(items, item => item.common?.etat ?? false) ?? [];
            // @ts-ignore
            resolve(itemfilter);
        }).catch((error) => {
            resolve([]);
        })
    });
}
export const loadChildrenOfImsDayClasse = async (thedate:number, classeId:number, maxResult:number) => {
    return new Promise<number[]>(resolve =>
        request("GET",  `/extra/imsday/enfantids/classeId/${classeId}/thedate/${thedate}/limit/${maxResult}`, {}).then((response) => {
            resolve(response.data ?? []);
        }).catch((error) => {
            resolve([]);
        })
    );
}

export const loadMenuJourData = async (imsJour:ImsDay, updateMenuJourData:any, unixTime:number, jour:string) => {
    return new Promise<boolean>(resolve =>
        request("GET",  `/extra/menujour/menudata/date/${unixTime}/${jour}`, {}).then((response) => {
            updateMenuJourData(imsJour, MENUJOURDATA_CONSTANT.convertToFrontEnd(response.data) );
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );
}
export const loadMenuJourDataById = async (imsJour:ImsDay, updateMenuJourData:any, id:number|null) => {
    if(_.isNull(id)){
        return ;
    }

    return new Promise<boolean>(resolve =>
        request("GET",  `/extra/menujour/menudata/${id}`, {}).then((response) => {
            updateMenuJourData(imsJour, MENUJOURDATA_CONSTANT.convertToFrontEnd(response.data) );
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );
}


//TODO PAGINATION TO HANDLE
//`/extra/imsday/pagination`
export const loadImsDay = (logic:any) => {
    return new Promise<boolean>(resolve =>
        request("GET",  `/extra/imsday`, {}).then((response) => {
            let items : ImsDay[]   = response.data._embedded?.imsDayDTOModelList ?? [];


            if(logic?.isTeacherRef?.current){
                items = _.filter(items, {'classeId':logic.classeIdRef.current});
            }
            const imsdays =  _.map(items, item => IMSDAY_CONSTANT.convertToFrontEnd(logic, item))
            const imsdaysSorts =_.reverse(_.sortBy(imsdays, ['theDate'])) ;
            logic.setImsDayList(imsdaysSorts) ;
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );
}


export const loadEmployees = async (logic: any, filterApi: any) => {
    const params = btoa(JSON.stringify(filterApi));
    return new Promise<boolean>(resolve =>
        request("GET", `/corebase/employees/role/admin-teacher`, {}).then((response) => {
            if(_.isObject(logic?.employeesRef?.current) === false){
                resolve(true);
                return ;
            }
            const items: Employee[] = response.data._embedded?.employeeDTOModelList ?? [];
            const itemsmp = _.map(items, item => EMPLOYEE_CONSTANT.convertToFrontEnd(logic, item));

            logic.employeesRef.current = _.filter(itemsmp, function (item) {
                return ! _.isEmpty(item.fonctions);
            });

            if(_.isObject(logic?.employeesByFonctionRef?.current)){
                const itemsclone = _.cloneDeep(logic.employeesRef.current);
                // @ts-ignore
                logic.employeesByFonctionRef.current = itemsclone
                    // @ts-ignore
                    .sort((a, b) => (a.fonctions[0]?.fonctionId - b.fonctions[0]?.fonctionId || a.person.nom.localeCompare(b.person.nom)));
                //logic.employeesByFonctionRef.current = _.sortBy(logic.employeesByFonctionRef.current, [function(e) { return e.fonctions[0]?.fonctionId; }]);

            }
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );


}
export const loadJobs = async (logic: any, filterApi: any) => {
    const params = btoa(JSON.stringify(filterApi));
    return new Promise<boolean>(resolve => {
        if(_.isObject(logic?.jobsRef?.current) === false){
            resolve(true);
            return ;
        }
        request("GET", `/corebase/fonctions`, {}).then((response) => {
            let items: Fonction[] = response.data._embedded?.fonctionDTOModelList ?? [];
            items   = _.filter(items, item => item.role != RoleUser.SUPER_ADMIN);
            const itemsFrom = _.map(items, FONCTION_CONSTANT.convertToFrontEnd);
            const fonctionmap = new Map<number, Fonction>();
            // @ts-ignore
            itemsFrom.forEach(item => fonctionmap.set(item.id, item));
            logic.jobsRef.current = fonctionmap;
            //logic.jobsMainRef.current  = _.filter(logic.jobsRef.current, function(o) { return o.role === RoleUser.ADMIN || o.role === RoleUser.TEACHER; });
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });


}
