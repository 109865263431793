import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {RoleUser} from "./RoleUser";
import _ from "lodash";

export const FONCTION_CONSTANT = {
    convertToFrontEnd: (item:Fonction) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(fonctionDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:Fonction) => item,
};

export  const fonctionDataImpl: Fonction = {
    id: null,
    nom: '',
    couleur: '#fff',
    role: RoleUser.SUPPORT,
    common: _.cloneDeep(commonDataImp),
}

export const fonctionSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable(),
    couleur: yup.string().nonNullable(),
    role: yup.mixed<RoleUser>().oneOf(Object.values(RoleUser)).default(RoleUser.SUPPORT),
    common: yup.mixed<Common>(),
});

export type Fonction = yup.InferType<typeof fonctionSchema>;
