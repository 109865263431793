import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";


export const SCOLARITEANNEE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteAnnee) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteAnneeDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ScolariteAnnee) => item,
};

export  const scolariteAnneeDataImpl: ScolariteAnnee = {
    id: null,
    nom: '',
    dateDebut: 0,
    dateFin: 0,
    code: '',
    migrateNextYear: false,
    common: _.cloneDeep(commonDataImp)
}

export const scolariteAnneeSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nullable(),
    dateDebut: yup.number().nullable(),
    dateFin: yup.number().nullable(),
    code: yup.string().nullable(),
    migrateNextYear: yup.boolean().nullable(),
    common: yup.mixed<Common>(),
});

export type ScolariteAnnee = yup.InferType<typeof scolariteAnneeSchema>;
