import React from 'react'
import {Button} from "@mui/material";

function FormCrudButton(props:any){
    const { color, variant, textButton, icon, onClickFormCrudAction } = props
    const IconCrudButton = icon;
    return (
        <Button color={color} variant={variant} sx={{textTransform: 'none'}} size="small" onClick={() => onClickFormCrudAction() }>
            <IconCrudButton sx={{ marginRight: 1}} /> { textButton }
        </Button>
    )
}

export default FormCrudButton
