export enum RdvEtat{
    CANCEL = 'CANCEL',
    CONFIRM = 'CONFIRM',
    DONE = 'DONE',
    NOT_RESPECTED = 'NOT_RESPECTED',
    NOT_HELD = 'NOT_HELD',
    REPORT = 'REPORT',
    REPORT_CONFIRM = 'REPORT_CONFIRM',
    REOPEN = 'REOPEN',
    PARTIAL_CONFIRM = 'PARTIAL_CONFIRM',
    WAIT = 'WAIT',
    DELETED = 'DELETED',
}
