import moment from "moment/moment";
import * as yup from "yup";
import {ScolariteVersement, SCOLARITEVERSEMENT_CONSTANT, scolariteVersementDataImpl} from "./ScolariteVersement";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT, scolariteGroupDataImpl} from "./ScolariteGroup";
import {ScolariteEcheancierEnfant, SCOLARITEECHEANCIERENFANT_CONSTANT} from "./ScolariteEcheancierEnfant";
import _ from "lodash";


export const SCOLARITEETAT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteEtat) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteEtatDataImpl);
        item = {...itemInit, ...item} ;

        item.ladateForm          = moment(item.laDate);
        // @ts-ignore
        item.liredateForm   = item.ladateForm.format("L");
        item.montantFactureForm    = new Intl.NumberFormat("fr-FR").format(item.montantFacture ?? 0) ;
        item.montantPayerForm    = new Intl.NumberFormat("fr-FR").format(item.montantPayer ?? 0) ;
        item.montantSoldeForm    = new Intl.NumberFormat("fr-FR").format(item.montantSolde ?? 0) ;
        item.montantFactureALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantFactureALaDate ?? 0) ;
        item.montantPayerALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantPayerALaDate ?? 0) ;
        item.montantSoldeALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantSoldeALaDate ?? 0) ;

        // @ts-ignore
        item.nouveauVersement       = SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, item.nouveauVersement);
        // @ts-ignore
        item.scolariteGroup         = SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item.scolariteGroup);
        // @ts-ignore
        item.tousLesVersements        = _.map(item.tousLesVersements, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));
        // @ts-ignore
        item.versementsAvantLaDate        = _.map(item.versementsAvantLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));
        // @ts-ignore
        item.versementsApresLaDate        = _.map(item.versementsApresLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));

        // @ts-ignore
        item.tousLesEcheanciers        = _.map(item.tousLesEcheanciers, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierAvantLaDate        = _.map(item.echeancierAvantLaDate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierApresLadate        = _.map(item.echeancierApresLadate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));

        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteEtat) => {
        item.laDate          = item.ladateForm?.valueOf()

        // @ts-ignore
        item.nouveauVersement    = SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, item.nouveauVersement);
        // @ts-ignore
        item.scolariteGroup    = SCOLARITEGROUP_CONSTANT.convertToBackEnd(logic, item.scolariteGroup);
        // @ts-ignore
        item.tousLesVersements        = _.map(item.tousLesVersements, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));
        // @ts-ignore
        item.versementsAvantLaDate        = _.map(item.versementsAvantLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));
        // @ts-ignore
        item.versementsApresLaDate        = _.map(item.versementsApresLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));

        // @ts-ignore
        item.tousLesEcheanciers        = _.map(item.tousLesEcheanciers, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierAvantLaDate        = _.map(item.echeancierAvantLaDate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierApresLadate        = _.map(item.echeancierApresLadate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));

        return item ;
    },
};

export  const scolariteEtatDataImpl: ScolariteEtat = {
    id:``,
    laDate: 0,
    montantFacture: 0,
    montantFactureForm: 0,
    montantPayer: 0,
    montantPayerForm: ``,
    montantSolde: 0,
    montantSoldeForm: ``,
    montantFactureALaDate: 0,
    montantFactureALaDateForm: ``,
    montantPayerALaDate: 0,
    montantPayerALaDateForm: ``,
    montantSoldeALaDate: 0,
    montantSoldeALaDateForm: ``,
    nouveauVersement: _.cloneDeep(scolariteVersementDataImpl),
    scolariteGroup: _.cloneDeep(scolariteGroupDataImpl),
    tousLesVersements: [],
    versementsAvantLaDate: [],
    versementsApresLaDate: [],
    tousLesEcheanciers: [],
    echeancierAvantLaDate: [],
    echeancierApresLadate: [],
    ladateForm: null,
    liredateForm: ``,
}

export const scolariteEtatSchema = yup.object().shape({
    id: yup.string(),
    laDate: yup.number().nullable(),
    montantFacture: yup.number(),
    montantFactureForm: yup.mixed<any>().notRequired(),
    montantPayer: yup.number(),
    montantPayerForm: yup.mixed<any>().notRequired(),
    montantSolde: yup.number(),
    montantSoldeForm: yup.mixed<any>().notRequired(),
    montantFactureALaDate: yup.number(),
    montantFactureALaDateForm: yup.mixed<any>().notRequired(),
    montantPayerALaDate: yup.number(),
    montantPayerALaDateForm: yup.mixed<any>().notRequired(),
    montantSoldeALaDate: yup.number(),
    montantSoldeALaDateForm: yup.mixed<any>().notRequired(),
    nouveauVersement: yup.mixed<ScolariteVersement>().nonNullable(),
    scolariteGroup: yup.mixed<ScolariteGroup>(),
    tousLesVersements: yup.array().of(yup.mixed<ScolariteVersement>()).nonNullable().default([]),
    versementsAvantLaDate: yup.array().of(yup.mixed<ScolariteVersement>()).nonNullable().default([]),
    versementsApresLaDate: yup.array().of(yup.mixed<ScolariteVersement>()).nonNullable().default([]),
    tousLesEcheanciers: yup.array().of(yup.mixed<ScolariteEcheancierEnfant>()).nonNullable().default([]),
    echeancierAvantLaDate: yup.array().of(yup.mixed<ScolariteEcheancierEnfant>()).nonNullable().default([]),
    echeancierApresLadate: yup.array().of(yup.mixed<ScolariteEcheancierEnfant>()).nonNullable().default([]),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
});
export type ScolariteEtat = yup.InferType<typeof scolariteEtatSchema>;