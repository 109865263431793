import React, {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {ScolariteEcheancierContext} from "./ScolariteEcheancier";
import scolariteEcheancierStateUpdate from "./businesslogic/ScolariteEcheancierStateUpdate";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import scolariteEcheancierBusinessLogic from "./businesslogic/ScolariteEcheancierBusinessLogic";
import {
    getDefaultMRTOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {ScolariteEcheancier} from "../../../constants/type/ScolariteEcheancier";
import {
    EcheancierGroupingDetails,
    echeancierGroupingDetailsDataImpl
} from "../../../constants/type/EcheancierGroupingDetails";


const defaultMRTOptions = getDefaultMRTOptions<EcheancierGroupingDetails>();
function ScolariteEcheancierDetailsList() {

    const logic: any = React.useContext(ScolariteEcheancierContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;


    const addItem = () => {
        scolariteEcheancierStateUpdate.dataDetailsSel(logic, _.cloneDeep(echeancierGroupingDetailsDataImpl));
        scolariteEcheancierStateUpdate.openEditDetailsDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<ScolariteEcheancier>) => {
        const data:ScolariteEcheancier = logic.scolaEcheancierDetails[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        scolariteEcheancierStateUpdate.dataDetailsSel(logic, _.cloneDeep(data));
        scolariteEcheancierStateUpdate.openEditDetailsDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        let scolaEcheancierDetails = _.cloneDeep(logic.scolaEcheancierDetails);
        const dataNotUpdatedSelected:number[] = [];
        const dataDBSelected:number[] = [];
        rows.map((row:any) => {
            const data:ScolariteEcheancier = scolaEcheancierDetails[row.index];
            // @ts-ignore
            if(data.id > 0){
                // @ts-ignore
                dataDBSelected.push(data.id);
                return ;
            }
            // @ts-ignore
            dataNotUpdatedSelected.push(data.id);
        });
        _.remove(scolaEcheancierDetails, function(n:ScolariteEcheancier) {
            // @ts-ignore
            return dataNotUpdatedSelected.includes(n.id) ;
        });

        scolariteEcheancierStateUpdate.showDetailsTableContent(logic, false);
        scolariteEcheancierBusinessLogic.deleteDetails(
            logic,
            messages,
            dataDBSelected).then((response:any) => {
            _.remove(scolaEcheancierDetails, function(n:ScolariteEcheancier) {
                // @ts-ignore
                return dataDBSelected.includes(n.id) ;
            });
            scolariteEcheancierStateUpdate.tableDetailsKeyValue(logic, new Date().getTime());
            scolariteEcheancierStateUpdate.scolaEcheancierDetails(logic, scolaEcheancierDetails) ;
            scolariteEcheancierStateUpdate.showDetailsTableContent(logic, true);

            const dataList = _.cloneDeep(logic.dataList);
            _.remove(dataList, function(n:any) {
                return dataDBSelected.includes(n.id);
            });
            scolariteEcheancierStateUpdate.dataListSelected(logic, []);
            scolariteEcheancierStateUpdate.dataList(logic, dataList) ;
            scolariteEcheancierStateUpdate.tableKeyValue(logic, new Date().getTime());
            scolariteEcheancierStateUpdate.tableDataLoading(logic, false);
        }).catch((e:any) => scolariteEcheancierStateUpdate.showDetailsTableContent(logic, true));
    }



    const columns = useMemo<MRT_ColumnDef<EcheancierGroupingDetails>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: intl.formatMessage({id: messages.table_h_echeancier_nom}),
            },
            {
                accessorKey: 'liredateForm',
                header: intl.formatMessage({id: messages.table_h_echeancier_date}),
            },
            {
                accessorKey: 'montant',
                header: intl.formatMessage({id: messages.table_h_montant}),
                Footer: () => {
                    const isDiff = logic.totalMontant !== logic.dataSel.montant ;
                    const color = isDiff ? 'red' : undefined ;
                    const exclam = isDiff ? '!' : undefined;

                    return (<b style={{fontSize: '14px', color }}>
                        {new Intl.NumberFormat("fr-FR").format(logic.totalMontant ?? 0)} {exclam}
                    </b>)
                },
               // Footer: (<b style={{fontSize: '14px'}}>{new Intl.NumberFormat("fr-FR").format(logic.totalMontant ?? 0)}</b>),
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                // @ts-ignore
                Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
            },
        ],
        [logic.totalMontant],
    );


    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...defaultMRTOptions,
        enableColumnFilters:false,
        enableFullScreenToggle:false,
        localization,
        columns,
        data: logic.scolaEcheancierDetails,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                addDisabled={logic.disabledEcheancierdetailsEdition}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });


    useEffect(() => {
        scolariteEcheancierStateUpdate.sumMontant(logic);
    }, [logic.openEditDetailsDialogForm, logic.scolaEcheancierDetails]);


    useEffect(() => {
        if(logic.showDetailsTableContent === false){
            return ;
        }
        scolariteEcheancierStateUpdate.sumMontant(logic);
    }, [logic.showDetailsTableContent]);

    return (
        <MaterialReactTable
            table={tableConfig}
            key={logic.tableKeyValue}
        />
    );
}
export default ScolariteEcheancierDetailsList ;
