import {NumberFormatBase} from "react-number-format";
import _ from "lodash";

export const MyMoneyFormat = (props:any) => {
    const format = (numStr: any) => {
        if (numStr === "") return "";
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'XOF',
            maximumFractionDigits: 0,
        }).format(numStr);
    };

    return <NumberFormatBase {...props} format={format} />;
}