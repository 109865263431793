import {
    deleteRequest,
    getRequest,
    putRequest,
    uploadFilePostRequest,
    uploadFileRequestAsync
} from "../helpers/axios_helpers";

function MarketingService() {
    return {
        getAllProduct: async () => {
            const productReq = await getRequest('/extra/commerceproduit');
            return productReq._embedded !== undefined ? productReq._embedded?.produitDTOModelList : [];
        },
        getProductOrderList: async () => {
            const orderListReq = await getRequest('/extra/commercevente');
            return orderListReq._embedded !== undefined ? orderListReq._embedded?.produitVenteDTOModelList : [];
        },
        postNewProduct: async (data:any, file:any) => {
            try {
                let formData:any = new FormData();
                formData.append("photo", file);

                formData.append('model', new Blob([JSON.stringify(data)], {
                    type: "application/json"
                }));

                return await uploadFilePostRequest('/extra/commerceproduit', formData);
            }
            catch (error) {
                console.log(error);
            }
        },
        updateProduct: async (method:string, url:string, data:any, file:any) => {
            let formData:any = new FormData();
            formData.append("photo", file);

            formData.append('model', new Blob([JSON.stringify(data)], {
                type: "application/json"
            }));

            return await uploadFileRequestAsync(method, url, formData);
        },
        deleteMarketing: async (data:any) => {
            return await deleteRequest('/extra/commerceproduit/bouquet', data);
        },
        setProductDelivery: async (url:string, dataToSend:any) => {
            return await putRequest(url, dataToSend);
        },
        deleteOrder: async (url:string) => {
            return await deleteRequest(url, {});
        },
        reformatMarketing: (data:any, allOrderList:any) => {
            return data.map((product: any) => {
                const productPrice = new Intl.NumberFormat('fr-FR').format(product.prix);
                const ordersList = allOrderList.filter((order:any) => order.produitId === product.id);
                return {
                    ...product,
                    id: product.id,
                    nom: product.nom,
                    prix: productPrice,
                    quantite: product.quantite,
                    photo: product.photo,
                    description: product.description,
                    orders: ordersList.length,
                    originalObject: product
                }
            })
        },
        reformatOrderList: (orderList:any, childrenList:any) => {
            let orderListReformat:any = [];
            for(let i=0; i<orderList.length; i++){
                for(let j=0; j<childrenList.length; j++){
                    const enfantParents = childrenList[j].enfantParents;
                    for(let z=0; z<enfantParents.length; z++){
                        //const parentFind = enfantParents.find((parent:any) => parent.parent.id === orderList[i].parentId && parent.person.id === orderList[i].enfantId);
                        if(enfantParents[z].parent.id === orderList[i].parentId && childrenList[j].person.id === orderList[i].enfantId){
                            //console.log(enfantParents[z]);
                            const orderFormat = {
                                ...orderList[i],
                                nomEnfant: childrenList[j]?.person?.nom,
                                prenomEnfant: childrenList[j]?.person?.prenom,
                                nomParent: enfantParents[z]?.parent?.person?.nom,
                                prenomParent: enfantParents[z]?.parent?.person?.prenom,
                            }
                            orderListReformat.push(orderFormat)
                        }
                    }
                }
            }
            return orderListReformat;
        },
        reformatMarketingDataForExportXLX: (productList:any) => {
            return productList.map((product: any) => {
                return {
                    "Nom produit": product?.produit?.nom,
                    "Quantité commandée": product?.quantite,
                    "Prix": new Intl.NumberFormat('fr-FR').format(product?.produit?.prix),
                    "Description": product?.produit?.description,
                    "Nom et prénom enfant":  `${product?.nomEnfant} ${product?.prenomEnfant}`,
                    "Nom et prénom parent":  `${product?.nomParent} ${product?.prenomParent}`,
                }
            });
        }
    }
}

export default MarketingService;
