import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {LivreCategorie} from "../../../../constants/type/LivreCategorie";
import {LivreEmprunt, livreEmpruntDataImpl} from "../../../../constants/type/LivreEmprunt";
import {ClasseRoom} from "../../../../constants/type/ClasseRoom";
import {Child} from "../../../../constants/type/Child";
import {Profils} from "../../../../constants/type/Profils";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {Livre} from "../../../../constants/type/Livre";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useLivreEmpruntVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'ListeDesEmpruntsDeLivres',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<LivreEmprunt>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [initialized, setInitialized] = useState(false);


    const profilsRef  = useRef<Profils[]>([]);
    const livreCategorieRef = useRef<LivreCategorie[]>([]);
    const livresRef = useRef<Livre[]>([]);
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());
    const childrenRef  = useRef<Child[]>([]);
    const childrenClasseRef  = useRef<Child[]>([]);
    const [openLivreEmpruntEditDialogForm, setOpenLivreEmpruntEditDialogForm] = useState<boolean>(false);
    const [openLivreEmpruntDeleteDialogForm, setOpenLivreEmpruntDeleteDialogForm] = useState<boolean>(false);
    const [processingLivreEmpruntStateNormBtn, setProcessingLivreEmpruntStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const employeesRef  = useRef<Employee[]>([]);
    const employeesByFonctionRef  = useRef<Employee[]>([]);
    const [livresEmprunt, setLivresEmprunt] = useState<LivreEmprunt[]>([]);
    const [livresEmpruntSelected, setLivresEmpruntSelected] = useState<LivreEmprunt[]>([]);
    const [livreEmpruntSel, setLivreEmpruntSel] = useState<LivreEmprunt>(_.cloneDeep(livreEmpruntDataImpl));
    const [showTableContent, setShowTableContent] = useState<boolean>(true);
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openLivreEmpruntEditDialogForm,setOpenLivreEmpruntEditDialogForm,
        openLivreEmpruntDeleteDialogForm, setOpenLivreEmpruntDeleteDialogForm,processingLivreEmpruntStateNormBtn,setProcessingLivreEmpruntStateNormBtn,tableDataLoading,seTableDataLoading,
        livresEmprunt,setLivresEmprunt,livresEmpruntSelected,setLivresEmpruntSelected,livreEmpruntSel,setLivreEmpruntSel,
        tableKeyValue,setTableKeyValue,livreCategorieRef,showTableContent,setShowTableContent,livresRef,
        profilsRef,classeRoomRef,childrenRef,childrenClasseRef,classeRoomMapRef,jobsRef,employeesRef,employeesByFonctionRef,
        initialized,setInitialized,pageForm,dataCaches, dispatch,tableConfigRef,
        ...logicMRT
    }
}
export default useLivreEmpruntVariables;