import React, {useCallback, useContext} from 'react';
import {Box, IconButton, Typography} from "@mui/material";
import Popover from "@mui/material/Popover";
import {Close, Edit} from "@mui/icons-material";
import {RdvEtat} from "../../constants/type/RdvEtat";
import _ from "lodash";
import {RdvType} from "../../constants/type/RdvType";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {AppointmentPresetContext} from "./Appointment";
import rdvBusinessLogic from "./businesslogic/RdvBusinessLogic";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import popperBusinessLogic from "./businesslogic/PopperBusinessLogic";


function PopperBlock(props:any) {
    const {eventCreneau} = props;
    const logicRacine: any = useContext(AppointmentPresetContext);
    const styleClasses = logicRacine.styleClasses ;
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const menuLeaveTimerRef = React.useRef<any>();

    const handleEventCreneauClick =  useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        rdvBusinessLogic.handleClickEditThisCrenoRdv(logicRacine, eventCreneau);
        handleClose(event);
        return false;
    }, [logicRacine.eventCreneau]);


    const handleSlotEnter =  useCallback((event:React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const rdv  =  _.find(logicRacine.rdvList, {id:eventCreneau.rdvId}) ;
        const creneauRdv                = eventCreneau ;
        const creneauRdvEmployee        = eventCreneau.creneauRdvEmployees[0] ;
        const creneauRdvEnfantParent    = eventCreneau.creneauRdvEnfantParents[0]  ;

        rdvStateUpdate.reportInfoRef(logicRacine, popperBusinessLogic.updateReportInfo(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent)) ;
        rdvStateUpdate.anchorRef(logicRacine, event.relatedTarget) ;

        menuLeaveTimerRef.current = setTimeout(function () {
            /* if(rdvBusinessLogic.selectCreneauEventProcessing(logicRacine, eventCreneau)){
                 setAnchorEl(logicRacine.anchorRef.current) ;
             }*/

            setAnchorEl(logicRacine.anchorRef.current) ;
        }, 750);

        return false;
    }, [logicRacine.eventCreneau, logicRacine.rdvList, logicRacine.anchorRef.current, menuLeaveTimerRef.current]);

    const handleSlotLeave =  useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        clearTimeout(menuLeaveTimerRef.current);
        handleClose(event);
        return false;
    }, [logicRacine.eventCreneau, menuLeaveTimerRef.current]);

    const handlePopoverEnter =  useCallback((event:React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(logicRacine.anchorRef.current);
    }, [logicRacine.eventCreneau, logicRacine.anchorRef.current]);

    const handlePopoverLeave =  useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        clearTimeout(menuLeaveTimerRef.current);
        handleClose(event);
        return false;
    }, [logicRacine.eventCreneau, menuLeaveTimerRef.current]);

    const handleEdit = (event:any) => {
        clearTimeout(menuLeaveTimerRef.current);
        handleClose(event);
        rdvBusinessLogic.handleClickEditThisCrenoRdv(logicRacine, eventCreneau);
    };
    const handleClose = (event:any) => setAnchorEl(null);

    const hideTitle = eventCreneau.rdvTypeForm === RdvType.PRESET && eventCreneau.meetingStatus === RdvEtat.WAIT ;
    const styleContainer = eventCreneau.rdvTypeForm === RdvType.PRESET ? styleClasses.eventContainerPreset : styleClasses.eventContainerNormal;
   // const id = openPopper ? `simple-popover${event.id}` : undefined;
    const objetInfo = eventCreneau.rdvTypeForm === RdvType.NORMAL ? <><div style={{clear:'both'}}></div><div className={styleClasses.appointmentTitle} style={{marginLeft:4, fontWeight:500}}>{eventCreneau.rdvTitleForm}</div></> : <></> ;


    return (
        <Box className={styleContainer} >
            <div
                onClick={handleEventCreneauClick}
                onContextMenu={handleSlotEnter}
                onMouseEnter={handleSlotEnter}
                onMouseLeave={handleSlotLeave}>
                <div className={logicRacine.rdvStatusStyleRef.current.get(eventCreneau.meetingStatus)}> </div>
                <div className={styleClasses.appointmentTitle}>
                    {eventCreneau.title}
                </div>
                <div style={{clear:'both'}}></div><div className={styleClasses.appointmentTitle2} >{hideTitle ? '' : eventCreneau.rdvTitleForm}</div>
            </div>
            <Popover
               // id={id}
                open={!(_.isUndefined(anchorEl) || _.isNull(anchorEl))}
                anchorEl={anchorEl}
                anchorReference={'anchorEl'}
                onClose={(event:any) => handleClose(event)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ minWidth:650, paddingLeft:1, paddingRight:1,}} onMouseOver={handlePopoverEnter} onMouseLeave={handlePopoverLeave}>
                    <div className={styleClasses.headerTools}>
                        <div style={{display: 'inline-block', marginBottom:'8px',width:'78%'}}>
                            {
                                _.includes(eventCreneau.childphotoForm, "pictures")  &&
                                <img
                                    alt={eventCreneau.childNomPrenomForm}
                                    src={eventCreneau.childphotoForm}
                                    loading="lazy"
                                    style={{width:'45px', height:'45px',  marginRight:'4px', marginTop:'4px',verticalAlign:"middle", borderRadius:'50%', display:'inline'}}
                                />
                            }
                            <Typography sx={{verticalAlign:"middle"}} component={"h5"} color={"primary"} display="inline" className={styleClasses.appointmentTitleDetails}>
                                {logicRacine.reportInfoRef.current.title}
                            </Typography>
                        </div>
                        {
                            eventCreneau.rdvTypeForm !== RdvType.PRESET &&
                            <div className={styleClasses.actionTools}>
                                <IconButton onClick={handleEdit} sx={{margin:0, }}><Edit sx={{width:22}} color={"primary"} /></IconButton>
                                <IconButton onClick={handleClose} sx={{margin:0, }}><Close sx={{width:22}} color={"primary"} /></IconButton>
                            </div>
                        }
                    </div>
                    <div className={styleClasses.contentDetails}>
                        <div className={styleClasses.itemDetail}>
                            <div className={styleClasses.labelDetail}>
                                <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                    <FormattedMessage id={messages.rdv_requested_libelle}/>
                                </Typography>
                            </div>
                            <div className={styleClasses.infoDetail}>
                                {logicRacine.reportInfoRef.current.rdvRequestedBy}
                            </div>
                        </div>
                        <div className={styleClasses.itemDetail}>
                            <div className={styleClasses.labelDetail}>
                                <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                    Type
                                </Typography>
                            </div>
                            <div className={styleClasses.infoDetail}>
                                <FormattedMessage id={messages[`type_${_.toLower(eventCreneau.rdvTypeForm)}`]} />
                            </div>
                        </div>
                        {
                            <div className={styleClasses.itemDetail}>
                                <div className={styleClasses.labelDetail}>
                                    <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                        <FormattedMessage id={messages.rdv_status_libelle}/>
                                    </Typography>
                                </div>
                                <div className={styleClasses.infoDetail}>
                                    {logicRacine.reportInfoRef.current.subtitle}
                                </div>
                            </div>
                        }
                        <div className={styleClasses.itemDetail}>
                            <div className={styleClasses.labelDetail}>
                                <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                    <FormattedMessage id={messages.day} />
                                </Typography>
                            </div>
                            <div className={styleClasses.infoDetail}>
                                <span className={logicRacine.reportInfoRef.current.timeStyle}>{eventCreneau.startForm?.format('L')}</span>
                                {
                                    logicRacine.reportInfoRef.current.isReport &&
                                    <span>&nbsp;&nbsp;{moment(logicRacine.reportInfoRef.current.lastReportDateDebut).format('L')}</span>
                                }
                            </div>
                        </div>
                        <div className={styleClasses.itemDetail}>
                            <div className={styleClasses.labelDetail}>
                                <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                    <FormattedMessage id={messages.hour} />
                                </Typography>
                            </div>
                            <div className={styleClasses.infoDetail}>
                                <span className={logicRacine.reportInfoRef.current.timeStyle}>{eventCreneau.startForm?.format('LT')} - {eventCreneau.endForm?.format('LT')}</span>
                                {
                                    logicRacine.reportInfoRef.current.isReport &&
                                    <span>&nbsp;&nbsp;{moment(logicRacine.reportInfoRef.current.lastReportDateDebut).format('LT')} - {moment(logicRacine.reportInfoRef.current.lastReportDateFin).format('LT')}</span>
                                }
                            </div>
                        </div>
                        {
                            eventCreneau.childNomPrenomForm &&
                            <>
                                <div className={styleClasses.itemDetail}>
                                    <div className={styleClasses.labelDetail}>
                                        <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                            <FormattedMessage id={messages.child} />
                                        </Typography>
                                    </div>
                                    <div className={styleClasses.infoDetail}>
                                        {eventCreneau.childNomPrenomForm}
                                    </div>
                                </div>
                                <div className={styleClasses.itemDetail}>
                                    <div className={styleClasses.labelDetail}>
                                        <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                            Parent
                                        </Typography>
                                    </div>
                                    <div className={styleClasses.infoDetail}>
                                        {eventCreneau.parentNameForm}
                                    </div>
                                </div>
                                <div className={styleClasses.itemDetail}>
                                    <div className={styleClasses.labelDetail}>
                                        <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                            <FormattedMessage id={messages.phone_number_child} />
                                        </Typography>
                                    </div>
                                    <div className={styleClasses.infoDetail}>
                                        {eventCreneau.parentPhoneForm}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            eventCreneau.classeNameForm &&
                            <div className={styleClasses.itemDetail}>
                                <div className={styleClasses.labelDetail}>
                                    <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                        <FormattedMessage id={messages.classroom_child} />
                                    </Typography>
                                </div>
                                <div className={styleClasses.infoDetail}>
                                    {eventCreneau.classeNameForm}
                                </div>
                            </div>
                        }
                        {
                            eventCreneau.employeeFonctionForm &&
                            <div className={styleClasses.itemDetail}>
                                <div className={styleClasses.labelDetail}>
                                    <Typography className={styleClasses.titleUserInfo} variant="subtitle2">
                                        {eventCreneau.employeeFonctionForm}
                                    </Typography>
                                </div>
                                <div className={styleClasses.infoDetail}>
                                    {eventCreneau.employeeNameForm}
                                </div>
                            </div>
                        }

                    </div>

                </Box>

            </Popover>

        </Box>
    );
}

export default PopperBlock;
function useMemo(arg0: () => void, arg1: any[]) {
    throw new Error('Function not implemented.');
}

