import React from "react";
import {makeStyles} from "@mui/styles";
import {Button, TextField, Typography} from "@mui/material";
import {BASEURL} from '../../config/AppUrl';
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import styles from "./form/user-jss";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(styles);

function ResetPassword(props:any){
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.containerSide}>
                    <div className={classes.opening}>
                        <div className={classes.sideFormWrap} style={{position:'relative'}}>
                            <div style={{textAlign:"center"}} className={classes.space}>
                                <a href="https://ivorymontessorischool.com/" target="_blank" rel="noreferrer">
                                    <img src={`${BASEURL}/images/logo.png`} alt="Logo IMS" width="130" />
                                </a>
                            </div>
                            <Paper className={classes.sideResetWrap}>
                                <div className={classes.topBar}>
                                    <Typography variant="h4" className={classes.title}>
                                        <FormattedMessage id={messages.title_form_reset} />
                                    </Typography>
                                </div>
                                <section className={classes.pageFormSideWrap}>
                                    <form>
                                        <div className={classes.space}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={<FormattedMessage id={messages.email_form_reset} />}
                                                    variant="outlined"
                                                    required={true}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={classes.btnArea} style={{marginTop:50}}>
                                            <Button
                                                variant="contained"
                                                //disabled={loading}
                                                fullWidth
                                                color="primary"
                                                size="large"
                                                onClick={() => {}}
                                            >
                                                <FormattedMessage id={messages.reset_password_form_reset} />
                                            </Button>
                                        </div>
                                    </form>

                                </section>

                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
