import React, {useEffect, useState} from 'react'
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {makeStyles} from "@mui/styles";
import DayOpen from "../../components/ui/time/DayOpen";
import {List, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import SaveIcon from "@mui/icons-material/Save";
import {request} from "../../helpers/axios_helpers";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import {SCHOOL_DAY_EN, SCHOOL_DAY_FR} from "../../constants";
import LoadingButtonForm from "../../components/ui/form/LoadingButtonForm";

const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        }
    }
})


function SchoolDay(props:any) {
    const {locale, snackbarShowMessage, } = props;
    const activeLanguage = locale.split('-');
    const classes = useStyles();
    const [workDays, setWorkDays] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);

    const getDay = () => workDays.map((item:any, index:any) => {
        const dayList = activeLanguage[0] === 'en' ? SCHOOL_DAY_EN : SCHOOL_DAY_FR;
        const day:any = dayList.find((dayFind:any) => dayFind.id === item.id);
        //item.jour
        return (
            <DayOpen day={day.value} dayStatus={item.ouvrable} key={item.id} workDays={workDays} setWorkDays={setWorkDays} />
        )
    })

    const handleSaveWorkdays = () => {
        setLoadingButton(true);
        request('PUT', '/corebase/workdays/bouquet', workDays).then((response) => {
            snackbarShowMessage(<FormattedMessage id={messages.sb_edit_success} />);
            setLoadingButton(false);
        }).catch((error) => {
            // Error message
            console.log(error);
            setLoadingButton(false);
            snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
        });
    }

    useEffect(() => {
        request('GET', '/corebase/workdays', {}).then((response) => {
            const dayList:any = response.data._embedded !== undefined ?  response?.data?._embedded?.jourTravailDTOModelList : [];
            setWorkDays(dayList);
        }).catch((error) => {
            // Error message
            console.log(error);
        });
    }, []);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.root}>
                <Typography variant="h6" className={classes.title}>
                    <FormattedMessage id={messages.school_day_title} />
                </Typography>
                <List className={classes.list}>
                    { getDay() }
                </List>
                <div className={classes.save_button}>
                    <LoadingButtonForm
                        color="primary"
                        variant="contained"
                        icon={SaveIcon}
                        textButton={<FormattedMessage id={messages.save_form_crud_btn} />}
                        onClickFormCrudAction={handleSaveWorkdays}
                        loadingButton={loadingButton}
                    />
                </div>
            </div>
        </PapperBlock>
    )
}

export default withSnackbar(SchoolDay);
