import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, Icon, IconButton, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import {makeStyles} from "@mui/styles";
import styles from "./../config-table-jss";
import JobForm from "./JobForm";
import {useForm} from "react-hook-form";
import {CONSTANT, USER_ROLE} from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import {request} from "../../../helpers/axios_helpers";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../helpers/errorManager";
import _ from "lodash";
import {RoleUser} from "../../../constants/type/RoleUser";

const useStyles = makeStyles(styles);

type PersonalType = {
    id: number;
    jobName:string,
    role: string,
};

type JobFormValues = {
    id: number;
    jobName: string,
    role: string,
}

function PersonalType(props:any) {
    const {theme, locale, snackbarShowMessage} = props;
    const classes = useStyles();
    const activeLanguage = locale.split('-');
    const [jobData, setJobData] = useState<PersonalType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateJobForm, setStateJobForm] = useState(true);
    const [role, setRole] = useState('');
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleRoleChange = (event:any) => {
        setRole(event.target.value);
    }

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.title_personal_type} />,
        <FormattedMessage id={messages.dialog_job_role} />,
    ];
    const columns = useMemo<MRT_ColumnDef<PersonalType>[]>(
        () => [
            {
                accessorKey: 'jobName',
                header: columnsHeaders[0],
                size: 800,
            },
            {
                accessorKey: 'role',
                header: columnsHeaders[1],
            },
        ],
        [],
    );

    const form = useForm<JobFormValues>({
        defaultValues: {
            id: 0,
            jobName: '',
            role: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const addJob = () => {
        setStateJobForm(true);
        setValue('role', '');
        setRole('');
        setValue('jobName', '');
        handleClickOpen();
    };

    const editJob = (row: any) => {
        setStateJobForm(false);
        const dataJob: any = jobData[row.index];
        setValue('id', dataJob.id);
        USER_ROLE.map((type:any) => {
            if(type.name === dataJob.role){
                setValue('role', type.key);
                setRole(type.key);
            }
        });
        setValue('jobName', dataJob.jobName);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let jobItem:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateJobForm) {
                jobItem.id = response.data.id;
                jobItem.jobName = data.jobName;
                jobItem.role = '';
                USER_ROLE.map((type:any) => {
                    if(type.key === data.role){
                        jobItem.role = type.name;
                    }
                });
                jobItem.couleur = response.data.couleur;
                jobItem.common = response.data.common;
                setJobData([jobItem, ...jobData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                jobData.find((job:any, index:any) => {
                    if(job.id === data.id) {
                        jobData[index].jobName = data.jobName;
                        jobData[index].role = '';
                        USER_ROLE.map((type:any) => {
                            if(type.key === data.role){
                                jobData[index].role = type.name;
                            }
                        });
                        setJobData([...jobData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }
            setOpen(false);
            snackbarShowMessage(message);

        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: JobFormValues) => {
        const dataToSend = {
            nom: data.jobName,
            role: data.role,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common,
        }
        sendData(data, 'POST', "/corebase/fonctions", dataToSend);
    }

    const onSubmitEditForm = (data: JobFormValues) => {
        const jobFind: any = jobData.find(job => job.id === data.id);
        let role = 'SUPPORT';
        USER_ROLE.map((type:any) => {
            if(type.key === data.role){
                role = type.key;
            }
        });
        const dataToSend = {
            ...jobFind,
            id: data.id,
            nom: data.jobName,
            role: role,
            couleur: jobFind.couleur,
            common: jobFind.common
        }
        sendData(data, 'PUT', `/corebase/fonctions/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataJobSelectedId:any = [];
        rows.map((row:any) => {
            const data = jobData[row.index];
            dataSelected.push(data);
            dataJobSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/fonctions/bouquet', dataJobSelectedId).then((response) => {
            const jobList = jobData.filter((jobData) => {
                if(!dataSelected.includes(jobData)){
                    return jobData;
                }
            });
            setJobData([...jobList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", "/corebase/fonctions", {}).then((response) => {
            let jobListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.fonctionDTOModelList : [];
            jobListRequest   = _.filter(jobListRequest, item => item.role != RoleUser.SUPER_ADMIN);

            jobListRequest = jobListRequest.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });

            const dataList = jobListRequest.map((job:any) => {
                let jobData:any = {};
                jobData.id = job.id;
                jobData.jobName = job.nom;
                jobData.role = job.role;
                USER_ROLE.map((type:any) => {
                    if(type.key === job.role){
                        jobData.role = type.name;
                    }
                });
                jobData = {...job, ...jobData, ...{ couleur: job.couleur, common: job.common }}
                return jobData;
            });
            setJobData([...dataList]);
            setTableDataLoading(false);

        }).catch((error) => {
            // Error message
            setTableDataLoading(false);
            console.log(error);
        })
    }, [activeLanguage[0]]);

    return (
        <div className={classes.root}>
            <MaterialReactTable
                columns={columns}
                data={jobData}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact',
                    isLoading: tableDataLoading,
                }}
                localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                editDisplayMode="row"
                enableRowSelection
                positionActionsColumn="last"
                key={tableKeyValue}
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }: {table:any}) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{ display: 'flex',  flexWrap: 'wrap' }}
                    >
                        <Button variant={"contained"}
                                component="label"
                                size="small"
                                color="primary"
                                sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                onClick={() => addJob()}
                        >
                            <Icon sx={{marginRight:1}}>add</Icon>
                            <FormattedMessage id={messages.add_action} />
                        </Button>

                        <Button variant={"contained"}
                                component="label"
                                size="small"
                                color="error"
                                sx={{ textTransform: 'none', marginLeft:2, paddingLeft:1, paddingRight:2}}
                                disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <Icon sx={{marginRight:1}}>delete</Icon>
                            <FormattedMessage id={messages.delete_action} />
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                onClick={() => editJob(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />

            <JobForm
                theme={theme}
                activeLanguage={activeLanguage}
                title={
                    stateJobForm ? <FormattedMessage id={messages.dialog_title_personal_type}/> :
                        <FormattedMessage id={messages.dialog_edit_title_job}/>
                }
                classes={classes}
                open={open}
                setOpen={setOpen}
                register={register}
                onSubmit={stateJobForm ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                role={role}
                setRole={setRole}
                errors={errors}
                handleRoleChange={handleRoleChange}
            />

        </div>
    )
}

export default withSnackbar(PersonalType);
