import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";


export const IMSDAYCHILDSTUFF_CONSTANT = {
    convertToFrontEnd: (item:ImsDayChildStuff) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(imsDayChildStuffDataImp);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:ImsDayChildStuff) => item,
};

export  const imsDayChildStuffDataImp: ImsDayChildStuff = {
    id: null,
    imsDayId: 0,
    childStuffId: 0,
    quantite: 0,
    nom: ``,
    activer: false,
    common: _.cloneDeep(commonDataImp)
}

export const imsDayChildStuffSchema = yup.object().shape({
    id: yup.number().nullable(),
    imsDayId: yup.number().nonNullable(),
    childStuffId: yup.number().nonNullable(),
    quantite: yup.number().min(0),
    nom: yup.string().default(``),
    activer : yup.boolean().default(false),
    common: yup.mixed<Common>()
});

export type ImsDayChildStuff = yup.InferType<typeof imsDayChildStuffSchema>;