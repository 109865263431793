import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {makeStyles} from "@mui/styles";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import styles from "./../config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {useForm} from "react-hook-form";
import {CONSTANT} from "../../../constants";
import {request} from "../../../helpers/axios_helpers";
import SchoolDocumentForm from "./SchoolDocumentForm";
import EditIcon from "@mui/icons-material/Edit";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import _ from "lodash";

const useStyles = makeStyles(styles);

type DocumentType = {
    id: number;
    documentName: string;
};

type DocumentFormValues = {
    id: number;
    documentName: string,
}

function SchoolDocumentType(props:any){
    const { locale, theme, snackbarShowMessage } = props;
    const classes = useStyles();
    const activeLanguage = locale.split('-')
    const [schoolDocumentTypeData, setSchoolDocumentTypeData] = useState<DocumentType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateDocumentType, setStateDocumentType] = useState(true);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.title_document_type} />,
    ];

    const columns = useMemo<MRT_ColumnDef<DocumentType>[]>(
        () => [
            {
                accessorKey: 'documentName',
                header: columnsHeaders[0],
                size: 800,
            },
        ],
        [],
    );

    const form = useForm<DocumentFormValues>({
        defaultValues: {
            id: 0,
            documentName: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;


    const addSchoolDocumentType = () => {
        setStateDocumentType(true);
        setValue('documentName', '');
        handleClickOpen()
    };

    const editSchoolDocumentType = (row:any) => {
        setStateDocumentType(false);
        const dataDocument: any = schoolDocumentTypeData[row.index];
        setValue('id', dataDocument.id);
        setValue('documentName', dataDocument.documentName);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let documentTypeData:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateDocumentType) {
                documentTypeData.id = response.data.id;
                documentTypeData.documentName = data.documentName;
                documentTypeData.couleur = response.data.couleur;
                documentTypeData.common = response.data.common;
                setSchoolDocumentTypeData([documentTypeData, ...schoolDocumentTypeData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                schoolDocumentTypeData.find((document:any, index:any) => {
                    if(document.id === data.id) {
                        schoolDocumentTypeData[index].documentName = data.documentName;
                        setSchoolDocumentTypeData([...schoolDocumentTypeData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
        });
    }

    const onSubmit = (data: DocumentFormValues) => {
        const dataToSend = {
            nom: data.documentName,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/typedossier", dataToSend);
    }

    const onSubmitEditForm = (data: DocumentFormValues) => {
        const documentFind: any = schoolDocumentTypeData.find(document => document.id === data.id);
        const dataToSend = {
            ...documentFind,
            id: data.id,
            nom: data.documentName,
            //couleur: documentFind.couleur,
            //common: documentFind.common
        }

        sendData(data, 'PUT', `/corebase/typedossier/${data.id}`, dataToSend);
    };

    const handleRowsDelete = (rows:any) => {

        const dataSelected:any = [];
        const dataJobSelectedId:any = [];
        rows.map((row:any) => {
            const data = schoolDocumentTypeData[row.index];
            dataSelected.push(data);
            dataJobSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/typedossier/bouquet', dataJobSelectedId).then((response) => {
            const docList = schoolDocumentTypeData.filter((documentData) => {
                if(!dataSelected.includes(documentData)){
                    return documentData;
                }
            });
            setSchoolDocumentTypeData([...docList]);
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
        });
    }


    useEffect(() => {
        request("GET", "/corebase/typedossier", {}).then((response) => {
            const docListRequest:any = response?.data?._embedded?.typeDossierDTOModelList;
            const dataList = docListRequest.map((document:any) => {
                let docData:any = {};
                docData.id = document.id;
                docData.documentName = document.nom;
                docData = {...document, ...docData, ...{ couleur: document.couleur, common: document.common }}
                return docData;
            })
            setSchoolDocumentTypeData([...dataList]);

        }).catch((error) => {
            // Error message
            console.log(error)
        })
    }, [activeLanguage[0]]);


    return (
        <div className={classes.root}>
            <MaterialReactTable
                columns={columns}
                data={schoolDocumentTypeData}
                enableRowSelection
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact'
                }}
                localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                //editDisplayMode="row"
                //onEditingRowSave={onSubmitEdit}
                positionActionsColumn="last"
                key={new Date().getTime()}
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => addSchoolDocumentType()}
                        >
                            <FormattedMessage id={messages.add_action} />
                        </Button>

                        <Button
                            color="error"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <FormattedMessage id={messages.delete_action} />
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                onClick={() => editSchoolDocumentType(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />

            <SchoolDocumentForm
                title={
                    stateDocumentType ? <FormattedMessage id={messages.dialog_title_school_document_type} /> : <FormattedMessage id={messages.dialog_edit_title_school_document_type} />
                }
                open={open}
                setOpen={setOpen}
                classes={classes}
                theme={theme}
                register={register}
                onSubmit={stateDocumentType ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />

        </div>
    )
}
export default withSnackbar(SchoolDocumentType);
