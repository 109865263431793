import React, {useState} from "react";
import {Child} from "../../../../constants/type/Child";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {ClasseRoom, classeRoomDataImpl} from "../../../../constants/type/ClasseRoom";
import {Message, messageDataImpl} from "../../../../constants/type/Message";
import {FilDiscussion, filDiscussionDataImpl} from "../../../../constants/type/FilDiscussion";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {UserChatModel, userChatModelDataImpl} from "../../../../constants/type/UserChatModel"
import {Profils} from "../../../../constants/type/Profils";

const useMessageCenterVariables = (props: any): any => {
    const {snackbarShowMessage, theme, msgCenterStyleClasses} = props;


    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const [wsUsersConnected, setWSUsersConnected] = React.useState<UserChatModel[]>([]);
    const [wsNewSpace, setWsNewSpace] = React.useState<FilDiscussion[]>([]);
    const [wsUpdatedSpaces, setWsUpdatedSpaces] = React.useState<FilDiscussion[]>([]);
    const [wsNewMessage, setNewWsMessage] = React.useState<Message>(_.cloneDeep(messageDataImpl));
    const hideClasseRoomSelectRef = React.useRef<boolean>(false);
    const childrenRef : React.MutableRefObject<Child[]> = React.useRef<Child[]>([]);
    const childrenClasseRef : React.MutableRefObject<Child[]> = React.useRef<Child[]>([]);
    const jobsRef : React.MutableRefObject<Map<number,Fonction>> = React.useRef<Map<number,Fonction>>(new Map());
    const employeesRef : React.MutableRefObject<Employee[]> = React.useRef<Employee[]>([]);
    const employeesByFonctionRef : React.MutableRefObject<Employee[]> = React.useRef<Employee[]>([]);
    const classeRoomRef : React.MutableRefObject<ClasseRoom[]> = React.useRef<ClasseRoom[]>([]);
    const activeLanguageRef = React.useRef<any[]>(props.locale.split('-'));
    const [listeDesConversations, setListeDesConversations] = React.useState<any[]>([]);
    const [openNewTicketDialog, setOpenNewTicketDialog] = React.useState<boolean>(false);
    const [sendingMsg, setSendingMsg] = React.useState<boolean>(false);
    const [uploadingMsg, setUploadingMsg] = React.useState<boolean>(false);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [newTicketSubject, setNewTicketSubject] = React.useState<string>(``);
    const [showConversationList, setShowConversationList] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [messagesHtml, setMessagesHtml] = React.useState<any[]>([]);
    const [newChipSpaces, setNewChipSpaces] = React.useState<FilDiscussion[]>([]);
    const usersConnectedMapRef = React.useRef<Map<string,UserChatModel>>(new Map<string,UserChatModel>());
    const [showChatRoom, setShowChatRoom] = React.useState<boolean>(false);
    const [loadingMsg, setLoadingMsg] = React.useState<boolean>(false);
    const [spaceList, setSpaceList] = React.useState<FilDiscussion[]>([]);
    const [space, setSpace] = React.useState<FilDiscussion>(_.cloneDeep(filDiscussionDataImpl));
    const websocketClientRef = React.useRef<any>({});
    const websocketSubscriptMapRef = React.useRef<Map<string,any>>(new Map<string,any>());
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    const userChatRef = React.useRef<UserChatModel>(_.cloneDeep(userChatModelDataImpl));
    const userUUIDRef = React.useRef<string>(userRef.current.uuid);
    //const websocketMessageSubscriptRef = React.useRef<any>(undefined);
    const [newMessage, setNewMessage] = React.useState(true);
    const [messageFieldStatus, setMessageFieldStatus] = React.useState(false);
    const [showMobileDetail, setShowMobileDetail] = React.useState(false);
    const [isWebSocketLoaded, setIsWebSocketLoaded] = React.useState(false);
    const [childrenSelecteds, setChildrenSelecteds] = React.useState<Child[]>([]);
    const [childrenSelectList, setChildrenSelectList] = React.useState<any[]>([]);
    const [classeRoomSelected, setClasseRoomSelected] = React.useState<ClasseRoom>(_.cloneDeep(classeRoomDataImpl));
    const messageFieldRef = React.useRef<any>();
    const rooMsgContainerRef = React.useRef<any>(undefined);
    const [textMessage, setTextMessage] = useState('');
    const [anchorElHeader, setAnchorElHeader] = useState<any>(null);
    const textBtnSendRef = React.useRef<string>(activeLanguageRef.current[0] === 'en' ? 'Send' : 'Envoyé');
    const textBtnMoreRef = React.useRef<string>(activeLanguageRef.current[0] === 'en' ? 'More' : 'Plus');
    const textBtnFileAttachedRef = React.useRef<string>(activeLanguageRef.current[0] === 'en' ? 'Attach a file' : 'Joindre un fichier');
    const [expandOpenedSpace, setExpandOpenedSpace] = React.useState<boolean>(false);
    const [expandClosedSpace, setExpandClosedSpace] = React.useState<boolean>(false);
    const profilsRef : React.MutableRefObject<Profils[]> = React.useRef<Profils[]>([]);
    const [processingFolderStateNormBtn, setProcessingFolderStateNormBtn] = React.useState<boolean>(false);


    return {
        newChipSpaces,setNewChipSpaces,space,spaceList,userRef,userUUIDRef,websocketClientRef,setNewMessage,messageFieldRef,
        showContent,setShowContent,showConversationList,setShowConversationList,isWebSocketLoaded,pageForm,
        activeLanguageRef,childrenRef,childrenClasseRef,jobsRef,employeesRef,employeesByFonctionRef,classeRoomRef,
        showChatRoom, setShowChatRoom,setMessages,setSpaceList,setSpace,websocketSubscriptMapRef,messageFieldStatus,showMobileDetail,setShowMobileDetail,setIsWebSocketLoaded,
        setMessageFieldStatus, messages,snackbarShowMessage,newMessage, userChatRef,theme,msgCenterStyleClasses,
        childrenSelecteds,setChildrenSelecteds,childrenSelectList,setChildrenSelectList,textMessage,setTextMessage,
        textBtnSendRef,textBtnMoreRef,textBtnFileAttachedRef,newTicketSubject,setNewTicketSubject,usersConnectedMapRef,
        anchorElHeader,classeRoomSelected, setClasseRoomSelected,setAnchorElHeader,rooMsgContainerRef,messagesHtml,setMessagesHtml,
        expandOpenedSpace,setExpandOpenedSpace,expandClosedSpace,setExpandClosedSpace,hideClasseRoomSelectRef,sendingMsg,setSendingMsg,
        uploadingMsg,setUploadingMsg,listeDesConversations,setListeDesConversations,processingFolderStateNormBtn,setProcessingFolderStateNormBtn,
        wsUsersConnected,setWSUsersConnected,wsNewSpace,setWsNewSpace,profilsRef,openNewTicketDialog,setOpenNewTicketDialog,
        wsUpdatedSpaces,setWsUpdatedSpaces,wsNewMessage,setNewWsMessage,loadingMsg,setLoadingMsg, dispatch,dataCaches
    }
}

export default useMessageCenterVariables;