import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {CONSTANT, IMGS} from "../../constants";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import _ from "lodash";
import AddNewProduct from "./AddNewProduct";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {dropZoneFilePreview, isPositiveValue} from "../../helpers/helpers";
import MarketingService from "../../service/MarketingService";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import {request} from "../../helpers/axios_helpers";
import {getErrorMessage} from "../../helpers/errorManager";
import {pageFormImpl, setPageForm} from "../../features/page/pageSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    getDefaultMRTOptions,
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../components/ui/materialReactTable/MyMaterialReactTableEvents";

const useStyles = makeStyles(styles);

const defaultMRTOptions = getDefaultMRTOptions<any>();
function Marketing(props:any) {
    const classes = useStyles();

    const {locale, snackbarShowMessage, theme} = props;
    const intl = useIntl() ;
    const activeLang = locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'Marketing',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<any>(mytableState), []);

    const dispatch = useDispatch();
    const pageForm = useSelector((state:any) => state.page.pageForm);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [marketingData, setMarketingData] = useState<any>([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [fileDataURL, setFileDataURL] = useState(null);
    const [file, setFile] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const navigate = useNavigate();

    const marketingService = MarketingService();



    const addNewProduct = () => {
        setFileDataURL(null);
        setFile(null);
        reset({
            title: '',
            prix: null,
            quantity: null,
            description: ''
        });

        setOpen(true);
    }

    const editProduct = (row:any) => {
        const data = row.original;
        showProductDetails(data);
    }
    const showProductDetails = (data:any) => {
        navigate('/app/products/edit', {
            state:{ data },
        });
    }
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleRowsDelete = async (rows:any) => {
        try {
            const dataSelected:any = [];
            const dataMarketingSelectedId:any = [];
            rows.map((row:any) => {
                const data = marketingData[row.index];
                dataSelected.push(data);
                dataMarketingSelectedId.push(data.id);
            });

            await marketingService.deleteMarketing(dataMarketingSelectedId);
            const productList = marketingData.filter((product:any) => {
                if(!dataSelected.includes(product)){
                    return product;
                }
            });
            setMarketingData([...productList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage( <FormattedMessage id={messages.sb_delete_success} />);
        }
        catch (error) {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        }
    }

    const onSubmit = async (data:any) => {
        setLoadingButton(true);
        try {
            const dataToSend = {
                nom: data?.title?.trim(),
                prix: data?.prix,
                quantite: data?.quantity,
                photo: '',
                description: data?.description,
                common: CONSTANT.common
            }

            const response:any = await marketingService.postNewProduct(dataToSend, file);
            //const dataReform:any = [response?.data];
            const dataReform:any = marketingService.reformatMarketing([response?.data], orderList);
            setMarketingData([...dataReform, ...marketingData]);

            snackbarShowMessage( <FormattedMessage id={messages.sb_add_success} />);
            setOpen(false);
            setLoadingButton(false);
        }
        catch (error) {
            setLoadingButton(false);
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        }
    }

    const schema = yup.object().shape({
        id: yup.number(),
        title: yup.string().required(),
        prix: yup.number().required().nonNullable(),
        quantity: yup.number().required().nonNullable(),
        description: yup.string(),
    });

    const form = useForm< any | undefined> ({
        defaultValues: {},
        resolver: yupResolver(schema),
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;



    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'photo',
                header:  intl.formatMessage({id: messages.marketing_product_photo}),
                size: 100,
                grow:false,
                Cell: ({cell}) => (
                    <div style={{textAlign: 'center', width: 100}}>
                        {
                            (cell.getValue() !== null && cell.getValue() !== "") ? (
                                <img
                                    src={`${BASEURL_RESOURCES}/${cell.getValue<string>()}`}
                                    style={{textAlign: 'center', width: 80}} alt="marketing-illustration"/>
                            ) : (
                                <img
                                    src={IMGS.photoMenu}
                                    style={{textAlign: 'center', width: 80}} alt="marketing-illustration"/>
                            )
                        }
                    </div>
                ),
            },
            {
                accessorKey: 'nom',
                header: intl.formatMessage({id: messages.marketing_product_nom}),
            },
            {
                accessorKey: 'prix',
                header: intl.formatMessage({id: messages.marketing_product_pix}),
                size: 100,
                muiTableBodyCellProps: {
                    align: 'right',
                }
            },
            {
                accessorKey: 'quantite',
                header: intl.formatMessage({id: messages.marketing_product_quantity}),
                size: 80,
                muiTableBodyCellProps: {
                    align: 'right',
                }
            },
            {
                accessorKey: 'orders',
                header: intl.formatMessage({id: messages.marketing_product_command}),
                size: 80,
                muiTableBodyCellProps: {
                    align: 'right',
                }
            },
        ],
        [activeLang],
    );

    const localization = useMemo<MRT_Localization>(() =>activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: marketingData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addNewProduct}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editProduct(row)}
                styleClasses={classes}
            />
        )
    });


    useEffect(() => {
        if(pageForm){
            (async () => {
                try {
                    setTableDataLoading(true);
                    const allOrderList = await marketingService.getProductOrderList();
                    setOrderList(allOrderList);

                    let productList = await marketingService.getAllProduct();
                    productList = marketingService.reformatMarketing(productList, allOrderList);
                    setMarketingData(productList)

                    if(_.isObject(pageForm)){
                        // @ts-ignore
                        if(pageForm.cle === 'product_sales' && isPositiveValue(pageForm.id) ){
                            // @ts-ignore
                            const   dataId = pageForm.id ;
                            dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                            // @ts-ignore
                            request("GET",  `/extra/commerceproduit/${dataId}`, {}).then((response:any) => {

                                if(isPositiveValue(response.data.id)){
                                    const producToShow: any[] = [response.data];
                                    const producToShowFrontEnd = marketingService.reformatMarketing(producToShow, allOrderList);
                                    showProductDetails(producToShowFrontEnd[0]);
                                }
                                setTableDataLoading(false);
                            }).catch((error:any) => {
                                setTableDataLoading(false);
                            });
                        }else{
                            setTableDataLoading(false);
                        }
                    }else{
                        setTableDataLoading(false);
                    }

                }
                catch (error) {
                    snackbarShowMessage( getErrorMessage(error)?.message, 'error');
                }
            })()
        }
    }, [pageForm]);

    useEffect(() => {
        (async () => {
            try {
                setTableDataLoading(true);
                const allOrderList = await marketingService.getProductOrderList();
                setOrderList(allOrderList);

                let productList = await marketingService.getAllProduct();
                productList = marketingService.reformatMarketing(productList, allOrderList);
                setMarketingData(productList);

                if(_.isObject(pageForm)){
                    // @ts-ignore
                    if(pageForm.cle === 'product_sales' && isPositiveValue(pageForm.id) ){
                        // @ts-ignore
                        const   dataId = pageForm.id ;
                        dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                        // @ts-ignore
                        request("GET",  `/extra/commerceproduit/${dataId}`, {}).then((response:any) => {

                            if(isPositiveValue(response.data.id)){
                                const producToShow: any[] = [response.data];
                                const producToShowFrontEnd = marketingService.reformatMarketing(producToShow, allOrderList);
                                showProductDetails(producToShowFrontEnd[0]);
                            }
                            setTableDataLoading(false);
                        }).catch((error:any) => {
                            setTableDataLoading(false);
                        });
                    }else{
                        setTableDataLoading(false);
                    }
                }else{
                    setTableDataLoading(false);
                }

            }
            catch (error) {
               snackbarShowMessage( getErrorMessage(error)?.message, 'error');
            }
        })();

        return () => logicMRT.storeState(tableConfig.getState());
    }, []);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);



    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />
            <AddNewProduct
                title={<FormattedMessage id={messages.dialog_modal_title} /> }
                open={open}
                setOpen={setOpen}
                theme={theme}
                classes={classes}
                register={register}
                fileDataURL={fileDataURL}
                onSubmit={handleSubmit(onSubmit)}
                handleFileChange={handleFileChange}
                loadingButton={loadingButton}
            />

        </div>
    );
}

export default withSnackbar(Marketing);
