import {Livre} from "../../../../constants/type/Livre";

const livreStateUpdate:any = {
    livres: (logic:any, val:Livre[]) => logic.setLivres(val),
    livresSelected: (logic:any, val:Livre[]) => logic.setLivresSelected(val),
    livreSel: (logic:any, val:Livre) => logic.setLivreSel(val),
    file: (logic:any, val:any) => logic.setFile(val),
    fileDataURL: (logic:any, val:any) => logic.setFileDataURL(val),
    openLivreEditDialogForm: (logic:any, val:boolean) => logic.setOpenLivreEditDialogForm(val),
    openLivreDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenLivreDeleteDialogForm(val),
    processingLivreStateNormBtn: (logic:any, val:boolean) => logic.setProcessingLivreStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),

}

export default livreStateUpdate ;