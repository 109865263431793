import React, {useEffect, useState} from 'react';
import {makeStyles, styled} from "@mui/styles";
import styles from "../config/config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Avatar, Paper} from "@mui/material";
import ChildrenService from "../../service/ChildrenService";
import {BASEURL, BASEURL_RESOURCES} from "../../config/AppUrl";
import Loading from "../../components/ui/Loading";
import IconButton from "@mui/material/IconButton";
import ExportFileService from "../../service/ExportFileService";
import MiniClubService from "../../service/MiniClubService";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme:any) => {
    return {
        container: {
            fontSize: '14px',
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontSize: 18,
            fontWeight:'400 !important',
            padding:0,
            margin: 0,
            paddingBottom:10,
            borderBottom: "1px solid #ccc",
        },
    }
});

const RegisteredChildItem:any = styled(Paper)(({ theme }:{theme:any}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    paddingRight:10,
    width:'100%',
    marginBottom: 10
}));

const excelIcon = BASEURL+'/images/xls.png';
const pdfIcon = BASEURL+'/images/pdf.png';

function MiniClubRegisteredList(props:any) {
    const {
        data,
        activeLang
    } = props;
    const classes = {...useStyles(), ...useStyles2()};
    const [registeredChild, setRegisteredChild] = useState([]);
    const [loading, setLoading] = useState(true);
    const export_xls = activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const export_pdf = activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const childrenService = ChildrenService();
    const miniClubService = MiniClubService();

    const handleExportToXLX = () => {
        try {
            if(registeredChild.length > 0){
                const dataExport = miniClubService.reformatMiniClubDataForExportXLX(data, registeredChild);
                ExportFileService.exportToCSV(dataExport, 'mini-club-registered-list');
            }
        }
        catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        (async () => {
            try {
                if(data?.inscritMiniClubs.length > 0){
                    const dataChildrenReq = await childrenService.getAllChildrenData();
                    const dataChildrenList = dataChildrenReq._embedded !== undefined ? dataChildrenReq._embedded.enfantDTOModelList : [];

                    let registeredList:any = [];
                    if(dataChildrenList.length > 0 ){
                        for(let i=0; i<dataChildrenList.length; i++){
                            for (let j=0; j<data.inscritMiniClubs.length; j++){
                                if(dataChildrenList[i]?.person.id === data.inscritMiniClubs[j].enfantId) {
                                    registeredList.push(dataChildrenList[i]);
                                }
                            }
                        }
                        setRegisteredChild(registeredList);
                        setLoading(false);
                    }
                }
                else
                    setLoading(false);
            }
            catch (error) {
                console.log(error);
            }
        })();
    }, []);

    return (
        <div className={classes.container}>
            <h2 className={classes.title}> <FormattedMessage id={messages.registered_mini_club_list} /> ({data?.placeLimit})</h2>
            <div style={{padding: 20, paddingLeft: 0, paddingTop: 20, maxHeight:600}}>
                <div style={{textAlign: 'right', marginBottom: 20}}>
                    <IconButton title={export_xls} onClick={() => handleExportToXLX() }>
                        <img width="24" height="24" src={excelIcon} alt="Excel icon" />
                    </IconButton>
                    {/*<IconButton title={export_pdf}>
                        <img width="24" height="24" src={pdfIcon} alt="PDF icon" />
                    </IconButton>*/}
                </div>

                <Loading status={loading} />
                {registeredChild.length > 0 && !loading && registeredChild.map((child:any) => {
                    return (
                        <RegisteredChildItem key={child.id}>
                            <Avatar
                                className={classes.childMiniAvatar}
                                sx={{display:'inline-block', textAlign:'center',}}
                                src={`${BASEURL_RESOURCES}/${child?.person?.photo}`}
                            />
                            <div className={classes.childMiniItemName}>
                                <div>{child?.person?.nom} {child?.person?.prenom}</div>
                                <div>{child?.eleves[0]?.classe.nom}</div>
                            </div>
                        </RegisteredChildItem>
                    )
                })}

                {registeredChild.length === 0 && !loading && (
                    <div style={{textAlign: 'center'}}><FormattedMessage id={messages.empty_registered_mini_club} /></div>
                )}

            </div>
        </div>
    );
}

export default MiniClubRegisteredList;
