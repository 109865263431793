import {Parent} from "../../../../constants/type/Parent";


const securiteParentStateUpdate:any = {
    dataList: (logic:any, val:Parent[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:Parent[]) => logic.setDataListSelected(val),
    dataSel: (logic:any, val:Parent) => logic.setDataSel(val),
    confirmMessage: (logic:any, val:any) => logic.setConfirmMessage(val),
    editDisableRef: (logic:any, val:boolean) => logic.editDisableRef.current = val,
    openConfirmDialogForm: (logic:any, val:boolean) => logic.setConfirmDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    contentLoaded: (logic:any, val:boolean) => logic.setContentLoaded(val),
    showDetailsTableContent: (logic:any, val:boolean) => logic.setShowDetailsTableContent(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    tableDetailsKeyValue: (logic:any, val:any) => logic.setTableDetailsKeyValue(val),

}

export default securiteParentStateUpdate ;