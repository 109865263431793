import React from "react";
import {Autocomplete, Avatar, Box, TextField} from "@mui/material";

function UserAutoCompleteInput(props:any){
    const { locale, data, placeholderText, inputWidth, onUserChange, backgroundColor } = props
    return (
        <Autocomplete
            sx={{
                width: {
                    xs: '100%',
                    sm: '100%',
                    fontWeight: 'normal',
                }
            }} // xs: 'block', sm: 'inline-block' //inputWidth
            options={data}
            autoHighlight
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            loading={false}
            onChange={(a: any, b: any) => onUserChange(b)}
            renderOption={(props, user) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                    <Avatar alt="avatar" src={user.image} sx={{mr: 2}}/>
                    {user.firstName} {user.lastName} {user.class !== "" ? `(${user.class})` : ''}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    color="secondary"
                    variant="outlined"
                    sx={{
                        backgroundColor: backgroundColor !== 'undefined' ? backgroundColor : '',
                        marginTop: 0.4,
                        width: '100% !important',
                    }}
                    size="small"
                    placeholder={placeholderText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}

export default UserAutoCompleteInput
