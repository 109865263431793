import _ from "lodash";
import {request} from "../../../../helpers/axios_helpers";
import {LivreCategorie, LIVRECATEGORIE_CONSTANT} from "../../../../constants/type/LivreCategorie";
import categoreLivreStateUpdate from "./CategoreLivreStateUpdate";
import {FormattedMessage} from "react-intl";
import React from "react";
import {CreneauRdv} from "../../../../constants/type/CreneauRdv";
import messages from "../../../messages/general/messages";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {isPositiveValue} from "../../../../helpers/helpers";

const categoreLivreBusinessLogic:any = {
    initPostConstruct: (logicRacine:any) => {
        categoreLivreBusinessLogic.loadingDatas(logicRacine);
    },
    loadingDatas: (logicRacine:any) =>{
        categoreLivreStateUpdate.tableDataLoading(logicRacine, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/corebase/categorielivre`, {}).then((response) => {
                const items : LivreCategorie[]   = response.data._embedded?.livreCategorieDTOModelList ?? [];
                categoreLivreStateUpdate.livreCategorieList(logicRacine, _.sortBy(_.map(items,  LIVRECATEGORIE_CONSTANT.convertToFrontEnd), ['nom']));
                categoreLivreStateUpdate.tableDataLoading(logicRacine, false);
                categoreLivreStateUpdate.tableKeyValue(logicRacine, new Date().getTime());
                resolve(true);
            }).catch((error) => {
                categoreLivreStateUpdate.tableDataLoading(logicRacine, false);
                logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    saveData: async (logicRacine:any, toSend:LivreCategorie):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = isPositiveValue(toSend.id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            request(method, `/corebase/categorielivre${params}`, toSend).then((response) => {
                logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                categoreLivreBusinessLogic.loadingDatas(logicRacine);
                resolve(true);
            }).catch((error) => {
                logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    updateDataList: (logicRacine:any, datas:LivreCategorie[], newData:LivreCategorie) => {
        let events: CreneauRdv[] = [];
        if(_.isNull(newData)){
            return ;
        }
        const index = _.findIndex(datas, {id:newData.id ?? 0});
        if(index < 0){
            // @ts-ignore
            datas.unshift(newData);
        }else {
            // @ts-ignore
            datas[index] = newRdv ;
        }
        categoreLivreStateUpdate.livreCategorieList(logicRacine, _.cloneDeep(datas));
    },
}

export default categoreLivreBusinessLogic ;