import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {MenuCantineJour} from "./MenuCantineJour";
import _ from "lodash";


export const MENUCANTINE_CONSTANT = {
    convertToFrontEnd: (item:MenuCantine) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(menuCantineDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:MenuCantine) => item,
};

export  const menuCantineDataImpl: MenuCantine = {
    id: 0,
    nom: '',
    couleur: '',
    photo: '',
    menuPlatCantines: [],
    common:  _.cloneDeep(commonDataImp),
}

export const menuCantineSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nullable(),
    couleur: yup.string().nullable(),
    photo: yup.string().nullable(),
    menuPlatCantines:  yup.array().of(yup.mixed<MenuCantineJour>()).nonNullable(),
    common:  yup.mixed<Common>(),
});



export type MenuCantine = yup.InferType<typeof menuCantineSchema>;