import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {ScolariteType, scolariteTypeDataImpl} from "./ScolariteType";
import moment, {Moment} from "moment";
import {isPositiveValue} from "../../helpers/helpers";

export const SCOLARITEVERSEMENTREF_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteVersementRef) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteVersementRefDataImpl);
        item = {...itemInit, ...item} ;

        item.ladateForm          = moment(item.ladate);
        // @ts-ignore
        item.liredateForm        = item.ladateForm.format("L");
        item.dateModePaiementForm = isPositiveValue(item.dateModePaiement) ?  moment(item.dateModePaiement) : null;

        item.montantForm        = new Intl.NumberFormat("fr-FR").format(item.montant ?? 0) ;
        item.resteapayerForm    = new Intl.NumberFormat("fr-FR").format(item.resteapayer ?? 0) ;

        const scolariteType:ScolariteType     = _.find(logic.scolariteTypeRef.current, {id:item.scolariteTypeId});
        item.scolariteTypeForm  = _.isObject(scolariteType) ? scolariteType :  _.cloneDeep(scolariteTypeDataImpl);

        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteVersementRef) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};
export  const scolariteVersementRefDataImpl: ScolariteVersementRef = {
    id: null,
    versementIds: ``,
    ladate: moment().clone().startOf('day').valueOf(),
    nom: ``,
    commentaire: ``,
    montant: 0,
    groupEnfantId: 0,
    scolariteTypeId: 0,
    parentId: 0,
    code:``,
    scolaritePaiementmodeId: 1,
    codeModePaiement:'',
    modePaiementTag: 'espece',
    dateModePaiement: null,
    resteapayer: 0,
    recu:``,
    agentCaisse: ``,
    payerpar: ``,
    dateModePaiementForm: null,
    montantForm:'',
    resteapayerForm:'',
    ladateForm: moment(),
    liredateForm: '',
    scolariteTypeForm: null,
    common: _.cloneDeep(commonDataImp)
}

export const scolariteVersementRefSchema = yup.object().shape({
    id: yup.number().nullable(),
    versementIds: yup.string().nullable(),
    nom: yup.string(),
    commentaire: yup.string().notRequired(),
    ladate: yup.number().nullable(),
    montant: yup.number().nonNullable().required(),
    groupEnfantId: yup.number().nonNullable().notRequired(),
    scolariteTypeId: yup.number().nonNullable().required(),
    parentId: yup.number().nonNullable().notRequired(),
    code: yup.string().notRequired(),
    scolaritePaiementmodeId: yup.number().default(1),
    codeModePaiement: yup.string().notRequired(),
    modePaiementTag: yup.string().notRequired().default('espece'),
    dateModePaiement: yup.number().notRequired().nullable(),
    resteapayer: yup.number().notRequired(),
    agentCaisse: yup.string().notRequired(),
    recu: yup.string().notRequired(),
    payerpar: yup.string().notRequired(),
    common: yup.mixed<Common>(),
    dateModePaiementForm: yup.mixed<Moment>().notRequired().nullable(),
    montantForm:  yup.mixed<any>().notRequired(),
    resteapayerForm:  yup.mixed<any>().notRequired(),
    ladateForm: yup.mixed<any>().required(),
    liredateForm: yup.string().nonNullable().notRequired(),
    scolariteTypeForm: yup.mixed<ScolariteType>().nullable().notRequired(),
});

export type ScolariteVersementRef = yup.InferType<typeof scolariteVersementRefSchema>;