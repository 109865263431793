import React from "react";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {request} from "../../../helpers/axios_helpers";
import {loadUserNotifications} from "../../../helpers/loader_notifications";
import mesNotificationsStateUpdate from "./MesNotificationsStateUpdate";
import {EventNotification} from "../../../constants/type/EventNotification";
import {HTTP_MESSAGE_URL} from "../../../config/AppUrl";
import {getErrorMessage} from "../../../helpers/errorManager";
import {isPositiveValue} from "../../../helpers/helpers";

const mesNotificationsBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        mesNotificationsStateUpdate.contentLoaded(logic, false) ;

        loadUserNotifications(logic.userRef.current.uuid).then(value => {
            mesNotificationsStateUpdate.updateNotifs(logic, value);
            mesNotificationsStateUpdate.contentLoaded(logic, true) ;
        });
    },
    delete: async (logic:any, messages:any, userUUID:string, ids:number[]):Promise<boolean> => {
        mesNotificationsStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>(resolve =>
            request("DELETE",  `${HTTP_MESSAGE_URL}/ws/notifications/utilisateur/${userUUID}/bouquet`, ids).then((response) => {

                mesNotificationsStateUpdate.processingStateNormBtn(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                const datas = _.filter(logic.dataList, item => !ids.includes(item.id));
                mesNotificationsStateUpdate.updateNotifs(logic, datas);
                resolve(true);
            }).catch((error) => {
                mesNotificationsStateUpdate.processingStateNormBtn(logic, false);
                const errorMessage =  getErrorMessage(error);
                logic.snackbarShowMessage(errorMessage?.message, 'error')
                resolve(false);
            })
        );/*
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/EventNotification/bouquet`, ids).then((response) => {
                // @ts-ignore

                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                mesNotificationsStateUpdate.showTableContent(logic, true);
                resolve(false);
            });
        });*/
    },
    deleteDatas: async (logic:any, messages:any, userUUID:string, toSends:EventNotification[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            for(const evt of toSends){
                if(isPositiveValue(evt.id)){
                    // @ts-ignore
                    ids.push(evt.id);
                }
            }
            mesNotificationsBusinessLogic.delete(logic, messages, userUUID, ids);
        });
    }
}
export default mesNotificationsBusinessLogic ;