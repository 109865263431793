import React, {useContext} from "react";

import {Box} from "@mui/material";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {SchoolingContext} from "./SchoolingPaymentList";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SchoolingRecuPdfForm = (props:any) => {
    const logic: any = useContext(SchoolingContext);
    const styleClasses = logic.styleClasses ;
    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            width={true}
            title="FACTURE"
            open={logic.openPDFDialogForm}
            setOpen={logic.setOpenPDFDialogForm}
            btnCloseLabel={messages.dialog_close}>
                <Box  sx={{ width: '100%' }}>
                    <iframe src={logic.versementSel?.recuForm} style={{width:'100%', height:'500px'}}></iframe>
                </Box>
        </DialogFormKiller>
    );
}

export default SchoolingRecuPdfForm ;