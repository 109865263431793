import _ from "lodash";
import {DocumentScolaire, documentScolaireDataImpl} from "../../../constants/type/DocumentScolaire";
import {
    DocumentScolaireFichier,
    documentScolaireFichierDataImpl
} from "../../../constants/type/DocumentScolaireFichier";
import {Employee} from "../../../constants/type/Employee";

const schoolDocStateUpdate:any = {
    classeIdRef: (logic:any, val: number) => logic.classeIdRef.current   = val,
    teachersListRef: (logic:any, val: Employee[]) => logic.teachersListRef.current   = val,
    teacherRef: (logic:any, val: Employee | null | undefined) => logic.teacherRef.current   = val,
    isTeacherRef: (logic:any, val: boolean) => logic.isTeacherRef.current   = val,
    processingFolderStateNormBtn: (logic:any, val:boolean) => logic.setProcessingFolderStateNormBtn(val),
    openFolderEditDialogForm: (logic:any, val:boolean) => logic.setOpenFolderEditDialogForm(val),
    openFolderDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenFolderDeleteDialogForm(val),
    openFileDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenFileDeleteDialogForm(val),
    showContent: (logic:any, val:boolean) => logic.setShowContent(val),
    docFoldersSelected: (logic:any, val:DocumentScolaire[]) => logic.setDocFoldersSelected(val),
    docFolders: (logic:any, val:DocumentScolaire[]) => logic.setDocFolders(val),
    docFolderSel: (logic:any, val:DocumentScolaire) => logic.setDocFolderSel(val),
    docFileSel: (logic:any, val:DocumentScolaireFichier) => logic.setDocFileSel(val),
    showFiles: (logic:any, val:boolean) => logic.setShowFiles(val),
    numeriChange: (logic:any, val:number) => logic.setNumeriChange(val),
    goInDocFolderSel: (logic:any, val:DocumentScolaire) => {
        schoolDocStateUpdate.docFolderSel(logic, _.cloneDeep(val));
        schoolDocStateUpdate.showFiles(logic, true);
        schoolDocStateUpdate.docFileSel(logic, _.cloneDeep(documentScolaireFichierDataImpl));
        schoolDocStateUpdate.numeriChange(logic, new Date().getTime());
    },
    goOutDocFolderSel: (logic:any) => {
        logic.setDocFolderSel(_.cloneDeep(documentScolaireDataImpl));
        logic.setShowFiles(false);
    },
    addFolderToList: (logic:any, val:DocumentScolaire) => {
        let datas   = _.cloneDeep(logic.docFoldersSelected);
        datas.push(val);
        datas       = _.sortBy(datas, ['nom']);
        schoolDocStateUpdate.docFoldersSelected(logic, datas);

        let datasDocFolders   = _.cloneDeep(logic.docFolders);
        datasDocFolders.push(val);
        datasDocFolders       = _.sortBy(datasDocFolders, ['nom']);
        schoolDocStateUpdate.docFolders(logic, datasDocFolders);
    },
    updateFileToList: (logic:any, val:DocumentScolaireFichier) => {
        let docFolder   = _.cloneDeep(logic.docFolderSel);
        let datas       = _.cloneDeep(docFolder.documentScolaireFichiers);
        const sz = datas.length;
        for(let i =0; i< sz; i++){
            if(datas[i].id !== val.id){
                continue ;
            }
            datas[i] = val ;
        }
        datas       = _.sortBy(datas, ['nom']);
        docFolder.documentScolaireFichiers = datas;
        schoolDocStateUpdate.docFolderSel(logic, docFolder);
        schoolDocStateUpdate.updateFolderToList(logic, docFolder);
    },
    updateFolderToList: (logic:any, val:DocumentScolaire) => {
        let datas   = _.cloneDeep(logic.docFolders);
        const sz = datas.length;
        for(let i =0; i< sz; i++){
            if(datas[i].id !== val.id){
                continue ;
            }
            datas[i] = val ;
            break ;
        }
        datas       = _.sortBy(datas, ['nom']);
        schoolDocStateUpdate.docFolders(logic, datas);
    },
}

export default schoolDocStateUpdate ;