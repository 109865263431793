import * as React from 'react';
import { Button, Menu, MenuItem } from "@mui/material";
import { ExpandMore} from "@mui/icons-material";
import {useState} from "react";
import classNames from "classnames";
import { LOCALES } from "../../../i18n";
import {useDispatch, useSelector} from "react-redux";
import {setAppLang} from "../../../features/user/userSlice";

const values = [
    LOCALES.ENGLISH,
    LOCALES.FRENCH,
];

function SelectLanguage(props:any){
    const { locale, setLocale, classes } = props
    const [openMenuLang, setOpenMenuLang] = useState(null);
    const [anchorLang, setAnchorLang] = useState(null);
    const {lang} = useSelector((state:any) => state.user);
    const activeLanguage = lang.split('-')
    const [language, setLanguage] = React.useState(activeLanguage[0]);
    const dispatch = useDispatch();

    const handleClick= (event:any, index:any) => {
        const value = values[index].split('-');
        setLanguage(value[0]);
        setLocale(values[index]);
        dispatch(setAppLang(values[index]));
        handleClose();

    };

    const handleMenu = (menu:any) => (event:any) => {
        setOpenMenuLang(openMenuLang === menu ? null : menu);
        setAnchorLang(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorLang(null);
        setOpenMenuLang(null);
    };


    return (
        <>
            <Button variant="outlined"
                    color="primary"
                    className={classNames(classes.inputLang, classes.selectbox)}
                    size="small"
                    onClick={handleMenu('lang-setting')}
            >
                <i className={language} />
                <div className={classes.languageName}>{language}</div>
                <ExpandMore />
            </Button>
            <Menu
                id="menu-lang"
                anchorEl={anchorLang}
                open={openMenuLang === 'lang-setting'}
                onClose={handleClose}
            >
                {values && values.map((value:any, index:any) => {
                    const val = value.split('-')
                    return (
                       <MenuItem
                           key={val[0]}
                           className={classes.langItem}
                           onClick={(event:any) => handleClick(event, index)}
                           selected={value === lang}
                       >
                           <i className={val[0]} style={{ paddingTop:2}} />
                           <span style={{textTransform: "uppercase", fontSize:12}}>{val[0]}</span>
                       </MenuItem>
                    )
                })}
            </Menu>
        </>
    );

}

export default SelectLanguage
