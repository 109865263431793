import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Grid} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import InputField from "../../../components/ui/form/InputField";
import messages from "../../messages/general/messages";
import {
    SCOLARITETYPE_CONSTANT,
    scolariteTypeDataImpl,
    scolariteTypeSchema
} from "../../../constants/type/ScolariteType";
import {ScolariteAutresFraisContext} from "./ScolariteListeFrais";
import scolariteFraisStateUpdate from "./businesslogic/ScolariteFraisStateUpdate";
import scolariteFraisBusinessLogic from "./businesslogic/ScolariteFraisBusinessLogic";
import Notabene from "../../../components/ui/notabene/Notabene";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ScolariteListeFraisEditForm = (props:any) => {
    const logic: any = useContext(ScolariteAutresFraisContext);
    const styleClasses = logic.styleClasses ;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteTypeSchema),
    }));
    const {errors} = formRef.current.formState;

    
    const onSubmit = async (data: FormData) => {
        scolariteFraisStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: ScolariteType = _.cloneDeep(data);

        // @ts-ignore
        const toSend = SCOLARITETYPE_CONSTANT.convertToBackEnd(logic, dataToSend);

        scolariteFraisBusinessLogic.saveEdit(
            logic,
            messages,
            toSend,
            toSend.id).then((response:any) => {
            scolariteFraisStateUpdate.openEditDialogForm(logic, false);
            scolariteFraisStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteFraisStateUpdate.processingStateNormBtn(logic, false));
    };

    React.useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteTypeDataImpl);
            scolariteFraisStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }

        formRef.current.reset(_.cloneDeep(logic.dataSel));
        return () => {
            formRef.current.reset(_.cloneDeep(scolariteTypeDataImpl));
        };
    }, [logic.openEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_edition}/>}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <InputField
                                required
                                label={<FormattedMessage id={messages.header_nom}/>}
                                size="small"
                                color={"secondary"}
                                defaultValue={logic.dataSel.nom}
                                inputProps={{
                                    ...formRef.current.register("nom")
                                }}
                            />
                        </div>
                        <div className={styleClasses.errorHelper}>
                            {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.header_code}/>}
                                size="small"
                                color={"secondary"}
                                defaultValue={logic.dataSel.code}
                                required
                                inputProps={{
                                    ...formRef.current.register("code")
                                }}
                            />
                        </div>
                        <div className={styleClasses.errorHelper}>
                            {errors.code && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{m:0, p:1}}>
                        <Notabene
                            libelle={<FormattedMessage id={messages.header_code}/>}
                            contrainte={<FormattedMessage id={messages.must_be_uniq}/>}
                        />
                    </Grid>

                </Grid>
            </Box>
        </DialogFormKiller>
    );
}

export default ScolariteListeFraisEditForm ;