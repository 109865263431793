import classNames from "classnames";
import SondageHeader from "./SondageHeader";
import React, {useContext} from "react";
import {Box, CircularProgress} from "@mui/material";
import sondageBusinessLogic from "./businessLogic/SondageBusinessLogic";
import {SondageCenterContext} from "./SondageCenter";

const  SondageResponsesSpace = (props:any) => {
    const logic: any = useContext(SondageCenterContext);


    const listerLesReponses = React.useMemo(() => sondageBusinessLogic.listerLesReponses(logic, logic.reponseList), [logic.reponseList]);


    if(logic.showTableContent === false){
        return <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
            <CircularProgress color="primary" />
        </Box> ;
    }

    return (
        <div className={classNames(logic.chatStyles.root, logic.chatStyles.content, logic.showMobileDetail ? logic.chatStyles.detailPopup : '')}>

            <SondageHeader />

            <div className={logic.chatStyles.boxChatList} id="roomContainer">
                <div style={{height: 540,}} >
                    <ul className={logic.chatStyles.chatList}>
                       {listerLesReponses}
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default SondageResponsesSpace ;