import React, {useContext} from "react";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import scolariteEcheancierBusinessLogic from "./businesslogic/ScolariteEcheancierBusinessLogic";
import {ScolariteEcheancierContext} from "./ScolariteEcheancier";
import scolariteEcheancierStateUpdate from "./businesslogic/ScolariteEcheancierStateUpdate";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ScolariteAutresfraisDeleteForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierContext);
    const styleClasses = logic.styleClasses ;
    const scolariteEcheanciersnomsRef = React.useRef<string>(``);


    const onSubmit = async () => {
        scolariteEcheancierStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
        //const dataToSend: DocumentScolaire = _.cloneDeep(data);

        // @ts-ignore
       // const toSend = SCOLARITEPROFORMA_CONSTANT.convertToBackEnd(logic, dataToSend);

        scolariteEcheancierBusinessLogic.deleteDatas(
            logic,
            messages,
            logic.datasDeleteSelected).then((response:any) => {
            scolariteEcheancierStateUpdate.openDeleteDialogForm(logic, false);
            scolariteEcheancierStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteEcheancierStateUpdate.processingStateNormBtn(logic, false));
    };

    React.useEffect(() => {
      /*  const noms:string[] = []
        logic.dataListSelected.forEach((l:ScolariteProforma) => {
            noms.push(`${l.nom} - ${l.scolariteTypeForm?.nom} - ${l.classeForm?.nom}`);
        });
        scolariteEcheanciersnomsRef.current = _.join(noms, ', ');*/
        return () => {
        };
    }, [logic.openDeleteDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_delete} />}
            open={logic.openDeleteDialogForm}
            setOpen={logic.setOpenDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={() => onSubmit()}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.selection_to_delete} />
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default ScolariteAutresfraisDeleteForm ;