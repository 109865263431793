import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {ScolariteEcheancier, SCOLARITEECHEANCIER_CONSTANT} from "../../../../constants/type/ScolariteEcheancier";
import {processingChildren} from "../../../../helpers/loader_helpers";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import scolariteEcheancierEnfantStateUpdate from "./ScolariteEcheancierEnfantStateUpdate";
import {
    ScolariteEcheancierEnfant,
    SCOLARITEECHEANCIERENFANT_CONSTANT
} from "../../../../constants/type/ScolariteEcheancierEnfant";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {Child} from "../../../../constants/type/Child";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";
import moment from "moment";

const scolariteEcheancierEnfantBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolariteannees', 'scolaritegroups', 'groupenfants', 'enfants'];
        const convertToFrontEnd = (data:any) => {
            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            scolariteEcheancierEnfantStateUpdate.idScolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false})?.id ?? 0);

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteTypeRef.current.forEach((data:ScolariteType) => {
                logic.scolariteTypeMapRef.current.set(data.id, data);
            });


            const itemsg : ScolariteGroup[]   = data.scolaritegroups.content ?? [];
            const itemsgfilter = _.filter(itemsg, { 'echeance': true});
            logic.scolariteGroupsRef.current  = _.sortBy(_.map(itemsgfilter,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteGroupsRef.current.forEach((data:ScolariteGroup) => {
                logic.scolariteGroupsMapRef.current.set(data.id, data);
            });

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itemge : GroupEnfant[]   = data.groupenfants.content ?? [];
            logic.groupEnfantsRef.current  = _.map(itemge,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item));
        };

        scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            scolariteEcheancierEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
            scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
        }).catch(e => {
            scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
        });
    },
    saveEdit: async (logic:any, messages:any, toSend:any[], id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                // @ts-ignore
                params = `/${toSend.id}`;
            }
            request(method, `/extra/scolaritecheancierbyenfant${params}`, toSend).then((response) => {
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    generateFeesForStudent: async (logic:any, messages:any, enfantId:number, scolariteGroupId:number):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request("POST", `/extra/scolaritecheancierbyenfant/generer/enfant/${enfantId}/scolaritegroup/${scolariteGroupId}`, {}).then((response) => {
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadScolariteEcheanciers: (logic:any, enfantId:number, scolariteGroupIds:number[]) => {
        scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritecheancierbyenfant/enfant/${enfantId}/scolaritegrouplist/${_.join(scolariteGroupIds, ',')}`, {}).then((response) => {
                const items : ScolariteEcheancierEnfant[]   = response.data._embedded?.scolariteEcheancierEnfantDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']);
                scolariteEcheancierEnfantStateUpdate.dataList(logic, itemsfrontend) ;
                scolariteEcheancierEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
                scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/scolaritecheancierbyenfant/bouquet`, ids).then((response) => {
                // @ts-ignore
                const datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITEECHEANCIER_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);

                scolariteEcheancierEnfantStateUpdate.dataList(logic, itemsfrontend) ;
                scolariteEcheancierEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteEcheancierEnfantStateUpdate.tableDataLoading(logic, false);
                scolariteEcheancierEnfantStateUpdate.openDeleteDialogForm(logic, false);
                scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false);
                scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteEcheancier[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteEcheancier) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });
            scolariteEcheancierEnfantBusinessLogic.delete(logic, messages, ids);
        });
    }
}
export default scolariteEcheancierEnfantBusinessLogic ;