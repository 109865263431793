import React, {useContext} from "react";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import messages from "../messages/general/messages";
import _ from "lodash";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {AlbumPhotoContext} from "./AlbumPhoto";
import albumPhotoStateUpdate from "./businesslogic/AlbumPhotoStateUpdate";
import albumPhotoBusinessLogic from "./businesslogic/AlbumPhotoBusinessLogic";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AlbumPhotoDeleteForm = (props:any) => {
    const logic: any = useContext(AlbumPhotoContext);
    const styleClasses = logic.styleClasses ;
    const fichiersRef = React.useRef<string>(``);


    const onSubmit = async () => {
        albumPhotoStateUpdate.processingStateNormBtn(logic, true);
        const ids:number[] = _.cloneDeep(logic.photosIdsSelected);

        // @ts-ignore
        //const toSend = DOCUMENTSCOLAIRE_CONSTANT.convertToBackEnd(dataToSend);
        albumPhotoBusinessLogic.deleteFile(
            logic,
            messages,
            ids).then((response:any) => {
            albumPhotoStateUpdate.openFileDeleteDialogForm(logic, false);
            albumPhotoStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => albumPhotoStateUpdate.processingStateNormBtn(logic, false));
    };

    React.useMemo(() => {
       /* const noms:string[] = []
        logic.photoList.forEach((l:AlbumPicture) => {
            // @ts-ignore
            if(logic.photosIdsSelected.includes(l.id)){  noms.push(l.nom);}
        });
        fichiersRef.current = _.join(noms, ', ');*/
        return () => {

        };
    }, [logic.openFileDeleteDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingFolderStateNormBtn}
            title={<FormattedMessage id={messages.doc_file_delete} />}
            open={logic.openFileDeleteDialogForm}
            setOpen={logic.setOpenFileDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.selection_to_delete} />
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default AlbumPhotoDeleteForm ;