import React, {useContext} from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import classNames from "classnames";
import {Grid} from "@mui/material";
import {SondageCenterContext} from "./SondageCenter";
import sondageStateUpdate from "./businessLogic/SondageStateUpdate";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import {isPositiveValue} from "../../helpers/helpers";


const optionsOpt = [
    'Delete Conversation',
    'Option 1',
    'Option 2',
    'Option 3',
];
const ITEM_HEIGHT = 48;

function SondageHeader(props:any) {
    const logic: any = useContext(SondageCenterContext);

    const onEditeSondage = () => {
        if(_.isNumber(logic.dataSel.id) === false){
            return ;
        }
        sondageStateUpdate.openEditDialogForm(logic, true);
    }
    const onMenuClose = () => sondageStateUpdate.updateHeaderAnchor(logic, null);
   /* const onSondageDelete = () => {
        sondageBusinessLogic.deleteSpace(logic, logic.dataSel);
        onMenuClose();
    }
    const onSondageClose = () => {
        sondageBusinessLogic.closeSpace(logic, logic.dataSel);
        onMenuClose();
    }*/

    return (
        <AppBar
            position="absolute"
            className={classNames(logic.chatHeaderStyles.appBar, logic.chatHeaderStyles.fixHeight, logic.chatHeaderStyles.appBarShift)}
        >
            <div className={logic.chatHeaderStyles.cover}>
               {/* <Box sx={{ display: { xs:'inline-block', sm:'none'} }}>
                    {(logic.showMobileDetail || logic.newMessage) && (
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => sondageBusinessLogic.hideDetail(logic)}
                            className={logic.chatHeaderStyles.navIconHide}
                            size="large">
                            <ArrowBack />
                        </IconButton>
                    )}
                </Box>*/}

                {
                    isPositiveValue(logic.dataSel.id) &&
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" display="block" color="black" >
                                <IconButton
                                    aria-label="edit"
                                    onClick={onEditeSondage}
                                    className={logic.chatHeaderStyles.navIconHide}>
                                    <EditIcon />
                                </IconButton> {logic.dataSel.nom}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} style={{padding:0, margin:0}}>
                            <Typography variant="body1" display="inline" color="gray" sx={{marginLeft:'65px'}}>
                                {logic.dataSel.question}
                            </Typography>
                        </Grid>
                    </Grid>
                }

                {/*
                <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    className={logic.chatHeaderStyles.buttonMore}
                    aria-expanded={Boolean(logic.anchorElHeader) ? 'true' : undefined}
                    onClick={(evt:React.MouseEvent<HTMLButtonElement>) => sondageStateUpdate.updateHeaderAnchor(logic, evt)}
                    size="large"
                    sx={{ marginRight: 2}}
                >
                    <MoreVertIcon color="inherit" />
                </IconButton>

                <Menu
                    id="long-menu"
                    anchorEl={logic.anchorElHeader}
                    open={Boolean(logic.anchorElHeader)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    onClose={onMenuClose}
                >
                    <MenuItem onClick={() => onSondageClose()}>
                        <FormattedMessage id={messages.close_space} />
                    </MenuItem>
                    <MenuItem onClick={() => onSondageDelete()}>
                        <FormattedMessage id={messages.delete_space} />
                    </MenuItem>
                </Menu>
                */}
            </div>
        </AppBar>
    );
}

export default SondageHeader;
