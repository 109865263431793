import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import classNames from "classnames";
import DialogForm from "../../../components/ui/form/DialogForm";
import {MenuItem, TextField} from "@mui/material";
import {USER_ROLE} from "../../../constants";

function JobForm(props:any) {
    const { theme, activeLanguage, classes, open, setOpen, register, title, onSubmit, errors, role, handleRoleChange } = props;

    return (
        <DialogForm title={title} open={open} setOpen={setOpen} theme={theme} onSubmit={onSubmit}>
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.dialog_job_role} />}
                    defaultValue={''}
                    value={role || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    onChange={(a:any) => handleRoleChange(a)}
                    inputProps={{
                        ...register('role', {
                            required: 'Role is required',
                        })
                    }}
                >
                    {USER_ROLE.map((option:any, index) => (
                        <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                            { option.name }
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.title_personal_type}/>}
                    size="small"
                    color={"secondary"}
                    {...register("jobName", {
                        required: "Job name is required"
                    })}
                />
            </div>
        </DialogForm>
    );
}

export default JobForm;
