import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";

export const PROFILS_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Profils) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(profilsDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:Profils) => item,
};

export  const profilsDataImpl: Profils = {
    id: null,
    nom: '',
    common: _.cloneDeep(commonDataImp)
}

export const profilsSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    common: yup.mixed<Common>()
});

export type Profils = yup.InferType<typeof profilsSchema>;