import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import {TextField} from "@mui/material";
import DialogForm from "../../../components/ui/form/DialogForm";

function SchoolDocumentForm(props:any) {
    const { theme, open, setOpen, classes, onSubmit, register, title} = props;
    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classes.inputField}>
                <TextField
                    label={<FormattedMessage id={messages.title_school_document_type}/>}
                    size="small"
                    color={"secondary"}
                    {...register("documentName", {
                        required: "Document name is required"
                    })}
                />
            </div>
        </DialogForm>
    );
}

export default SchoolDocumentForm;
