import React, {useContext, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {Button} from "@mui/material";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import scolariteEcheancierEnfantStateUpdate from "./businesslogic/ScolariteEcheancierEnfantStateUpdate";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    columnsEcheancierEnfant,
    excelEcheancierEnfantDataMapping,
    ScolariteEcheancierEnfant,
    scolariteEcheancierEnfantDataImpl
} from "../../../constants/type/ScolariteEcheancierEnfant";
import {ScolariteEcheancierEnfantContext} from "./ScolariteEcheancierEnfant";
import {isPositiveValue} from "../../../helpers/helpers";
import CachedIcon from '@mui/icons-material/Cached';
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function ScolariteEcheancierEnfantList() {
    const logic: any = useContext(ScolariteEcheancierEnfantContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
   // const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const addItem = () => {
        scolariteEcheancierEnfantStateUpdate.dataSel(logic, _.cloneDeep(scolariteEcheancierEnfantDataImpl));
        scolariteEcheancierEnfantStateUpdate.openEditDialogForm(logic, true);
    }
    const generateItems = () => {
        scolariteEcheancierEnfantStateUpdate.openGenerateDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<ScolariteEcheancierEnfant>) => {
        const data:ScolariteEcheancierEnfant = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        scolariteEcheancierEnfantStateUpdate.dataSel(logic, _.cloneDeep(data));
        scolariteEcheancierEnfantStateUpdate.openEditDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        const dataSelected:ScolariteEcheancierEnfant[] = [];
        rows.map((row:any) => {
            const data:ScolariteEcheancierEnfant = logic.dataList[row.index];
            dataSelected.push(data);
        });
        scolariteEcheancierEnfantStateUpdate.dataListSelected(logic, dataSelected);
        scolariteEcheancierEnfantStateUpdate.openDeleteDialogForm(logic, true);
    };


    const GenererButton = () => (
        <Button variant={"contained"}
                disabled={!isPositiveValue(logic.childSelected.id)}
                component="label"
                size="small"
                color="primary"
                sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                startIcon={<CachedIcon />}
                onClick={generateItems}
        >
            <FormattedMessage id={messages.generer_action} />
        </Button>
    );

    const columns = useMemo<MRT_ColumnDef<ScolariteEcheancierEnfant>[]>(
        // @ts-ignore
        () => [...columnsEcheancierEnfant({intl:intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                addDisabled={!isPositiveValue(logic.childSelected.id)}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier={`Financial_Schedules_Of_${logic.childSelected.childNomPrenomForm}`}
                excelDataMapping={excelEcheancierEnfantDataMapping({
                    rows: table?.getPrePaginationRowModel().rows,
                    columns: table?.getVisibleFlatColumns(),
                    table: table,
                    intl,
                })}
                styleClasses={styleClasses}
                otherComponents={<GenererButton />}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

    return(
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default ScolariteEcheancierEnfantList ;