import React from 'react';
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import Face2Icon from '@mui/icons-material/Face2';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

function SchoolingSubMenu(props:any) {
    const {classes, selected} = props;

    return (
        <>
            <Button
                className={classNames(classes.button, selected === 'parent' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                //color="secondary"
                component={Link} to="/app/security/parent"
                startIcon={<Face2Icon />}
            >
                <FormattedMessage id={messages.gestion_compte_parent} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'employee' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/security/employee"
                startIcon={<WorkspacesIcon />}
            >
                <FormattedMessage id={messages.gestion_compte_equipe} />
            </Button>
        </>
    );
}

export default SchoolingSubMenu;
