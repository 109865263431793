import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import {
    columnsScolariteGroupPlugin,
    excelScolariteGroupDataMappingPlugin,
    ScolariteGroup,
    scolariteGroupCustomOptionsPlugins,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import messages from "../../page/messages/general/messages";
import {Child, childDataImpl, columnsChildPlugin, excelChildDataMappingPlugin} from "./Child";

export const SCOLARITEECHEANCIERENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteEcheancierEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteEcheancierEnfantDataImpl);
        const data = {...itemInit, ...item} ;

        data.childForm           = logic.childrenMapRef?.current.get(item.enfantId) ?? _.cloneDeep(childDataImpl);
        data.ladateForm          = isPositiveValue(data.ladate) ? moment(data.ladate) : undefined;
        // @ts-ignore
        data.liredateForm        = _.isObject(data.ladateForm) ? data.ladateForm.format("L") : ``;
        // @ts-ignore
        data.rappeldateForm      = isPositiveValue(data.rappeldate) ? moment(data.rappeldate) : null;

        data.scolariteGroupForm   = logic.scolariteGroupsMapRef?.current.get(data.scolariteGroupId)  ?? _.cloneDeep(scolariteGroupDataImpl);

        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteEcheancierEnfant) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};
export  const scolariteEcheancierEnfantDataImpl: ScolariteEcheancierEnfant = {
    id: null,
    ladate: 0,
    nom: ``,
    code: ``,
    nomFraisGroup: ``,
    enfantId: 0,
    scolariteGroupId: 0,
    scolariteTypeId: 0,
    scolariteEcheancierId:0,
    groupesectionIds: [],
    IdsToDelete: [],
    groupesectionId: 0,
    montant: 0,
    rappeldate: null,
    scolariteGroupForm: null,
    ladateForm: null,
    childForm: _.cloneDeep(childDataImpl),
    liredateForm: '',
    scolariteTypeNomForm: '',
    rappeldateForm: null,
    scolariteTypeTag: '',
    infos: '',
    common: _.cloneDeep(commonDataImp),
}

export const scolariteEcheancierEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string(),
    code: yup.string().nullable(),
    ladate: yup.number().nullable(),
    enfantId: yup.number().nonNullable().notRequired(),
    scolariteGroupId: yup.number().nonNullable().required(),
    scolariteTypeId: yup.number().nonNullable().required(),
    scolariteEcheancierId: yup.number().nonNullable().notRequired().default(0),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    groupesectionId: yup.number().nonNullable().default(0).notRequired(),
    nomFraisGroup: yup.string().nonNullable().notRequired(),
    montant: yup.number().default(0).min(0).notRequired(),
    rappeldate: yup.number().nullable().notRequired(),
    childForm: yup.mixed<Child>().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().nullable().notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    infos: yup.string().notRequired(),
    scolariteTypeNomForm: yup.string().notRequired(),
    common: yup.mixed<Common>(),
});


export const excelEcheancierEnfantDataMapping = (
    {rows=[], columns=[], intl={},  table=[]}:{ rows:any[]; intl:any;  table: any; columns: any[];}
) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj = excelChildDataMappingPlugin({obj, row:data, columns, table, prefix:'childForm.', intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
        obj = excelScolariteGroupDataMappingPlugin({obj, row:data, table, intl, columns, prefix:'scolariteGroupForm.', infoSpace:intl.formatMessage({id:messages.fees})});
        //obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteGroupForm?.scolariteAnneeForm?.nom;

        if(isColumnVisible(columns, 'nom')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, 'code')){
            obj[`${intl.formatMessage({id:messages.header_code})}`] = data.code;
        }
        if(isColumnVisible(columns, 'liredateForm')){
            obj[`${intl.formatMessage({id:messages.date_limite})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, 'montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        return obj;
    });
}

export const echeancierEnfantCustomOptions = {
    columnVisibility : {
        //...childCustomOptionsPlugins.columnVisibility,
        ...scolariteGroupCustomOptionsPlugins.columnVisibility,
        'code':false,
    }
} ;

export const echeancierEnfantCustomOptionsPlugin = {
    columnVisibility : {
        ...echeancierEnfantCustomOptions.columnVisibility,
    }
} ;

export const columnsEcheancierEnfant = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsChildPlugin({intl, prefixKey:`childForm.`, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        ...columnsScolariteGroupPlugin({intl, prefixKey:`scolariteGroupForm.`, nomSpace: intl.formatMessage({id:messages.fees})}),
        {
            accessorKey: 'nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            accessorKey: 'code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;

export type ScolariteEcheancierEnfant = yup.InferType<typeof scolariteEcheancierEnfantSchema> ;
