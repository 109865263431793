import { Checkbox, Grid } from "@mui/material";
import PapperBlock from "../papperBlock/PapperBlock";
import {Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart} from "recharts";
import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import colorfull from "../../../data/colorfull";
import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";

const color = {
    main: colorfull[2],
    secondary: colorfull[3],
    third: colorfull[0],
    fourth: colorfull[1],
    fifth: colorfull[6],
};
const useStyles = makeStyles((theme:any) => {
    return {
        chartWrap: {
            width: '100%',
            overflow: 'auto',
            margin: theme.spacing(2),
        },
        chartFluid: {
            width: '100%',
            //minWidth: 400,
            height: 300,
            marginLeft: theme.spacing(1) * -3,
        },
        tooltipLabel: {
            fontSize: 'normal'
        }
    }
})

export default function AnalyticSchoolingChartWidget(props:any){
    const {data, legend, countCurve, setCountCurve} = props;
    const classes = useStyles();
    const [checked, setChecked] = useState(['bolinger', 'stoch']);

    const handleToggle = (value:any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    useEffect(() => {
        //setCountCurve((prev:number) => prev+1);
        //console.log(countCurve)

    }, []);

    return (
        <PapperBlock whiteBg noMargin title={<FormattedMessage id={messages.turnover_schooling_compare} />} icon="timeline" desc="">
            <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                    <div className={classes.chartWrap}>
                        <div className={classes.chartFluid}>
                            {/* width="100%" height="100%"*/}
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart data={data}>
                                    <XAxis dataKey="name" tickLine={false} />
                                    <YAxis
                                        axisLine={false}
                                        tickSize={3}
                                        tickLine={false}
                                        //tick={{ stroke: 'none' }}
                                    />
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <Tooltip formatter={(value:any) => new Intl.NumberFormat("fr-FR").format(value ?? 0)} />
                                    {checked.indexOf('bolinger') > -1 && (
                                        <Area
                                            type="monotone"
                                            stackId="1"
                                            dataKey="deadline"
                                            stroke={color.fifth}
                                            fill={color.fifth}
                                            name={legend.deadline}
                                            height={300}
                                        />
                                    )}
                                    {checked.indexOf('stoch') > -1 && (
                                        <Area
                                            type="monotone"
                                            stackId="2"
                                            dataKey="payment"
                                            stroke={color.main}
                                            fill={color.main}
                                            name={legend.payment}
                                            height={300}
                                        />
                                    )}
                                </ComposedChart>
                            </ResponsiveContainer>

                        </div>
                        <div style={{textAlign:"center"}}>
                            <span onClick={handleToggle('bolinger')} style={{color:color.fifth, verticalAlign: "middle" }}>
                                <Checkbox checked={checked.indexOf('bolinger') !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                          icon={<Brightness1OutlinedIcon fontSize="small" />}
                                          checkedIcon={<Brightness1Icon fontSize="small" />} style={{color:color.fifth}}
                                /> {<FormattedMessage id={messages.turnover_schooling_forecast} />}
                            </span>
                            <span onClick={handleToggle('stoch')} style={{color:color.main}}>
                                <Checkbox checked={checked.indexOf('stoch') !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                          icon={<Brightness1OutlinedIcon fontSize="small" />}
                                          checkedIcon={<Brightness1Icon fontSize="small" />} style={{color:color.main}}
                                />
                                {<FormattedMessage id={messages.turnover_schooling_actual} />}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </PapperBlock>
    )
}
