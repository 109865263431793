import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {useForm} from "react-hook-form";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import SubFolderSubMenu from "../SubFolderSubMenu";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderTypeForm from "./FolderTypeForm";
import {withSnackbar} from "../../../../components/ui/snackbar/SnackbarHOC";
import {request} from "../../../../helpers/axios_helpers";
import {CONSTANT} from "../../../../constants";
import {getErrorMessage} from "../../../../helpers/errorManager";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'blue',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.primary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type FolderType = {
    id: number;
    folderName: string;
    folderConstitutive: [];
};


type FolderFormValues = {
    id: number;
    folderName: string,
    filesType: number[],
}

type SubFolderType = {
    id: number;
    fileName: string;
}


function FolderType(props:any) {
    const { locale, theme, snackbarShowMessage } = props;
    const classes = {...useStyles(), ...useStyles2()};
    const activeLanguage = locale.split('-');
    const [folderTypeData, setFolderTypeData] = useState<FolderType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateFolderType, setStateFolderType] = useState(true);
    const [subFolderTypeData, setSubFolderTypeData] = useState<SubFolderType[]>([]);
    const [subFolderFileChecked, setSubFolderFileChecked] = useState([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.folder_type} />,
    ];
    const columns = useMemo<MRT_ColumnDef<FolderType>[]>(
        () => [
            {
                accessorKey: 'folderName',
                header: columnsHeaders[0],
                size: 800,
            },
        ],
        [],
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const form = useForm<FolderFormValues>({
        defaultValues: {
            id: 0,
            folderName: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const addSubFolderType = () => {
        setStateFolderType(true);
        setValue('folderName', '');
        setValue('filesType', []);
        setSubFolderFileChecked([]);
        handleClickOpen();
    }

    const editSubFolderType = (row:any) => {
        setStateFolderType(false);
        const dataFolderType: any = folderTypeData[row.index];
        setValue('id', dataFolderType.id);
        setValue('folderName', dataFolderType.folderName);

        const subFolderTypeConstitutive:any = dataFolderType.folderConstitutive;
        const subFolderTypeId = subFolderTypeConstitutive.map((subFileType:any) => {
            return subFileType.typeSousDossierId;
        });

        setValue('filesType', subFolderTypeId);
        setSubFolderFileChecked(subFolderTypeId);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let folderData:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateFolderType) {
                folderData.id = response.data.id;
                folderData.folderName = data.folderName;
                folderData.couleur = response.data.couleur;
                folderData.folderConstitutive = response.data.dossierConstitutifs;
                folderData.common = response.data.common;
                setFolderTypeData([folderData, ...folderTypeData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                folderTypeData.find((folderType:any, index:any) => {
                    if(folderType.id === data.id) {
                        folderTypeData[index].folderName = data.folderName;
                        folderTypeData[index].folderConstitutive = response.data.dossierConstitutifs;
                        setFolderTypeData([...folderTypeData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: FolderFormValues) => {
        if(data.filesType.length > 0) {
            const folderConstitutive = data.filesType.map((fileTypeId:any) => {
                const folder:any = {
                    typeSousDossierId: fileTypeId,
                    details: '',
                    common: CONSTANT.common,
                }
                return folder;
            });

            const dataToSend = {
                nom: data.folderName,
                couleur: 'rgba(150, 145, 120, 0.7)',
                common: CONSTANT.common,
                dossierConstitutifs:folderConstitutive
            }
            sendData(data, 'POST', "/corebase/typedossier", dataToSend);
        }
    }

    const onSubmitEditForm = (data: FolderFormValues) => {
        const folderTypeFind: any = folderTypeData.find(fileType => fileType.id === data.id);
        const constitutiveFolder:any = folderTypeFind.folderConstitutive;
        const folderConstitutive = data.filesType.map((fileTypeId:number) => {
            let folderConstitutiveId = null;
            for(let i=0; i < constitutiveFolder.length; i++){
                if(constitutiveFolder[i].typeSousDossierId == fileTypeId){
                    folderConstitutiveId = constitutiveFolder[i].id;
                }
            }

            const folder:any = {
                id: folderConstitutiveId,
                typeDossierId: data.id,
                typeSousDossierId: fileTypeId,
                details: '',
                common: CONSTANT.common,
            }
            return folder;
        });

        const dataToSend = {
            ...folderTypeFind,
            id: data.id,
            nom: data.folderName,
            //couleur: folderTypeFind.couleur,
            dossierConstitutifs:folderConstitutive,
            //common: folderTypeFind.common,
        }

       sendData(data, 'PUT', `/corebase/typedossier/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataFolderSelectedId:any = [];
        rows.map((row:any) => {
            const data = folderTypeData[row.index];
            dataSelected.push(data);
            dataFolderSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/typedossier/bouquet', dataFolderSelectedId).then((response) => {
            const fileList = folderTypeData.filter((folderType) => {
                if(!dataSelected.includes(folderType)){
                    return folderType;
                }
            });
            setFolderTypeData([...fileList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", "/corebase/typedossier", {}).then((response) => {
            const fileListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.typeDossierDTOModelList : [];
            const dataList = fileListRequest.map((folterType:any) => {
                let fileData:any = {};
                fileData.id = folterType.id;
                fileData.folderName = folterType.nom;
                fileData = {...folterType, ...fileData, ...{ couleur: folterType.couleur, folderConstitutive:folterType.dossierConstitutifs, common: folterType.common }}
                return fileData;
            })
            setFolderTypeData([...dataList]);
            setTableDataLoading(false);
        }).catch((error) => {
            // Error message
            setTableDataLoading(false);
        })
    }, [activeLanguage[0]]);

    useEffect(() => {
        request("GET", "/corebase/typesousdossier", {}).then((response) => {
            const fileListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.typeSousDossierDTOModelList : [];
            const dataList = fileListRequest.map((fileType:any) => {
                let fileData:any = {};
                fileData.id = fileType.id;
                fileData.fileName = fileType.nom;
                fileData = {...fileType, ...fileData, ...{ couleur: fileType.couleur, common: fileType.common }}
                return fileData;
            })

            setSubFolderTypeData([...dataList]);

        }).catch((error) => {
            // Error message
            console.log(error)
        })
    }, [activeLanguage[0]]);

    return (
        <div className={classes.root}>
            <Box className={classes.buttonContainer}>
                <SubFolderSubMenu classes={classes} selected="foldertype" />
            </Box>

            <div className={classes.tableContainer}>
                <MaterialReactTable
                    columns={columns}
                    data={folderTypeData}
                    enableRowSelection
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact',
                        isLoading: tableDataLoading,
                    }}
                    localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing
                    positionActionsColumn="last"
                    key={tableKeyValue}
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={classes.topToolbarActionsButton}
                            sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => addSubFolderType()}
                                disabled
                            >
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button
                                color="error"
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                disabled
                                onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                            >
                                <FormattedMessage id={messages.delete_action} />
                            </Button>
                        </Box>
                    )}
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '0.3rem'}}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                                <IconButton
                                    onClick={() => editSubFolderType(row)}
                                    sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />

                <FolderTypeForm
                    title={ stateFolderType ? <FormattedMessage id={messages.dialog_title_folder_type} /> : <FormattedMessage id={messages.dialog_edit_title_folder_type} /> }
                    open={open}
                    setOpen={setOpen}
                    theme={theme}
                    classes={classes}
                    register={register}
                    subFolderTypeFile={subFolderTypeData}
                    onSubmit={stateFolderType ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                    errors={errors}
                    activeLanguage={activeLanguage}
                    subFolderFileChecked={subFolderFileChecked}
                    stateFolderType={stateFolderType}
                />
            </div>

        </div>
    );
}

export default withSnackbar(FolderType);
