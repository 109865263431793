import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import messages from "../../page/messages/general/messages";
import {isColumnVisible} from "../../helpers/helpers";

export const SCOLARITETYPE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteType) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteTypeDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ScolariteType) => item,
};


export const scolariteTypeCustomOptionsPlugin = {
    columnVisibility : {
        'scolariteTypeForm.nom': false,
        'scolariteTypeForm.code': false,
    }
} ;

export const columnsScolariteType = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            accessorKey: 'nom',
            header: intl.formatMessage({id: messages.table_h_nom}),
        },
        {
            accessorKey: 'code',
            header: intl.formatMessage({id: messages.header_code}),
        }
    ];
} ;


export const columnsScolariteTypePlugin = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            accessorKey: 'scolariteTypeForm.nom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_nom})}`,
            filterVariant: 'multi-select',
            size: options?.nomSize ?? undefined,
        },
        {
            accessorKey: 'scolariteTypeForm.code',
            header: `${infoSpace} ${intl.formatMessage({id: messages.header_code})}`,
            size: options?.codeSize ?? undefined,
        }
    ];
} ;

export const excelScolariteTypeDataMapping = ({columns=[], table={}, rows=[], intl={},  infoSpace=``}:{rows:any[]; intl:any; infoSpace:string; columns:any[]; table:any; }) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original ;

        if(isColumnVisible(columns, 'nom')){
            obj[intl.formatMessage({id:messages.table_h_nom})] = data.nom;
        }
        if(isColumnVisible(columns, 'code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        return obj;
    });
}

export const excelScolariteTypeDataMappingPlugin = ({obj={}, columns=[], table={}, row={}, intl={}, prefix=``, infoSpace=``}:{obj:any; row:any; intl:any; infoSpace:string; columns:any[]; table:any; prefix:string;}) => {
    const data = row?.scolariteTypeForm;
    if(_.isObject(data) === false){
        return obj ;
    }

    if(isColumnVisible(columns, `${prefix}nom`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, `${prefix}code`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    return obj;
}

export  const scolariteTypeDataImpl: ScolariteType = {
    id: null,
    nom: '',
    code: '',
    couleur: '#fff',
    autrefrais: true,
    common: _.cloneDeep(commonDataImp),
}

export const scolariteTypeSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    code: yup.string().nonNullable().required(),
    couleur: yup.string().nonNullable().notRequired(),
    autrefrais: yup.boolean().nonNullable().default(true).notRequired(),
    common: yup.mixed<Common>(),
});

export type ScolariteType = yup.InferType<typeof scolariteTypeSchema>;

