import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import {styled} from "@mui/styles";
import {CHILD_PROFILE, USER_GENRE} from "../../constants";
import classNames from "classnames";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AuthorizationPersonItem from "./AuthorizationPersonItem";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButtonForm from "../../components/ui/form/LoadingButtonForm";
import _ from "lodash";
import {GENDER_MAP} from "../../constants/gender";
import {canShowButton} from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {STATUS} from '../../constants/schemaBD';

const Item:any = styled(Paper)(({ theme }:{theme:any}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    paddingRight:10,
    width:'100%'
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function StudentForm(props:any) {
    const {
        locale, classes, fileDataURL, handleFileChange, title, register, errors,
        activeLang, birthDate, handleDateChange, classroom, handleClassroomChange,
        dataClassroom, selectedOptions, handleSelectOptionChange, nationalitiesData,
        gender, handleGenderChange, profile, handleProfileChange, childrenList,
        handleSiblingsChange, searchPlaceholder, displaySiblings, siblings,
        handleRemoveSibling, optionRegistration, handleRegistrationChipChange,
        registrationsFiles, optionImagesRights, handleImageRightChipChange,
        imageRightsData, addAuthorizationPersonFormFields, authorizationPersonFormValues,
        handleAuthorizationPersonChange, removeAuthorizationPersonFormFields,
        mainParent, handleChangeMainParent, onSubmit, loadingButton

    } = props;
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));


    return (
        <>
            <h1 className={classes.addFormTitle}>{title}</h1>
            <div style={{textAlign:'left', marginLeft:10, marginBottom: 30, fontStyle:'italic', color:'red'}}>
                * {<FormattedMessage id={messages.warning_child_registration} />}
            </div>
            {/* CHILD ON PARENTS */}
            <h3 className={classes.stepTitle}>1- { <FormattedMessage id={messages.identity_child} />}</h3>
            <div className={classes.dialogAvatar} style={{marginBottom:50}}>
                <Avatar src={fileDataURL ? fileDataURL : ''} sx={{width:100, height:100}} />
                <div className={classes.iconButtonLabel}>
                    <label htmlFor="file-upload">
                        <div className={classes.iconButton} style={{textAlign:"center"}}>
                            <EditIcon />
                        </div>
                        <input
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            hidden={true}
                            onChange={(a:any) => handleFileChange(a)}
                        />
                    </label>
                </div>
            </div>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.firstname_child}/>}
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            {...register("firstname", {
                                required: "Required field"
                            })}
                        />
                        <div className={classes.errorHelper}>
                            {errors.firstname && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.lastname_child}/>}
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            {...register("lastname", {
                                required: "Lastname is required"
                            })}
                        />
                        <div className={classes.errorHelper}>
                            {errors.lastname && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                            <DatePicker
                                label={<FormattedMessage id={messages.birth_date_child} />}
                                value={birthDate}
                                maxDate={moment(new Date())}
                                onChange={handleDateChange}
                                slotProps={{ textField: { color: 'secondary' } }}
                                sx={{ width:'100% !important' }}
                            />
                        </LocalizationProvider>

                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.classroom_child} />}
                            defaultValue={''}
                            value={classroom || ''}
                            sx={{ width: { xs: '100% !important'}}}
                            color={"secondary"}
                            required
                            onChange={(a:any) => handleClassroomChange(a)}
                            inputProps={{
                                ...register('classroom', {
                                    required: 'Classroom is required',
                                })
                            }}
                        >
                            {dataClassroom.map((option:any) => (
                                <MenuItem key={option.id} value={option} sx={{fontWeight:400}}>
                                    {option.nom}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className={classes.errorHelper}>
                            {errors.classroom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <FormControl
                            sx={{ m:0, p:0, width: { xs: '100% !important'},  fontWeight:'normal !important'}}
                        >
                            <InputLabel id="multiple-checkbox-label">{<FormattedMessage id={messages.nationality_child} />} *</InputLabel>
                            <Select
                                sx={{
                                    m:0,
                                    p:0,
                                    width: { xs: '100% !important'},
                                    fontWeight:'normal !important',
                                }}
                                labelId="multiple-checkbox-label"
                                multiple
                                value={selectedOptions}
                                onChange={handleSelectOptionChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.nationality_child} />} />}
                                renderValue={(selected:any) => {
                                    const rowLength = selected.length;
                                    return selected.map((value:any, i:number) => {
                                        let country:any = activeLang === 'en' ? value.nom_en_gb : value.nom_fr_fr;
                                        return rowLength === i+1 ? country : country+', '
                                    });
                                }}
                                MenuProps={MenuProps}
                                color={"secondary"}
                                inputProps={{
                                    ...register('nationalities', {
                                        required: 'required field',
                                    })
                                }}
                            >
                                {nationalitiesData.map((option:any) => (
                                    <MenuItem key={option.id} value={option}>
                                        <Checkbox checked={selectedOptions.indexOf(option) > -1} color={"secondary"} />
                                        <ListItemText primary={<Typography variant={"subtitle1"}>{activeLang === 'en' ? option.nom_en_gb : option.nom_fr_fr}</Typography>} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div className={classes.errorHelper}>
                            {errors.nationalities && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.genre_child} />}
                            value={gender || ''}
                            sx={{ width: { xs: '100% !important'}}}
                            color={"secondary"}
                            onChange={(a:any) => handleGenderChange(a)}
                            inputProps={{
                                ...register('genre', {
                                    required: 'Genre is required',
                                })
                            }}
                        >
                            {USER_GENRE.map((option:any, index) => (
                                <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                    {GENDER_MAP.get(_.toUpper(option.key+'_'+activeLang))}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className={classes.errorHelper}>
                            {errors.genre && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.address_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("address")}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.profile_child} />}
                            value={profile}
                            sx={{ width: { xs: '100% !important'}}}
                            color={"secondary"}
                            onChange={(a:any) => handleProfileChange(a)}
                            inputProps={{
                                ...register('profile')
                            }}
                        >
                            {CHILD_PROFILE.map((option:any, index) => (
                                <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                    {activeLang === 'en' ? option.name_en : option.name_fr}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </Grid>
            </Grid>


            {/* INFORMATION ON PARENTS */}
            <h3 className={classes.stepTitle} style={{marginTop:40}}>2- { <FormattedMessage id={messages.parent_child} />}</h3>
            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={4} xs={12}>
                    <p style={{fontSize: '16px'}}>{<FormattedMessage id={messages.main_parent} />}</p>
                </Grid>
                <Grid item md={8} xs={12}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={mainParent}
                        onChange={handleChangeMainParent}
                        style={{margin:0, padding:0}}
                    >
                        <FormControlLabel value="mother" control={<Radio />} label={<FormattedMessage id={messages.mother_child} />} />
                        <FormControlLabel value="father" control={<Radio />} label={<FormattedMessage id={messages.father_child} />} />
                    </RadioGroup>
                </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{ marginBottom: { xs:-2, sm: -2 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.mother_child} />
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.firstname_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("firstNameMother")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.firstNameMother && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.lastname_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("lastNameMother")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.lastNameMother && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.phone_number_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("phoneNumberMother")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.phoneNumberMother && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.email_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("emailMother")}
                        />
                        <div className={classes.errorHelper}>
                           {/* {errors.emailMother && <span><FormattedMessage id={messages.required_field_child}/></span>}*/}
                        </div>

                    </div>
                </Grid>

            </Grid>

            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.father_child} />
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.firstname_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("firstNameFather")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.firstNameFather && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>

                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.lastname_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("lastNameFather")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.lastNameFather && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.phone_number_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("phoneNumberFather")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.phoneNumberFather && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.email_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("emailFather")}
                        />
                        <div className={classes.errorHelper}>
                            {errors.emailFather && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>
            </Grid>

            {/* START OTHERS INFORMATION */}
            <h3 className={classes.stepTitle} style={{marginTop:40}}>3- { <FormattedMessage id={messages.other_details_child} />}</h3>
            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.health_detail_child} />
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.allergies_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("allergies")}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.food_restriction_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("foodRestriction")}
                        />
                    </div>
                </Grid>

                <Grid item md={12} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.health_issue_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("healthIssue")}
                        />

                    </div>
                </Grid>

                {/* FRATRIE */}
                <Grid item md={12} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.siblings_child} />
                    </Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mb:{xs:-2, sm:-3} }}>
                    <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                        {/*<FormControl
                            sx={{
                                display: { xs: 'block', sm: 'inline-block'},
                                marginBottom: { xs: 3},
                                marginRight: { sm: 2 },
                                width:'100%',
                            }}
                        >
                            <UserAutoCompleteInputChild
                                locale={locale}
                                data={childrenList}
                                onUserChange={handleSiblingsChange}
                                placeholderText={searchPlaceholder}
                            />
                        </FormControl>*/}
                    </div>
                </Grid>
                {!displaySiblings && (<Box sx={{ ml: { xs:3, sm:3 },}}> <FormattedMessage id={messages.empty_siblings} /></Box>)}
                { displaySiblings && (
                    <Stack
                        sx={{
                            width: { xs:'100%', sm: 'auto'},
                            ml: { xs:3, sm:3 },
                            mr: 1
                        }}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {
                            siblings.map((sibling:any) => (
                                <Item key={sibling?.person?.nom+sibling?.person?.id}>
                                    <Avatar
                                        className={classes.childStackAvatar}
                                        sx={{display:'inline-block', textAlign:'center',}}
                                        src={sibling?.person?.photoDataTable}
                                    />
                                    <div className={classes.childStackItemName}>
                                        {sibling?.person?.prenom} {sibling?.person?.nom}
                                    </div>
                                    <div style={{display:'block', textAlign:"right"}}>
                                        <IconButton
                                            color={"error"} sx={{p:0.3, m:0}}
                                            title={"Remove"}
                                            onClick={() => handleRemoveSibling(sibling)}
                                        >
                                            <Clear sx={{fontSize:20}} />
                                        </IconButton>
                                    </div>
                                </Item>
                            ))
                        }
                    </Stack>
                )}

            </Grid>

            {/* REGISTRATION FILE INFORMATION */}
            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{marginTop:0, marginBottom: { xs:0, sm:0 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.registration_file_child} />
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <div style={{ width:'100%', padding:0, margin:0, marginTop:-10, marginLeft:0}}>
                        <FormControl sx={{ m:0, width:'100%' }}>
                            <InputLabel color={"secondary"}>{<FormattedMessage id={messages.select_file_child} />}</InputLabel>
                            <Select
                                //labelId={labelId}
                                //id={id}
                                multiple
                                value={optionRegistration}
                                onChange={handleRegistrationChipChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.select_file_child} />} color={"secondary"} />}
                                renderValue={(selected:any) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value:any) => (
                                            <Chip sx={{fontWeight:'normal', fontSize:14}} key={value.id} label={`${value.nom}`} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                sx={{ fontWeight:400, p:0 }}
                                color={"secondary"}
                                //size={size}
                                // {...register("registrationFilesType")}
                            >
                                {registrationsFiles.map((data:any) => {
                                    //console.log(data)
                                    return (
                                        <MenuItem
                                            key={data.id}
                                            value={data}
                                            sx={{ fontWeight:400,}}
                                        >
                                            {data.nom}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            {/* IMAGES RIGHTS */}
            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{marginTop:0, marginBottom: { xs:0, sm:0 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.image_rights_child} />
                    </Typography>
                </Grid>

                <Grid item md={12} xs={12}>
                    <div style={{ width:'100%', padding:0, margin:0, marginLeft:0, marginTop:-10, }}>
                        <FormControl sx={{ m:0, width:'100%' }}>
                            <InputLabel color={"secondary"}>{<FormattedMessage id={messages.image_rights} />}</InputLabel>
                            <Select
                                //labelId={labelId}
                                //id={id}
                                multiple
                                value={optionImagesRights}
                                onChange={handleImageRightChipChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.image_rights} />} color={"secondary"} />}
                                renderValue={(selected:any) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value:any) => (
                                            <Chip sx={{fontWeight:'normal', fontSize:14}} key={value?.id} label={`${value?.nom}`} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                sx={{ fontWeight:400, p:0 }}
                                color={"secondary"}
                                //size={size}
                                // {...register("registrationFilesType")}
                            >
                                {imageRightsData.map((data:any) => {
                                    return (
                                        <MenuItem
                                            key={data.id}
                                            value={data}
                                            sx={{ fontWeight:400,}}
                                        >
                                            {data?.nom}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>


            {/* EMERGENCY INFORMATION */}
            <h3 className={classes.stepTitle} style={{marginTop:40}}>4- { <FormattedMessage id={messages.emergency_child} />}</h3>
            <Grid container spacing={3} className={classes.separator}>
                <Grid item md={12} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.emergency_contact_child} />
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.emergency_contact_one_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("firstnameLastnameContact1")}
                        />
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.emergency_contact_one_phone_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("phoneNumberContact1")}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.emergency_contact_two_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("firstnameLastnameContact2")}
                        />
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className={classes.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.emergency_contact_two_phone_child}/>}
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            {...register("phoneNumberContact2")}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.authorization_person_child} />

                        <IconButton aria-label={"<FormattedMessage id={messages.add_person_authorization_child}/>"} title={""} onClick={() => addAuthorizationPersonFormFields()}>
                            <AddCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} sx={{ marginBottom: { xs:-1.5, sm: -1.5 } }}> </Grid>

                <Grid item md={12} xs={12}>
                    <AuthorizationPersonItem
                        //date={date}
                        classes={classes}
                        activeLang={activeLang}
                        formValues={authorizationPersonFormValues}
                        handleChange={handleAuthorizationPersonChange}
                        removeFormFields={removeAuthorizationPersonFormFields}
                    />
                </Grid>

                <Grid item md={12} xs={12} style={{textAlign: 'right'}}>
                    {
                        canShowButton(userRef.current, 'enfants', [STATUS.ADD, STATUS.UP]) &&
                        <LoadingButtonForm
                            color="primary"
                            variant="contained"
                            icon={SaveIcon}
                            textButton={<FormattedMessage id={messages.save_form_crud_btn} />}
                            onClickFormCrudAction={() => onSubmit()}
                            loadingButton={loadingButton}
                        />
                    }

                </Grid>

            </Grid>

        </>
    );
}

export default StudentForm;

