import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import classNames from "classnames";
import {MenuItem, TextField} from "@mui/material";
import DialogForm from "../../components/ui/form/DialogForm";
import {makeStyles} from "@mui/styles";
import {DISH_TYPE} from "../../constants";

const useStyles = makeStyles((theme:any) => {
    return {
        inputField: {
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input':{
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft:0,
                paddingRight:0
            },
        },
        inputFieldMarginBottom:{
            marginBottom: 15,
        },
    }
})

function DishDialogForm(props:any){
    const {theme, locale, title, open, setOpen, dish, handleDishTypeChange, onSubmit,  register } = props;
    const classes = useStyles();

    return (
        <DialogForm title={title} open={open} setOpen={setOpen} theme={theme} onSubmit={onSubmit}>
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.dish_type_config} />}
                    value={dish || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    onChange={(a:any) => handleDishTypeChange(a)}

                >
                    {DISH_TYPE.map((option:any) => (
                        <MenuItem key={option.key} value={option} sx={{fontWeight:400}}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.title_dish_config}/>}
                    size="small"
                    color={"secondary"}
                    {...register("nom", {
                        required: "Dish name is required"
                    })}
                />

                {/*<InputField
                    label={<FormattedMessage id={messages.title_dish_config} />}
                    size="small"
                    color={"secondary"}
                    width={'100% !important'}
                    {...register("fileName", {
                        required: "File name is required"
                    })}
                />*/}
            </div>
        </DialogForm>
    )
}

export default DishDialogForm;