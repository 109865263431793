import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Grid, MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {ScolariteEcheancierEnfantContext} from "./ScolariteEcheancierEnfant";
import scolariteEcheancierEnfantStateUpdate from "./businesslogic/ScolariteEcheancierEnfantStateUpdate";
import scolariteEcheancierEnfantBusinessLogic from "./businesslogic/ScolariteEcheancierEnfantBusinessLogic";
import {NumericFormat} from "react-number-format";
import {Profils} from "../../../constants/type/Profils";
import InputField from "../../../components/ui/form/InputField";
import {
    ScolariteEcheancierEnfant,
    SCOLARITEECHEANCIERENFANT_CONSTANT,
    scolariteEcheancierEnfantDataImpl,
    scolariteEcheancierEnfantSchema
} from "../../../constants/type/ScolariteEcheancierEnfant";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {ScolariteGroup, scolariteGroupDataImpl} from "../../../constants/type/ScolariteGroup";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getProfils(logic:any, profilId:number):Profils | undefined{
    return _.find(logic.profilsRef?.current, {id:profilId})  ;
}
function getProfilName(logic:any, profilId:number):string{
    const profil: Profils | undefined =  getProfils(logic, profilId)  ;
    return _.isObject(profil) ? profil.nom : ``;
}

const ScolariteEcheancierEnfantEditForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierEnfantContext);
    const styleClasses = logic.styleClasses ;
    const [scolariteGroupList, setScolariteClasseList] = React.useState<ScolariteGroup[]>([]);

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteEcheancierEnfantSchema),
    }));
    const {errors} = formRef.current.formState;


    const filtrerFraisOfClasse = () => {
        const scolclasseFiltre  = _.filter(logic.scolariteGroupsRef.current, item => _.includes(logic.scolariteGroupIdListSel, item.id));
        setScolariteClasseList(scolclasseFiltre);
    }

    const handleOnScolariteGroupChange = (event: any) => {
        const idcompo  = parseInt(event.target.value);
        const scolariteGroup: ScolariteGroup      = _.find(scolariteGroupList, {id:idcompo}) ?? _.cloneDeep(scolariteGroupDataImpl);
        if(_.isObject(scolariteGroup) === false){
            return ;
        }
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteGroupId = scolariteGroup.id ;
        data.scolariteTypeId = scolariteGroup.scolariteTypeId ;
        data.scolariteTypeTag   = scolariteGroup.scolariteTypeTag ;
        data.scolariteTypeForm   = logic.scolariteTypeMapRef.current.get(scolariteGroup.scolariteTypeId) ;

        scolariteEcheancierEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteGroupId', data.scolariteGroupId);
        formRef.current.setValue('scolariteTypeId', data.scolariteTypeId);
        formRef.current.setValue('scolariteTypeTag', data.scolariteTypeTag);
        formRef.current.setValue('scolariteTypeForm', data.scolariteTypeForm);
    };

    //TODO loading children par charger la liste
    const handleMontantChange = (values: any) => {
        let data:ScolariteEcheancierEnfant = _.cloneDeep(logic.dataSel);
        data.montant = values.floatValue;

        scolariteEcheancierEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('montant', data.montant);
    }

    const updateLaDate = (data:ScolariteEcheancierEnfant, ladate: moment.Moment):ScolariteEcheancierEnfant => {
        data.ladateForm = ladate;
        data.ladate = data.ladateForm.valueOf();
        data.liredateForm =  data.ladateForm?.clone().format('L');

        return data ;
    }
    const handleLaDateChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:ScolariteEcheancierEnfant = _.cloneDeep(logic.dataSel);
        data = updateLaDate(data, newday);

        scolariteEcheancierEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('ladate', data.ladate);
        formRef.current.setValue('ladateForm', data.ladateForm);
        formRef.current.setValue('liredateForm', data.liredateForm);
    };

    const onSubmit = async (data: FormData) => {
        // @ts-ignore
        const scolariteGroup: ScolariteGroup | undefined | null   = _.find(scolariteGroupList, {id:data.scolariteGroupId});

        if(!_.isObject(scolariteGroup)){
            logic.snackbarShowMessage("Incohérence dans le choix", 'error');
            return;
        }
        scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: ScolariteEcheancierEnfant = _.cloneDeep(data);
        dataToSend.enfantId             = logic.childSelected.id ;
        // @ts-ignore
        const toSend = SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, dataToSend);

        scolariteEcheancierEnfantBusinessLogic.saveEdit(
            logic,
            messages,
            toSend,
            toSend.id).then((response:any) => {
            scolariteEcheancierEnfantStateUpdate.openEditDialogForm(logic, false);
            scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false);
            scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());
        }).catch((e:any) => scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false));
    };

   

    useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteEcheancierEnfantDataImpl);
            scolariteEcheancierEnfantStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }
        formRef.current.reset(_.cloneDeep(logic.dataSel));
        filtrerFraisOfClasse();
        return () => {
            formRef.current.reset(_.cloneDeep(scolariteEcheancierEnfantDataImpl));
        };
    }, [logic.openEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_edition}/>}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <InputField
                            label={<FormattedMessage id={messages.the_group}/>}
                            size="small"
                            color={"secondary"}
                            value={logic.groupEnfantSel?.groupesectionForm?.nom}
                            disabled
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.schooling_echeance_label} />}
                                size="small"
                                color={"secondary"}
                                defaultValue={logic.dataSel.nom}
                                inputProps={{
                                    ...formRef.current.register("nom")
                                }}
                            />
                        </div>
                        <div className={styleClasses.errorHelper}>
                            {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.schooling_fees_title}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={logic.dataSel.scolariteGroupId}
                            onChange={handleOnScolariteGroupChange}
                            inputProps={{
                                required: "Required field",
                                min: 1,
                            }}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {logic.scolariteGroupList?.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                    {opt.nom} ({opt.scolariteTypeForm?.nom})
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>


                    <Grid item md={12} xs={12}>
                        <NumericFormat
                            value={logic.dataSel.montant}
                            customInput={TextField}
                            thousandSeparator={' '}
                            onValueChange={(values, sourceInfo) => {
                                handleMontantChange(values);
                            }}/>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                <MobileDateTimePicker
                                    label={<FormattedMessage id={messages.schooling_period_payment} />}
                                    value={logic.dataSel.ladateForm}
                                    //@ts-ignore
                                    onChange={handleLaDateChange}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            fontWeight: 'normal',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    
                </Grid>


            </Box>
        </DialogFormKiller>
    );
}

export default ScolariteEcheancierEnfantEditForm ;