import {getRequest} from "../helpers/axios_helpers";

function WorkDayService() {
    return {
        getAllWorkDay: async () => {
            const daysListRequest:any = await getRequest(`/corebase/workdays`);
            return  daysListRequest._embedded !== undefined ? daysListRequest._embedded.jourTravailDTOModelList : [];
        }
    };
}

export default WorkDayService;
