import React, {useEffect} from 'react';
import ConversationList from "./ConversationList";
// @ts-ignore
import SockJsClient from "react-stomp";
// @ts-ignore
import {Client} from '@stomp/stompjs';
import chatStyles from "./jss/chat-jss";
import msgCenterStyles from "./jss/message-jss";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import useMessageCenterVariables from "./businessLogic/MessageCenterVariables";
import messageCenterBusinessLogic from "./businessLogic/MessageCenterBusinessLogic";
import spaceListStyles from "./jss/conversation-jss";
import chatHeaderStyles from "./jss/chatheader-jss";
import spaceRoomBusinessLogic from "./businessLogic/SpaceRoomBusinessLogic";
import Space from "./Space";
import webSocketBusinessLogic from "./businessLogic/WebSocketBusinesLogic";
import spaceListBusinessLogic from "./businessLogic/SpaceListBusinessLogic";
import {Box, CircularProgress} from "@mui/material";
import NewTicketForm from "./NewTicketForm";
import moment from "moment-timezone";


export const MessageCenterContext = React.createContext({});


const useBusinessLogic = (props: any): any => {
    return {
        theme: props.theme,
        msgCenterStyles: msgCenterStyles(),
        spaceListStyles: spaceListStyles(),
        chatStyles: chatStyles(),
        chatHeaderStyles: chatHeaderStyles(),
        ...useMessageCenterVariables(props)
    };
}
function MessageCenter(props:any) {
    const logic = useBusinessLogic(props);

    useEffect(() => webSocketBusinessLogic.setUsersConnected(logic, logic.wsUsersConnected), [logic.wsUsersConnected])

    useEffect(() => spaceListBusinessLogic.addNewConversation(logic, logic.wsNewSpace), [logic.wsNewSpace])

    useEffect(() => spaceListBusinessLogic.updateConversationState(logic, logic.wsUpdatedSpaces), [logic.wsUpdatedSpaces]);

    useEffect(() => spaceRoomBusinessLogic.processingNewMessage(logic, logic.wsNewMessage), [logic.wsNewMessage]);


    useEffect(() => {
        messageCenterBusinessLogic.initProcessingChildrenList(logic);
    }, [logic.classeRoomSelected]);

    useEffect(() => {
        spaceRoomBusinessLogic.loadMessageList(logic, logic.space.id);
        logic.setShowMobileDetail(true);
    }, [logic.space]);

    useEffect(() => {
        if(logic.pageForm){
            messageCenterBusinessLogic.initPostConstruct(logic);
        }
        return () => { }
    }, [logic.pageForm]);

    useEffect(() => {
        //moment.tz.setDefault("Africa/Abidjan");
        moment.locale(logic.activeLanguageRef.current[0]);
        messageCenterBusinessLogic.initPostConstruct(logic);
        return () => { }
    }, []);
   /* useEffect(() => {
        return () => {
            window.alert("MERCI");
        }
    }, []);*/

    /*const showDetail = (data:any) => {
        setItemSelected(data.id)
        setChatSelected((data.id-1))
        setShowMobileDetail(true)
        setNewMessage(false)
        setDataChatHeader(data)
        setMessageFieldStatus(false)
    }

    const hideDetail = () => {
        setShowMobileDetail(false)
        setNewMessage(false)
    }

    const handleComposeMessage = () => {
        setNewMessage(true)
        setItemSelected(0)
        setChatSelected(0)
        setDataChatHeader({})
        setMessageFieldStatus(true)
    }*/

    return (
        <div className={logic.msgCenterStyles.root}>
            <MessageCenterContext.Provider value={logic}>
                {
                    (!logic.showChatRoom || !logic.showConversationList) &&
                    <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                        <CircularProgress color="primary" />
                    </Box>
                }
                {
                    logic.showConversationList  && logic.showChatRoom &&
                    <>
                        <ConversationList />
                        <Space />
                    </>
                }
                <NewTicketForm />
            </MessageCenterContext.Provider>
        </div>
    )
}

export default withSnackbar(MessageCenter);
