import _ from "lodash";
import webSocketBusinessLogic from "./WebSocketBusinesLogic";
import {processingChildren, processingEmployees, processingJobs} from "../../../../helpers/loader_helpers";
import {Child} from "../../../../constants/type/Child";
import {Avatar, Box, MenuItem} from "@mui/material";
import React from "react";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {getErrorMessage} from "../../../../helpers/errorManager";

const messageCenterBusinessLogic: any = {
    initPostConstruct: (logicRef:any) => {
        const requestbody = ['classes', 'profils', 'fonctions', 'enfants', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logicRef.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);

            const itemsp : Profils[]   = data.profils.content ?? [];
            logicRef.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logicRef, item)), ['nom']);


            const itemchilds : Child[]   = data.enfants.content ?? [];
            logicRef = processingChildren(logicRef, itemchilds);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logicRef = processingJobs(logicRef, itemsprof);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logicRef = processingEmployees(logicRef, itememployees);

            const teachers: Employee[] = [];
            logicRef.employeesByFonctionRef.current.forEach((e:Employee) => {
                if(_.isEmpty(e.teacherClasses)){
                    return ;
                }
                teachers.push(e);
            });
            logicRef.classeRoomRef.current = _.filter(_.cloneDeep(logicRef.classeRoomRef.current), (item) => {
                return _.findIndex(teachers, (emp) => {
                    return emp?.teacherClasses[0]?.classeId === item.id;
                })  >= 0;
            });
        };

        mixLoad(logicRef, requestbody, convertToFrontEnd).then(value => {
            webSocketBusinessLogic.runWebSocket(logicRef);
        }).catch(error => {
            logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
        /*loadClasseRooms(logicRef).then(value => {});
        loadProfils(logicRef).then((val:any)=>{
            loadChildren(logicRef).then((value:boolean) => {
                if(logicRef.userRef.current.role === RoleUser.TEACHER){
                    const classeId = logicRef.userRef.current.userDetails.personDetails.teacherClasses[0]?.classeId ?? 0;

                    //logicRef.userRef.current.userDetails.personDetails.teacherClasses.forEach((t:any) => teacherclasses.push(t.classeId)) ;
                    logicRef.childrenClasseRef.current = _.filter(_.cloneDeep(logicRef.childrenRef.current), function(item:Child) {
                        return classeId === item.eleves[0]?.classe?.id ;
                    });
                } else {
                    logicRef.childrenClasseRef.current = _.cloneDeep(logicRef.childrenRef.current) ;
                }

                logicRef.childrenClasseRef.current = logicRef.childrenClasseRef.current
                    // @ts-ignore
                    .sort((a, b) => (a.eleves[0]?.classe?.nom?.localeCompare(b.eleves[0]?.classe?.nom) || a.person.nom.localeCompare(b.person.nom)) );

                loadJobs(logicRef, {}).then(value =>
                    loadEmployees(logicRef, {}).then( value => {
                        webSocketBusinessLogic.runWebSocket(logicRef);
                    }));
            });
        });*/

    },
    initProcessingChildrenList: (logicRef:any) => {
        const groupsItems:any[] = [];
        const classeRoomId = logicRef.classeRoomSelected.id ;
        for(const datachild of logicRef.childrenClasseRef.current) {
            const eleve = datachild.eleves[0];
            if(_.isUndefined(eleve) || _.isNull(eleve)){
                continue ;
            }

            if(eleve.classe?.id !== classeRoomId){
                continue ;
            }

            for(const enfantParent of datachild.enfantParents){
                // @ts-ignore
                const parent    = enfantParent.parent ;
                // @ts-ignore
                const enfantParentId  = enfantParent.id ;

                // @ts-ignore
                if(_.size(parent?.uuid) > 2){
                    // @ts-ignore
                    const parentEl = `${parent?.person?.nom} ${parent?.person?.prenom}` ;
                    groupsItems.push( <MenuItem key={enfantParentId} value={enfantParentId} sx={{fontWeight:400}}>
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}}>
                            <Avatar alt={parentEl}  src={`${datachild.childphotoForm}`} sx={{display:'inline-block', mr: 2}}/>
                            <span style={{verticalAlign: '50%'}}>{datachild.childNomPrenomForm}, Parent {parentEl}</span>
                        </Box>
                    </MenuItem> )  ;
                }
            }/*
            if(_.isObject(enfantParent)){

            }
            if(_.isObject(datachild.enfantParents[1])){
                // @ts-ignore
                if(_.isEmpty(datachild.enfantParents[1].parent?.uuid) === false){
                    // @ts-ignore
                    const parent = datachild.enfantParents[1].parent ;

                    // @ts-ignore
                    parentEl = `${parent?.person?.nom} ${parent?.person?.prenom}` ;
                    groupsItems.push( <MenuItem key={parent.id} value={parent.id} sx={{fontWeight:400}}>
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}}>
                            <Avatar alt={parentEl}  src={`${BASEURL_RESOURCES}/${datachild.childphotoForm}`} sx={{display:'inline-block', mr: 2}}/>
                            <span style={{verticalAlign: '50%'}}>{datachild.childNomPrenomForm}, Parent {parentEl}</span>
                        </Box>
                    </MenuItem> )  ;
                }
            }*/
        }
        logicRef.setChildrenSelectList(groupsItems) ;
    },
    hideDetail: (logicRef:any) => {
        logicRef.setShowMobileDetail(false);
        logicRef.setNewMessage(false);
    },

}
export default messageCenterBusinessLogic;