import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {BASEURL} from "../../config/AppUrl";
import Loading from "../../components/ui/Loading";
import IconButton from "@mui/material/IconButton";
import ExportFileService from "../../service/ExportFileService";
import MarketingService from "../../service/MarketingService";
import ProductOrderItem from "./ProductOrderItem";
import ChildrenService from "../../service/ChildrenService";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme:any) => {
    return {
        container: {
            fontSize: '14px',
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontSize: 18,
            fontWeight:'400 !important',
            padding:0,
            margin: 0,
            paddingBottom:10,
            borderBottom: "1px solid #ccc",
        },
    }
});

const excelIcon = BASEURL+'/images/xls.png';
const pdfIcon = BASEURL+'/images/pdf.png';

function ProductOrdersList(props:any) {
    const {
        data,
        activeLang
    } = props;

    const classes = {...useStyles(), ...useStyles2()};
    const [orderListProduct, setOrderListProduct] = useState([]);
    const [loading, setLoading] = useState(true);

    const export_xls = activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const export_pdf = activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const marketingService = MarketingService();
    const childrenService = ChildrenService();

    const handleExportToXLX = () => {
        try {
            if(orderListProduct.length > 0){
                const dataExport = marketingService.reformatMarketingDataForExportXLX(orderListProduct);
                ExportFileService.exportToCSV(dataExport, 'ordered-product-list');
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleOrderDelete = async (order:any) => {
        setLoading(true);
        try {
            await marketingService.deleteOrder(`/extra/commercevente/${order.id}`);
            const orderListFiltered = orderListProduct.filter((orderProduct:any) => orderProduct.id !== order.id);
            setOrderListProduct(orderListFiltered);

            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {

                const allOrderList = await marketingService.getProductOrderList();
                const ordersList = allOrderList.filter((order:any) => order.produitId === data.id);
                const childrenListReq = await childrenService.getAllChildrenData();
                const childrenList =  childrenListReq._embedded !== undefined ? childrenListReq._embedded?.enfantDTOModelList : [];
                const reformatOrderList = marketingService.reformatOrderList(ordersList, childrenList);
                setOrderListProduct(reformatOrderList);

                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                console.log(error);
            }
        })();
    }, []);

    return (
        <div className={classes.container}>
            <h2 className={classes.title}> <FormattedMessage id={messages.orders_product_list} /> </h2>
            <div style={{padding: 20,  maxHeight:600, paddingLeft: 0, paddingTop: 20,}}>
                <div style={{textAlign: 'right', marginBottom: 20}}>
                    <IconButton title={export_xls} onClick={(a) => handleExportToXLX() }>
                        <img width="24" height="24" src={excelIcon} alt="Excel icon" />
                    </IconButton>
                    {/*<IconButton title={export_pdf}>
                        <img width="24" height="24" src={pdfIcon} alt="PDF icon" />
                    </IconButton>*/}
                </div>

                <Loading status={loading} />
                {orderListProduct.length > 0 && !loading && orderListProduct.map((orderProduct:any) => {
                    return (
                        <ProductOrderItem
                            key={orderProduct.id}
                            classes={classes}
                            orderProduct={orderProduct}
                            setLoading={setLoading}
                            marketingService={marketingService}
                            handleOrderDelete={handleOrderDelete}
                        />
                    )
                })}

                {orderListProduct.length === 0 && !loading && (
                    <div style={{textAlign: 'center'}}>
                        <FormattedMessage id={messages.empty_order_product} />
                    </div>
                )}

            </div>
        </div>
    );
}

export default ProductOrdersList;
