import React, {useContext, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {
    ScolariteGroup,
    SCOLARITEGROUP_CONSTANT,
    scolariteGroupDataImpl,
    scolariteGroupSchema
} from "../../../constants/type/ScolariteGroup";
import {ScolariteToutFraisContext} from "./ScolariteFraisGroup";
import scolariteFraisGroupStateUpdate from "./businesslogic/ScolariteFraisGroupStateUpdate";
import scolariteFraisGroupBusinessLogic from "./businesslogic/ScolariteFraisGroupBusinessLogic";
import FormGroup from '@mui/material/FormGroup';
import {NumericFormat} from "react-number-format";
import Chip from "@mui/material/Chip";
import InputField from "../../../components/ui/form/InputField";
import {ScolariteType} from "../../../constants/type/ScolariteType";
import {Profils, profilsDataImpl} from "../../../constants/type/Profils";
import {isNumericValue, isPositiveValue} from "../../../helpers/helpers";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import {MiniClub} from "../../../constants/type/MiniClub";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getProfils(logic:any, profilId:number):Profils | undefined{
    return _.find(logic.profilsRef?.current, {id:profilId})  ;
}
function getProfilName(logic:any, profilId:number):string{
    const profil: Profils | undefined =  getProfils(logic, profilId)  ;
    return _.isObject(profil) ? profil.nom : ``;
}
const ScolariteFraisGroupEditForm = (props:any) => {
    const logic: any = useContext(ScolariteToutFraisContext);
    const styleClasses = logic.styleClasses ;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteGroupSchema),
    }));
    const {errors} = formRef.current.formState;

    const handleOnObligationCheck = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.obligatoire = !data.obligatoire ;
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('obligatoire', data.obligatoire);
    };

    const handleOnEcheanceCheck = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.echeance = !data.echeance ;
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('echeance', data.echeance);
    };

    const updateAnneeScolarite = (id:number) => {
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteAnneeId = id;

        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteAnneeId', data.scolariteAnneeId);
    }
    const handleOnAnneeChange = (event: any) => {
        updateAnneeScolarite(parseInt(event.target.value));
    };
    const handleOnTypeChange = (event: any) => {
        const idcompo   = parseInt(event.target.value);
        const dataCompoSel: ScolariteType      = _.find(logic.scolariteTypeRef.current, {id:idcompo});
        if(_.isObject(dataCompoSel) === false){
            return ;
        }
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteTypeId    = dataCompoSel.id ;
        data.scolariteTypeTag   = dataCompoSel.common?.tag ;
        data.scolariteTypeForm   = dataCompoSel ;

        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteTypeId', data.scolariteTypeId);
        formRef.current.setValue('scolariteTypeTag', data.scolariteTypeTag);
        formRef.current.setValue('scolariteTypeForm', data);
    };
    const handleOnClasseChange = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.groupesectionIds = [] ;
        event.target.value.forEach((id:string) => {
            data.groupesectionIds.push(parseInt(id));
        });

        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('groupesectionIds', data.groupesectionIds);
    };
    const handleOnMinilubChange = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.extrascolaireId = null;
        data.extrascolaireCode = null;
        if(isPositiveValue(event.target.value)){
            // @ts-ignore
            data.extrascolaireId = _.toInteger(event.target.value);
            data.extrascolaireCode = 'miniclub';
            const miniclub : MiniClub = _.find(logic.miniClubRef.current, {id: data.extrascolaireId});
            if(_.isObject(miniclub)){
                // @ts-ignore
                data.montant = miniclub.prix ;
                formRef.current.setValue('montant', data.montant);
            }
        }
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('extrascolaireCode', data.extrascolaireCode);
        formRef.current.setValue('extrascolaireId', data.extrascolaireId);
    };
    const handleOnProfilsChange = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.profilsId = null;
        data.profils = _.cloneDeep(profilsDataImpl);
        data.profilsName=  ``;
        if(isNumericValue(event.target.value)){
            // @ts-ignore
            data.profilsId = _.toInteger(event.target.value);
            data.profils = _.find(logic.profilsRef?.current, {id: data.profilsId}) ?? _.cloneDeep(profilsDataImpl);
            data.profilsName = data.profils?.nom;
        }
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('profilsId', data.profilsId);
        formRef.current.setValue('profilsName', data.profilsName);
        formRef.current.setValue('profils', data.profils);
    };
    const updateLaDate = (data:ScolariteGroup, ladate: moment.Moment):ScolariteGroup => {
        data.ladateForm = ladate;
        data.ladate = data.ladateForm.valueOf();
        data.liredateForm =  data.ladateForm?.clone().format('L');

        return data ;
    }
    const handleLaDateChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:ScolariteGroup = _.cloneDeep(logic.dataSel);
        data = updateLaDate(data, newday);

        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('ladate', data.ladate);
        formRef.current.setValue('ladateForm', data.ladateForm);
        formRef.current.setValue('liredateForm', data.liredateForm);
    };
    const handleMontantChange = (values: any) => {
        let data:ScolariteGroup = _.cloneDeep(logic.dataSel);
        data.montant = values.floatValue;

        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        formRef.current.setValue('montant', data.montant);
    }

    const onSubmit = async (data: FormData) => {
        // @ts-ignore
        const dataToSend: ScolariteGroup = _.cloneDeep(data);
        if(dataToSend.echeance){
            dataToSend.ladate = null;
            dataToSend.extrascolaireCode = null;
            dataToSend.extrascolaireId = null;
        }
        else if(!isPositiveValue(dataToSend.ladate)){
            const erreur = logic.activeLang === 'en' ? `Please select a payment deadline.` : `Veuillez choisir une date limite de paiement.`;
            logic.snackbarShowMessage(erreur, 'error');
            return ;
        }
        // @ts-ignore
        const toSend = SCOLARITEGROUP_CONSTANT.convertToBackEnd(logic, dataToSend);

        const datasToSendList: ScolariteGroup[] = [];
        dataToSend.groupesectionIds.forEach(groupesectionId=>{
            const data = _.cloneDeep(dataToSend);
            // @ts-ignore
            data.groupesectionId = groupesectionId;
            datasToSendList.push(data);
        });

        scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, true);
        scolariteFraisGroupBusinessLogic.saveEdit(
            logic,
            messages,
            datasToSendList,
            toSend.id).then((response:any) => {
            scolariteFraisGroupStateUpdate.openEditDialogForm(logic, false);
            scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false));
    };


    const scolariteAnneesMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteAnneesRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight:400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteAnneesRef?.current]) ;
    const groupesectionsMenuItems = useMemo<any>(() => {
        return _.map(logic.groupesectionsRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                <Checkbox checked={logic.dataSel?.groupesectionIds?.includes(item.id)}/>
                <ListItemText primary={item.nom}/>
            </MenuItem>
        ));
    }, [logic.groupesectionsRef?.current, logic.dataSel?.groupesectionIds]) ;

    const scolariteTypeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteTypeRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteTypeRef?.current]) ;

    const miniMenuItems = useMemo<any>(() => {
        return _.map(logic.miniClubRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.miniClubRef?.current]) ;

    const profilsItems = useMemo<any>(() => {
        return _.map(logic.profilsRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.profilsRef?.current]) ;

    useEffect(() => {
        const data = _.cloneDeep(logic.dataSel);
        if(data.echeance){
            data.extrascolaireCode = null;
            data.extrascolaireId = null;
        }
        const showMiniClub = !data.echeance && data.scolariteTypeTag === 'miniclub';

        scolariteFraisGroupStateUpdate.showMiniClub(logic, showMiniClub);
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        return () => {};
    }, [logic.dataSel.echeance, logic.dataSel.scolariteTypeId]);

    useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteGroupDataImpl);
            scolariteFraisGroupStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }
        formRef.current.reset(_.cloneDeep(logic.dataSel));
        if(isPositiveValue(logic.dataSel?.id) === false){
            updateAnneeScolarite(_.find(logic.scolariteAnneesRef.current, {migrateNextYear:false})?.id ?? 0);
        }

        return () => {
            formRef.current.reset(_.cloneDeep(scolariteGroupDataImpl));
        };
    }, [logic.openEditDialogForm]);

    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={isPositiveValue(logic.dataSel.id) ? <FormattedMessage id={messages.selection_edition} /> : <FormattedMessage id={messages.add_item} />}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
                <Box  sx={{ width: '100%' }}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.scolaritee_annee} />}
                                sx={{ width: { xs: '100% !important'}, }}
                                color={"secondary"}
                                value={logic.dataSel.scolariteAnneeId}
                                onChange={handleOnAnneeChange}
                                required
                                inputProps={{
                                    ...formRef.current.register("scolariteAnneeId")
                                }}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                                {scolariteAnneesMenuItems}
                            </TextField>
                            <div className={styleClasses.errorHelper}>
                                {errors.scolariteAnneeId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            {
                                isPositiveValue(logic.dataSel.id) &&
                                <TextField
                                    color={"secondary"}
                                    sx={{ width:'100% !important' }}
                                    label={<FormattedMessage id={messages.the_group} />}
                                    value={logic.dataSel.groupesectionForm?.nom}
                                    disabled
                                    //error={!!errors.period?.message}
                                    //helperText={errors.username?.message}
                                />
                            }
                            {
                                !isPositiveValue(logic.dataSel.id) &&
                                <>
                                    <InputLabel id="demo-simple-select-label"><FormattedMessage id={messages.the_group} /></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select-label"
                                        multiple
                                        size="small"
                                        label={<FormattedMessage id={messages.the_group}/>}
                                        sx={{width: {xs: '100% !important'},}}
                                        color={"secondary"}
                                        value={logic.dataSel.groupesectionIds}
                                        onChange={handleOnClasseChange}
                                        required
                                        input={<OutlinedInput label={<FormattedMessage id={messages.the_group}/>}/>}
                                        renderValue={(selected: any) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value: any) => (
                                                    <Chip key={value}
                                                          label={logic.groupesectionMapRef.current.get(value)?.nom ?? ``}/>
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                            ...formRef.current.register("groupesectionIds")
                                        }}
                                    >
                                        <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {groupesectionsMenuItems}
                                    </Select>
                                    <div className={styleClasses.errorHelper}>
                                        {errors.groupesectionIds && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                    </div>
                                </>
                            }
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.fees_type} />}
                                sx={{ width: { xs: '100% !important'}, }}
                                color={"secondary"}
                                value={logic.dataSel.scolariteTypeId}
                                onChange={handleOnTypeChange}
                                required
                                inputProps={{
                                    ...formRef.current.register("scolariteTypeId")
                                }}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                                {scolariteTypeMenuItems}
                            </TextField>
                            <div className={styleClasses.errorHelper}>
                                {errors.scolariteTypeId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.schooling_fees_label}/>}
                                    size="small"
                                    color={"secondary"}
                                    required
                                    defaultValue={logic.dataSel.nom}
                                    inputProps={{
                                        ...formRef.current.register("nom")
                                    }}
                                />
                                <div className={styleClasses.errorHelper}>
                                    {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.header_code}/>}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.dataSel.code}
                                    inputProps={{
                                        ...formRef.current.register("code")
                                    }}
                                />
                            </div>
                        </Grid>

                        {
                            logic.showMiniClub &&
                            <>
                                <Grid item md={12} xs={12}>
                                <TextField
                                        select
                                        label={<FormattedMessage id={messages.return_mini_club_list} />}
                                        sx={{ width: { xs: '100% !important'}, }}
                                        color={"secondary"}
                                        required
                                        value={logic.dataSel.extrascolaireId}
                                        onChange={handleOnMinilubChange}
                                        inputProps={{
                                            ...formRef.current.register("extrascolaireId")
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                                        {miniMenuItems}
                                    </TextField>
                                </Grid>
                            </>
                        }

                        <Grid item md={12} xs={12}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.schooling_profile} />}
                                sx={{ width: { xs: '100% !important'}, }}
                                color={"secondary"}
                                required
                                value={logic.dataSel.profilsId}
                                onChange={handleOnProfilsChange}
                                inputProps={{
                                    ...formRef.current.register("profilsId")
                                }}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                                {profilsItems}
                            </TextField>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <NumericFormat
                                    label={<FormattedMessage id={messages.schooling_amount}/>}
                                    required
                                    value={logic.dataSel.montant}
                                    customInput={TextField}
                                    thousandSeparator={' '}
                                    onValueChange={(values, sourceInfo) => {
                                        handleMontantChange(values);
                                    }}/>
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.montant && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <div className={styleClasses.inputField} style={{marginTop:0, marginBottom:0}}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={logic.dataSel.obligatoire} onChange={handleOnObligationCheck} />}
                                        label={<FormattedMessage id={messages.schooling_fees_obligatory} />} />
                                </FormGroup>
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12} sx={{display:"flex", alignItems:'right', justifyContent:'right',}}>
                            <div className={styleClasses.inputField} style={{marginTop:0, marginBottom:0}}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={logic.dataSel.echeance} onChange={handleOnEcheanceCheck} />}
                                        label={<FormattedMessage id={messages.schooling_fees_echeance} />} />
                                </FormGroup>
                            </div>
                        </Grid>

                        {
                            !logic.dataSel.echeance &&
                            <Grid item md={12} xs={12} sx={{mt:2}}>
                                <div className={styleClasses.inputField}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                        <MobileDateTimePicker
                                            label={<FormattedMessage id={messages.payment_date_limite} />}
                                            value={logic.dataSel.ladateForm}
                                            //@ts-ignore
                                            onChange={handleLaDateChange}
                                            required
                                            views={['year', 'month', 'day']}
                                            slotProps={{ textField: { color: 'secondary' } }}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '100%',
                                                    fontWeight: 'normal',
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                        }
                    </Grid>

                </Box>
        </DialogFormKiller>
    );
}

export default ScolariteFraisGroupEditForm ;