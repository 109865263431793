import React from 'react';
import Box from "@mui/material/Box";
import {IMGS} from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import {TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import DialogFormLoading from "../../components/ui/form/DialogFormLoading";
import InputField from "../../components/ui/form/InputField";

function AddNewProduct(props:any) {
    const {
        theme,
        title,
        open,
        setOpen,
        classes,
        onSubmit,
        register,
        fileDataURL,
        handleFileChange,
        activeLang,
        loadingButton
    } = props;

    return (
        <DialogFormLoading
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
            loadingButton={loadingButton}
        >
            <Box sx={{ width: '100%' }}>
                <div className={classes.dialogAvatar}>
                    <img
                        src={fileDataURL ? fileDataURL : IMGS.photoMenu}
                        style={{width:100, height:90}}
                        alt={'mini-club-illustration'}
                    />
                    <div className={classes.iconButtonLabel}>
                        <label htmlFor="file-upload">
                            <div className={classes.iconButton} style={{textAlign:"center"}}>
                                <EditIcon />
                            </div>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                hidden={true}
                                onChange={(a:any) => handleFileChange(a)}
                            />
                        </label>
                    </div>
                </div>

                <div style={{marginTop:40}}> </div>
                <div className={classes.inputField} style={{marginBottom:15}}>
                    <TextField
                        label={<FormattedMessage id={messages.marketing_product_nom}/>}
                        size="small"
                        color={"secondary"}
                        {...register("title", {
                            required: "Title is required"
                        })}
                    />
                </div>

                <div className={classes.inputField} style={{marginBottom:15}}>
                    <TextField
                        label={<FormattedMessage id={messages.dialog_tab_hd_club_price}/>}
                        size="small"
                        color={"secondary"}
                        type={"number"}
                        {...register("prix", {
                            required: "Price is required"
                        })}
                    />
                </div>

                <div className={classes.inputField}  style={{marginBottom:20}}>
                    <TextField
                        label={<FormattedMessage id={messages.marketing_product_quantity}/>}
                        size="small"
                        color={"secondary"}
                        type={"number"}
                        {...register("quantity", {
                            required: "Quantity is required"
                        })}
                    />
                </div>

                <div className={classes.inputField}>
                    <InputField
                        label={<FormattedMessage id={messages.dialog_tab_hd_club_description}/>}
                        size="small"
                        color={"secondary"}
                        InputProps={{
                            rows: 3,
                            multiline: true,
                            inputComponent: 'textarea',
                        }}
                        inputProps={{
                            ...register("description")
                        }}
                    />
                </div>

            </Box>
        </DialogFormLoading>
    );
}

export default AddNewProduct;
