import {
    Autocomplete,
    Avatar,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import {Child} from "../../constants/type/Child";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import InputField from "../../components/ui/form/InputField";
import classNames from "classnames";
import {MenujourObservation} from "../../constants/type/MenujourObservation";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {ChilDayContext} from "./ChildDayAtIms";
import _ from "lodash";
import {IMGS} from "../../constants";
import SaveIcon from '@mui/icons-material/Save';
import {NavigateNext} from "@mui/icons-material";
import {ChildStuff} from "../../constants/type/ChildStuff";
import AddIcon from "@mui/icons-material/Add";
import {isPositiveValue} from "../../helpers/helpers";
import {ChildImsDayStatus, ChildImsDayStatusImpl, ImsDay, IMSDAY_CONSTANT} from "../../constants/type/ImsDay";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import ListIcon from '@mui/icons-material/List';
import {loadChildrenOfImsDayClasse} from "../../helpers/loader_helpers";
import {Moment} from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DialogMenuPlatChoiceForm from "./DialogMenuPlatChoiceForm";


const ITEM_HEIGHT = 48;
const styleMenuItem = {fontSize: '14px', fontWeight:'normal'};

const ChildDayForm = (props: any) => {
    const {styleClasses} = props;
    const menuLeaveTimerRef = useRef<any>();
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const logic: any = useContext(ChilDayContext);

    const handleOnMenuImsDayChildStatusClick = (item:ChildImsDayStatus) => {

       const child =  logic.childrenMapRef.current.get(item.enfantId);
       if(_.isObject(child) === false){
           return ;
       }
        if(isPositiveValue(item.theDate) === false){
            return ;
        }
        afficherImsDayOfChild(child, moment(item.theDate));
        handleCloseConextMenu();
    }

    const afficherImsDayOfChild = (child:Child, dateMoment: Moment) => {
        
        const imsDay = _.cloneDeep(logic.imsDay);

        const dateTime = dateMoment?.valueOf() ;
        imsDay.id = null ;
        imsDay.childForm = child ;
        imsDay.theDateForm = dateMoment ;
        imsDay.theDate = dateTime ;


        logic.setLoadingForm(true);
        let newImsDay   = logic.reinitImsDay(imsDay);
        newImsDay       = logic.setChild(child, newImsDay);
        logic.afficherImsDay(newImsDay);

        logic.setNumericChange(new Date().getTime());

        logic.loadImsDayOfEnfant(child.id, dateTime).then((data:ImsDay|undefined) => {
            if(_.isObject(data) && isPositiveValue(data?.id)){
                const imsDayConvert = IMSDAY_CONSTANT.convertToFrontEnd(logic, data);
                logic.afficherImsDay(imsDayConvert);
            }
            logic.setLoadingForm(false);
            logic.setShowForm(true);
            _.delay(function(text) {
                logic.setNumericChange(new Date().getTime());
            }, 15, 'later');
        }).catch((e:any) => {
            logic.setLoadingForm(false);
            logic.setNumericChange(new Date().getTime());
        });
    }

    const handleChildChange = (event: React.SyntheticEvent, value: Child) => {
       // const daychoose = logic.imsDay?.theDateForm?.startOf('day').valueOf();
        if(!_.isObject(value) || !_.isObject(logic.imsDay?.theDateForm)){
            return ;
        }
        logic.setChild(value, logic.imsDay);
        const dateMoment =   logic.imsDay?.theDateForm?.clone().startOf('day');
        afficherImsDayOfChild(value, dateMoment);
    }

    const handleDayMenuChange = (day: any) => {
        clearTimeout(menuLeaveTimerRef.current);

        menuLeaveTimerRef.current = setTimeout(function() {
            const daychoose = moment(day.valueOf()).startOf('day');

            afficherImsDayOfChild( logic.imsDay?.childForm, daychoose);

        }, 50);
    };

    const handleOnChildStuffCheck = (data:ChildStuff, event: any) => {
        data.activer = !data.activer ;
        const datas = _.cloneDeep(logic.childStuffList);
        const sz = datas ;

        for (let i = 0; i < sz; i++) {
            const item = logic.childStuffList[i];
            if(item.id !== data.id){
               continue ;
            }
            datas[i] = data;
            break ;
        }
        logic.setChildStuffList(datas);
    };
    const handleQuantiteChange = (data:ChildStuff, event: any) => {
        data.quantite =  parseInt(event.target.value);
        const datas = _.cloneDeep(logic.childStuffList);
        const sz = datas ;
        for (let i = 0; i < sz; i++) {
            const item = logic.childStuffList[i];
            if(item.id !== data.id){
                continue ;
            }
            datas[i] = data;
            break ;
        }
        logic.setChildStuffList(datas);
    };

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };


    const handleCloseConextMenu = () => {
        setContextMenu(null);
    };

    const onSubmit =   (data: FormData) => {
        logic.onSubmit(logic, data).then((response:any) => window.scrollTo(0, 0));
    };

  /*  useEffect(()=>{
        // && isPositiveValue(logic.imsDay.childForm.id)
        if(logic.showForm){
            logic.afficherImsDay(logic.imsDay);
        }
    }, [logic.showForm]);*/

/*

    useEffect(()=>{
        handleDayMenuChange(logic.imsDay.theDateForm);
    }, []);*/

    const changeMenuCantine = () => {
        logic.setOpenMenuEditDialogForm(true);
    }
    const isMenuUpdateDisabled = !isPositiveValue(logic.menuCantineDataRef.current?.id) || !isPositiveValue(logic.menuJourData?.id);

   const menuContent = useMemo<any>(() => (
        <div style={{marginTop: 10}}>
            <i>
                <FormattedMessage id={messages.ims_plat_entree}/> :
            </i> <br/>
            <span style={{paddingLeft:'4px;'}}>{logic.menuCantineDataRef.current.platCantineDataMap.get('ENTREE') ?? ''}</span><br/><br/>
            <i>
                <FormattedMessage id={messages.ims_plat_plat}/> :
            </i> <br/>
            <span style={{paddingLeft:'4px;'}}>{logic.menuCantineDataRef.current.platCantineDataMap.get('PLAT') ?? ''}</span><br/><br/>
            <i><FormattedMessage id={messages.ims_plat_dessert}/> :</i>
            <br/>
            <span style={{paddingLeft:'4px;'}}>{logic.menuCantineDataRef.current.platCantineDataMap.get('DESSERT') ?? ''}</span><br/><br/>
        </div>
    ), [logic.menuCantineDataRef.current]);


    const contextMenuItems = useMemo<any>(() => {
        if(_.isEmpty(logic.childImsDayStatusList)){
            return [] ;
        }
        return _.map(logic.childImsDayStatusList, item => {
            const icon = item.saved ?  (<ListItemIcon><VerifiedUserIcon fontSize="small" color="success" /></ListItemIcon>) : <ListItemIcon><GppMaybeOutlinedIcon fontSize="small" color="warning" /></ListItemIcon> ;

            return (
                <MenuItem key={item.enfantId} onClick={() => handleOnMenuImsDayChildStatusClick(item)}>
                    {icon}
                    <ListItemText  primaryTypographyProps={styleMenuItem}>{item.childName}</ListItemText>
                </MenuItem>
            ) ;
        });
    }, [logic.childImsDayStatusList]);

    useEffect(() => {
        const dateTime : number =   logic.imsDay?.theDateForm?.clone().startOf('day').valueOf() ?? 0;
        const idClasse : number =   logic.imsDay?.childForm?.eleves?.[0]?.classe?.id ?? 0 ;
        const enfantId : number =   logic.imsDay?.childForm?.id;

        if(logic.loadingForm || isPositiveValue(dateTime) === false || isPositiveValue(idClasse) === false){
            logic.afficherImsDay(logic.imsDay);
            logic.setChildImsDayStatusList([]);
            return
        }
        const imsChilDayStatusList: ChildImsDayStatus[] = [];
        for(const child of logic.childrenClasseRef.current){
            if(child.eleves?.[0]?.classe?.id !== idClasse){
                continue ;
            }

            const dataChilDayEtat = _.cloneDeep(ChildImsDayStatusImpl);
            dataChilDayEtat.enfantId        = child.id ;
            dataChilDayEtat.theDate         = dateTime ;
            dataChilDayEtat.classeId        = idClasse;
            dataChilDayEtat.childName       = child.childNomPrenomForm;
            dataChilDayEtat.icon            = <></> ;
            dataChilDayEtat.saved           = false ;
            imsChilDayStatusList.push(dataChilDayEtat);
        }

        loadChildrenOfImsDayClasse(dateTime, idClasse, _.size(imsChilDayStatusList)).then((enfantIds) => {
            const datastatus =  _.map(imsChilDayStatusList, item => {
                item.saved = _.includes(enfantIds, item.enfantId);
                return item ;
            }) ;
            logic.setChildImsDayStatusList(datastatus);
            logic.afficherImsDay(logic.imsDay);
        }).catch((error) => {
            logic.afficherImsDay(logic.imsDay);
        });

    }, [logic.numericChange]);



    return (<form onSubmit={logic.formRef.current.handleSubmit(onSubmit)}>
            <PapperBlock whiteBg noMargin displayNoTitle={true} >
                <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                    <Grid container spacing={2} sx={{p: 7, pt: 2, backgroundColor: 'white'}}>
                        <Grid item md={1} xs={12}><></></Grid>
                        <Grid item md={10} xs={12} style={{padding: 0}}>
                            <Box sx={{width: '100%', p: 0}}>
                                <div className={styleClasses.content} style={{width: '100%'}}>
                                    <Box className={styleClasses.childContainer} sx={{width: '100%', padding: 0}}>
                                        {
                                            logic.loadingForm &&
                                            <CircularProgress/>
                                        }
                                        {
                                            !logic.loadingForm &&
                                            <>
                                                <IconButton
                                                    aria-label="Add IMS Day"
                                                    color="primary"
                                                    onClick={logic.addImsDay}
                                                    sx={{display: "inline-block", textAlign: 'left', marginRight: 1}}>
                                                    <AddIcon/>
                                                </IconButton>
                                                <Typography component="span" sx={{
                                                    textAlign: 'center',
                                                    paddingBottom: 5,
                                                    display: "inline-block",
                                                    width: "88%"
                                                }}>
                                                    <FormattedMessage id={messages.ims_day_title}/>
                                                    {
                                                        ` ${_.upperFirst(logic.imsDay?.theDateForm?.clone().format('dddd'))} 
                                                  ${_.startCase(logic.imsDay?.theDateForm?.clone().format('LL'))}. ${logic.menuJourData?.semaineData?.nom ?? ''}`
                                                    }
                                                </Typography>
                                                <IconButton
                                                    aria-label="Return to list"
                                                    color="secondary"
                                                    onClick={logic.returnToList}
                                                    sx={{display: "inline-block", textAlign: 'right', marginRight: 2}}>
                                                    <NavigateNext/>
                                                </IconButton>
                                                <Grid container spacing={2} sx={{width: '100%', marginTop: 1, padding: 0}}>
                                                    <Grid item md={3} xs={12}>
                                                        <div className={styleClasses.inputField} style={{width: '100%'}}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment}
                                                                adapterLocale={logic.activeLang}>
                                                                <MobileDateTimePicker
                                                                    label={<FormattedMessage id={messages.day}/>}
                                                                    value={logic.imsDay?.theDateForm}
                                                                    maxDate={moment().clone().endOf('day')}
                                                                    onChange={handleDayMenuChange}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{textField: {color: 'secondary'}}}
                                                                    sx={{width: '100% !important'}}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <FormControl
                                                            sx={{
                                                                display: {xs: 'block', sm: 'inline-block'},
                                                                marginBottom: {xs: 2},
                                                                marginRight: {sm: 2},
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Autocomplete
                                                                sx={{
                                                                    width: {
                                                                        xs: '100%',
                                                                        sm: '100%',
                                                                        fontWeight: 'normal',
                                                                    }
                                                                }}
                                                                options={logic.childrenClasseRef.current}
                                                                value={logic.imsDay.childForm}
                                                                defaultValue=""
                                                                autoHighlight
                                                                getOptionLabel={(opt:any) => opt.childNomPrenomForm}
                                                                isOptionEqualToValue={(opt:any, value:any) => opt.id === value.id}
                                                                // @ts-ignore
                                                                groupBy={(opt: Child) => opt.eleves?.[0]?.classe.nom ?? ''}
                                                                loading={false}
                                                                onChange={handleChildChange}
                                                                renderOption={(props, opt:any) => (
                                                                    <Box  component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                                                        <Avatar alt="avatar"  src={opt.childphotoForm} sx={{mr: 2}}/>
                                                                        {opt.childNomPrenomForm}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={<FormattedMessage id={messages.choose_child}/>}
                                                                        required
                                                                        color="secondary"
                                                                        variant="outlined"
                                                                        sx={{marginTop: 0.4, width: '100% !important'}}
                                                                        size="small"
                                                                        placeholder={logic.searchPlaceholderRef.current}
                                                                        inputProps={{
                                                                            ...params.inputProps
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <div className={styleClasses.errorHelper}>
                                                                {logic.errors.childNomPrenomForm && <span><FormattedMessage
                                                                    id={messages.required_field_child}/></span>}
                                                            </div>
                                                        </FormControl>

                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <div className={styleClasses.inputField}>
                                                            <InputField
                                                                label={<FormattedMessage id={messages.classroom_child}/>}
                                                                size="small"
                                                                color={"secondary"}
                                                                width={'100% !important'}
                                                                value={logic.imsDay.childForm.eleves?.[0]?.classe.nom ?? ''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item md={12} xs={12} sx={{display: 'block'}}>
                                                        <Box className={styleClasses.userDetails}>
                                                            <Grid container spacing={2} sx={{width: '100%', padding: 0}}>
                                                                <Grid item md={12} xs={12} sx={{margin: 0, padding: 0}}>
                                                                    <Box
                                                                        className={classNames(styleClasses.userInfoBox)}
                                                                        style={{
                                                                            width: '100%',
                                                                            paddingBottom: 0,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <Avatar className={styleClasses.customAvatar}
                                                                                src={`${BASEURL_RESOURCES}/${logic.imsDay.childphotoForm}`}
                                                                                sx={{width: 56, height: 56}}
                                                                                alt={`${logic.imsDay.childNomPrenomForm}`}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={12} xs={12} sx={{margin: 0, padding: 0}}>
                                                                    <Box
                                                                        style={{
                                                                            width: '100%',
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <Typography className={styleClasses.titleUserInfo}
                                                                                    variant="subtitle1">
                                                                            {logic.imsDay.childNomPrenomForm}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item md={4} xs={12}>
                                                                    <Typography className={styleClasses.titleUserInfo}
                                                                                variant="subtitle2">
                                                                        <FormattedMessage id={messages.allergies_child}/>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={8} xs={12}>
                                                                    {
                                                                        _.isNull(logic.imsDay.childForm.allergy) || logic.imsDay.childForm.allergy.length < 2 ?
                                                                            <FormattedMessage id={messages.none}/> :
                                                                            logic.imsDay.childForm.allergy
                                                                    }
                                                                </Grid>


                                                                <Grid item md={4} xs={12}>
                                                                    <Typography className={styleClasses.titleUserInfo}
                                                                                variant="subtitle2">
                                                                        <FormattedMessage id={messages.health_detail_child}/>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={8} xs={12}>
                                                                    {
                                                                        _.isNull(logic.imsDay.childForm.healthDetail) || logic.imsDay.childForm.healthDetail.length < 2 ?
                                                                            <FormattedMessage id={messages.none}/> :
                                                                            logic.imsDay.childForm.healthDetail
                                                                    }
                                                                </Grid>


                                                                <Grid item md={4} xs={12}>
                                                                    <Typography className={styleClasses.titleUserInfo}
                                                                                variant="subtitle2">
                                                                        <FormattedMessage id={messages.food_restriction_child}/>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={8} xs={12}>
                                                                    {
                                                                        _.isNull(logic.imsDay.childForm.foodRestriction) || logic.imsDay.childForm.foodRestriction.length < 2 ?
                                                                            <FormattedMessage id={messages.none}/> :
                                                                            logic.imsDay.childForm.foodRestriction
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>

                                                    </Grid>

                                                    {
                                                        logic.showContent === false &&
                                                        <Box sx={{width: '100%', textAlign: 'center', alignContent: 'center',}}>
                                                            <CircularProgress color="primary"/>
                                                        </Box>
                                                    }
                                                    {
                                                        logic.showContent &&
                                                        <>
                                                            <Grid item md={12} xs={12} sx={{paddingTop: 0, marginBottom:0, marginTop: 3}}>

                                                                <Typography>
                                                                    <IconButton
                                                                        disabled={isMenuUpdateDisabled}
                                                                        aria-label="Change Menu IMS Day"
                                                                        color="primary"
                                                                        onClick={changeMenuCantine}
                                                                        sx={{marginRight: 1,}}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <FormattedMessage
                                                                    id={messages.ims_menu_day}/>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={12} xs={12} sx={{paddingTop: 0}}>
                                                                <div style={{display: 'flex', paddingTop: 0, marginBottom: 20}}>
                                                                    <Box className={styleClasses.boxImg}>
                                                                        <img
                                                                            className={styleClasses.imgItem}
                                                                            loading="lazy"
                                                                            src={
                                                                                `${_.isNull(logic.menuCantineDataRef.current.photo)
                                                                                || logic.menuCantineDataRef.current.photo.length < 1 ?
                                                                                    IMGS.photoMenu : BASEURL_RESOURCES + `/` + logic.menuCantineDataRef.current.photo}`
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <div className={styleClasses.contentCanteen}>
                                                                        <Typography>{logic.menuCantineDataRef.current.nom}</Typography>
                                                                        {menuContent}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Grid item md={12} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginBottom: 15}}>
                                                            <div>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.ims_day_how_ate}/> :
                                                                </Typography>
                                                            </div>
                                                            <FormControl className={styleClasses.radioContainer}>
                                                                <RadioGroup
                                                                    row
                                                                    value={logic.imsDay.observationCantine}
                                                                    onChange={logic.handleObsCantineChange}
                                                                >
                                                                    <FormControlLabel
                                                                        sx={{fontWeight: 'normal'}}
                                                                        value={MenujourObservation.VERY_WELL}
                                                                        control={<Radio size="small" color={"secondary"}/>}
                                                                        label={<FormattedMessage
                                                                            id={messages.ims_menu_obs_very_well}/>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={MenujourObservation.EASILY}
                                                                        control={<Radio size="small" color={"secondary"}/>}
                                                                        label={<FormattedMessage
                                                                            id={messages.ims_menu_obs_easily}/>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={MenujourObservation.GOOD}
                                                                        control={<Radio size="small" color={"secondary"}/>}
                                                                        label={<FormattedMessage id={messages.ims_menu_obs_good}/>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={MenujourObservation.NOT_GOOD}
                                                                        control={<Radio size="small" color={"secondary"}/>}
                                                                        label={<FormattedMessage
                                                                            id={messages.ims_menu_obs_not_good}/>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={MenujourObservation.NOT_AT_ALL}
                                                                        control={<Radio size="small" color={"secondary"}/>}
                                                                        label={<FormattedMessage
                                                                            id={messages.ims_menu_obs_not_at_all}/>}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                        <div className={styleClasses.errorHelper}>
                                                            {logic.errors.observationCantine &&
                                                                <span><FormattedMessage id={messages.required_field_child}/></span>}
                                                        </div>
                                                    </Grid>

                                                    <Grid item md={12} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginBottom: 15}}>
                                                            <div style={{paddingBottom: 10}}>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.ims_menu_cantine_obs}/> :
                                                                </Typography>
                                                            </div>
                                                            <div className={styleClasses.inputField}>
                                                                <InputField
                                                                    label={<FormattedMessage id={messages.comment}/>}
                                                                    size="small"
                                                                    color={"secondary"}
                                                                    InputProps={{
                                                                        rows: 2,
                                                                        multiline: true,
                                                                        inputComponent: 'textarea',
                                                                    }}
                                                                    inputProps={{
                                                                        ...logic.formRef.current.register("commentaireCantine")
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                    </Grid>

                                                    <Grid item md={12} xs={12}>
                                                        <div className={styleClasses.napTimeContainer} style={{paddingBottom: 0}}>
                                                            <Typography className={styleClasses.title}>
                                                                <FormattedMessage id={messages.ims_nap_time}/> :
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <div className={styleClasses.inputField}>
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={logic.imsDay.sieste}
                                                                exclusive
                                                                onChange={logic.handleImsDaySieste}
                                                                aria-label="Platform"
                                                            >
                                                                <ToggleButton value={false}>
                                                                    <FormattedMessage id={messages.ims_day_sieste_false}/>
                                                                </ToggleButton>
                                                                <ToggleButton value={true}>
                                                                    <FormattedMessage id={messages.ims_day_sieste_true}/>
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <div className={styleClasses.inputField}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment}
                                                                adapterLocale={logic.activeLang}>
                                                                <TimePicker
                                                                    label={<FormattedMessage id={messages.start_hour}/>}
                                                                    value={logic.imsDay.timeDebutSiesteForm}
                                                                    onChange={logic.handleNapStartChange}
                                                                    views={['hours', 'minutes']}
                                                                    slotProps={{textField: {color: 'secondary',}}}
                                                                    sx={{width: '100% !important'}}
                                                                    disabled={!logic.imsDay.sieste}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <div className={styleClasses.inputField}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment}
                                                                adapterLocale={logic.activeLang}>
                                                                <TimePicker
                                                                    label={<FormattedMessage id={messages.end_hour}/>}
                                                                    minTime={logic.imsDay.timeDebutSiesteForm}
                                                                    value={logic.imsDay.timeFinSiesteForm}
                                                                    onChange={logic.handleNapEndChange}
                                                                    views={['hours', 'minutes']}
                                                                    slotProps={{textField: {color: 'secondary',}}}
                                                                    sx={{width: '100% !important'}}
                                                                    disabled={!logic.imsDay.sieste}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </Grid>

                                                    <Grid item md={12} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginTop: 10, marginBottom: 15}}>
                                                            <div style={{paddingBottom: 10}}>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.ims_day_bowel}/> :
                                                                </Typography>
                                                            </div>
                                                            <div className={styleClasses.inputField}>
                                                                <TextField
                                                                    label={<FormattedMessage id={messages.times_number}/>}
                                                                    type="number"
                                                                    color={"secondary"}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        step: 1,
                                                                        ...logic.formRef.current.register("bowelMvt")
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </Grid>


                                                    <Grid item md={12} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginBottom: 15}}>
                                                            <div style={{paddingBottom: 10}}>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.incident}/> :
                                                                </Typography>
                                                            </div>
                                                            <div className={styleClasses.inputField}>
                                                                <InputField
                                                                    label={<FormattedMessage id={messages.comment}/>}
                                                                    size="small"
                                                                    color={"secondary"}
                                                                    InputProps={{
                                                                        rows: 2,
                                                                        multiline: true,
                                                                        inputComponent: 'textarea',
                                                                    }}
                                                                    inputProps={{
                                                                        ...logic.formRef.current.register("incident")
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                    </Grid>

                                                    <Grid item md={6} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginBottom: 15}}>
                                                            <div style={{paddingBottom: 10}}>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.obs_gen}/> :
                                                                </Typography>
                                                            </div>
                                                            <div className={styleClasses.inputField}>
                                                                <InputField
                                                                    label={<FormattedMessage id={messages.comment}/>}
                                                                    size="small"
                                                                    color={"secondary"}
                                                                    InputProps={{
                                                                        rows: 3,
                                                                        multiline: true,
                                                                        inputComponent: 'textarea',
                                                                    }}
                                                                    inputProps={{
                                                                        ...logic.formRef.current.register("observationTeacher")
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <div className={styleClasses.containerObservation}
                                                             style={{marginBottom: 15}}>
                                                            <div style={{paddingBottom: 10}}>
                                                                <Typography className={styleClasses.title}>
                                                                    <FormattedMessage id={messages.obs_parent}/> :
                                                                </Typography>
                                                            </div>
                                                            <div className={styleClasses.inputField}>
                                                                <InputField
                                                                    label={<FormattedMessage id={messages.comment}/>}
                                                                    size="small"
                                                                    color={"secondary"}
                                                                    InputProps={{
                                                                        rows: 3,
                                                                        multiline: true,
                                                                        inputComponent: 'textarea',
                                                                    }}
                                                                    disabled
                                                                    value={logic.imsDay.commentaireParent}
                                                                />
                                                            </div>

                                                        </div>
                                                    </Grid>

                                                    <Box style={{width: '100%', marginTop: 10, paddingBottom: 10, marginLeft: 22}}>
                                                        <div>
                                                            <Typography className={styleClasses.title}>
                                                                <FormattedMessage id={messages.child_stuff}/> :
                                                            </Typography>
                                                        </div>
                                                        <FormGroup style={{marginTop: 12}}>
                                                            <Grid container spacing={2} md={12} xs={12}>
                                                                {
                                                                    logic.childStuffList.map((data: ChildStuff) => (
                                                                        <Grid key={data.id} item md={4} xs={6} sx={{mb: 2}}>
                                                                            <FormControlLabel
                                                                                control={<Checkbox checked={data.activer}
                                                                                                   onChange={(event: any) => handleOnChildStuffCheck(data, event)}/>}
                                                                                label={<Typography style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '12px'
                                                                                }}>{data.nom}</Typography>}
                                                                            />
                                                                            <TextField
                                                                                sx={{display: 'block'}}
                                                                                label={<FormattedMessage
                                                                                    id={messages.header_quantite}/>}
                                                                                type="number"
                                                                                color={"secondary"}
                                                                                value={data.quantite}
                                                                                disabled={!data.activer}
                                                                                onChange={(event: any) => handleQuantiteChange(data, event)}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    step: 1
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </FormGroup>
                                                    </Box>

                                                    <Grid item md={12} xs={12}>
                                                        <div style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'right',
                                                            justifyContent: 'right'
                                                        }}>
                                                            <div style={{float: 'right'}}>
                                                                <Button onClick={logic.returnToList} variant="contained"
                                                                        size="small" color={"primary"}>
                                                                    {<FormattedMessage id={messages.return_to_list}/>}
                                                                </Button>
                                                            </div>
                                                            <div style={{marginLeft: 10, float: 'right'}}>
                                                                <Button
                                                                    color="secondary"
                                                                    variant="contained"
                                                                    type="submit"
                                                                >
                                                                    <SaveIcon sx={{marginRight: 1}}/> {<FormattedMessage
                                                                    id={messages.save_form_crud_btn}/>}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </>
                                        }
                                    </Box>

                                </div>
                            </Box>
                        </Grid>
                        <Grid item md={1} xs={12}><></></Grid>
                    </Grid>
                    {
                        _.isObject(logic.imsDay) &&
                        _.isObject(logic.imsDay?.theDateForm) &&
                        <Menu
                            open={contextMenu !== null}
                            onClose={handleCloseConextMenu}
                            anchorReference="anchorPosition"
                            slotProps={{
                                paper: {
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 9.0,
                                    },
                                },
                            }}
                            anchorPosition={
                                contextMenu !== null
                                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                    : undefined
                            }
                        >
                            <MenuItem>
                                <ListItemText
                                    primaryTypographyProps={{...styleMenuItem, fontWeight:'bold'}}
                                    primary={`Ims Day ${_.upperFirst(logic.imsDay?.theDateForm?.clone().format('dddd'))}, ${logic.imsDay?.theDateForm?.clone().format('L')}`}
                                    secondary={`${logic.imsDay?.childForm?.childNomPrenomForm ?? ``} (${logic.imsDay?.childForm?.eleves?.[0]?.classe?.nom ?? ``})`}
                                />
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logic.formRef.current.handleSubmit(onSubmit)}>
                                <ListItemIcon>
                                    <SaveIcon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{...styleMenuItem, fontWeight:'bold', color:'secondary'}}
                                > <FormattedMessage id={messages.save_form_crud_btn}/></ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logic.returnToList}>
                                <ListItemIcon>
                                    <ListIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{...styleMenuItem, fontWeight:'bold'}}
                                > <FormattedMessage id={messages.return_list}/></ListItemText>
                            </MenuItem>
                            <Divider />
                            {contextMenuItems}
                        </Menu>
                    }

                </div>
        </PapperBlock>
    </form>
);


}

export default ChildDayForm;
