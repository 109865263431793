import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import dataPays from "../../data/pays";
import {isPositiveValue} from "../../helpers/helpers";


export const PAYSPERSON_CONSTANT = {
    convertToFrontEnd: (logic:any, item:PaysPerson) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(paysPersonDataImpl);
        const data = {...itemInit, ...item} ;
        data.paysForm =  {
            "id" : 110,
            "code" : 384,
            "alpha2" : "CI",
            "alpha3" : "CIV",
            "nom_en_gb" : "Côte d'Ivoire",
            "nom_fr_fr" : "Côte d'Ivoire",
            "afric" : 1,
            "statut" : 1,
            "etat" : "OK",
            "miseajour" : null,
            "editable" : false,
            "editeur" : null,
            "tag" : null,
            "metadata" : "{}"
        } ;
        if(isPositiveValue(data.paysId)){
            data.paysForm = _.find(dataPays, {"id":data.paysId}) ?? data.paysForm;
        }

        return data ;
    },
    convertToBackEnd: (item:PaysPerson) => item,
};

export  const paysPersonDataImpl: PaysPerson = {
    id: null,
    paysId: 0,
    personId: 0,
    paysForm: `{}`,
    common: _.cloneDeep(commonDataImp)
}

export const paysPersonSchema = yup.object().shape({
    id: yup.number().nullable(),
    paysId: yup.number().nonNullable(),
    personId: yup.number().nullable(),
    common: yup.mixed<Common>(),
    paysForm: yup.mixed<any>(),
});

export type PaysPerson = yup.InferType<typeof paysPersonSchema>;
