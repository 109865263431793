import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import _ from "lodash";
import * as yup from "yup";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {Child, childDataImpl} from "./Child";
import {CourrielRecipient, courrielRecipientDataImpl} from "./CourrielRecipient";
import {Groupesection, groupesectionDataImpl} from "./Groupesection";
import {GroupEnfant, groupEnfantDataImpl} from "./GroupEnfant";
import {ScolariteAnnee, scolariteAnneeDataImpl} from "./ScolariteAnnee";

export const SCOLARITEPROFROMAENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteProformaEnfant) => {
        const itemInit = _.cloneDeep(scolariteProformaEnfantDataImpl);
        item = {...itemInit, ...item} ;

        item.laDateForm         = moment(item.ladate);
        // @ts-ignore
        item.lireDateForm       = item.laDateForm?.clone().format('L');
        item.montantForm        = new Intl.NumberFormat("fr-FR").format(item.montant ?? 0) ;
        item.fichierForm        = `${BASEURL_RESOURCES}/${item.fichier}` ;

        item.groupEnfantForm = _.find(logic.groupEnfantsRef.current, {id: item?.groupEnfantId}) ?? _.cloneDeep(groupesectionDataImpl);

        item.groupesectionForm = _.find(logic.groupesectionsRef.current, {id: item.groupEnfantForm?.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);
        item.groupesectionNomForm     = item.groupesectionForm?.nom ;

        item.scolariteAnneeForm = _.find(logic.scolariteAnneesRef.current, {id: item.groupEnfantForm?.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);

        item.childForm          = _.find(logic.childrenRef.current, {id:item.groupEnfantForm?.enfantId}) ?? _.cloneDeep(childDataImpl);
        // @ts-ignore
        item.enfantNomForm      = `${item.childForm?.person.nom} ${item.childForm?.person.prenom}`;
        item.montantForm        = new Intl.NumberFormat("fr-FR").format(item.montant ?? 0);

        const courrielRecips: CourrielRecipient[] = [];
        item.childForm?.enfantParents?.forEach(ef => {
            const courrielRecip: CourrielRecipient = _.cloneDeep(courrielRecipientDataImpl);
            courrielRecip.parentNom = `${ef?.parent.person.nom} ${ef?.parent.person.prenom}`;
            courrielRecip.parentCourriel = `${ef?.parent.person.email}`;
            courrielRecip.enfantNom = item.enfantNomForm;
            courrielRecips.push(courrielRecip);
        })
        item.courrielRecipientJson = JSON.stringify(courrielRecips);

        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteProformaEnfant) => item
};
export  const scolariteProformaEnfantDataImpl: ScolariteProformaEnfant = {
    id: null,
    ladate: 0,
    groupEnfantId: 0,
    montant: 0,
    nom: '',
    notabene: '',
    titreDocument: '',
    fichier: '',
    scolariteProformaId:0,
    scolaritetypes: '',
    scolaritetypesnames: '',
    courrielRecipientJson: '',
    fichierForm: '',
    montantForm: '',
    laDateForm: null,
    lireDateForm: '',
    groupEnfantForm: _.cloneDeep(groupEnfantDataImpl),
    groupesectionForm: _.cloneDeep(groupesectionDataImpl),
    childForm: _.cloneDeep(childDataImpl),
    groupesectionNomForm: ``,
    scolariteAnneeForm: _.cloneDeep(scolariteAnneeDataImpl),
    enfantNomForm: ``,
    enfantClasseForm: ``,
    common: _.cloneDeep(commonDataImp),
}

export const scolariteProformaEnfantSchema = yup.object().shape({
    id:  yup.number().nullable(),
    ladate: yup.number(),
    groupEnfantId: yup.number(),
    montant: yup.number(),
    nom: yup.string().default(''),
    fichier: yup.string().default(''),
    notabene: yup.string().default(''),
    titreDocument: yup.string().default(''),
    scolariteProformaId: yup.number(),
    scolaritetypes: yup.string(),
    scolaritetypesnames: yup.string(),
    courrielRecipientJson: yup.string(),
    fichierForm: yup.string(),
    montantForm: yup.object().nullable(),
    laDateForm: yup.object().nullable(),
    lireDateForm: yup.string().default(''),
    groupEnfantForm: yup.mixed<GroupEnfant>(),
    groupesectionForm: yup.mixed<Groupesection>(),
    childForm: yup.mixed<Child>(),
    scolariteAnneeForm:  yup.mixed<ScolariteAnnee>(),
    groupesectionNomForm: yup.string(),
    enfantNomForm: yup.string(),
    enfantClasseForm: yup.string(),
    common: yup.mixed<Common>(),
});

export type ScolariteProformaEnfant = yup.InferType<typeof scolariteProformaEnfantSchema>;
