import {alpha, Box, Typography} from "@mui/material";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            display: 'flex',
            padding: 5,
            marginBottom: 6,
            minHeight: 100,
        },
        title: {
            color: theme.palette.common.white,
            fontSize: '14px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px !important',
            },
            fontWeight: '400 !important',
        },
        amount: {
            color: theme.palette.common.white,
            fontSize: '28px !important',
            fontWeight: '500 !important',
            padding: 0,
            margin: 0
        },
        customTitle: {
            flex:6,
            paddingLeft: 15,
        },
        customContent: {
            flex:1,
            textAlign: 'left',
        },
        primaryLight: {
            background: theme.palette.primary.light,
            '& $title, $amount': {
                color: theme.palette.primary.main,
            },
        },
        primaryMain: {
            border: `1px solid ${alpha(theme.palette.primary.main, 0.7)}`,
            '& $title, $amount': {
                color: theme.palette.primary.main,
            },
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        primaryDark: {
            background: theme.palette.primary.main,
            '& $title, $amount': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.primary.light,
            },
        },
        secondaryLight: {
            background: theme.palette.secondary.light,
            '& $title, $amount': {
                color: theme.palette.secondary.main,
            },
        },
        secondaryMain: {
            '& $title, $amount': {
                color: theme.palette.text.primary,
            },
            '& svg': {
                color: theme.palette.text.secondary,
            },
        },
        secondaryDark: {
            background: theme.palette.secondary.main,
            '& $title, $amount': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.secondary.light,
            },
        },
        thirdlyMain: {
            '& $title, $amount': {
                color: theme.palette.text.primary,
            },
            '& svg': {
                color: theme.palette.warning.main,
            },
        },
        fourthMain: {
            '& $title, $amount': {
                color: theme.palette.text.primary,
            },
            '& svg': {
                color: theme.palette.success.main,
            },
        },
    }
})

export default function TotalAmountWidget(props:any) {
    const { color, title, amount, devise, children } = props;
    const classes = useStyles();

    const bgColor = (clr:string) => {
        switch (clr) {
            case 'primary-light':
                return classes.primaryLight;
            case 'primary-dark':
                return classes.primaryDark;
            case 'secondary-light':
                return classes.secondaryLight;
            case 'secondary-main':
                return classes.secondaryMain;
            case 'secondary-dark':
                return classes.secondaryDark;
            case 'thirdly-main':
                return classes.thirdlyMain;
            case 'fourth-main':
                return classes.fourthMain;
            default:
                return classes.primaryMain;
        }
    };
    return (
        <Box className={classNames(classes.root, bgColor(color))}>
            <div className={classes.customContent}>{children}</div>
            <Box className={classes.customTitle} letterSpacing={1}>
                <Box>
                    <Typography className={classes.title} variant="subtitle2">
                        {title}
                    </Typography>
                </Box>
                <Typography className={classes.amount} variant="subtitle1">
                    {amount} {devise}
                </Typography>
            </Box>
        </Box>
    )
}
