import {RoleUser} from "./RoleUser";
import * as yup from "yup";
import moment from "moment";
import _ from "lodash";

const today = moment().startOf('day');
const yesterday = moment().subtract(1, 'day').startOf('day');
export const MESSAGE_CONSTANT = {
    convertToFrontEnd: (item:Message) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(messageDataImpl);
        item =  {...itemInit, ...item} ;

        const messageDate = moment(item.theDate);
        const messageDateDay = messageDate.clone().startOf('day');
        if(messageDateDay.isSame(today)){
            item.theDateForm = messageDate.format('LT');
        } else if(messageDateDay.isSame(yesterday)) {
            const textday = moment.locale() === 'en' ? 'Yesterday' : 'Hier';
            item.theDateForm = `${textday} ${messageDate.format('LT')}`;
        } else {
            item.theDateForm = `${messageDate.format('ll')} ${messageDate.format('LT')}`;
        }
        return item ;
    },
    convertToBackEnd: (item:Message) => item,
};

export  const messageDataImpl: Message = {
    id: null,
    userId: 0,
    userUuid: '',
    roleUser: RoleUser.TEACHER,
    filDiscussionId: 0,
    theDate: 0,
    message: '',
    fichier: '',
    userNom: '',
    userPhoto: '',
    theDateForm:'',
    nomFichier:'',
    typeFichier:'',
}

export const messageSchema = yup.object().shape({
    id: yup.number().nullable(),
    userId: yup.number(),
    userUuid: yup.string(),
    roleUser: yup.mixed<RoleUser>().oneOf(Object.values(RoleUser)).default(RoleUser.TEACHER),
    filDiscussionId: yup.number(),
    theDate: yup.number(),
    message: yup.string(),
    fichier: yup.string(),
    userNom: yup.string(),
    userPhoto: yup.string(),
    theDateForm: yup.string(),
    nomFichier: yup.string(),
    typeFichier: yup.string(),
});

export type Message = yup.InferType<typeof messageSchema>;