const BASEURL = 'https://erp.troovite.com';
const deco = BASEURL + '/images/decoration/hexaDecoration.svg';

const wrapper = (theme:any) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
    },
});

const styles = (theme:any) => ({
    root: {
        width: '100%',
        height:'100vh',
        zIndex: 1,
        //background: theme.palette.primary.main,
        backgroundColor: '#F7F5F3',
    },
    containerSide: {
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden',
        },
    },
    opening: {
        overflow: 'hidden',
        '&:before': {
            content: "''",
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: 0.1,
            background: `url(${deco}) no-repeat 230px 480px`,
        },
    },
    sideFormWrap: {
        width: '45%',
        height: '100%',
        zIndex: 1,
        //position: 'relative',
        margin: 'auto',
        paddingTop: '7%',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            width: 630,
        },
        [theme.breakpoints.down('sm')]: {
            width: '85%',
        },
    },
    sideWrap: {
        ...wrapper(theme),
        height: 400,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    sideResetWrap: {
        ...wrapper(theme),
        height: 320,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    icon: {},
    topBar: {
        display: 'flex',
        marginBottom: theme.spacing(7),
        paddingBottom: theme.spacing(1),
        maxWidth: 480,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderBottom: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
        '& $icon': {
            margin: `0 ${theme.spacing(1)}px`,
        },
        '& .MuiTypography-root':{
            fontWeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
    },
    title: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    pageFormSideWrap: {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            width: 480,
        },
        [theme.breakpoints.only('sm')]: {
            width: '100%',
            margin: 'auto',
        },
        '& .MuiOutlinedInput-root':{
            height: 50,
        },
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    space: {
        marginBottom: theme.spacing(3)
    },
    btnArea: {
        display: 'flex',
        justifyContent: 'center',
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
        fontSize: 12,
        '& $label': {
            fontSize: 12,
            '& span': {
                fontSize: 12,
            },
        },
        '& button': {
            margin: `0 ${theme.spacing(1)}px`,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& button': {
                width: '100%',
                margin: 5,
            },
        },
    },
    optArea: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop:-10,
    },
    label: {
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightRegular,
        }
    },
    buttonLink: {
        padding: 0,
        paddingTop: 3,
        [theme.breakpoints.up('md')]: {
            paddingTop: 8,
        },
        transition: 'color ease 0.3s',
        fontWeight: theme.typography.fontWeightRegular,
    },
    errorHelper:{
        color: '#FF0000',
        fontSize: 13,
        paddingTop:7,
        paddingLeft: 2,
    },
    errorMessage: {
        flex:1,
        color: '#FF0000',
        fontSize: 14,
        marginBottom: 15,
    }
})

export default styles
