import {CreneauRdv} from "../../../constants/type/CreneauRdv";
import {CreneauRdvEmployee} from "../../../constants/type/CreneauRdvEmployees";
import {LectureRdv, Rdv} from "../../../constants/type/Rdv";
import {CreneauRdvEnfantParent} from "../../../constants/type/CreneauRdvEnfantParent";
import {RdvType} from "../../../constants/type/RdvType";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import _ from "lodash";
import rdvNormalBusinessLogic from "./RdvNormalBusinessLogic";


const EmployeeFeedbackParentInitToggle = (props:any) =>{

    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.CANCEL}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.REPORT} disabled>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.REPORT)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.CONFIRM}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CONFIRM)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
const EmployeeInitToggle = (props:any) =>{

    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.CANCEL}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
const EmployeeReportToggle = (props:any) => {

    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.CANCEL}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.REPORT} disabled>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.REPORT)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.CONFIRM}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CONFIRM)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
const EmployeeFeedbackParentReportToggle= (props:any) => {
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);
    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.CANCEL}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.REPORT} disabled>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.REPORT)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.CONFIRM}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CONFIRM)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
const EmployeeConfirmToggle = (props:any) => {
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);
   // console.log('BIZARRE creneauRdvEmployee.meetingStatus >>> ', creneauRdvEmployee.meetingStatus);
    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.CANCEL}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.REPORT} disabled>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.REPORT)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
const EmployeeCancelToggle= (props:any):any => {

    return <></>
}
const EmployeeRdvNotAgreedToggle = (props:any) => {
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.NOT_HELD}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.NOT_HELD)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.DONE}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.DONE)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

const EmployeeRapportConfirmRdvToggle = (props:any) =>{

    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(props.logicRacine,  value);

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.logicRacine.creneauRdvEmployeeNorm.meetingStatus}
            exclusive
            onChange={handleEmployeeMeetingStatusChange}
            disabled={props.logicRacine.isFormDisableForCurrentUserRef.current}
            aria-label="Platform"
        >
            <ToggleButton value={RdvEtat.DONE}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.DONE)}`]} />
            </ToggleButton>
            <ToggleButton value={RdvEtat.NOT_RESPECTED}>
                <FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.NOT_RESPECTED)}`]} />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export const employeeNormalToggle = (props:any) => {
    switch (props.component) {
        case 'employeeCancelToggle':
            return <EmployeeCancelToggle {...props} />;
        case 'employeeInitToggle':
            return <EmployeeInitToggle  {...props} />;
        case 'employeeRapportConfirmRdvToggle':
            return <EmployeeRapportConfirmRdvToggle  {...props} />;
        case 'employeeRdvNotAgreedToggle':
            return <EmployeeRdvNotAgreedToggle  {...props} />;
        case 'employeeConfirmToggle':
            return <EmployeeConfirmToggle  {...props} />;
        case 'employeeReportToggle':
            return <EmployeeReportToggle  {...props} />;
        case 'employeeFeedbackParentReportToggle':
            return <EmployeeFeedbackParentReportToggle  {...props} />;
        case 'employeeFeedbackParentInitToggle':
            return <EmployeeFeedbackParentInitToggle  {...props} />;
        default:
            return <></>;
    }
}

const rdvNormalLectureBusinessLogic: any = {
    lire: (logicRacine:any,
                           rdv:Rdv,
                           creneauRdv:CreneauRdv,
                           creneauRdvEmployee:CreneauRdvEmployee,
                           creneauRdvEnfantParent:CreneauRdvEnfantParent,
                           lectureRdv:LectureRdv): LectureRdv  => {


        if(rdv.meetingType !== RdvType.NORMAL){
            return lectureRdv ;
        }
        if(_.isNull(rdv.id) || _.isUndefined(rdv.id)){
            return lectureRdv ;
        }
        lectureRdv.showDeleteBtn = rdv.meetingStatus === RdvEtat.WAIT ;
        if(_.isNull(creneauRdvEmployee) || _.isUndefined(creneauRdvEmployee)){
            return lectureRdv ;
        }
        if(_.isNull(creneauRdvEnfantParent) || _.isUndefined(creneauRdvEnfantParent)){
            return lectureRdv ;
        }

        lectureRdv  = rdvNormalLectureBusinessLogic.lireBeforeMeeting(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent, lectureRdv);
        lectureRdv  = rdvNormalLectureBusinessLogic.lireAfterMeeting(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent, lectureRdv);

        if(_.isNull(creneauRdvEnfantParent) || _.isUndefined(creneauRdvEnfantParent)){
            return lectureRdv ;
        }

        lectureRdv.showDeleteBtn = rdv.meetingStatus === RdvEtat.WAIT ;
        lectureRdv.title= rdv.objet;
        lectureRdv.meetingStatus = rdv.meetingStatus;
        lectureRdv.timeSlotStyle = logicRacine.styleClasses.eventContainerNormal;

        return lectureRdv;
    },
    lireAfterMeeting:(logicRacine:any,
                      rdv:Rdv,
                      creneauRdv:CreneauRdv,
                      creneauRdvEmployee:CreneauRdvEmployee,
                      creneauRdvEnfantParent:CreneauRdvEnfantParent,
                      lectureRdv:LectureRdv): LectureRdv => {

        if(logicRacine.statusAfterMeetingViewNormRef.current === false){
            return lectureRdv;
        }


        if(rdv.meetingStatus === RdvEtat.CANCEL){
            //Employé a annulé le rendez-vous
            lectureRdv.subtitle = 'rdv_read_title_employee_cancel';
            lectureRdv.parentStatusLibelle  = 'rdv_read_void';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
           // lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeCancelToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeCancelToggle";
            lectureRdv.employeeEditDisable = true ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = true ;

            //Parent a annulé le rendez-vous
            if(creneauRdvEnfantParent.meetingStatus === RdvEtat.CANCEL){
                lectureRdv.subtitle = 'rdv_read_title_parent_cancel';
                lectureRdv.parentStatusLibelle  = 'rdv_read_void';
                lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
                //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeCancelToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
                lectureRdv.employeeToggle  = "employeeCancelToggle";
                lectureRdv.employeeEditDisable = true ;
                lectureRdv.parentOutcomeShow = false ;
                lectureRdv.employeeOutcomeShow = false ;
            }
        }

        else if(rdv.meetingStatus === RdvEtat.CONFIRM){
            //Rapport d'un rendez-vous confirmé
            lectureRdv.subtitle = 'rdv_read_title_confirm_rapport';
            lectureRdv.parentStatusLibelle  = 'rdv_read_void';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
           // lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeRapportConfirmRdvToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeRapportConfirmRdvToggle";
            lectureRdv.employeeEditDisable = true ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = true ;
        }
        else {
            //Rapport d'un rendez-vous non-confirmé et non annulé
            lectureRdv.subtitle = 'rdv_read_title_notheld_rapport';
            lectureRdv.parentStatusLibelle  = 'rdv_read_void';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
           // lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeRdvNotAgreedToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeRdvNotAgreedToggle";
            lectureRdv.employeeEditDisable = true ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = true ;
        }

        return lectureRdv ;
    },
    lireBeforeMeeting:(logicRacine:any,
                       rdv:Rdv,
                       creneauRdv:CreneauRdv,
                       creneauRdvEmployee:CreneauRdvEmployee,
                       creneauRdvEnfantParent:CreneauRdvEnfantParent,
                       lectureRdv:LectureRdv): LectureRdv => {
        if(logicRacine.statusAfterMeetingViewNormRef.current){
            return lectureRdv;
        }

        if(rdv.meetingStatus === RdvEtat.WAIT){
            //Employé initie le rdv
            lectureRdv.subtitle = 'rdv_read_parent_feedback';
            lectureRdv.parentStatusLibelle  = 'rdv_read_parent_noresponse_yet';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
           // lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeInitToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeInitToggle";
            lectureRdv.employeeEditDisable = false ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = false ;

            //Parent initie le rdv
            if(creneauRdvEnfantParent.userId === rdv.userInitor){
                lectureRdv.subtitle                = 'rdv_read_employee_feedback';
                lectureRdv.parentStatusLibelle  = 'rdv_read_parent_wait_response';
                lectureRdv.employeeStatusLibelle  = 'rdv_read_employee_which_outcome';
               // lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeFeedbackParentInitToggle(logicRacine, rdv, creneauRdv,creneauRdvEmployee, creneauRdvEnfantParent);
                lectureRdv.employeeToggle  = "employeeFeedbackParentInitToggle";
                lectureRdv.employeeEditDisable = false ;
                lectureRdv.parentOutcomeShow = true ;
                lectureRdv.employeeOutcomeShow = true ;
            }
        }

        if(rdv.meetingStatus === RdvEtat.REPORT){
            //Employé dernière proposition de date
            lectureRdv.subtitle = 'rdv_read_title_employee_report';
            lectureRdv.parentStatusLibelle  = 'rdv_read_parent_feedback';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
            //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeReportToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeReportToggle";
            lectureRdv.employeeEditDisable = false ;
            lectureRdv.parentOutcomeShow = true ;
            lectureRdv.employeeOutcomeShow = true ;
            lectureRdv.lastReportDateDebut = creneauRdv.lastReportDateDebut ;
            lectureRdv.lastReportDateFin = creneauRdv.lastReportDateFin ;
            lectureRdv.lastReportUserId = creneauRdv.lastReportUserId ;

            //Parent dernière proposition de date
            if(creneauRdvEnfantParent.parentId === creneauRdv.lastReportUserId){
                lectureRdv.subtitle = 'rdv_read_title_parent_report';
                lectureRdv.parentStatusLibelle  = 'rdv_read_void';
                lectureRdv.employeeStatusLibelle  = 'rdv_read_employee_which_outcome';
                //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeFeedbackParentReportToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
                lectureRdv.employeeToggle  = "employeeFeedbackParentReportToggle";
                lectureRdv.employeeEditDisable = false ;
                lectureRdv.parentOutcomeShow = true ;
                lectureRdv.employeeOutcomeShow = true ;
            }
        }

        if(rdv.meetingStatus === RdvEtat.CONFIRM){
            //Employé confirmé le rendez-vous
            lectureRdv.subtitle = 'rdv_read_title_confirm';
            lectureRdv.parentStatusLibelle  = 'rdv_read_void';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
            //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeConfirmToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeConfirmToggle";
            lectureRdv.employeeEditDisable = false ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = true ;

            //Parent confirmé le rendez-vous
            if(creneauRdvEnfantParent.parentId === creneauRdv.lastReportUserId){
                lectureRdv.subtitle = 'rdv_read_title_parent_report';
                lectureRdv.parentStatusLibelle  = 'rdv_read_void';
                lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
                //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeConfirmToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
                lectureRdv.employeeToggle  = "employeeConfirmToggle";
                lectureRdv.employeeEditDisable = false ;
                lectureRdv.parentOutcomeShow = false ;
                lectureRdv.employeeOutcomeShow = true ;
            }
        }

        if(rdv.meetingStatus === RdvEtat.CANCEL){
            //Employé a annulé le rendez-vous
            lectureRdv.subtitle = 'rdv_read_title_employee_cancel';
            lectureRdv.parentStatusLibelle  = 'rdv_read_void';
            lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
            //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeCancelToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
            lectureRdv.employeeToggle  = "employeeCancelToggle";
            lectureRdv.employeeEditDisable = true ;
            lectureRdv.parentOutcomeShow = false ;
            lectureRdv.employeeOutcomeShow = true ;

            //Parent a annulé le rendez-vous
            if(creneauRdvEnfantParent.meetingStatus === RdvEtat.CANCEL){
                lectureRdv.subtitle = 'rdv_read_title_parent_cancel';
                lectureRdv.parentStatusLibelle  = 'rdv_read_void';
                lectureRdv.employeeStatusLibelle  = 'rdv_read_void';
                //lectureRdv.employeeToggle  = rdvNormalLectureBusinessLogic.employeeCancelToggle(logicRacine, rdv, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent);
                lectureRdv.employeeToggle  = "employeeCancelToggle";
                lectureRdv.employeeEditDisable = true ;
                lectureRdv.parentOutcomeShow = false ;
                lectureRdv.employeeOutcomeShow = false ;
            }
        }
        return lectureRdv ;
    }
};

export default rdvNormalLectureBusinessLogic ;
