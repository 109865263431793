import React, {useState} from "react";
import {ChildStuff, childStuffDataImpl} from "../../../../constants/type/ChildStuff";
import moment from "moment";
import _ from "lodash";


const useChildStuffVariables = (props: any): any => {
    const {snackbarShowMessage, theme, classes} = props;

    const [childEdit, setChildEdit] = React.useState<ChildStuff>(_.cloneDeep(childStuffDataImpl));
    const [childStuffList, setChildStuffList] = React.useState<ChildStuff[]>([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const activeLanguageRef = React.useRef<any[]>(props.locale.split('-'));
    const [tableKeyValue, setTableKeyValue] = useState(moment().valueOf());
    const [openDialog, setOpenDialog] = React.useState(false);
    const [savingStateBtn, setSavingStateBtn] = React.useState(false);

    return {
        snackbarShowMessage,theme,classes,tableDataLoading,setTableDataLoading,openDialog,setOpenDialog,
        childStuffList,setChildStuffList,activeLanguageRef,tableKeyValue,setTableKeyValue,
        childEdit,setChildEdit,savingStateBtn,setSavingStateBtn
    }
}

export default useChildStuffVariables;