import _ from "lodash";
import * as yup from "yup";

export const ERRORRESPONSEDATA_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ErrorResponseData) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(errorResponseDataDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ErrorResponseData) => item,
};

export  const errorResponseDataDataImpl: ErrorResponseData = {
    error: '',
    message: '',
    codeMessage: '',
    guid: '',
    timestamp: '',
    path: '',
    method: '',
    status: 400,
    httpStatusName: '',
    errorDetails: [],
}

export const errorResponseDataSchema = yup.object().shape({
    error: yup.string(),
    message: yup.string(),
    codeMessage: yup.string(),
    guid: yup.string(),
    timestamp: yup.string(),
    path: yup.string(),
    method: yup.string(),
    status: yup.number(),
    httpStatusName: yup.string(),
    errorDetails: yup.array().of(yup.mixed<any>()).nonNullable().default([]),
});

export type ErrorResponseData = yup.InferType<typeof errorResponseDataSchema>;