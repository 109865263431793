import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {AlertMessageParent} from "./AlertMessageParent";
import messages from "../../page/messages/general/messages";
import {Avatar, Box} from "@mui/material";
import {stringToColor} from "../../helpers/helpers";
import React from "react";

export const ALERTMESSAGE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:AlertMessage) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(alertMessageDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:AlertMessage) => item,
};

export  const alertMessageDataImpl: AlertMessage = {
    id: null,
    nom: ``,
    message: ``,
    alertMessageParents: [],
    common: _.cloneDeep(commonDataImp)
}

export const alertMessageSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    message: yup.string().nonNullable().required(),
    alertMessageParents: yup.array().of(yup.mixed<AlertMessageParent>()).nonNullable().default([]),
    common: yup.mixed<Common>(),
});

export type AlertMessage = yup.InferType<typeof alertMessageSchema>;