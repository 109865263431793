export const usersList = [
    {
        id:1,
        firstName:"Nicole",
        lastName:"Kodjo",
        profile: "teacher",
        class:"Acajou",
        username:"atuny0",
        image:"https://troovite.com/media/upload/photo/Nicole.png",
    },
    {
        id:2,
        firstName:"Ange",
        lastName:"Koffi",
        profile: "teacher",
        class:"Bamboo",
        username:"atuny0",
        image:"https://troovite.com/media/upload/photo/Ange.png",
    },
    {
        id:3,
        firstName:"Gloria",
        lastName:"Moukouri",
        profile: "teacher",
        class:"Baobab",
        username:"atuny0",
        image:"https://troovite.com/media/upload/photo/Gloria.png",
    },
    {
        id:4,
        firstName:"Gamuchirai",
        lastName:"Atta",
        profile: "teacher",
        class:"Iroko",
        username:"yraigatt3",
        image:"https://troovite.com/media/upload/photo/Gamu.png",
    },
    {
        id:5,
        firstName:"Najia",
        lastName:"Schultz",
        profile: "teacher",
        class:"Samba",
        username:"kmeus4",
        image:"https://troovite.com/media/upload/photo/Najia.png",
    },
    {
        id:6,
        firstName:"Anam",
        lastName:"Hassam",
        profile: "admin",
        class:"",
        username:"jtreleven5",
        image:"https://troovite.com/media/upload/photo/Anam.png",
    },
    {
        id:7,
        firstName:"Yasmeen",
        lastName:"Mitha",
        profile: "admin",
        class:"",
        username:"dpettegre6",
        image:"https://troovite.com/media/upload/photo/me_profile.jpg",
    },
    {
        id:8,
        firstName:"Georgette",
        lastName:"Umuhoza",
        profile: "admin",
        class:"",
        username:"ggude7",
        image:"https://troovite.com/media/upload/photo/Georgette_Rpq3BZd.png",
    },
    {
        id:9,
        firstName:"Camille",
        lastName:"Dodde",
        profile: "admin",
        class:"",
        username:"nloiterton8",
        image:"https://troovite.com/media/upload/photo/Camille.png",
    },
    {
        id:10,
        firstName:"Martial",
        lastName:"Price",
        profile: "teacher",
        class:"Samba",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/photo/Marcial.png",
    },
    {
        id:11,
        firstName:"Hanta",
        lastName:"Soro",
        profile: "assistant",
        class:"",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/photo/Hanta.png",
    },
    {
        id:13,
        firstName:"Désirée",
        lastName:"Kipré Epse WILLIAMS",
        profile: "assistant",
        class:"",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/photo/Desiree.png",
    },
    {
        id:14,
        firstName:"Édith",
        lastName:"Dioulo",
        profile: "assistant",
        class:"",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/photo/Edith.png",
    },
    {
        id:15,
        firstName:"Ali",
        lastName:"Dekmak",
        profile: "children",
        class:"Iroko",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/child_221.jpeg",
    },
    {
        id:16,
        firstName:"Alyona",
        lastName:"Amata",
        profile: "children",
        class:"Bamboo",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/child_222.jpeg",
    },
    {
        id:16,
        firstName:"Azzaria",
        lastName:"Mahtani",
        profile: "children",
        class:"Samba",
        username:"umcgourty9",
        image:"https://troovite.com/media/upload/child_122.jpeg",
    },

];
