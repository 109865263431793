import {makeStyles} from "@mui/styles";

const msgCenterStyles = makeStyles((theme:any) => {
    return {
        padding:{},
        root: {
            flexGrow: 1,
            zIndex: 0,
            overflow: 'hidden',
            position: 'relative',
            //background: theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.6) : theme.palette.primary.light,
            backgroundColor: 'rgb(245,245,248)',
            borderRadius: theme.rounded.medium,
            boxShadow: theme.shade.light,
            margin:theme.spacing(0.1),
            marginTop:0,
            marginBottom: theme.spacing(3),
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            [`& $padding`]: {
                paddingBottom: theme.spacing(3),
            },
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            }
        }
    }
});
export default msgCenterStyles ;