import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabContent(props:TabPanelProps){
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`ims-tabpanel-${index}`}
            aria-labelledby={`ims-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb:3 }}>{children}</Box>
            )}
        </div>
    );
}

export default TabContent;
