import messages from "../page/messages/general/messages";
import {FormattedMessage} from "react-intl";
import React from "react";

const ROLES:any[] = [
    <FormattedMessage id={messages.enum_admin} />,
    <FormattedMessage id={messages.enum_teacher} />,
    <FormattedMessage id={messages.enum_support} />,
];

export default [
    {
        key: 'ADMIN',
        name: ROLES[0],
    },
    {
        key: 'TEACHER',
        name: ROLES[1],
    },
    {
        key: 'SUPPORT',
        name: ROLES[2],
    },
    // {
    //     key: 'ENFANT',
    //     name: 'ENFANT',
    // },
    // {
    //     key: 'SUPER_ADMIN',
    //     name: 'SUPER_ADMIN',
    // },
]
