export const livreFiltreDataImp: LivreFiltre =  {
    livreCategorieId: 0,
    recherche: '',
    etat: '',
}

export type LivreFiltre = {
    livreCategorieId: number;
    recherche: string;
    etat: string;
}
export const paginationImp: Pagination =  {
    total:0,
    page:1,
    pageSize:10,
    pageCount:0,
}

export type Pagination = {
    total:number;
    page:number;
    pageSize:number;
    pageCount:number;
}