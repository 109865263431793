export const SCHOOL_DAY_FR = [
    {
        id: 1,
        value: 'Lundi',
    },
    {
        id: 2,
        value: 'Mardi',
    },
    {
        id: 5,
        value: 'Mercredi',
    },
    {
        id: 6,
        value: 'Jeudi',
    },
    {
        id: 7,
        value: 'Vendredi',
    },
    {
        id: 8,
        value: 'Samedi',
    },
    {
        id: 9,
        value: 'Dimanche',
    },

];

export const SCHOOL_DAY_EN = [
    {
        id: 1,
        value: 'Monday',
    },
    {
        id: 2,
        value: 'Tuesday',
    },
    {
        id: 5,
        value: 'Wednesday',
    },
    {
        id: 6,
        value: 'Thursday',
    },
    {
        id: 7,
        value: 'Friday',
    },
    {
        id: 8,
        value: 'Saturday',
    },
    {
        id: 9,
        value: 'Sunday',
    },
]

export const SCHOOL_DAY_MENU_EN = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
export const SCHOOL_DAY_MENU_FR = ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'];
