import * as yup from "yup";
import {Genre} from "./Genre";
import {RoleUser} from "./RoleUser";
import {AccountEtat} from "./AccountEtat";

export const CompteStatut_CONSTANT = {
    convertToFrontEnd: (logic:any, item:CompteStatut) => item,
    convertToBackEnd: (logic:any, item:CompteStatut) => item,
};

export  const compteStatutDataImpl: CompteStatut = {
    userId: null,
    username: null,
    email: ``,
    personNom: ``,
    personPrenom: ``,
    personId: 0,
    fonctionId: 0,
    sexe: Genre.FEMME,
    roleUser: RoleUser.PARENT,
    accountStatus: AccountEtat.OK,
}

export const compteStatutSchema = yup.object().shape({
    userId: yup.number().nullable(),
    username: yup.string().nullable(),
    email: yup.string(),
    personNom: yup.string(),
    personPrenom: yup.string(),
    personId: yup.number(),
    fonctionId: yup.number(),
    sexe: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.FEMME),
    roleUser: yup.mixed<RoleUser>().oneOf(Object.values(RoleUser)).default(RoleUser.PARENT),
    accountStatus: yup.mixed<AccountEtat>().oneOf(Object.values(AccountEtat)).default(AccountEtat.OK),
});

export type CompteStatut = yup.InferType<typeof compteStatutSchema>;