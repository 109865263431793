import React from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import livreStyles from "./jss/livre-jss";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {useDispatch} from "react-redux";
import emprunterBusinessLogic from "./businesslogic/EmprunterBusinessLogic";
import EmprunterList from "./EmprunterList";
import EmprunterDeleteForm from "./EmprunterDeleteForm";
import useLivreEmpruntVariables from "./businesslogic/EmprunterVariables";
import {livreEmpruntDataImpl, livreEmpruntSchema} from "../../../constants/type/LivreEmprunt";
import LivreList from "./LivreList";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import EmprunterForm from "./EmprunterForm";
import PapperBlock from "../../../components/ui/papperBlock/PapperBlock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import classNames from "classnames";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";

const useBusinessLogic = (props: any): any => {
    const styleClasses = livreStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreEmpruntDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreEmpruntSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useLivreEmpruntVariables({styleClasses, ...props})
    }
};

export const EmprunterContext = React.createContext({});

function Emprunter(props:any){
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const [affichage, setAffichage] = React.useState<string>('les_livres');

    const handleAffichageChange = (val: string) => setAffichage(val);

    React.useEffect(() => {
        moment.locale(logic.activeLang);
        emprunterBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);

    return (
        <div className={logic.styleClasses.root}>
            <Box className={logic.styleClasses.buttonContainer}>
                <Button
                    variant={"text"}
                    className={classNames(affichage === 'les_livres' ? logic.styleClasses.active : '') }
                    onClick={() => handleAffichageChange('les_livres')}
                    sx={{
                        marginRight: 1,
                        textTransform: 'none',
                        paddingLeft: 2,
                        paddingRight: 2,
                        fontWeight: 400,
                        fontSize: 15,
                    }}
                    startIcon={<ListIcon/>}
                >
                    <FormattedMessage id={messages.bibliotheque} />
                </Button>

                <Button
                    variant={"text"}
                    className={classNames(affichage === 'mes_emprunts' ? logic.styleClasses.active : '') }
                    onClick={() => handleAffichageChange('mes_emprunts')}
                    sx={{
                        marginRight: 1,
                        textTransform: 'none',
                        paddingLeft: 2,
                        paddingRight: 2,
                        fontWeight: 400,
                        fontSize: 15,
                    }}
                    startIcon={<AddIcon/>}
                >
                    <FormattedMessage id={messages.mes_livres_empruntes} />
                </Button>
            </Box>
           {/* <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                    <ToggleButtonGroup
                        color="primary"
                        value={affichage}
                        exclusive
                        onChange={handleAffichageChange}
                        aria-label="Etat_Livres"
                    >
                        <ToggleButton value="les_livres"><FormattedMessage id={messages.bibliotheque} /></ToggleButton>
                        <ToggleButton value="mes_emprunts"><FormattedMessage id={messages.mes_livres_empruntes} /></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item md={2} xs={2}></Grid>
                <Grid item md={4} xs={4}></Grid>
            </Grid>*/}

            <PapperBlock whiteBg noMargin displayNoTitle={true}>
                {
                    affichage === 'les_livres' &&
                    <EmprunterContext.Provider value={logic}>
                        <LivreList />
                    </EmprunterContext.Provider>
                }


                {
                    affichage === 'mes_emprunts' &&
                    <EmprunterContext.Provider value={logic}>
                        <EmprunterList />
                    </EmprunterContext.Provider>
                }


                <EmprunterContext.Provider value={logic}>
                    <EmprunterForm />
                </EmprunterContext.Provider>
                <EmprunterContext.Provider value={logic}>
                    <EmprunterDeleteForm />
                </EmprunterContext.Provider>
                {/*<Box>
                    <Grid container rowSpacing={4} sx={{pr:4,pl:4}}  spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <EmprunterContext.Provider value={logic}>
                            <DocumentFolder />
                        </EmprunterContext.Provider>
                        <EmprunterContext.Provider value={logic}>
                            <DocumentFile />
                        </EmprunterContext.Provider>
                    </Grid>
                </Box>





                <EmprunterContext.Provider value={logic}>
                    <FileDeleteForm />
                </EmprunterContext.Provider>*/}
            </PapperBlock>

        </div>
    );
}
export default withSnackbar(Emprunter);