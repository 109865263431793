import * as yup from "yup";
import {SCOLARITEVERSEMENT_CONSTANT} from "./ScolariteVersement";
import {SCOLARITEECHEANCIERENFANT_CONSTANT} from "./ScolariteEcheancierEnfant";
import _ from "lodash";
import {
    ScolariteVersementRef,
    SCOLARITEVERSEMENTREF_CONSTANT,
    scolariteVersementRefDataImpl
} from "./ScolariteVersementReference";
import {ScolariteEtat, SCOLARITEETAT_CONSTANT} from "./ScolariteEtat";
import {Common, commonDataImp} from "./Common";
import {SCOLARITETYPE_CONSTANT} from "./ScolariteType";


export const SCOLARITEDATA_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteData) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteDataDataImpl);
        item = {...itemInit, ...item} ;

        item.montantFactureForm    = new Intl.NumberFormat("fr-FR").format(item.montantFacture ?? 0) ;
        item.montantPayerForm    = new Intl.NumberFormat("fr-FR").format(item.montantPayer ?? 0) ;
        item.montantSoldeForm    = new Intl.NumberFormat("fr-FR").format(item.montantSolde ?? 0) ;
        item.montantFactureALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantFactureALaDate ?? 0);
        item.montantPayerALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantPayerALaDate ?? 0) ;
        item.montantSoldeALaDateForm    = new Intl.NumberFormat("fr-FR").format(item.montantSoldeALaDate ?? 0) ;

        // @ts-ignore
        item.versementReference    = SCOLARITEVERSEMENTREF_CONSTANT.convertToFrontEnd(logic, item.versementReference);
        // @ts-ignore
        item.tousLesVersements        = _.map(item.tousLesVersements, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));
        // @ts-ignore
        item.versementsAvantLaDate        = _.map(item.versementsAvantLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));
        // @ts-ignore
        item.versementsApresLaDate        = _.map(item.versementsApresLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, versement));

        // @ts-ignore
        item.tousLesEcheanciers           = _.map(item.tousLesEcheanciers, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierAvantLaDate        = _.map(item.echeancierAvantLaDate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierApresLadate        = _.map(item.echeancierApresLadate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, echeancier));
        // @ts-ignore
        item.scolariteEtats                = _.map(item.scolariteEtats, scolariteEtat => SCOLARITEETAT_CONSTANT.convertToFrontEnd(logic, scolariteEtat));

        return item;
    },
    convertToBackEnd: (logic:any, item:ScolariteData) => {
        // @ts-ignore
        item.versementReference = SCOLARITEVERSEMENTREF_CONSTANT.convertToBackEnd(logic, item.versementReference);
        // @ts-ignore
        item.tousLesVersements        = _.map(item.tousLesVersements, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));
        // @ts-ignore
        item.versementsAvantLaDate        = _.map(item.versementsAvantLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));
        // @ts-ignore
        item.versementsApresLaDate        = _.map(item.versementsApresLaDate, versement => SCOLARITEVERSEMENT_CONSTANT.convertToBackEnd(logic, versement));

        // @ts-ignore
        item.tousLesEcheanciers        = _.map(item.tousLesEcheanciers, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierAvantLaDate        = _.map(item.echeancierAvantLaDate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));
        // @ts-ignore
        item.echeancierApresLadate        = _.map(item.echeancierApresLadate, echeancier => SCOLARITEECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, echeancier));
        // @ts-ignore
        item.scolariteEtats        = _.map(item.scolariteEtats, scolariteEtat => SCOLARITEETAT_CONSTANT.convertToBackEnd(logic, scolariteEtat));

        return item ;
    },
};

export  const scolariteDataDataImpl: ScolariteData = {
    enfantId: 0,
    groupesectionId: 0,
    scolariteAnneeId: 0,
    scolaritePaiementmodeId: 0,
    versementReference: _.cloneDeep(scolariteVersementRefDataImpl),
    montantFacture: 0,
    montantFactureForm: 0,
    montantPayer: 0,
    montantPayerForm: `0`,
    montantSolde: 0,
    montantSoldeForm: ``,
    montantFactureALaDate: 0,
    montantFactureALaDateForm: `0`,
    montantPayerALaDate: 0,
    montantPayerALaDateForm: `0`,
    montantSoldeALaDate: 0,
    montantSoldeALaDateForm: `0`,
    scolariteTypeIds: [],
    scolariteEtats: [],
    common: _.cloneDeep(commonDataImp),
}

export const scolariteDataSchema = yup.object().shape({
    enfantId: yup.number().nullable().required(),
    groupesectionId: yup.number().nullable().required(),
    scolariteAnneeId: yup.number().nullable().required(),
    codeScolariteAnnee: yup.string(),
    scolaritePaiementmodeId: yup.number(),
    versementReference: yup.mixed<ScolariteVersementRef>().required(),
    montantFacture: yup.number(),
    montantFactureForm: yup.mixed<any>().notRequired(),
    montantPayer: yup.number(),
    montantPayerForm: yup.mixed<any>().notRequired(),
    montantSolde: yup.number(),
    montantSoldeForm: yup.mixed<any>().notRequired(),
    montantFactureALaDate: yup.number(),
    montantFactureALaDateForm: yup.mixed<any>().notRequired(),
    montantPayerALaDate: yup.number(),
    montantPayerALaDateForm: yup.mixed<any>().notRequired(),
    montantSoldeALaDate: yup.number(),
    montantSoldeALaDateForm: yup.mixed<any>().notRequired(),
    scolariteTypeIds: yup.array().of(yup.number()).nonNullable().default([]),
    scolariteEtats: yup.array().of(yup.mixed<ScolariteEtat>()).nonNullable().default([]),
    classeName: yup.string(),
    enfantNom: yup.string(),
    parentNom: yup.string(),
    parentPhone1: yup.string(),
    parentPhone2: yup.string(),
    common: yup.mixed<Common>(),
});
export type ScolariteData = yup.InferType<typeof scolariteDataSchema>;