import React from "react";
import {request, uploadFileRequest} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {Livre, LIVRE_CONSTANT} from "../../../../constants/type/Livre";
import livreStateUpdate from "./LivreStateUpdate";
import {FormattedMessage} from "react-intl";
import {isPositiveValue} from "../../../../helpers/helpers";
import {LivreCategorie, LIVRECATEGORIE_CONSTANT} from "../../../../constants/type/LivreCategorie";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {getErrorMessage} from "../../../../helpers/errorManager";

const livreBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "nom",
                    operator: "START_BY",
                    field_type: "STRING",
                    value: "Am"
                }
            ]
        };

        const search = btoa(JSON.stringify(filterApi));

        const requestbody = ['livrecategories', 'livres'];
        const convertToFrontEnd = (data:any) => {
            const itemlc : LivreCategorie[]   = data.livrecategories.content ?? [];
            logic.livreCategorieRef.current  = _.sortBy(_.map(itemlc,  LIVRECATEGORIE_CONSTANT.convertToFrontEnd), ['nom']);

            const items : Livre[]   = data.livres.content ?? [];
            const itemsfrontend = _.sortBy(_.map(items,  item => LIVRE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            livreStateUpdate.livres(logic, itemsfrontend) ;
        };

        livreStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            livreStateUpdate.tableKeyValue(logic, new Date().getTime());
            livreStateUpdate.tableDataLoading(logic, false);
        }).catch(e => {
            livreStateUpdate.tableDataLoading(logic, false);
        });

    },
    saveLivreEdit: async (logic:any, messages:any, toSend:any, id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = isPositiveValue(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${id}`;
            }

            uploadFileRequest(method, `/extra/bibliotheque${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = LIVRE_CONSTANT.convertToFrontEnd(logic, response.data);

                livreStateUpdate.livreSel(logic, _.cloneDeep(dataSaved));
                livreBusinessLogic.loadLivres(logic).then((val:any) => {});
                livreStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');

                resolve(false);
            });
        });
    },
    loadLivres: (logic:any) => {
        livreStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/bibliotheque`, {}).then((response) => {
                const items : Livre[]   = response.data._embedded?.livreDTOModelList ?? [];
                const itemsfrontend = _.sortBy(_.map(items,  item => LIVRE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
                livreStateUpdate.livres(logic, itemsfrontend) ;
                //logic.setLivres(_.cloneDeep(itemsfrontend));
                livreStateUpdate.tableKeyValue(logic, new Date().getTime());
                livreStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');

                resolve(false);
                livreStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    deleteLivre: async (logic:any, messages:any, toSends:Livre[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:Livre) => {
                if(isPositiveValue(l.id)){
                    // @ts-ignore
                    ids.push(l.id);
                }
            });


            request('DELETE', `/extra/bibliotheque/bouquet`, ids).then((response) => {
                // @ts-ignore
                livreBusinessLogic.loadLivres(logic).then((val:any) => {});
                livreStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    }
}
export default livreBusinessLogic ;