import React from 'react'
import {Box, SwipeableDrawer} from "@mui/material";
import {makeStyles} from "@mui/styles";
import SidebarContent from "./SidebarContent";

const useStyles = makeStyles((theme:any) => {
    return {
        swipeDrawerPaper: {
            //width: categoryWidth + listWidth,
        },
    }
})

function Sidebar(props:any) {
    const classes = useStyles();
    const {theme, drawerPaper, setDrawerPaper, mobile, setMobile,  setPageLoaded } = props

    const toggleDrawerOpen = () => {
        setMobile(!mobile)
    }

    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'inline-block' }}}>
                <SidebarContent
                    theme={theme}
                    drawerPaper={drawerPaper}
                    setDrawerPaper={setDrawerPaper}
                    setPageLoaded={setPageLoaded}
                />
            </Box>

            <Box sx={{ display: { xs: 'inline-block', sm: 'none' } }}>
                <SwipeableDrawer onClose={toggleDrawerOpen} onOpen={toggleDrawerOpen} open={mobile} anchor='left'>
                    <div className={classes.swipeDrawerPaper}>
                        <SidebarContent
                            theme={theme}
                            drawerPaper={drawerPaper}
                            setDrawerPaper={setDrawerPaper}
                            setPageLoaded={setPageLoaded}
                        />
                    </div>
                </SwipeableDrawer>
            </Box>
        </>
    )
}

export default Sidebar
