import React from "react";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../constants/type/ClasseRoom";
import {request} from "../../../helpers/axios_helpers";
import {ALERTMESSAGEPARENT_CONSTANT} from "../../../constants/type/AlertMessageParent";
import {AlertMessage, ALERTMESSAGE_CONSTANT} from "../../../constants/type/AlertMessage";
import alertMessageStateUpdate from "./AlertMessageStateUpdate";
import {getErrorMessage} from "../../../helpers/errorManager";
import emprunterStateUpdate from "../../bibliotheque/emprunter/businesslogic/EmprunterStateUpdate";
import {mixLoad} from "../../../helpers/loader_cache_helpers";

const alertMessageBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['classes'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });
        };

        alertMessageStateUpdate.tableDataLoading(logic, true) ;
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            alertMessageStateUpdate.tableDataLoading(logic, false) ;
        }).catch(e => { emprunterStateUpdate.tableDataLoading(logic, false);});

    },
    saveEdit: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('POST', `/extra/alertmessage`, toSend).then((response) => {
                const dataDB : AlertMessage   =  ALERTMESSAGE_CONSTANT.convertToFrontEnd(logic, response.data);
                // @ts-ignore
                alertMessageStateUpdate.dataSel(logic, dataDB) ;

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        });
    },
    loadParents: (logic:any, classeIds:string) => {
        return new Promise<boolean>(resolve =>
            request("GET",  `/corebase/enfants/parentuseraccount/classes/${classeIds}`, classeIds).then((response) => {
                const data = _.cloneDeep(logic.dataSel);
                data.alertMessageParents = _.map(response.data, item => ALERTMESSAGEPARENT_CONSTANT.convertToFrontEnd(logic, item)) ;
                // @ts-ignore
                alertMessageStateUpdate.dataSel(logic, data) ;
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    }
}
export default alertMessageBusinessLogic ;