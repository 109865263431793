import React from 'react'
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Avatar, Box, Grid, MenuItem, TextField} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import DialogForm from "../../components/ui/form/DialogForm";
import classNames from "classnames";
import moment from "moment";
import {USER_GENRE_EN, USER_GENRE_FR} from "../../constants/userGenre";

function PersonalDialogForm(props:any) {
    const {
        theme,
        locale,
        classes,
        statePersonalForm,
        open,
        setOpen,
        fileDataURL,
        setFileDataURL,
        jobData,
        employeeJob,
        classroomData,
        classroom,
        handleEmployeeJobChange,
        birthDate,
        sexe,
        handleSexeChange,
        hireDate,
        handleBirthDateChange,
        handleHireDateChange,
        onSubmit,
        register,
        handleClassroomChange,
        classroomStatus,
        handleFileChange,
        errors,
    } = props;
    const activeLang = locale.split('-')[0];
    const dialogTitle = statePersonalForm ? <FormattedMessage id={messages.dialog_title_personal} /> : <FormattedMessage id={messages.dialog_title_edit_personal}/>;

    return (
        <DialogForm
            title={dialogTitle}
            open={open}
            setOpen={setOpen}
            theme={theme}
            width={true}
            setFileDataURL={setFileDataURL}
            onSubmit={onSubmit}
        >
            <Box sx={{ width: '100%' }}>
                <div className={classes.dialogAvatar}>
                    <Avatar src={fileDataURL ? fileDataURL : ''} sx={{width:100, height:100}} />
                    <div className={classes.iconButtonLabel}>
                        <label htmlFor="file-upload">
                            <div className={classes.iconButton} style={{textAlign:"center"}}>
                                <EditIcon />
                            </div>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                hidden={true}
                                onChange={(a:any) => handleFileChange(a)}
                            />
                        </label>
                    </div>
                </div>

                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.firstname_personal}/>}
                                size="small"
                                color={"secondary"}
                                required
                                sx={{ width: { xs: '100% !important'} }}
                                inputProps={{
                                    ...register("prenom", {
                                        required: "Required field"
                                    })
                                }}
                            />
                            <div className={classes.errorHelper}>
                                {errors.prenom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.lastname_personal}/>}
                                size="small"
                                color={"secondary"}
                                required
                                sx={{ width: { xs: '100% !important'} }}
                                inputProps={{
                                    ...register("nom", {
                                        required: "Required field"
                                    })
                                }}
                            />
                            <div className={classes.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                                <DatePicker
                                    label={<FormattedMessage id={messages.birth_date_personal} />}
                                    value={birthDate}
                                    maxDate={moment(new Date())}
                                    onChange={handleBirthDateChange}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    sx={{ width:'100% !important' }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.genre_personal} />}
                                value={sexe || ''}
                                sx={{ width: { xs: '100% !important'}}}
                                color={"secondary"}
                                required
                                onChange={(a:any) => handleSexeChange(a)}
                                inputProps={{
                                    ...register('sexe', {
                                        required: 'Required field',
                                    })
                                }}
                            >
                                {/*{USER_GENRE.map((option:any, index) => (
                                    <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                        {option.name}
                                    </MenuItem>
                                ))}*/}

                                {activeLang === 'en' ? (
                                    USER_GENRE_EN.map((option:any, index) => (
                                        <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    USER_GENRE_FR.map((option:any, index) => (
                                        <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                )}

                            </TextField>
                            <div className={classes.errorHelper}>
                                {errors.sexe && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.phone_number_personal}/>}
                                size="small"
                                color={"secondary"}
                                required
                                sx={{ width: { xs: '100% !important'} }}
                                inputProps={{
                                    ...register("phone1", {
                                        required: "Required field"
                                    })
                                }}
                            />
                            <div className={classes.errorHelper}>
                                {errors.phone1 && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.email_personal}/>}
                                size="small"
                                color={"secondary"}
                                required
                                sx={{ width: { xs: '100% !important'} }}
                                inputProps={{
                                    ...register("email", {
                                        required: "Required field"
                                    })
                                }}
                            />
                            <div className={classes.errorHelper}>
                                {errors?.email && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.job_name_personal} />}
                                defaultValue={''}
                                value={employeeJob?.id || ''}
                                sx={{ width: { xs: '100% !important'}}}
                                color={"secondary"}
                                required
                                onChange={(a:any) => handleEmployeeJobChange(a)}
                                inputProps={{
                                    ...register("jobNameId", {
                                        required: "Required field"
                                    })
                                }}
                            >
                                {jobData.map((option:any, index:any) => (
                                    <MenuItem key={index} value={option.id} sx={{fontWeight:400}}>
                                        {option.jobName}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div className={classes.errorHelper}>
                                {errors.jobNameId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.post_personal} />}
                                size="small"
                                color={"secondary"}
                                sx={{ width: { xs: '100% !important'} }}
                                inputProps={{
                                    ...register("poste")
                                }}
                            />
                        </div>
                    </Grid>

                    {
                        classroomStatus && (
                            <Grid item md={12} xs={12}>
                                <Box
                                    className={classNames(classes.inputField)}
                                    //sx={{display:  classroomStatus ? 'block' : 'none' }}
                                >
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.dialog_input_classroom} />}
                                        defaultValue={''}
                                        value={classroom.id || ''}
                                        sx={{ width: { xs: '100% !important'}}}
                                        color={"secondary"}
                                        required={classroomStatus}
                                        onChange={(a:any) => handleClassroomChange(a)}
                                        inputProps={{
                                            ...register("classroomEdit", {
                                                required: "Required field"
                                            })
                                        }}
                                    >
                                        {classroomData.map((option:any, index:any) => (
                                            <MenuItem key={index} value={option.id} sx={{fontWeight:400}}>
                                                {option.className}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <div className={classes.errorHelper}>
                                        {errors.classroomEdit && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                    </div>
                                </Box>
                            </Grid>
                        )
                    }

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                                <DatePicker
                                    label={<FormattedMessage id={messages.hire_date_personal} />}
                                    value={hireDate}
                                    maxDate={moment(new Date())}
                                    onChange={handleHireDateChange}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    sx={{ width:'100% !important' }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.more_info_personal}/>}
                                size="small"
                                color={"secondary"}
                                sx={{ width:'100% !important' }}
                                inputProps={{
                                    ...register("note")
                                }}
                            />
                        </div>
                    </Grid>

                </Grid>
            </Box>

        </DialogForm>
    )
}

export default PersonalDialogForm
