import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {ScolariteEcheancier} from "../../../../constants/type/ScolariteEcheancier";
import {processingChildren} from "../../../../helpers/loader_helpers";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import fraisOneShotEnfantStateUpdate from "./FraisOneShotEnfantStateUpdate";
import {
    ScolariteSansEcheanceEnfant,
    SCOLARITESANSECHEANCEENFANT_CONSTANT
} from "../../../../constants/type/ScolariteSansEcheanceEnfant";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {Child} from "../../../../constants/type/Child";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {isPositiveValue} from "../../../../helpers/helpers";
import moment from "moment";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";

const fraisOneShotEnfantBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolariteannees', 'scolaritegroups', 'groupenfants', 'enfants'];
        const convertToFrontEnd = (data:any) => {
            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            fraisOneShotEnfantStateUpdate.idScolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false})?.id ?? 0);

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteTypeRef.current.forEach((data:ScolariteType) => {
                logic.scolariteTypeMapRef.current.set(data.id, data);
            });

            let itemssc : ScolariteGroup[]   =_.sortBy(data.scolaritegroups.content ?? [], ['nom']) ;
            const itemsscFilter = _.filter(itemssc, { 'echeance': false});
            logic.scolariteGroupsRef.current = _.map(itemsscFilter,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item));
            logic.scolariteGroupsRef.current.forEach((data:ScolariteGroup) => {
                logic.scolariteGroupMapRef.current.set(data.id, data);
            });

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itemge : GroupEnfant[]   = data.groupenfants.content ?? [];
            logic.groupEnfantsRef.current  = _.map(itemge,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item));
        };

        fraisOneShotEnfantStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            fraisOneShotEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
            fraisOneShotEnfantStateUpdate.tableDataLoading(logic, false);
        }).catch(e => {
            fraisOneShotEnfantStateUpdate.tableDataLoading(logic, false);
        });
      
    },
    saveEdit: async (logic:any, messages:any, toSend:any[], id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = isPositiveValue(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                // @ts-ignore
                params = `/${toSend.id}`;
            }
            request(method, `/extra/fraisoneshotenfant${params}`, toSend).then((response) => {
                fraisOneShotEnfantStateUpdate.numeriChange(logic, moment().valueOf());
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadFraisSansEcheances: (logic:any, enfantId:number, scolariteGroupIds:number[]) => {
        fraisOneShotEnfantStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/fraisoneshotenfant/enfant/${enfantId}/scolaritegrouplist/${_.join(scolariteGroupIds, ',')}`, {}).then((response) => {
                const items : ScolariteSansEcheanceEnfant[]   = response.data?._embedded?.scolariteSansecheanceEnfantDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITESANSECHEANCEENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']);
                fraisOneShotEnfantStateUpdate.dataList(logic, itemsfrontend) ;
                fraisOneShotEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                fraisOneShotEnfantStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
                fraisOneShotEnfantStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/fraisoneshotenfant/bouquet`, ids).then((response) => {
                // @ts-ignore
                const datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITESANSECHEANCEENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);

                fraisOneShotEnfantStateUpdate.dataList(logic, itemsfrontend) ;
                fraisOneShotEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                fraisOneShotEnfantStateUpdate.tableDataLoading(logic, false);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteEcheancier[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteEcheancier) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });
            fraisOneShotEnfantBusinessLogic.delete(logic, messages, ids);
        });
    }
}
export default fraisOneShotEnfantBusinessLogic ;