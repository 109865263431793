import {getRequest} from "../helpers/axios_helpers";

function PersonalService() {
    return {
        getAllFonctions: async () => {
            const jobListRequest:any = await getRequest(`/corebase/fonctions`);
            let jobRequestList:any = jobListRequest._embedded !== undefined ? jobListRequest._embedded?.fonctionDTOModelList : [];

            jobRequestList = jobRequestList.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });

            return jobRequestList.map((job: any) => {
                let jobData: any = {};
                jobData.id = job.id;
                jobData.jobName = job.nom;
                jobData.role = job.role;
                return jobData;
            });
        }
    }
}

export default PersonalService;