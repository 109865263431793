import React, {useContext} from "react";
import _ from "lodash";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import {SecuriteEmployeeContext} from "./SecuriteEmployee";
import messages from "../../messages/general/messages";
import {CompteStatut, compteStatutDataImpl} from "../../../constants/type/CompteStatut";
import {AccountEtat} from "../../../constants/type/AccountEtat";
import securiteEmployeeBusinessLogic from "./businesslogic/SecuriteEmployeeBusinessLogic";
import {Fonction} from "../../../constants/type/Fonction";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DialogCompteEmployeeEditForm = (props:any) => {
    const logic: any = useContext(SecuriteEmployeeContext);
    const styleClasses = logic.styleClasses ;

    const genereCompte = () => {

        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }


        const compteList: CompteStatut[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isEmpty(item.fonctions)){
                return ;
            }
            const jobsList: Fonction[] = Array.from(logic.jobsRef.current.values()) ;
            item.fonctions.forEach((fonction:any) => {
                const job: Fonction | undefined = _.find(jobsList, {id:fonction.fonctionId});
                if(_.isEmpty(job)){
                    return ;
                }
                const compte = _.cloneDeep(compteStatutDataImpl);
                compte.userId = null ;
                compte.username = null ;
                compte.email = item.person.email ;
                compte.personNom = item.person.nom ;
                compte.personPrenom = item.person.prenom ;
                compte.personId = item.person.id ;
                compte.fonctionId = fonction.fonctionId ;
                compte.sexe = item.person.sexe ;
                compte.roleUser = job.role ;
                compte.accountStatus = AccountEtat.OK ;
                compteList.push(compte);
            })
        });
       securiteEmployeeBusinessLogic.generateAccounts(logic, messages, compteList);
    }

    const activerCompte = () => {
        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }
        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userIdForm) === false){
                return ;
            }
            compteList.push(item.userIdForm);
        })
        securiteEmployeeBusinessLogic.activerAccounts(logic, messages, compteList);
    }

    const suspendreCompte = () => {
        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }
        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userIdForm) === false){
                return ;
            }
            compteList.push(item.userIdForm);
        })
        securiteEmployeeBusinessLogic.suspendreAccounts(logic, messages, compteList);
    }

    const supprimerCompte = () => {
        if(_.isEmpty(logic.dataListSelected)){
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }
        const compteList: number[] = [];
        logic.dataListSelected.forEach((item:any) =>{
            if(_.isNumber(item.userIdForm) === false){
                return ;
            }
            compteList.push(item.userIdForm);
        })
        securiteEmployeeBusinessLogic.supprimerAccounts(logic, messages, compteList);
    }
    const onSubmit = async () => {
        switch (logic.confirmMessage){
            case 'generer_compte_question':
                genereCompte();
                break;

            case 'activer_compte_question':
                activerCompte();
                break;

            case 'suspendre_compte_question':
                suspendreCompte();
                break;

            case 'supprimer_compte_question':
                supprimerCompte();
                break;
        }
    };

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.gestion_compte} />}
            open={logic.openConfirmDialogForm}
            setOpen={logic.setConfirmDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={() => onSubmit()}>
                <Box  sx={{ width: {xs:'100% !important'} }}>
                    <div className={styleClasses?.inputField} style={{textAlign:'center', color:'black'}}>
                        {<FormattedMessage id={messages[logic.confirmMessage]} />}
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default DialogCompteEmployeeEditForm ;