import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";


export const CLASSEROOM_CONSTANT = {
    convertToFrontEnd: (item:ClasseRoom) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(classeRoomDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:ClasseRoom) => item,
};

export  const classeRoomDataImpl: ClasseRoom = {
    id: null,
    promotionId: 0,
    nom: '',
    totalEleves: 0,
    couleur: '',
    common: _.cloneDeep(commonDataImp)
}

export const classeRoomSchema = yup.object().shape({
    id: yup.number().nullable(),
    promotionId: yup.number().nullable(),
    nom: yup.string().nonNullable(),
    totalEleves: yup.number().min(0),
    couleur: yup.string().nullable(),
    common: yup.mixed<Common>()
});

export type ClasseRoom = yup.InferType<typeof classeRoomSchema>;
