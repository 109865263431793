import React, {useEffect, useState} from "react";
import {ListItem, ListItemText, ToggleButton} from "@mui/material";
import messages from "../../../page/messages/general/messages";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";
import {SCHOOL_DAY_EN, SCHOOL_DAY_FR} from "../../../constants";

function DayOpen(props:any) {
    const { day, dayStatus, workDays, setWorkDays } = props
    const [selected, setSelected] = useState(dayStatus)

    const handleDayStatusChange = () => {
        setSelected(!selected);
        const result = workDays.map((workDay:any) => {

            if(workDay.jour === day) {
                workDay.ouvrable = !selected;
                return workDay;
            }
            else {
                return workDay;
            }
        });

        setWorkDays([...result]);
    }

    useEffect(() => {
        //const dayToDisplay = activeLanguage[0] === 'en' ? SCHOOL_DAY_EN.find(schoolDay => schoolDay.id === item.id) : SCHOOL_DAY_FR.find(schoolDay => schoolDay.id === item.id)
    }, [])

    return (
        <ListItem>
            <ListItemText primary={day} />
            <ToggleButton
                value='check'
                selected={selected}
                onChange={() => handleDayStatusChange()}
                color="secondary"
                sx={{ textTransform: 'none', fontWeight:500 }}
                size={"medium"}
            >
                {
                    selected
                        ? <><DoneIcon /> <FormattedMessage id={messages.day_open} /></>
                        : <><CloseIcon /> <FormattedMessage id={messages.day_off} /></>
                }
            </ToggleButton>
        </ListItem>
    )
}

export default DayOpen
