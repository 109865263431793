import {ScolariteSansEcheanceEnfant} from "../../../../constants/type/ScolariteSansEcheanceEnfant";
import {Child} from "../../../../constants/type/Child";
import {GroupEnfant} from "../../../../constants/type/GroupEnfant";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {Groupesection} from "../../../../constants/type/Groupesection";
import _ from "lodash";


const matchingFraisOneShotEnfantStateUpdate:any = {
    childrenGroupList: (logic:any, val:GroupEnfant[]) => logic.setChildrenGroupList(val),
    dataList: (logic:any, val:ScolariteSansEcheanceEnfant[]) => logic.setDataList(val),
    scolariteGroupList: (logic:any, val:ScolariteGroup[]) => logic.setScolariteGroupList(val),
    scolariteGroupIdListSel: (logic:any, val:number[]) => logic.setScolariteGroupIdListSel(val),
    checkedList: (logic:any, val:number[]) => logic.setCheckedList(val),
    leftList: (logic:any, val:GroupEnfant[]) => logic.setLeftList(_.sortBy(val, ['childForm.childNomPrenomForm'])),
    rightList: (logic:any, val:GroupEnfant[]) => logic.setRightList(_.sortBy(val, ['childForm.childNomPrenomForm'])),
    scolariteAnneeSel: (logic:any, val:ScolariteAnnee) => logic.setScolariteAnneeSel(val),
    groupEnfantSel: (logic:any, val:GroupEnfant) => logic.setGroupEnfantSel(val),
    childSelected: (logic:any, val:Child) => logic.setChildSelected(val),
    groupSel: (logic:any, val:Groupesection) => logic.setGroupSel(val),
    montantFrais: (logic:any, val:number) => logic.setMontantFrais(val),
    scolariteGroupSel: (logic:any, val:ScolariteGroup) => logic.setScolariteGroupSel(val),
    dataSel: (logic:any, val:ScolariteSansEcheanceEnfant) => logic.setDataSel(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    dataLoading: (logic:any, val:boolean) => logic.seDataLoading(val),
    numeriChange: (logic:any, val:any) => logic.setNumeriChange(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
}

export default matchingFraisOneShotEnfantStateUpdate ;