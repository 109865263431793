import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {DocumentScolaireFichier, DOCUMENTSCOLAIREFICHIER_CONSTANT} from "./DocumentScolaireFichier";
import {
    DocumentScolaireReceiver,
    DOCUMENTSCOLAIRERECEIVER_CONSTANT,
    documentScolaireReceiverDataImpl
} from "./DocumentScolaireReceiver";
import {RoleUser} from "./RoleUser";


export const DOCUMENTSCOLAIRE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:DocumentScolaire) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(documentScolaireDataImpl);
        item = {...itemInit, ...item} ;

        item.isOwnerForm = item.employeeId === logic.userRef.current.userDetails.personDetails.person.id;
        item.isUserCanEditForm = item.isOwnerForm || logic?.userRef?.current?.role === RoleUser.ADMIN;

        // @ts-ignore
        item.documentScolaireFichiers   = _.map(item.documentScolaireFichiers, docFile => DOCUMENTSCOLAIREFICHIER_CONSTANT.convertToFrontEnd(logic, item, docFile));
        // @ts-ignore
        item.documentScolaireReceivers  = _.map(item.documentScolaireReceivers, docReceiver => DOCUMENTSCOLAIRERECEIVER_CONSTANT.convertToFrontEnd(logic, item, docReceiver));

        item.chipConcernsForm = [];
        item.documentScolaireReceivers.forEach(doc => {
            // @ts-ignore
            const element = doc.receiverGroup+'_'+doc?.receiverId ;
            if(logic.destinatairesMapRef.current.has(element)){
                item.chipConcernsForm.push(element);
            }
        });
        // @ts-ignore
        return item ;
    },
    convertToBackEnd: (logic:any, item:DocumentScolaire) => {
        item.documentScolaireReceivers = [];
        item.chipConcernsForm.forEach(dest =>{
            const infos = dest?.split('_') ?? [];
            if(infos.length >= 2){
                const docRecceiver = _.cloneDeep(documentScolaireReceiverDataImpl);
                docRecceiver.documentScolaireId = item.id ?? 0;
                docRecceiver.receiverGroup      = infos[0];
                docRecceiver.receiverId         = parseInt(infos[1]);
                item.documentScolaireReceivers.push(docRecceiver);
            }
        });
        return item;
    },
};

export  const documentScolaireDataImpl: DocumentScolaire = {
    id: null,
    nom: '',
    employeeId: 0,
    usersIdDeleted: '',
    documentScolaireReceivers:[],
    documentScolaireFichiers: [],
    isOwnerForm: true,
    isUserCanEditForm: true,
    chipConcernsForm: [],
    common: _.cloneDeep(commonDataImp)
}

export const documentScolaireSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable().required(),
    employeeId: yup.number().default(0),
    usersIdDeleted: yup.string().default(''),
    documentScolaireReceivers:  yup.array().of(yup.mixed<DocumentScolaireReceiver>()).nonNullable().default([]),
    documentScolaireFichiers:  yup.array().of(yup.mixed<DocumentScolaireFichier>()).nonNullable().default([]),
    isOwnerForm: yup.boolean().default(true),
    isUserCanEditForm: yup.boolean().default(true),
    chipConcernsForm: yup.array().of(yup.mixed<string>()).nonNullable().default([]),
    common: yup.mixed<Common>().default(_.cloneDeep(commonDataImp))
});

export type DocumentScolaire = yup.InferType<typeof documentScolaireSchema>;
