import {EventNotification} from "../../../constants/type/EventNotification";
import {Pagination} from "../../../constants/type/LivreFiltre";
import _ from "lodash";


const mesNotificationsStateUpdate:any = {
    pagination: (logic:any, pagination:Pagination) => logic.setPagination(pagination),
    dataList: (logic:any, val:EventNotification[]) => logic.setDataList(val),
    initPagination: (logic:any, val:EventNotification[], pagination:Pagination) => {
        const page= _.cloneDeep(pagination);
        page.total        =  _.size(val);
        page.pageCount    = Math.ceil(page.total / page.pageSize) ;
        mesNotificationsStateUpdate.pagination(logic, page);
        return pagination ;
    },
    updateNotifs: (logic:any, events:EventNotification[]) => mesNotificationsStateUpdate.dataList(logic, events),
    dataListSelected: (logic:any, val:EventNotification[]) => logic.setDataListSelected(val),
    dataSel: (logic:any, val:EventNotification) => logic.setDataSel(val),
    editDisableRef: (logic:any, val:boolean) => logic.editDisableRef.current = val,
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    processingSubmitStateNormBtn: (logic:any, val:boolean) => logic.setProcessingSubmitStateNormBtn(val),
    showTableContent: (logic:any, val:boolean) => logic.setShowTableContent(val),
    contentLoaded: (logic:any, val:boolean) => logic.setContentLoaded(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),

}

export default mesNotificationsStateUpdate ;