import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import groupStateUpdate from "./GroupStateUpdate";
import {FormattedMessage} from "react-intl";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {getErrorMessage} from "../../../../helpers/errorManager";

const groupBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        groupBusinessLogic.loadGroups(logic).then((val:any) => {});
    },
    saveEdit: async (logic:any, messages:any, toSend:any, id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            request(method, `/extra/groups${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = GROUPESECTION_CONSTANT.convertToFrontEnd(logic, response.data);

                groupStateUpdate.dataSel(logic, _.cloneDeep(dataSaved));
                groupBusinessLogic.loadGroups(logic).then((val:any) => {});
                groupStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadGroups: (logic:any) => {
        groupStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/groups`, {}).then((response) => {
                const items : Groupesection[]   = response.data._embedded?.groupesectionDTOModelList ?? [];

                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
                groupStateUpdate.dataList(logic, itemsfrontend) ;
                //logic.setDataList(_.cloneDeep(itemsfrontend));
                groupStateUpdate.tableKeyValue(logic, new Date().getTime());
                groupStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                groupStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    deleteDatas: async (logic:any, messages:any, toSends:Groupesection[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:Groupesection) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });


            request('DELETE', `/extra/groups/bouquet`, ids).then((response) => {
                // @ts-ignore
                groupBusinessLogic.loadGroups(logic).then((val:any) => {});
                groupStateUpdate.tableKeyValue(logic, new Date().getTime());
                groupStateUpdate.dataListSelected(logic, []);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    }
}
export default groupBusinessLogic ;