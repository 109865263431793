import React, {useEffect, useRef, useState} from 'react';

import {Chip, Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import classNames from "classnames";
import dataMenu from '../../data/menu';
import {NavLink, useNavigate} from "react-router-dom";
import messages from "../../page/messages/general/menuMessages";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {setMenuOpen, setPageTitle} from "../../features/page/pageSlice";
import menuMessages from "../../page/messages/general/menuMessages";

const LinkBtn = React.forwardRef<any, any>((props, ref) => (
    <NavLink
        ref={ref}
        to={props.to}
        className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : ''}`}
    >
        {props.children}
    </NavLink>
));



function MainMenu(props:any) {
    const {classes, setPageLoaded} = props;
    //const [open, setOpen] = useState('dashboard');
    //const {menuOpen} = useSelector((state:any) => state.page);
    //const [open, setOpen] = useState(menuOpen);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {pageTitle, menuOpen} = useSelector((state:any) => state.page);
    const intl = useIntl() ;
    const userDetailsRef = React.useRef<any>(useSelector((state:any) => state.user.user.userDetails));

    /*const handleClick = (item:any) => {
        setPageLoaded(false);
        dispatch(setPageTitle(messages[item.cle] !== undefined ? <FormattedMessage id={messages[item.cle]} /> : item.nom));
        setTimeout(() => {
            setPageLoaded(true)
        }, 15);
    };*/

    const openSubMenu = (item:any) => {
        toggleDrawerOpen(item);
        if (typeof item.children === 'undefined' || _.isEmpty(item.children) ) {
            navigation(item);
        }
    }
    const toggleDrawerOpen = (item:any) => {
        dispatch(setMenuOpen(item.cle));
    };

    const navigation = (item:any) => {
        setPageLoaded(false);
        navigate(item.lien);
        dispatch(setMenuOpen(item.cle));
        dispatch(setPageTitle(messages[item.cle] !== undefined ? intl.formatMessage({id:messages[item.cle]}) : item.nom));
        setTimeout(() => {
            setPageLoaded(true);
        }, 15);
    }


    const getMenus = (menuArray:any) => menuArray.map((item:any, index:any) => {
        if(_.isNull(item.cle)){
            return <></>;
        }
        if (!_.isEmpty(item.children) || item.parentKey === 'racine') {
            return (
                <>
                    <ListItemButton
                        key={`libtnprim_${item.cle}`}
                        className={classNames(
                            classes.head,
                            item.icon ? classes.iconed : '',
                            menuOpen.indexOf(item.cle) > -1 ? classes.opened : '',
                        )}
                        onClick={() => openSubMenu(item)}
                    >
                        {item.icon && (
                            <ListItemIcon
                                key={`lico_${item.cle}`}
                                className={classes.icon}>
                                <Icon>{item.icon}</Icon>
                            </ListItemIcon>
                        )}
                        <ListItemText
                            key={`litxt_${item.cle}`}
                            classes={{ primary: classes.primary }}
                            primary={messages[item.cle] !== undefined ? <FormattedMessage id={messages[item.cle]} /> : item.nom}
                        />
                        {
                            !_.isEmpty(item.children) &&
                            <span style={{display: 'block', paddingLeft:70, overflow: 'hidden'}}>
                                { menuOpen.indexOf(item.cle) > -1 ? <ExpandLess /> : <ExpandMore /> }
                            </span>
                        }
                    </ListItemButton>
                    {
                        !_.isEmpty(item.children) &&
                        <Collapse
                            key={`collapse_${item.cle}`}
                            component="div"
                            className={classNames(
                                classes.nolist,
                                (item.keyParent ? classes.child : ''),
                            )}
                            in={menuOpen.indexOf(item.cle) > -1}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List
                                key={`list_${item.cle}`}
                                className={classes.dense}
                                component="nav" dense>
                                { getMenus(item.children) }
                            </List>
                        </Collapse>
                    }
                </>
            );
        }
        if (item.title) {
            return (
                <ListSubheader
                    disableSticky
                    key={`lisuhead_${item.cle}`}
                    component="div"
                    className={classes.title}
                >
                    {/* {item.name}*/}
                </ListSubheader>
            );
        }
        return (
            <ListItemButton
                key={`listitembtn_${item.cle}`}
                exact
                className={classes.nested}
                activeClassName={classes.active}
                component={LinkBtn}
                to={item.lien}
            >
                {item.icon && (
                    <ListItemIcon
                        key={`lico_${item.cle}`}
                        className={classes.icon}>
                        <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                )}
                <ListItemText
                    key={`litxt_${item.cle}`}
                    classes={{ primary: classes.primary }}
                    primary={messages[item.cle] !== undefined ? <FormattedMessage id={messages[item.cle]} /> : item.nom}
                    onClick={() => navigation(item)}
                />
            </ListItemButton>
        );
    });

    return (
        <div key="mondiv">
            {getMenus(userDetailsRef.current.menus)}
        </div>
    );
}



export default MainMenu;
