import React, {useContext} from 'react';
import SendIcon from "@mui/icons-material/Send";
import Paper from "@mui/material/Paper";
import {MessageCenterContext} from "./MessageCenter";
import _ from "lodash";
import spaceRoomBusinessLogic from './businessLogic/SpaceRoomBusinessLogic';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from "@mui/lab/LoadingButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {DiscussionEtat} from "../../../constants/type/DiscussionEtat";
import InputField from "../../../components/ui/form/InputField";

const  ChatFooter = (props:any) => {
    const logicRef: any = useContext(MessageCenterContext);
    // @ts-ignore
    const handleWrite = (event: any) => spaceRoomBusinessLogic.updateTextMessage(logicRef, event.target.value);
    const handleKeyUp = (event: any) => {
        if (event.key !== 'Enter'){
            return;
        }
        handleSendTextMessage();
    };
    const handleSendTextMessage = () => {
       /* if(logicRef.newMessage){
            spaceRoomBusinessLogic.sendMessageForNewSpaces(logicRef, logicRef.textMessage);
            return ;
        }*/
        spaceRoomBusinessLogic.sendMessage(logicRef, logicRef.textMessage);
    }
    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        spaceRoomBusinessLogic.envoieFichier(logicRef, file);
    }
   /* const handleFileUpload = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }*/
    //const type_message = logicRef.activeLanguageRef.current[0] === 'en' ? 'Type a message' : 'Ecrire un message'

    return (
        <Paper className={logicRef.chatStyles.writeMessage} sx={{ borderRadius: 50 }}>
            {/*<MessageField
                onChange={handleWrite}
                passedRef={logicRef.messageFieldRef}
                placeholder={type_message}
                fieldType="input"
                value={logicRef.message}
                onKeyPress={(event:any) => spaceRoomBusinessLogic.sendMessageByEnter(logicRef, event)}
                messageFieldStatus={logicRef.messageFieldStatus}
            />
            <TextField
                id="standard-multiline-static"
                variant="outlined"
                multiline
                autoFocus
                maxRows={4}
                onChange={handleWrite}
                value={logicRef.textMessage}
                onKeyUp={handleKeyUp}
                disabled={logicRef.messageFieldStatus}
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <LoadingButton
                                loading={logicRef.uploadingMsg}
                                component="label"
                                loadingPosition="start"
                                startIcon={<AttachFileIcon />}
                                aria-label={logicRef.textBtnFileAttachedRef.current}
                                size="large"
                                color={"primary"}
                            >
                                <input
                                    id="file-upload"
                                    type="file"
                                    hidden={true}
                                    onChange={handleFileChange}
                                />
                            </LoadingButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <LoadingButton
                                loading={logicRef.sendingMsg}
                                loadingPosition="start"
                                disabled={_.isEmpty(logicRef.textMessage)}
                                startIcon={<SendIcon />}
                                onClick={handleSendTextMessage}
                                size="large"
                                color={"primary"}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            */}
            {
                logicRef.space.filEtat === DiscussionEtat.OPEN &&
                <InputField
                    id="standard-multiline-static"
                    variant="outlined"
                    autoFocus
                    onChange={handleWrite}
                    value={logicRef.textMessage}
                    onKeyUp={handleKeyUp}
                    InputProps={{
                        rows: 3,
                        multiline: true,
                        inputComponent: 'textarea',
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <LoadingButton
                                    loading={logicRef.uploadingMsg}
                                    component="label"
                                    loadingPosition="start"
                                    startIcon={<AttachFileIcon />}
                                    aria-label={logicRef.textBtnFileAttachedRef.current}
                                    size="large"
                                    color={"primary"}
                                >
                                    <input
                                        id="file-upload"
                                        type="file"
                                        hidden={true}
                                        onChange={handleFileChange}
                                    />
                                </LoadingButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <LoadingButton
                                    loading={logicRef.sendingMsg}
                                    loadingPosition="start"
                                    disabled={_.isEmpty(logicRef.textMessage)}
                                    startIcon={<SendIcon />}
                                    onClick={handleSendTextMessage}
                                    size="large"
                                    color={"primary"}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            }
        </Paper>
    )
}

export default ChatFooter
