import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {Box} from "@mui/material";
const COLORS = ['#FF8042', '#00C49F', '#FFBB28', '#0088FE'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }:{cx:any, cy:any, midAngle:any, innerRadius:any, outerRadius:any, percent:any, index:any, name:any}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}% ${name}`}
        </text>
    );
};

export default function PieCustomLabel (props:any) {
    const {data} = props;
    return (
        <ResponsiveContainer width="100%" height="100%">
            <div>
                <PieChart
                    width={450}
                    height={390}
                >
                    <Pie
                        data={data}
                        cx="50%"
                        cy="45%"
                        labelLine={false}
                        aria-label={"name"}
                        label={renderCustomizedLabel}
                        outerRadius={170}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry:any, index:any) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </div>
        </ResponsiveContainer>

    );
}
