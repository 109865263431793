import React from 'react';
import {Grid, IconButton, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";

function AuthorizationPersonItem(props:any) {
    const { formValues, handleChange, removeFormFields, classes } = props;

    return (
        <>
            { formValues.map((element:any, index:any) => (
                <div className={classes.authorizationPersonItem} key={index}>
                    <Grid container spacing={2} sx={{mb:4}}>
                        <Grid item md={4} xs={12}>
                            <TextField
                                sx={{padding: 0, margin: 0, fontWeight:'normal', width: '100% !important'}}
                                label={<FormattedMessage id={messages.firstname_child}/>}
                                color={"secondary"}
                                name={"firstname_authorization"}
                                value={element.firstname_authorization}
                                onChange={e => handleChange(index, e)}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                sx={{padding: 0, margin: 0, fontWeight:'normal', width: '100% !important'}}
                                label={<FormattedMessage id={messages.lastname_child}/>}
                                color={"secondary"}
                                name={"lastname_authorization"}
                                value={element.lastname_authorization}
                                onChange={e => handleChange(index, e)}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                sx={{padding: 0, margin: 0, fontWeight:'normal', width: '100% !important'}}
                                label={<FormattedMessage id={messages.phone_number_child}/>}
                                color={"secondary"}
                                name={"phone_number_authorization"}
                                value={element.phone_number_authorization}
                                onChange={e => handleChange(index, e)}
                            />
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <Box sx={{ marginLeft:{ xs:-1 , sm:-1 } , marginTop:{ xs:-2 , sm:0 } }}>
                                <IconButton aria-label="delete" onClick={() => removeFormFields(index)}>
                                    <ClearIcon fontSize="inherit" color={"error"} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            ))}

        </>
    );
}

export default AuthorizationPersonItem;
