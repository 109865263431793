import {getRequest} from "../helpers/axios_helpers";

function ParentService() {
    return {
        getAllParentData: async () => {
            const parentListReq = await getRequest('/corebase/parents');
            return parentListReq._embedded !== undefined ? parentListReq._embedded?.parentDTOModelList : [];
        },
        getAllParentFiltered: (parentList:any) => {
            return parentList.filter((parent:any) => parent?.person?.nom !== '' && parent?.person?.nom !== null && parent?.person?.prenom !== '' && parent?.person?.prenom !== null && parent?.person?.email !== '' && parent?.person?.email !== null);
        }
    };
}

export default ParentService;
