import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import scolariteFraisStateUpdate from "./ScolariteFraisStateUpdate";
import {FormattedMessage} from "react-intl";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import scolariteFraisGroupStateUpdate from "../../montantdesfrais/businesslogic/ScolariteFraisGroupStateUpdate";
import {getErrorMessage} from "../../../../helpers/errorManager";

const scolariteFraisBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        scolariteFraisBusinessLogic.loadScolaritefrais(logic).then((val:any) => {});
    },
    saveEdit: async (logic:any, messages:any, toSend:any, id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            request(method, `/extra/scolaritelistfrais${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, response.data);

                scolariteFraisStateUpdate.dataSel(logic, _.cloneDeep(dataSaved));
                scolariteFraisBusinessLogic.loadScolaritefrais(logic).then((val:any) => {});
                scolariteFraisStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                const errorMessage =  getErrorMessage(error);
                logic.snackbarShowMessage(errorMessage.message, 'error');
                resolve(false);
            });
        });
    },
    loadScolaritefrais: (logic:any) => {
        scolariteFraisStateUpdate.loading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritelistfrais`, {}).then((response) => {
                const items : ScolariteType[]   = response.data._embedded?.scolariteTypeDTOModelList ?? [];
               /* const itemfilter = _.filter(items, function(item:ScolariteType) {
                    return item.autrefrais;
                });*/
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
                scolariteFraisStateUpdate.dataList(logic, itemsfrontend) ;
                //logic.setDataList(_.cloneDeep(itemsfrontend));
                scolariteFraisStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteFraisStateUpdate.loading(logic, false);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                scolariteFraisStateUpdate.loading(logic, false);
            })
        );
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteType[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteType) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });


            request('DELETE', `/extra/scolaritelistfrais/bouquet`, ids).then((response) => {
                // @ts-ignore
                scolariteFraisBusinessLogic.loadScolaritefrais(logic).then((val:any) => {});
                scolariteFraisStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteFraisGroupStateUpdate.dataListSelected(logic, []);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    }
}
export default scolariteFraisBusinessLogic ;