import {createSlice} from "@reduxjs/toolkit";

export const pageFormImpl = {
    cle: '',
    id: 0,
    metaData: '',
    reload:false,
};
const initialState = {
    pageTitle: 'Dashboard',
    menuOpen: 'dashboard',
    pageForm: {
        cle: '',
        id: 0,
        metaData: '',
        reload:false,
    }
}

const pageSlice = createSlice({
    name: 'page',
    initialState: initialState,
    reducers:{
        setPageTitle:(state, action) => {
            state.pageTitle = action.payload;
        },
        setPageForm:(state, action) => {
            state.pageForm = action.payload;
        },
        setMenuOpen:(state, action) => {
            state.menuOpen = action.payload;
        },
        initPageState:(state) => {
            return initialState;
        }
    }
})

export const { setPageTitle, setPageForm, setMenuOpen, initPageState } = pageSlice.actions;

export default pageSlice.reducer;
