import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import fraisOneShotEnfantStateUpdate from "./businesslogic/FraisOneShotEnfantStateUpdate";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    columnsFraisOneShot,
    excelFraisOneShotDataMapping,
    ScolariteSansEcheanceEnfant,
    scolariteSansEcheanceEnfantDataImpl
} from "../../../constants/type/ScolariteSansEcheanceEnfant";
import {FraisSansEcheanceEnfantContext} from "./FraisOneShotEnfant";
import {isPositiveValue} from "../../../helpers/helpers";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function FraisOneShotEnfantList() {
    const logic: any = useContext(FraisSansEcheanceEnfantContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
   // const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    
    const addItem = () => {
        fraisOneShotEnfantStateUpdate.dataSel(logic, _.cloneDeep(scolariteSansEcheanceEnfantDataImpl));
        fraisOneShotEnfantStateUpdate.openEditDialogForm(logic, true);
    }

    const editItem = (row:MRT_Row<ScolariteSansEcheanceEnfant>) => {
        const data:ScolariteSansEcheanceEnfant = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        fraisOneShotEnfantStateUpdate.dataSel(logic, _.cloneDeep(data));
        fraisOneShotEnfantStateUpdate.openEditDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        const dataSelected:ScolariteSansEcheanceEnfant[] = [];
        rows.map((row:any) => {
            const data:ScolariteSansEcheanceEnfant = logic.dataList[row.index];
            dataSelected.push(data);
        });
        fraisOneShotEnfantStateUpdate.dataListSelected(logic, dataSelected);
        fraisOneShotEnfantStateUpdate.openDeleteDialogForm(logic, true);
    }


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        // @ts-ignore
        () => [
            ...columnsFraisOneShot(
                {
                    intl:intl,
                }
            )
        ],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                addDisabled={!isPositiveValue(logic.childSelected.id)}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier="frais_one_shot"
                excelDataMapping={excelFraisOneShotDataMapping({
                    rows:table.getPrePaginationRowModel().rows,
                    columns: table?.getVisibleFlatColumns(),
                    table: table,
                    intl,
                })}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });
    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default FraisOneShotEnfantList ;