import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    user: [],
    userDetails: [],
    schoolYear: null,
    isLoggedIn: false,
    lang: 'fr-fr'
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.userDetails = action.payload.user.userDetails;
        },
        logoutUser: (state) => {
             return initialState;
        },
        updateUser: (state, action) => {
            state.user = action.payload;
            state.userDetails = action.payload.userDetails;
        },
        setSchoolYear: (state, action ) => {
            state.schoolYear = action.payload;
        },
        setAppLang: (state, action ) => {
            state.lang = action.payload;
        },
    }
});

export const { loginUser, logoutUser, setSchoolYear, updateUser, setAppLang } = userSlice.actions;

export default userSlice.reducer;
