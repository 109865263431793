import React, {useEffect, useState} from 'react';
import '../App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import applicationTheme from "../components/theme/applicationTheme";
import {LanguageProvider, LOCALES} from "../i18n";
//import type {} from '@mui/x-data-grid/themeAugmentation';
import Application from "../app/Application";
import Login from "../page/connection/Login";
import ResetPassword from "../page/connection/ResetPassword";
import Protected from './Protected';
import {useSelector} from "react-redux";
import {ConfirmProvider} from "material-ui-confirm";

const theme = createTheme(applicationTheme())

function App(props:any) {
    const [locale, setLocale] =  useState(LOCALES.FRENCH);
    const {isLoggedIn, lang} = useSelector((state:any) => state.user);

    useEffect(() => {
        //console.log(lang);
        setLocale(lang);
    }, []);

    return (
        <LanguageProvider locale={locale}>
            <ThemeProvider theme={theme}>
                <ConfirmProvider>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Login locale={locale} setLocale={setLocale}  />}  />
                            <Route path="/reset-password" element={<ResetPassword locale={locale} setLocale={setLocale} />} />

                            <Route path="/*" element={
                                <Protected isLoggedIn={isLoggedIn}>
                                    <Application locale={locale} setLocale={setLocale} theme={theme}  />
                                </Protected>
                            } />
                        </Routes>
                    </Router>
                </ConfirmProvider>
            </ThemeProvider>
        </LanguageProvider>
    );
}

export default App;
