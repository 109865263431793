import React, {useContext} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import {useForm} from "react-hook-form";
import messages from "../messages/general/messages";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    DocumentScolaire,
    DOCUMENTSCOLAIRE_CONSTANT,
    documentScolaireDataImpl,
    documentScolaireSchema
} from "../../constants/type/DocumentScolaire";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";
import {Box, Grid, Select} from "@mui/material";
import {FormattedMessage} from "react-intl";
import InputField from "../../components/ui/form/InputField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FolderEditForm = (props:any) => {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;
    const [chipConcerns, setChipConcerns] = React.useState<string[]>([]);
    const handleReceiverSelected = (event: any, object:any) => setChipConcerns(event.target.value);

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(documentScolaireDataImpl),
        // @ts-ignore
        resolver: yupResolver(documentScolaireSchema),
    }));
    const {errors} = formRef.current.formState;

    const onSubmit = async (data: FormData) => {
        schoolDocStateUpdate.processingFolderStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: DocumentScolaire = _.cloneDeep(data);
        dataToSend.chipConcernsForm = _.cloneDeep(chipConcerns);

        // @ts-ignore
        const toSend = DOCUMENTSCOLAIRE_CONSTANT.convertToBackEnd(logic, dataToSend);
        schoolDocumentBusinessLogic.saveFolderEdit(
            logic,
            messages,
            toSend).then((response:any) => {
            schoolDocStateUpdate.openFolderEditDialogForm(logic, false);
            schoolDocStateUpdate.processingFolderStateNormBtn(logic, false);
        }).catch((e:any) => schoolDocStateUpdate.processingFolderStateNormBtn(logic, false));
    };

    React.useMemo(() => {
        if(logic.openFolderEditDialogForm === false){
            return ;
        }
        formRef.current.reset(logic.docFolderSel);
        setChipConcerns(logic.docFolderSel.chipConcernsForm);
        return () => {
            formRef.current.reset(_.cloneDeep(documentScolaireDataImpl));
        };
    }, [logic.openFolderEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingFolderStateNormBtn}
            title={<FormattedMessage id={messages.doc_folder} />}
            open={logic.openFolderEditDialogForm}
            setOpen={logic.setOpenFolderEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            showSubmitBtn={logic.docFolderSel.isUserCanEditForm}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
                <Box  sx={{ width: {xs:'100% !important'} }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.doc_edit_folder} />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.doc_folder} />}
                                    size="small"
                                    color={"secondary"}
                                    disabled={!logic.docFolderSel.isUserCanEditForm}
                                    inputProps={{
                                        ...formRef.current.register("nom")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl sx={{xs: 'block', width:'96%' }}>
                                <InputLabel id="send_to">{<FormattedMessage id={messages.send_to} />}</InputLabel>
                                <Select
                                    labelId="send_to"
                                    id="send_to"
                                    multiple
                                    disabled={!logic.docFolderSel.isUserCanEditForm}
                                    value={chipConcerns}
                                    onChange={handleReceiverSelected}
                                    input={<OutlinedInput label="Chip" />}
                                    renderValue={(selected:any) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value:any) => (
                                                <Chip key={value} label={logic.destinatairesMapRef.current.get(value) ?? ``} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                    sx={{ fontWeight:400, p:0 }}
                                    size="small"
                                >
                                    {logic.menuItemSelectsRef.current}
                                </Select>
                            </FormControl>

                            <div className={styleClasses.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
        </DialogFormKiller>
    );
}

export default FolderEditForm ;