import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Link, useLocation} from "react-router-dom";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {dropZoneFilePreview} from "../../helpers/helpers";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import Button from "@mui/material/Button";
import classNames from "classnames";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import EditProductForm from "./EditProductForm";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import MarketingService from "../../service/MarketingService";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import ProductOrdersList from "./ProductOrdersList";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            },
            '& .css-2m0kuj-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                padding: '9px 1px'
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {},

    }
})

function EditProduct(props:any) {
    const classes = {...useStyles(), ...useStyles2()};
    const {locale, theme, snackbarShowMessage} = props;
    const [loadingButton, setLoadingButton] = useState(false);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [file, setFile] = useState(null);
    const activeLang = locale.split('-')[0];
    const marketingService = MarketingService();

    const schema = yup.object().shape({
        id: yup.number(),
        title: yup.string().required(),
        prix: yup.number().required().nonNullable(),
        quantity: yup.number().required().nonNullable(),
        description: yup.string(),
    });

    const params = useLocation();
    const { data } = params.state;

    const form = useForm< any | undefined> ({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const onSubmit = async (dataForm:any) => {
        setLoadingButton(true);
        try {
            const dataToSend = {
                ...data?.originalObject,
                nom: dataForm?.title?.trim(),
                prix: dataForm?.prix,
                quantite: dataForm?.quantity,
                description: dataForm?.description,
            }

            await marketingService.updateProduct('PUT', `/extra/commerceproduit/${data?.id}`, dataToSend, file);
            setLoadingButton(false);
            snackbarShowMessage( <FormattedMessage id={messages.sb_edit_success} />);
        }
        catch (error) {
            setLoadingButton(false);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        }
    }

    useEffect(() => {
        (async () => {
            setFileDataURL(null);
            if(data?.photo !== '' && data?.photo !== null){
                const photo:any = `${BASEURL_RESOURCES}/${data?.photo}`;
                setFileDataURL(photo);
            }
            setValue('id', data?.id);
            setValue('title', data?.nom);
            setValue('prix', data?.originalObject?.prix);
            setValue('quantity', data?.quantite);
            setValue('description', data?.description);
        })()
    }, []);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.subMenuTools}>
                <Button
                    className={classNames(classes.button) }
                    variant={"text"}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: 15,
                        color: "#666666"
                    }}
                    component={Link} to="/app/products/list"
                    startIcon={<KeyboardBackspaceIcon />}
                >
                    <FormattedMessage id={messages.return_product_list} />
                </Button>
            </div>
            <div className={classes.root}>
                <Grid container spacing={2} style={{marginBottom: 30}}>
                    <Grid item md={5} xs={12} style={{background: 'white'}}>
                        <EditProductForm
                            activeLang={activeLang}
                            data={data}
                            handleFileChange={handleFileChange}
                            fileDataURL={fileDataURL}
                            register={register}
                            onSubmit={handleSubmit(onSubmit)}
                            setValue={setValue}
                            loadingButton={loadingButton}
                        />
                    </Grid>

                    <Grid item md={7} xs={12} style={{background: 'white'}}>
                        <ProductOrdersList
                            data={data}
                            activeLang={activeLang}
                        />
                    </Grid>
                </Grid>

            </div>
        </PapperBlock>
    );
}

export default withSnackbar(EditProduct);
