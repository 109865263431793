import React, {useContext, useMemo} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import {Box, Button, Grid} from "@mui/material";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import {DocumentScolaireFichier} from "../../constants/type/DocumentScolaireFichier";
import _ from "lodash";
import {BASEURL_RESOURCES} from "../../config/AppUrl";

function DocumentFile() {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;
    const docFolderSel = logic.docFolderSel;
    const docFileSel = logic.docFileSel;
    const onSelect = (event:any, doc:DocumentScolaireFichier) =>  {
        if (_.isNull(doc.id)) {
            if (event.detail === 2) {
                schoolDocStateUpdate.goOutDocFolderSel(logic);
            }
            return ;
        }
        if (event.detail === 2) {
            const url = `${BASEURL_RESOURCES}/${doc.uri}`;
            window.open(url, '_blank');
            return ;
        }
        schoolDocStateUpdate.docFileSel(logic, doc);
    };
    const handleGoOut = () => schoolDocStateUpdate.goOutDocFolderSel(logic);


    const fichiers = useMemo<any>(() => {
        return _.map(docFolderSel.documentScolaireFichiers, item => {
            return (
                <Grid item xs={2} sm={2} md={2} key={item.id}>
                    <Button
                        variant={item.id !== docFileSel.id ?"text":"outlined"}
                        onClick={(event:any) => onSelect(event, item)}>
                        <Box className={styleClasses.containerBtnFileFolder}>
                            <img
                                width="48"
                                height="48"
                                src={item.iconForm} alt={item.nom} />
                            <span>{item.nom}</span>
                        </Box>
                    </Button>
                </Grid>
            );
        });
    }, [docFileSel.id, docFolderSel.documentScolaireFichiers, logic.numeriChange]) ;

    if(logic.showFiles === false){
        return <></>;
    }

    return(
        <>
            {fichiers}
            <Button
                variant="text"
                sx={{float:'right', width:'10%',wordWrap:'break-word', textTransform:'none'}}
                onClick={handleGoOut}>
                <Box className={styleClasses.containerBtnFileFolder}>
                    <img
                        width="32"
                        height="32"
                        src={logic.backPictureRef.current.iconForm} alt={logic.backPictureRef.current.nom} />
                    <span>{logic.backPictureRef.current.nom}</span>
                </Box>
            </Button>
        </>
    );
}

export default DocumentFile ;