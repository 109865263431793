import React, {useContext, useMemo} from "react";
import {EmprunterContext} from "./Emprunter";
import {useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import emprunterStateUpdate from "./businesslogic/EmprunterStateUpdate";
import {columnsLivreEmprunt, LivreEmprunt} from "../../../constants/type/LivreEmprunt";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function EmprunterList() {
    const logic: any = useContext(EmprunterContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';


    const editItem = (row:MRT_Row<LivreEmprunt>) => {
        const data:LivreEmprunt = logic.livresEmprunt[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        emprunterStateUpdate.livreSel(logic, _.cloneDeep(data.livre));
        emprunterStateUpdate.livreEmpruntSel(logic, _.cloneDeep(data));
        emprunterStateUpdate.openLivreForEmpruntDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        const dataSelected:LivreEmprunt[] = [];
        rows.map((row:any) => {
            const data:LivreEmprunt = logic.livresEmprunt[row.index];
            dataSelected.push(data);
        });
        emprunterStateUpdate.livresEmpruntSelList(logic, dataSelected);
        emprunterStateUpdate.openLivreEmpruntDeleteDialogForm(logic, true);
    }


    const excelDataMapping = (rows:any) => {
        return _.map(rows, (row:any) => {
            let obj:any = {};
            const data = row.original;
            obj[intl.formatMessage({id:messages.table_h_livre})] = data.livre?.nom ?? ``;
            obj[intl.formatMessage({id:messages.table_h_quantite})] = data.quantite;
            obj[intl.formatMessage({id:messages.table_h_emprunt_date})] = data.lireDateEmpruntForm;
            obj[intl.formatMessage({id:messages.table_h_emprunt_deadline})] = data.lireDeadlineRetourForm;
            obj[intl.formatMessage({id:messages.table_h_emprunt_retour})] = data.lireDateRetourForm;
            obj[intl.formatMessage({id:messages.table_h_emprunt})] = data.lireHorsdelaiForm;
            return obj;
        });
    }

    const columns = useMemo<MRT_ColumnDef<LivreEmprunt>[]>(
        () => [...columnsLivreEmprunt({intl:intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.livresEmprunt,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
                showAddButton={false}
                export_xls_title={export_xls}
                xls_nom_fichier="Books_loaned"
                excelDataMapping={excelDataMapping(table.getPrePaginationRowModel().rows)}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

    
    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default EmprunterList ;