import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";

export const LIVRECATEGORIE_CONSTANT = {
    convertToFrontEnd: (item:LivreCategorie) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(livreCategorieDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:LivreCategorie) => item,
};

export  const livreCategorieDataImpl: LivreCategorie = {
    id: null,
    nom: '',
    common: _.cloneDeep(commonDataImp)
}

export const livreCategorieSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable(),
    common: yup.mixed<Common>()
});

export type LivreCategorie = yup.InferType<typeof livreCategorieSchema>;