import * as yup from "yup";
import {Genre} from "./Genre";
import {PaysPerson, PAYSPERSON_CONSTANT} from "./PaysPerson";
import moment from "moment/moment";
import _ from "lodash";
import messages from "../../page/messages/general/messages";
import {Avatar} from "@mui/material";
import React from "react";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import Box from "@mui/material/Box";
import {isPositiveValue, stringToColor} from "../../helpers/helpers";


export const PERSON_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Person) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(personDataImpl);
        item =  {...itemInit, ...item} ;
        // @ts-ignore
        item.paysPersons = _.map(item.paysPersons, itempays => PAYSPERSON_CONSTANT.convertToFrontEnd(logic, itempays)) ;

        item.nomPrenom = `${item.prenom} ${item.nom}`;
        item.dateNaissanceForm = isPositiveValue(item.dateNaissance) ? moment(item.dateNaissance).format("L") : `` ;
        // @ts-ignore
        item.photoForm = `${BASEURL_RESOURCES}/${item.photo}`;
        item.phoneForm = _.size(item.phone2) > 3 ? item.phone1 : `${item.phone1} / ${item.phone2}`;

        const paysKey = logic.activeLang === 'en' ? 'nom_en_gb' : 'nom_fr_fr' ;
        let paysForm = ``;
        for(const dataPays of item.paysPersons ?? []){
            paysForm = `${dataPays?.paysForm[paysKey] ?? ``}${paysForm}, ` ;
        }
        // @ts-ignore
        item.paysForm   = paysForm;
        return item ;
    },
    convertToBackEnd: (logic:any, item:Person) => item,
};



export  const personDataImpl: Person = {
    id: null,
    dateNaissance: 0,
    dateNaissanceForm: '',
    nomPrenom: ``,
    nom: '',
    prenom: '',
    sexe: Genre.HOMME,
    adresse: '',
    photo: '',
    email: '',
    phone1: '',
    phone2: '',
    note: '',
    cniNumero: '',
    cniPhoto: '',
    paysPersons: [],
    paysForm: ``,
    photoForm:'',
    phoneForm:'',
}


export const personSchema = yup.object().shape({
    id: yup.number().nullable(),
    dateNaissance: yup.number().nonNullable(),
    dateNaissanceForm: yup.string(),
    nomPrenom: yup.string().nonNullable(),
    nom: yup.string().nonNullable(),
    prenom: yup.string().nonNullable(),
    sexe: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.FEMME),
    adresse: yup.string().nullable(),
    photo: yup.string().nullable(),
    email: yup.string().nullable(),
    phone1: yup.string().nullable(),
    phone2: yup.string().nullable(),
    note: yup.string().nullable(),
    cniNumero: yup.string().nullable(),
    cniPhoto: yup.string().nullable(),
    poste: yup.string().nullable(),
    paysPersons: yup.array().of(yup.mixed<PaysPerson>()).nonNullable(),
    paysForm: yup.string(),
    photoForm: yup.string(),
    phoneForm: yup.string().nullable(),
});

export const personCustomOptions = {
    columnVisibility : {
        'person.nom': false,
        'person.prenom': false,
        'person.dateNaissanceForm': false,
        'person.poste': false,
        'person.note': false,
        'person.paysForm': false,
    }
} ;

export const columnsPersonPlugin = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        {
            accessorKey: 'person.photoForm',
            header:  `${infoSpace} ${intl.formatMessage({id: messages.table_h_person_avatar})}`,
            grow: false,
            size: 60,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            accessorKey: 'person.nomPrenom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            accessorKey: 'person.nom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            accessorKey: 'person.prenom',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            accessorKey: 'person.dateNaissanceForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`,
            size: 100,
        },
        {
            accessorKey: 'person.email',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_person_email})}`,
            size: 120,
        },
        {
            accessorKey: 'person.phoneForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_person_phone})}`,
            size: 100,
        },
        {
            accessorKey: 'person.poste',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_person_job})}`,
        },
        {
            accessorKey: 'person.note',
            header: `${infoSpace} ${intl.formatMessage({id: messages.note})}`,
        },
        {
            accessorKey: 'person.paysForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.country})}`,
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                const paysKey = intl.locale === 'en' ? 'nom_en_gb' : 'nom_fr_fr';
                // @ts-ignore
                const paysData = cell.getValue<any>();
                const nomPays = paysData[paysKey] ?? ``;
                return (
                    <Box component="span" style={{textAlign: 'center', color: stringToColor(nomPays)}}>
                        {nomPays}
                    </Box>
                );
            },
        },
    ]
};

export type Person = yup.InferType<typeof personSchema>;
