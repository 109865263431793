import React, {useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../config-table-jss";
import Box from "@mui/material/Box";
import ScolariteSubMenu from "../ScolariteSubMenu";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {Button, Icon, Tooltip} from "@mui/material";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ScolariteForm from './ScolariteForm';
import {useForm} from "react-hook-form";
import _ from "lodash";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type Schooling = {
    id: number;
    nom: string,
    profile: string,
    montant: number,
};

type SchoolingFormValues = {
    id:number,
    nom:string,
    profile:string,
    montant:number,
}

const data:Schooling[] = [
    {
        id:1,
        nom: 'Scolarité',
        profile: 'Tous les profils',
        montant: 4500000,
    }
];

function Scolarite(props:any) {
    const { theme, locale, } = props;
    const activeLanguage = locale.split('-');
    const classes = {...useStyles(), ...useStyles2()};
    const [schoolingData, setSchoolingData] = useState(data);
    const [stateScolarite, setStateScolarite] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [profile, setProfile] = useState('');


    const handleClickOpen = () => {
        setOpen(true);
    };

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.schooling} />,
        <FormattedMessage id={messages.schooling_profile} />,
        <FormattedMessage id={messages.schooling_amount} />,
        <FormattedMessage id={messages.end_period} />
    ];

    const columns = useMemo<MRT_ColumnDef<Schooling>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: columnsHeaders[0],
                size:600
            },
            {
                accessorKey: 'profile',
                header: columnsHeaders[1],
            },
            {
                accessorKey: 'montant',
                header: columnsHeaders[2],
            },

        ],
        [],
    );

    const form = useForm<SchoolingFormValues>({
        defaultValues: {
            id: 0,
            nom: '',
            profile: '',
            montant:0,
        }
    });
    const {register, handleSubmit,  setValue } = form;

    const handleProfileChange = (event:any) => {
        setProfile(event.target.value);
    }

    const addSchooling = () => {
        setStateScolarite(true);
        setValue('nom', '');
        setValue('profile', '');
        setValue('montant', 0);
        handleClickOpen();
    };

    const editSchooling = (row:any) => {
        setStateScolarite(false);
        const data:any = schoolingData[row.index];
        //const montant:any = new Intl.NumberFormat('fr-FR').format(4500000);

        setValue('id', data.id );
        setValue('nom', data.nom );
        setValue('profile', data.profile);
        setValue('montant', data.montant);

        handleClickOpen();
    }

    const onSubmit = () => {

    }

    const onSubmitEditForm = () => {

    }

    return (
        <div className={classes.root}>
            <Box className={classes.buttonContainer}>
                <ScolariteSubMenu classes={classes} selected="schooling" />
            </Box>

            <div className={classes.tableContainer}>
                <MaterialReactTable
                    columns={columns}
                    data={schoolingData}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact'
                    }}
                    localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing
                    // editDisplayMode="row"
                    //onEditingRowSave={handleSaveRow}
                    enableRowSelection
                    positionActionsColumn="last"
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={classes.topToolbarActionsButton}
                            sx={{ display: 'flex',  flexWrap: 'wrap' }}
                        >
                             <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="secondary"
                                    sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                    onClick={() => addSchooling()}
                            >
                                <Icon sx={{marginRight:1}}>add</Icon>
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            >
                                <Icon sx={{marginRight:1}}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>



                            {/*<IconButton title={export_xls}>
                                <img width="24" height="24" src={IMGS.excelIcon} alt="Excel icon" />
                            </IconButton>

                            <IconButton title={export_pdf}>
                                <img width="24" height="24" src={IMGS.pdfIcon} alt="PDF icon" />
                            </IconButton>*/}
                        </Box>
                    )}

                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} />}>
                                <IconButton
                                    onClick={() => editSchooling(row)}
                                    sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />

                <ScolariteForm
                    theme={theme}
                    title={stateScolarite ? <FormattedMessage id={messages.dialog_title_schooling} /> : <FormattedMessage id={messages.dialog_edit_title_schooling} /> }
                    open={open}
                    setOpen={setOpen}
                    classes={classes}
                    register={register}
                    onSubmit={stateScolarite ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                    profile={profile}
                    handleProfileChange={handleProfileChange}
                />

            </div>
        </div>
    );
}

export default Scolarite;
