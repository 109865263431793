import React, {useContext} from 'react';
import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';
import classNames from "classnames";
import {Box, Button, ListSubheader} from "@mui/material";
import messages from "../general/messages";
import {FormattedMessage} from "react-intl";
import {MessageCenterContext} from './MessageCenter';
import spaceListBusinessLogic from "./businessLogic/SpaceListBusinessLogic";
import spaceRoomBusinessLogic from "./businessLogic/SpaceRoomBusinessLogic";
import {TransitionGroup} from 'react-transition-group';

function ConversationList(props:any) {
    const logicRef: any = useContext(MessageCenterContext);


    React.useEffect(() => spaceListBusinessLogic.listerLesConversation(logicRef, logicRef.space, logicRef.spaceList),
        [logicRef.expandOpenedSpace, logicRef.expandClosedSpace]);


    return (
        <div>
            <div className={classNames(logicRef.spaceListStyles.toolbar, logicRef.spaceListStyles.clippedRight)}>
                <div className={logicRef.spaceListStyles.flex}>
                    <Box
                        sx={{
                            paddingLeft:{ sm:2 },
                            paddingRight:{ sm:2 }
                        }}
                    >
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            size={"large"}
                            sx={{ width:{ xs:330, sm:300 }, textTransform:"inherit" }}
                            onClick={() => spaceRoomBusinessLogic.handleComposeNewMessage(logicRef)}
                            startIcon={<AddIcon />}
                        >
                            <FormattedMessage id={messages.compose_ticket} />
                        </Button>
                    </Box>



                    {/*<div style={{width:'100%', backgroundColor:'red', display:'block'}}>
                            <Button sx={{backgroundColor:'yellowgreen', width:'89%', marginLeft:'15px', marginRight:'20px'}}>
                                Compose new message
                            </Button>
                        </div>*/}
                    {/*<div className={classes.searchWrapper}>
                            <div style={{width:'100%', backgroundColor:'red', display:'block'}}>
                                <Button sx={{backgroundColor:'yellowgreen', width:'89%', marginLeft:'15px', marginRight:'20px'}}>
                                    Compose new message
                                </Button>
                            </div>

                           <div className={classes.search}>
                                <SearchIcon />
                            </div>
                            <input className={classes.input} onChange={(event) => search(event)} placeholder={"Search"} />
                        </div>*/}
                    {/*<Tooltip title={"Add contact"}>
                            <IconButton
                                //className={classes.buttonAdd}
                                onClick={() => addContact()}
                                color="secondary"
                                aria-label="Delete"
                                size="large">
                                <Add />
                            </IconButton>
                        </Tooltip>*/}
                </div>
            </div>
           {/* <div className={logicRef.spaceListStyles.total}>
                <FormattedMessage id={messages.conversation_list} />
            </div>*/}

                <List
                    component="nav"
                    disablePadding
                    subheader={
                        <ListSubheader component="div" className={logicRef.spaceListStyles.total} id="nested-list-subheader">
                            <FormattedMessage id={messages.conversation_list} />
                        </ListSubheader>
                    }
                    className={logicRef.spaceListStyles.contactList}>
                    <TransitionGroup>
                        { logicRef.listeDesConversations }
                    </TransitionGroup>
                </List>
        </div>
    );
}


export default ConversationList;
