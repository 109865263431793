import {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";

import _ from "lodash";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import {AlertMessage, alertMessageDataImpl} from "../../../constants/type/AlertMessage";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {alertMessageParentCustomOptions} from "../../../constants/type/AlertMessageParent";

const useAlertMessageVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'AlertMessageNotification',  customOptions:alertMessageParentCustomOptions, updateId:1}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<AlertMessage>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openEditDetailsDialogForm, setOpenEditDetailsDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());


    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());
    const editDisableRef = useRef<boolean>(true);


    const [dataList, setDataList] = useState<AlertMessage[]>([]);
    const [dataListSelected, setDataListSelected] = useState<AlertMessage[]>([]);
    const [dataSel, setDataSel] = useState<AlertMessage>(_.cloneDeep(alertMessageDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,classeRoomRef,tableConfigRef
        ,classeRoomMapRef,openEditDetailsDialogForm,setOpenEditDetailsDialogForm,editDisableRef,
        processingSubmitStateNormBtn,setProcessingSubmitStateNormBtn,...logicMRT
    }
}
export default useAlertMessageVariables;
