import {request} from "./axios_helpers";
import _ from "lodash";
import {EventNotification, EVENTNOTIFICATION_CONSTANT} from "../constants/type/EventNotification";
import {HTTP_MESSAGE_URL} from "../config/AppUrl";


export const NOTIF_MODULES_NAME = new Map<string,string>([
    ['fr', 'une information'],
    ['en', 'an information'],
    ['rdv_fr', 'le rendez-vous'],
    ['rdv_en', 'the appointment'],
    ['chat_fr', 'le mesage'],
    ['chat_en', 'the message'],
    ['enfants_fr', `le profil de l'enfant`],
    ['enfants_en', 'the profil of child'],
]);
export const NAVIGATION = new Map<string,string>([
    ['rdv', '/app/appointment/preset'],
    ['chat', '/app/message/list'],
    ['enfants', '/app/child/list'],
    ['imsday', '/app/child/child-day'],
]);

export const NOTIF_COD = new Map<string,string>([
    ['create_fr', ` a enregistré  `],
    ['read_fr', ` a lu `],
    ['update_fr', ` a mis à jour `],
    ['delete_fr', ` a supprimé `],
    ['create_en', ` added `],
    ['read_en', ` read `],
    ['update_en', ` updated `],
    ['delete_en', ` deleted `],
]);
export const loadUserUnReadNotifications = (userUUID:string) => {
    return new Promise<EventNotification[]>(resolve =>
        request("GET",  `${HTTP_MESSAGE_URL}/ws/notifications/utilisateur/${userUUID}`, {}).then((response) => {
            const items : EventNotification[]   = response.data ?? [];
            // @ts-ignore
            const itemsMaps: EventNotification[]  = _.map(items,  EVENTNOTIFICATION_CONSTANT.convertToFrontEnd);

            resolve(itemsMaps);
        }).catch((error) => {
            console.error(error);
            resolve([]);
        })
    );
}


export const loadUserNotifications = (userUUID:string) => {
    return new Promise<EventNotification[]>(resolve =>
        request("GET",  `${HTTP_MESSAGE_URL}/ws/notifications/utilisateur/${userUUID}/all`, {}).then((response) => {
            const items : EventNotification[]   = response.data ?? [];
            // @ts-ignore
            const itemsOrder: EventNotification[]  = _.reverse(_.sortBy(_.map(items,  EVENTNOTIFICATION_CONSTANT.convertToFrontEnd), ['dateSent']));
            resolve(itemsOrder);
        }).catch((error) => {
            console.error(error);
            resolve([]);
        })
    );
}



export const deleteNotification = (userUUID:string, notifId:number) => {
   return new Promise<boolean>(resolve =>
        request("DELETE",  `${HTTP_MESSAGE_URL}/ws/notifications/utilisateur/${userUUID}/${notifId}`, {}).then((response) => {
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );
}

export const updateNotification = (userUUID:string, notifId:number) => {
   return new Promise<boolean>(resolve =>
        request("PUT",  `${HTTP_MESSAGE_URL}/ws/notifications/utilisateur/${userUUID}/read/${notifId}`, {}).then((response) => {
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    );
}
/*

export const openPageRelatedToNotification = (userUUID:string, item:EventNotification, setOpen:any, dispatch:any) => {
    // @ts-ignore
    deleteNotification(userUUID, item.id);

    const pageForm = {cle:item.uriErpCle, id:item.dataId, metaData:item};
    dispatch(setPageForm(pageForm));

    setOpen(item.uriErpCle);
    // @ts-ignore
    setPageTitle(menuMessages[item.uriErpCle] !== undefined ? <FormattedMessage id={menuMessages[item.uriErpCle]} /> : item.uriErpNom);
    // @ts-ignore
    navigate(item.uriErp);

}
*/
