import React from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import livreStyles from "./jss/livre-jss";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {useDispatch} from "react-redux";
import livreEmpruntBusinessLogic from "./businesslogic/LivreEmpruntBusinessLogic";
import LivreEmpruntList from "./LivreEmpruntList";
import LivreEmpruntEditForm from "./LivreEmpruntEditForm";
import LivreEmpruntDeleteForm from "./LivreEmpruntDeleteForm";
import useLivreEmpruntVariables from "./businesslogic/LivreEmpruntVariables";
import {livreEmpruntDataImpl, livreEmpruntSchema} from "../../../constants/type/LivreEmprunt";

const useBusinessLogic = (props: any): any => {
    const styleClasses = livreStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreEmpruntDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreEmpruntSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useLivreEmpruntVariables({styleClasses, ...props})
    }
};

export const EmpruntContext = React.createContext({});

function Emprunt(props:any){
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(logic.pageForm.reload){
            moment.locale(logic.activeLang);
            livreEmpruntBusinessLogic.initPostConstruct(logic);
        }
    }, [logic.pageForm]);


    React.useEffect(() => {
        moment.locale(logic.activeLang);
        livreEmpruntBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);
    return (
        <div className={logic.styleClasses.root}>
            <EmpruntContext.Provider value={logic}>
                <LivreEmpruntList />
            </EmpruntContext.Provider>

            <EmpruntContext.Provider value={logic}>
                <LivreEmpruntEditForm />
            </EmpruntContext.Provider>
            <EmpruntContext.Provider value={logic}>
                <LivreEmpruntDeleteForm />
            </EmpruntContext.Provider>
            {/*<Box>
                    <Grid container rowSpacing={4} sx={{pr:4,pl:4}}  spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <EmpruntContext.Provider value={logic}>
                            <DocumentFolder />
                        </EmpruntContext.Provider>
                        <EmpruntContext.Provider value={logic}>
                            <DocumentFile />
                        </EmpruntContext.Provider>
                    </Grid>
                </Box>





                <EmpruntContext.Provider value={logic}>
                    <FileDeleteForm />
                </EmpruntContext.Provider>*/}
        </div>
    );
}
export default withSnackbar(Emprunt);