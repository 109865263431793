import React, {useState} from 'react';
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Grid, InputAdornment, OutlinedInput, TextField} from "@mui/material";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormCrudButton from "../../components/ui/form/FormCrudButton";
import SaveIcon from "@mui/icons-material/Save";
import AuthenticationService from "../../service/AuthenticationService";
import {getErrorMessage} from "../../helpers/errorManager";


function UdpatePasswordForm(props:any) {
    const {classes, snackbarShowMessage} = props;
    const {user} = useSelector((state:any) => state.user);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: any) => event.preventDefault();

    const authenticationService = AuthenticationService();

    const schema = yup.object().shape({
        oldPassword: yup.string().required(),
        newPassword: yup.string().min(4).required(),
        confirmNewPassword: yup.string().min(4).required(),
    });

    const form = useForm({
        defaultValues:{},
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue, getValues} = form;
    const {errors} = formState;

    const onSubmitEdit = async (data:any) => {
        try {
            if(data.newPassword === data.confirmNewPassword){
                const dataToSend = {
                    username: user.username,
                    password: data.oldPassword,
                    newPassword: data.newPassword,
                }
                const response = await authenticationService.updateUserPassword(dataToSend);
                snackbarShowMessage(<FormattedMessage id={messages.sb_edit_success} />);
            }
            else {
                snackbarShowMessage(<FormattedMessage id={messages.password_not_match_profile} />, 'error');
            }
        }
        catch (error) {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        }
    }

    return (
        <div style={{backgroundColor: 'white'}}>
            <div style={{backgroundColor: '#eee', padding: '10%', paddingTop:20}}>
                <h3 style={{marginBottom:25}}>{<FormattedMessage id={messages.title_password_profile}/>}</h3>

                <Grid item md={7} xs={12}>
                    <div className={classNames(classes.inputField)} style={{ marginBottom:20}}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.username_profile}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            disabled
                            value={user.username}
                            sx={{ width: { xs: '100% !important'}, backgroundColor: 'white' }}
                        />
                    </div>
                </Grid>

                <Grid item md={7} xs={12}>
                    <div className={classNames(classes.inputField)} style={{ marginBottom:20}}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.old_password_profile}/>}</p>
                        <FormControl className={classes.formControl} sx={{width: '100%'}}>
                            <OutlinedInput
                                id="outlined-adornment-old-password"
                                type={showPassword ? 'text' : 'password'}
                                sx={{ backgroundColor: 'white'}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={{mt: 0}}
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{
                                    ...register("oldPassword", {
                                        required: "Required field"
                                    })
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>


                <Grid item md={7} xs={12}>
                    <div className={classNames(classes.inputField)} style={{ marginBottom:20}}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.new_password_profile}/>}</p>
                        <FormControl className={classes.formControl} sx={{width: '100%'}}>
                            <OutlinedInput
                                id="outlined-adornment-new-password"
                                type={showPassword ? 'text' : 'password'}
                                sx={{ backgroundColor: 'white'}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={{mt: 0}}
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{
                                    ...register("newPassword", {
                                        required: "Required field"
                                    })
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>


                <Grid item md={7} xs={12}>
                    <div className={classNames(classes.inputField)} style={{ marginBottom:20}}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.confirm_new_password_profile}/>}</p>
                        <FormControl className={classes.formControl} sx={{width: '100%'}}>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                type={showPassword ? 'text' : 'password'}
                                sx={{ backgroundColor: 'white'}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={{mt: 0}}
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{
                                    ...register("confirmNewPassword", {
                                        required: "Required field"
                                    })
                                }}
                            />
                        </FormControl>
                    </div>
                </Grid>

                <Grid item md={7} xs={12}>
                    <div className={classes.save_button}>
                        <FormCrudButton
                            color="primary"
                            variant="contained"
                            icon={SaveIcon}
                            textButton={<FormattedMessage id={messages.save_form_crud_btn} />}
                            onClickFormCrudAction={handleSubmit(onSubmitEdit)}
                        />
                    </div>
                </Grid>

            </div>


        </div>
    );
}

export default UdpatePasswordForm;