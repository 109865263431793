import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Livre, livreDataImpl} from "../../../../constants/type/Livre";

import _ from "lodash";
import {LivreCategorie} from "../../../../constants/type/LivreCategorie";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useLivreVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'Livres',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<Livre>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const livreCategorieRef = useRef<LivreCategorie[]>([]);
    const [openLivreEditDialogForm, setOpenLivreEditDialogForm] = useState<boolean>(false);
    const [openLivreDeleteDialogForm, setOpenLivreDeleteDialogForm] = useState<boolean>(false);
    const [processingLivreStateNormBtn, setProcessingLivreStateNormBtn] = useState<boolean>(false);
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const [livres, setLivres] = useState<Livre[]>([]);
    const [livresSelected, setLivresSelected] = useState<Livre[]>([]);
    const [livreSel, setLivreSel] = useState<Livre>(_.cloneDeep(livreDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openLivreEditDialogForm,setOpenLivreEditDialogForm,
        openLivreDeleteDialogForm, setOpenLivreDeleteDialogForm,processingLivreStateNormBtn,setProcessingLivreStateNormBtn,
        livres,setLivres,livresSelected,setLivresSelected,livreSel,setLivreSel,file,setFile,fileDataURL,setFileDataURL,
        tableKeyValue,setTableKeyValue,livreCategorieRef,tableDataLoading,seTableDataLoading,dataCaches, dispatch,
        tableConfigRef,
        ...logicMRT
    }
}
export default useLivreVariables;