import React, {useContext} from "react";
import _ from "lodash";

import {Box, Grid, MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {ScolariteEcheancierEnfantContext} from "./ScolariteEcheancierEnfant";
import scolariteEcheancierEnfantStateUpdate from "./businesslogic/ScolariteEcheancierEnfantStateUpdate";
import scolariteEcheancierEnfantBusinessLogic from "./businesslogic/ScolariteEcheancierEnfantBusinessLogic";
import {Profils} from "../../../constants/type/Profils";
import InputField from "../../../components/ui/form/InputField";
import moment from "moment";
import {ScolariteGroup} from "../../../constants/type/ScolariteGroup";
import {isPositiveValue} from "../../../helpers/helpers";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getProfils(logic:any, profilId:number):Profils | undefined{
    return _.find(logic.profilsRef?.current, {id:profilId})  ;
}
function getProfilName(logic:any, profilId:number):string{
    const profil: Profils | undefined =  getProfils(logic, profilId)  ;
    return _.isObject(profil) ? profil.nom : ``;
}

const ScolariteEcheancierEnfantGenerateForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierEnfantContext);
    const styleClasses = logic.styleClasses ;
    const [scolariteGroupId, setScolariteClasseId] = React.useState<number>(0);
    const [scolariteGroupList, setScolariteClasseList] = React.useState<ScolariteGroup[]>([]);


    const updateScolariteType = () => {
        const classeId: number = logic.childSelected?.eleves[0]?.classe?.id ?? 0;
        const scolclasseFiltre  = _.filter(logic.scolariteGroupsRef.current, function(item) {
            return item.classeId ===  classeId;
        });
        setScolariteClasseList(scolclasseFiltre);
    }

    const handleOnTypeChange = (event: any) => setScolariteClasseId (_.toInteger(event.target.value));

    const onSubmit = async () => {
        // @ts-ignore
        if(isPositiveValue(scolariteGroupId)){
            logic.snackbarShowMessage(logic.activeLang === "en" ? "Please select a tuition fees" : "Veuillez choisir un frais de scolarité", 'error');
            return ;
        }

        scolariteEcheancierEnfantBusinessLogic.generateFeesForStudent(
            logic,
            messages,
            logic.childSelected.id,
            scolariteGroupId).then((response:any) => {
            scolariteEcheancierEnfantStateUpdate.openEditDialogForm(logic, false);
            scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false);
            scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());
        }).catch((e:any) => scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false));
    };

   

    React.useEffect(() => {
        if(logic.openEditDialogForm === false){
            setScolariteClasseId(0);
            return ;
        }
        updateScolariteType();
        return () => {};
    }, [logic.openEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.generate_financial_schedule}/>}
            open={logic.openGenerateDialogForm}
            setOpen={logic.setOpenGenerateDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <InputField
                            label={<FormattedMessage id={messages.classroom_child}/>}
                            size="small"
                            color={"secondary"}
                            value={logic.childSelected.classeNameForm}
                            disabled
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.schooling_fees_title}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={scolariteGroupId}
                            onChange={handleOnTypeChange}
                            required
                            inputProps={{
                                required: "Required field",
                                min: 1,
                            }}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteGroupList?.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                    {opt.nom} ({opt.scolariteTypeForm?.nom})
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    
                </Grid>


            </Box>
        </DialogFormKiller>
    );
}

export default ScolariteEcheancierEnfantGenerateForm ;