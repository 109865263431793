import React, {useContext} from "react";
import {Avatar, Box, Button, Grid, IconButton} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import albumPhotoStateUpdate from "./businesslogic/AlbumPhotoStateUpdate";
import albumPhotoBusinessLogic from "./businesslogic/AlbumPhotoBusinessLogic";
import _ from "lodash";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {AlbumPhotoContext} from "./AlbumPhoto";

function AlbumPhotoEditToolBar(props: any) {
    const logic: any = useContext(AlbumPhotoContext);
    const styleClasses = logic.styleClasses;
    const [uploadingMsg, setUploadingMsg] = React.useState<boolean>(false);
    const textBtnFileAttachedRef = React.useRef<string>(logic.activeLang === 'en' ? 'Upload photos' : 'Uploader des photos');
    const handleDelete = () => albumPhotoBusinessLogic.handleDeleteFiles(logic);
    const handleGoOut = () => albumPhotoStateUpdate.showForm(logic, false);
    const handleFileChange = (event: any) => {
        const files = event.target.files;
        albumPhotoBusinessLogic.envoieFichier(logic, logic.childSel, files, setUploadingMsg);
    }



    return (
        <div style={{display: "flex"}}>
            <Grid container spacing={4} sx={{padding:7, paddingTop:2}}>
                <Grid item md={12} xs={12}>
                    <div className={styleClasses.inputField} style={{marginBottom:'15px', textAlign:"center", alignItems:"center"}}>
                        <div style={{width:250, margin:'auto', textAlign:"center", alignItems:"center"}}>
                            <Avatar className={styleClasses.customAvatar}
                                    src={`${BASEURL_RESOURCES}/${logic.childSel.person?.photo}`}
                                    sx={{width:56, height:56}}
                            />
                        </div>
                        <p style={{fontWeight:'bold', textAlign:"center"}}>{logic.childSel.person?.nom} {logic.childSel.person?.prenom}</p>
                    </div>
                </Grid>
                <Grid item md={8} xs={8}>
                    <Box sx={{width: '100%', textAlign: 'left'}}>
                        <IconButton
                            aria-label="back"
                            color="secondary"
                            onClick={handleGoOut}
                            sx={{marginRight: 2}}>
                            <ArrowBackIcon />
                        </IconButton>
                        <LoadingButton
                            loading={uploadingMsg}
                            component="label"
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<CloudUploadIcon/>}
                            aria-label={textBtnFileAttachedRef.current}
                            size="large"
                            color={"primary"}
                            sx={{textTransform: 'none', marginTop: 0.5, marginRight: {sm: 2}}}
                        >
                            {textBtnFileAttachedRef.current}
                            <input
                                id="file-upload"
                                type="file"
                                hidden={true}
                                multiple
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </LoadingButton>
                        <Button
                            component="label"
                            size="small"
                            color={"error"}
                            variant="contained"
                            onClick={handleDelete}
                            disabled={_.isEmpty(logic.photosIdsSelected)}
                            startIcon={<DeleteForeverIcon />}>
                            <FormattedMessage id={messages.doc_del_file} />
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </div>);
}

export default AlbumPhotoEditToolBar;