import React from 'react';
import classNames from "classnames";
import InputField from "../../components/ui/form/InputField";
import {InputAdornment, Typography} from "@mui/material";
import DialogForm from "../../components/ui/form/DialogForm";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import IconButton from "@mui/material/IconButton";

import MenuYearService from "../../service/MenuYearService";
import WeekService from "../../service/WeekService";

const weekService = WeekService();
const menuYearService = MenuYearService();

function AddToMenuDiaglogForm(props:any){
    const {
        theme,
        locale,
        classes,
        open,
        setOpen,
        handleSearch,
        searchTerm,
        data,
        width,
        onSubmit,
        handleToggle,
        checked,
        handleDeleteMenu,
        dataMenuJourList,
        dataList
    } = props;
    const activeLanguage = locale.split('-');
    const placeholder = activeLanguage[0] === 'en' ? 'Search a dish' : 'Recherche de plat';

    return (
        <DialogForm title={<FormattedMessage id={messages.add_to_menu_year} />} open={open} setOpen={setOpen} theme={theme} width={width} onSubmit={onSubmit}>
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <InputField
                    placeholder={placeholder}
                    size="small"
                    color={"secondary"}
                    value={searchTerm}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{ p:0, m:0 }}><SearchIcon sx={{fontSize:'1.8rem'}} /></InputAdornment>,
                    }}
                    onChange={(a:any) => handleSearch(a)}
                />
            </div>
            <Box sx={{textAlign: 'right', marginBottom: 2}}><FormattedMessage id={messages.total_menu_year} /> : {data.length}</Box>

            <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
                {data.map((menu:any, index:number) => {
                    const labelId = `checkbox-list-label-${index}`;
                    const menuDayStatus = menuYearService.checkMenuDay(menu, dataMenuJourList, dataList);
                    return (
                        <ListItem
                            key={menu.id}
                            disablePadding
                            sx={{border:'1px solid #cecece', mb:2, borderRadius:2, overflow:"auto"}}
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(menu)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(menu) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <div style={{display:'flex', width:'90%'}}>
                                    <Box className={classes.toMenuBoxImg}>
                                        <img
                                            className={classes.imgItem}
                                            loading="lazy"
                                            src={menu.photo}
                                        />
                                    </Box>
                                    <div className={classes.toMenuContentCanteen}>
                                        <Typography>{menu.nom}</Typography>
                                        <div style={{marginTop:10}}>
                                            <i><FormattedMessage id={messages.starter_menu_year} /> :</i> {menu.starterDish}<br /><br />
                                            <i><FormattedMessage id={messages.dish_menu_year} /> :</i> {menu.dish}<br /><br />
                                            <i><FormattedMessage id={messages.dessert_menu_year} /> :</i> {menu.dessert}
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'5%'}}>
                                    <Box sx={{marginTop:{xs:0, sm:0, flex: 1}}}>
                                        <IconButton color={"primary"}  onClick={() => handleDeleteMenu(menu.id)}>
                                            <DeleteIcon sx={{fontSize:'1.5rem'}} />
                                        </IconButton>
                                        {menuDayStatus && (
                                            <DoneAllIcon sx={{color: 'green', marginTop:10, marginLeft: 1, fontSize:20}} />
                                        )}
                                    </Box>
                                </div>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>

        </DialogForm>
    )
}

export default AddToMenuDiaglogForm;
