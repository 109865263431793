import {ScolariteProforma} from "../../../../constants/type/ScolariteProforma";
import {ScolariteProformaEnfant} from "../../../../constants/type/ScolariteProformaEnfant";


const proformaStateUpdate:any = {
    proformaDataList: (logic:any, val:ScolariteProforma[]) => logic.setProformaDataList(val),
    dataList: (logic:any, val:ScolariteProformaEnfant[]) => logic.setDataList(val),
    idScolariteAnneeSel: (logic:any, val:number[]) => logic.setIdScolariteAnneeSel(val),
    dataListSelected: (logic:any, val:ScolariteProformaEnfant[]) => logic.setDataListSelected(val),
    dataSel: (logic:any, val:ScolariteProforma) => logic.setDataSel(val),
    proformaEnfantSel: (logic:any, val:ScolariteProformaEnfant) => logic.setProformaEnfantSel(val),
    editDisableRef: (logic:any, val:boolean) => logic.editDisableRef.current = val,
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openPDFDialogForm: (logic:any, val:boolean) => logic.setOpenPDFDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    processingLoadingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingLoadingStateNormBtn(val),
    processingSubmitStateNormBtn: (logic:any, val:boolean) => logic.setProcessingSubmitStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    showDetailsTableContent: (logic:any, val:boolean) => logic.setShowDetailsTableContent(val),
    contentLoaded: (logic:any, val:boolean) => logic.setContentLoaded(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    tableDetailsKeyValue: (logic:any, val:any) => logic.setTableDetailsKeyValue(val),

}

export default proformaStateUpdate ;