import React from 'react';
import DialogForm from "../../../../components/ui/form/DialogForm";
import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import classNames from "classnames";

function FolderTypeForm(props:any) {
    const {
        theme,
        title,
        open,
        setOpen,
        classes,
        onSubmit,
        register,
        subFolderTypeFile,
        errors,
        activeLanguage,
        subFolderFileChecked,
        stateFolderType
    } = props;
    const requiredFiles = activeLanguage[0] === 'en' ? 'Folder files type is required' : 'Fichier(s) du type de dossier requis';

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.folder_type}/>}
                    size="small"
                    disabled={!stateFolderType}
                    color={"secondary"}
                    {...register("folderName", {
                        required: "Folder name is required"
                    })}
                />
            </div>

            <Grid container spacing={0}>
                <Grid item md={12} xs={12} sx={{marginTop:2, marginBottom: { xs:1, sm:1 } }}>
                    <Typography sx={{p:0, m:0}} variant={"subtitle1"} component="h6">
                        <FormattedMessage id={messages.dialog_folder_type_file} />
                    </Typography>
                </Grid>
                {
                    subFolderTypeFile.map((type:any) => {
                        if(subFolderFileChecked.includes(type.id)){
                            return (
                                <Grid item md={5} xs={12} sx={{p:0, m:0, mb:1}} key={type.id}>
                                    <FormControlLabel
                                        sx={{p:0, m:0}}
                                        control={<Checkbox color={"secondary"} sx={{p:0, m:0}} value={type.id} defaultChecked {...register("filesType", {
                                            required: `${requiredFiles}`
                                        })} />}
                                        label={<Typography variant={"subtitle2"} sx={{ fontWeight:'normal'}}>{type.fileName}</Typography>}
                                    />
                                </Grid>
                            );
                        }
                        else {
                            return (
                                <Grid item md={5} xs={12} sx={{p:0, m:0, mb:1}} key={type.id}>
                                    <FormControlLabel
                                        sx={{p:0, m:0}}
                                        control={<Checkbox color={"secondary"} sx={{p:0, m:0}} value={type.id} {...register("filesType", {
                                            required: `${requiredFiles}`
                                        })} />}
                                        label={<Typography variant={"subtitle2"} sx={{ fontWeight:'normal'}}>{type.fileName}</Typography>}
                                    />
                                </Grid>
                            )
                        }
                    })
                }
                <Grid item md={12} xs={12}>
                    <div className={classes.errorHelper}>
                        {errors.filesType?.message}
                    </div>
                </Grid>
            </Grid>
        </DialogForm>
    );
}

export default FolderTypeForm;
