import React from 'react';
import {Route, Routes} from 'react-router-dom';
import '../App.css';
import Layout from '../components/layout/Layout';
import Dashboard from "../page/Dashboard";
import AppointmentCalendar from "../page/appointment/AppointmentCalendar";
import MessageCenter from "../page/messages/center/MessageCenter";
import SchoolPeriod from "../page/config/periode/SchoolPeriod";
import SchoolDay from "../page/config/SchoolDay";
import PersonalType from "../page/config/job/PersonalType";
import SchoolDocumentType from "../page/config/document/SchoolDocumentType";
import Personal from "../page/personal/Personal";
import CanteenWeek from "../page/config/canteenweek/CanteenWeek";
import MenuYear from "../page/canteen/MenuYear";

import Dish from "../page/config/dish/Dish";
import ChildDayAtIms from "../page/child/ChildDayAtIms";
import Classroom from "../page/config/classroom/Classroom";
import AppointmentType from "../page/config/appointment/AppointmentType";
import ScolaritePaymentPeriod from "../page/config/scolarite/payment/ScolaritePaymentPeriod";
import Scolarite from "../page/config/scolarite/schooling/Scolarite";
import SchoolFees from "../page/config/scolarite/additonalfees/SchoolFees";
import SubFolderType from "../page/config/folder/subfolder/SubFolderType";
import FolderType from "../page/config/folder/foldertype/FolderType";
import ImageRights from "../page/config/imageright/ImageRights";
import Appointment from '../page/appointment/Appointment';
import ChildStuffPage from "../page/config/childstuff/ChildStuffPage";
import SchoolDocument from "../page/schooldocument/SchoolDocument";
import Bibliotheque from "../page/bibliotheque/livre/Bibliotheque";
import Emprunt from "../page/bibliotheque/listedesemprunts/Emprunt";
import AlbumPhoto from '../page/photo/AlbumPhoto';

/*
import ScolariteListeFrais from "../page/scolarite/listedesfrais/ScolariteListeFrais";
import ScolariteEcheancier from '../page/scolarite/echeancier/ScolariteEcheancier';
import FraisOneShotEnfant from "../page/scolarite/fraisoneshotenfant/FraisOneShotEnfant";
import SchoolingPayment from '../page/scolarite/paiement/SchoolingPayment';
import SchoolingPaymentList from "../page/scolarite/paiement/SchoolingPaymentList";
import Facture from '../page/scolarite/proforma/Proforma';

import ScolariteEcheancierEnfant from "../page/scolarite/echeancierenfant/ScolariteEcheancierEnfant";
import GroupEnfantPage from "../page/scolarite/groupenfant/GroupEnfantPage";
import Proforma from "../page/scolarite/proforma/Proforma";
*/
import AlertMessageNotification from '../page/alertmessage/AlertMessageNotification';

import MiniClub from "../page/miniclub/MiniClub";
import EditMiniClub from "../page/miniclub/EditMiniClub";
import UserProfile from "../page/profil/UserProfile";
import SondageCenter from "../page/sondage/SondageCenter";
import SecuriteParent from "../page/securite/parent/SecuriteParent";
import SecuriteEmployee from "../page/securite/employee/SecuriteEmployee";
import Student from "../page/child/Student";
import AddNewChild from "../page/child/AddNewChild";
import EditChild from "../page/child/EditChild";
import MesNotifications from '../page/notification/MesNotifications';

import Marketing from "../page/marketing/Marketing";
import EditProduct from "../page/marketing/EditProduct";
import Emprunter from "../page/bibliotheque/emprunter/Emprunter";
import CategoreLivrePage from "../page/config/categorielivre/CategoreLivrePage";
import GroupPage from '../page/scolarite/group/GroupPage';
import GroupEnfantPage from "../page/scolarite/groupenfant/GroupEnfantPage";
import ScolariteFraisGroup from "../page/scolarite/montantdesfrais/ScolariteFraisGroup";
import ScolariteEcheancier from '../page/scolarite/echeancier/ScolariteEcheancier';
import ScolariteEcheancierEnfant from '../page/scolarite/echeancierenfant/ScolariteEcheancierEnfant';
import Proforma from "../page/scolarite/proforma/Proforma";
import FraisOneShotEnfant from "../page/scolarite/fraisoneshotenfant/FraisOneShotEnfant";
import SchoolingPayment from "../page/scolarite/paiement/SchoolingPayment";
import SchoolingPaymentList from "../page/scolarite/paiement/SchoolingPaymentList";
import ScolariteListeFrais from "../page/scolarite/listedesfrais/ScolariteListeFrais";
import MatchingFraisOneShotEnfant from "../page/scolarite/matchingfraisoneshotenfant/MatchingFraisOneShotEnfant";

function Application(props:any) {
    const {theme, locale,  setLocale} = props;

    return (
        <Layout theme={theme} locale={locale}  setLocale={setLocale}>
            <Routes>
                <Route
                    path="/app/dashboard"
                    element={<Dashboard theme={theme} locale={locale}/>}
                />
                <Route path="/app/config/period" element={<SchoolPeriod theme={theme} locale={locale} />} />
                <Route path="/app/config/schoolday" element={<SchoolDay theme={theme} locale={locale} />} />
                <Route path="/app/config/canteenweek" element={<CanteenWeek theme={theme} locale={locale} />} />
                <Route path="/app/config/categorielivre" element={<CategoreLivrePage theme={theme} locale={locale} />} />
                <Route path="/app/config/childstuff" element={<ChildStuffPage theme={theme} locale={locale} />} />
                <Route path="/app/config/personal" element={<PersonalType theme={theme} locale={locale} />} />
                <Route path="/app/config/schooldocumentype" element={<SchoolDocumentType theme={theme} locale={locale} />} />
                {/*<Route path="/app/config/inscriptionfiletype" element={<InscriptionFile theme={theme} locale={locale} />} />*/}
                <Route path="/app/config/folder/subfoldertype" element={<SubFolderType theme={theme} locale={locale} />} />
                <Route path="/app/config/folder/foldertype" element={<FolderType theme={theme} locale={locale} />} />
                <Route path="/app/config/imagerights" element={<ImageRights theme={theme} locale={locale} />} />


                <Route path="/app/config/dish" element={<Dish theme={theme} locale={locale} />} />
                <Route path="/app/config/classroom" element={<Classroom theme={theme} locale={locale} />} />
                <Route path="/app/config/appointment" element={<AppointmentType theme={theme} locale={locale} />} />
                <Route path="/app/config/schooling" element={<Scolarite theme={theme} locale={locale} />} />
                <Route path="/app/config/schooling/schoolfees" element={<SchoolFees theme={theme} locale={locale} />} />
                <Route path="/app/config/schooling/paymentperiod" element={<ScolaritePaymentPeriod theme={theme} locale={locale} />} />

                <Route path="/app/appointment/calendar" element={<AppointmentCalendar theme={theme} locale={locale} />} />
                <Route path="/app/appointment/preset" element={<Appointment theme={theme} locale={locale} />} />
                {/*<Route path="/app/appointment/list" element={<AppointmentList theme={theme} locale={locale} />} />*/}
                <Route path="/app/message/list" element={<MessageCenter theme={theme} locale={locale} />} />
                <Route path="/app/sondage/question" element={<SondageCenter theme={theme} locale={locale} />} />
                <Route path="/app/alert/message" element={<AlertMessageNotification theme={theme} locale={locale} />} />
                <Route path="/app/personal/list" element={<Personal theme={theme} locale={locale} />} />
                {/*<Route path="/app/child/list" element={<Children theme={theme} locale={locale} />} />*/}
                <Route path="/app/child/list" element={<Student theme={theme} locale={locale} />} />
                <Route path="/app/child/add" element={<AddNewChild theme={theme} locale={locale} />} />
                <Route path="/app/child/edit" element={<EditChild theme={theme} locale={locale} />} />
                {/*
                <Route path="/app/schooling/scolaritefacture" element={<Facture theme={theme} locale={locale} />} />
                <Route path="/app/schooling/scolaritelistfrais" element={<ScolariteListeFrais theme={theme} locale={locale} />} />
                <Route path="/app/schooling/scolaritedesfrais" element={<ScolariteFraisGroup theme={theme} locale={locale} />} />

                */}
                <Route path="/app/schooling/schoolfees" element={<ScolariteListeFrais theme={theme} locale={locale} />} />
                <Route path="/app/schooling/payment-list" element={<SchoolingPaymentList theme={theme} locale={locale} />} />
                <Route path="/app/schooling/payment" element={<SchoolingPayment theme={theme} locale={locale} />} />
                <Route path="/app/schooling/matchingfraisoneshotenfant" element={<MatchingFraisOneShotEnfant theme={theme} locale={locale} />} />
                <Route path="/app/schooling/fraisoneshotenfant" element={<FraisOneShotEnfant theme={theme} locale={locale} />} />
                <Route path="/app/schooling/proforma" element={<Proforma theme={theme} locale={locale} />} />
                <Route path="/app/schooling/scolaritecheancierenfant" element={<ScolariteEcheancierEnfant theme={theme} locale={locale} />} />
                <Route path="/app/schooling/groups" element={<GroupPage theme={theme} locale={locale} />} />
                <Route path="/app/schooling/groupsenfants" element={<GroupEnfantPage theme={theme} locale={locale} />} />
                <Route path="/app/schooling/scolaritecheancier" element={<ScolariteEcheancier theme={theme} locale={locale} />} />
                <Route path="/app/schooling/scolaritedesfrais" element={<ScolariteFraisGroup theme={theme} locale={locale} />} />
                    <Route path="/app/child/child-day" element={<ChildDayAtIms theme={theme} locale={locale} />} />
                {/*<Route path="/app/child/day-list" element={<ChildDayList theme={theme} locale={locale} />} />*/}

                <Route path="/app/year-menu/list" element={<MenuYear theme={theme} locale={locale} />} />

                <Route path="/app/school-document/list" element={<SchoolDocument theme={theme} locale={locale} />} />

                <Route path="/app/bibliotheque/list" element={<Bibliotheque theme={theme} locale={locale} />} />
                <Route path="/app/bibliotheque/empruntparemployer" element={<Emprunter theme={theme} locale={locale} />} />
                <Route path="/app/bibliotheque/emprunt" element={<Emprunt theme={theme} locale={locale} />} />
                <Route path="/app/photo/list" element={<AlbumPhoto theme={theme} locale={locale} />} />

                <Route path="/app/mini-club/list" element={<MiniClub theme={theme} locale={locale} />} />
                <Route path="/app/mini-club/edit" element={<EditMiniClub theme={theme} locale={locale} />} />

                <Route path="/app/profile" element={<UserProfile theme={theme} locale={locale} />} />

                <Route path="/app/security/parent" element={<SecuriteParent theme={theme} locale={locale} />} />
                <Route path="/app/security/employee" element={<SecuriteEmployee theme={theme} locale={locale} />} />
                <Route path="/app/notification/mesnotifs" element={<MesNotifications theme={theme} locale={locale} />} />

                <Route path="/app/products/list" element={<Marketing theme={theme} locale={locale} />} />
                <Route path="/app/products/edit" element={<EditProduct theme={theme} locale={locale} />} />
            </Routes>
        </Layout>
    );
}

export default Application;
