import React from "react";
import {Autocomplete, Avatar, Box, TextField} from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import FormControl from "@mui/material/FormControl";
import DialogForm from "../../components/ui/form/DialogForm";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";

function AddNewMenuDialogForm(props:any) {
    const {
        theme,
        locale,
        title,
        classes,
        open,
        setOpen,
        dialogTitle,
        fileDataURL,
        handleFileChange,
        dataStaterDish,
        handleStarterDishChange,
        placeholderStarterDish,
        dataDish,
        handleDishChange,
        placeholderDish,
        dataDessertDish,
        handleDessertDishChange,
        placeholderDessert,
        onSubmit,
        dishName,
        handleDishNameChange,
        starterDish,
        dish,
        dessertDish
    } = props;

    const [inputValue, setInputValue] = React.useState('');
    //console.log(dataStaterDish[0])

    return (
        <DialogForm title={title} open={open} setOpen={setOpen} theme={theme} onSubmit={onSubmit}>

            <div className={classes.dialogAvatar}>
                {
                    fileDataURL ? (
                        <Avatar src={fileDataURL ? fileDataURL : ''} sx={{width:140, height:140}} />
                    ) : (
                        <Avatar sx={{width:140, height:140}}><InsertPhotoIcon fontSize={"large"} /></Avatar>
                    )
                }
                <div className={classes.iconButtonLabel}>
                    <label htmlFor="file-upload">
                        <div className={classes.iconButton} style={{textAlign:"center"}}>
                            <EditIcon />
                        </div>
                        <input
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            hidden={true}
                            onChange={(a:any) => handleFileChange(a)}
                        />
                    </label>
                </div>
            </div>


            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.title_dish_config}/>}
                    value={dishName}
                    onChange={handleDishNameChange}
                    size="small"
                    color={"secondary"}
                    sx={{ marginTop:0.4, width:'100% !important' }}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <Autocomplete
                    value={starterDish}
                    options={dataStaterDish}
                    autoHighlight
                    getOptionLabel={(option:any) => option.nom}
                    loading={false}
                    onChange={(a:any, b:any) => handleStarterDishChange(b) }
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ fontWeight:400  }} {...props}>
                            {option.nom}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="secondary"
                            variant="outlined"
                            label={placeholderStarterDish}
                            size="small"
                            placeholder={placeholderStarterDish}
                            sx={{ marginTop:0.4, width:'100% !important' }}
                        />
                    )}
                />

            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <Autocomplete
                    value={dish}
                    options={dataDish}
                    autoHighlight
                    getOptionLabel={(option:any) => option.nom}
                    loading={false}
                    onChange={(a:any, b:any) => handleDishChange(b) }
                    renderOption={(props, option:any) => (
                        <Box component="li" sx={{ fontWeight:400  }} {...props}>
                            {option.nom}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="secondary"
                            variant="outlined"
                            label={placeholderDish}
                            size="small"
                            placeholder={placeholderDish}
                            sx={{ marginTop:0.4, width:'100% !important' }}
                        />
                    )}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <FormControl sx={{
                    minWidth: 280,
                    maxWidth: '100%',
                    width:"100%"
                }}>
                    <Autocomplete
                        value={dessertDish }
                        options={dataDessertDish}
                        autoHighlight
                        getOptionLabel={(option) => option.nom}
                        loading={false}
                        onChange={(a:any, b:any) => handleDessertDishChange(b) }
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ fontWeight:400  }} {...props}>
                                {option.nom}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                color="secondary"
                                variant="outlined"
                                label={placeholderDessert}
                                size="small"
                                placeholder={placeholderDessert}
                                sx={{ marginTop:0.4, width:'100% !important' }}
                                // inputProps={{
                                //     ...params.inputProps,
                                //     autoComplete: 'new-password',
                                // }}
                            />
                        )}
                    />
                    {/*<AutoCompleteInput
                        label={true}
                        data={dataDessertDish}
                        onOptionChange={handleDessertDishChange}
                        placeholderText={placeholderDessert}
                    />*/}
                </FormControl>
            </div>
        </DialogForm>
    )
}

export default AddNewMenuDialogForm;