import React from 'react';
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import ScolariteListeFraisEditForm from "./ScolariteListeFraisEditForm";
import ScolariteListeFraisList from './ScolariteListeFraisList';
import ScolariteAutresfraisDeleteForm from "./ScolariteListeFraisDeleteForm";
import {scolariteTypeDataImpl, scolariteTypeSchema} from '../../../constants/type/ScolariteType';
import useScolariteTypeVariables from './businesslogic/ScolariteFraisVariables';
import scolariteFraisBusinessLogic from "./businesslogic/ScolariteFraisBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(scolariteTypeDataImpl),
        // @ts-ignore
        resolver: yupResolver(scolariteTypeSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useScolariteTypeVariables({styleClasses, ...props})
    }
};
export const ScolariteAutresFraisContext = React.createContext({});
function ScolariteListeFrais(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const classes = logic.styleClasses;

    React.useEffect(() => {
        moment.locale(logic.activeLang);
        scolariteFraisBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);



    return (
        <div className={classes.root}>
           {/* <Box className={classes.buttonContainer}>
                <SchoolingSubMenu classes={classes} selected="scolaritelistfrais"/>
            </Box>
*/}

            <ScolariteAutresFraisContext.Provider value={logic}>
                <ScolariteListeFraisList />
            </ScolariteAutresFraisContext.Provider>
            <ScolariteAutresFraisContext.Provider value={logic}>
                <ScolariteListeFraisEditForm />
            </ScolariteAutresFraisContext.Provider>
            <ScolariteAutresFraisContext.Provider value={logic}>
                <ScolariteAutresfraisDeleteForm />
            </ScolariteAutresFraisContext.Provider>
        </div>
    );
}

export default withSnackbar(ScolariteListeFrais);
