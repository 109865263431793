import React from 'react';
import DialogForm from "../../../components/ui/form/DialogForm";
import {TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";

function AppointmentTypeForm(props:any) {
    const { theme, title, open, setOpen, classes, onSubmit, register, } = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classes.inputField}>
                <TextField
                    label={<FormattedMessage id={messages.input_appoint_type}/>}
                    size="small"
                    color={"secondary"}
                    {...register("appointmentName", {
                        required: "Appointment name is required"
                    })}
                />
            </div>
        </DialogForm>
    );
}

export default AppointmentTypeForm;
