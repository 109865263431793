import React, {useContext} from "react";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import scolariteFraisGroupBusinessLogic from "./businesslogic/ScolariteFraisGroupBusinessLogic";
import {ScolariteToutFraisContext} from "./ScolariteFraisGroup";
import scolariteFraisGroupStateUpdate from "./businesslogic/ScolariteFraisGroupStateUpdate";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ScolariteAutresfraisDeleteForm = (props:any) => {
    const logic: any = useContext(ScolariteToutFraisContext);
    const styleClasses = logic.styleClasses ;


    const onSubmit = async () => {
        scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, true);
        scolariteFraisGroupBusinessLogic.deleteDatas(
            logic,
            messages,
            logic.dataListSelected).then((response:any) => {
            scolariteFraisGroupStateUpdate.openDeleteDialogForm(logic, false);
            scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false));
    };

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_delete} />}
            open={logic.openDeleteDialogForm}
            setOpen={logic.setOpenDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={() => onSubmit()}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.selection_to_delete} />
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default ScolariteAutresfraisDeleteForm ;