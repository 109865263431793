import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import messages from "../messages/general/messages";
import sondageStateUpdate from "./businessLogic/SondageStateUpdate";
import sondageBusinessLogic from "./businessLogic/SondageBusinessLogic";
import {Sondage, SONDAGE_CONSTANT, sondageDataImpl, sondageSchema} from "../../constants/type/Sondage";
import {ClasseRoom} from "../../constants/type/ClasseRoom";
import DialogFormKiller from "../../components/ui/form/DialogFormKiller";
import InputField from "../../components/ui/form/InputField";
import alertMessageStateUpdate from "../alertmessage/businesslogic/AlertMessageStateUpdate";
import {SondageCenterContext} from "./SondageCenter";
import InputLabel from "@mui/material/InputLabel";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FactureEditForm = (props:any) => {
    const logic: any = useContext(SondageCenterContext);
    const styleClasses = logic.chatStyles ;


    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(sondageSchema),
    }));
    const {errors} = formRef.current.formState;
    const handleOnClasseChange = (event: any) => {
        const data:Sondage = _.cloneDeep(logic.dataSel);
        data.classesIdForm = [] ;
        event.target.value.forEach((id:string) => {
            const classeRoom : ClasseRoom = logic.classeRoomMapRef.current.get(_.toNumber(id));
            if(_.isObject(classeRoom) === false){
                return ;
            }
            // @ts-ignore
            data.classesIdForm.push(classeRoom.id);
        });
        //data.classes = _.join(data.classesIdForm, ',') ;
        sondageStateUpdate.dataSel(logic, data);
        formRef.current.setValue('classesIdForm', data.classesIdForm);
    };

    const handleOnObligationCheck = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.terminer = !data.terminer ;
        sondageStateUpdate.dataSel(logic, data);
        formRef.current.setValue('terminer', data.terminer);
    };

    const onQuestionChange = (event: any) => {
        const data:Sondage = _.cloneDeep(logic.dataSel);
        data.question = event.target.value ;
        alertMessageStateUpdate.dataSel(logic, data);
        formRef.current.setValue('question', data.question);
    };
    const onSubmit = async (data: FormData) => {
        sondageStateUpdate.processingSubmitStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: Sondage = _.cloneDeep(data);

        const toSend = SONDAGE_CONSTANT.convertToBackEnd(logic, dataToSend);

        let formData:any = new FormData();
        if(_.isNull(logic.file) !== false){
            formData.append("photo", logic.file);
        }
        formData.append('model', new Blob([JSON.stringify(toSend)], {
            type: "application/json"
        }));
        sondageBusinessLogic.saveEdit(
            logic,
            messages,
            formData,
            toSend.id).then((response:any) => {
            sondageStateUpdate.openEditDialogForm(logic, false);
            sondageStateUpdate.processingSubmitStateNormBtn(logic, false);
            sondageStateUpdate.tableKeyValue(logic, moment().valueOf()) ;
        }).catch((e:any) => sondageStateUpdate.processingSubmitStateNormBtn(logic, false));
    };

    React.useEffect(() => {
       /* if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(sondageDataImpl);
            sondageStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }*/
        const data:Sondage = _.cloneDeep(logic.dataSel);
        if(_.isNull(logic.dataSel.id)){
            data.classesIdForm = [] ;
            logic.classeRoomRef.current.forEach((item:ClasseRoom) => {
                // @ts-ignore
                data.classesIdForm.push(item.id);
            });
        }
        formRef.current.reset(_.cloneDeep(data));
        sondageStateUpdate.dataSel(logic, data);
        return () => {
            formRef.current.reset(_.cloneDeep(sondageDataImpl));
        };
    }, [logic.openEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingSubmitStateNormBtn}
            title={<FormattedMessage id={messages.selection_edition} />}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
                <Box  sx={{ width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <FormControl
                                sx={{
                                    display: { xs: 'block', sm: 'inline-block'},
                                    marginBottom: { xs: 2},
                                    marginRight: { sm: 2 },
                                    width:'100%',
                                }}
                            >
                                <InputLabel id="send_to">{<FormattedMessage id={messages.classroom_child} />}</InputLabel>
                                <Select
                                    multiple
                                    sx={{width: {xs: '100% !important'},}}
                                    color={"secondary"}
                                    value={logic.dataSel.classesIdForm}
                                    onChange={handleOnClasseChange}
                                    input={<OutlinedInput label={<FormattedMessage id={messages.classroom_child}/>}/>}
                                    renderValue={(selected: any) => (
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                            {selected.map((value: any) => (
                                                <Chip key={value} label={logic.classeRoomMapRef.current.get(value)?.nom ?? ``}/>
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                    {logic.classeRoomRef.current?.map((opt: any) => (
                                        <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                            <Checkbox checked={logic.dataSel.classesIdForm?.includes(opt.id)}/>
                                            <ListItemText primary={opt.nom}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.libelle_title} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.dataSel.nom}
                                    inputProps={{
                                        ...formRef.current.register("nom")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        {
                            _.isNumber(logic.dataSel.id) &&
                            <Grid item md={12} xs={12}>
                                <div className={styleClasses.inputField}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch checked={logic.dataSel.terminer} onChange={handleOnObligationCheck} />}
                                            label={<FormattedMessage id={messages.close_sondage} />} />
                                    </FormGroup>
                                </div>
                            </Grid>
                        }


                        <Grid item md={12} xs={12}>
                            <InputField
                                label="Question"
                                InputProps={{
                                    rows: 3,
                                    multiline: true,
                                    inputComponent: 'textarea',
                                }}
                                variant="filled"
                                value={logic.dataSel.question}
                                onChange={onQuestionChange}
                            />
                            <div className={styleClasses.errorHelper}>
                                {errors.question && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                    </Grid>
                </Box>
        </DialogFormKiller>
    );
}

export default FactureEditForm ;