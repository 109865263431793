import themePalette from './themePalette';
//imsTheme
const applicationTheme = (color:any = 'imsThemeBlue', mode:any = 'light') => ({
    palette: {
        primary: themePalette(color, mode).palette.primary,
        secondary: themePalette(color, mode).palette.secondary,
        action: {
            hover:  'rgba(80,80,80, 0.05)',
            hoverOpacity: 0.05,
        },
    },
    typography: {
        fontFamily: ['Roboto', 'Quicksand', 'Maven Pro', 'sans-serif'].join(','),
        body1: {
            fontWeight: 700,
        },
        body2: {
            fontWeight: 400,
        },
        fontWeightMedium: 700,
    },
    shade: {
        light: '0 10px 15px -2px rgba(62, 57, 107, .07)',
    },
    glow: {
        light: `0 2px 20px -5px ${themePalette(color, mode).palette.primary.main}`,
        medium: `0 2px 40px -5px ${themePalette(color, mode).palette.primary.main}`,
        dark: `0 2px 40px 0px ${themePalette(color, mode).palette.primary.main}`,
    },
    rounded: {
        small: '8px',
        medium: '12px',
        big: '20px',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor:
                        mode === 'dark'
                            ? '#292929'
                            : '#FFFFFF'
                },
                rounded: {
                    borderRadius: 8,
                },
                elevation1: {
                    boxShadow:
                        mode === 'dark'
                            ? '0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)'
                            : '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
                },
                elevation4: {
                    boxShadow:
                        mode === 'dark'
                            ? '0px 2px 4px -1px rgba(64, 64, 64, 0.46), 0px 4px 5px 0px rgba(42, 42, 42, 0.32), 0px 1px 10px 0px rgba(20, 20, 20, 0.12)'
                            : '0px 2px 4px -1px rgba(142, 142, 142, 0.2), 0px 4px 5px 0px rgba(243, 243, 243, 0.14), 0px 1px 10px 0px rgba(204, 204, 204, 0.12)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                },
                root: {
                    borderRadius: 8,
                    fontWeight: 600,
                },
                sizeSmall: {
                    padding: '7px 12px',
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                button: {
                    fontWeight: 600,
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    padding: '24px 8px',
                    //top: -3,
                    '& $endAdornment': {
                        paddingTop: 0
                    }
                },
                tag: {
                    padding: '0 !important'
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    border:
                        mode === 'dark'
                            ? '1px solid rgba(255,255,255,0.32)'
                            : '1px solid rgba(0,0,0,0.32)',
                    borderRadius: 8,
                    overflow: 'hidden',
                    alignItems: 'center',
                    transition: 'border 0.3s ease',
                },
                underline: {
                    '&:after': {
                        height: 'calc(100% + 1px)',
                        borderRadius: 8,
                        bottom: -1,
                        boxShadow: `0 0 1px ${themePalette(color, mode).palette.primary.main}`,
                    },
                    '&:before': {
                        display: 'none'
                    }
                },
                input: {
                    padding: 8,
                    fontSize: 14,
                },
                multiline: {
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 22,
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                formControl: {
                    //top: 12,
                    //left: 10,
                    //transform: 'translate(0, 22px) scale(1)',
                },
                /*shrink: {
                    transform: 'translate(0, 13px) scale(0.7)',
                    zIndex: 1,
                },*/
                /*filled: {
                    transform: 'translate(2px, 6px) scale(1)',
                    '&$shrink': {
                        transform: 'translate(0px, -6px) scale(0.75)',
                    },
                },*/
                outlined: {
                    //fontSize: 14,
                    fontWeight: 500,
                    '&$shrink': {
                    },
                },
                root: {
                    '& + div': {
                        '& .MuiSelect-root': {
                            paddingBottom: 0,
                        }
                    },
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    paddingLeft: 5,
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
                icon: {
                    top: 'calc(50% - 10px)',
                    right: 0,
                },
                selectMenu: {
                    paddingRight: '24px',
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root':{
                        '&:hover fieldset': {
                            borderColor: '#c4c4c4',
                        },
                        //height: 50,
                        '& .MuiOutlinedInput-input': {
                            padding: '9.5px 14px',
                        }
                    },
                    '& label + div': {
                        alignItems: 'flex-end',
                        '&[role="radiogroup"]': {
                            alignItems: 'flex-start',
                        },
                        paddingBottom: 4,
                        '& input, > .MuiInputAdornment-positionStart, > select': {
                            fontWeight: 500,
                            fontSize: '0.9em',
                        },
                    },
                },
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-end',
                    paddingLeft: 7,
                    paddingRight: 0,
                    height: 'auto !important',
                    '& button': {
                        width: 'auto',
                    },
                },
                positionStart: {
                },
                positionEnd: {
                },
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingLeft:5,
                    borderBottom:
                        mode === 'dark'
                            ? '0px solid #636363'
                            : `0px solid ${themePalette(color, mode).palette.primary.light}`,
                    '& h6':{
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: 'center'
                    }
                },
                head: {
                    fontWeight: 600,
                },
            }
        },
        MuiTableRow:{
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 400,
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
                bar: {
                    borderRadius: 16,
                },
                colorPrimary: {
                    backgroundColor: '#ededed',
                },
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding:'0px',
                    paddingLeft:'0px',
                },
                toolbar: {
                    margin: 0,
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#ffffff',
                    }
                },
                input: {
                    marginRight: 0,
                    marginLeft: 0,
                },
                selectRoot: {
                    marginLeft: 0,
                    marginRight: 0,
                },
                select: {
                    //paddingRight: 24,
                    fontWeight: 400,
                },
                /*selectIcon: {
                    top: 4,
                },*/
                menuItem: {
                    fontWeight: 400,
                },
                spacer: {}
            },

        },
        MuiPickersToolbar: {
            styleOverrides: {
                toolbar: {
                    borderRadius: 0,
                    boxShadow: 'inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)',
                },
            }
        },
        MuiPickersClock: {
            styleOverrides: {
                clock: {
                    backgroundColor: 'none',
                    border: `1px solid ${themePalette(color, mode).palette.primary.main}`,
                },
            }
        },
        MuiPickersClockPointer: {
            styleOverrides: {
                thumb: {
                    boxShadow: `0 1px 10px 0px ${
                        themePalette(color, mode).palette.primary.main
                    }`,
                },
            }
        },
        MuiPickerDTTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: 'transparent',
                    color: themePalette(color, mode).palette.primary.main,
                },
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&:first-child': {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    },
                    '&:last-child': {
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                    },
                    '&$expanded': {
                        borderRadius: 8,
                        boxShadow: `0px 0px 0px 1px ${themePalette(color, mode).palette.primary.main}`,
                        '& + div': {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                        },
                    },
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    //position: 'relative',
                    marginBottom: 32,
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        width: 60,
                        height: 4,
                        background: themePalette(color, mode).palette.primary.main,
                        bottom: 0,
                        left: 26,
                    },
                    '& h2': {
                        color:
                            mode === 'dark'
                                ? themePalette(color, mode).palette.primary.light
                                : themePalette(color, mode).palette.primary.dark,
                    },
                },
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    '@media (min-width: 960px)': {
                        borderRadius: 8,
                    },
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
                colorPrimary: {
                    backgroundColor:
                        mode === 'dark'
                            ? themePalette(color, mode).palette.primary.dark
                            : themePalette(color, mode).palette.primary.light,
                    //'#FFF',
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
                indicator: {
                    borderRadius: '10px 10px 0 0',
                    height: 4,
                },
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    overflow: 'hidden',
                    borderRadius: 8,
                    boxShadow: 'none',
                    border: `1px solid ${themePalette(color, mode).palette.secondary.main}`,
                },
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    height: 32,
                    boxShadow: 'none !important',
                    '&$selected': {
                        backgroundColor: mode === 'dark' ? themePalette(color, mode).palette.primary.dark : themePalette(color, mode).palette.primary.light
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                deleteIcon: {
                    margin: '0 4px 0 -8px'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
            }
        },
        /*OPACITY BACKGROUND DIALOG BOX*/
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    //backgroundColor: "rgba(225,225,225,0.7)",
                    backgroundColor: "rgba(223,222,224,0.1)",
                },
            },
        },
        /*MUI DATA GRID FOR TEST*/
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    width:'100%',
                    height:'100%'
                    //backgroundColor: 'red',
                },
            },
        },
        /*MuiDialogpaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#eee'
                },
            },
        },
        */

        /*MUIDataTableToolbar: {
            filterPaper: {
                maxWidth: 'none'
            }
        },
        MUIDataTableToolbarSelect: {
            root: {
                boxShadow: 'none',
                backgroundColor:
                    mode === 'dark'
                        ? themePalette(color, mode).palette.secondary.dark
                        : themePalette(color, mode).palette.secondary.light,
            },
            title: {
                padding: '0 0 0 26px',
            },
            deleteIcon: {
                color: mode === 'dark' ? '#FFF' : '#000',
            },
        },
         MuiSwitch: {
            styleOverrides: {
                root: {

                }
            }
        },
        MUIDataTable: {
            responsiveStacked: {
                overflow: 'auto !important',
                overflowX: 'auto !important',
            },
            tableRoot: {
                minWidth: 360
            }
        },
        MUIDataTablePagination: {
            toolbar: {
                padding: 0,
                '& > p:nth-child(2)': {
                    '@media (max-width: 400px)': {
                        display: 'none'
                    }
                },
            },
            root: {
                padding: 0
            }
        }
        */
    },
})

export default applicationTheme;
