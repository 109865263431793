import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../page/config/config-table-jss";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {useForm} from "react-hook-form";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import MiniClubService from "../../service/MiniClubService";
import {TMiniClubDataColumn, TMiniClubFormValues} from "../../constants/type/miniclub/miniclubType";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {IMGS} from "../../constants";
import AddNewMiniClubForm from "./AddNewMiniClubForm";
import ClassroomService from "../../service/ClassroomService";
import {SelectChangeEvent} from "@mui/material/Select";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {dropZoneFilePreview, isPositiveValue} from "../../helpers/helpers";
import moment from "moment";
import {useNavigate} from 'react-router-dom';
import _ from "lodash";
import {getErrorMessage} from "../../helpers/errorManager";
import {pageFormImpl, setPageForm} from "../../features/page/pageSlice";
import {request} from "../../helpers/axios_helpers";
import {useDispatch, useSelector} from "react-redux";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../components/ui/materialReactTable/MyMaterialReactTableEvents";

const useStyles = makeStyles(styles);


function MiniClub(props: any) {
    const {locale, theme, snackbarShowMessage} = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const [miniClubData, setMiniClubData] = useState<TMiniClubDataColumn[]>([]);
    const [open, setOpen] = React.useState(false);
    //const [stateClassroom, setStateClassroom] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [classroomData, setClassroomData] = useState([]);
    const [classroomDataValues, setClassroomDataValues] = useState([]);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [file, setFile] = useState(null);
    const [startDate, setStartDate]:any = useState(moment( moment()));
    const [endDate, setEndDate] = useState(startDate);
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const dispatch = useDispatch();

    const mytableState = useMyMaterialReactTable({tableId:'MiniClub',  updateId:1725916246000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<any>(mytableState), []);

    const miniClubService = MiniClubService();
    const classroomService = ClassroomService();



    const schema = yup.object().shape({
        id: yup.number(),
        title: yup.string().required(),
        prix: yup.number().required().nonNullable(),
        placeLimit: yup.number().required().nonNullable(),
        classroom: yup.array(),
    });


    /*const form = useForm<TMiniClubFormValues>({
        defaultValues: {
            title: '',
            prix: null,
            placeLimit: null,
        }
    });*/

    const form = useForm<TMiniClubFormValues | any | undefined> ({
        defaultValues: {},
        resolver: yupResolver(schema),
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const addNewMiniClub = () => {
        setFileDataURL(null);
        setFile(null);
        setClassroomDataValues([]);
        setStartDate(undefined);
        setEndDate(undefined);
        reset({
            title: '',
            prix: null,
            placeLimit: null,
            details:''
        });

        setOpen(true);
    }

    const editMiniClub = (row: any) => {
        const data = row.original;
        showMiniClub(data);
    }

    const showMiniClub = (data: any) => {
        navigate('/app/mini-club/edit', {
            state:{ data },
        });
    }

    const handleRowsDelete = async (rows:any) => {
        try {
            const dataSelected:any = [];
            const dataMiniClubSelectedId:any = [];
            rows.map((row:any) => {
                const data = miniClubData[row.index];
                dataSelected.push(data);
                dataMiniClubSelectedId.push(data.id);
            });

            await miniClubService.deleteMiniClub(dataMiniClubSelectedId);
            const miniCLubList = miniClubData.filter((club:any) => {
                if(!dataSelected.includes(club)){
                    return club;
                }
            });
            setMiniClubData([...miniCLubList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage( <FormattedMessage id={messages.sb_delete_success} />);
        }
        catch (error) {
            snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
        }
    }

    const handleClassroomChange = (event: SelectChangeEvent<typeof classroomDataValues>) => {
        const value:any = event.target.value;
        setClassroomDataValues(value);
    }

    const onSubmit = async (data:any) => {
        if(classroomDataValues.length > 0){
            if(startDate !== undefined && endDate !== undefined){
                try {
                    const dataToSend = {
                        ...data,
                        dateDebut: startDate._d.valueOf(),
                        dateFin: endDate._d.valueOf(),
                        classroom: classroomDataValues
                    }
                    const response:any = await miniClubService.postNewMiniClubData(dataToSend, file);
                    const dataReform:any = [response.data];
                    const dataCreated = miniClubService.reformatMiniClubData(dataReform, activeLang);
                    setMiniClubData([...dataCreated, ...miniClubData]);

                    snackbarShowMessage( <FormattedMessage id={messages.sb_add_success} />);
                    setOpen(false);
                }
                catch (error){
                    snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                }
            }
        }
    }

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleStartDateChange = (date:any) => {
        setStartDate(date);
        setEndDate(date);
    };

    const handleEndDateChange = (date:any) => {
        setEndDate(date);
    }

    const columns = useMemo<MRT_ColumnDef<TMiniClubDataColumn>[]>(
        () => [
            {
                accessorKey: 'image',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_illustration}),
                size: 70,
                Cell: ({cell}) => (
                    <div style={{textAlign: 'center', width: 100}}>
                        {
                            (cell.getValue() !== null && cell.getValue() !== "") ? (
                                <img
                                    src={`${BASEURL_RESOURCES}/${cell.getValue<string>()}`}
                                    style={{textAlign: 'center', width: 80}} alt="mini-club-illustration"/>
                            ) : (
                                <img
                                    src={IMGS.photoMenu}
                                    style={{textAlign: 'center', width: 80}} alt="mini-club-illustration"/>
                            )
                        }
                    </div>
                ),
            },
            {
                accessorKey: 'title',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_title}),
                size: 100,
            },
            {
                accessorKey: 'startTime',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_start_time}),
                //size: 100,
            },
            {
                accessorKey: 'endTime',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_end_time}),
                //size: 100,
            },
            {
                accessorKey: 'placeLimit',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_number}),
                //size: 100,
            },
            {
                accessorKey: 'price',
                header: intl.formatMessage({id: messages.dialog_tab_hd_club_price}),
                //size: 100,
            },
        ],
        [activeLang],
    );


    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang])
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: miniClubData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addNewMiniClub}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editMiniClub(row)}
                styleClasses={classes}
            />
        )
    });

    useEffect(() => {
        if(pageForm){
            (async () => {

                try {
                    setTableDataLoading(true);
                    const dataMiniClubRequest = await miniClubService.getAllMiniClubData();
                    const dataMiniClubList: any = dataMiniClubRequest._embedded !== undefined ? dataMiniClubRequest._embedded.miniClubDTOModelList : [];
                    const dataMiniClub = miniClubService.reformatMiniClubData(dataMiniClubList, activeLang);
                    setMiniClubData([...dataMiniClub]);
                    setTableDataLoading(false);

                    const dataClassroomReq = await classroomService.getAllClassData();
                    const dataClassroomList = dataClassroomReq._embedded !== undefined ? dataClassroomReq._embedded.classeDTOModelList : [];
                    setClassroomData(dataClassroomList);

                    if(_.isObject(pageForm)){
                        // @ts-ignore
                        if(pageForm.cle === 'mini_club' && isPositiveValue(pageForm.id) ){
                            // @ts-ignore
                            const   dataId = pageForm.id ;
                            dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                            // @ts-ignore
                            request("GET",  `/extra/miniclubs/${dataId}`, {}).then((response:any) => {

                                if(isPositiveValue(response.data.id)){
                                    const dataToShow: any = [response.data];
                                    const dataToShowFrontEnd = miniClubService.reformatMiniClubData(dataToShow, activeLang);
                                    showMiniClub(dataToShowFrontEnd[0]);
                                }
                                setInitialized(true);
                            }).catch((error:any) => {
                                setInitialized(true);
                            });
                        }else{
                            setInitialized(true);
                        }
                    }else{
                        setInitialized(true);
                    }

                }catch (error){
                    snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                }
            })()
        }
    }, [pageForm]);

    useEffect(() => {
        (async () => {

            try {
                setTableDataLoading(true);
                const dataMiniClubRequest = await miniClubService.getAllMiniClubData();
                const dataMiniClubList: any = dataMiniClubRequest._embedded !== undefined ? dataMiniClubRequest._embedded.miniClubDTOModelList : [];
                const dataMiniClub = miniClubService.reformatMiniClubData(dataMiniClubList, activeLang);
                setMiniClubData([...dataMiniClub]);
                setTableDataLoading(false);

                const dataClassroomReq = await classroomService.getAllClassData();
                const dataClassroomList = dataClassroomReq._embedded !== undefined ? dataClassroomReq._embedded.classeDTOModelList : [];
                setClassroomData(dataClassroomList);

                if(_.isObject(pageForm)){
                    // @ts-ignore
                    if(pageForm.cle === 'mini_club' && isPositiveValue(pageForm.id) ){
                        // @ts-ignore
                        const   dataId = pageForm.id ;
                        dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                        // @ts-ignore
                        request("GET",  `/extra/miniclubs/${dataId}`, {}).then((response:any) => {

                            if(isPositiveValue(response.data.id)){
                                const dataToShow: any = [response.data];
                                const dataToShowFrontEnd = miniClubService.reformatMiniClubData(dataToShow, activeLang);
                                showMiniClub(dataToShowFrontEnd[0]);
                            }
                            setInitialized(true);
                        }).catch((error:any) => {
                            setInitialized(true);
                        });
                    }else{
                        setInitialized(true);
                    }
                }else{
                    setInitialized(true);
                }

            }catch (error){
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            }
        })()
    }, [activeLang]);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    useEffect(() => {
        return () => logicMRT.storeState(tableConfig.getState());
    }, []);

   /* if(!initialized){
        return(
            <div style={{width:'100%', textAlign:'center', padding:20}}>
                <CircularProgress />
            </div>
        );
    }*/
    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />
            <AddNewMiniClubForm
                title={<FormattedMessage id={messages.dialog_modal_club_title} /> }
                open={open}
                setOpen={setOpen}
                theme={theme}
                classes={classes}
                register={register}
                onSubmit={handleSubmit(onSubmit)}
                classroomData={classroomData}
                classroomDataValues={classroomDataValues}
                handleClassroomChange={handleClassroomChange}
                fileDataURL={fileDataURL}
                handleFileChange={handleFileChange}
                activeLang={activeLang}
                startDate={startDate}
                handleStartDateChange={handleStartDateChange}
                endDate={endDate}
                handleEndDateChange={handleEndDateChange}
            />

        </div>
    );
}

export default withSnackbar(MiniClub);
