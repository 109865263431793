import React from "react";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {processingChildren, processingEmployees, processingJobs} from "../../../helpers/loader_helpers";
import albumPhotoStateUpdate from "./AlbumPhotoStateUpdate";
import {Child} from "../../../constants/type/Child";
import {request, uploadFileRequest} from "../../../helpers/axios_helpers";
import {AlbumPicture, ALBUMPICTURE_CONSTANT} from "../../../constants/type/AlbumPicture";
import messages from "../../messages/general/messages";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../constants/type/Profils";
import {Fonction} from "../../../constants/type/Fonction";
import {Employee} from "../../../constants/type/Employee";
import {checkTeacherUser} from "../../../helpers/helpers";
import {mixLoad} from "../../../helpers/loader_cache_helpers";

const albumPhotoBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['classes', 'profils', 'fonctions', 'enfants', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logic = processingEmployees(logic, itememployees);
        };


        albumPhotoStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            checkTeacherUser(logic, albumPhotoStateUpdate);
            albumPhotoStateUpdate.childrenList(logic, _.cloneDeep(logic.childrenClasseRef.current));
            albumPhotoStateUpdate.tableDataLoading(logic, false);
        }).catch(e => {
            albumPhotoStateUpdate.tableDataLoading(logic, false);
        });
    },
    handleDeleteFiles: (logic:any) => {
        if(_.isNull(logic.photosIdsSelected) || _.isEmpty(logic.photosIdsSelected)){
            return ;
        }
        albumPhotoStateUpdate.openFileDeleteDialogForm(logic, true);
    },
    envoieFichier: (logic:any, enfant:Child|null, files:any[], setUploadingMsg:any) => {
        if(_.isNull(enfant) || _.isNull(enfant.id)){
            return ;
        }

        let formData:any = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }

        setUploadingMsg(true);
        return new Promise<boolean>(resolve =>
            uploadFileRequest('POST',  `/extra/images/enfant/${enfant.id}`, formData).then((response) => {

                resolve(true);
                setUploadingMsg(false);
                albumPhotoBusinessLogic.loadAlbumPhoto(logic, enfant);
            }).catch((error) => {
                resolve(false);
                setUploadingMsg(false);
                console.error(error);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            })
        );
    },
    loadAlbumPhoto: (logic:any, enfant:Child) => {
        albumPhotoStateUpdate.showPhotoContent(logic, false);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/images/enfant/${enfant.id}/${logic.userRef.current.id}`, {}).then((response) => {
                const items : AlbumPicture[]   = response.data._embedded?.albumPictureDTOModelList ?? [];
                const itemsfrontend = _.reverse(_.sortBy(_.map(items,  item => ALBUMPICTURE_CONSTANT.convertToFrontEnd(logic, item)), ['lastUpdate']));
                albumPhotoStateUpdate.photoList(logic, itemsfrontend) ;
                albumPhotoStateUpdate.showPhotoContent(logic, true);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                albumPhotoStateUpdate.showPhotoContent(logic, true);
            })
        );
    },
    deleteFile: async (logic:any, messages:any,  filesIds:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {

            request('DELETE', `/extra/images/bouquet`, filesIds).then((response) => {
                albumPhotoBusinessLogic.loadAlbumPhoto(logic, logic.childSel);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
}
export default albumPhotoBusinessLogic ;