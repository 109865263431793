import React from 'react';
import {TextField} from "@mui/material";

function InputField(props:any){
    const { id, placeholder, label, InputProps, size, sx,  variant, color, type, required, disabled, value, onChange, inputProps } = props
    return (
        <TextField
            sx={sx ?? {
                m:0,
                p:0,
                width: { xs: '100% !important'},
            }}
            InputProps={InputProps}
            id={id}
            type={type}
            label={label}
            value={value}
            placeholder={placeholder}
            size={size}
            color={color}
            inputProps={inputProps}
            disabled={disabled}
            required={required}
            onChange={onChange}
            variant={variant}
        />
    )
}

export default InputField
