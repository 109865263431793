import React from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Grid, TextField} from "@mui/material";
import {IMGS} from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButtonForm from "../../components/ui/form/LoadingButtonForm";
import InputField from "../../components/ui/form/InputField";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        container: {
            fontSize: '14px',
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontSize: 18,
            fontWeight:'400 !important',
            padding:0,
            margin: 0,
            paddingBottom:10,
            borderBottom: "1px solid #ccc",
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {},
        textFieldWith: {
            width: '100%',
            padding: 0,
        }
    }
});

function EditProductForm(props:any) {
    const {
        fileDataURL,
        handleFileChange,
        register,
        onSubmit,
        loadingButton
    } = props;
    const classes = {...useStyles(), ...useStyles2()};

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>
                <FormattedMessage id={messages.dialog_modal_edit_title} />
            </h2>
            <div style={{marginTop: 40}}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <div className={classes.dialogAvatar}>
                            <img
                                src={fileDataURL ? fileDataURL : IMGS.photoMenu}
                                style={{width:100, height:90}}
                                alt={'mini-club-illustration'}
                            />
                            <div className={classes.iconButtonLabel}>
                                <label htmlFor="file-upload">
                                    <div className={classes.iconButton} style={{textAlign:"center"}}>
                                        <EditIcon />
                                    </div>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        hidden={true}
                                        onChange={(a:any) => handleFileChange(a)}
                                    />
                                </label>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.marketing_product_nom}/>}
                                size="small"
                                color={"secondary"}
                                className={classes.textFieldWith}
                                {...register("title", {
                                    required: "Title is required"
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classes.inputField}>
                            <TextField
                                label={<FormattedMessage id={messages.dialog_tab_hd_club_price}/>}
                                size="small"
                                color={"secondary"}
                                type={"number"}
                                className={classes.textFieldWith}
                                {...register("prix", {
                                    required: "Price is required"
                                })}
                            />
                        </div>
                    </Grid>


                    <Grid item md={8} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.marketing_product_quantity}/>}
                                size="small"
                                color={"secondary"}
                                type={"number"}
                                className={classes.textFieldWith}
                                {...register("quantity", {
                                    required: "Quantity is required"
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classes.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.dialog_tab_hd_club_description}/>}
                                size="small"
                                color={"secondary"}
                                InputProps={{
                                    rows: 3,
                                    multiline: true,
                                    inputComponent: 'textarea',
                                }}
                                inputProps={{
                                    ...register("description")
                                }}
                                className={classes.textFieldWith}
                            />
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12} sx={{ marginTop: 3}}>
                        <div className={classes.inputField} style={{textAlign: 'right'}}>
                            {/*<Button variant="contained" size={"large"} style={{textTransform:"none"}} onClick={onSubmit}>
                                <FormattedMessage id={messages.save_edit_mini_club_list} />
                            </Button>*/}
                            <LoadingButtonForm
                                color="primary"
                                variant="contained"
                                icon={SaveIcon}
                                textButton={<FormattedMessage id={messages.save_edit_mini_club_list} />}
                                onClickFormCrudAction={onSubmit}
                                loadingButton={loadingButton}
                            />
                        </div>
                    </Grid>

                </Grid>
            </div>

        </div>
    );
}

export default EditProductForm;
