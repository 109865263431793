import React, {useContext} from 'react';
import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';
import {Button, ListSubheader} from "@mui/material";
import {FormattedMessage} from "react-intl";
import sondageBusinessLogic from "./businessLogic/SondageBusinessLogic";
import {TransitionGroup} from 'react-transition-group';
import messages from "../messages/general/messages";
import {SondageCenterContext} from "./SondageCenter";

function SondageList(props:any) {
    const logic: any = useContext(SondageCenterContext);


    React.useEffect(() => sondageBusinessLogic.listerLesSondages(logic, logic.dataList),
        [logic.expandOpenedSondage, logic.expandClosedSondage]);


    return (
        <div>
                <List
                    component="nav"
                    disablePadding
                    subheader={
                        <ListSubheader component="div" className={logic.spaceListStyles.total} id="nested-list-subheader">
                            <Button
                                variant={"text"}
                                color="secondary"
                                onClick={() => sondageBusinessLogic.handleComposeNewSondage(logic)}
                                startIcon={<AddIcon/>}
                                aria-label="add">
                                <FormattedMessage id={messages.add_survey_list}/>
                            </Button>

                        </ListSubheader>
                    }
                    className={logic.spaceListStyles.contactList}>
                    <TransitionGroup>
                        { logic.listeDesSondages }
                    </TransitionGroup>
                </List>
        </div>
    );
}


export default SondageList;
