import moment from "moment/moment";
import {RdvEtat} from "./RdvEtat";
import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import {Child} from "./Child";
import {Parent, PARENT_CONSTANT} from "./Parent";

export const CRENEAURDVENFANTPARENT_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:CreneauRdvEnfantParent | null) => {
        if(_.isNull(item)){
            return item ;
        }
        const itemInit = _.cloneDeep(creneauRdvEnfantParentDataImp);
        item =  {...itemInit, ...item} ;

        item.startForm    = moment(item.dateDebut);
        item.endForm      = moment(item.dateFin);
        item.childForm    = _.find(logicRacine.childrenRef.current, {id:item?.enfantId});

        if(_.isObject(item.childForm)){
            for(const enfantparent of item.childForm.enfantParents){
                if(_.isNull(enfantparent) || _.isUndefined(enfantparent)){
                    continue  ;
                }
                if(enfantparent.parent?.id !== item?.parentId){
                    continue  ;
                }
                // @ts-ignore
                item.parentForm   = PARENT_CONSTANT.convertToFrontEnd(logicRacine, enfantparent.parent) ;
            }
        }

        // @ts-ignore
       return item ;
    },
    convertToBackEnd: (item:CreneauRdvEnfantParent) => {
        item.dateDebut          = item.startForm.valueOf();
        item.dateFin            = item.endForm.valueOf();
        return item ;
    }
};

export const creneauRdvEnfantParentDataImp: CreneauRdvEnfantParent =  {
    id: null,
    meetingStatus: RdvEtat.WAIT,
    creneauRdvId:null,
    enfantId: null,
    parentId: null,
    classeId:0,
    userId: 0,
    dateDebut: 0,
    dateFin: 0,
    startForm: moment(),
    endForm: moment().add(1, 'minutes'),
    commentaire: '',
    common: _.cloneDeep(commonDataImp),
    childForm:null,
    parentForm:null,
}

export const creneauRdvEnfantParentSchema = yup.object().shape({
    id : yup.number().nullable(),
    meetingStatus: yup.mixed<RdvEtat>().required().oneOf(Object.values(RdvEtat)),
    creneauRdvId: yup.number().nullable(),
    enfantId: yup.number().nullable().min(1),
    parentId: yup.number().nullable(),
    classeId: yup.number().nullable(),
    userId: yup.number().min(0),
    dateDebut: yup.number().min(1),
    dateFin: yup.number().min(1),
    startForm : yup.mixed<moment.Moment>().required(),
    endForm : yup.mixed<moment.Moment>().required(),
    commentaire: yup.string().nullable(),
    common: yup.mixed<Common>().required(),
    childForm: yup.mixed<Child>().nullable().default(null),
    parentForm: yup.mixed<Parent>().nullable().default(null),
});

export type CreneauRdvEnfantParent = yup.InferType<typeof creneauRdvEnfantParentSchema>;