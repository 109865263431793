import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Icon,
    MenuItem,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {
    LectureRdv,
    lectureRdvDataImpl,
    presetRdvSchema,
    Rdv,
    RDV_CONSTANT,
    rdvPresetDataImpl
} from "../../constants/type/Rdv";
import React, {useContext} from "react";
import {AppointmentPresetContext} from "./Appointment";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import InputField from "../../components/ui/form/InputField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import DialogForm from "../../components/ui/form/DialogFormKiller";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import _ from "lodash";
import {RdvEtat} from "../../constants/type/RdvEtat";
import colorfull from "../../data/colorfull";
import {RdvType} from "../../constants/type/RdvType";
import {CreneauRdv, creneauRdvDataImp} from "../../constants/type/CreneauRdv";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import rdvBusinessLogic from "./businesslogic/RdvBusinessLogic";
import rdvPresetBusinessLogic from "./businesslogic/RdvPresetBusinessLogic";
import rdvPresetLectureBusinessLogic from "./businesslogic/RdvPresetLectureBusinessLogic";
import {isPositiveValue} from "../../helpers/helpers";
import {getErrorMessage} from "../../helpers/errorManager";
import {RoleUser} from "../../constants/type/RoleUser";

const color = {
    main: colorfull[2],
    secondary: colorfull[3],
    third: colorfull[0],
    fourth: colorfull[1],
    fifth: colorfull[6],
};

//TODO LORS D'UNE MODIFICATION DU PRESET NETTOYER LES CRENEAUX HORAIRES

const PresetForm = () => {
    const logicRacine: any = useContext(AppointmentPresetContext);
    const intl = useIntl() ;


    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(rdvPresetDataImpl),
        // @ts-ignore
        resolver: yupResolver(presetRdvSchema),
    }));
    const {errors} = formRef.current.formState;

    const editableConfig = (item: Rdv) => rdvStateUpdate.editDisablePresetRef(logicRacine, rdvPresetBusinessLogic.editePresetDisableChecking(logicRacine, item));
    const handleClickDeleteRdv = () => {
        const rdvsId:Rdv[] = [logicRacine.rdvEdit.id];
        rdvBusinessLogic.deleteRdv(logicRacine, rdvsId).then((response:any) => {
            rdvStateUpdate.openPresetDialogForm(logicRacine, false);
        });
    }

    const handleOnCancelMeetingCheck = (event: any) => {
        const isChecked = !logicRacine.rdvCancelOpenBeforeMeetingPreset ;
        let newMeetingStatus     = isChecked ? RdvEtat.CANCEL : _.cloneDeep(logicRacine.rdvEdit.meetingStatus);
        rdvStateUpdate.rdvCancelOpenBeforeMeetingPreset(logicRacine, isChecked);
        formRef.current.setValue('meetingStatus', newMeetingStatus);
    };
    const onStatusAfterMeetingChange = (event: React.ChangeEvent, value: string) => {
        // @ts-ignore
        const meetingStatus = RdvEtat[value];
        rdvStateUpdate.statusAfterMeetingPreset(logicRacine, meetingStatus);
        formRef.current.setValue('meetingStatus', meetingStatus);
    }
    const statusBeforeMeetingViewConfig = (item: Rdv) =>  rdvStateUpdate.statusBeforeMeetingViewPresetRef(logicRacine, _.isNull(item.id) === false && moment().isBefore(item.endForm) );

    const statusAfterMeetingViewConfig = (item: Rdv) =>  rdvStateUpdate.statusAfterMeetingViewPresetRef(logicRacine, _.isObject(item.id) && moment().isAfter(item.endForm) );

    const updateClasse = (id:number) => {
        rdvStateUpdate.classeIdPreset(logicRacine, id);
        formRef.current.setValue('classeId', id);
    }
    const handleOnClasseChange = (event: any) => updateClasse(_.toInteger(event.target.value));

    const handleStartDateChange = (value: moment.Moment,     context: any) => {
        const newdate = value.clone().startOf('day');
        rdvStateUpdate.startFormPreset(logicRacine, newdate);
        formRef.current.setValue('startForm', newdate);
    };
    const handleEndDateChange = (value: moment.Moment,     context: any) => {
        const newdate = value.clone().endOf('day');
        rdvStateUpdate.endFormPreset(logicRacine, newdate);
        formRef.current.setValue('endForm', newdate);
    }
    const handleEmployeeMeetingStatusChange = (event: React.MouseEvent<HTMLElement>,     value: any) => {
        if(_.isNull(logicRacine.creneauRdvEmployeePreset) || _.isUndefined(logicRacine.creneauRdvEmployeePreset)){
            return ;
        }
        const crenoemployee = _.cloneDeep(logicRacine.creneauRdvEmployeePreset);
        // @ts-ignore
        crenoemployee.meetingStatus = value;
        rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, crenoemployee);
    }
    const handleEmployeeCommentChange = (event:any) => rdvPresetBusinessLogic.handleEmployeeCommentChange(logicRacine, event.target.value);



    const onSubmit = async (data: FormData) => {
        rdvStateUpdate.savingStatePresetBtn(logicRacine, true);
        if(logicRacine.lectureRdvPreset?.employeeOutcomeShow){
            const rdvchoose = _.cloneDeep(data);
            const crenordv = _.cloneDeep(logicRacine.eventCreneauSelectedRef.current) ?? _.cloneDeep(creneauRdvDataImp);
            crenordv.creneauRdvEmployees[0] = logicRacine.creneauRdvEmployeePreset ;
            // @ts-ignore
            rdvchoose.creneauRdvs = _.map(rdvchoose.creneauRdvs, (item:CreneauRdv) => {
                if(crenordv.id !== item.id){
                    return item;
                }
                return crenordv ;
            });
            // @ts-ignore
            data = rdvchoose ;
        }
        // @ts-ignore
        const toSend = RDV_CONSTANT.convertToBackEnd(data);
        rdvBusinessLogic.saveRdv(
            logicRacine,
            messages,
            toSend).then((response:any) => {
            rdvStateUpdate.openPresetDialogForm(logicRacine, false);
            rdvStateUpdate.savingStatePresetBtn(logicRacine, false);

            // @ts-ignore
            if(_.isNull(data.id)){
                const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please select week mode in calendar to edite time slots` : `Veuillez selectionner le mode semaine dans le calendrier afin d'éditer les creneaux horaires`;
                logicRacine.snackbarShowMessage(msg, 'warning', 10000);
            }

        }).catch((e:any) => rdvStateUpdate.savingStatePresetBtn(logicRacine, false));

    };

    const loadRdv = React.useCallback((logicRacine:any) => {
        return new Promise<boolean>(resolve =>
            rdvBusinessLogic.loadRdv(logicRacine, logicRacine.rdvEdit.id).then((data:any) => {
                const item : Rdv   = RDV_CONSTANT.convertToFrontEnd(logicRacine, data);
                if(_.isNull(item) || _.isUndefined(item)){
                    return ;
                }
                rdvStateUpdate.rdvEdit(logicRacine, item);
                rdvStateUpdate.eventCreneauSelectedRef(logicRacine, item.creneauRdvs[0]);;

                const creneauemployer = logicRacine.eventCreneauSelectedRef.current?.creneauRdvEmployees[0] ;
                if(_.isNull(creneauemployer) === false && _.isUndefined(creneauemployer) === false){
                    const employeeId : number | null = creneauemployer?.employeeId ?? null ;
                    if(isPositiveValue(employeeId)){
                        rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, _.cloneDeep(creneauemployer));
                    }
                }
                const crenoparent = logicRacine.eventCreneauSelectedRef.current?.creneauRdvEnfantParents[0]  ;
                if(_.isNull(crenoparent) === false && _.isUndefined(crenoparent) === false) {
                    rdvStateUpdate.creneauRdvEnfantParentPresetRef(logicRacine, crenoparent);
                }
                editableConfig(logicRacine.rdvEdit);
                statusBeforeMeetingViewConfig(logicRacine.rdvEdit);
                statusAfterMeetingViewConfig(logicRacine.rdvEdit);
                rdvStateUpdate.classeIdPreset(logicRacine, logicRacine.rdvEdit.classeId);
                rdvStateUpdate.startFormPreset(logicRacine, logicRacine.rdvEdit.startForm);
                rdvStateUpdate.endFormPreset(logicRacine, logicRacine.rdvEdit.endForm);

                formRef.current.reset(logicRacine.rdvEdit);
                resolve(true);
            }).catch((error:any) => {
                logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    }, [logicRacine.openNormalDialogForm]);

    React.useEffect(() => {

        if(logicRacine.openPresetDialogForm === false){
            rdvStateUpdate.showContentPreset(logicRacine, false);
            return ;
        }

        if(logicRacine.newPresetRef.current){
            const newrdv = _.cloneDeep(rdvPresetDataImpl);
            newrdv.classeId = logicRacine.classeIdRef.current ?? 0;
            rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, null);
            rdvStateUpdate.creneauRdvEnfantParentPresetRef(logicRacine, null);
            editableConfig(newrdv);
            statusBeforeMeetingViewConfig(newrdv);
            statusAfterMeetingViewConfig(newrdv);
            // @ts-ignore
            updateClasse(newrdv.classeId);

            rdvStateUpdate.startFormPreset(logicRacine, newrdv.startForm);
            rdvStateUpdate.endFormPreset(logicRacine, newrdv.endForm);
            formRef.current.reset(newrdv);
            rdvStateUpdate.showContentPreset(logicRacine, true);
            return ;
        }

        loadRdv(logicRacine).then(value => rdvStateUpdate.showContentPreset(logicRacine, true));


        return () => {
            rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, null);
            rdvStateUpdate.creneauRdvEnfantParentPresetRef(logicRacine, null);
            formRef.current.reset(_.cloneDeep(rdvPresetDataImpl));
        };
    }, [logicRacine.openPresetDialogForm]);

    React.useEffect(() => {
        if(logicRacine.rdvEdit?.meetingType !== RdvType.PRESET){
            return ;
        }
        const rdv = logicRacine.rdvEdit ;
        const creneauRdv                = logicRacine.eventCreneauSelectedRef.current ;
        const creneauRdvEmployee        = logicRacine.creneauRdvEmployeePreset ;
        const creneauRdvEnfantParent    = _.cloneDeep(logicRacine.creneauRdvEnfantParentPresetRef.current)  ;

        let lectureRdv:LectureRdv = _.cloneDeep(lectureRdvDataImpl);
        lectureRdv = rdvPresetLectureBusinessLogic.lire(
            logicRacine,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            lectureRdv);
        rdvStateUpdate.lectureRdvPreset(logicRacine, lectureRdv);
        return () => {};
    }, [logicRacine.rdvEdit, logicRacine.creneauRdvEmployeePreset]);


    return (
        <DialogForm
            title={<FormattedMessage id={messages.preset_title} />}
            savingBtn={true}
            savingStateBtn={logicRacine.savingStatePresetBtn}
            open={logicRacine.openPresetDialogForm}
            setOpen={logicRacine.setOpenPresetDialogForm}
            theme={logicRacine.theme}
            btnCloseLabel={messages.dialog_close}
            showSubmitBtn={logicRacine?.userRef?.current?.role !== RoleUser.TEACHER}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            {
                !logicRacine.showContentPreset &&
                <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                    <CircularProgress color="primary" />
                </Box>
            }
            {
                logicRacine.showContentPreset &&
                <Box sx={{display: 'block'}}>
                    {
                        !_.isNull(logicRacine.rdvEdit.id) &&
                        <div className={logicRacine.styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                            <FormattedMessage id={messages[`appointment_${_.toLower(logicRacine.rdvEdit.meetingStatus)}`]} />
                        </div>
                    }

                    <div className={logicRacine.styleClasses.inputField}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.class_preset} />}
                            sx={{ width: { xs: '100% !important'}, }}
                            color={"secondary"}
                            value={logicRacine.classeIdPreset}
                            onChange={handleOnClasseChange}
                            disabled={logicRacine.editDisablePresetRef.current || logicRacine.isTeacherRef.current}
                            inputProps={{
                                ...formRef.current.register("classeId", {
                                    required: "Required field",
                                    min:1,
                                })
                            }}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                            {logicRacine.classeRoomRef.current?.map((opt:any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight:400}}>
                                    {opt.nom}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className={logicRacine.styleClasses.errorHelper}>
                            {errors.classeId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                    <div className={logicRacine.styleClasses.inputField}>
                        <InputField
                            label={<FormattedMessage id={messages.title_appointment} />}
                            size="small"
                            color={"secondary"}
                            width={'100% !important'}
                            disabled={logicRacine.editDisablePresetRef.current}
                            inputProps={
                                {
                                    ...formRef.current.register("objet", {
                                        required: "Required field"
                                    })
                                }
                            }
                        />
                        <div className={logicRacine.styleClasses.errorHelper}>
                            {errors.objet && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                    <div className={logicRacine.styleClasses.inputField}>
                        <InputField
                            label={`${intl.formatMessage({id:messages.type_message})} (550 characters max)`}
                            size="small"
                            color={"secondary"}
                            disabled={logicRacine.editDisablePresetRef.current}
                            required
                            InputProps={{
                                rows: 2,
                                multiline: true,
                                inputComponent: 'textarea',
                            }}
                            inputProps={{
                                maxLength: 550,
                                ...formRef.current.register("details")
                            }}
                        />

                        <div className={logicRacine.styleClasses.errorHelper}>
                            {errors.details && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                    <div className={logicRacine.styleClasses.inputField}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.start_day} />}
                                value={logicRacine.startFormPreset}
                                // @ts-ignore
                                onChange={handleStartDateChange}
                                views={['year', 'month', 'day']}
                                slotProps={{ textField: { color: 'secondary' } }}
                                minDate={moment().subtract(1, 'days')}
                                disabled={logicRacine.editDisablePresetRef.current}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className={logicRacine.styleClasses.inputField}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.end_day} />}
                                value={logicRacine.endFormPreset}
                                // @ts-ignore
                                onChange={handleEndDateChange}
                                views={['year', 'month', 'day']}
                                slotProps={{ textField: { color: 'secondary' } }}
                                minDate={logicRacine.startFormPreset}
                                disabled={logicRacine.editDisablePresetRef.current}
                            />
                        </LocalizationProvider>

                        <div className={logicRacine.styleClasses.errorHelper}>
                            {errors.endForm && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>

                    <div className={logicRacine.styleClasses.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.duration_preset} />}
                            type="number"
                            color={"secondary"}
                            disabled={logicRacine.editDisablePresetRef.current}
                            inputProps={{
                                min: 5,
                                step: 5,
                                ...formRef.current.register("dureeMeetingForm")
                            }}
                        />

                    </div>

                    <div className={logicRacine.styleClasses.inputField}>
                        <TextField
                            label={<FormattedMessage id={messages.deadline_rdv} />}
                            type="number"
                            color={"secondary"}
                            disabled={logicRacine.editDisablePresetRef.current}
                            inputProps={{
                                min: 0,
                                step: 1,
                                ...formRef.current.register("deadlineUpdateForm")
                            }}
                        />
                    </div>

                    <ParentMeetingAction
                        logicRacine={logicRacine}
                        styleClasses={logicRacine.styleClasses} />
                    <EmployeeMeetingAction
                        logicRacine={logicRacine}
                        handleEmployeeCommentChange={handleEmployeeCommentChange}
                        handleClickDeleteRdv={handleClickDeleteRdv}
                        handleOnCancelMeetingCheck={handleOnCancelMeetingCheck}
                        onStatusAfterMeetingChange={onStatusAfterMeetingChange}
                        styleClasses={logicRacine.styleClasses} />
                </Box>
            }


        </DialogForm>
    );
}
const EmployeeMeetingAction = (props:any) => {
    const {logicRacine,
        styleClasses,
        handleEmployeeCommentChange,
        handleClickDeleteRdv,
        handleOnCancelMeetingCheck,
        onStatusAfterMeetingChange} = props;
    const deleteComponent = logicRacine.lectureRdvPreset.showDeleteBtn ? <div className={logicRacine.styleClasses.inputField}>
        <Button variant={"contained"}
                component="label"
                size="small"
                color="error"
                sx={{textTransform: 'none', marginTop:0.5, marginRight: { sm: 2 } }}
                onClick={handleClickDeleteRdv}
        >
            <Icon className={logicRacine.styleClasses.actionIcon}>delete</Icon>
            <FormattedMessage id={messages.delete_action} />
        </Button>
    </div> : <></>;
    if(logicRacine.lectureRdvPreset.employeeOutcomeShow === false){
        return <>{deleteComponent}</>;
    }
    return <>
        {deleteComponent}
        <div className={logicRacine.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.comment} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                placeholder={logicRacine.activeLanguageRef.current[0] === 'en' ? 'Comment' : 'Commentaire'}
                onChange={handleEmployeeCommentChange}
                value={logicRacine.creneauRdvEmployeePreset.commentaire}
            />
        </div>

        {
            logicRacine?.userRef?.current?.role !== RoleUser.TEACHER &&
            <>
                <ActionBeforeDateMeeting
                    logicRacine={logicRacine}
                    handleOnCancelMeetingCheck={handleOnCancelMeetingCheck}
                    styleClasses={logicRacine.styleClasses}/>
                <ActionAfterDateMeeting
                    logicRacine={logicRacine}
                    onStatusAfterMeetingChange={onStatusAfterMeetingChange}
                    styleClasses={logicRacine.styleClasses}/>
            </>
        }
    </>;
}
const ParentMeetingAction = (props:any) => {
    const {logicRacine, styleClasses} = props;

    const creno = logicRacine.eventCreneauSelectedRef.current ;
    if(logicRacine.lectureRdvPreset.parentOutcomeShow === false){
        return <></>;
    }
    if(_.isNull(creno) || _.isUndefined(creno)){
        return <></>;
    }
    if(_.isNull(creno.childNomPrenomForm) || _.isNull(creno.parentIdForm)){
        return <></>;
    }
    if(_.isNull(creno.childNomPrenomForm) || _.isNull(creno.parentIdForm)){
        return <></>;
    }
    return <>
        <div className={logicRacine.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.dialog_input_classroom} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                disable
                value={creno.classeNameForm}
            />
        </div>
        <div className={logicRacine.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.child} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                disable
                value={creno.childNomPrenomForm}
            />
        </div>
        <div className={logicRacine.styleClasses.inputField}>
            <InputField
                label="Parent"
                size="small"
                color={"secondary"}
                width={'100% !important'}
                disable
                value={creno.parentNameForm}
            />
        </div>
        <div className={logicRacine.styleClasses.inputField}>
            <InputField
                label={<FormattedMessage id={messages.phone_number_child} />}
                size="small"
                color={"secondary"}
                width={'100% !important'}
                disable
                value={creno.parentPhoneForm}
            />
        </div>
    </>


}

const ActionBeforeDateMeeting = (props:any) => {
    const {logicRacine, styleClasses,handleOnCancelMeetingCheck} = props;
    if(logicRacine.statusAfterMeetingViewPresetRef.current){
        return <></>;
    }
    const meetingStatus = logicRacine.rdvEdit.meetingStatus ;
    if(meetingStatus === RdvEtat.CANCEL){
        return <></>;
    }

    return <div className={logicRacine.styleClasses.inputField}>
        <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={logicRacine.rdvCancelOpenBeforeMeetingPreset}
                                   onChange={handleOnCancelMeetingCheck} />}
                label={<FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.CANCEL)}`]} />} />
        </FormGroup>
    </div> ;
}

const ActionAfterDateMeeting = (props:any) => {
    const {logicRacine, styleClasses, onStatusAfterMeetingChange} = props;

    if(logicRacine.statusBeforeMeetingViewPresetRef.current){
        return <></>;
    }

    return <FormControl>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={logicRacine.statusAfterMeetingPreset}
            onChange={onStatusAfterMeetingChange}>
            <FormControlLabel value={RdvEtat.DONE} control={<Radio />} label={<FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.DONE)}`]} />} />
            <FormControlLabel value={RdvEtat.NOT_RESPECTED} control={<Radio />} label={<FormattedMessage id={messages[`appointment_${_.toLower(RdvEtat.NOT_RESPECTED)}`]} />} />
        </RadioGroup>
    </FormControl> ;
}
export default PresetForm ;
