import {RoleUser} from "./RoleUser";
import * as yup from "yup";
import {DiscussionParticipant} from "./DiscussionParticipants";
import {Genre} from "./Genre";
import {DiscussionRole} from "./DiscussionRole";
import {DiscussionEtat} from "./DiscussionEtat";
import _ from "lodash";

export const FILDISCUSSION_CONSTANT = {
    convertToFrontEnd: (logicRef:any, item:FilDiscussion) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(filDiscussionDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logicRef:any, item:FilDiscussion) => item,
};

export  const filDiscussionDataImpl: FilDiscussion = {
    id: null,
    filRole: DiscussionRole.MESSAGERIE,
    enfantId: 0,
    objet: '',
    uuid: '',
    employeeUuid: '',
    parentUuid: '',
    filEtat: DiscussionEtat.OPEN,
    theDate: 0,
    parentUserId: 0,
    parentNom: '',
    enfantPhoto: '',
    enfantNom: '',
    employeeUserId: 0,
    employeeNom: '',
    employeeFonction: '',
    employeePhoto: '',
    classeId: 0,
    employeeRole: RoleUser.SUPPORT,
    parentSexe: Genre.FEMME,
    parentDeletedSpace: false,
    employeeDeletedSpace: false,
    discussionParticipants: [],
    totalNotifications:0,
    lastMessage: ``,
    userNameCloseSpace: ``,
    initiatorUserId: 0,
    enfantParentIdForm: 0,
    //soro et hanam
}

export const filDiscussionSchema = yup.object().shape({
    id: yup.number().nullable(),
    filRole: yup.mixed<DiscussionRole>().oneOf(Object.values(DiscussionRole)).default(DiscussionRole.MESSAGERIE),
    enfantId: yup.number(),
    objet: yup.string(),
    uuid: yup.string(),
    employeeUuid: yup.string(),
    parentUuid: yup.string(),
    filEtat: yup.mixed<DiscussionEtat>().oneOf(Object.values(DiscussionEtat)).default(DiscussionEtat.OPEN),
    theDate: yup.number(),
    parentUserId: yup.number(),
    parentNom: yup.string(),
    enfantPhoto: yup.string(),
    enfantNom: yup.string(),
    employeeUserId: yup.number(),
    employeeNom: yup.string(),
    employeeFonction: yup.string(),
    employeePhoto: yup.string(),
    classeId: yup.number(),
    employeeRole: yup.mixed<RoleUser>().oneOf(Object.values(RoleUser)).default(RoleUser.SUPPORT),
    parentSexe: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.FEMME),
    parentDeletedSpace: yup.boolean().default(false),
    employeeDeletedSpace: yup.boolean().default(false),
    discussionParticipants: yup.array().of(yup.mixed<DiscussionParticipant>()).nonNullable().default([]),
    totalNotifications: yup.number().default(0),
    lastMessage: yup.string().default(``),
    userNameCloseSpace: yup.string().default(``),
    initiatorUserId: yup.number().default(0),
    enfantParentIdForm: yup.number().default(0),
});

export type FilDiscussion = yup.InferType<typeof filDiscussionSchema>;