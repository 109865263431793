import {getRequest} from "../helpers/axios_helpers";
import {getNextMonthsListByDate, getPrevMonthsListByDate, getShortMonthName} from "../helpers/helpers";
import moment from "moment";
import _ from 'lodash';

function SchoolingService() {
    return {
        getAllPayment: async () => {
            try {
                const result = await getRequest(`/extra/scolaritenfant`);
                return result._embedded !== undefined ? result._embedded?.scolariteVersementDTOModelList : [];
            }
            catch (error){
                console.log(error);
            }
        },
        getTurnoverSum: (data:any) => {
            let turnover = 0;
            for(let i=0; i<data.length; i++){
                turnover += data[i].montant;
            }
            return turnover;
        },
        getSchoolingTurnoverSum: (data:any, scolariteTypeId:number) => {
            let turnover = 0;
            for(let i=0; i<data.length; i++){
                if(data[i].scolariteTypeId === scolariteTypeId){
                    turnover += data[i].montant;
                }
            }
            return turnover;
        },
        getFeesTurnoverSum: (data:any, scolariteTypeId:number) => {
            let turnover = 0;
            for(let i=0; i<data.length; i++){
                if(data[i].scolariteTypeId !== scolariteTypeId){
                    turnover += data[i].montant;
                }
            }
            return turnover;
        },
        getAllSchoolingFees: async () => {
            const result = await getRequest(`/extra/scolaritelistfrais`);
            return result._embedded !== undefined ? result._embedded?.scolariteTypeDTOModelList : [];
        },
        getSchoolingComparativeList: (startPeriod:any, endPeriod:any, deadlineList:any, paymentList:any) => {
            let comparativeMonthsList:any = [];
            const monthsListPartOne:any = getNextMonthsListByDate(startPeriod);

            let monthsListPartTwo:any = getPrevMonthsListByDate(endPeriod);
            monthsListPartTwo = monthsListPartTwo.reverse();
            comparativeMonthsList = monthsListPartOne;

            for(let j=0; j<monthsListPartTwo.length; j++){
                if(!monthsListPartOne.includes(monthsListPartOne[j].month)){
                    comparativeMonthsList.push(monthsListPartTwo[j]);
                }
            }

            let schoolingData = [];
            for(let i=0; i<comparativeMonthsList.length; i++){
                const shortMonthName = getShortMonthName(comparativeMonthsList[i].month);
                if(shortMonthName !== null){
                    let sumDeadline = 0;
                    let sumPayment = 0;
                    for(let x=0; x<deadlineList.length; x++){
                        if(deadlineList[x].deadlineMonth === shortMonthName){
                            sumDeadline += deadlineList[x].montant;
                        }
                    }

                    for(let x=0; x<paymentList.length; x++){
                        if(paymentList[x].monthPayment === shortMonthName){
                            sumPayment += paymentList[x].montant;
                        }
                    }

                    const data = {
                        name: `${shortMonthName} ${comparativeMonthsList[i].year}`,
                        deadline: sumDeadline,
                        payment: sumPayment,
                    }
                    schoolingData.push(data);
                }
            }

            return schoolingData;
        },
        getAllDeadline: async () => {
            const result = await getRequest(`/extra/scolaritecheancier`);
            const responseList =  result._embedded !== undefined ? result._embedded?.scolariteProformaDTOModelList : [];

            let deadLineList:any = [];
            if(responseList.length > 0){
                for (let i=0; i<responseList.length; i++){
                    if(responseList[i].scolariteTypeTag === 'schooling'){
                        deadLineList.push(responseList[i]);
                    }
                }
            }

            return deadLineList.map((deadline:any) => {
                let deadlineDate = moment(deadline.ladate);
                const dateDeadline = deadlineDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthDeadline = deadlineDate.format('MMM');

                return {
                    ...deadline,
                    deadlineDate: dateDeadline.valueOf(),
                    deadlineMonth: monthDeadline,
                }
            });
        },
        getAllChildEcheancierDeadline: async () => {
            const result = await getRequest(`/extra/scolaritecheancierbyenfant`);
            const responseList =  result._embedded !== undefined ? result._embedded?.scolariteProformaEnfantDTOModelList : [];

            let deadLineList:any = [];
            if(responseList.length > 0){
                for (let i=0; i<responseList.length; i++){
                    if(responseList[i].scolariteTypeTag === 'schooling'){
                        deadLineList.push(responseList[i]);
                    }
                }
            }

            return deadLineList.map((deadline:any) => {
                let deadlineDate = moment(deadline.ladate);
                const dateDeadline = deadlineDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthDeadline = deadlineDate.format('MMM');

                return {
                    ...deadline,
                    deadlineDate: dateDeadline.valueOf(),
                    deadlineMonth: monthDeadline,
                }
            });
        },
        getSchoolingPaymentDashboard: (data:any) => {
            return data.map((payment:any) => {
                let paymentDate = moment(data.ladate);
                const datePayment = paymentDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthPayment = paymentDate.format('MMM');

                return {
                    ...payment,
                    datePayment: datePayment.valueOf(),
                    monthPayment: monthPayment,
                }
            });
        },
        getTuitionFeesSum: (
            data:any,
            turnover:any,
            scolariteTypeId:number,
            schoolingSum:number,
            schoolingFees:any,
            lang:string
        ) => {
            //console.log(JSON.stringify(schoolingFees));

            // TUITION FEES
            let tuition = schoolingSum > 0 ? (schoolingSum * 100) / turnover  : 0;
            const tuitionCode = schoolingFees.find((tuition:any) => tuition.id === scolariteTypeId);
            let tuitionPie:any = {
                name: tuitionCode !== undefined ? tuitionCode.code : '',
                value: tuition
            };
            let female = 0;

            // OTHER SCHOOL FEES
            const dataFiltered = data.filter((tuition:any) => tuition.scolariteTypeId !== scolariteTypeId)
            const groupedFeesData = _.groupBy(dataFiltered, 'scolariteTypeId');
            let schoolTuitionFees:any = [];

            if(dataFiltered.length > 0) {
                Object.keys(groupedFeesData).forEach(function(key, index) {
                    const tuitionCode = schoolingFees.find((tuition:any) => tuition.id === parseInt(key));
                    const paymentFeesList = groupedFeesData[key];
                    let feesSum = 0;
                    if(paymentFeesList.length > 0){
                        for(let i= 0; i<paymentFeesList.length; i++){
                            feesSum += paymentFeesList[i].montant;
                        }
                    }

                    const feesSumPie:any = {
                        name: tuitionCode !== undefined ? tuitionCode.code : '',
                        value: feesSum > 0 ? (feesSum * 100) / turnover  : 0,
                    };
                    schoolTuitionFees.push(feesSumPie);
                });
            }

            return [
                tuitionPie,
                ...schoolTuitionFees
            ];
        }
    };
}

export default SchoolingService;
