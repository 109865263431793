import {LivreEmprunt} from "../../../../constants/type/LivreEmprunt";

const livreEmpruntStateUpdate:any = {
    initialized: (logic:any, val:boolean) => logic.setInitialized(val),
    livresEmprunt: (logic:any, val:LivreEmprunt[]) => logic.setLivresEmprunt(val),
    livresEmpruntSelected: (logic:any, val:LivreEmprunt[]) => logic.setLivresEmpruntSelected(val),
    livreEmpruntSel: (logic:any, val:LivreEmprunt) => logic.setLivreEmpruntSel(val),
    openLivreEmpruntEditDialogForm: (logic:any, val:boolean) => logic.setOpenLivreEmpruntEditDialogForm(val),
    openLivreEmpruntDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenLivreEmpruntDeleteDialogForm(val),
    processingLivreEmpruntStateNormBtn: (logic:any, val:boolean) => logic.setProcessingLivreEmpruntStateNormBtn(val),
    showTableContent: (logic:any, val:boolean) => logic.setShowTableContent(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),

}

export default livreEmpruntStateUpdate ;