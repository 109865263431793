import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {request} from "../../../../helpers/axios_helpers";
import SubFolderTypeForm from "./SubFolderTypeForm";
import {useForm} from "react-hook-form";
import {CONSTANT, REGISTRATION_FILES_EN, REGISTRATION_FILES_FR} from "../../../../constants";
import {withSnackbar} from "../../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../../helpers/errorManager";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'blue',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.primary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type SubFolderType = {
    id: number;
    fileName: string;
};

type SubFolderFormValues = {
    id: number;
    fileName: string,
}

function SubFolderType(props:any) {
    const { locale, theme, snackbarShowMessage } = props;
    const classes = {...useStyles(), ...useStyles2()};
    const activeLanguage = locale.split('-');
    const [subFolderTypeData, setSubFolderTypeData] = useState<SubFolderType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateSubFolderType, setStateSubFolderType] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.inscription_file_type} />,
    ];
    const columns = useMemo<MRT_ColumnDef<SubFolderType>[]>(
        () => [
            {
                accessorKey: 'fileName',
                header: columnsHeaders[0],
                size: 800,
            },
        ],
        [],
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const form = useForm<SubFolderFormValues>({
        defaultValues: {
            id: 0,
            fileName: '',
        }
    });
    const { register, handleSubmit, reset, setValue} = form;
    //const {errors} = formState;

    const addSubFolderType = () => {
        setStateSubFolderType(true);
        setValue('fileName', '');
        handleClickOpen();
    }

    const editSubFolderType = (row:any) => {
        setStateSubFolderType(false);
        const dataSubFolderType: any = subFolderTypeData[row.index];
        setValue('id', dataSubFolderType.id);
        setValue('fileName', dataSubFolderType.fileName);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let subFolderData:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateSubFolderType) {
                subFolderData.id = response.data.id;
                subFolderData.fileName = data.fileName;
                subFolderData.couleur = response.data.couleur;
                subFolderData.common = response.data.common;
                setSubFolderTypeData([subFolderData, ...subFolderTypeData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                subFolderTypeData.find((fileType:any, index:any) => {
                    if(fileType.id === data.id) {
                        subFolderTypeData[index].fileName = data.fileName;
                        setSubFolderTypeData([...subFolderTypeData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: SubFolderFormValues) => {
        const dataToSend = {
            nom: data.fileName,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/typesousdossier", dataToSend);
    }

    const onSubmitEditForm = (data: SubFolderFormValues) => {
        const subFolderTypeFind: any = subFolderTypeData.find(fileType => fileType.id === data.id);
        const dataToSend = {
            id: data.id,
            nom: data.fileName,
            couleur: subFolderTypeFind.couleur,
            common: subFolderTypeFind.common
        }
        sendData(data, 'PUT', `/corebase/typesousdossier/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataFileSelectedId:any = [];
        rows.map((row:any) => {
            const data = subFolderTypeData[row.index];
            dataSelected.push(data);
            dataFileSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/typesousdossier/bouquet', dataFileSelectedId).then((response) => {
            const fileList = subFolderTypeData.filter((subFolderType) => {
                if(!dataSelected.includes(subFolderType)){
                    return subFolderType;
                }
            });
            setSubFolderTypeData([...fileList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", "/corebase/typesousdossier", {}).then((response) => {
            let fileListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.typeSousDossierDTOModelList : [];
            fileListRequest = fileListRequest.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });
            const dataList = fileListRequest.map((fileType:any) => {
                const fileList = activeLanguage[0] === 'en' ? REGISTRATION_FILES_EN :REGISTRATION_FILES_FR;
                const fileFind:any = fileList.find((file:any) => file.id === fileType.id);

                let fileData:any = {};
                fileData.id = fileType.id;
                fileData.fileName = fileFind.value; //fileType.nom;
                fileData = {...fileData, ...{ couleur: fileType.couleur, common: fileType.common }}
                return fileData;
            })
            setSubFolderTypeData([...dataList]);
            setTableDataLoading(false);

        }).catch((error) => {
            // Error message
            console.log(error);
            setTableDataLoading(false);
        })
    }, [activeLanguage[0]]);

    return (
        <div className={classes.root}>
            {/*<Box className={classes.buttonContainer}>
                <SubFolderSubMenu classes={classes} selected="subfolder" />
            </Box>*/}

            <div className={classes.tableContainer}>
                <MaterialReactTable
                    columns={columns}
                    data={subFolderTypeData}
                    enableRowSelection={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact',
                        isLoading: tableDataLoading,
                    }}
                    localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing={false}
                    positionActionsColumn="last"
                    key={tableKeyValue}
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={classes.topToolbarActionsButton}
                            sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => addSubFolderType()}
                                disabled={true}
                            >
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button
                                color="error"
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                //disabled={_.isEmpty(table.getSelectedRowModel().rows) && !table.getIsAllRowsSelected()}
                                disabled={true}
                                onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                            >
                                <FormattedMessage id={messages.delete_action} />
                            </Button>
                        </Box>
                    )}
                    /*renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '0.3rem'}}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                                <IconButton
                                    onClick={() => editSubFolderType(row)}
                                    sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}*/
                />

                <SubFolderTypeForm
                    title={ stateSubFolderType ? <FormattedMessage id={messages.dialog_title_sub_folder_type} /> : <FormattedMessage id={messages.dialog_edit_title_inscription_file_type} /> }
                    open={open}
                    setOpen={setOpen}
                    theme={theme}
                    classes={classes}
                    register={register}
                    onSubmit={stateSubFolderType ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                />
            </div>
        </div>
    );
}

export default withSnackbar(SubFolderType);
