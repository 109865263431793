import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {MaterialReactTable, MRT_ColumnDef, MRT_TableOptions} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, Icon, IconButton, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import EditIcon from "@mui/icons-material/Edit";
import React, {useMemo} from "react";
import {makeStyles} from "@mui/styles";
import styles from "../config-table-jss";
import {
    ChildStuff,
    CHILDSTUFF_CONSTANT,
    childStuffDataImpl,
    childStuffSchema
} from "../../../constants/type/ChildStuff";
import useChildStuffVariables from "./businesslogic/ChildStuffVariables";
import {request} from "../../../helpers/axios_helpers";
import childStuffBusinessLogic from "./businesslogic/ChildStuffBusinessLogic";
import _ from "lodash";
import childStuffStateUpdate from "./businesslogic/ChildStuffStateUpdate";
import moment from "moment/moment";
import {getErrorMessage} from "../../../helpers/errorManager";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppointmentPresetContext} from "../../appointment/Appointment";
import ChildStuffForm from "./ChildStuffForm";

const useStyles = makeStyles(styles);
const columnsHeaders:any[] = [
    (<FormattedMessage id={messages.header_nom}/>),
    <FormattedMessage id={messages.header_quantite} />,
];
const useBusinessLogic = (props: any): any => {
    const columns = useMemo<MRT_ColumnDef<ChildStuff>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: columnsHeaders[0],
            },
            {
                accessorKey: 'quantite',
                header: columnsHeaders[1],
                size: 200,
            },
        ],
        [],
    );

    return {
        theme: props.theme,
        classes: useStyles(),
        columns,
        ...useChildStuffVariables(props),
    };
}
const ChildStuffPage= (props:any) => {
    const logicRacine = useBusinessLogic(props);
    const classes = logicRacine.classes;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(childStuffDataImpl),
        // @ts-ignore
        resolver: yupResolver(childStuffSchema),
    }));
    const {errors} = formRef.current.formState;


    const handleSaveRow: MRT_TableOptions<ChildStuff>['onEditingRowSave'] = async({exitEditingMode, row, values}) => {
        if(_.isEmpty(values.nom) || values.nom.length < 2){
            const msgerror = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please fill the name` : "Veuillez renseigner le nom";
            logicRacine.snackbarShowMessage(msgerror, 'error');
            return ;
        }
        if(_.isNumber(values.quantite) || values.quantite < 1){
            const msgerror = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please fill the quantity` : "Veuillez renseigner la quantité";
            logicRacine.snackbarShowMessage(msgerror, 'error');
            return ;
        }
        const toSend = CHILDSTUFF_CONSTANT.convertToBackEnd(logicRacine, values);
        childStuffStateUpdate.savingStateBtn(logicRacine, true);
        childStuffBusinessLogic.saveData(
            logicRacine,
            toSend).then((response:any) => {
            exitEditingMode();
            childStuffStateUpdate.open(logicRacine, false);
            childStuffStateUpdate.savingStateBtn(logicRacine, false);
        }).catch((e:any) => childStuffStateUpdate.savingStateBtn(logicRacine, false));
    };
    const handleClickOpen = () => childStuffStateUpdate.openDialog(logicRacine, true);
    const addData = () => {
        childStuffStateUpdate.childEdit(logicRacine, _.cloneDeep(childStuffDataImpl));
        handleClickOpen();
    };
    const handleRowsDelete = (rows:any) => {

        const dataSelected:any = [];
        const dataSelectedId:any = [];
        rows.map((row:any) => {
            const data = logicRacine.childStuffList[row.index];
            dataSelected.push(data);
            dataSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/childstuff', dataSelectedId).then((response) => {
            const datas     = _.cloneDeep(logicRacine.childStuffList);
            const newDataList = _.filter(datas, (data:ChildStuff) => !dataSelected.includes(data));

            childStuffStateUpdate.childStuffList(logicRacine, newDataList);
            childStuffStateUpdate.tableKeyValue(logicRacine, moment().valueOf());
            logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const editData = (row: any) => {
        const data: any = _.cloneDeep(logicRacine.childStuffList[row.index]);
        childStuffStateUpdate.childEdit(logicRacine,data);
        handleClickOpen();
    }

    React.useEffect(() => {
        childStuffBusinessLogic.initPostConstruct(logicRacine);
    }, []);

    return (
        <div className={classes.root}>
            <MaterialReactTable
                columns={logicRacine.columns}
                data={logicRacine.childStuffList}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density: 'compact',
                    isLoading: logicRacine.tableDataLoading,
                }}
                localization={logicRacine.activeLanguageRef.current[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                editDisplayMode="row"
                onEditingRowSave={handleSaveRow}
                enableRowSelection
                positionActionsColumn="last"
                key={logicRacine.tableKeyValue}
                icons={{
                    SaveIcon: (props: any) => (<DoneIcon {...props} color="primary" sx={{marginLeft: -2,}}/>),
                    CancelIcon: (props: any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{sx: {maxHeight: '500px'}}}
                renderTopToolbarCustomActions={({table}: { table: any }) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{display: 'flex', flexWrap: 'wrap'}}
                    >
                        <Button variant={"contained"}
                                component="label"
                                size="small"
                                color="primary"
                                sx={{textTransform: 'none', marginLeft: 1, paddingLeft: 1, paddingRight: 2}}
                                onClick={addData}
                        >
                            <Icon sx={{marginRight: 1}}>add</Icon>
                            <FormattedMessage id={messages.add_action}/>
                        </Button>

                        <Button variant={"contained"}
                                component="label"
                                size="small"
                                color="error"
                                sx={{textTransform: 'none', marginLeft: 2, paddingLeft: 1, paddingRight: 2}}
                                disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <Icon sx={{marginRight: 1}}>delete</Icon>
                            <FormattedMessage id={messages.delete_action}/>
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                onClick={() => editData(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />

            <AppointmentPresetContext.Provider value={logicRacine}>
                <Box>
                    <ChildStuffForm />
                </Box>
            </AppointmentPresetContext.Provider>

        </div>
    )
}

export default withSnackbar(ChildStuffPage);