import React from 'react';
import {Dialog, DialogContent, DialogProps, IconButtonProps} from "@mui/material";
import Paper, {PaperProps} from "@mui/material/Paper";
import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {styled} from "@mui/material/styles";
import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";

const IconButtonClose = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
        border: '1px solid',
    },
    width: 26,
    height: 26,
    zIndex:1,
    right:0,
    marginBottom: -25,
    border: `1px solid ${theme.palette.primary.main}`,
}));

const ButtonDialogAction = styled(Button)(({theme}) => ({
    padding: '3px',
    paddingLeft:'10px',
    paddingRight:'10px',
    textTransform: 'capitalize'
}))

const useStyles = makeStyles((theme:any) => ({
    dialogFormTitle: {
        textAlign:'center',
        backgroundColor: 'transparent',
        cursor: 'move'
    },
    formInputModal: {
        '& .MuiOutlinedInput-root':{
            fontWeight: 500,
            fontSize: '0.93em',
        },
        '& .css-1xw7kbb-MuiFormControl-root-MuiTextField-root .MuiInputBase-root': {
            paddingTop: 5,
            paddingBottom: 2,
        },
    },
    title: {
        textAlign:"center",
        paddingTop:15,
        //marginBottom:'20px'
    },
    draggableBox: {
        width: 'auto',
        minHeight: 30
    }

}));


function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function DialogForm(props:any){
    const { theme, open, setOpen, children, title, width, disablePostAction, onSubmit, btnCloseLabel=null } = props
    const classes = useStyles(props);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

    const handleClose = () => {
        setOpen(false);
    };
    const handleBackdropClose = (event: any, reason: any) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const handleBackdropClick = (event:any) => {
        event.stopPropagation();
        return false;
    };

    const handleSubmit = () => {
        onSubmit();
    }

    return (
        <div>
            <form noValidate>
                <Dialog
                    //fullScreen={true}
                    fullWidth={width}
                    maxWidth={width ? maxWidth : 'md'}
                    open={open}
                    onClose={handleBackdropClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{
                        style: {
                            boxShadow: 'none',
                            border: '1px solid #ececec', //e1e1e1
                            opacity:1,
                        },
                    }}
                    onBackdropClick={handleBackdropClick}
                >
                    <div className={classes.dialogFormTitle} id="draggable-dialog-title">
                        <div className={classes.title}>
                            <Typography variant="h6">{title}</Typography>
                        </div>
                    </div>
                    <DialogContent sx={{ backgroundColor: 'white',  width: { xs: 'auto', sm:  width ? 'auto' : '55ch' } }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { width: { xs: 'auto', sm: width ? 'auto' : '55ch' }},
                            }}
                            noValidate
                            autoComplete="off"
                            className={classes.formInputModal}
                        >
                            {children}
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: 'white'}}>
                        <ButtonDialogAction autoFocus onClick={handleClose} size="small" color={"secondary"}>
                            {<FormattedMessage id={btnCloseLabel ?? messages.dialog_cancel} />}
                        </ButtonDialogAction>
                        <ButtonDialogAction onClick={handleSubmit} variant="contained" size="small" color={"primary"} disabled={disablePostAction}>
                            {<FormattedMessage id={messages.dialog_post} />}
                        </ButtonDialogAction>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    )
}

export default DialogForm