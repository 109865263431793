import {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ClasseRoom} from "../../../../constants/type/ClasseRoom";
import {Child} from "../../../../constants/type/Child";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {Parent} from "../../../../constants/type/Parent";
import {Profils} from "../../../../constants/type/Profils";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {personCustomOptions} from "../../../../constants/type/Person";

const useSecuriteParentVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'SecuriteParent',  updateId:1, customOptions:personCustomOptions}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<Parent>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openConfirmDialogForm, setConfirmDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = useState<any>();


    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const employeesRef  = useRef<Employee[]>([]);
    const profilsRef  = useRef<Profils[]>([]);
    const employeesByFonctionRef  = useRef<Employee[]>([]);
    const childrenRef  = useRef<Child[]>([]);
    const childrenClasseRef  = useRef<Child[]>([]);
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const editDisableRef = useRef<boolean>(true);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());

    const [dataList, setDataList] = useState<Parent[]>([]);
    const [dataListSelected, setDataListSelected] = useState<Parent[]>([]);
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(false);
    const [showDetailsTableContent, setShowDetailsTableContent] = useState<boolean>(true);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openConfirmDialogForm,setConfirmDialogForm,
        processingStateNormBtn,setProcessingStateNormBtn,confirmMessage,setConfirmMessage,
        dataList,setDataList,dataListSelected,setDataListSelected,tableDataLoading,seTableDataLoading,classeRoomRef,
        showDetailsTableContent,setShowDetailsTableContent,classeRoomMapRef,
        childrenRef, childrenClasseRef,editDisableRef,contentLoaded,setContentLoaded,
        employeesByFonctionRef, employeesRef, jobsRef,tableKeyValue,setTableKeyValue,tableConfigRef,
        profilsRef, ...logicMRT
    }
}
export default useSecuriteParentVariables;