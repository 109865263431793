import {alpha, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: 10,
            paddingTop: 4,
            paddingBottom: 4,
            height: 190,
            marginBottom: 6,
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                height: 126,
                marginBottom: -1,
                alignItems: 'flex-end',
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        title: {
            color: theme.palette.common.white,
            fontSize: '13px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '15px !important',
            },
            fontWeight: 400,
            lineHeight: '25px !important',
        },
        number: {
            color: theme.palette.common.white,
            fontSize: '25px !important',
            fontWeight: 500,
        },
        ncCustomContent: {
            textAlign: 'right',
        },
        primaryLight: {
            background: theme.palette.primary.light,
            '& $title, $number': {
                color: theme.palette.primary.main,
            },
        },
        primaryMain: {
            border: `1px solid ${alpha(theme.palette.primary.main, 0.7)}`,
            '& $title, $number': {
                color: theme.palette.primary.main,
            },
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        primaryDark: {
            background: `${theme.palette.primary.main} !important`,
            '& $title, $number': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.primary.light,
            },
        },
        secondaryLight: {
            background: theme.palette.secondary.light,
            '& $title, $number': {
                color: theme.palette.secondary.main,
            },
        },
        secondaryMain: {
            border: `1px solid ${alpha(theme.palette.secondary.main, 0.7)}`,
            '& $title, $number': {
                color: theme.palette.secondary.main,
            },
            '& svg': {
                color: theme.palette.secondary.main,
            },
        },
        secondaryDark: {
            background: `${theme.palette.secondary.main} !important` ,
            '& $title, $number': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.secondary.light,
            },
        },
        thirdlyMain: {
            background: `${theme.palette.warning.main} !important`,
            '& $title, $number': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.common.white,
            },
        },
        fourthMain: {
            background:  `${theme.palette.success.main} !important`,
            '& $title, $number': {
                color: theme.palette.common.white,
            },
            '& svg': {
                color: theme.palette.primary.light,
            },
        },
    }
})

export default function KpIndicatorWidget(props:any) {
    const classes = useStyles();
    const { color, title, children, number, devise, moment } = props;

    const bgColor = (clr:string) => {
        switch (clr) {
            case 'primary-light':
                return classes.primaryLight;
            case 'primary-dark':
                return classes.primaryDark;
            case 'secondary-light':
                return classes.secondaryLight;
            case 'secondary-main':
                return classes.secondaryMain;
            case 'secondary-dark':
                return classes.secondaryDark;
            case 'thirdly-main':
                return classes.thirdlyMain;
            case 'fourth-main':
                return classes.fourthMain;
            default:
                return classes.primaryMain;
        }
    };


    return(
        <Paper className={classNames(classes.root, bgColor(color))}>
            <div>
                <Typography className={classes.number}>
                    {number} {devise}
                </Typography>
                <Typography className={classes.title} variant="subtitle1">
                    {title} <br /> {moment}
                </Typography>
            </div>
            <div className={classes.ncCustomContent}>{children}</div>
        </Paper>
    )
}
