import {ScolariteData} from "../../../../constants/type/ScolariteData";
import {Child} from "../../../../constants/type/Child";
import {ScolariteVersement} from "../../../../constants/type/ScolariteVersement";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {GroupEnfant} from "../../../../constants/type/GroupEnfant";
import {ScolariteEcheancier} from "../../../../constants/type/ScolariteEcheancier";


const scolaritePaiementStateUpdate:any = {
    versements: (logic:any, val:ScolariteVersement[]) => logic.setVersements(val),
    dataList: (logic:any, val:ScolariteData[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:ScolariteData[]) => logic.setDataListSelected(val),
    scolariteGroupList: (logic:any, val:ScolariteGroup[]) => logic.setScolariteGroupList(val),
    scolariteGroupIdListSel: (logic:any, val:number[]) => logic.setScolariteGroupIdListSel(val),
    idScolariteAnneeSel: (logic:any, val:number[]) => logic.setIdScolariteAnneeSel(val),
    scolaEcheancierDetails: (logic:any, val:ScolariteEcheancier[]) => logic.setScolaEcheancierDetails(val),
    scolariteTypeIdsSel: (logic:any, val:number[]) => logic.setScolariteTypeIdsSel(val),
    groupEnfantSel: (logic:any, val:GroupEnfant) => logic.setGroupEnfantSel(val),
    childSelected: (logic:any, val:Child) => logic.setChildSelected(val),
    dataSel: (logic:any, val:ScolariteData) => logic.setDataSel(val),
    dataDetailsSel: (logic:any, val:ScolariteData) => logic.setDataDetailsSel(val),
    versementSel: (logic:any, val:ScolariteVersement) => logic.setVersementSel(val),
    editDisableRef: (logic:any, val:boolean) => logic.editDisableRef.current = val,
    paiementListInited: (logic:any, val:boolean) => logic.setPaiementListInited(val),
    openPDFDialogForm: (logic:any, val:boolean) => logic.setOpenPDFDialogForm(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    contentLoaded: (logic:any, val:boolean) => logic.setContentLoaded(val),
    showDetailsTableContent: (logic:any, val:boolean) => logic.setShowDetailsTableContent(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    tableDetailsKeyValue: (logic:any, val:any) => logic.setTableDetailsKeyValue(val),

}

export default scolaritePaiementStateUpdate ;