export const IMAGE_RIGHTS_FR = [
    {
        id: 1,
        value: 'Video',
    },
    {
        id: 2,
        value: 'Lettre du vendredi',
    },
    {
        id: 3,
        value: 'Annuaire de l\'école',
    },
    {
        id: 4,
        value: 'Magazine',
    },
    {
        id: 5,
        value: 'Instagram',
    },
    {
        id: 6,
        value: 'Newsletter',
    },
    {
        id: 7,
        value: 'Site Internet',
    },
    {
        id: 8,
        value: 'Prospectus',
    },
    {
        id: 9,
        value: 'Facebook',
    },
];

export const IMAGE_RIGHTS_EN = [
    {
        id: 1,
        value: 'Video',
    },
    {
        id: 2,
        value: 'Friday Newsletter',
    },
    {
        id: 3,
        value: 'School Directory',
    },
    {
        id: 4,
        value: 'Magazine',
    },
    {
        id: 5,
        value: 'Instagram',
    },
    {
        id: 6,
        value: 'Newsletter',
    },
    {
        id: 7,
        value: 'Website',
    },
    {
        id: 8,
        value: 'Flyers',
    },
    {
        id: 9,
        value: 'Facebook',
    },
]
