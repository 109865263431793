import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {ScolariteAutresFraisContext} from "./ScolariteListeFrais";
import scolariteFraisStateUpdate from "./businesslogic/ScolariteFraisStateUpdate";
import {
    columnsScolariteType,
    excelScolariteTypeDataMapping,
    ScolariteType,
    scolariteTypeDataImpl
} from "../../../constants/type/ScolariteType";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import messages from "../../messages/general/messages";

function ScolariteListeFraisList() {
    const logic: any = useContext(ScolariteAutresFraisContext);
    const styleClasses = logic.styleClasses;

    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    //const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';



    const addItem = () => {
        scolariteFraisStateUpdate.dataSel(logic, _.cloneDeep(scolariteTypeDataImpl));
        scolariteFraisStateUpdate.openEditDialogForm(logic, true);
    }

    const editItem = (row:MRT_Row<ScolariteType>) => {
        const data:ScolariteType = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        scolariteFraisStateUpdate.dataSel(logic, _.cloneDeep(data));
        scolariteFraisStateUpdate.openEditDialogForm(logic, true);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:ScolariteType[] = [];
        rows.map((row:any) => {
            const data:ScolariteType = logic.dataList[row.index];
            dataSelected.push(data);
        });
        scolariteFraisStateUpdate.dataListSelected(logic, dataSelected);
        scolariteFraisStateUpdate.openDeleteDialogForm(logic, true);
    };

    const columns = useMemo<MRT_ColumnDef<ScolariteType>[]>(
        () => [...columnsScolariteType({intl:intl})],
        [logic.activeLang],
    );
    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.loading,
            showSkeletons: logic.loading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getIsSomeRowsSelected())}
                styleClasses={logic.styleClasses}
                export_xls_title={export_xls}
                xls_nom_fichier="Tuitions_Fees"
                excelDataMapping={excelScolariteTypeDataMapping({
                    rows:table.getPrePaginationRowModel().rows,
                    columns: table?.getVisibleFlatColumns(),
                    table: table,
                    intl,
                    infoSpace: ``,
                })}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={logic.styleClasses}
            />
        )
    });
    return (
        <div className={styleClasses.tableContainer}>
            <MaterialReactTable
                table={logic.tableConfigRef.current}
                key={logic.tableKeyValue}
            />
        </div>
    );
}
export default ScolariteListeFraisList ;