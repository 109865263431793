import {useContext} from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {AlbumPhotoContext} from "./AlbumPhoto";
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {AlbumPicture} from "../../constants/type/AlbumPicture";
import _ from "lodash";
import albumPhotoStateUpdate from "./businesslogic/AlbumPhotoStateUpdate";

function AlbumPhotoCanvas(props: any) {
    const logic: any = useContext(AlbumPhotoContext);
    const styleClasses = logic.styleClasses;

    const handlePhotoCheck = (data:AlbumPicture) => {
        let ids = _.cloneDeep(logic.photosIdsSelected);
        if(ids.includes(data.id)){
            _.remove(ids, function(n) {
                return n === data.id;
            });
        } else {
            ids.push(data.id);
        }
        albumPhotoStateUpdate.photosIdsSelected(logic, ids);
    }
    return (
        <div style={{display: "flex", backgroundColor:"lightblue"}}>
            <ImageList sx={{ width: '100%', height: 450 }} cols={4}>
                {logic.photoList.map((item:any) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=150&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=150&fit=crop&auto=format`}
                            alt={item.nom}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.nom}
                            subtitle={<span>by: @{item.author}</span>}
                            sx={{
                                "& .MuiImageListItemBar-title": { color: "white" }, //styles for title
                                "& .MuiImageListItemBar-subtitle": { color: "darkgray" }, //styles for subtitle
                            }}
                            actionIcon={
                                <IconButton
                                    sx={{ color: logic.photosIdsSelected.includes(item.id) ? 'rgba(0,149,241,0.54)' : 'rgba(255, 255, 255, 0.54)'} }
                                    aria-label={`info about ${item.title}`}
                                    onClick={() => handlePhotoCheck(item)}
                                >
                                    {logic.photosIdsSelected.includes(item.id) ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                                </IconButton>
                            }
                            position="below"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}
export default AlbumPhotoCanvas ;

