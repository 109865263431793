import {Sondage} from "../../../constants/type/Sondage";
import {SondageResponse} from "../../../constants/type/SondageResponse";


const sondageStateUpdate:any = {
    dataList: (logic:any, val:Sondage[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:Sondage[]) => logic.setDataListSelected(val),
    reponseList: (logic:any, val:SondageResponse[]) => logic.setReponsesList(val),
    dataSel: (logic:any, val:Sondage) => logic.setDataSel(val),
    updateHeaderAnchor: (logic:any, val:any) => logic.setAnchorElHeader(val?.currentTarget ?? null),
    showMobileDetail: (logic:any, val:boolean) => logic.setShowMobileDetail(val),
    listeDesSondages: (logic:any, val:any) => logic.setListeDesSondages(val),
    dataDetailsSel: (logic:any, val:Sondage) => logic.setDataDetailsSel(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingSubmitStateNormBtn: (logic:any, val:boolean) => logic.setProcessingSubmitStateNormBtn(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    showTableContent: (logic:any, val:boolean) => logic.setShowTableContent(val),
    showDetailsTableContent: (logic:any, val:boolean) => logic.setShowDetailsTableContent(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    tableDetailsKeyValue: (logic:any, val:any) => logic.setTableDetailsKeyValue(val),
    expandOpenedSondage: (logic:any, val:boolean) => logic.setExpandOpenedSondage(val),
    expandClosedSondage: (logic:any, val:boolean) => logic.setExpandClosedSondage(val),

}

export default sondageStateUpdate ;