import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import groupEnfantStateUpdate from "./GroupEnfantStateUpdate";
import {FormattedMessage} from "react-intl";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {Fonction} from "../../../../constants/type/Fonction";
import {processingChildren, processingJobs} from "../../../../helpers/loader_helpers";
import {Child} from "../../../../constants/type/Child";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {isNumericValue} from "../../../../helpers/helpers";

const groupEnfantBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        const requestbody = ['classes', 'profils', 'groups', 'scolariteannees', 'fonctions', 'enfants'];
        const convertToFrontEnd = (data:any) => {

            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            groupEnfantStateUpdate.idScolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false})?.id ?? 0);

            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomMapRef.current = new Map();
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);


            const itemsgs : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemsgs,  item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

        };

        groupEnfantStateUpdate.tableDataLoading(logic, true);
        groupEnfantStateUpdate.contentLoaded(logic, false);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            groupEnfantStateUpdate.tableDataLoading(logic, false);
            groupEnfantStateUpdate.contentLoaded(logic, true);
        }).catch(e => {
            groupEnfantStateUpdate.tableDataLoading(logic, false);
            groupEnfantStateUpdate.contentLoaded(logic, true);
        });
        //groupEnfantBusinessLogic.loadGroupEnfants(logic).then((val:any) => {});
    },
    saveEdit: async (logic:any, messages:any, toSends:any[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request("POST", `/extra/groupsenfants/bouquet`, toSends).then((response) => {
                // @ts-ignore
                groupEnfantBusinessLogic.fetchElevesOfGroup(logic, logic.idScolariteAnneeSel,  logic.idGroupSel);
                groupEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                groupEnfantStateUpdate.dataListSelected(logic, []);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadGroupEnfants: (logic:any) => {
        groupEnfantStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/groupsenfants`, {}).then((response) => {
                const items : GroupEnfant[]   = response.data._embedded?.groupEnfantDTOModelList ?? [];

                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
                groupEnfantStateUpdate.dataList(logic, itemsfrontend) ;
                //logic.setDataList(_.cloneDeep(itemsfrontend));
                groupEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                groupEnfantStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                groupEnfantStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    loadEnfantOfGroup: async (logic:any, idScolariteannee:number, idGroup:number) => {
        return new Promise<GroupEnfant[]>(resolve =>
            request("GET",  `/extra/groupsenfants/${idGroup}/${idScolariteannee}`, {}).then((response) => {
                const items : GroupEnfant[]   = response.data ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
                resolve(itemsfrontend);
            }).catch((error) => {
                console.error(error);
                resolve([]);
            })
        );
    },
    fetchElevesOfGroup : async (logic:any, idYearSchool:number, idGroup:number) => {
        if(idYearSchool > 0 && idGroup > 0){
            groupEnfantBusinessLogic.loadEnfantOfGroup(logic, idYearSchool, idGroup).then((values:GroupEnfant[]) => {
                groupEnfantStateUpdate.dataList(logic, values);
            });
        }
    },
    deleteDatas: async (logic:any, messages:any, toSends:GroupEnfant[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const updateAfterDelete = (idsToDelete:number[]) => {
                const datas = _.filter(_.cloneDeep(logic.dataList), item => !_.includes(idsToDelete, item.id));
                groupEnfantStateUpdate.dataList(logic, datas);
                groupEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
                groupEnfantStateUpdate.dataListSelected(logic, []);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }
            const idsRemoteDatas:number[] = [];
            const idsNewDatas:number[] = [];
            for(const l of toSends){
                if(isNumericValue(l.id) && (l.id ?? -1) > 0){
                    // @ts-ignore
                    idsRemoteDatas.push(l.id);
                    continue ;
                }
                // @ts-ignore
                idsNewDatas.push(l.id);
            }

            if(_.isEmpty(idsRemoteDatas)){
                updateAfterDelete(idsNewDatas);
            }
            else {
                request('DELETE', `/extra/groupsenfants/bouquet`, idsRemoteDatas).then((response) => {
                    // @ts-ignore
                    updateAfterDelete(_.concat(idsRemoteDatas, idsNewDatas));
                }).catch((error) => {
                    logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                    resolve(false);
                });
            }

        });
    }
}
export default groupEnfantBusinessLogic ;