import * as yup from "yup";
import {Genre} from "./Genre";
import {RoleUser} from "./RoleUser";
import moment from "moment";
import _ from "lodash";

export const USERCHATMODEL_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:UserChatModel) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(userChatModelDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:UserChatModel) => item
};

export  const userChatModelDataImpl: UserChatModel = {
    userId: 0,
    uuid: '',
    nom: '',
    fonction: '',
    role: RoleUser.SUPPORT,
    genre: Genre.FEMME,
    photo: '',
    enfantNom: '',
    connexionDate: moment().valueOf(),
}


export const userChatModelSchema = yup.object().shape({
    userId: yup.number(),
    uuid: yup.string().nonNullable().default(''),
    nom: yup.string(),
    fonction: yup.string(),
    role: yup.mixed<RoleUser>().oneOf(Object.values(RoleUser)).default(RoleUser.SUPPORT),
    genre: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.FEMME),
    photo: yup.string(),
    enfantNom: yup.string(),
    connexionDate: yup.number().default(moment().valueOf()),
});

export type UserChatModel = yup.InferType<typeof userChatModelSchema>;