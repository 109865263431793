import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import moment from "moment";
import ChildrenService from "../../service/ChildrenService";
import ClassroomService from "../../service/ClassroomService";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectChangeEvent} from "@mui/material/Select";
import {dropZoneFilePreview} from "../../helpers/helpers";
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Grid} from "@mui/material";
import Loading from "../../components/ui/Loading";
import StudentForm from "./StudentForm";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import dataPays from "../../data/pays";
import {getRequest, uploadFileRequest} from "../../helpers/axios_helpers";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {
    CONSTANT,
    IMAGE_RIGHTS_EN,
    IMAGE_RIGHTS_FR,
    REGISTRATION_FILES_EN,
    REGISTRATION_FILES_FR
} from "../../constants";
import ParentService from "../../service/ParentService";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {},
        addFormTitle: {
            textAlign: 'center',
            margin: 0,
            padding: 15,
            borderRadius: 7,
            fontSize: '18px',
            backgroundColor: '#ededed',
            marginBottom: 15,
        },
        stepTitle: {
            paddingLeft: 10,
        },
        separator: {
            marginBottom: 25,
        }
    }
})

function EditChild(props:any) {
    const {
        locale, theme, snackbarShowMessage,
    } = props;
    const params = useLocation();
    const { data } = params.state;

    const classes = {...useStyles(), ...useStyles2()};
    const activeLang = locale.split('-')[0];
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statusChildForm, setStatusChildForm] = useState(false);
    const [classroomData, setClassroomData] = useState<any[]>([]);
    const [childrenDataList, setChildrenDataList] = useState<any>([]);

    //FORM DATA
    const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
    const [countries, setCountries] = useState<any>([]);
    const [gender, setGender] = React.useState('');
    const [profile, setProfile] = React.useState(1);
    const [birthDate, setBirthDate] = useState(moment(new Date()));
    const [classroom, setClassroom] = React.useState<any | ''>('');
    const [imageRightsData, setImageRightsData] = useState<any>([]);
    const [authorizationPersonFormValues, setAuthorizationPersonFormValues] = useState<any[]>([{ firstname_authorization: '', lastname_authorization : '', phone_number_authorization : ''}]);
    const [mainParent, setMainParent] = useState('mother');
    const [siblings, setSiblings] = useState<any[]>([]);
    const [siblingsOriginal, setSiblingsOriginal] = useState<any[]>([]);
    const [siblingsToRemove, setSiblingsToRemove] = useState<any[]>([]);
    const [displaySiblings, setDisplaySiblings] = useState(false);
    const searchPlaceholder:string | null = activeLang === 'en' ? 'Search by name' : 'Recherche par nom';
    const [optionRegistration, setOptionRegistration] = useState<any>([]);
    const [registrationsFiles, setRegistrationsFiles] = useState<any>([]);
    const [optionImagesRights, setOptionImagesRights] = useState<any>([]);
    const [loadingButton, setLoadingButton] = useState(false);

    const classroomService = ClassroomService();
    const childrenService = ChildrenService();
    const parentService = ParentService();


    const schema = yup.object().shape({
        id: yup.number(),
        firstname: yup.string().min(2).required(),
        lastname: yup.string().min(2).required(),
        nationalities: yup.array().of(yup.object().required()).min(1),
        genre: yup.string().required(),
        profile: yup.number(),
        address: yup.string(),
        classroom: yup.object().required(),
        firstNameMother: yup.string(),
        lastNameMother: yup.string(),
        phoneNumberMother: yup.string(),
        emailMother: yup.string(),
        firstNameFather: yup.string(),
        lastNameFather: yup.string(),
        phoneNumberFather: yup.string(),
        emailFather: yup.string(),
        allergies: yup.string(),
        foodRestriction: yup.string(),
        healthIssue: yup.string(),
        registrationFilesType: yup.array(),
        imageRightsTabId: yup.array(),
        firstnameLastnameContact1: yup.string(),
        //phoneNumberContact1: yup.string().notRequired().matches(phoneRegExp, "Enter a valid phone number"),
        phoneNumberContact1: yup.string().notRequired(),
        firstnameLastnameContact2: yup.string(),
        phoneNumberContact2: yup.string().notRequired(),
    });

    const form = useForm({
        defaultValues:{
            emailFather: ''
        },
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue, getValues} = form;
    const {errors} = formState;

    const handleClassroomChange = (event:any) => {
        classroomData.map((selectedClass:any) => {
            if(selectedClass === event.target.value){
                setClassroom(selectedClass);
                setValue('classroom', selectedClass);
            }
        })
    }
    const handleDateChange = (date:any) => {
        setBirthDate(date);
    };
    const handleGenderChange = (event:any) => {
        setGender(event.target.value);
    }
    const handleProfileChange = (event:any) => {
        setProfile(event.target.value);
    }
    const handleSelectOptionChange = (event: any) => {
        const value:any = event.target.value
        setSelectedOptions(value);
        setValue('nationalities', value);
    };
    const handleChangeMainParent = (event: any) => {
        const value:any = event.target.value;
        setMainParent(value);
    };
    const handleRemoveSibling = async (siblingSelected:any) => {
        try {
            const result:any = siblings.filter((child:any) => child.id !== siblingSelected.id);
            setSiblings(result);

            /*let parentIdsToDelete:any = [];
            const parentTabIds = siblingSelected.enfantParents;
            if(parentTabIds.length > 0){
                for(let i=0; i<parentTabIds.length; i++){
                    parentIdsToDelete.push(parentTabIds[i]?.parent?.id);
                }
            }*/

            const fratrieToRemoveFunded = siblingsOriginal.find((fratrie:any) => fratrie.id === siblingSelected.id);
            if(fratrieToRemoveFunded !== undefined){
                setSiblingsToRemove([...siblingsToRemove, fratrieToRemoveFunded.id]);
            }

            // await childrenService.removeChildFromSiblings(data?.person?.id, parentIdsToDelete);
        }
        catch (error) {
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        }
    }
    const handleSiblingsChange = (child:any) => {
        if(child !== null) {
            const childFind:any = childrenDataList.find(({ id } : {id:number}) => id === child.id);
            const result = siblings.some(value => value.id === child.id);
            if(!result){
                setSiblings([childFind, ...siblings]);
                setDisplaySiblings(true);
            }
        }
    }
    const handleImageRightChipChange = (event: SelectChangeEvent<typeof optionImagesRights>) => {
        const value:any = event.target.value;
        setOptionImagesRights(value);
    }
    const addAuthorizationPersonFormFields = () => {
        setAuthorizationPersonFormValues([
            ...authorizationPersonFormValues,
            { firstname_authorization: '', lastname_authorization : '', phone_number_authorization : ''}
        ]);
    }
    const handleRegistrationChipChange = (event: SelectChangeEvent<typeof optionRegistration>) => {
        const value:any = event.target.value
        setOptionRegistration(value);
    }
    const removeAuthorizationPersonFormFields = (i:any) => {
        let newFormValues = [...authorizationPersonFormValues];
        newFormValues.splice(i, 1);
        setAuthorizationPersonFormValues(newFormValues);
    }
    const handleRegistrationChange = (event: any) => {
        const registrationChecked:any = event.target.value;
    };
    const handleAuthorizationPersonChange = (i:any, e:any) => {
        const newFormValues:any = [...authorizationPersonFormValues];
        newFormValues[i][e.target.name] = e.target.value;
        setAuthorizationPersonFormValues(newFormValues);
    }
    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';

        uploadFileRequest(method, url, dataToSend).then((response) => {
            message = <FormattedMessage id={messages.sb_edit_success} />
            snackbarShowMessage(message);
            setLoadingButton(false);

        }).catch((error) => {
            // Error message
            setLoadingButton(false);
            console.log(error);
            if(error?.response?.data?.codeMessage === 'PERSON_ALREADY_EXIST_EXCEPTION'){
                message = error?.response?.data?.message;
            }
            else {
                message = <FormattedMessage id={messages.sb_action_error} />;
            }
            snackbarShowMessage(message ?? <FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const onSubmitEditForm = (data:any) => {
        setLoadingButton(true);
        const childFind = childrenDataList.find((child:any) => child.id === data.id);
        let paysPersonEnfant:any = childFind?.person?.paysPersons;
        let nationalityEnfant:any = [];

        //console.log(JSON.stringify(childFind));

        if(mainParent === 'mother' && (data.firstNameMother === '' ||  data.lastNameMother === '' || data.phoneNumberMother === '' || data.emailMother === '' ) ){
            snackbarShowMessage(<FormattedMessage id={messages.all_mother_information} />, 'error');
            setLoadingButton(false);
        }
        else if(mainParent === 'father'  && (data.firstNameFather === '' ||  data.lastNameFather === '' || data.phoneNumberFather === '' || data.emailFather === '' )){
            snackbarShowMessage(<FormattedMessage id={messages.all_father_information} />, 'error');
            setLoadingButton(false);
        }
        else {
            // NATIONALITIES
            if(data.nationalities.length > 0){
                nationalityEnfant = data.nationalities.map((nationality:any) => {
                    let id = null;
                    let common = CONSTANT.common;
                    if(paysPersonEnfant.length > 0) {
                        for(let i=0; i < paysPersonEnfant.length; i++){
                            if(paysPersonEnfant[i].paysId == nationality.id){
                                id = paysPersonEnfant[i].id;
                                common = paysPersonEnfant[i].common;
                            }
                        }
                    }

                    return {
                        id:id,
                        personId: data?.id,
                        paysId: nationality?.id,
                        common: common,
                    };
                });
            }

            // IMAGE RIGHTS
            let oldImageRight:any = childFind.droitImages;
            let imageRights:any = [];
            if(optionImagesRights.length > 0){
                imageRights = optionImagesRights.map((image:any) => {
                    let id = null;
                    let common = CONSTANT.common;
                    if(oldImageRight.length > 0) {
                        for(let i=0; i < oldImageRight.length; i++){
                            if(oldImageRight[i]?.droitImageId == image?.id){
                                id = oldImageRight[i]?.id;
                                //common = CONSTANT.common;
                                common =  oldImageRight[i]?.common
                            }
                        }
                    }

                    return {
                        id:id,
                        enfantId: data?.id,
                        droitImageId: image?.id,
                        common: common,
                    }
                });
            }

            // CHILD REGISTRATION FILES
            let dossierEnfants:any;
            let contentDossierEnfant:any = [];
            if(optionRegistration.length > 0){
                const documentDossierEnfants = optionRegistration.map((dossier:any) => {
                    const dossierEnfantsFind = childFind?.dossierEnfants[0]?.documentDossierEnfants;

                    let id = null; let dossierEnfantId = null; let fichierNom = ''; let fichierUri = ''; let common = CONSTANT.common; let libelle = '';
                    if(dossierEnfantsFind !== undefined && dossierEnfantsFind.length > 0){
                        const documentFind = dossierEnfantsFind.find((document:any) => document.dossierConstitutifId === dossier.dossierConstitutifId);
                        if(documentFind !== undefined){
                            id = documentFind?.id;
                            dossierEnfantId = documentFind?.dossierEnfantId;
                            fichierNom = documentFind?.fichierNom;
                            fichierUri = documentFind?.fichierUri;
                            common = documentFind?.common;
                            libelle = documentFind?.libelle;
                        }
                    }

                    return {
                        id: id,
                        dossierEnfantId: dossierEnfantId,
                        dossierConstitutifId: dossier.dossierConstitutifId, // null
                        fichierNom: fichierNom,
                        fichierUri: fichierUri,
                        common: common,
                        libelle: libelle,
                    }
                });

                contentDossierEnfant = [
                    {
                        ...childFind?.dossierEnfants[0],
                        id: childFind.dossierEnfants.length > 0 ? childFind?.dossierEnfants[0]?.id : null,
                        enfantId: data?.id,
                        typeDossierId: optionRegistration[0]?.typeDossierId,
                        common: optionRegistration[0]?.common,
                        documentDossierEnfants: documentDossierEnfants,
                    }
                ];
            }
            dossierEnfants = optionRegistration.length > 0 ? contentDossierEnfant : [];


            // EMERGENCY CONTACT
            let emergencyNom1:string = data?.firstnameLastnameContact1;
            let emergencyPhone1:string = data?.phoneNumberContact1;
            if(data?.firstnameLastnameContact1 !=="" && data?.phoneNumberContact1 !=""){
                emergencyNom1 = data?.firstnameLastnameContact1;
                emergencyPhone1 = data?.phoneNumberContact1;
            }

            let emergencyNom2:string = data?.firstnameLastnameContact2;
            let emergencyPhone2:string = data?.phoneNumberContact2;
            if(data?.firstnameLastnameContact2 !=="" && data?.phoneNumberContact2 !=""){
                emergencyNom2 = data?.firstnameLastnameContact2;
                emergencyPhone2 = data?.phoneNumberContact2;
            }

            //PERSONNE AUTORISEE
            let personneAutoriserEnfants:any = [];
            const dateAuthorization:any = moment(new Date());
            const dateBirthAuthorization:any = moment(new Date());
            if(authorizationPersonFormValues.length > 0){
                for(let i=0; i< authorizationPersonFormValues.length; i++){
                    const authorizationData = authorizationPersonFormValues[i];
                    const personneAutoriseFind = childFind?.personneAutoriserEnfants
                    if(authorizationData?.firstname_authorization !=="" && authorizationData?.lastname_authorization && authorizationData?.phone_number_authorization){

                        const personneAutoriser:any = {
                            id: personneAutoriseFind.length > 0 ? personneAutoriseFind[i]?.id : 0,
                            enfantId: personneAutoriseFind.length > 0 ? personneAutoriseFind[i]?.enfantId : 0,
                            dateDebut: personneAutoriseFind.length > 0 ? personneAutoriseFind[i]?.dateDebut : dateAuthorization.valueOf(),
                            dateFin: personneAutoriseFind.length > 0 ? personneAutoriseFind[i]?.dateDebut : null,
                            personneAutoriser: {
                                person: {
                                    id: personneAutoriseFind[i]?.personneAutoriser?.person?.id !== null && personneAutoriseFind[i]?.personneAutoriser?.person?.id !== 0 ? personneAutoriseFind[i]?.personneAutoriser?.person?.id : 0,
                                    dateNaissance: personneAutoriseFind[i]?.personneAutoriser?.person?.dateNaissance !== null ? personneAutoriseFind[i]?.personneAutoriser.person?.dateNaissance : dateBirthAuthorization.valueOf(),
                                    nom: authorizationData?.lastname_authorization,
                                    prenom: authorizationData?.firstname_authorization,
                                    sexe: 'HOMME',
                                    email: null,
                                    phone1: authorizationData?.phone_number_authorization,
                                    adresse: '',
                                    photo: '',
                                    phone2: '',
                                    cniNumero: '',
                                    cniPhoto: '',
                                    note: '',
                                    poste: '',
                                    paysPersons: personneAutoriseFind[i]?.personneAutoriser?.person?.paysPersons.length > 0 ? personneAutoriseFind[i]?.personneAutoriser?.person?.paysPersons : [],
                                },
                                common: CONSTANT.common,
                            },
                            common: personneAutoriseFind.length > 0 ? personneAutoriseFind[i]?.common : CONSTANT.common,
                        }
                        personneAutoriserEnfants.push(personneAutoriser);
                    }
                }
            }

            //PROFILE ENFANT
            let enfantProfils = [
                {
                    ...childFind?.enfantProfils,
                    profilsId: data?.profile,
                }
            ]

            let formData:any = new FormData();
            formData.append("photoEnfant", file);
            formData.append("photoPapa", null);
            formData.append("photoMaman", null);

            const birth_date:any = birthDate;

            // ADDING SIBLINGS
            //let enfantParents:any = [];
            let firstNameMother = '';
            let lastNameMother = '';
            let emailMother = '';
            let phoneNumberMother = '';
            let sexeMother = '';
            let mainParentMother = true;

            let firstNameFather = '';
            let lastNameFather = '';
            let emailFather = '';
            let phoneNumberFather = '';
            let sexeFather = '';
            let mainParentFather = false;

            let enfantParents:any = [];
            let parentMother;
            let parentFather;
            let enfantParentsMother;
            let enfantParentsFather;
            /*if(siblings.length > 0){
                const mother:any = siblings[0]?.enfantParents[0]?.parent?.person;
                const father:any = siblings[0]?.enfantParents[1]?.parent?.person;

                parentMother = siblings[0]?.enfantParents[0]?.parent;
                mainParentMother = siblings[0]?.enfantParents[0]?.principal;

                parentFather = siblings[0]?.enfantParents[1]?.parent;
                mainParentFather = siblings[0]?.enfantParents[1]?.principal;
            }
            else {*/
                firstNameMother = data?.firstNameMother?.trim();
                lastNameMother =  data?.lastNameMother?.trim();
                emailMother = data?.emailMother?.trim();
                phoneNumberMother = data?.phoneNumberMother?.trim();
                sexeMother = 'FEMME';
                mainParentMother = mainParent === 'mother';
                if(childFind?.enfantParents.length > 0){
                    for(let i=0; i<childFind?.enfantParents.length; i++){
                        if(childFind?.enfantParents[i]?.parent?.person?.sexe === 'FEMME'){
                            parentMother = {
                                id: childFind?.enfantParents[i]?.parent?.id,
                                userId: childFind?.enfantParents[i]?.parent?.userId,
                                person: {
                                    id: childFind?.enfantParents[i]?.parent?.person?.id,
                                    dateNaissance: childFind?.enfantParents[i]?.parent?.person?.dateNaissance,
                                    nom: lastNameMother,
                                    prenom: firstNameMother,
                                    sexe: sexeMother,
                                    adresse: childFind?.enfantParents[i]?.parent?.person?.adresse,
                                    photo: childFind?.enfantParents[i]?.parent?.person?.photo,
                                    email: emailMother,
                                    phone1: phoneNumberMother,
                                    phone2: childFind?.enfantParents[i]?.parent?.person?.phone2,
                                    cniNumero: childFind?.enfantParents[i]?.parent?.person?.cniNumero,
                                    cniPhoto: childFind?.enfantParents[i]?.parent?.person?.cniPhoto,
                                    note: childFind?.enfantParents[i]?.parent?.person?.note,
                                    paysPersons: childFind?.enfantParents[i]?.parent?.person?.paysPersons,
                                },
                                common: childFind?.enfantParents[i]?.parent?.common,
                                fonctions: childFind?.enfantParents[i]?.parent?.fonctions,
                            };

                            enfantParentsMother = {
                                id: childFind?.enfantParents[i]?.id,
                                enfantId: childFind?.enfantParents[i]?.enfantId,
                                parent: parentMother,
                                principal: mainParentMother,
                                common: childFind?.enfantParents[i]?.common,
                            }

                        }
                    }
                }
                /*parentMother = {
                    id: childFind?.enfantParents[0]?.parent?.id,
                    userId: childFind?.enfantParents[0]?.parent?.userId,
                    person: {
                        id: childFind?.enfantParents[0]?.parent?.person?.id,
                        dateNaissance: childFind?.enfantParents[0]?.parent?.person?.dateNaissance,
                        nom: lastNameMother,
                        prenom: firstNameMother,
                        sexe: sexeMother,
                        adresse: childFind?.enfantParents[0]?.parent?.person?.adresse,
                        photo: childFind?.enfantParents[0]?.parent?.person?.photo,
                        email: emailMother,
                        phone1: phoneNumberMother,
                        phone2: childFind?.enfantParents[0]?.parent?.person?.phone2,
                        cniNumero: childFind?.enfantParents[0]?.parent?.person?.cniNumero,
                        cniPhoto: childFind?.enfantParents[0]?.parent?.person?.cniPhoto,
                        note: childFind?.enfantParents[0]?.parent?.person?.note,
                        paysPersons: childFind?.enfantParents[0]?.parent?.person?.paysPersons,
                    },
                    common: childFind?.enfantParents[0]?.parent?.common,
                    fonctions: childFind?.enfantParents[0]?.parent?.fonctions,
                };*/

                firstNameFather = data?.firstNameFather?.trim();
                lastNameFather = data?.lastNameFather?.trim();
                emailFather = data?.emailFather?.trim();
                phoneNumberFather = data?.phoneNumberFather?.trim();
                sexeFather = 'HOMME';
                mainParentFather = mainParent === 'father';
                if(childFind?.enfantParents.length > 0){

                    for(let i=0; i<childFind?.enfantParents.length; i++){
                        if(childFind?.enfantParents[i]?.parent?.person?.sexe === 'HOMME'){
                            parentFather = {
                                id: childFind?.enfantParents[i]?.parent.id,
                                userId: childFind?.enfantParents[i]?.parent.userId,
                                person: {
                                    id: childFind?.enfantParents[i]?.parent?.person?.id,
                                    dateNaissance: childFind?.enfantParents[i]?.parent?.person?.dateNaissance,
                                    nom: lastNameFather,
                                    prenom: firstNameFather,
                                    sexe: sexeFather,
                                    adresse: childFind?.enfantParents[i]?.parent?.person?.adresse,
                                    photo: childFind?.enfantParents[i]?.parent?.person?.photo,
                                    email: emailFather,
                                    phone1: phoneNumberFather,
                                    phone2: childFind?.enfantParents[i]?.parent?.person?.phone2,
                                    cniNumero: childFind?.enfantParents[i]?.parent?.person?.cniNumero,
                                    cniPhoto: childFind?.enfantParents[i]?.parent?.person?.cniPhoto,
                                    note: childFind?.enfantParents[i]?.parent?.person?.note,
                                    paysPersons: childFind?.enfantParents[i]?.parent?.person?.paysPersons,
                                },
                                common: childFind?.enfantParents[i]?.parent?.common,
                                fonctions: childFind?.enfantParents[i]?.parent?.fonctions,
                            };

                            enfantParentsFather = {
                                id: childFind?.enfantParents[i]?.id,
                                enfantId: childFind?.enfantParents[i]?.enfantId,
                                parent: parentFather,
                                principal: mainParentFather,
                                common: childFind?.enfantParents[i]?.common,
                            }
                        }
                    }
                }
                /*parentFather = {
                    id: childFind?.enfantParents[1]?.parent.id,
                    userId: childFind?.enfantParents[1]?.parent.userId,
                    person: {
                        id: childFind?.enfantParents[1]?.parent?.person?.id,
                        dateNaissance: childFind?.enfantParents[1]?.parent?.person?.dateNaissance,
                        nom: lastNameFather,
                        prenom: firstNameFather,
                        sexe: sexeFather,
                        adresse: childFind?.enfantParents[1]?.parent?.person?.adresse,
                        photo: childFind?.enfantParents[1]?.parent?.person?.photo,
                        email: emailFather,
                        phone1: phoneNumberFather,
                        phone2: childFind?.enfantParents[1]?.parent?.person?.phone2,
                        cniNumero: childFind?.enfantParents[1]?.parent?.person?.cniNumero,
                        cniPhoto: childFind?.enfantParents[1]?.parent?.person?.cniPhoto,
                        note: childFind?.enfantParents[1]?.parent?.person?.note,
                        paysPersons: childFind?.enfantParents[1]?.parent?.person?.paysPersons,
                    },
                    common: childFind?.enfantParents[1]?.parent?.common,
                    fonctions: childFind?.enfantParents[1]?.parent?.fonctions,
                };*/
            //}

            enfantParents = [
                {
                    ...enfantParentsMother
                   /* id: childFind?.enfantParents[0]?.id,
                    enfantId: childFind?.enfantParents[0]?.enfantId,
                    parent: parentMother,
                    principal: mainParentMother,
                    common: childFind?.enfantParents[0]?.common,*/
                },
                {
                    ...enfantParentsFather
                    /*id: childFind?.enfantParents[1]?.id,
                    enfantId: childFind?.enfantParents[1]?.enfantId,
                    parent: parentFather,
                    principal: mainParentFather,
                    common: childFind?.enfantParents[1]?.common,*/
                }
            ];

            const dataToSend = {
                //...childFind,
                id: childFind.id,
                person: {
                    id: data?.id,
                    dateNaissance: birth_date._d.valueOf(),
                    nom: data?.lastname?.trim(),
                    prenom: data.firstname?.trim(),
                    sexe: data?.genre,
                    adresse: data?.address?.trim(),
                    photo: childFind?.person?.photo,
                    email: childFind?.person?.email?.trim(),
                    phone1:  childFind?.person?.phone1,
                    phone2: childFind?.person?.phone2,
                    cniNumero: childFind?.person?.cniNumero,
                    cniPhoto: childFind?.person?.cniPhoto,
                    note: childFind?.person?.note,
                    poste: childFind?.person?.poste,
                    paysPersons: nationalityEnfant,
                },
                emergencyNom1: emergencyNom1?.trim(),
                emergencyPhone1: emergencyPhone1?.trim(),
                emergencyNom2: emergencyNom2?.trim(),
                emergencyPhone2: emergencyPhone2?.trim(),
                situationParent: childFind?.situationParent,
                foodRestriction: data?.foodRestriction,
                healthDetail: data?.healthIssue,
                allergy: data?.allergies,
                common: childFind?.common,
                enfantParents: enfantParents,
                droitImages: imageRights,
                dossierEnfants: dossierEnfants,
                eleves: [
                    {
                        id: childFind?.eleves[0]?.id,
                        enfantId: childFind?.eleves[0]?.enfantId,
                        classe: classroom,
                        theDate: childFind?.eleves[0]?.theDate,
                        common: childFind?.eleves[0]?.common,
                    }
                ],
                personneAutoriserEnfants: personneAutoriserEnfants,
                santes:childFind?.santes,
                enfantProfils: enfantProfils,
                fratrieASupprimmer: siblingsToRemove
            }

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));

            //console.log(JSON.stringify(dataToSend));
            sendData(data, 'PUT', `/corebase/enfants/${data.id}`, formData);
        }

    }

    useEffect(() => {
        (async () => {
            try {
                //console.log(JSON.stringify(data));

                const parents:any = await parentService.getAllParentData();
                const parentsFiltered = parentService.getAllParentFiltered(parents);

                // CHILDREN LIST
                const childListRequest:any = await childrenService.getAllChildrenData();
                let childrenList:any = childListRequest._embedded !== undefined ? childListRequest._embedded?.enfantDTOModelList : [];
                const dataListFormatted = childrenService.reformatChildrenList(childrenList);
                setChildrenDataList([...dataListFormatted]);

                //CLASSROOM LIST
                const classList:any = await classroomService.getAllClassDataAscByName();
                setClassroomData(classList);

                // NATIONALITIES
                /*const nationalityList = dataPays.sort(function (a:any, b:any) {
                    if(activeLang === "en"){
                        if(a.nom_en_gb < b.nom_en_gb) { return -1; }
                        if(a.nom_en_gb > b.nom_en_gb) { return 1; }
                        return 0;
                    }
                    else {
                        if(a.nom_fr_fr < b.nom_fr_fr) { return -1; }
                        if(a.nom_fr_fr > b.nom_fr_fr) { return 1; }
                        return 0;
                    }
                });*/
                const nationalityList = dataPays.sort(
                     (a:any, b:any) => activeLang === "en" ?
                         a.nom_en_gb.localeCompare(b.nom_en_gb, 'en') :
                         a.nom_fr_fr.localeCompare(b.nom_fr_fr, 'fr')
                );
                setCountries(nationalityList);

                // REGISTRATION FILES
                const folderConstitutive:any = await childrenService.getAllRegistrationFile();
                // SUBFOLDER TYPE
                const subFolderList:any = await childrenService.getAllSubRegistrationFile();
                let listSubFilesType = childrenService.getChildRegistrationFile(folderConstitutive, subFolderList);
                listSubFilesType = listSubFilesType.map((fileType:any) => {
                    const fileList = activeLang === 'en' ? REGISTRATION_FILES_EN :REGISTRATION_FILES_FR;
                    const fileFind:any = fileList.find((file:any) => file.id === fileType.id);
                    return {
                        ...fileType,
                        nom: fileFind.value
                    };
                })
                const registrationsFilesLoad = listSubFilesType;
                setRegistrationsFiles([...listSubFilesType]);

                // IMAGE RIGHTS
                let imageRightsList:any = await childrenService.getAllImageRights();
                imageRightsList = imageRightsList.map((imageRights:any) => {
                    const imageList = activeLang === 'en' ? IMAGE_RIGHTS_EN :IMAGE_RIGHTS_FR;
                    const imaageFind:any = imageList.find((image:any) => image.id === imageRights.id);
                    return  {
                        ...imageRights,
                        nom: imaageFind.value,
                    };
                });
                setImageRightsData([...imageRightsList]);

                 //////////////////////////
                // SET FORM VALUES CHOOSE
                //setStatusChildForm(true);
                setFile(null);
                moment.locale(activeLang);
                setOptionRegistration([]);
                const gender = data.person.sexe;
                let profileChild  = data.enfantProfils[0]?.profilsId;
                if(profileChild === null || profileChild === undefined || profileChild === ''){
                    profileChild = 1;
                }
                setValue('id', data.id);
                setSiblings([]);
                setSiblingsOriginal([]);
                const sibillingsReq:any = await getRequest(`/corebase/enfants/${data.id}/fratrie`);
                const sibillingList:any = sibillingsReq._embedded !== undefined ? sibillingsReq._embedded?.enfantDTOModelList : [];

               if(sibillingList.length > 0) {
                   const sibillings = sibillingList.map((child:any) => {
                       const { classe } = child.eleves[0];
                       child.person.photoDataTable = (child?.person?.photo !== null && child?.person?.photo !== "") ? `${BASEURL_RESOURCES}/${child?.person?.photo}` : '';
                       child.person.birthDay = moment(child?.person?.dateNaissance).format("L");
                       child.person.classeName = classe?.nom;
                       return child;
                   });

                   setSiblings(sibillings);
                   setSiblingsOriginal(sibillings);
                   setDisplaySiblings(true);
               }

               setFileDataURL(data?.person?.photoDataTable);
               setValue('firstname', data?.person?.prenom);
               setValue('lastname', data?.person?.nom);
               setValue('genre', gender);
               setGender(gender);
               setValue('profile', profileChild);
               setProfile(profileChild);

               setValue('address', data?.person?.adresse);
               const editBirthDate = moment(data?.person?.dateNaissance).format("L");
               setBirthDate(moment(editBirthDate, activeLang === "en" ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]));

               if(data?.eleves.length > 0){
                   const classChildSelect:any = data?.eleves;
                   const { classe } = classChildSelect[0];
                   classList.map((classroom:any) => {
                       if(classroom.id === classe?.id) {
                           setClassroom(classroom);
                       }
                   });
               }

                const nationaltiesEnfant:any = [];
                for(let i=0; i< data?.person?.paysPersons.length; i++) {
                    const pays = data?.person?.paysPersons[i];
                    const nationalityFind = nationalityList.find((paysFind:any) => {
                        if(pays.paysId === paysFind.id) {
                            return paysFind;
                        }
                    });
                    nationaltiesEnfant.push(nationalityFind);
                }
                setValue('nationalities', nationaltiesEnfant);
                setSelectedOptions(nationaltiesEnfant);

                let mother:any; //data?.enfantParents[0]?.parent?.person;
                let father:any; //data?.enfantParents[1]?.parent?.person;
                let mainContactMother = true; // = data?.enfantParents[0]?.principal;
                let mainContactFather = false; // = data?.enfantParents[1]?.principal;
                if(data?.enfantParents?.length>0){
                    for (let i= 0; i<data?.enfantParents.length; i++){
                        if(data?.enfantParents[i]?.parent?.person.sexe === "FEMME"){
                            mother = data?.enfantParents[i]?.parent?.person;
                            mainContactMother = data?.enfantParents[i]?.principal;
                        }
                        else if(data?.enfantParents[i]?.parent?.person.sexe === "HOMME"){
                            father = data?.enfantParents[i]?.parent?.person;
                            mainContactFather = data?.enfantParents[i]?.principal;
                        }
                    }
                }

                setValue('firstNameMother', mother?.prenom?.trim());
                setValue('lastNameMother', mother?.nom?.trim());
                setValue('phoneNumberMother', mother?.phone1?.trim());
                setValue('emailMother', mother?.email?.trim());
                setValue('firstNameFather', father?.prenom?.trim());
                setValue('lastNameFather', father?.nom?.trim());
                setValue('phoneNumberFather', father?.phone1);
                setValue('emailFather', father?.email?.trim());
                setValue('allergies', data?.allergy);
                setValue('foodRestriction', data?.foodRestriction);
                setValue('healthIssue', data?.healthDetail);

                //const mainContactMother = data?.enfantParents[0]?.principal;
                const prenomMother = mother?.prenom;
                const nomMother = mother?.nom;
                //const mainContactFather = data?.enfantParents[1]?.principal;
                const prenomFather = father?.prenom;
                const nomFather = father?.nom;

                if(prenomMother !== '' && prenomMother !== null && nomMother !== '' && nomMother !== null){
                    if(mainContactMother){
                        setMainParent('mother');
                    }
                }
                else if(prenomFather !== '' && prenomFather !== null && nomFather !== '' && nomFather !== null){
                    if(mainContactFather){
                        setMainParent('father');
                    }
                }
                else {
                    setMainParent('mother');
                }

                // Registration files
                if(data?.dossierEnfants.length > 0) {
                    //console.log(JSON.stringify(data))
                    const childFiles:any = data?.dossierEnfants[0]?.documentDossierEnfants;
                    if(childFiles.length > 0) {
                        let registrationSelectedList = [];
                        const fileDataList:any = [];

                        for(let i=0; i < childFiles.length; i++) {
                            const file = childFiles[i];
                            //registrationsFiles.find
                            registrationSelectedList = registrationsFilesLoad.find((registrationFile:any) => {
                                if(file.dossierConstitutifId === registrationFile.dossierConstitutifId) {
                                    return registrationFile;
                                }
                            });

                            fileDataList.push(registrationSelectedList);
                        }

                        setOptionRegistration([...fileDataList]);
                    }

                }

                // Image rights
                const imagesRightEdit:any = [];
                const droitsImages:any = data?.droitImages;
                if(droitsImages.length > 0) {
                    for(let i=0; i < droitsImages.length; i++) {
                        const image = droitsImages[i];
                        //imageRightsData
                        const imageRightFind = imageRightsList.find((imageFind:any) => {
                            if(image?.droitImageId === imageFind?.id) {
                                return imageFind;
                            }
                        });
                        imagesRightEdit.push(imageRightFind);
                    }
                }
                setOptionImagesRights(imagesRightEdit);

                // EMERGENCY CONTACT
                setValue('firstnameLastnameContact1', data?.emergencyNom1);
                setValue('phoneNumberContact1', data?.emergencyPhone1);
                setValue('firstnameLastnameContact2', data?.emergencyNom2);
                setValue('phoneNumberContact2', data?.emergencyPhone2);

                // AUTHORIZATION PERSON
                const childAuthorizationPerson = data?.personneAutoriserEnfants;
                if(childAuthorizationPerson.length > 0){
                    let authorizationPersonTab:any = [];
                    for(let i=0; i < childAuthorizationPerson.length; i++) {
                        const authorizationPerson = childAuthorizationPerson[i]?.personneAutoriser?.person;
                        const authorization = {
                            firstname_authorization: authorizationPerson?.prenom,
                            lastname_authorization : authorizationPerson?.nom,
                            phone_number_authorization : authorizationPerson?.phone1,
                        }
                        authorizationPersonTab.push(authorization);
                    }
                    setAuthorizationPersonFormValues(authorizationPersonTab);
                }

                //console.log(JSON.stringify(data))
                setLoading(false);
            }
            catch (e) {
                setLoading(false);
                console.log(e);
            }
        })()
    }, [activeLang]);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.subMenuTools}>
                <Button
                    className={classNames(classes.button) }
                    variant={"text"}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: 15,
                        color: "#666666"
                    }}
                    component={Link} to="/app/child/list"
                    startIcon={<KeyboardBackspaceIcon />}
                >
                    <FormattedMessage id={messages.return_children_list} />
                </Button>
            </div>
            <div className={classes.root}>
                <Grid container spacing={2} style={{marginBottom: 30}}>
                    <Grid item md={7} xs={12} style={{background: 'white'}}>
                        <Loading status={loading} />
                        {!loading && (
                            <StudentForm
                                title={<FormattedMessage id={messages.dialog_title_edit_child}/>}
                                classes={classes}
                                locale={locale}
                                activeLang={activeLang}
                                fileDataURL={fileDataURL}
                                handleFileChange={handleFileChange}
                                register={register}
                                errors={errors}
                                birthDate={birthDate}
                                handleDateChange={handleDateChange}
                                classroom={classroom}
                                dataClassroom={classroomData}
                                handleClassroomChange={handleClassroomChange}
                                selectedOptions={selectedOptions}
                                handleSelectOptionChange={handleSelectOptionChange}
                                nationalitiesData={countries}
                                gender={gender}
                                handleGenderChange={handleGenderChange}
                                profile={profile}
                                handleProfileChange={handleProfileChange}
                                childrenList={childrenDataList}
                                siblings={siblings}
                                displaySiblings={displaySiblings}
                                handleSiblingsChange={handleSiblingsChange}
                                searchPlaceholder={searchPlaceholder}
                                handleRemoveSibling={handleRemoveSibling}
                                handleRegistrationChange={handleRegistrationChange}
                                handleRegistrationChipChange={handleRegistrationChipChange}
                                registrationsFiles={registrationsFiles}
                                optionRegistration={optionRegistration}
                                setOptionRegistration={setOptionRegistration}
                                optionImagesRights={optionImagesRights}
                                imageRightsData={imageRightsData}
                                handleImageRightChipChange={handleImageRightChipChange}
                                addAuthorizationPersonFormFields={addAuthorizationPersonFormFields}
                                handleAuthorizationPersonChange={handleAuthorizationPersonChange}
                                removeAuthorizationPersonFormFields={removeAuthorizationPersonFormFields}
                                authorizationPersonFormValues={authorizationPersonFormValues}
                                mainParent={mainParent}
                                handleChangeMainParent={handleChangeMainParent}
                                onSubmit={handleSubmit(onSubmitEditForm)}
                                loadingButton={loadingButton}
                                statusChildForm={statusChildForm}
                            />
                        )}
                    </Grid>

                    <Grid item md={5} xs={12} style={{background: 'white'}}>

                    </Grid>
                </Grid>

            </div>
        </PapperBlock>
    );
}

export default withSnackbar(EditChild);
