import {deleteRequest, getRequest, postRequest, putRequest} from "../helpers/axios_helpers";

function MenuYearService() {
    return {
        getAllDishData: async () => {
            const dishListRequest:any = await getRequest(`/extra/platcantine`);
            return dishListRequest._embedded !== undefined ? dishListRequest._embedded.platCantineDTOModelList : [];
        },
        getAllMenuCanteen: async () => {
            const menuListRequest:any = await getRequest(`/extra/menucantine`);
            return menuListRequest._embedded !== undefined ? menuListRequest._embedded.menuCantineDTOModelList : [];
        },
        getAllMenuJour: async () => {
            const dataMenuJourRequest:any = await getRequest(`/extra/menujour`);
            return dataMenuJourRequest._embedded !== undefined ?  dataMenuJourRequest._embedded.menuJourDTOModelList : [];
        },
        getStatusMenuYear: async () => {
            return await getRequest(`/extra/menujour/menuyearstatut`);
        },
        updateStatusMenuYear: async (data:boolean) => {
            const dataToSend = {
                id:1,
                statut:data,
            }
            await putRequest(`/extra/menujour/menuyearstatut`, dataToSend);
        },
        postMenuJourBouquet: async (data:any) => {
            return await postRequest('/extra/menujour/bouquet', data);
        },
        deleteMenuJour: async (data:any) => {
            return await deleteRequest('/extra/menujour/bouquet', data);
        },
        checkMenuDay: (menu:any, dataMenuJourList:any, weekListTrue:any) => {
            if(dataMenuJourList.length > 0 && weekListTrue.length > 0){
                for(let i=0; i<dataMenuJourList.length; i++){
                    if(dataMenuJourList[i]?.menuCantineJours.length > 0){
                        if(dataMenuJourList[i]?.menuCantineJours[0]?.menuCantineId === menu?.id){
                            for(let j=0; j<weekListTrue.length; j++){
                                if(weekListTrue[j]?.id === dataMenuJourList[i]?.semaineId){
                                    return true;
                                }
                            }
                        }
                    }
                }
            }

            return false;
        }
    }
}

export default MenuYearService;
