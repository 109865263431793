import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {DocumentScolaire} from "./DocumentScolaire";

export const DOCUMENTSCOLAIREFICHIER_CONSTANT = {
    convertToFrontEnd: (logic:any, itemDoc:DocumentScolaire, item:DocumentScolaireFichier) => {
        const itemInit = _.cloneDeep(documentScolaireFichierDataImpl);
        item = {...itemInit, ...item} ;

        item.iconForm = logic.filePictureMapRef.current.get(item.filetype) ?? logic.documentPictureRef.current ; // @ts-ignore
        item.isOwnerForm = itemDoc.isOwnerForm;
        // @ts-ignore
        return item ;
    },
    convertToBackEnd: (logic:any, item:DocumentScolaireFichier) => item,
};

export  const documentScolaireFichierDataImpl: DocumentScolaireFichier = {
    id: null,
    documentScolaireId: 0,
    nom: '',
    filetype:'',
    uri:'',
    usersIdDeleted: '',
    iconForm:'',
    isOwnerForm: true,
    common: _.cloneDeep(commonDataImp)
}

export const documentScolaireFichierSchema = yup.object().shape({
    id: yup.number().nullable(),
    documentScolaireId: yup.number(),
    nom: yup.string(),
    filetype: yup.string(),
    uri: yup.string(),
    usersIdDeleted: yup.string().default(''),
    isOwnerForm: yup.boolean().default(true),
    iconForm: yup.string(),
    common: yup.mixed<Common>()
});

export type DocumentScolaireFichier = yup.InferType<typeof documentScolaireFichierSchema>;