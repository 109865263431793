import React, {useContext, useMemo} from "react";
import {BibliothequeContext} from "./Bibliotheque";
import {useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import _ from "lodash";
import {columnsLivre, Livre, livreDataImpl} from "../../../constants/type/Livre";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import livreStateUpdate from "./businesslogic/LivreStateUpdate";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

function LivreList() {
    const logic: any = useContext(BibliothequeContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    
    const addItem = () => {
        livreStateUpdate.livreSel(logic, _.cloneDeep(livreDataImpl));
        livreStateUpdate.openLivreEditDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<Livre>) => {
        const data:Livre = logic.livres[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        livreStateUpdate.livreSel(logic, _.cloneDeep(data));
        livreStateUpdate.openLivreEditDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        const dataSelected:Livre[] = [];
        rows.map((row:any) => {
            const data:Livre = logic.livres[row.index];
            dataSelected.push(data);
        });
        livreStateUpdate.livresSelected(logic, dataSelected);
        livreStateUpdate.openLivreDeleteDialogForm(logic, true);
    }


    const excelDataMapping = (rows:any) => {
        return _.map(rows, (row:any) => {
            let obj:any = {};
            const data = row.original;
            obj[intl.formatMessage({id:messages.table_h_catlivre})] = data.livreCategorieForm?.nom ?? ``;
            obj[intl.formatMessage({id:messages.table_h_nom})] = data.nom;
            obj[intl.formatMessage({id:messages.table_h_auteur})] = data.auteur;
            obj[intl.formatMessage({id:messages.table_h_langue})] = data.langue;
            obj[intl.formatMessage({id:messages.table_h_quantite})] = data.quantite;
            obj[intl.formatMessage({id:messages.en_stock})] = data.quantiteDispo;
            obj[intl.formatMessage({id:messages.table_h_empruntmaxjour})] = data.empruntMaxJour;
            return obj;
        });
    }

    const columns = useMemo<MRT_ColumnDef<Livre>[]>(
        () => [...columnsLivre({intl:intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.livres,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
                export_xls_title={export_xls}
                xls_nom_fichier="Library"
                excelDataMapping={excelDataMapping(table.getPrePaginationRowModel().rows)}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}

export default LivreList;