import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import livreEmpruntStateUpdate from "./LivreEmpruntStateUpdate";
import {FormattedMessage} from "react-intl";
import {processingChildren, processingEmployees, processingJobs} from "../../../../helpers/loader_helpers";
import {LivreEmprunt, LIVREEMPRUNT_CONSTANT} from "../../../../constants/type/LivreEmprunt";
import {isPositiveValue} from "../../../../helpers/helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {Child} from "../../../../constants/type/Child";
import {LivreCategorie, LIVRECATEGORIE_CONSTANT} from "../../../../constants/type/LivreCategorie";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {Livre, LIVRE_CONSTANT} from "../../../../constants/type/Livre";
import {pageFormImpl, setPageForm} from "../../../../features/page/pageSlice";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";

const livreEmpruntBusinessLogic : any = {
    initPostConstruct: (logic:any) => {

        const requestbody = ['classes', 'profils',  'enfants', 'livrecategories', 'livres', 'fonctions', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemlc : LivreCategorie[]   = data.livrecategories.content ?? [];
            logic.livreCategorieRef.current  = _.sortBy(_.map(itemlc,  LIVRECATEGORIE_CONSTANT.convertToFrontEnd), ['nom']);

            const items : Livre[]   = data.livres.content ?? [];
            logic.livresRef.current = _.sortBy(_.map(items,  item => LIVRE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);


            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logic = processingEmployees(logic, itememployees);
        };

        livreEmpruntStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            livreEmpruntBusinessLogic.loadLivresEmprunt(logic).then((val:any) => {});
            if(_.isObject(logic.pageForm)){
                // @ts-ignore
                if(logic.pageForm.cle === 'books_loan' && isPositiveValue(logic.pageForm.id) ){
                    const dataId = logic.pageForm.id ;
                    logic.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                    // @ts-ignore
                    request("GET",  `/extra/livremprunt/${dataId}`, {}).then((response:any) => {

                        if(isPositiveValue(response.data.id)){
                            const data: LivreEmprunt = LIVREEMPRUNT_CONSTANT.convertToFrontEnd(logic, response.data);
                            livreEmpruntBusinessLogic.showLivreEmprunt(logic, data);
                        }
                        livreEmpruntStateUpdate.tableDataLoading(logic, false);
                    }).catch((error:any) => {
                        livreEmpruntStateUpdate.tableDataLoading(logic, false);
                    });
                }else{
                    livreEmpruntStateUpdate.tableDataLoading(logic, false);
                }
            }else{
                livreEmpruntStateUpdate.tableDataLoading(logic, false);
            }

        }).catch(e => {
            livreEmpruntStateUpdate.tableDataLoading(logic, false);
        });

    },
    showLivreEmprunt : (logic:any, data:LivreEmprunt) => {
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        livreEmpruntStateUpdate.livreEmpruntSel(logic, _.cloneDeep(data));
        livreEmpruntStateUpdate.openLivreEmpruntEditDialogForm(logic, true);
    },
    saveLivreEmpruntEdit: async (logic:any, messages:any, toSend:LivreEmprunt):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            let params = `/cancel`;
            if(isPositiveValue(toSend.dateEmprunt)){
                params = `/confirm`;
            }
            if(isPositiveValue(toSend.dateRetour)){
                params = `/retour`;
            }
            request('PUT', `/extra/livremprunt${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = LIVREEMPRUNT_CONSTANT.convertToFrontEnd(logic, response.data);

                livreEmpruntStateUpdate.livreEmpruntSel(logic, _.cloneDeep(dataSaved));
                livreEmpruntBusinessLogic.loadLivresEmprunt(logic).then((val:any) => {});
                livreEmpruntStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadLivresEmprunt: (logic:any) => {
        livreEmpruntStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/livremprunt`, {}).then((response) => {
                const items : LivreEmprunt[]   = response.data._embedded?.livreEmpruntDTOModelList ?? [];
                const itemsfrontend = _.reverse(_.sortBy(_.map(items,  item => LIVREEMPRUNT_CONSTANT.convertToFrontEnd(logic, item)), ['dateDemande']));
                livreEmpruntStateUpdate.livresEmprunt(logic, itemsfrontend) ;
                //logic.setLivresEmprunt(_.cloneDeep(itemsfrontend));
                livreEmpruntStateUpdate.tableKeyValue(logic, new Date().getTime());
                livreEmpruntStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                livreEmpruntStateUpdate.tableDataLoading(logic, false);
                resolve(false);
            })
        );
    },
    deleteLivre: async (logic:any, messages:any, toSends:LivreEmprunt[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:LivreEmprunt) => {
                if(isPositiveValue(l.id)){
                    // @ts-ignore
                    ids.push(l.id);
                }
            });


            request('DELETE', `/extra/livremprunt/bouquet`, ids).then((response) => {
                // @ts-ignore
                livreEmpruntBusinessLogic.loadLivresEmprunt(logic).then((val:any) => {});
                livreEmpruntStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    }
}
export default livreEmpruntBusinessLogic ;