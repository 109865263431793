import {GroupEnfant} from "../../../../constants/type/GroupEnfant";
import _ from "lodash";


const groupEnfantStateUpdate:any = {
    dataList: (logic:any, val:GroupEnfant[]) => {
        const idEnfants: number[] = _.map(val, item => item.enfantId);
        groupEnfantStateUpdate.idEnfantsOfGroup(logic, idEnfants);
        logic.setDataList(_.sortBy(val, [(item:GroupEnfant) => item.childForm?.childnomForm]));
    },
    addDataList: (logic:any, val:GroupEnfant[]) => {
        const datas: GroupEnfant[] = _.concat(val, logic.dataList);
        groupEnfantStateUpdate.dataList(logic, datas);
    },
    dataListSelected: (logic:any, val:GroupEnfant[]) => logic.setDataListSelected(val),
    dataSel: (logic:any, val:GroupEnfant) => logic.setDataSel(val),
    contentLoaded: (logic:any, val:GroupEnfant) => logic.setContentLoaded(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openAddDialogForm: (logic:any, val:boolean) => logic.setOpenAddDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    processingSubmitStateNormBtn: (logic:any, val:boolean) => logic.setProcessingSubmitStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    enfantsOfClasse: (logic:any, val:any) => logic.setEnfantsOfClasse(val),
    idEnfantsOfGroup: (logic:any, val:any) => logic.setIdEnfantsOfGroup(val),
    idScolariteAnneeSel: (logic:any, val:any) => logic.setIdScolariteAnneeSel(val),
    idGroupSel: (logic:any, val:any) => logic.setIdGroupSel(val),
}

export default groupEnfantStateUpdate ;