import React from 'react';
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import messages from "../../messages/general/messages";
import {FormattedMessage} from "react-intl";

function ScolariteSubMenu(props:any) {
    const {classes, selected} = props;

    return (
        <>
            <Button
                className={classNames(classes.button, selected === 'schooling' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/config/schooling"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.schooling} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'schoolfees' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/config/schooling/schoolfees"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.schooling_fees} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'paymentPeriod' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                //color="secondary"
                component={Link} to="/app/config/schooling/paymentperiod"
                startIcon={<AddIcon/>}
            >
                <FormattedMessage id={messages.schooling_payment_period} />
            </Button>

        </>
    );
};

export default ScolariteSubMenu;
