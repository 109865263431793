import {Groupesection} from "../../../../constants/type/Groupesection";


const groupStateUpdate:any = {
    dataList: (logic:any, val:Groupesection[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:Groupesection[]) => logic.setDataListSelected(val),
    dataSel: (logic:any, val:Groupesection) => logic.setDataSel(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),

}

export default groupStateUpdate ;