import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {makeStyles} from "@mui/styles";
import styles from "./../config-table-jss";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import CanteenWeekForm from "./../canteenweek/CanteenWeekForm";
import {useForm} from "react-hook-form";
import moment, {Moment} from "moment";
import {CONSTANT} from "../../../constants";
import {request} from "../../../helpers/axios_helpers";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {AxiosResponse} from "axios";
import {getErrorMessage} from "../../../helpers/errorManager";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {Common} from "../../../constants/type/Common";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {useDispatch} from "react-redux";
import _ from "lodash";

const useStyles = makeStyles(styles);

type WeekType = {
    id: number;
    periodId: number;
    weekName: string;
    startPeriod: string;
    endPeriod: string;
    statut: boolean;
    common: Common ;
};

type CanteenWeekFormValues = {
    id: number;
    periodId: number;
    weekName: string;
    startPeriod: Moment;
    endPeriod: Moment;
    statut: boolean;
    common: Common ;
}

type Period = {
    id: number,
    year: string;
    period:string,
    startPeriod: string;
    endPeriod: string;
    common: Common ;
};

function CanteenWeek(props: any) {
    const {locale, theme, snackbarShowMessage} = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0] ?? 'fr';
    const dispatch = useDispatch();
    const intl = useIntl() ;
    const [weekTypeData, setWeekTypeData] = useState<WeekType[]>([]);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate]: any = useState(moment(new Date()))
    const [endDate, setEndDate] = useState(startDate);
    const [stateCanteenWeekForm, setStateCanteenWeekForm] = useState(true);
    const [schoolPeriodData, setSchoolPeriodData] = useState<Period[]>([]);
    const [period, setPeriod] = useState('');
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'CanteenWeek',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<WeekType>(mytableState), []);

    const handleSaveRow = ({exitEditingMode, row, values}: { exitEditingMode: any, row: any, values: any }) => {
        weekTypeData[row.index] = values;
        setWeekTypeData([...weekTypeData]);
        exitEditingMode();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const addCanteenWeek = () => {
        setStateCanteenWeekForm(true);
        setStartDate(moment(new Date()));
        setEndDate(moment(new Date()));
        setPeriod('')
        setValue('periodId', 0);
        setValue('weekName', '');
        handleClickOpen();
    };

    const editCanteenWeek = (row: any) => {
        setStateCanteenWeekForm(false);
        const dataWeek: any = weekTypeData[row.index];

        moment.locale(activeLang);
        setValue('id', dataWeek.id);
        setValue('periodId', dataWeek.periodId);
        setValue('weekName', dataWeek.weekName);
        const dateFinWeek = moment(dataWeek.endPeriod, activeLang === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').toDate();
        const dateDebWeek = moment(dataWeek.startPeriod, activeLang === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').toDate();
        setStartDate(moment(dateDebWeek));
        setEndDate(moment(dateFinWeek));

        schoolPeriodData.map((type:any) => {
            if(type.id === dataWeek.periodId){
                setPeriod(type);
            }
        })

        handleClickOpen();
    }

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
        setEndDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handlePeriodChange = (event:any) => {
        schoolPeriodData.map((type:any) => {
            if(type.id === event.target.value){
                setPeriod(type);
            }
        })
    }



    const form = useForm<CanteenWeekFormValues>({
        defaultValues: {
            id: 0,
            periodId: 0,
            weekName: '',
            startPeriod: startDate,
            endPeriod: startDate
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        if(startDate <= endDate){
            let message:any = '';
            let canteenWeek:any = {}
            data.startPeriod = moment(startDate._d);
            data.endPeriod = moment(endDate._d);
            request(method, url, dataToSend).then((response) => {
                moment.locale(activeLang);
                if(stateCanteenWeekForm) {
                    canteenWeek.id = response.data.id;
                    canteenWeek.weekName = data.weekName;
                    canteenWeek.periodId = data.periodId;
                    canteenWeek.startPeriod = data.startPeriod.format("L");
                    canteenWeek.endPeriod = data.endPeriod.format("L");
                    canteenWeek.common = response.data.common;
                    setWeekTypeData([canteenWeek, ...weekTypeData]);
                    reset();
                    message = <FormattedMessage id={messages.sb_add_success} />
                }
                else {
                    weekTypeData.find((weekData:any, index:any) => {
                        const startPeriod = moment(startDate._d);
                        const endPeriod = moment(endDate._d);
                        if(weekData.id === data.id) {
                            weekTypeData[index].weekName = data.weekName;
                            weekTypeData[index].periodId = data.periodId;
                            weekTypeData[index].startPeriod = startPeriod.format("L");
                            weekTypeData[index].endPeriod = endPeriod.format("L");

                            setWeekTypeData([...weekTypeData]);
                        }
                    });
                    message = <FormattedMessage id={messages.sb_edit_success} />
                }
                setOpen(false);
                snackbarShowMessage(message);
            }).catch((error) => {
                // Error message
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            });
        }
    }

    const onSubmit = (data: CanteenWeekFormValues) => {
        startDate.set({hour:0,minute:0,second:0,millisecond:0});
        //console.log(startDate._d.valueOf())
        endDate.set({hour:23,minute:59,second:59,millisecond:0});

        if(data.periodId > 0) {
            const dataToSend = {
                periodeId: data.periodId,
                nom: data.weekName,
                dateDebut:startDate._d.valueOf(),
                dateFin:endDate._d.valueOf(),
                statut: true,
                common: CONSTANT.common
            }

            sendData(data, 'POST', "/corebase/semaines", dataToSend);
        }
    }

    const onSubmitEditForm = (data: CanteenWeekFormValues) => {
        if(data.periodId > 0) {
            const weekFind: any = weekTypeData.find(weekData => weekData.id === data.id);
            startDate.set({hour:0,minute:0,second:0,millisecond:0});
            endDate.set({hour:23,minute:59,second:59,millisecond:0});

            const dataToSend = {
                ...weekFind,
                id: data.id,
                periodeId: data.periodId,
                nom: data.weekName,
                dateDebut: startDate._d.valueOf(),
                dateFin: endDate._d.valueOf(),
                //common: weekFind.common
            }
            sendData(data, 'PUT', `/corebase/semaines/${data.id}`, dataToSend);
        }
    }

    const handleRowsDelete = (rows:any) => {

        const dataSelected:any = [];
        const dataWeekSelectedId:any = [];
        rows.map((row:any) => {
            const data = weekTypeData[row.index];
            dataSelected.push(data);
            dataWeekSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/semaines/bouquet', dataWeekSelectedId).then((response) => {
            const weekList = weekTypeData.filter((weekData) => {
                if(!dataSelected.includes(weekData)){
                    return weekData;
                }
            });
            setWeekTypeData([...weekList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }




    const columns = useMemo<MRT_ColumnDef<WeekType>[]>(
        () => [
            {
                accessorKey: 'weekName',
                header: intl.formatMessage({id: messages.title_canteen_week}),
                size: 570,
            },
            {
                accessorKey: 'startPeriod',
                header: intl.formatMessage({id: messages.start_period}),
            },
            {
                accessorKey: 'endPeriod',
                header: intl.formatMessage({id: messages.end_period}),
            },
        ],
        [activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization ,
        columns,
        data: weekTypeData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addCanteenWeek}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editCanteenWeek(row)}
                styleClasses={classes}
            />
        )
    });

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", "/corebase/semaines", {}).then((response) => {
            let canteenWeek:any = response.data._embedded !== undefined ? response?.data?._embedded?.semaineDTOModelList : [];
            //console.log(JSON.stringify(canteenWeek));
            canteenWeek = canteenWeek.filter((week:any) => week.statut === true);

            canteenWeek = canteenWeek.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            const dataList = canteenWeek.map((week:any) => {
                moment.locale(activeLang);
                let weekData:any = {};
                weekData.id = week.id;
                weekData.periodId = week.periodeId;
                weekData.weekName = week.nom;
                weekData.startPeriod = moment(week.dateDebut).format("L");
                weekData.endPeriod = moment(week.dateFin).format("L");
                weekData = {...week, ...weekData, ...{common: week.common }}
                return weekData;

            })
            setWeekTypeData([...dataList]);
            setTableDataLoading(false);
        }).catch((error) => {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            // Error message
            setTableDataLoading(false);
        })
    }, [activeLang]);

    useEffect(() => {
        request("GET", "/corebase/periodes", {}).then((response: AxiosResponse<any>) => {
            const dataPeriod:any =  response.data._embedded !== undefined ? response?.data?._embedded?.periodeDTOModelList : [];
            const dataList = dataPeriod.map((schoolPeriod:any) => {
                moment.locale(activeLang);
                let periodData:any = {};
                periodData.id = schoolPeriod.id;
                periodData.year = '2022-2023';
                periodData.period = schoolPeriod.nom;
                periodData.startPeriod = moment(schoolPeriod.dateDebut).format("L");
                periodData.endPeriod = moment(schoolPeriod.dateFin).format("L");
                periodData = {...periodData, ...{common: schoolPeriod.common }}
                return periodData;
            })
            setSchoolPeriodData([...dataList]);

        }).catch((error) => {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        })
    }, [activeLang]);

    useEffect(() => {
        return () => logicMRT.storeState(tableConfig.getState());
    }, []);


    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />
            <CanteenWeekForm
                theme={theme}
                locale={locale}
                activeLanguage={activeLang}
                title={
                    stateCanteenWeekForm ? <FormattedMessage id={messages.dialog_title_canteen_week}/> :
                        <FormattedMessage id={messages.dialog_edit_title_canteen_week}/>
                }
                open={open}
                setOpen={setOpen}
                onSubmit={stateCanteenWeekForm ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                classes={classes}
                register={register}
                startDate={startDate}
                handleStartDateChange={handleStartDateChange}
                endDate={endDate}
                handleEndDateChange={handleEndDateChange}
                dataPeriod={schoolPeriodData}
                handlePeriodChange={handlePeriodChange}
                period={period}
            />
        </div>
    )
}

export default withSnackbar(CanteenWeek);
