import React, {useContext, useEffect, useMemo, useRef} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Icon,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import InputField from "../../../components/ui/form/InputField";
import messages from "../../messages/general/messages";
import {
    ScolariteProforma,
    SCOLARITEPROFORMA_CONSTANT,
    scolariteProformaDataImpl,
    ScolariteProformaGroupEnfant,
    scolariteProformaSchema
} from "../../../constants/type/ScolariteProforma";
import {FactureContext} from "./Proforma";
import Chip from "@mui/material/Chip";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import PeopleIcon from "@mui/icons-material/People";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {Child} from "../../../constants/type/Child";
import proformaStateUpdate from "./businesslogic/ProformaStateUpdate";
import profromaBusinessLogic from "./businesslogic/ProfromaBusinessLogic";
import {Groupesection} from "../../../constants/type/Groupesection";

//TODO Proforma invoice par Proforma
//TODO DASHBOARD ETT RDV STAT NE S'AFFICHENT PAS A CHAQUE FOIS
//TODO FEVRIER APPARAIT AVANT JANVIER DANS LE RDV
//TODO S'ASSURER QUE LES COMMENTAIRES SONT BIEN ENREGISTRES LORS DU PAIEMENT
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ProformaEditForm = (props:any) => {
    const logic: any = useContext(FactureContext);
    const styleClasses = logic.styleClasses ;
    const intl = useIntl() ;

    const columns = useMemo<MRT_ColumnDef<ScolariteProformaGroupEnfant>[]>(
        () => [
            {
                accessorKey: 'childForm.childphotoForm',
                header: intl.formatMessage({id: messages.table_h_photo}),
                size: 80,
                Cell: ({ cell }) => (
                    <div style={{textAlign:'center', width:'70'}}>
                        <Avatar
                            src={`${cell.getValue<string>()}`}
                            sx={{ textAlign:'center'}} />
                    </div>
                ),
            },
            {
                accessorKey: 'childForm.childnomForm',
                header:  intl.formatMessage({id: messages.table_h_child_lastname}),
            },
            {
                accessorKey: 'childForm.childprenomForm',
                header:  intl.formatMessage({id: messages.table_h_child_firstname}),
            },
            {
                accessorKey: 'groupEnfantForm.groupesectionForm.nom',
                header: intl.formatMessage({id: messages.table_h_group}),
            },
        ],
        [],
    );

    const formRef = useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteProformaSchema),
    }));
    const {errors} = formRef.current.formState;

    const handleOnScolariteAnneChange = (event: any) => {
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        data.scolariteAnneeId = parseInt(event.target.value) ;
        formRef.current.setValue('scolariteAnneeId', data.scolariteAnneeId);
        proformaStateUpdate.dataSel(logic, data);
    };

    const handleOnGroupeChange = (event: any) => {
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        data.groupesectionsIds = event.target.value ;
        formRef.current.setValue('groupesectionsIds', data.groupesectionsIds);
        proformaStateUpdate.dataSel(logic, data);
    };

    const handleOnProfilChange = (event: any) => {
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        data.profilsIds = event.target.value ;
        formRef.current.setValue('profilsIds', data.profilsIds);
        proformaStateUpdate.dataSel(logic, data);
    };

    const handleOnFraisCheck = (id: number) => {
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        const etat = !data.scolariteTypeIds?.includes(id) ;
        if(etat){
            data.scolariteTypeIds.push(id);
        } else {
            _.remove(data.scolariteTypeIds, function(n) {
                return n === id;
            });
        }
        formRef.current.setValue('scolariteTypeIds', data.scolariteTypeIds);
        proformaStateUpdate.dataSel(logic, data);
    };

    const handleDayChange = (value: moment.Moment,     context: any) => {
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        data.laDateForm = value.clone().endOf('month');
        formRef.current.setValue('laDateForm', data.laDateForm);
        proformaStateUpdate.dataSel(logic, data);
    }

    const handleLoadChildren = () => {

        if(_.isEmpty(logic.dataSel.groupesectionsIds)){
            return ;
        }

        proformaStateUpdate.processingLoadingStateNormBtn(logic, true);
        const proformaNom = formRef.current.getValues('nom') ;
        const titleDocument = formRef.current.getValues('titleDocument') ;
        profromaBusinessLogic.loadChildren(logic, logic.dataSel.groupesectionsIds, logic.dataSel.profilsIds).then((val:ScolariteProformaGroupEnfant[])=>{
            const data = _.cloneDeep(logic.dataSel);
            data.scolariteProformaGroupEnfants =  val;
            data.nom = proformaNom ;
            data.titleDocument = titleDocument ;

            // @ts-ignore
            proformaStateUpdate.dataSel(logic, data) ;
            proformaStateUpdate.processingLoadingStateNormBtn(logic, false);
            proformaStateUpdate.tableDetailsKeyValue(logic, new Date().getTime());
            formRef.current.setValue('scolariteProformaGroupEnfants', data.scolariteProformaGroupEnfants);
            formRef.current.setValue('nom', data.nom);
            formRef.current.setValue('titleDocument', data.titleDocument);
        }).catch((error:any) => {
            proformaStateUpdate.processingLoadingStateNormBtn(logic, false);
        });
    }

    const handleRowsDelete = (rows:any) => {
        const data = _.cloneDeep(logic.dataSel);
        const datas = _.cloneDeep(data.scolariteProformaGroupEnfants);
        const idsToDelete:number[] = [];
        rows.map((row:any) => {
            const rowData:Child = datas[row.index];
            // @ts-ignore
            idsToDelete.push(rowData.id);
        });
        _.remove(datas, function(n:any) {
            return idsToDelete.includes(n.id ?? -1);
        });

        data.scolariteProformaGroupEnfants = datas;
        proformaStateUpdate.dataSel(logic, data);
        proformaStateUpdate.tableDetailsKeyValue(logic, new Date().getTime());
        formRef.current.setValue('scolariteProformaGroupEnfants', data.scolariteProformaGroupEnfants);
    }

    const onSubmit = async (data: FormData) => {
        proformaStateUpdate.processingSubmitStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: ScolariteProforma = _.cloneDeep(data);
        dataToSend.scolariteProformaGroupEnfants = logic.dataSel.scolariteProformaGroupEnfants ;
        dataToSend.scolariteProformaGroupEnfants = _.map(dataToSend.scolariteProformaGroupEnfants, item => {
            // @ts-ignore
            item.child = item?.childForm ;
            // @ts-ignore
            item.groupEnfant = item?.groupEnfantForm ;
            return item ;
        })
        // @ts-ignore
        const toSend = SCOLARITEPROFORMA_CONSTANT.convertToBackEnd(logic, dataToSend);

        profromaBusinessLogic.saveEdit(
            logic,
            messages,
            toSend,
            toSend.id).then((data:any) => {
            proformaStateUpdate.openEditDialogForm(logic, false);
            proformaStateUpdate.processingSubmitStateNormBtn(logic, false);
            proformaStateUpdate.tableKeyValue(logic, moment().valueOf()) ;
            // @ts-ignore
            proformaStateUpdate.dataSel(logic, data) ;
            logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
        }).catch((error:any) => {
            proformaStateUpdate.processingSubmitStateNormBtn(logic, false);
        });
    };

    const scolariteAnneesMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteAnneesRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteAnneesRef?.current]) ;

    const groupesectionsMenuItems = useMemo<any>(() => {
        return _.map(logic.groupesectionsRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                <Checkbox checked={logic.dataSel.groupesectionsIds?.includes(item.id)}/>
                <ListItemText primary={item.nom}/>
            </MenuItem>
        ));
    }, [logic.groupesectionsRef?.current, logic.dataSel.groupesectionsIds]) ;

    const profilsMenuItems = useMemo<any>(() => {
        return _.map(logic.profilsRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                <Checkbox checked={logic.dataSel.profilsIds?.includes(item.id)}/>
                <ListItemText primary={item.nom}/>
            </MenuItem>
        ));
    }, [logic.profilsRef?.current, logic.dataSel.profilsIds]) ;

    const scolariteTypeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteTypeRef?.current, item => (
            <FormControlLabel
                key={item.id}
                control={<Checkbox key={item.id}
                                   checked={logic.dataSel.scolariteTypeIds?.includes(item.id)}
                                   onChange={() => handleOnFraisCheck(item.id)} />}
                label={item.nom} />
        ));
    }, [logic.scolariteTypeRef?.current, logic.dataSel.scolariteTypeIds]) ;


   useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteProformaDataImpl);
            proformaStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }
        const data:ScolariteProforma = _.cloneDeep(logic.dataSel);
        if(_.isNull(logic.dataSel.id)){
            data.groupesectionsIds = [] ;
            data.scolariteTypeIds = [] ;
            data.scolariteAnneeId = logic.idScolariteAnneeSel;
            logic.groupesectionsRef.current.forEach((item:Groupesection) => {
                // @ts-ignore
                data.groupesectionsIds.push(item.id);
            });/*
            logic.scolariteTypeMapRef.current.forEach((item:ScolariteType) => {
                // @ts-ignore
                data.scolariteTypeIds.push(item.id);
            });*/
        }
        formRef.current.reset(_.cloneDeep(data));
        proformaStateUpdate.dataSel(logic, data);
        return () => {
            formRef.current.reset(_.cloneDeep(scolariteProformaDataImpl));
        };
    }, [logic.openEditDialogForm]);


    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingSubmitStateNormBtn}
            title={<FormattedMessage id={messages.selection_edition}/>}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.scolaritee_annee}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={logic.dataSel.scolariteAnneeId}
                            onChange={handleOnScolariteAnneChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteAnneesMenuItems}
                        </TextField>
                        <div className={styleClasses.errorHelper}>
                            {errors.scolariteAnneeId &&
                                <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.table_h_echeancier_date}/>}
                                value={logic.laDateForm}
                                required
                                //@ts-ignore
                                onChange={handleDayChange}
                                views={['year', 'month']}
                                slotProps={{textField: {color: 'secondary'}}}
                                sx={{
                                    width: {
                                        xs: '100% !important',
                                        sm: '100% !important',
                                        fontWeight: 'normal',
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <div className={styleClasses.errorHelper}>
                            {errors.laDateForm &&
                                <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>

                    <Grid item md={5} xs={6}>
                        <div className={styleClasses.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.header_nom}/>}
                                size="small"
                                color={"secondary"}
                                defaultValue={logic.dataSel.nom}
                                required
                                inputProps={{
                                    ...formRef.current.register("nom")
                                }}
                            />
                        </div>
                        <div className={styleClasses.errorHelper}>
                            {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </Grid>

                    <Grid item md={7} xs={12}>
                        <div className={styleClasses.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.title_document_optional}/>}
                                size="small"
                                color={"secondary"}
                                defaultValue={logic.dataSel.titreDocument}
                                inputProps={{
                                    ...formRef.current.register("titreDocument")
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid item md={5} xs={12}>
                        <FormControl
                            sx={{
                                display: {xs: 'block', sm: 'inline-block'},
                                marginBottom: {xs: 2},
                                marginRight: {sm: 2},
                                width: '100%',
                            }}
                        >
                            <InputLabel id="demo-crc-label"><FormattedMessage
                                id={messages.schooling_profile}/></InputLabel>
                            <Select
                                labelId="demo-crc-label"
                                id="demo-crc"
                                multiple
                                size="small"
                                sx={{width: {xs: '100% !important'},}}
                                color={"secondary"}
                                value={logic.dataSel.profilsIds}
                                onChange={handleOnProfilChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.schooling_profile}/>}/>}
                                renderValue={(selected: any) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value: any) => (
                                            <Chip key={value}
                                                  label={logic.profilsMapRef.current.get(value)?.nom ?? ``}/>
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                {profilsMenuItems}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <FormControl
                            sx={{
                                display: {xs: 'block', sm: 'inline-block'},
                                marginBottom: {xs: 2},
                                marginRight: {sm: 2},
                                width: '100%',
                            }}
                        >
                            <InputLabel id="demo-crc-label"><FormattedMessage id={messages.the_group}/></InputLabel>
                            <Select
                                labelId="demo-crc-label"
                                id="demo-crc"
                                multiple
                                size="small"
                                sx={{width: {xs: '100% !important'},}}
                                color={"secondary"}
                                value={logic.dataSel.groupesectionsIds}
                                onChange={handleOnGroupeChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.the_group}/>}/>}
                                renderValue={(selected: any) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value: any) => (
                                            <Chip key={value}
                                                  label={logic.groupesectionMapRef.current.get(value)?.nom ?? ``}/>
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                {groupesectionsMenuItems}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormGroup row={true}>
                            {scolariteTypeMenuItems}
                        </FormGroup>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <LoadingButton
                            loading={logic.processingLoadingStateNormBtn}
                            color="secondary"
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<PeopleIcon/>}
                            aria-label="Loading Children"
                            size="large"
                            onClick={handleLoadChildren}
                        >
                            <FormattedMessage id={messages.load_children}/>
                        </LoadingButton>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{marginTop: 8,}}>
                        <MaterialReactTable
                            columns={columns}
                            data={logic.dataSel.scolariteProformaGroupEnfants}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableColumnResizing={true}
                            key={logic.tableDetailsKeyValue}
                            state={{
                                density: 'compact',
                                isLoading: logic.processingLoadingStateNormBtn,
                            }}
                            localization={logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                            enableEditing={false}
                            //onEditingRowSave={handleSaveRow}
                            enableRowSelection
                            positionActionsColumn="last"
                            muiTableContainerProps={{sx: {maxHeight: '500px'}}}
                            renderTopToolbarCustomActions={({table}: { table: any }) => (
                                <Box
                                    className={styleClasses.topToolbarActionsButton}
                                    sx={{display: 'flex', flexWrap: 'wrap'}}
                                >

                                    <Button variant={"contained"}
                                            component="label"
                                            size="small"
                                            color="error"
                                            sx={{textTransform: 'none', marginLeft: 2, paddingLeft: 1, paddingRight: 2}}
                                            disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                            onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                                    >
                                        <Icon sx={{marginRight: 1}}>delete</Icon>
                                        <FormattedMessage id={messages.delete_action}/>
                                    </Button>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </DialogFormKiller>
    );
}

export default ProformaEditForm ;