import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import _ from "lodash";


export const SEMAINEDATA_CONSTANT = {
    convertToFrontEnd: (item:SemaineData) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(dataSemaineImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:SemaineData) => item,
};

export  const dataSemaineImpl: SemaineData = {
    id: 0,
    nom: '',
    periodeId: 0,
    dateDebut: 0,
    dateFin: 0,
    common:  _.cloneDeep(commonDataImp),
}

export const semaineDataSchema = yup.object().shape({
    id: yup.number().nullable(),
    periodeId: yup.number(),
    nom: yup.string().nullable(),
    dateDebut: yup.number(),
    dateFin: yup.number(),
    common: yup.mixed<Common>(),
});
export type SemaineData = yup.InferType<typeof semaineDataSchema>;