import React, {useContext} from "react";
import {AlbumPhotoContext} from "./AlbumPhoto";
import _ from "lodash";
import albumPhotoBusinessLogic from "./businesslogic/AlbumPhotoBusinessLogic";
import {childDataImpl} from "../../constants/type/Child";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {Box} from "@mui/material";
import AlbumPhotoEditToolBar from "./AlbumPhotoEditToolBar";
import AlbumPhotoCanvas from "./AlbumPhotoCanvas";
import albumPhotoStateUpdate from "./businesslogic/AlbumPhotoStateUpdate";

function AlbumPhotoEditForm() {
    const logic: any = useContext(AlbumPhotoContext);
    const styleClasses = logic.styleClasses;

    React.useEffect(() => {
        if(logic.showForm && _.isNumber(logic.childSel.id)){
            albumPhotoBusinessLogic.loadAlbumPhoto(logic, logic.childSel);
        } else{
            albumPhotoStateUpdate.childSel(logic, _.cloneDeep(childDataImpl));
            albumPhotoStateUpdate.photoList(logic, []);
            albumPhotoStateUpdate.photosIdsSelected(logic, []);
        }
    }, [logic.showForm]);

    if(logic.showForm === false){
        return <></>;
    }
    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={styleClasses.root}>
                <div className={styleClasses.toolbar}>
                    <Box>
                        <AlbumPhotoContext.Provider value={logic}>
                            <AlbumPhotoEditToolBar />
                        </AlbumPhotoContext.Provider>
                    </Box>
                </div>
                <div>
                    <Box>
                        <AlbumPhotoContext.Provider value={logic}>
                            <AlbumPhotoCanvas />
                        </AlbumPhotoContext.Provider>
                    </Box>
                </div>
            </div>
        </PapperBlock>
    );
}

export  default AlbumPhotoEditForm ;