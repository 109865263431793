import {RdvEtat} from "./RdvEtat";
import {creneauNormalRdvDataImp, CreneauRdv, CRENEAURDV_CONSTANT} from "./CreneauRdv";
import {RdvType} from "./RdvType";
import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import _ from "lodash";
import * as yup from "yup";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";

//TODO CREATE EMPLOYE PROPOSITION SHOW (report "postpone rdv" form)

export enum RdvPeriode {
    PAST = 'PAST',
    CURRENT = 'CURRENT',
    UPCOMING = 'UPCOMING',
}
export const rdvFilterDataImpl : RdvFilter = {
    meetingType: [],
    meetingStatus: [],
    periode: [],
};
export const rdvFilterSchema = yup.object().shape({
    meetingType: yup.array().of(yup.mixed<RdvType>()).nonNullable().default([]),
    meetingStatus: yup.array().of(yup.mixed<RdvEtat>()).nonNullable().default([]),
    periode: yup.array().of(yup.mixed<RdvPeriode>()).nonNullable().default([]),
});
export type RdvFilter = yup.InferType<typeof rdvFilterSchema>;


export const RDV_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:Rdv) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(item.meetingType ===  RdvType.NORMAL ? rdvNormalDataImpl : rdvPresetDataImpl);
        item = {...itemInit, ...item} ;

        item.startForm          = moment(item.dateDebut);
        item.endForm            = moment(item.dateFin);
        item.start              = item.startForm.toDate();
        item.end                = item.endForm.toDate();
        item.dureeMeeting       = item.dureeMeeting ?? moment.duration(5, 'minutes').asMilliseconds();
        item.deadlineUpdateForm = moment.duration(item.deadlineUpdate).asHours();
        item.dureeMeetingForm   = Math.max(5, moment.duration(item.dureeMeeting).asMinutes());
        // @ts-ignore
        item.enfantIdForm       = item.creneauRdvs[0]?.creneauRdvEnfantParents[0]?.enfantId ?? 0 ;
        // @ts-ignore
        item.employeeIdForm     = item.creneauRdvs[0]?.creneauRdvEmployees[0]?.employeeId ?? 0 ;

        // @ts-ignore
        item.creneauRdvs        = _.map(item.creneauRdvs, creneauRdv => CRENEAURDV_CONSTANT.convertToFrontEnd(logicRacine, item, creneauRdv));
        return item ;
    },
    convertToBackEnd: (item:Rdv) => {
        item.dateDebut          = item.startForm?.valueOf();
        item.dateFin            = item.endForm?.valueOf();
        item.deadlineUpdate     = moment.duration(item.deadlineUpdateForm, 'hours').asMilliseconds();
        item.dureeMeeting       = moment.duration(item.dureeMeetingForm, 'minutes').asMilliseconds();
        // @ts-ignore
        item.creneauRdvs        = _.map(item.creneauRdvs, CRENEAURDV_CONSTANT.convertToBackEnd);
        return item ;
    }
};
export  const rdvNormalDataImpl: Rdv = {
    id: null,
    meetingType: RdvType.NORMAL,
    dateDebut: 0,
    dateFin: 0,
    objet: '',
    details: '',
    classeId: 0,
    userInitor:0,
    maxInviter: 1,
    dureeMeeting: 0,
    deadlineUpdate: moment.duration(0, 'hours').asMilliseconds(),
    meetingStatus: RdvEtat.WAIT,
    totalCreneau: 0,
    maxEnfantChoice: 1,
    creneauRdvs: [_.cloneDeep(creneauNormalRdvDataImp)],
    start: moment().toDate(),
    end: moment().add(1, 'days').toDate(),
    common: _.cloneDeep(commonDataImp),
    deadlineUpdateForm: 0,
    dureeMeetingForm: 10,
    startForm: moment(),
    endForm: moment().add(1, 'days'),
    enfantIdForm:0,
    employeeIdForm:0,
}


export  const rdvPresetDataImpl: Rdv = {
    id: null,
    meetingType: RdvType.PRESET,
    dateDebut: 0,
    dateFin: 0,
    objet: '',
    details: '',
    classeId: 0,
    userInitor:0,
    maxInviter: 1,
    dureeMeeting: 0,
    deadlineUpdate: moment.duration(48, 'hours').asMilliseconds(),
    meetingStatus: RdvEtat.WAIT,
    totalCreneau: 0,
    maxEnfantChoice: 1,
    creneauRdvs: [],
    start: moment().toDate(),
    end: moment().add(1, 'days').toDate(),
    common: _.cloneDeep(commonDataImp),
    deadlineUpdateForm: 48,
    dureeMeetingForm: 10,
    startForm: moment(),
    endForm: moment().add(30, 'minutes'),
    enfantIdForm:1,
    employeeIdForm:1,
}


export const presetRdvSchema = yup.object().shape({
    id:  yup.number().nullable(),
    meetingType:  yup.mixed<RdvType>().oneOf(Object.values(RdvType)),
    dateDebut: yup.number(),
    dateFin: yup.number(),
    objet: yup.string().required(),
    details: yup.string().required(),
    classeId: yup.number().min(1).required(),
    userInitor: yup.number().min(0).nullable(),
    maxInviter: yup.number().min(1),
    dureeMeeting: yup.number(),
    deadlineUpdate: yup.number().min(0).defined(),
    meetingStatus: yup.mixed<RdvEtat>().oneOf(Object.values(RdvEtat)),
    totalCreneau: yup.number(),
    maxEnfantChoice: yup.number().min(1),
    creneauRdvs:  yup.array().of(yup.mixed<CreneauRdv>()).nonNullable().default([]),
    start : yup.date(),
    end : yup.date(),
    common: yup.mixed<Common>(),
    deadlineUpdateForm: yup.number().min(0).defined(),
    dureeMeetingForm: yup.number().min(5).defined(),
    startForm: yup.mixed<moment.Moment>().default(moment()).required(),
    endForm: yup.mixed<moment.Moment>().default(moment().add(1,'hours')).required(),
    enfantIdForm: yup.number().default(0),
    employeeIdForm: yup.number().default(0),
});
export const rdvSchema = yup.object().shape({
    id:  yup.number().nullable(),
    meetingType:  yup.mixed<RdvType>().oneOf(Object.values(RdvType)),
    dateDebut: yup.number(),
    dateFin: yup.number(),
    objet: yup.string().required(),
    details: yup.string().required(),
    classeId: yup.number().min(0).nullable(),
    userInitor: yup.number().min(0).nullable(),
    maxInviter: yup.number().min(1),
    dureeMeeting: yup.number(),
    deadlineUpdate: yup.number().min(0).defined(),
    meetingStatus: yup.mixed<RdvEtat>().oneOf(Object.values(RdvEtat)).default(RdvEtat.WAIT),
    totalCreneau: yup.number(),
    maxEnfantChoice: yup.number().min(1),
    creneauRdvs:  yup.array().of(yup.mixed<CreneauRdv>()).nonNullable().default([]),
    start : yup.date(),
    end : yup.date(),
    common: yup.mixed<Common>(),
    deadlineUpdateForm: yup.number().min(0).defined(),
    dureeMeetingForm: yup.number().min(5).defined(),
    startForm: yup.mixed<moment.Moment>().default(moment()).required(),
    endForm: yup.mixed<moment.Moment>().default(moment().add(1,'hours')).required(),
    enfantIdForm: yup.number().min(1).required(),
    employeeIdForm: yup.number().notRequired(),
});

export type Rdv = yup.InferType<typeof rdvSchema>;


export  const lectureRdvDataImpl: LectureRdv = {
    meetingStatus: RdvEtat.WAIT,
    title: <FormattedMessage id={messages.normal_title} />,
    subtitle: 'rdv_read_void',
    parentStatusLibelle: 'rdv_read_void',
    showDeleteBtn: false,
    parentOutcomeShow:false,
    employeeOutcomeShow:false,
    employeeStatusLibelle: 'rdv_read_void',
    employeeToggle: '',
    employeeEditDisable: false,
    lastReportDateDebut:0,
    lastReportDateFin:0,
    lastReportUserId:0,
    timeSlotStyle:{},
}
export const lectureRdvSchema = yup.object().shape({
    meetingStatus:yup.mixed<RdvEtat>().oneOf(Object.values(RdvEtat)),
    title: yup.mixed<any>(),
    subtitle: yup.string(),
    parentStatusLibelle: yup.string(),
    showDeleteBtn: yup.boolean().default(false),
    parentOutcomeShow: yup.boolean().default(true),
    employeeOutcomeShow: yup.boolean().default(true),
    employeeStatusLibelle: yup.string(),
    employeeToggle: yup.string(),
    employeeEditDisable: yup.boolean().default(false),
    lastReportDateDebut: yup.number().min(0),
    lastReportDateFin: yup.number().min(0),
    lastReportUserId: yup.number().min(0),
    timeSlotStyle: yup.mixed<any>(),
});
export type LectureRdv = yup.InferType<typeof lectureRdvSchema>;
