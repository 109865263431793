import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import _ from "lodash";
import * as yup from "yup";
import {Child, childDataImpl} from "./Child";
import {
    columnsScolariteGroupPlugin,
    excelScolariteGroupDataMappingPlugin,
    ScolariteGroup,
    scolariteGroupCustomOptionsPlugins,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import messages from "../../page/messages/general/messages";
import React from "react";
import {isColumnVisible} from "../../helpers/helpers";

export const SCOLARITESANSECHEANCEENFANT_CONSTANT = {
    convertToFrontEnd: (logic: any, item: ScolariteSansEcheanceEnfant) => {
        
        const itemInit = _.cloneDeep(scolariteSansEcheanceEnfantDataImpl);
        item = {...itemInit, ...item};

        item.ladateForm = moment(item.ladate);
        // @ts-ignore
        item.liredateForm = item.ladateForm?.clone().format('L');
        item.childForm = logic.childrenMapRef.current.get(item?.enfantId) ?? _.cloneDeep(childDataImpl);
        item.scolariteGroupForm = logic.scolariteGroupMapRef.current.get(item?.scolariteGroupId) ?? _.cloneDeep(scolariteGroupDataImpl);

        return item;
    },
    convertToBackEnd: (logic: any, item: ScolariteSansEcheanceEnfant) => item
};
export  const scolariteSansEcheanceEnfantDataImpl: ScolariteSansEcheanceEnfant = {
    id: null,
    ladate: moment().endOf('day').valueOf(),
    enfantId: 0,
    parentId:0,
    montant: 0,
    infos: '',
    scolariteGroupId:0,
    ladateForm: moment().endOf('day'),
    liredateForm: '',
    childForm: _.cloneDeep(childDataImpl),
    scolariteGroupForm: _.cloneDeep(scolariteGroupDataImpl),
    common: _.cloneDeep(commonDataImp),

}

export const scolariteSansEcheanceEnfantSchema = yup.object().shape({
    id:  yup.number().nullable(),
    ladate: yup.number().notRequired(),
    enfantId: yup.number().notRequired(),
    parentId: yup.number().notRequired(),
    montant: yup.number().notRequired(),
    infos: yup.string().default(''),
    scolariteGroupId: yup.number().required(),
    ladateForm: yup.object().nullable().required(),
    liredateForm: yup.string().default('').notRequired(),
    childForm: yup.mixed<Child>().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().notRequired(),
    common: yup.mixed<Common>(),
});

export const excelFraisOneShotDataMapping = ({rows=[], columns=[], intl={}, table=[]}:{ rows:any[]; intl:any;  table: any; columns: any[];}) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
       // obj = excelChildDataMappingPlugin({obj, row:data, intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
        obj = excelScolariteGroupDataMappingPlugin({obj, row:data, table, columns, prefix:`scolariteGroupForm.`, intl, infoSpace:intl.formatMessage({id:messages.fees})});
        //obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteGroupForm?.scolariteAnneeForm?.nom;

        if(isColumnVisible(columns, 'liredateForm')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, 'montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        return obj;
    });
}

export const fraisOneShotCustomOptions = {
    columnVisibility : {
        //...childCustomOptionsPlugins.columnVisibility,
        ...scolariteGroupCustomOptionsPlugins.columnVisibility,
        'scolariteGroupForm.scolariteAnneeForm.nom':false,
        'scolariteGroupForm.nom':true,
    }
} ;

export const fraisOneShotCustomOptionsPlugin = {
    columnVisibility : {
        ...fraisOneShotCustomOptions.columnVisibility,
    }
} ;

export const columnsFraisOneShot = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsScolariteGroupPlugin({intl, prefixKey:`scolariteGroupForm.`, nomSpace:``}),
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;

export type ScolariteSansEcheanceEnfant = yup.InferType<typeof scolariteSansEcheanceEnfantSchema>;

