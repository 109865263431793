import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config-table-jss";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {MRT_Localization_FR} from 'material-react-table/locales/fr';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Localization,
    useMaterialReactTable
} from 'material-react-table';
import ClassroomForm from "./ClassroomForm";
import {useForm} from "react-hook-form";
import {request} from "../../../helpers/axios_helpers";
import {CONSTANT} from "../../../constants";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../helpers/errorManager";
import _ from "lodash";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";

const useStyles = makeStyles(styles);

type ClassroomDataType = {
    id: number;
    promotionId: number,
    className: string;
    common: any,
};

type ClassroomFormValues = {
    id: number;
    promotionId: number,
    className: string,
}




function Classroom(props:any) {
    const { locale, theme, snackbarShowMessage } = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const [classroomData, setClassroomData] = useState<ClassroomDataType[]>([]);
    const [open, setOpen] = useState(false);
    const [stateClassroom, setStateClassroom] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'Classroom',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<ClassroomDataType>(mytableState), []);

    const updateDataList = (datas:ClassroomDataType[]) => {
        setClassroomData([..._.sortBy(datas, ['className'])]);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };



    const form = useForm<ClassroomFormValues>({
        defaultValues: {
            id: 0,
            className: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const addClassroom = () => {
        setStateClassroom(true);
        setValue('className', '');
        handleClickOpen();
    }


    const editClassroom = (row:any) => {
        setStateClassroom(false);
        const dataClassroom: any = classroomData[row.index];
        setValue('id', dataClassroom.id);
        setValue('className', dataClassroom.className);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let classData:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateClassroom) {
                classData.id = response.data.id;
                classData.promotionId = response.data.promotionId;
                classData.className = data.className;
                classData.couleur = response.data.couleur;
                classData.common = response.data.common;
                updateDataList([classData, ...classroomData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                classroomData.find((classroom:any, index:any) => {
                    if(classroom.id === data.id) {
                        classroomData[index].className = data.className;
                        updateDataList(classroomData);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: ClassroomFormValues) => {
        const dataToSend = {
            promotionId: 1,
            nom: data.className,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/classes", dataToSend);
    }

    const onSubmitEditForm = (data: ClassroomFormValues) => {
        const classroomFind: any = classroomData.find(classroom => classroom.id === data.id);
        const dataToSend = {
            ...classroomFind,
            id: data.id,
            //promotionId: classroomFind.promotionId,
            nom: data.className,
            //couleur: classroomFind.couleur,
            //common: classroomFind.common
        }
        sendData(data, 'PUT', `/corebase/classes/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const classroomSelectedId:any = [];

        rows.map((row:any) => {
            const data = classroomData[row.index];
            dataSelected.push(data);
            classroomSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/classes/bouquet', classroomSelectedId).then((response) => {
            const classroomList = classroomData.filter((classData) => {
                if(!dataSelected.includes(classData)){
                    return classData;
                }
            });
            updateDataList(classroomList);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }



    const columns = useMemo<MRT_ColumnDef<ClassroomDataType>[]>(
        () => [
            {
                accessorKey: 'className',
                header:  intl.formatMessage({id: messages.dialog_table_header_classroom}),
            },
        ],
        [activeLang],
    );

    const localization = useMemo<MRT_Localization>(() =>activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: classroomData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addClassroom}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editClassroom(row)}
                styleClasses={classes}
            />
        )
    });



    useEffect(() => {
        setTableDataLoading(true);

        request("GET", `/corebase/classes`, {}).then((response) => {
            let classListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.classeDTOModelList : [];
            classListRequest = classListRequest.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });

            const dataList = classListRequest.map((classroom:any) => {
                let classData:any = {};
                classData.id = classroom.id;
                classData.className = classroom.nom;
                classData.promotionId = classroom.promotionId
                classData = {...classroom, ...classData, ...{ couleur: classroom.couleur, common: classroom.common }}
                return classData;
            })
            //setClassroomData(_.cloneDeep(formatDatas(classListRequest)));
            updateDataList(dataList);
            setTableDataLoading(false);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            setTableDataLoading(false)
        });

        return () => logicMRT.storeState(tableConfig.getState());

    }, []);


    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />
            {/*<MaterialReactTable
                columns={columns}
                data={classroomData}
                enableRowSelection
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact',
                    isLoading: tableDataLoading,
                }}
                localization={activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                positionActionsColumn="last"
                key={tableKeyValue}
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }: {table:any}) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => addClassroom()}
                        >
                            <FormattedMessage id={messages.add_action} />
                        </Button>

                        <Button
                            //only export selected rows
                            color="error"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <FormattedMessage id={messages.delete_action} />
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                onClick={() => editClassroom(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />*/}

            <ClassroomForm
                title={ stateClassroom ? <FormattedMessage id={messages.dialog_title_classroom} /> : <FormattedMessage id={messages.dialog_edit_title_classroom} /> }
                open={open}
                setOpen={setOpen}
                theme={theme}
                classes={classes}
                register={register}
                onSubmit={stateClassroom ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />

        </div>
    );
}

export default withSnackbar(Classroom);
