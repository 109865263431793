import {RdvEtat} from "./RdvEtat";
import {Common, commonDataImp} from "./Common";
import {CreneauRdvEmployee, CRENEAURDVEMPLOYEE_CONSTANT, creneauRdvEmployeeDataImp} from "./CreneauRdvEmployees";
import moment from "moment";
import {Rdv} from "./Rdv";
import * as yup from "yup";
import {
    CreneauRdvEnfantParent,
    CRENEAURDVENFANTPARENT_CONSTANT,
    creneauRdvEnfantParentDataImp
} from "./CreneauRdvEnfantParent";
import _ from "lodash";
import {RdvType} from "./RdvType";

export const CRENEAURDV_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, rdv:Rdv, item:CreneauRdv) => {
        const itemInit = _.cloneDeep(rdv.meetingType === RdvType.NORMAL ? creneauNormalRdvDataImp : creneauRdvDataImp);
        item = {...itemInit, ...item} ;

        item.startForm          = moment(item.dateDebut);
        item.endForm            = moment(item.dateFin);
        item.start              = item.startForm.toDate();
        item.end                = item.endForm.toDate();
        item.title              = rdv.objet;
        item.rdvTitleForm       = rdv.objet;
        item.rdvTypeForm        = rdv.meetingType;
        /*item.childNomPrenomForm      = : '',
            parentNameForm: '',
            teacherNameForm: '',
            classeNameForm: '',*/
        // @ts-ignore
        if(_.isNull(item.lastReportUserId) === false && item.lastReportUserId > 0){
            item.lastReportDateDebutForm    = moment(item.lastReportDateDebut ?? 0);
            item.lastReportDateFinForm      = moment(item.lastReportDateFin ?? 0);
        }
        // @ts-ignore
        if(_.isNull(rdv.classeId) === false && rdv.classeId > 0){
           const classeRoom = _.find(logicRacine.classeRoomRef.current, {id:rdv.classeId});
           item.classeNameForm = classeRoom?.nom;
        }
        if(rdv.meetingType === RdvType.PRESET){
            item.employeeFonctionForm = 'Teacher';
            item.employeeNameForm = 'Team';
        }



        // @ts-ignore
        item.creneauRdvEmployees  = _.map(item.creneauRdvEmployees,
            // @ts-ignore
              crenoempl => CRENEAURDVEMPLOYEE_CONSTANT.convertToFrontEnd(logicRacine, crenoempl));

        item.creneauRdvEmployees.forEach(creneaurdvemployer => {
            if(_.isNull(creneaurdvemployer) || _.isUndefined(creneaurdvemployer)){
                return ;
            }
            if(_.isNull(creneaurdvemployer.employeeForm) || _.isUndefined(creneaurdvemployer.employeeForm)){
                return ;
            }

            // @ts-ignore
            const fonction = logicRacine.jobsRef.current.get(creneaurdvemployer.employeeForm?.fonctions[0]?.fonctionId);
            item.employeeNameForm       = `${creneaurdvemployer.employeeForm.person?.nom} ${creneaurdvemployer.employeeForm.person?.prenom}`;
            item.employeeFonctionForm   = `${fonction?.nom}`;
        });

        // @ts-ignore
        item.creneauRdvEnfantParents = _.map(item.creneauRdvEnfantParents,
            // @ts-ignore
                crenoenfparent => CRENEAURDVENFANTPARENT_CONSTANT.convertToFrontEnd(logicRacine, crenoenfparent));

        for(const crenoparenfant of item.creneauRdvEnfantParents){
            if(_.isNull(crenoparenfant) || _.isUndefined(crenoparenfant)){
                continue ;
            }
            if(_.isNull(crenoparenfant.childForm) || _.isUndefined(crenoparenfant.childForm)){
                continue ;
            }
            item.title = `${crenoparenfant.childForm.childNomPrenomForm}`;
            item.childNomPrenomForm = `${crenoparenfant.childForm.childNomPrenomForm}`;
            item.childphotoForm = `${crenoparenfant.childForm.childphotoForm}`;
            // @ts-ignore
            item.classeNameForm = crenoparenfant.childForm.eleves[0]?.classe?.nom ?? null;

            if(_.isNull(crenoparenfant.parentForm) || _.isUndefined(crenoparenfant.parentForm)){
                continue ;
            }
            // @ts-ignore
            item.parentIdForm   = crenoparenfant.parentForm.id  ;
            item.parentNameForm = `${crenoparenfant.parentForm.person?.nom} ${crenoparenfant.parentForm.person?.prenom}`;
            item.parentPhoneForm = crenoparenfant.parentForm.person?.phone1 ?? crenoparenfant.parentForm.person?.phone2 ?? '';
        }
        /*item.creneauRdvEnfantParents.forEach(crenoparenfant => {

        });*/
        // @ts-ignore
       return item ;
    },
    convertToBackEnd: (item:CreneauRdv) => {
        item.dateDebut          = item.startForm.valueOf();
        item.dateFin            = item.endForm.valueOf();
        // @ts-ignore
        item.creneauRdvEnfantParents  = _.map(item.creneauRdvEnfantParents, CRENEAURDVENFANTPARENT_CONSTANT.convertToBackEnd);
        // @ts-ignore
        item.creneauRdvEmployees  = _.map(item.creneauRdvEmployees, CRENEAURDVEMPLOYEE_CONSTANT.convertToBackEnd);
        return item ;
    }
};

export const creneauNormalRdvDataImp: CreneauRdv =  {
    id: null,
    rdvId:0,
    totalInviterConfirm: 0,
    meetingStatus: RdvEtat.WAIT,
    creneauRdvEnfantParents: [_.cloneDeep(creneauRdvEnfantParentDataImp)],
    creneauRdvEmployees: [_.cloneDeep(creneauRdvEmployeeDataImp)],
    dateDebut: 0,
    dateFin: 0,
    lastReportDateDebut: 0,
    lastReportDateFin: 0,
    lastReportUserId: 0,
    parentNbrAction: 0,
    employeeNbrAction: 0,
    common: _.cloneDeep(commonDataImp),
    allDay: false,
    title : '',
    start: moment().toDate(),
    end: moment().add(1, 'minutes').toDate(),
    startForm: moment(),
    endForm: moment(),
    lastReportDateDebutForm: null,
    lastReportDateFinForm: null,
    resource: null,
    rdvTitleForm:'',
    rdvTypeForm: RdvType.NORMAL,
    childNomPrenomForm: '',
    childphotoForm:'',
    parentIdForm:0,
    parentNameForm: '',
    parentPhoneForm: '',
    employeeNameForm: '',
    employeeFonctionForm: '',
    classeNameForm: '',
}

export const creneauRdvDataImp: CreneauRdv =  {
    id: null,
    rdvId:0,
    totalInviterConfirm: 0,
    meetingStatus: RdvEtat.WAIT,
    creneauRdvEnfantParents: [creneauRdvEnfantParentDataImp],
    creneauRdvEmployees: [creneauRdvEmployeeDataImp],
    dateDebut: 0,
    dateFin: 0,
    lastReportDateDebut: 0,
    lastReportDateFin: 0,
    lastReportUserId: 0,
    parentNbrAction: 0,
    employeeNbrAction: 0,
    common: _.cloneDeep(commonDataImp),
    allDay: false,
    title : '',
    start: moment().toDate(),
    end: moment().add(1, 'minutes').toDate(),
    startForm: moment(),
    endForm: moment(),
    lastReportDateDebutForm: null,
    lastReportDateFinForm: null,
    resource: null,
    rdvTitleForm:'',
    rdvTypeForm: RdvType.NORMAL,
    childNomPrenomForm: '',
    childphotoForm:'',
    parentIdForm:0,
    parentNameForm: '',
    parentPhoneForm: '',
    employeeNameForm: '',
    employeeFonctionForm: '',
    classeNameForm: '',
}
export const creneauRdvSchema = yup.object().shape({
    id : yup.number().nullable(),
    rdvId: yup.number().required(),
    totalInviterConfirm: yup.number(),
    parentNbrAction: yup.number().min(0),
    employeeNbrAction: yup.number().min(0),
    meetingStatus: yup.mixed<RdvEtat>().required().oneOf(Object.values(RdvEtat)).default(RdvEtat.WAIT),
    creneauRdvEnfantParents: yup.array().of(yup.mixed<CreneauRdvEnfantParent>()).nonNullable().defined(),
    creneauRdvEmployees: yup.array().of(yup.mixed<CreneauRdvEmployee>()).nonNullable().defined(),
    dateDebut: yup.number().min(1),
    dateFin: yup.number().min(1),
    lastReportDateDebut: yup.number().min(0),
    lastReportDateFin: yup.number().min(0),
    lastReportUserId: yup.number().min(0),
    common: yup.mixed<Common>().required(),
    allDay : yup.boolean().nullable(),
    title: yup.object().nullable(),
    start : yup.date().required(),
    end : yup.date().required(),
    startForm : yup.mixed<moment.Moment>().required(),
    endForm : yup.mixed<moment.Moment>().required(),
    lastReportDateDebutForm: yup.mixed<moment.Moment>().nullable(),
    lastReportDateFinForm: yup.mixed<moment.Moment>().nullable(),
    resource: yup.object().nullable(),
    rdvTitleForm: yup.string().default(''),
    rdvTypeForm: yup.mixed<RdvType>(),
    childNomPrenomForm: yup.string().default(''),
    childphotoForm: yup.string().default(''),
    parentIdForm: yup.number().min(0),
    parentNameForm: yup.string().default(''),
    parentPhoneForm: yup.string().default(''),
    employeeNameForm: yup.string().default(''),
    employeeFonctionForm: yup.string().nullable(),
    classeNameForm: yup.string().default('').default(''),
});

export type CreneauRdv = yup.InferType<typeof creneauRdvSchema>;
