import React from 'react';
import classNames from "classnames";
import {FormControlLabel, MenuItem, Switch, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import DialogForm from "../../../../components/ui/form/DialogForm";


function SchoolFeesForm(props:any) {
    const { theme, title, open, setOpen, classes, onSubmit, register, } = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.additional_fees_title} />}
                    size="small"
                    color={"secondary"}
                    {...register("nom", {
                        required: "Additional fees name is required"
                    })}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.schooling_profile} />}
                    defaultValue={''}
                    //value={profile || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    //onChange={(a:any) => handleProfileChange(a)}
                    inputProps={{
                        ...register('profile', {
                            required: 'Profile is required',
                        })
                    }}
                >
                    <MenuItem value="0" sx={{fontWeight:400}}>
                        Tous les profils
                    </MenuItem>
                </TextField>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.schooling_amount} />}
                    type={"number"}
                    size="small"
                    color={"secondary"}
                    {...register("montant", {
                        required: "Schooling amount is required"
                    })}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <label style={{display:'inline-block', marginRight:15}}><FormattedMessage id={messages.schooling_fees_obligatory} /> : </label>
                <FormControlLabel control={<Switch defaultChecked />} label="oui" />
            </div>


        </DialogForm>
    );
}

export default SchoolFeesForm;
