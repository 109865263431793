import _ from "lodash";
import {request} from "../../../../helpers/axios_helpers";
import {ChildStuff, CHILDSTUFF_CONSTANT} from "../../../../constants/type/ChildStuff";
import childStuffStateUpdate from "./ChildStuffStateUpdate";
import {FormattedMessage} from "react-intl";
import React from "react";
import {CreneauRdv} from "../../../../constants/type/CreneauRdv";
import messages from "../../../messages/general/messages";

const childStuffBusinessLogic:any = {
    initPostConstruct: (logicRacine:any) => {
        return new Promise<boolean>(resolve =>
            request("GET",  `/corebase/childstuff`, {}).then((response) => {
                const items : ChildStuff[]   = response.data._embedded?.childStuffDTOModelList ?? [];
                childStuffStateUpdate.childStuffList(logicRacine, _.sortBy(_.map(items,  CHILDSTUFF_CONSTANT.convertToFrontEnd), ['nom']));
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
            })
        );
    },
    saveData: async (logicRacine:any, toSend:ChildStuff):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNull(toSend.id) ? 'POST' : 'PUT';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            request(method, `/corebase/childstuff${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved = CHILDSTUFF_CONSTANT.convertToFrontEnd(logicRacine, response.data);
                childStuffBusinessLogic.updateDataList(logicRacine, _.cloneDeep(logicRacine.childStuffList), dataSaved);
                logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logicRacine.snackbarShowMessage(error.response.data?.message, 'error')
                    : logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    updateDataList: (logicRacine:any, datas:ChildStuff[], newData:ChildStuff) => {
        let events: CreneauRdv[] = [];
        if(_.isNull(newData)){
            return ;
        }
        const index = _.findIndex(datas, {id:newData.id ?? 0});
        if(index < 0){
            // @ts-ignore
            datas.unshift(newData);
        }else {
            // @ts-ignore
            datas[index] = newRdv ;
        }
        childStuffStateUpdate.childStuffList(logicRacine, _.cloneDeep(datas));
    },
}

export default childStuffBusinessLogic ;