export const scope = 'boilerplate.components.Sidebar';

const messages:any = {}

messages['dashboard'] = `${scope}.dashboard`

messages['config'] = `${scope}.config`
messages['config_submenu'] = `${scope}.config.config_submenu`
messages['school_period'] = `${scope}.config.school_period`
messages['school_day'] = `${scope}.config.school_day`
messages['personal_type'] = `${scope}.config.personal_type`
messages['school_document_type'] = `${scope}.config.school_document_type`
messages['categorielivre'] = `${scope}.config.categorielivre`
messages['inscription_file_type'] = `${scope}.config.inscription_file_type`
messages['folder_type'] = `${scope}.config.folder_type`
messages['image_rights'] = `${scope}.config.image_rights`
messages['dish_menu'] = `${scope}.config.dish_menu`
// messages['schooling_payment_period'] = `${scope}.config.schooling_payment_period`
messages['schooling_payment'] = `${scope}.config.schooling_payment`


messages['classroom'] = `${scope}.config.classroom`
messages['appointment_type'] = `${scope}.config.appointment_type`
messages['timetable_class'] = `${scope}.config.timetable_class`

messages['appointment'] = `${scope}.appointment`
messages['appointment_calendar'] = `${scope}.appointment.appointment_calendar`
messages['appointment_list'] = `${scope}.appointment.appointment_list`

messages['mesnotifs'] = `${scope}.mesnotifs`
messages['user_profile'] = `${scope}.user_profile`
messages['message_center'] = `${scope}.message_center`
messages['alertmessage'] = `${scope}.alertmessage`
messages['school_document'] = `${scope}.school_document`
messages['personal'] = `${scope}.personal`
messages['products'] = `${scope}.products`
messages['children'] = `${scope}.children`
messages['children_list'] = `${scope}.children_list`
messages['ims_day'] = `${scope}.ims_day`
messages['security_parent'] = `${scope}.security_parent`
messages['week_ims'] = `${scope}.week_ims`

messages['schooling'] = `${scope}.schooling`
messages['schoolfees'] = `${scope}.config.schoolfees`
messages['sondage'] = `${scope}.sondage`


messages['menu_of_year'] = `${scope}.menu_of_year`
messages['my_books'] = `${scope}.my_books`
messages['image'] = `${scope}.image`
messages['photo'] = `${scope}.photo`
messages['mini_club'] = `${scope}.mini_club`
messages['exchange_library'] = `${scope}.exchange_library`
messages['marketing'] = `${scope}.marketing`
messages['books_mangement'] = `${scope}.books_mangement`
messages['books'] = `${scope}.books`
messages['books_loan'] = `${scope}.books_loan`
messages['fraisoneshotenfant'] = `${scope}.fraisoneshotenfant`

export default messages;

/*

[{"id":41,"dataId":7,"dateRead":null,"useruniversSenderUuid":"50dd862c-a5a8-48bb-a333-f2365ea3c96b","useruniversReceiverUuid":"","title":"IMS Day Goulamhussen Hassam Kayden","body":"Commentaire du parent","message":"{\"id\":7,\"theDate\":1710201600000,\"menuJourId\":1110,\"sieste\":true,\"timeDebutSieste\":1710252000000,\"timeFinSieste\":1710257400000,\"enfantId\":3,\"observationCantine\":\"NOT_AT_ALL\",\"observationTeacher\":\"\",\"commentaireParent\":\"Il n'a pas eu l'appetit?. \",\"commentaireParentId\":0,\"commentaireCantine\":\"na pas aimer la nourriture\",\"bowelMvt\":1,\"classeId\":20,\"besoinMenuJour\":null,\"incident\":\"RAS\",\"imsDayChildStuffs\":[{\"id\":22,\"nom\":\"Crème hydratante/Changing cream\",\"quantite\":1,\"imsDayId\":7,\"childStuffId\":10,\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":\"changing_cream\",\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]},{\"id\":23,\"nom\":\"Haut/Top\",\"quantite\":2,\"imsDayId\":7,\"childStuffId\":4,\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":\"top\",\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]}],\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":null,\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]}","nameSender":"Parent HASSAM Anam","nameReceiver":"","roleSender":"PARENT","roleReceiver":"TEACHER","uriErp":"/app/child/child-day","uriMobile":null,"dateSent":1710347049537,"userNotifierUuid":"5e81886b-4289-4019-b687-f6b88457d4cd","photo":"eleves/pictures/kikourainjpg1709214980071.jpg","senderAction":"UPDATE","firebaseResponseId":null,"common":{"etat":"OK","miseajour":1710347049401,"editable":true,"editeur":6,"tag":"imsday","metadata":"{}","id":0,"compositeId":{}},"libelle":"","newEntity":false}]
 */

/*
[
    {
        "id": 41,
        "dataId": 7,
        "dateRead": null,
        "useruniversSenderUuid": "50dd862c-a5a8-48bb-a333-f2365ea3c96b",
        "useruniversReceiverUuid": "",
        "title": "IMS Day Goulamhussen Hassam Kayden",
        "body": "Commentaire du parent",
        "message": "{\"id\":7,\"theDate\":1710201600000,\"menuJourId\":1110,\"sieste\":true,\"timeDebutSieste\":1710252000000,\"timeFinSieste\":1710257400000,\"enfantId\":3,\"observationCantine\":\"NOT_AT_ALL\",\"observationTeacher\":\"\",\"commentaireParent\":\"Il n'a pas eu l'appetit?. \",\"commentaireParentId\":0,\"commentaireCantine\":\"na pas aimer la nourriture\",\"bowelMvt\":1,\"classeId\":20,\"besoinMenuJour\":null,\"incident\":\"RAS\",\"imsDayChildStuffs\":[{\"id\":22,\"nom\":\"Crème hydratante/Changing cream\",\"quantite\":1,\"imsDayId\":7,\"childStuffId\":10,\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":\"changing_cream\",\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]},{\"id\":23,\"nom\":\"Haut/Top\",\"quantite\":2,\"imsDayId\":7,\"childStuffId\":4,\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":\"top\",\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]}],\"common\":{\"id\":0,\"compositeId\":{},\"etat\":\"OK\",\"miseajour\":1710347049401,\"editable\":true,\"editeur\":6,\"tag\":null,\"metadata\":\"{}\"},\"libelle\":\"\",\"compositeId\":{},\"links\":[]}",
        "nameSender": "Parent HASSAM Anam",
        "nameReceiver": "",
        "roleSender": "PARENT",
        "roleReceiver": "TEACHER",
        "uriErp": "/app/child/child-day",
        "uriMobile": null,
        "dateSent": 1710347049537,
        "userNotifierUuid": "5e81886b-4289-4019-b687-f6b88457d4cd",
        "photo": "eleves/pictures/kikourainjpg1709214980071.jpg",
        "senderAction": "UPDATE",
        "firebaseResponseId": null,
        "common": {
            "etat": "OK",
            "miseajour": 1710347049401,
            "editable": true,
            "editeur": 6,
            "tag": "imsday",
            "metadata": "{}",
            "id": 0,
            "compositeId": {}
        },
        "libelle": "",
        "newEntity": false
    }
]
 */

