import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {RoleUser} from "./RoleUser";
import {UserAction} from "./UserAction";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {Rdv} from "./Rdv";
import {RdvEtat} from "./RdvEtat";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";


export const EVENTNOTIFICATION_CONSTANT = {
    convertToFrontEnd: (item:EventNotification) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(eventNotificationDataImpl);
        item = {...itemInit, ...item} ;

        let info = {title:null, subtitle:null};
        info = updateRdvInfo(item, info);
        info = updateProfil(item, info);
        item.title   =  _.isNull(info.title) ? item.title : info.title;
        item.body    = _.isNull(info.subtitle) ? item.body : info.subtitle;

        item.photoForm = _.isEmpty(item.photo)  ? `` : `${BASEURL_RESOURCES}/${item.photo}`;
        return item ;
    },
    convertToBackEnd: (item:EventNotification) => item
};

export  const eventNotificationDataImpl: EventNotification = {
    id: null,
    dataId: 0,
    dateRead: null,
    useruniversSenderUuid: ``,
    useruniversReceiverUuid: ``,
    title:``,
    body:``,
    photo:``,
    message: ``,
    nameSender: ``,
    nameReceiver: ``,
    roleSender: RoleUser.TEACHER,
    roleReceiver: RoleUser.TEACHER,
    uriErp: ``,
    uriMobile: ``,
    dateSent: 0,
    userNotifierUuid: ``,
    uriErpCle: ``,
    uriErpNom: ``,
    photoForm:``,
    senderAction: UserAction.READ,
    common: _.cloneDeep(commonDataImp),
}


export const eventNotificationSchema = yup.object().shape({
    id: yup.number().nullable(),
    dataId: yup.number(),
    dateRead: yup.number().nullable(),
    useruniversSenderUuid: yup.string(),
    useruniversReceiverUuid: yup.string(),
    title: yup.string(),
    body: yup.string(),
    photo: yup.string(),
    message: yup.string(),
    nameSender: yup.string(),
    nameReceiver: yup.string(),
    roleSender: yup.mixed<RoleUser>().default(RoleUser.TEACHER),
    roleReceiver: yup.mixed<RoleUser>().default(RoleUser.TEACHER),
    uriErp: yup.string(),
    uriMobile: yup.string(),
    dateSent: yup.number(),
    userNotifierUuid: yup.string(),
    uriErpCle: yup.string(),
    uriErpNom: yup.string(),
    photoForm: yup.string(),
    senderAction: yup.mixed<UserAction>().default(UserAction.READ),
    common: yup.mixed<Common>(),
});
//const intl = useIntl() ;

export const lireStatutRdv = (meetingStatus: RdvEtat) => {
    let msgId;
    switch (meetingStatus){
        case RdvEtat.WAIT:
            msgId = 'rdv_read_employee_feedback';
            break;

        case RdvEtat.REPORT:
            msgId = 'rdv_read_report_feedback';
            break;

        case RdvEtat.CONFIRM:
            msgId = 'rdv_read_title_confirm_rapport';
            break;

        case RdvEtat.CANCEL:
            msgId = 'rdv_read_cancel_feedback';
            break;

        default:
            msgId = 'rdv_read_void';
    }

    return msgId ;
}
const updateRdvInfo = (item:EventNotification, info:any) =>{
    if(item.common?.tag !== 'rdv'){
        return info;
    }
    const data: Rdv = JSON.parse(item?.message ?? '{}');
    info.subtitle = <FormattedMessage id={messages[lireStatutRdv(data.meetingStatus)]} />;
    return info ;
}
const updateProfil = (item:EventNotification, info:any) =>{
    if(item.common?.tag !== 'enfants'){
        return info;
    }
    info.subtitle = <FormattedMessage id={messages.notif_update_action} />;
    return info ;
}
export type EventNotification = yup.InferType<typeof eventNotificationSchema>;