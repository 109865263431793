import FormControl from "@mui/material/FormControl";
import {Box, Button, Icon, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";
import React from "react";
import UserAutoCompleteInput from "../form/UserAutoCompleteInput";

function AppointmenToolBar(props:any){
    const { classes, locale, users, setUserInfoStatus, setUser, addButton } = props
    const [profil, setProfil] = React.useState('');
    const [classe, setClasse] = React.useState('')
    const [classeStatus, setClasseStatus] = React.useState(false)
    const activeLanguage = locale.split('-')
    const searchPlaceholder:string | null = activeLanguage[0] === 'en' ? 'Search by name' : 'Recherche par nom'

    const handleProfilChange = (event: SelectChangeEvent) => {
        setProfil(event.target.value as string);
        if(event.target.value == '20'){
            setClasseStatus(true)
        }
        else {
            setClasse('')
            setClasseStatus(false)
        }
    };

    const handleClasseChange = (event: SelectChangeEvent) => {
        setClasse(event.target.value as string);
    }

    const handleChange = (user:any) => {
        setUserInfoStatus(false)
        if(user !== null) {
            const userFind = users.find(({ id } : {id:number}) => id === user.id);
            setUser(userFind)
            if(user.id !== 0 ){
                setUserInfoStatus(true)
            }
        }
    }

    return(
        <div>
            <Box sx={{ textAlign:'left'}}>
                <Button variant={"contained"}
                        component="label"
                        size="small"
                        color={"secondary"}
                        sx={{textTransform: 'none', marginTop:0.5 }}
                        onClick={() => addButton()}
                >
                    <Icon className={classes.actionIcon}>add</Icon>
                    <FormattedMessage id={messages.add_appointment} />
                </Button>

                <FormControl
                    sx={{
                        display: { xs: 'block', sm: 'inline-block'},
                        marginTop: { xs: 2, sm: 0},
                        marginBottom: { xs: 2},
                        marginRight: { sm: 2 },
                        marginLeft: { xs: 0, sm: 2 },
                        width:{ xs: 'block', sm: 350},
                    }}
                >
                    <UserAutoCompleteInput
                        locale={locale}
                        data={users}
                        onUserChange={handleChange}
                        placeholderText={searchPlaceholder}
                        backgroundColor='#F1EFF0'
                    />
                </FormControl>

                <FormControl
                    sx={{
                        display: { xs: 'block', sm: 'inline-block'},
                        marginBottom: { xs: 2},
                        marginRight: { sm: 2 },
                    }}
                    size="small"
                    color={"secondary"}
                >
                    <Select
                        value={profil}
                        onChange={handleProfilChange}
                        displayEmpty
                        sx={{ textAlign:"left", width: {xs: '100%', sm: 200 }, fontWeight:'normal' }}
                    >
                        <MenuItem value="" sx={{fontWeight:400}}><FormattedMessage id={messages.all_profiles} /></MenuItem>
                        <MenuItem value={10} sx={{fontWeight:400}}>Teachers & Admins</MenuItem>
                        <MenuItem value={20} sx={{fontWeight:400}}>Children</MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    sx={{
                        display: { xs: classeStatus ? 'block' : 'none', sm: classeStatus ? 'inline-block' : 'none'},
                        marginBottom: { xs: 2},
                        marginRight: { sm: 2 },
                    }}
                    size="small"
                    color={"secondary"}
                >
                    <Select
                        value={classe}
                        onChange={handleClasseChange}
                        displayEmpty
                        sx={{ textAlign:"left", width: {xs: '100%', sm: 200 }, fontWeight:'normal' }}

                    >
                        <MenuItem value="" sx={{fontWeight:400}}><FormattedMessage id={messages.all_classes} /></MenuItem>
                        <MenuItem value={10} sx={{fontWeight:400}}>Accajou</MenuItem>
                        <MenuItem value={20} sx={{fontWeight:400}}>Bambou</MenuItem>
                    </Select>
                </FormControl>

            </Box>
        </div>
    )
}

export default AppointmenToolBar
