import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import _ from "lodash";


export const MENUPLATCANTINE_CONSTANT = {
    convertToFrontEnd: (item:MenuPlatCantine) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(menuPlatCantineDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:MenuPlatCantine) => item,
};

export  const menuPlatCantineDataImpl: MenuPlatCantine = {
    id: 0,
    platCantineId: 0,
    menuCantineId: 0,
    common:  _.cloneDeep(commonDataImp),
}

export const menuPlatCantineSchema = yup.object().shape({
    id: yup.number().nullable(),
    platCantineId: yup.number().nullable(),
    menuCantineId: yup.number().nullable(),
    common:  yup.mixed<Common>(),
});
export type MenuPlatCantine = yup.InferType<typeof menuPlatCantineSchema>;

export const menuDataToDisplaySchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string(),
    starterDish: yup.string(),
    dish: yup.string(),
    dessert: yup.string(),
    photo: yup.string(),
    isMenuOfTheDay: yup.boolean(),
});
export type MenuDataToDisplay = yup.InferType<typeof menuDataToDisplaySchema>;
