import {Rdv, rdvPresetDataImpl} from "../../../constants/type/Rdv";
import _ from "lodash";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import moment from "moment/moment";
import {SlotInfo, Views} from "react-big-calendar";
import {CreneauRdv, CRENEAURDV_CONSTANT, creneauRdvDataImp} from "../../../constants/type/CreneauRdv";
import React from "react";
import {RdvType} from "../../../constants/type/RdvType";
import rdvBusinessLogic from "./RdvBusinessLogic";
import rdvStateUpdate from "./RdvStateUpdate";


const rdvPresetBusinessLogic: any = {
    editePresetDisableChecking: (logicRacine:any, item: Rdv): boolean => {
        if(_.isNull(item.id) || _.isUndefined(item.id)){
            return false ;
        }
        return item.meetingStatus !== RdvEtat.WAIT || moment().isAfter(item.startForm.clone().subtract(item.deadlineUpdate));
    },
    selectSlotPresetProcessing: (logicRacine:any,
                                  slotInfo: SlotInfo,
                                  rdv: Rdv,
                                  minuteStep: number) => {
        let slotMoment= moment(slotInfo.start);
        const slotEnd = moment(slotInfo.end);

        if(rdv.meetingType !== RdvType.PRESET){
            return ;
        }
        if(logicRacine.calendarRef.current?.props.view !== Views.WEEK){
            const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please select week mode in calendar to edite time slots` : `Veuillez sélectionner le mode semaine dans le calendrier afin d'éditer les creneaux horaires`;
            logicRacine.snackbarShowMessage(msg, 'warning', 10000);
            return ;
        }

        if(rdv.meetingStatus !== RdvEtat.WAIT){
            const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `A parent made choice. Les time slots could not be updated` : `Un parent a effectué un choix. Les creneaux ne peuvent être mis à jour`;
            logicRacine.snackbarShowMessage(msg, 'error');
            return ;
        }
        if(moment(slotInfo.start.getTime()).isBetween(rdv.startForm, rdv.endForm) === false
            || moment(slotInfo.end.getTime()).isBetween(rdv.startForm, rdv.endForm) === false){
            return ;
        }
        if(rdvBusinessLogic.isRdvDeadLineReach(logicRacine, rdv)){
            const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `This preset appointment is outdated. You can't add new time slot` : `Ce rendez-vous prédéfini est passé. Vous ne pouvez y ajouter des creneaux horaires`;
            logicRacine.snackbarShowMessage(msg, 'error');
            return ;
        }

        let  creneauRdvEvents = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
        while(slotMoment.isBefore(slotEnd)){
            const startForm   = slotMoment.clone();
            const endForm     = startForm.clone().add(minuteStep, 'minutes');
            creneauRdvEvents = rdvPresetBusinessLogic.updatePresetCreneau(logicRacine, rdv, startForm, endForm, creneauRdvEvents);
            slotMoment = endForm.clone();
        }
        rdvStateUpdate.creneauRdvListInCalendar(logicRacine,creneauRdvEvents);
        return () => {};
    },
    updatePresetCreneau: (logicRacine:any,
                            rdv:Rdv,
                            startForm:moment.Moment,
                            endForm:moment.Moment,
                            creneauRdvEvents: CreneauRdv[]):CreneauRdv[] => {
        const creneauData = CRENEAURDV_CONSTANT.convertToFrontEnd(logicRacine, rdv, _.cloneDeep(creneauRdvDataImp));

        creneauData.startForm       = startForm.clone();
        creneauData.endForm         = endForm.clone();
        creneauData.start           = creneauData.startForm.toDate();
        creneauData.end             = creneauData.endForm.toDate();
        creneauData.dateDebut       = creneauData.startForm.valueOf();
        creneauData.dateFin         = creneauData.endForm.valueOf();

        return rdvPresetBusinessLogic.syncPresetCreneauRdvListInCalendar(logicRacine, creneauData, creneauRdvEvents);
        /* const creneaufind : CreneauRdv | undefined = _.find(creneauRdvListInCalendar, {
             'dateDebut': creneauData.dateDebut,
             'dateFin': creneauData.dateFin
         });
         if(_.isObject(creneaufind)){
             setCreneauRdvListInCalendar((creneauRdvListInCalendar:CreneauRdv[]): CreneauRdv[] => {
                 const events = _.cloneDeep(creneauRdvListInCalendar);
                 _.remove(events, function(n) {
                     return n.dateDebut === creneauData.dateDebut && n.dateFin === creneauData.dateFin;
                 });
                 return events ;
             });
             return  ;
         }
         setCreneauRdvListInCalendar((creneauRdvListInCalendar:CreneauRdv[]): CreneauRdv[] => {
             const events = _.cloneDeep(creneauRdvListInCalendar);
             events.push(creneauData);
             return events ;
         });*/
    },
    syncPresetCreneauRdvListInCalendar:  (logicRacine:any,
                                           creneauData: CreneauRdv | null,
                                          creneauRdvEvents: CreneauRdv[]):CreneauRdv[] => {

        if(_.isNull(creneauData) || _.isUndefined(creneauData)){
            return creneauRdvEvents;
        }

        const indexfind = _.findIndex(logicRacine.creneauRdvListInCalendar, {
            'dateDebut': creneauData.dateDebut,
            'dateFin': creneauData.dateFin,
        });

       if(indexfind >= 0){
           _.remove(creneauRdvEvents, function(n:CreneauRdv) {
               return n.dateDebut === creneauData.dateDebut && n.dateFin === creneauData.dateFin;
           });
           return creneauRdvEvents ;
           /* rdvStateUpdate.creneauRdvListInCalendar(logicRacine, () => {
                let events = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
                _.remove(creneauRdvEvents, function(n:CreneauRdv) {
                    return n.dateDebut === creneauData.dateDebut && n.dateFin === creneauData.dateFin;
                });
                return creneauRdvEvents ;
            });
            return  ;*/
        }
        creneauRdvEvents.push(creneauData);
        return creneauRdvEvents ;
        /*rdvStateUpdate.creneauRdvListInCalendar(logicRacine, () => {
            const events = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
            events.push(creneauData);
            console.log("events >>> ", events);
            return events ;
        });*/
    },
    handleClickAddPreset: (logicRacine:any) => {
        rdvStateUpdate.newPresetRef(logicRacine, true);
        rdvStateUpdate.rdvEdit(logicRacine, rdvPresetDataImpl);
        rdvStateUpdate.openPresetDialogForm(logicRacine, true);
    },
    isRdvPresetCreneauDeletable: (logicRacine:any, rdv:Rdv | null):boolean => {
        if(_.isNull(rdv) || _.isUndefined(rdv) || rdv.meetingType !== RdvType.PRESET){
            return false ;
        }
        return rdv.meetingStatus === RdvEtat.WAIT  ;
    },
    deletePresetCreneauRdvListInCalendarEventCreation: (logicRacine:any, rdv:Rdv | null, eventCreneauClicked: CreneauRdv):boolean => {
        if(_.isNull(rdv) || rdv.meetingType !== RdvType.PRESET){
            return false ;
        }
        if(rdv.meetingStatus !== RdvEtat.WAIT){
            return false ;
        }
        const dateDebut = eventCreneauClicked.dateDebut;
        const dateFin = eventCreneauClicked.dateFin;
        let events: CreneauRdv[] = _.remove(_.cloneDeep(logicRacine.creneauRdvListInCalendar), function (creneau) {
            return creneau.dateDebut !== dateDebut || creneau.dateFin !== dateFin;
        });
        if (events.length !== logicRacine.creneauRdvListInCalendar.length) {
            rdvStateUpdate.creneauRdvListInCalendar(logicRacine, _.cloneDeep(events));
            return true ;
        }
        return false ;
    },
    handleEmployeeCommentChange: (logicRacine:any, val:any) => {
        if(_.isNull(logicRacine.creneauRdvEmployeePreset) || _.isUndefined(logicRacine.creneauRdvEmployeePreset)){
            return ;
        }
        const crenordvemployer          = _.cloneDeep(logicRacine.creneauRdvEmployeePreset);
        crenordvemployer.commentaire    = val;
        rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, crenordvemployer);
    },
    handleEmployeeMeetingStatusChange: (logicRacine:any, event: React.MouseEvent<HTMLElement>, value: any) => {
        if(_.isNull(logicRacine.creneauRdvEmployeePreset) || _.isUndefined(logicRacine.creneauRdvEmployeePreset)){
            return ;
        }
        const crenoemployee = _.cloneDeep(logicRacine.creneauRdvEmployeePreset);
        // @ts-ignore
        crenoemployee.meetingStatus = value;
        rdvStateUpdate.creneauRdvEmployeePreset(logicRacine, crenoemployee);
    }
}

export default rdvPresetBusinessLogic ;