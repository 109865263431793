import {ScolariteSansEcheanceEnfant} from "../../../../constants/type/ScolariteSansEcheanceEnfant";
import {Child} from "../../../../constants/type/Child";
import {GroupEnfant} from "../../../../constants/type/GroupEnfant";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";


const fraisOneShotEnfantStateUpdate:any = {
    dataList: (logic:any, val:ScolariteSansEcheanceEnfant[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:ScolariteSansEcheanceEnfant[]) => logic.setDataListSelected(val),
    scolariteGroupList: (logic:any, val:ScolariteGroup[]) => logic.setScolariteGroupList(val),
    scolariteGroupIdListSel: (logic:any, val:number[]) => logic.setScolariteGroupIdListSel(val),
    idScolariteAnneeSel: (logic:any, val:number[]) => logic.setIdScolariteAnneeSel(val),
    groupEnfantSel: (logic:any, val:GroupEnfant) => logic.setGroupEnfantSel(val),
    childSelected: (logic:any, val:Child) => logic.setChildSelected(val),
    dataSel: (logic:any, val:ScolariteSansEcheanceEnfant) => logic.setDataSel(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openGenerateDialogForm: (logic:any, val:boolean) => logic.setOpenGenerateDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    numeriChange: (logic:any, val:any) => logic.setNumeriChange(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    totalMontant: (logic:any, val:number) => logic.setTotalMontant(val),
    totalMontantEcheancier: (logic:any, val:number) => logic.setTotalMontantEcheancier(val),
    sumEcheancier: (logic:any) =>  {
        const initialValue = 0;
        let sumWithInitial:number = logic.dataList.reduce(
            (accumulator:number, currentValue:ScolariteSansEcheanceEnfant) => accumulator + (currentValue.montant ?? 0),
            initialValue,
        );
        fraisOneShotEnfantStateUpdate.totalMontantEcheancier(logic, sumWithInitial) ;
        fraisOneShotEnfantStateUpdate.tableKeyValue(logic, new Date().getTime()) ;
    },
}

export default fraisOneShotEnfantStateUpdate ;