import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {GenrePlat} from "./GenrePlat";
import _ from "lodash";


export const PLATCANTINE_CONSTANT = {
    convertToFrontEnd: (item:PlatCantine) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(platCantineDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:PlatCantine) => item,
};

export  const platCantineDataImpl: PlatCantine = {
    id: 0,
    typePlat: GenrePlat.PLAT,
    nom: '',
    details: '',
    photo: '',
    common:  _.cloneDeep(commonDataImp),
}

export const PlatCantineSchema = yup.object().shape({
    id: yup.number().nullable(),
    typePlat: yup.mixed<GenrePlat>().oneOf(Object.values(GenrePlat)),
    nom: yup.string().nullable(),
    details: yup.string().nullable(),
    photo: yup.string().nullable(),
    common: yup.mixed<Common>(),
});
export type PlatCantine = yup.InferType<typeof PlatCantineSchema>;