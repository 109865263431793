import {Rdv, rdvNormalDataImpl} from "../../../constants/type/Rdv";
import _ from "lodash";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import moment from "moment/moment";
import rdvStateUpdate from "./RdvStateUpdate";
import {CreneauRdvEmployee} from "../../../constants/type/CreneauRdvEmployees";
import rdvBusinessLogic from "./RdvBusinessLogic";
import {isNumericValue, isPositiveValue} from "../../../helpers/helpers";

const rdvNormalBusinessLogic:any = {
    isFormDisableForCurrentUserChecking: (logicRacine:any, item: Rdv): boolean => {
        // @ts-ignore
        const employeeId = item.creneauRdvs[0]?.creneauRdvEmployees[0]?.employeeId ?? null ;
        return logicRacine.isTeacherRef.current && employeeId === logicRacine.userRef.current.userDetails?.personDetails?.person?.id ;
    },
    editeNormalDisableChecking: (logicRacine:any, item: Rdv): boolean => {
        if(!isPositiveValue(item.id)){
            return false;
        }
        if(item.meetingStatus === RdvEtat.WAIT){
            return false;
        }
        if(moment().isSameOrAfter(item.startForm.clone().subtract(item.deadlineUpdate))){
            return true;
        }

        // @ts-ignore
        const employeeId = item.creneauRdvs[0]?.creneauRdvEmployees[0]?.employeeId  ;
        // @ts-ignore
        const parentId = item.creneauRdvs[0]?.creneauRdvEnfantParents[0]?.parentId  ;

        return isPositiveValue(parentId) && isPositiveValue(employeeId);
    },
    handleClickAddNormalRdv: (logicRacine:any) => {
        rdvStateUpdate.newNormalRef(logicRacine, true);
        rdvStateUpdate.rdvEdit(logicRacine, rdvNormalDataImpl);
        rdvStateUpdate.openNormalDialogForm(logicRacine, true);
    },
    updateEmployeeMeetingStatus: (logicRacine:any, val:any) => {
        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        const crenoemployee = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
        // @ts-ignore
        crenoemployee.meetingStatus = value;
        rdvStateUpdate.creneauRdvEmployeeNorm(logicRacine, crenoemployee);
    },
    handleEmployeeMeetingStatusChange: (logicRacine:any, value: any) => {
        let startForm = logicRacine.creneauRdvEmployeeNorm?.startForm.clone() ;
        let endForm = logicRacine.creneauRdvEmployeeNorm?.endForm.clone() ;
        if(value === RdvEtat.CONFIRM
            && _.isNull(logicRacine.parentNormRef.current) === false && _.isUndefined(logicRacine.parentNormRef.current) === false
            && _.isNull(logicRacine.creneauRdvEnfantParentNormRef.current) === false && _.isUndefined(logicRacine.creneauRdvEnfantParentNormRef.current) === false
            && _.isNull(logicRacine.creneauRdvEmployeeNorm) === false && _.isUndefined(logicRacine.creneauRdvEmployeeNorm) === false){

            startForm = logicRacine.creneauRdvEnfantParentNormRef.current?.startForm.clone();
            endForm = logicRacine.creneauRdvEnfantParentNormRef.current?.endForm.clone();
        }
        // @ts-ignore
        rdvNormalBusinessLogic.updateCreneauEmployeeDate(logicRacine, startForm, endForm, value);
    },
    handleEmployeeCommentChange: (logicRacine:any, value: any) => {
        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        const crenordvemployer: CreneauRdvEmployee = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
        crenordvemployer.commentaire = value;
        rdvStateUpdate.creneauRdvEmployeeNorm(logicRacine, crenordvemployer);
    },
    handleEmployeeEndDateChange: (logicRacine:any, value: moment.Moment) => {
        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        rdvNormalBusinessLogic.updateCreneauEmployeeDate(logicRacine, logicRacine.creneauRdvEmployeeNorm.startForm, moment(value.valueOf()));
    },
    handleEmployeeStartDateChange: (logicRacine:any, value: number) => {
        const newdebutForm = logicRacine.startNormForm.clone().startOf('day').add(value, 'ms');
        const newfinForm = newdebutForm.clone().add(logicRacine.rdvNormalDureeRef.current, 'ms');

        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        rdvNormalBusinessLogic.updateCreneauEmployeeDate(logicRacine, newdebutForm, newfinForm);
    },
    handleCrenoDayChange: (logicRacine:any, value: moment.Moment) => {
        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        const creno = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
        const newday = moment(value.valueOf()).startOf('day');
        const newdebut = newday.clone().add(creno?.startForm.hours(), 'hours').add(creno?.startForm.minutes(), 'minutes');
        const newfin = newday.clone().add(creno?.endForm.hours(), 'hours').add(creno?.endForm.minutes(), 'minutes');

        rdvStateUpdate.crenoDayEmployeeNorm(logicRacine, newday);
        rdvNormalBusinessLogic.updateCreneauEmployeeDate(logicRacine, newdebut, newfin);
    },
    handleDayChange: (logicRacine:any, formRef:any, value: moment.Moment) => {
        const newday = moment(value.valueOf()).startOf('day');
        const newdebut = newday.clone().add(logicRacine.startNormForm.hours(), 'hours').add(logicRacine.startNormForm.minutes(), 'minutes');
        const newfin = newday.clone().add(logicRacine.endNormForm.hours(), 'hours').add(logicRacine.endNormForm.minutes(), 'minutes');

        rdvStateUpdate.dayNormForm(logicRacine, newday);

        rdvStateUpdate.startNormForm(logicRacine, newdebut);
        formRef.current.setValue('startForm', newdebut);

        rdvStateUpdate.endNormForm(logicRacine, newfin);
        formRef.current.setValue('endForm', newfin);
    },
    handleTimeChange: (logicRacine:any, formRef:any, value: number) => {

        const newdebut = logicRacine.startNormForm.clone().startOf('day').add(value, 'ms');
        const newfin = newdebut.clone().add(logicRacine.rdvNormalDureeRef.current, 'ms');

        rdvStateUpdate.startNormForm(logicRacine, newdebut);
        formRef.current.setValue('startForm', newdebut);

        rdvStateUpdate.endNormForm(logicRacine, newfin);
        formRef.current.setValue('endForm', newfin);
    },
    handleStartDateChange: (logicRacine:any, formRef:any, value: moment.Moment) => {
        const newdate = moment(value.valueOf());
        rdvStateUpdate.startNormForm(logicRacine, newdate);
        formRef.current.setValue('startForm', newdate);
    },
    handleEndDateChange: (logicRacine:any, formRef:any, value: moment.Moment) => {
        const newdate = moment(value.valueOf());
        rdvStateUpdate.endNormForm(logicRacine, newdate);
        formRef.current.setValue('endForm', newdate);
    },
    handleClickDeleteRdv: (logicRacine:any) => {
        const rdvsId:Rdv[] = [logicRacine.rdvEdit.id];
        rdvBusinessLogic.deleteRdv(logicRacine, rdvsId).then((response:any) => {
            rdvStateUpdate.openPresetDialogForm(logicRacine, false);
        });
    },
    statusBeforeMeetingViewConfig: (logicRacine:any, item: Rdv) =>  rdvStateUpdate.statusBeforeMeetingViewNormRef(logicRacine,  isPositiveValue(item.id) && moment().isBefore(item.startForm.clone().subtract(item.deadlineUpdate))),
    statusAfterMeetingViewConfig: (logicRacine:any, item: Rdv) =>  rdvStateUpdate.statusAfterMeetingViewNormRef(logicRacine, isNumericValue(item.id) && moment().isAfter(item.endForm)),
    updateCreneauEmployeeDate: (logicRacine:any, startForm:moment.Moment, endForm:moment.Moment, meetingStatus:RdvEtat|null = null) => {

        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) || _.isUndefined(logicRacine.creneauRdvEmployeeNorm)){
            return ;
        }
        const crenoemployee: CreneauRdvEmployee = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
        crenoemployee.startForm = startForm;
        crenoemployee.endForm = endForm;
        let rdvStatus = meetingStatus ?? crenoemployee.meetingStatus ;

        if(_.isObject(logicRacine.creneauRdvEnfantParentNormRef?.current)){
            const parentEmployeeChooseSameDate  = crenoemployee
                    .startForm.isSame(logicRacine.creneauRdvEnfantParentNormRef.current?.startForm) &&
                crenoemployee.endForm.isSame(logicRacine.creneauRdvEnfantParentNormRef.current?.endForm)

            // @ts-ignore
            crenoemployee.meetingStatus = parentEmployeeChooseSameDate ? rdvStatus :  RdvEtat.REPORT ;
        }
        rdvStateUpdate.creneauRdvEmployeeNorm(logicRacine, crenoemployee);
    },



}

export default rdvNormalBusinessLogic ;