import React, {useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../../config-table-jss";
import Box from "@mui/material/Box";
import ScolariteSubMenu from "../ScolariteSubMenu";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Icon, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {useForm} from "react-hook-form";
import SchoolFeesForm from "./SchoolFeesForm";
import _ from "lodash";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});


type SchoolingFeesType = {
    id: number;
    nom: string,
    profile: string,
    amount: number,
    obligatory: boolean,
};

type SchoolingFeesFormValues = {
    id:number,
    nom:string,
    profile:string,
    amount:number,
    obligatory: boolean,
}

const data:SchoolingFeesType[] = [
    {
        id:1,
        nom: 'Inscription',
        profile: 'Tous les profils',
        amount: 4500000,
        obligatory: true,
    }
];

function SchoolFees(props:any) {
    const { theme, locale, } = props;
    const activeLanguage = locale.split('-');
    const classes = {...useStyles(), ...useStyles2()};
    const [schoolingFeesData, setSchoolingFeesData] = useState(data);
    const [stateSchoolingFees, setStateSchoolingFees] = useState(true);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.schooling_rappel_payment} />,
        <FormattedMessage id={messages.schooling_profile} />,
        <FormattedMessage id={messages.schooling_amount} />,
        <FormattedMessage id={messages.schooling_fees_obligatory} />
    ];

    const columns = useMemo<MRT_ColumnDef<SchoolingFeesType>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: columnsHeaders[0],
                size:200
            },
            {
                accessorKey: 'profile',
                header: columnsHeaders[1],
            },
            {
                accessorKey: 'amount',
                header: columnsHeaders[2],
            },
            {
                accessorKey: 'obligatory',
                header: columnsHeaders[3],
                columnDefType: 'display',
                Cell: ({ row }) => {
                    const { _valuesCache } = row;
                    return (
                        <Button
                            color={"secondary"}
                            sx={{color:"inherit", fontWeight:400, textTransform:"capitalize"}}>
                            { _valuesCache.obligatory ? 'Oui' : 'Non' }
                        </Button>
                        /*<Switch color={"secondary"} disabled defaultChecked />*/
                    )
                },
                //columnDefType
                //renderColumnActionsMenuItems:()
            },

        ],
        [],
    );

    const form = useForm<SchoolingFeesFormValues>({
        defaultValues: {
            id: 0,
            nom: '',
            profile: '',
            amount:0,
            obligatory: true
        }
    });
    const { control, register, handleSubmit, formState, reset, setValue } = form;
    const { errors } = formState;

    const addSchoolingFees = () => {
        setStateSchoolingFees(true);

        handleClickOpen();
    }

    const editSchoolingFees = (row:any) => {
        setStateSchoolingFees(false);

        handleClickOpen();
    }

    const onSubmit = () => {

    }

    const onSubmitEditForm = () => {

    }

    return (
        <div className={classes.root}>
            <Box className={classes.buttonContainer}>
                <ScolariteSubMenu classes={classes} selected="schoolfees" />
            </Box>

            <div className={classes.tableContainer}>
                <MaterialReactTable
                    columns={columns}
                    data={schoolingFeesData}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact'
                    }}
                    localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing
                    // editDisplayMode="row"
                    //onEditingRowSave={handleSaveRow}
                    enableRowSelection
                    positionActionsColumn="last"
                    key={new Date().getTime()}
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={classes.topToolbarActionsButton}
                            sx={{ display: 'flex',  flexWrap: 'wrap' }}
                        >
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="secondary"
                                    sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                    onClick={() => addSchoolingFees()}
                            >
                                <Icon sx={{marginRight:1}}>add</Icon>
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            >
                                <Icon sx={{marginRight:1}}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>

                            {/*<IconButton title={export_xls}>
                                <img width="24" height="24" src={IMGS.excelIcon} alt="Excel icon" />
                            </IconButton>

                            <IconButton title={export_pdf}>
                                <img width="24" height="24" src={IMGS.pdfIcon} alt="PDF icon" />
                            </IconButton>*/}
                        </Box>
                    )}

                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} />}>
                                <IconButton
                                    onClick={() => editSchoolingFees(row)}
                                    sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />

                <SchoolFeesForm
                    theme={theme}
                    title={stateSchoolingFees ? <FormattedMessage id={messages.dialog_title_additional_fees} /> : <FormattedMessage id={messages.dialog_edit_title_additional_fees} /> }
                    open={open}
                    setOpen={setOpen}
                    classes={classes}
                    register={register}
                    onSubmit={stateSchoolingFees ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                    //profile={profile}
                    //handleProfileChange={handleProfileChange}
                />

            </div>

        </div>
    );
}

export default SchoolFees;
