import React from 'react';
import {CircularProgress} from "@mui/material";

function Loading(props:any) {
    const { status } = props;
    if(status){
        return (
            <div style={{width:'100%', textAlign:'center', padding:20}}>
                <CircularProgress />
            </div>
        );
    }
    else {
        return <div> </div>
    }

}

export default Loading;