export default [
    {
        key: 'DESSERT',
        name: 'Dessert',
    },
    {
        key: 'ENTREE',
        name: 'Entrée',
    },
    {
        key: 'PLAT',
        name: 'Plat',
    },
]
