import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {Box, Grid, TextField} from "@mui/material";
import {IMGS} from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import styles from "../config/config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import classNames from "classnames";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers";
import {renderTimeViewClock} from '@mui/x-date-pickers/timeViewRenderers';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {canShowButton} from "../../helpers/helpers";
import moment from "moment";
import {STATUS} from "../../constants/schemaBD";
import InputField from "../../components/ui/form/InputField";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        container: {
            fontSize: '14px',
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontSize: 18,
            fontWeight:'400 !important',
            padding:0,
            margin: 0,
            paddingBottom:10,
            borderBottom: "1px solid #ccc",
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {},
        textFieldWith: {
            width: '100%'
        }
    }
});

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function EditMiniClubForm(props:any) {
    const {
        activeLang,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        handleStartDateChange,
        handleEndDateChange,
        classroomData,
        classroomDataValues,
        handleClassroomChange,
        data,
        fileDataURL,
        handleFileChange,
        register,
        setValue,
        onSubmit,
        userRef,
    } = props;
    const classes = {...useStyles(), ...useStyles2()};

    useEffect(() => {
        setValue('title', data.title);
        setValue('prix', data.prix);
        setValue('placeLimit', data.originalObject.placeLimit);
        setValue('details', data.details);

        const startTime = moment(data.dateDebut);
        setStartDate(moment(startTime, activeLang === "en" ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]));

        const endTime = moment(data.dateFin);
        setEndDate(moment(endTime, activeLang === "en" ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]));

    }, []);

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>
                <FormattedMessage id={messages.edit_mini_club_list} />
            </h2>
            <div style={{marginTop: 40}}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <div className={classes.dialogAvatar}>
                            {/*<Avatar src={''} sx={{width:100, height:100}} />*/}
                            <img
                                src={fileDataURL ? fileDataURL : IMGS.photoMenu}
                                //src={IMGS.photoMenu}
                                style={{width:100, height:90}}
                                alt={'mini-club-illustration'}
                            />
                            <div className={classes.iconButtonLabel}>
                                <label htmlFor="file-upload">
                                    <div className={classes.iconButton} style={{textAlign:"center"}}>
                                        <EditIcon />
                                    </div>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        hidden={true}
                                        onChange={(a:any) => handleFileChange(a)}
                                    />
                                </label>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <TextField
                                label={<FormattedMessage id={messages.dialog_tab_hd_club_title}/>}
                                size="small"
                                color={"secondary"}
                                className={classes.textFieldWith}
                                {...register("title", {
                                    required: "Title is required"
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel color={"secondary"}>{<FormattedMessage id={messages.dialog_input_classroom} />}</InputLabel>
                            <Select
                                multiple
                                value={classroomDataValues}
                                onChange={handleClassroomChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.dialog_input_classroom} />} color={"secondary"} />}
                                renderValue={(selected:any) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value:any) => (
                                            <Chip sx={{fontWeight:'normal', fontSize:14}} key={value.id} label={`${value.nom}`} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                sx={{ fontWeight:400, p:0 }}
                                color={"secondary"}
                                //{...register("classroom")}
                            >
                                {classroomData.map((data:any) => {
                                    return (
                                        <MenuItem
                                            key={data.id}
                                            value={data}
                                            sx={{ fontWeight:400,}}
                                        >
                                            {data.nom}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classes.inputField}>
                            <TextField
                                label={<FormattedMessage id={messages.dialog_tab_hd_club_price}/>}
                                size="small"
                                color={"secondary"}
                                type={"number"}
                                className={classes.textFieldWith}
                                {...register("prix", {
                                    required: "Price is required"
                                })}
                            />
                        </div>
                    </Grid>


                    <Grid item md={8} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                                <DateTimePicker
                                    label={<FormattedMessage id={messages.start_day} />}
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    className={classes.textFieldWith}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classNames(classes.inputField)}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                                <DateTimePicker
                                    label={<FormattedMessage id={messages.end_day} />}
                                    value={endDate}
                                    minDate={startDate}
                                    onChange={handleEndDateChange}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    className={classes.textFieldWith}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classes.inputField}>
                            <TextField
                                label={<FormattedMessage id={messages.dialog_modal_club_limit}/>}
                                size="small"
                                color={"secondary"}
                                type={"number"}
                                className={classes.textFieldWith}
                                {...register("placeLimit", {
                                    required: "Limit is required"
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <div className={classes.inputField}>
                            <InputField
                                label={<FormattedMessage id={messages.dialog_tab_hd_club_description}/>}
                                size="small"
                                color={"secondary"}
                                type={"number"}
                                InputProps={{
                                    rows: 3,
                                    multiline: true,
                                    inputComponent: 'textarea',
                                }}
                                inputProps={{
                                    ...register("details")
                                }}
                                className={classes.textFieldWith}
                            />
                        </div>
                    </Grid>

                    {
                        canShowButton(userRef.current, 'miniclubs', [STATUS.ADD, STATUS.UP]) &&
                        <Grid item md={8} xs={12} sx={{marginTop: 3}}>
                            <div className={classes.inputField} style={{textAlign: 'right'}}>
                                <Button variant="contained" size={"large"} style={{textTransform: "none"}}
                                        onClick={onSubmit}>
                                    <FormattedMessage id={messages.save_edit_mini_club_list}/>
                                </Button>
                            </div>
                        </Grid>
                    }

                </Grid>
            </div>

        </div>
    )
}

export default EditMiniClubForm;