import {Child} from "../../../../constants/type/Child";
import {EcheancierGroupingDetails} from "../../../../constants/type/EcheancierGroupingDetails";
import {EcheancierGrouping} from "../../../../constants/type/EcheancierGrouping";


const scolariteEcheancierStateUpdate:any = {
    dataList: (logic:any, val:EcheancierGrouping[]) => logic.setDataList(val),
    dataListSelected: (logic:any, val:EcheancierGrouping[]) => logic.setDataListSelected(val),
    datasDeleteSelected: (logic:any, val:EcheancierGroupingDetails[]) => logic.setDatasDeleteSelected(val),
    scolaEcheancierDetails: (logic:any, val:EcheancierGroupingDetails[]) => logic.setScolaEcheancierDetails(val),
    childSelected: (logic:any, val:Child) => logic.setChildSelected(val),
    dataSel: (logic:any, val:EcheancierGrouping) => logic.setDataSel(val),
    dataDetailsSel: (logic:any, val:EcheancierGroupingDetails) => logic.setDataDetailsSel(val),
    disabledEcheancierdetailsEdition: (logic:any, val:boolean) => logic.setDisabledEcheancierdetailsEdition(val),
    openEditDialogForm: (logic:any, val:boolean) => logic.setOpenEditDialogForm(val),
    openEditDetailsDialogForm: (logic:any, val:boolean) => logic.setOpenEditDetailsDialogForm(val),
    openDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenDeleteDialogForm(val),
    processingStateNormBtn: (logic:any, val:boolean) => logic.setProcessingStateNormBtn(val),
    tableDataLoading: (logic:any, val:boolean) => logic.seTableDataLoading(val),
    showDetailsTableContent: (logic:any, val:boolean) => logic.setShowDetailsTableContent(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),
    tableDetailsKeyValue: (logic:any, val:any) => logic.setTableDetailsKeyValue(val),
    totalMontant: (logic:any, val:number) => logic.setTotalMontant(val),
    sumMontant: (logic:any) =>  {
        const initialValue = 0;
        let sumWithInitial:number = logic.scolaEcheancierDetails.reduce(
            (accumulator:number, currentValue:EcheancierGroupingDetails) => accumulator + currentValue.montant,
            initialValue,
        );
        scolariteEcheancierStateUpdate.totalMontant(logic, sumWithInitial) ;
    },

}

export default scolariteEcheancierStateUpdate ;