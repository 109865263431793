import {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";

import _ from "lodash";
import {ScolariteType, scolariteTypeDataImpl} from "../../../../constants/type/ScolariteType";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useScolariteTypeVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'ScolariteType', updateId:1}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<ScolariteType>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const [dataList, setDataList] = useState<ScolariteType[]>([]);
    const [dataListSelected, setDataListSelected] = useState<ScolariteType[]>([]);
    const [dataSel, setDataSel] = useState<ScolariteType>(_.cloneDeep(scolariteTypeDataImpl));
    const [loading, setLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,
        tableKeyValue,setTableKeyValue,loading,setLoading,
        tableConfigRef,
        ...logicMRT
    }
}
export default useScolariteTypeVariables;