import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";

import {Box, Checkbox, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {GroupEnfantContext} from "./GroupEnfantPage";
import groupEnfantStateUpdate from "./businesslogic/GroupEnfantStateUpdate";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import {
    GroupEnfantProfils,
    GROUPENFANTPROFILS_CONSTANT,
    groupEnfantProfilsDataImpl
} from "../../../constants/type/GroupEnfantProfils";
import {isPositiveValue} from "../../../helpers/helpers";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const GroupEnfantEnfantEditForm = (props:any) => {
    const logic: any = useContext(GroupEnfantContext);
    const styleClasses = logic.styleClasses ;
    const [checked, setChecked] = useState<number[]>([]);


    const updateProfilsChecked = (ids : number[]) => {
        setChecked(ids);
    }
    const handleOnProfilChange = (event: any) => {
        const ids : number[]= [] ;
        event.target.value.forEach((id:string) => {
            ids.push(parseInt(id));
        });
        updateProfilsChecked(ids);
    };
    const onSubmit = async () => {
        groupEnfantStateUpdate.processingStateNormBtn(logic, true);
        const groupEnfantsProfils : GroupEnfantProfils[] = _.map(checked, profilsId => {
            const groupEnfantProfil = _.cloneDeep(groupEnfantProfilsDataImpl);
            groupEnfantProfil.profilsId = profilsId ;
            return GROUPENFANTPROFILS_CONSTANT.convertToBackEnd(logic, groupEnfantProfil)  ;
        });
        const data = _.cloneDeep(logic.dataSel);
        data.groupEnfantProfils = groupEnfantsProfils ;
        let dataList = _.cloneDeep(logic.dataList);
        const index = _.findIndex(dataList, {id:data.id});

        if(index >= 0){
            dataList[index] = data ;
            groupEnfantStateUpdate.dataList(logic, dataList);
            groupEnfantStateUpdate.dataSel(logic, data);
            groupEnfantStateUpdate.openEditDialogForm(logic, false);
        }

        groupEnfantStateUpdate.processingStateNormBtn(logic, false);
    };

   useEffect(() => {
        if(logic.openEditDialogForm === false){
            setChecked([]);
            return ;
        }
       const ids : number[]= [] ;
        for(const item of logic.dataSel?.groupEnfantProfils){
            if(isPositiveValue(item.profilsId)){
                ids.push(item.profilsId);
            }
        }
       updateProfilsChecked(ids);
        return () => { };
    }, [logic.openEditDialogForm]);


    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={`${logic.dataSel.childForm.childnomForm}`}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel color={"secondary"}>{<FormattedMessage id={messages.schooling_profile} />}</InputLabel>
                            <Select
                                labelId="demo-multiple-2-label"
                                id="demo-multiple-2"
                                multiple
                                size="small"
                                sx={{width: {xs: '100% !important'},}}
                                color={"secondary"}
                                value={checked}
                                onChange={handleOnProfilChange}
                                input={<OutlinedInput label={<FormattedMessage id={messages.schooling_profile}/>}/>}
                                renderValue={(selected: any) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value: any) => (
                                            <Chip key={value}
                                                  label={_.find(logic.profilsRef.current, {id:value})?.nom ?? ``}/>
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                {logic.profilsRef.current?.map((opt: any) => (
                                    <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                        <Checkbox checked={_.includes(checked, opt.id)}/>
                                        <ListItemText primary={opt.nom}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>
        </DialogFormKiller>
    );
}

export default GroupEnfantEnfantEditForm ;