import * as yup from "yup";
import {Common, commonDataImp} from "./Common";
import moment from "moment";
import {Jour} from "./Jour";
import {dataMenuCantineDataImpl, MenuCantineData, MENUCANTINEDATA_CONSTANT} from "./MenuCantineData";
import {dataSemaineImpl, SemaineData, SEMAINEDATA_CONSTANT} from "./SemaineData";
import _ from "lodash";


export const MENUJOURDATA_CONSTANT = {
    convertToFrontEnd: (item:MenuJourData|null|undefined) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(dataMenuJourDataImpl);
        item = {...itemInit, ...item} ;

        if(_.isNull(item) || _.isUndefined(item)){
            return _.cloneDeep(dataMenuCantineDataImpl) ;
        }
        item.theDateForm    = moment(item.theDate);
        // @ts-ignore
        item.semaineData    = SEMAINEDATA_CONSTANT.convertToFrontEnd(item.semaineData);
        // @ts-ignore
        item.menuCantineDatas   = _.map(item.menuCantineDatas,  MENUCANTINEDATA_CONSTANT.convertToFrontEnd);

        return item;
    },
    convertToBackEnd: (item:MenuJourData) => item,
};

export  const dataMenuJourDataImpl: MenuJourData = {
    id: null,
    theDate: 0,
    semaineData:dataSemaineImpl,
    nom: '',
    couleur: '',
    jour: Jour.LUNDI,
    menuCantineDatas: [],
    common:  _.cloneDeep(commonDataImp),
    theDateForm: moment(),
}

// @ts-ignore
export const menuJourDataSchema = yup.object().shape({
    id: yup.number().nullable(),
    theDate: yup.number(),
    semaineData:  yup.mixed<SemaineData>().nonNullable(),
    nom: yup.string(),
    couleur: yup.string(),
    jour: yup.mixed<Jour>().oneOf(Object.values(Jour)),
    menuCantineDatas: yup.array().of(yup.mixed<MenuCantineData>()).nonNullable(),
    common:  yup.mixed<Common>(),
    theDateForm:  yup.mixed<moment.Moment>().default(moment()),
});
export type MenuJourData = yup.InferType<typeof menuJourDataSchema>;