import React, {useEffect, useMemo, useRef} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {Button} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import scolariteStyles from "../jss/scolarite-jss";
import useSecuriteParentVariables from "./businesslogic/SecuriteParentVariables";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {Parent} from "../../../constants/type/Parent";
import securiteParentBusinessLogic from "./businesslogic/SecuriteParentBusinessLogic";
import DialogCompteParentEditForm from "./DialogCompteParentEditForm";
import _ from "lodash";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {Employee} from "../../../constants/type/Employee";
import {AccountEtat} from "../../../constants/type/AccountEtat";
import securiteEmployeeStateUpdate from "../employee/businesslogic/SecuriteEmployeeStateUpdate";
import Tooltip from '@mui/material/Tooltip';
import {MyRenderTopToolbarActions} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {columnsPersonPlugin} from "../../../constants/type/Person";
import {MyBadge} from "../../../components/ui/badge/MyBadge";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
/*const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};*/

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();

    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useSecuriteParentVariables({styleClasses, ...props})
    }
};

export const SecuriteParentContext = React.createContext({});

function SecuriteParent(props:any) {
    const {theme, locale, snackbarShowMessage} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const searchPlaceholderRef =  useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');


    const handleAction = (rows:any, messageType:string) => {
        const dataSelected: any = [];
        let countStatus: number = 0;
        rows.map((row: any) => {
            const data:Employee = logic.dataList[row.index];
            dataSelected.push(data);
            if('activer_compte_question' === messageType){
                if(data.userAccountEtat === AccountEtat.SUSPENDED){
                    ++countStatus ;
                }
            }
        });
        if('activer_compte_question' === messageType){
            if(countStatus !== dataSelected.length){
                snackbarShowMessage(<FormattedMessage id={messages.sb_selection_only_suspended_account} />, 'warning', 10000);
                return ;
            }
        }
        if(_.isEmpty(dataSelected)){
            snackbarShowMessage(<FormattedMessage id={messages.sb_selection_empty} />, 'warning');
            return ;
        }
        securiteEmployeeStateUpdate.confirmMessage(logic, messageType);
        securiteEmployeeStateUpdate.dataListSelected(logic, dataSelected);
        securiteEmployeeStateUpdate.openConfirmDialogForm(logic, true);
    }


    const toolBarButtons = (table:any) => (
        <>
            <Tooltip title={<FormattedMessage id={messages.tooltip_generate_account} />}>
                <Button variant={"contained"}
                        component="label"
                        size="small"
                        color="primary"
                        startIcon={<AddReactionIcon />}
                        sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'generer_compte_question')}
                >
                    <FormattedMessage id={messages.generer_compte} />
                </Button>
            </Tooltip>

            <Tooltip title={<FormattedMessage id={messages.tooltip_activate_account} />}>
                <Button variant={"contained"}
                        component="label"
                        size="small"
                        color="success"
                        startIcon={<FaceRetouchingNaturalIcon />}
                        sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                        disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'activer_compte_question')}
                >
                    <FormattedMessage id={messages.activer_compte} />
                </Button>
            </Tooltip>


            <Tooltip title={<FormattedMessage id={messages.tooltip_suspended_account} />}>
                <Button variant={"contained"}
                        component="label"
                        size="small"
                        color="secondary"
                        startIcon={<FaceRetouchingOffIcon />}
                        sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                        disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'suspendre_compte_question')}
                >
                    <FormattedMessage id={messages.fermer_compte} />
                </Button>
            </Tooltip>


            <Button variant={"contained"}
                    component="label"
                    size="small"
                    color="error"
                    startIcon={<SentimentVeryDissatisfiedIcon />}
                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                    onClick={() => handleAction(table.getSelectedRowModel().rows, 'supprimer_compte_question')}
            >
                <FormattedMessage id={messages.supprimer_compte} />
            </Button>
        </>
    );


    const columns = useMemo<MRT_ColumnDef<Parent>[]>(
        () => [
            // @ts-ignore
            ...columnsPersonPlugin({intl:intl}),
            {
                accessorKey: 'userAccountEtat',
                filterVariant: 'multi-select',
                header: intl.formatMessage({id: messages.table_h_account_status}),
                size: 100,
                // @ts-ignore
                muiTableBodyCellProps: {
                    align: 'center', //change head cell props
                },
                Cell: ({ cell }) => {
                    const data = cell.row.original;


                    let value : any = <></>;
                    if(data.userAccountEtat === AccountEtat.OK){
                        value =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_ok} />} color="success" /> ;
                    }
                    else if(data.userAccountEtat === AccountEtat.LOCKED){
                        value =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_locked} />} color="secondary" /> ;
                    }
                    else if(data.userAccountEtat === AccountEtat.SUSPENDED){
                        value =  <MyBadge badgeContent={<FormattedMessage id={messages.accountetat_suspended} />} color="error" /> ;
                    }
                    return value ;
                },
            },
        ],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        enableEditing: false,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                otherComponents={toolBarButtons(table)}
                showAddButton={false}
                showDeleteButton={false}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
            />
        )
    });


    useEffect(() => {
        moment.locale(logic.activeLang);
        securiteParentBusinessLogic.initPostConstruct(logic);
       /* tableConfig.setColumnVisibility(
            isPositiveValue(logic.updateId) && logic.updateId === 1725856315000 ?
                logic.etat.columnVisibility :
                personCustomOptions.columnVisibility
        );*/

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);


    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="parent"/>
            </Box>

            <div className={styleClasses.tableContainer}>
                <MaterialReactTable
                    table={logic.tableConfigRef.current}
                    key={logic.tableKeyValue}
                />
                {/*<MaterialReactTable
                    columns={columns}
                    data={logic.dataList}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    state={{
                        density:'compact',
                        isLoading: !logic.showTableContent,
                    }}
                    localization={logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    key={logic.tableKeyValue}
                    // editDisplayMode="row"
                    //onEditingRowSave={handleSaveRow}
                    enableRowSelection
                    positionActionsColumn="last"
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={styleClasses.topToolbarActionsButton}
                            sx={{ display: 'flex',  flexWrap: 'wrap' }}
                        >
                            <Tooltip title={<FormattedMessage id={messages.tooltip_generate_account} />}>
                                <Button variant={"contained"}
                                        component="label"
                                        size="small"
                                        color="primary"
                                        startIcon={<AddReactionIcon />}
                                        sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'generer_compte_question')}
                                >
                                    <FormattedMessage id={messages.generer_compte} />
                                </Button>
                            </Tooltip>

                            <Tooltip title={<FormattedMessage id={messages.tooltip_activate_account} />}>
                                <Button variant={"contained"}
                                        component="label"
                                        size="small"
                                        color="success"
                                        startIcon={<FaceRetouchingNaturalIcon />}
                                        sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                        disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'activer_compte_question')}
                                >
                                    <FormattedMessage id={messages.activer_compte} />
                                </Button>
                            </Tooltip>


                            <Tooltip title={<FormattedMessage id={messages.tooltip_suspended_account} />}>
                                <Button variant={"contained"}
                                        component="label"
                                        size="small"
                                        color="secondary"
                                        startIcon={<FaceRetouchingOffIcon />}
                                        sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                        disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                        onClick={() => handleAction(table.getSelectedRowModel().rows, 'suspendre_compte_question')}
                                >
                                    <FormattedMessage id={messages.fermer_compte} />
                                </Button>
                            </Tooltip>


                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    startIcon={<SentimentVeryDissatisfiedIcon />}
                                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                    onClick={() => handleAction(table.getSelectedRowModel().rows, 'supprimer_compte_question')}
                            >
                                <FormattedMessage id={messages.supprimer_compte} />
                            </Button>
                        </Box>
                    )}
                />*/}
            </div>

            <SecuriteParentContext.Provider value={logic}>
                <DialogCompteParentEditForm />
            </SecuriteParentContext.Provider>
        </div>
    );
}

export default withSnackbar(SecuriteParent);
