import React from 'react';
import DialogForm from "../../../../components/ui/form/DialogForm";
import {MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages/general/messages";
import classNames from "classnames";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";

function ScolaritePaymentPeriodForm(props:any) {
    const {
        theme,
        title,
        open,
        activeLanguage,
        setOpen,
        classes,
        onSubmit,
        register,
        handleSchoolingChange,
        schooling,
        periodDate,
        handlePeriodDateChange
    } = props;

    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    select
                    label={<FormattedMessage id={messages.schooling} />}
                    value={schooling || ''}
                    sx={{ width: { xs: '100% !important'}}}
                    color={"secondary"}
                    onChange={(a:any) => handleSchoolingChange(a)}
                    inputProps={{
                        ...register('schooling', {
                            required: 'Schooling is required',
                        })
                    }}
                >
                    <MenuItem value="0" sx={{fontWeight:400}}>
                        Scolarité
                    </MenuItem>
                    {/*{USER_ROLE.map((option:any, index) => (
                        <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                            {option.name}
                        </MenuItem>
                    ))}*/}
                </TextField>
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.schooling_rappel_payment} />}
                    size="small"
                    color={"secondary"}
                    {...register("nom", {
                        required: "Title name is required"
                    })}
                />
            </div>


            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <TextField
                    label={<FormattedMessage id={messages.schooling_amount} />}
                    type={"number"}
                    size="small"
                    color={"secondary"}
                    {...register("montant", {
                        required: "Schooling amount is required"
                    })}
                />
            </div>

            <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                    <DatePicker
                        label={<FormattedMessage id={messages.start_day} />}
                        value={periodDate}
                        onChange={handlePeriodDateChange}
                        slotProps={{ textField: { color: 'secondary' } }}
                    />

                </LocalizationProvider>
            </div>

        </DialogForm>
    );
}

export default ScolaritePaymentPeriodForm;
