import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {Parent, PARENT_CONSTANT, parentDataImpl} from "./Parent";
import _ from "lodash";

export const ENFANTPARENT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:EnfantParent) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(enfantParentDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:EnfantParent) => item,
};

export  const enfantParentDataImpl: EnfantParent = {
    id: null,
    enfantId: 0,
    parent: _.cloneDeep(parentDataImpl),
    siblingUuid: 0,
    common: _.cloneDeep(commonDataImp),
}

export const enfantParentSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number().nullable(),
    parent: yup.mixed<Parent>().default(_.cloneDeep(parentDataImpl)),
    siblingUuid: yup.number().nullable(),
    common: yup.mixed<Common>().default(_.cloneDeep(commonDataImp))
});

export type EnfantParent = yup.InferType<typeof enfantParentSchema>;
