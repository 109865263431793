import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config-table-jss";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {useForm} from "react-hook-form";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {request} from "../../../helpers/axios_helpers";
import {CONSTANT, IMAGE_RIGHTS_EN, IMAGE_RIGHTS_FR,} from "../../../constants";
import ImageRightsForm from "./ImageRightsForm";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../helpers/errorManager";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type ImageRightsType = {
    id: number;
    name: string;
};

type ImageRightsFormValues = {
    id: number;
    name: string,
}

function ImageRights(props:any) {
    const { locale, theme, snackbarShowMessage } = props;
    const activeLanguage = locale.split('-');
    const classes = {...useStyles(), ...useStyles2()};
    const [imageRightsData, setImageRightsData] = useState<ImageRightsType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateImageRights, setStateImageRights] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.image_rights} />,
    ];
    const columns = useMemo<MRT_ColumnDef<ImageRightsType>[]>(
        () => [
            {
                accessorKey: 'name',
                header: columnsHeaders[0],
                size: 800,
            },
        ],
        [],
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const form = useForm<ImageRightsFormValues>({
        defaultValues: {
            id: 0,
            name: '',
        }
    });
    const {register, handleSubmit, reset, setValue} = form;
    //const {errors} = formState;

    const addImageRights = () => {
        setStateImageRights(true);
        setValue('name', '');
        handleClickOpen();
    }

    const editImageRights = (row:any) => {
        setStateImageRights(false);
        const dataImageRights: any = imageRightsData[row.index];
        setValue('id', dataImageRights.id);
        setValue('name', dataImageRights.name);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let dataImageRights:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateImageRights) {
                dataImageRights.id = response.data.id;
                dataImageRights.name = data.name;
                dataImageRights.couleur = response.data.couleur;
                dataImageRights.common = response.data.common;
                setImageRightsData([dataImageRights, ...imageRightsData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                imageRightsData.find((imageRights:any, index:any) => {
                    if(imageRights.id === data.id) {
                        imageRightsData[index].name = data.name;
                        setImageRightsData([...imageRightsData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: ImageRightsFormValues) => {
        const dataToSend = {
            nom: data.name,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/imageright", dataToSend);
    }

    const onSubmitEditForm = (data: ImageRightsFormValues) => {
        const imageRightsFind: any = imageRightsData.find(imageRights => imageRights.id === data.id);
        const dataToSend = {
            ...imageRightsFind,
            id: data.id,
            nom: data.name,
            //couleur: imageRightsFind.couleur,
            //common: imageRightsFind.common
        }
        sendData(data, 'PUT', `/corebase/imageright/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataFileSelectedId:any = [];
        rows.map((row:any) => {
            const data = imageRightsData[row.index];
            dataSelected.push(data);
            dataFileSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/imageright/bouquet', dataFileSelectedId).then((response) => {
            const fileList = imageRightsData.filter((imageRights) => {
                if(!dataSelected.includes(imageRights)){
                    return imageRights;
                }
            });
            setImageRightsData([...fileList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    useEffect(() => {
        setTableDataLoading(true);
        request("GET", '/corebase/imageright', {}).then((response) => {
            const { _embedded } = response.data;
            let fileListRequest:any =  _embedded !== undefined ? _embedded.droitImageDTOModelList : [];
            fileListRequest = fileListRequest.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });

            let dataList = fileListRequest.map((imageRights:any) => {
                const imageList = activeLanguage[0] === 'en' ? IMAGE_RIGHTS_EN :IMAGE_RIGHTS_FR;
                const imaageFind:any = imageList.find((image:any) => image.id === imageRights.id);

                let imageRightData:any = {};
                imageRightData.id = imageRights.id;
                imageRightData.name = imaageFind.value; //imageRights.nom;
                imageRightData = {...imageRights, ...imageRightData, ...{ couleur: imageRights.couleur, common: imageRights.common }}
                return imageRightData;
            });

            dataList = dataList.sort(function (a:any, b:any) {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });

            setImageRightsData([...dataList]);
            setTableDataLoading(false);

        }).catch((error) => {
            // Error message
            setTableDataLoading(false);
            console.log(error);
        });

    }, [activeLanguage[0]]);

    return (
        <div className={classes.root}>
            <MaterialReactTable
                columns={columns}
                data={imageRightsData}
                enableRowSelection={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact',
                    isLoading: tableDataLoading,
                }}
                localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing={false}
                positionActionsColumn="last"
                key={tableKeyValue}
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }: {table:any}) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            //onClick={() => addImageRights()}
                            disabled={true}
                        >
                            <FormattedMessage id={messages.add_action} />
                        </Button>

                        <Button
                            //only export selected rows
                            color="error"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            //disabled={_.isEmpty(table.getSelectedRowModel().rows) && !table.getIsAllRowsSelected()}
                            disabled={true}
                            //onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <FormattedMessage id={messages.delete_action} />
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                //onClick={() => editImageRights(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />

            <ImageRightsForm
                title={ stateImageRights ? <FormattedMessage id={messages.dialog_title_image_right} /> : <FormattedMessage id={messages.dialog_edit_title_image_right} /> }
                open={open}
                setOpen={setOpen}
                theme={theme}
                classes={classes}
                register={register}
                onSubmit={stateImageRights ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />
        </div>
    );
}

export default withSnackbar(ImageRights);
