import {LectureRdv, Rdv, RdvFilter} from "../../../constants/type/Rdv";
import {CreneauRdv} from "../../../constants/type/CreneauRdv";
import _ from "lodash";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import moment from "moment";
import {CreneauRdvEmployee} from "../../../constants/type/CreneauRdvEmployees";
import {CreneauRdvEnfantParent} from "../../../constants/type/CreneauRdvEnfantParent";
import {Child} from "../../../constants/type/Child";
import {Employee} from "../../../constants/type/Employee";
import {Parent} from "../../../constants/type/Parent";
import {ReportInfo} from "./PopperBusinessLogic";

const rdvStateUpdate: any = {

    openRdvList:(logicRacine:any, val:boolean) => logicRacine.setOpenRdvList(!logicRacine.openRdvList),
    filterRdv: (logicRacine:any, val:RdvFilter) => logicRacine.setFilterRdv(val),
    savingStateBtn: (logicRacine:any, val:boolean) => logicRacine.setSavingStateBtn(val),
    rdvToolbarSelect: (logicRacine:any, rdv:Rdv) => logicRacine.setRdvToolbarSelect(_.cloneDeep(rdv)),
    rdvEdit: (logicRacine:any, rdv:Rdv) => logicRacine.setRdvEdit(_.cloneDeep(rdv)),
    rdvList: (logicRacine:any, rdvs:Rdv[]) => logicRacine.setRdvList(_.cloneDeep(rdvs)),
    creneauRdvListInCalendar: (logicRacine:any, events: any) => logicRacine.setCreneauRdvListInCalendar(events) ,
    rdvStoreRef: (logicRacine:any, rdvs: Rdv[]) => logicRacine.rdvStoreRef.current   = _.cloneDeep(rdvs),
    teachersListRef: (logicRacine:any, val: Employee[]) => logicRacine.teachersListRef.current   = val,
    teacherRef: (logicRacine:any, val: Employee | null | undefined) => logicRacine.teacherRef.current   = val,
    isTeacherRef: (logicRacine:any, val: boolean) => logicRacine.isTeacherRef.current   = val,
    classeIdRef: (logicRacine:any, val: number) => logicRacine.classeIdRef.current   = val,
    newNormalRef: (logicRacine:any, val: boolean) => logicRacine.newNormalRef.current   = val,
    newPresetRef: (logicRacine:any, val: boolean) => logicRacine.newPresetRef.current   = val,
    reportInfoRef: (logicRacine:any, val: ReportInfo) => logicRacine.reportInfoRef.current   = val,
    classeRef: (logicRacine:any, val: ClasseRoom | null) => logicRacine.classeRef.current   = val,
    anchorRef: (logicRacine:any, val: any) => logicRacine.anchorRef.current   = val,
    deadlineDateRef: (logicRacine:any, val: string) => logicRacine.deadlineDateRef.current   = val,
    indicatorRef: (logicRacine:any, val: Map<string, any>) => logicRacine.indicatorRef.current   = val,
    eventCreneauSelectedRef: (logicRacine:any, val: CreneauRdv|null) => logicRacine.eventCreneauSelectedRef.current   = _.cloneDeep(val),
    calendarDate:(logicRacine:any, date:Date) => logicRacine.setCalendarDate(_.cloneDeep(date)),
    minDate:(logicRacine:any, date:Date) => logicRacine.setMinDate(_.cloneDeep(date)),
    maxDate:(logicRacine:any, date:Date) => logicRacine.setMaxDate(_.cloneDeep(date)),
    showContent:(logicRacine:any, val:boolean) => logicRacine.setShowContent(val),
    openPresetDialogForm:(logicRacine:any, val:boolean) => logicRacine.setOpenPresetDialogForm(val),
    openFilterDialogForm:(logicRacine:any, val:boolean) => logicRacine.setOpenFilterDialogForm(val),
    minuteStep:(logicRacine:any, val:number) => logicRacine.setMinuteStep(val),
    rdvCancelOpenBeforeMeetingPreset:(logicRacine:any, val:boolean) => logicRacine.setRdvEtatCheckedBeforeMeetingPreset(val),
    statusAfterMeetingPreset:(logicRacine:any, val:boolean) => logicRacine.setStatusAfterMeetingPreset(val),
    savingStatePresetBtn:(logicRacine:any, val:boolean) => logicRacine.setSavingStatePresetBtn(val),
    showContentPreset:(logicRacine:any, val:boolean) => logicRacine.setShowContentPreset(val),
    lectureRdvPreset:(logicRacine:any, val:LectureRdv | null) => logicRacine.setLectureRdvPreset(_.cloneDeep(val)),
    startFormPreset:(logicRacine:any, val:moment.Moment) => logicRacine.setStartFormPreset(val),
    endFormPreset:(logicRacine:any, val:moment.Moment) => logicRacine.setEndFormPreset(val),
    classeIdPreset:(logicRacine:any, val:number) => logicRacine.setClasseIdPreset(val),
    statusBeforeMeetingViewPresetRef:(logicRacine:any, val:boolean) => logicRacine.statusBeforeMeetingViewPresetRef.current = val,
    statusAfterMeetingViewPresetRef:(logicRacine:any, val:boolean) => logicRacine.statusAfterMeetingViewPresetRef.current = val,
    creneauRdvEnfantParentPresetRef:(logicRacine:any, val:CreneauRdvEnfantParent | null | undefined) => logicRacine.creneauRdvEnfantParentPresetRef.current = _.cloneDeep(val),
    creneauRdvEmployeePreset:(logicRacine:any, val:CreneauRdvEmployee | null | undefined) => logicRacine.setCreneauRdvEmployeePreset(_.cloneDeep(val)),
    creneauRdvEmployeeNorm:(logicRacine:any, val:CreneauRdvEmployee | null | undefined) => logicRacine.setCreneauRdvEmployeeNorm(_.cloneDeep(val)),
    crenoDayEmployeeNorm:(logicRacine:any, val:moment.Moment) => logicRacine.setCrenoDayEmployeeNormForm(_.cloneDeep(val)),
    dayNormForm:(logicRacine:any, val:moment.Moment) => logicRacine.setDayNormForm(val),
    startNormForm:(logicRacine:any, val:moment.Moment) => logicRacine.setStartNormForm(val),
    endNormForm:(logicRacine:any, val:moment.Moment) => logicRacine.setEndNormForm(val),
    childNormSelected:(logicRacine:any, val:Child | null | undefined) => logicRacine.setChildNormSelected(_.cloneDeep(val)),
    childNormList:(logicRacine:any, val:Child[]) => logicRacine.setChildNormList(_.cloneDeep(val)),
    employeeNormSelected:(logicRacine:any, val:Employee | null | undefined) => logicRacine.setEmployeeNormSelected(_.cloneDeep(val)),
    childNormDisable:(logicRacine:any, val:boolean) => logicRacine.setChildNormDisable(val),
    creneauRdvEnfantParentNormRef:(logicRacine:any, val:CreneauRdvEnfantParent | null | undefined) => logicRacine.creneauRdvEnfantParentNormRef.current = val,
    editDisableNorm:(logicRacine:any, val:boolean) =>  logicRacine.setEditDisableNorm(val),
    isFormDisableForCurrentUserRef:(logicRacine:any, val:boolean) =>  logicRacine.editDisableNorm = val,
    parentNormRef:(logicRacine:any, val:Parent | null | undefined) =>  logicRacine.parentNormRef.current = val,
    editDisablePresetRef: (logicRacine:any, val: boolean) => logicRacine.editDisablePresetRef.current   = val,
    statusBeforeMeetingViewNormRef:(logicRacine:any, val:boolean) => logicRacine.statusBeforeMeetingViewNormRef.current = val,
    statusAfterMeetingViewNormRef:(logicRacine:any, val:boolean) => logicRacine.statusAfterMeetingViewNormRef.current = val,
    savingStateNormBtn: (logicRacine:any, val:boolean) => logicRacine.setSavingStateNormBtn(val),
    openNormalDialogForm:(logicRacine:any, val:boolean) => logicRacine.setOpenNormalDialogForm(val),
    showContentNorm:(logicRacine:any, val:boolean) => logicRacine.setShowContentNorm(val),
    lectureRdvNorm:(logicRacine:any, val:LectureRdv | null) => logicRacine.setLectureRdvNorm(_.cloneDeep(val)),

}

export default rdvStateUpdate ;
