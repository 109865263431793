export const USER_GENRE = [
    {
        key: 'HOMME',
        name: 'HOMME',
    },
    {
        key: 'FEMME',
        name: 'FEMME',
    },
];


export const USER_GENRE_EN = [
    {
        key: 'HOMME',
        name: 'MALE',
    },
    {
        key: 'FEMME',
        name: 'FEMALE',
    },
];

export const USER_GENRE_FR = [
    {
        key: 'HOMME',
        name: 'HOMME',
    },
    {
        key: 'FEMME',
        name: 'FEMME',
    },
];

