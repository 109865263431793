import React from "react";
import {Badge, Box} from "@mui/material";

export const MyBadge = (
    {
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right',
        },
        badgeContent = ``,
        color = "secondary",
    } : {
        anchorOrigin ?: any;
        badgeContent ?: any;
        color ?: any;
    } ={}
) => {

    return (
       <Badge anchorOrigin={anchorOrigin} badgeContent={badgeContent} color={color} />
    )
}