import {Box, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import classNames from "classnames";
import React, {useContext} from "react";
import {AppointmentPresetContext} from "./Appointment";
import {RdvType} from "../../constants/type/RdvType";
import _ from "lodash";
import {RdvEtat} from "../../constants/type/RdvEtat";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import {isPositiveValue} from "../../helpers/helpers";

const PresetDetails = (props:any) => {
    const logicRacine: any = useContext(AppointmentPresetContext);

    React.useMemo(() => {
        if(!isPositiveValue(logicRacine.rdvEdit.id)){
            return ;
        }
        if(_.isNull(logicRacine.creneauRdvListInCalendar) || _.isUndefined(logicRacine.creneauRdvListInCalendar)){
            return ;
        }
        rdvStateUpdate.classeRef(logicRacine, _.find(logicRacine.classeRoomRef.current, {id:logicRacine.rdvEdit.classeId}));
        rdvStateUpdate.deadlineDateRef(logicRacine, logicRacine.rdvEdit.startForm?.clone().subtract(logicRacine.rdvEdit.deadlineUpdate).format('L'));

        const crenolist = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
        const kpis = _.countBy(crenolist, 'meetingStatus');
        logicRacine.indicatorRef.current.set(RdvEtat.WAIT.valueOf(), kpis[RdvEtat.WAIT] ?? 0);
        logicRacine.indicatorRef.current.set(RdvEtat.CONFIRM.valueOf(), kpis[RdvEtat.CONFIRM] ?? 0);
        logicRacine.indicatorRef.current.set(RdvEtat.NOT_RESPECTED.valueOf(), kpis[RdvEtat.NOT_RESPECTED] ?? 0);
        logicRacine.indicatorRef.current.set(RdvEtat.DONE.valueOf(), kpis[RdvEtat.DONE] ?? 0);
        logicRacine.indicatorRef.current.set('creneau_total', crenolist.length);
        logicRacine.indicatorRef.current.set('eleve_total', logicRacine.classeRef.current?.totalEleves ?? 0);
    }, [logicRacine.rdvEdit]);

   /* React.useMemo(() => {
        if(_.isNull(logicRacine.creneauRdvListInCalendar) || _.isUndefined(logicRacine.creneauRdvListInCalendar)){
            return ;
        }
        if(logicRacine.rdvEdit.meetingType !== RdvType.PRESET || !isPositiveValue(logicRacine.rdvEdit.id) ){
            return <></>;
        }

    }, [logicRacine.rdvEdit.meetingType]);*/


    if(logicRacine.rdvEdit.meetingType !== RdvType.PRESET || !isPositiveValue(logicRacine.rdvEdit.id) ){
        return <></>;
    }

    return <Box className={logicRacine.styleClasses.userDetails}
                sx={{display:  'block' }}
    >
        <Typography align="center" variant="h6" component="h2" className={logicRacine.styleClasses.titleUserAppointment}>
             {logicRacine.rdvEdit.objet}
        </Typography>

        <Box className={classNames(logicRacine.styleClasses.userInfoBox)} sx={{display:"flex"}}>
            <Box className={logicRacine.styleClasses.customTitlePreset} letterSpacing={1} sx={{ textAlign:'left', flex:4}}>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.rdv_status_libelle} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <FormattedMessage id={messages[`appointment_${_.toLower(logicRacine.rdvEdit.meetingStatus)}`]} />
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.class_preset} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" variant="inherit">{logicRacine.classeRef.current?.nom}</Typography>
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.description_appointment} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" variant="inherit">{logicRacine.rdvEdit.details}</Typography>
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.start_day} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" variant="inherit">{logicRacine.rdvEdit.startForm?.format('L')}</Typography>
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.end_day} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" variant="inherit">{logicRacine.rdvEdit.endForm?.format('L')}</Typography>
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.duration_preset} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" variant="inherit">{logicRacine.rdvEdit.dureeMeetingForm} minutes</Typography>
                    </div>
                </Box>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"30%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.deadline_rdv_date} />
                        </Typography>
                    </div>
                    <div style={{width:"65%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" color="red" variant="inherit">{logicRacine.deadlineDateRef.current}</Typography>
                    </div>
                </Box>
            </Box>
            <Box sx={{ textAlign:'left',  flex:2}} className={logicRacine.styleClasses.customTitle} letterSpacing={1}>
                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.class_effectif} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                      <Typography component="span" variant="inherit">{logicRacine.indicatorRef.current.get('eleve_total')}</Typography>
                    </div>
                </Box>

                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.appointment_creno} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                        <Typography component="span" className={logicRacine.styleClasses.dotStatus} variant="inherit">
                            {logicRacine.indicatorRef.current.get('creneau_total')}
                        </Typography>
                    </div>
                </Box>

                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.appointment_wait} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                        <Typography
                            component="span"
                            className={`${logicRacine.styleClasses.dotStatus} ${logicRacine.styleClasses.dotStatusPending}`}
                            variant="inherit">
                            {logicRacine.indicatorRef.current.get(RdvEtat.WAIT.valueOf())}
                        </Typography>
                    </div>
                </Box>

                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.appointment_confirm} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                        <Typography
                            component="span"
                            className={`${logicRacine.styleClasses.dotStatus} ${logicRacine.styleClasses.dotStatusConfirm}`}
                            variant="inherit">
                            {logicRacine.indicatorRef.current.get(RdvEtat.CONFIRM.valueOf())}
                        </Typography>
                    </div>
                </Box>

                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.appointment_done} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                        <Typography
                            component="span"
                            className={`${logicRacine.styleClasses.dotStatus} ${logicRacine.styleClasses.dotStatusDone}`}
                            variant="inherit">
                            {logicRacine.indicatorRef.current.get(RdvEtat.DONE.valueOf())}
                        </Typography>
                    </div>
                </Box>

                <Box sx={{display:"flex"}}>
                    <div style={{width:"55%", marginBottom:10}}>
                        <Typography className={logicRacine.styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages.appointment_not_respected} />
                        </Typography>
                    </div>
                    <div style={{width:"40%", marginBottom:10, paddingTop:5}}>
                        <Typography
                            component="span"
                            className={`${logicRacine.styleClasses.dotStatus} ${logicRacine.styleClasses.dotStatusNotRespected}`}
                            variant="inherit">
                            {logicRacine.indicatorRef.current.get(RdvEtat.NOT_RESPECTED.valueOf())}
                        </Typography>
                    </div>
                </Box>
            </Box>
        </Box>
    </Box>
}

export default PresetDetails ;