import {Action, combineReducers, configureStore, ThunkAction,} from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import menuReducer from '../features/menu/menuSlice';
import pageReducer from '../features/page/pageSlice';
import remoteDBCacheReducer from '../features/cache/remoteDBCacheSlice';
import dataTableReducer from '../features/datatable/dataTableSlice';
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const reducer = combineReducers({
    user: userReducer,
    menu: menuReducer,
    page: pageReducer,
    cacheDB: remoteDBCacheReducer,
    dataTable: dataTableReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

