import _ from "lodash";
import {Rdv, RdvFilter, rdvFilterDataImpl, rdvNormalDataImpl, RdvPeriode} from "../../../constants/type/Rdv";
import moment from "moment";
import rdvStateUpdate from "./RdvStateUpdate";
import {RdvType} from "../../../constants/type/RdvType";
import {RdvEtat} from "../../../constants/type/RdvEtat";
import {
    Badge,
    Collapse,
    IconButton,
    ListItemButton,
    ListItemSecondaryAction,
    ListSubheader,
    Typography
} from "@mui/material";
import {alpha} from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import React from "react";
import popperBusinessLogic from "./PopperBusinessLogic";
import {creneauRdvDataImp} from "../../../constants/type/CreneauRdv";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {TransitionGroup} from "react-transition-group";
import List from "@mui/material/List";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import GroupsIcon from '@mui/icons-material/Groups';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import MenuIcon from "@mui/icons-material/Menu";
import {isPositiveValue} from "../../../helpers/helpers";

const rdvToolBarBusinessLogic : any = {
    filtrerLesRdv: (logicRacine:any, customFilter:RdvFilter, updateRdvSelected=false) => {
        let rdvclones   = _.cloneDeep(logicRacine.rdvStoreRef.current);
        let rdvs =  _.cloneDeep(logicRacine.rdvStoreRef.current);
        let resultFiltre: Rdv[] = [] ;
        if(customFilter.meetingType.length > 0){
            resultFiltre  = _.filter(rdvclones, function(item:Rdv) {
                return _.indexOf(customFilter.meetingType, item.meetingType) >= 0;
            });
            // @ts-ignore
            rdvclones = _.cloneDeep(resultFiltre);
            rdvs = _.cloneDeep(resultFiltre);
        }
        if(customFilter.periode.length > 0){
            const today = moment();
            let periodRdvfiltre: Rdv[]=[];
            if(_.indexOf(customFilter.periode, RdvPeriode.PAST) >= 0){
                resultFiltre  = _.filter(rdvclones, function(item) {
                    return today.isAfter(item.endForm) ;
                });
                // @ts-ignore
                periodRdvfiltre =  periodRdvfiltre.concat(resultFiltre);
            }

            if(_.indexOf(customFilter.periode, RdvPeriode.CURRENT) >= 0){
                resultFiltre  = _.filter(rdvclones, function(item) {
                    return today.isBetween(item.startForm, item.endForm) ;
                });
                // @ts-ignore
                periodRdvfiltre =  periodRdvfiltre.concat(resultFiltre);
            }
            if(_.indexOf(customFilter.periode, RdvPeriode.UPCOMING) >= 0){
                resultFiltre  = _.filter(rdvclones, function(item) {
                    return today.isBefore(item.startForm) ;
                });
                // @ts-ignore
                periodRdvfiltre =  periodRdvfiltre.concat(resultFiltre);
            }
            rdvclones = _.cloneDeep(periodRdvfiltre);
            rdvs = _.cloneDeep(periodRdvfiltre);
        }
        if(customFilter.meetingStatus.length > 0){
            resultFiltre  = _.filter(rdvclones, function(item) {
                // @ts-ignore
                return _.indexOf(customFilter.meetingStatus, item.meetingStatus) >= 0;
            });
            // @ts-ignore
            rdvs = _.cloneDeep(resultFiltre);
        }

        const rdvsUniq = _.uniqBy(rdvs, 'id');
        const rdvSorted = _.sortBy(rdvsUniq, 'objet');

        const idrdvEdit:number = logicRacine.rdvEdit?.id ?? 0;
        const idrdvtoolbar:number = logicRacine.rdvToolbarSelect?.id ?? 0;

        // @ts-ignore
        if(_.findIndex(rdvSorted, {'id': idrdvEdit}) < 0){
            rdvStateUpdate.rdvEdit(logicRacine, _.cloneDeep(rdvNormalDataImpl));
        }
        // @ts-ignore
        if(_.findIndex(rdvSorted, {'id': idrdvtoolbar}) < 0){
            rdvStateUpdate.rdvToolbarSelect(logicRacine, _.cloneDeep(rdvNormalDataImpl));
        }
        rdvStateUpdate.rdvList(logicRacine, rdvSorted);
    },
    updateCrenoListFromRdvToolBarSelected: (logicRacine:any, rdv:Rdv|null) => {
        if(_.isObject(rdv) && isPositiveValue(rdv.id)){
            const slots = rdv.creneauRdvs ?? [];
            rdvStateUpdate.creneauRdvListInCalendar(logicRacine, () => slots);
            return ;
        }
        const  slots    = _.flatMap(logicRacine.rdvList, (item:Rdv) => item.creneauRdvs);
        rdvStateUpdate.creneauRdvListInCalendar(logicRacine, () => slots);
    },
    showToolbarSavePreset: (logicRacine:any, item: Rdv): boolean => {
        if(_.isNull(item.id) || _.isUndefined(item.id)){
            return true ;
        }
        if(item.meetingType !== RdvType.PRESET){
            return false ;
        }
        if(item.meetingStatus !== RdvEtat.WAIT){
            return false ;
        }
        return  moment().isBefore(item.startForm.clone().subtract(item.deadlineUpdate));
    },
    handleFilterRdv: (logicRacine:any) => rdvStateUpdate.openFilterDialogForm(logicRacine, true),
    afficherAllRdv: (logicRacine:any) => {
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, _.cloneDeep(rdvFilterDataImpl));
    },
    afficherAllNormalRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.NORMAL);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    afficherAllNormalNextRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.NORMAL);
        filtre.periode.push(RdvPeriode.UPCOMING);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    afficherAllNormalPastRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.NORMAL);
        filtre.periode.push(RdvPeriode.PAST);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    afficherAllPresetRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.PRESET);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    afficherAllPresetNextRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.PRESET);
        filtre.periode.push(RdvPeriode.UPCOMING);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    afficherAllPresetPastRdv: (logicRacine:any) => {
        const filtre = _.cloneDeep(rdvFilterDataImpl);
        filtre.meetingType.push(RdvType.PRESET);
        filtre.periode.push(RdvPeriode.PAST);
        rdvToolBarBusinessLogic.filtrerLesRdv(logicRacine, filtre);
    },
    handleRdvChange: (logicRacine:any, rdv: Rdv) => {
        //const rdvId = parseInt(event.target.value);
        //let rdv =  _.find(logicRacine.rdvList, {id:rdvId}) ;
        if(_.isNull(rdv) || _.isUndefined(rdv)){
            rdv = _.cloneDeep(rdvNormalDataImpl);
        }
        rdvStateUpdate.rdvToolbarSelect(logicRacine, rdv);
        rdvStateUpdate.rdvEdit(logicRacine, rdv);
    },
    rdvsToListItems:(logicRacine:any, rdvList:Rdv[]) => {
        const currentRdvId = logicRacine.rdvEdit.id ;
        const styleClasses = logicRacine.styleClasses ;
        const paperTheme = logicRacine.theme.palette.background.paper ;
        return rdvList.map((data:Rdv) => {
            const eventCreneau              = data.creneauRdvs[0] ?? _.cloneDeep(creneauRdvDataImp);
            const creneauRdv                = eventCreneau ;
            const creneauRdvEmployee        = eventCreneau.creneauRdvEmployees[0] ?? _.cloneDeep(creneauRdvDataImp) ;
            const creneauRdvEnfantParent    = eventCreneau.creneauRdvEnfantParents[0] ?? _.cloneDeep(creneauRdvDataImp) ;

            const reportInfo = popperBusinessLogic.updateReportInfo(logicRacine, data, creneauRdv, creneauRdvEmployee, creneauRdvEnfantParent) ;

            const index = data.id;
            const sndText = <>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.startForm.format('LL')}</p>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{reportInfo.subtitle}</p>
            </>;
            return <ListItemButton
                key={`listitembutton_${index}`}
                className={currentRdvId === data.id ? styleClasses.selected : ''}
                sx={{ pl: 6 , backgroundColor: (currentRdvId === data.id ? alpha(paperTheme, 0.8) : '' ) }}
                onClick={() => rdvToolBarBusinessLogic.handleRdvChange(logicRacine,data)}
            >
                <ListItemText
                    key={`listitemtext_${index}`}
                    primary={data.objet}
                    secondary={sndText}  />
            </ListItemButton>;
        });
    },
    handleExpanCollapseNormal: (logicRacine:any) => {
        const etat = !logicRacine.expandNormal ;
        logicRacine.setExpandNormal(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandPreset(false);
        }
    },
    handleExpanCollapsePreset: (logicRacine:any) => {
        const etat = !logicRacine.expandPreset ;
        logicRacine.setExpandPreset(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandNormal(false);
        }
    },
    handleExpanCollapseNormalPast: (logicRacine:any) => {
        const etat = !logicRacine.expandNormalPast ;
        logicRacine.setExpandNormalPast(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandNormalNext(false);
            logicRacine.setExpandPresetPast(false);
            logicRacine.setExpandPresetNext(false);
        }
    },
    handleExpanCollapseNormalNext: (logicRacine:any) => {
        const etat = !logicRacine.expandNormalNext ;
        logicRacine.setExpandNormalNext(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandNormalPast(false);
            logicRacine.setExpandPresetNext(false);
            logicRacine.setExpandPresetPast(false);
        }
    },
    handleExpanCollapsePresetNext: (logicRacine:any) => {
        const etat = !logicRacine.expandPresetNext ;
        logicRacine.setExpandPresetNext(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandPresetPast(false);
            logicRacine.setExpandNormalPast(false);
            logicRacine.setExpandNormalNext(false);
        }
    },
    handleExpanCollapsePresetPast: (logicRacine:any) => {
        const etat = !logicRacine.expandPresetPast ;
        logicRacine.setExpandPresetPast(etat);
        logicRacine.setListItemUpdate(moment().valueOf());
        if(etat){
            logicRacine.setExpandPresetNext(false);
            logicRacine.setExpandNormalPast(false);
            logicRacine.setExpandNormalNext(false);
        }
    },
    ListItemRdvPresetPast:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const currentmoment = moment();
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.PRESET && currentmoment.isAfter(data.endForm) );
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }
        const rdvSortResult = _.reverse(_.sortBy(rdvFilterResult, ['dateFin']));
        return  <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: 4 }}
                        key="presetpast"
                        onClick={() => rdvToolBarBusinessLogic.handleExpanCollapsePresetPast(logicRacine)}>
                        <ListItemIcon>
                            <AlarmOffIcon color="action" />
                        </ListItemIcon>
                        <ListItemText
                            key="presetpastlistitemtext"
                            primary={<FormattedMessage id={messages.appointment_past} />}
                        />
                        <ListItemSecondaryAction>
                            <Badge max={999}
                                   badgeContent={rdvSortResult.length}
                                   color="primary"
                                   className={logicRacine.styleClasses.rdvBadgeList} />
                            {logicRacine.expandPresetPast ? <ExpandLess /> : <ExpandMore  />}
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Collapse  in={logicRacine.expandPresetPast} key="presetpastcollapse" timeout="auto" unmountOnExit>
                        <ListItemButton key="allpresetrdvpast" onClick={() => rdvToolBarBusinessLogic.afficherAllPresetPastRdv(logicRacine) }>
                            <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                                <FormattedMessage id={messages.all_label_rdv} />
                            </Typography>
                        </ListItemButton>
                        {rdvToolBarBusinessLogic.rdvsToListItems(logicRacine, rdvSortResult)}
                    </Collapse>
                </List>
            ;
    },
    ListItemRdvPresetNext:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const currentmoment = moment();
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.PRESET && currentmoment.isBefore(data.endForm) );
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }
        const rdvSortResult = _.sortBy(rdvFilterResult, ['dateDebut']);
        return  <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} key="presetnext" onClick={() => rdvToolBarBusinessLogic.handleExpanCollapsePresetNext(logicRacine)}>
                <ListItemIcon>
                    <NextWeekIcon color="secondary" />
                </ListItemIcon>
                <ListItemText  key="presetnextlistitemtext" primary={<FormattedMessage id={messages.appointment_upcoming} />} />

                <ListItemSecondaryAction>
                    <Badge max={999}
                           badgeContent={rdvSortResult.length}
                           color="primary"
                           className={logicRacine.styleClasses.rdvBadgeList} />
                    {logicRacine.expandPresetNext ? <ExpandLess /> : <ExpandMore  />}
                </ListItemSecondaryAction>

            </ListItemButton>
            <Collapse  in={logicRacine.expandPresetNext} key="presetnextcollapse" timeout="auto" unmountOnExit>
                <ListItemButton key="allpresetrdvnext" onClick={() => rdvToolBarBusinessLogic.afficherAllPresetNextRdv(logicRacine) }>
                    <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                        <FormattedMessage id={messages.all_label_rdv} />
                    </Typography>
                </ListItemButton>
                {rdvToolBarBusinessLogic.rdvsToListItems(logicRacine, rdvSortResult)}
            </Collapse>
        </List>
            ;
    },
    ListItemRdvNormalPast:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const currentmoment = moment();
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.NORMAL && currentmoment.isAfter(data.endForm) );
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }
        const rdvSortResult = _.reverse(_.sortBy(rdvFilterResult, ['dateFin']));
        return  <List component="div" disablePadding>
            <ListItemButton
                sx={{ pl: 4 }}
                key="normalpast"
                onClick={() => rdvToolBarBusinessLogic.handleExpanCollapseNormalPast(logicRacine)}>
                <ListItemIcon>
                    <AlarmOffIcon color="action" />
                </ListItemIcon>
                <ListItemText  key="normalpastlistitemtext" primary={<FormattedMessage id={messages.appointment_past} />} />
                <ListItemSecondaryAction>
                    <Badge max={999}
                           badgeContent={rdvSortResult.length}
                           color="primary"
                           className={logicRacine.styleClasses.rdvBadgeList} />
                    {logicRacine.expandNormalPast ? <ExpandLess /> : <ExpandMore  />}
                </ListItemSecondaryAction>

            </ListItemButton>
            <Collapse  in={logicRacine.expandNormalPast} key="normalpastcollapse" timeout="auto" unmountOnExit>
                <ListItemButton key="allrdvnormalpast" onClick={() => rdvToolBarBusinessLogic.afficherAllNormalPastRdv(logicRacine) }>
                    <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                        <FormattedMessage id={messages.all_label_rdv} />
                    </Typography>
                </ListItemButton>
                {rdvToolBarBusinessLogic.rdvsToListItems(logicRacine, rdvSortResult)}
            </Collapse>
        </List>
        ;
    },
    ListItemRdvNormalNext:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const currentmoment = moment();
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.NORMAL && currentmoment.isBefore(data.endForm) );
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }
        const rdvSortResult = _.sortBy(rdvFilterResult, ['dateDebut']);
        return  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}  key="normalnext" onClick={() => rdvToolBarBusinessLogic.handleExpanCollapseNormalNext(logicRacine)}>
                        <ListItemIcon>
                            <NextWeekIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText  key="normalnextlistitemtext" primary={<FormattedMessage id={messages.appointment_upcoming} />} />
                        <ListItemSecondaryAction>
                            <Badge max={999}
                                   badgeContent={rdvSortResult.length}
                                   color="primary"
                                   className={logicRacine.styleClasses.rdvBadgeList} />
                            {logicRacine.expandNormalNext ? <ExpandLess /> : <ExpandMore  />}
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Collapse  in={logicRacine.expandNormalNext} key="normalnextcollapse" timeout="auto" unmountOnExit>
                        <ListItemButton key="allrdvnormalnext" onClick={() => rdvToolBarBusinessLogic.afficherAllNormalNextRdv(logicRacine) }>
                            <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                                <FormattedMessage id={messages.all_label_rdv} />
                            </Typography>
                        </ListItemButton>
                        {rdvToolBarBusinessLogic.rdvsToListItems(logicRacine, rdvSortResult)}
                    </Collapse>
                </List>
            ;
    },
    ListItemRdvNormal:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.NORMAL);
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }

        return  <List component="div" disablePadding>
            <ListItemButton key="normalrdv" onClick={() => rdvToolBarBusinessLogic.handleExpanCollapseNormal(logicRacine)}>
                <ListItemIcon>
                    <PersonPinIcon color="primary" />
                </ListItemIcon>
                <ListItemText  key="normalrdvlistitemtext" primary={<FormattedMessage id={messages.normal_title} />} />
                <ListItemSecondaryAction>
                    <Badge max={999}
                           badgeContent={rdvFilterResult.length}
                           color="primary"
                           className={logicRacine.styleClasses.rdvBadgeList} />
                    {logicRacine.expandNormalPast ? <ExpandLess /> : <ExpandMore  />}
                </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse  in={logicRacine.expandNormal} key="normalrdvcollapse" timeout="auto" unmountOnExit>
                <ListItemButton key="allrdvnormal" onClick={() => rdvToolBarBusinessLogic.afficherAllNormalRdv(logicRacine) }>
                    <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                        <FormattedMessage id={messages.all_label_rdv} />
                    </Typography>
                </ListItemButton>
                {rdvToolBarBusinessLogic.ListItemRdvNormalNext(logicRacine, rdvFilterResult)}
                {rdvToolBarBusinessLogic.ListItemRdvNormalPast(logicRacine, rdvFilterResult)}
            </Collapse>
        </List>
            ;
    },
    ListItemRdvPreset:(logicRacine:any, rdvList:Rdv[]) => {
        if(_.isEmpty(rdvList)){
            return <></> ;
        }
        const rdvFilterResult = rdvList.filter((data:Rdv) => data.meetingType === RdvType.PRESET);
        if(_.isEmpty(rdvFilterResult)){
            return <></> ;
        }

        return  <List component="div" disablePadding>
            <ListItemButton key="presetrdv" onClick={() => rdvToolBarBusinessLogic.handleExpanCollapsePreset(logicRacine)}>
                <ListItemIcon>
                    <GroupsIcon color="primary" />
                </ListItemIcon>
                <ListItemText  key="presetrdvlistitemtext" primary={<FormattedMessage id={messages.preset_title} />} />
                <ListItemSecondaryAction>
                    <Badge max={999}
                           badgeContent={rdvFilterResult.length}
                           color="primary"
                           className={logicRacine.styleClasses.rdvBadgeList} />
                    {logicRacine.expandPreset ? <ExpandLess /> : <ExpandMore  />}
                </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse  in={logicRacine.expandPreset} key="normalrdvcollapse" timeout="auto" unmountOnExit>
                <ListItemButton key="allpresetrdv" onClick={() => rdvToolBarBusinessLogic.afficherAllPresetRdv(logicRacine) }>
                    <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                        <FormattedMessage id={messages.all_label_rdv} />
                    </Typography>
                </ListItemButton>
                {rdvToolBarBusinessLogic.ListItemRdvPresetNext(logicRacine, rdvFilterResult)}
                {rdvToolBarBusinessLogic.ListItemRdvPresetPast(logicRacine, rdvFilterResult)}
            </Collapse>
        </List>
            ;
    },
    rdvMenuList:(logicRacine:any, anchor:any) => {
        return (
            <List
                component="nav"
                disablePadding
                subheader={
                    <ListSubheader component="div" style={{padding:0, textAlign: 'center',textTransform: 'uppercase'}} id="nested-list-subheader">
                        <FormattedMessage id={messages.rdv_list} />
                        <IconButton
                            aria-label="edit"
                            color="secondary"
                            onClick={() => rdvStateUpdate.openRdvList(logicRacine)}
                            sx={{float:'right', marginRight:2}}>
                            <MenuIcon />
                        </IconButton>
                    </ListSubheader>
                }
                className={logicRacine.styleClasses.rdvList}>
                <TransitionGroup>
                    <ListItemButton key="allrdv" onClick={() => rdvToolBarBusinessLogic.afficherAllRdv(logicRacine) }>
                        <Typography variant="body2" sx={{fontStyle: 'italic', fontWeight:'normal', ml:4}} gutterBottom>
                            <FormattedMessage id={messages.all_label_rdv} />
                        </Typography>
                    </ListItemButton>
                    { rdvToolBarBusinessLogic.ListItemRdvNormal(logicRacine, logicRacine.rdvList) }
                    { rdvToolBarBusinessLogic.ListItemRdvPreset(logicRacine, logicRacine.rdvList) }
                </TransitionGroup>
            </List>
        );
    }
}

export default rdvToolBarBusinessLogic ;
