import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import emprunterStateUpdate from "./EmprunterStateUpdate";
import {FormattedMessage} from "react-intl";
import {processingChildren, processingEmployees, processingJobs} from "../../../../helpers/loader_helpers";
import {LivreEmprunt, LIVREEMPRUNT_CONSTANT} from "../../../../constants/type/LivreEmprunt";
import {isPositiveValue} from "../../../../helpers/helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {Child} from "../../../../constants/type/Child";
import {LivreCategorie, LIVRECATEGORIE_CONSTANT} from "../../../../constants/type/LivreCategorie";
import {Livre, LIVRE_CONSTANT} from "../../../../constants/type/Livre";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";

const emprunterBusinessLogic : any = {
    initPostConstruct: (logic:any) => {

        const requestbody = ['classes', 'profils',  'enfants', 'livrecategories', 'livres', 'fonctions', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemlc : LivreCategorie[]   = data.livrecategories.content ?? [];
            logic.livreCategorieRef.current  = _.sortBy(_.map(itemlc,  LIVRECATEGORIE_CONSTANT.convertToFrontEnd), ['nom']);

            const items : Livre[]   = data.livres.content ?? [];
            logic.livresRef.current = _.sortBy(_.map(items,  item => LIVRE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);


            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logic = processingEmployees(logic, itememployees);

        };

        emprunterStateUpdate.showListContent(logic, false);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            emprunterStateUpdate.livres(logic, logic.livresRef.current, logic.livreFiltre);
            emprunterBusinessLogic.loadLivresEmprunter(logic);
        }).catch(e => { emprunterStateUpdate.showListContent(logic, true);});

    },
    saveLivreEmprunt: async (logic:any, messages:any, toSend:LivreEmprunt):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = isPositiveValue(toSend.id) ? 'PUT' : 'POST';
            let params = ``;
            if(isPositiveValue(toSend.id)){
                params = `/${toSend.id}`;
            }

            request(method, `/extra/livremprunt/employee${params}`, toSend).then((response) => {

                emprunterBusinessLogic.loadLivresEmprunter(logic).then((val:any) => {});
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    /*loadLivresPagination: (logic:any) => {
        emprunterStateUpdate.showListContent(logic, false);
        loadLivresPagination(logic, logic.page, logic.pageSizeRef.current, logic.search).then(value => {
            const itemsfrontend = _.sortBy(_.map(value,  item => LIVRE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            emprunterStateUpdate.livres(logic, itemsfrontend) ;
            emprunterStateUpdate.showListContent(logic, true);
        }).catch(e => {
            emprunterStateUpdate.livres(logic, []);
            emprunterStateUpdate.showListContent(logic, true);
        });
    },*/
    loadLivresEmprunter: (logic:any) => {
        emprunterStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/livremprunt/employee/${logic.userRef.current.userDetails.personDetails.person.id}`, {}).then((response) => {

                const items : LivreEmprunt[]   = response.data._embedded?.livreEmpruntDTOModelList ?? [];

                const itemsfrontend = _.reverse(_.sortBy(_.map(items,  item => LIVREEMPRUNT_CONSTANT.convertToFrontEnd(logic, item)), ['dateDemande']));
                emprunterStateUpdate.livresEmprunt(logic, itemsfrontend) ;
                emprunterStateUpdate.tableKeyValue(logic, new Date().getTime());
                emprunterStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                emprunterStateUpdate.tableDataLoading(logic, false);
                resolve(false);
            })
        );
    },
    deleteLivre: async (logic:any, messages:any, toSends:LivreEmprunt[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:LivreEmprunt) => {
                if(isPositiveValue(l.id)){
                    // @ts-ignore
                    ids.push(l.id);
                }
            });


            request('DELETE', `/extra/livremprunt/bouquet`, ids).then((response) => {
                // @ts-ignore
                emprunterBusinessLogic.loadLivresEmprunter(logic).then((val:any) => {});
                emprunterStateUpdate.tableKeyValue(logic, new Date().getTime());

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    }
}
export default emprunterBusinessLogic ;