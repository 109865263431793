import {RdvEtat} from "../../../constants/type/RdvEtat";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import * as yup from "yup";
import {Rdv} from "../../../constants/type/Rdv";
import {CreneauRdv} from "../../../constants/type/CreneauRdv";
import {CreneauRdvEmployee} from "../../../constants/type/CreneauRdvEmployees";
import {CreneauRdvEnfantParent} from "../../../constants/type/CreneauRdvEnfantParent";
import _ from "lodash";
import moment from "moment/moment";
import {RdvType} from "../../../constants/type/RdvType";
import React from "react";
import {isPositiveValue} from "../../../helpers/helpers";

export const reportInfoDataImpl: ReportInfo =  {
    isReport:  false,
    timeStyle:{},
    meetingStatus: RdvEtat.WAIT,
    title: <FormattedMessage id={messages.normal_title} />,
    rdvRequestedBy:'',
    subtitle: 'rdv_read_void',
    parentStatusLibelle: 'rdv_read_void',
    parentOutcomeShow:false,
    employeeOutcomeShow:false,
    employeeStatusLibelle: 'rdv_read_void',
    employeeEditDisable: false,
    lastReportDateDebut:0,
    lastReportDateFin:0,
    lastReportUserId:0,
    timeSlotStyle:{},
}
const reportInfoSchema = yup.object().shape({
    isReport:  yup.boolean().default(false),
    timeStyle: yup.mixed<any>(),
    meetingStatus:yup.mixed<RdvEtat>().oneOf(Object.values(RdvEtat)),
    title: yup.mixed<any>(),
    subtitle: yup.string(),
    rdvRequestedBy: yup.string(),
    parentStatusLibelle: yup.string(),
    parentOutcomeShow: yup.boolean().default(true),
    employeeOutcomeShow: yup.boolean().default(true),
    employeeStatusLibelle: yup.string(),
    employeeEditDisable: yup.boolean().default(false),
    lastReportDateDebut: yup.number().min(0),
    lastReportDateFin: yup.number().min(0),
    lastReportUserId: yup.number().min(0),
    timeSlotStyle: yup.mixed<any>(),
});
export type ReportInfo = yup.InferType<typeof reportInfoSchema>;

const popperBusinessLogic: any  = {
    updateReportInfo : (logicRacine:any,
                     rdv:Rdv,
                     creneauRdv:CreneauRdv,
                     creneauRdvEmployee:CreneauRdvEmployee,
                     creneauRdvEnfantParent:CreneauRdvEnfantParent):ReportInfo => {

        let reportinfo:ReportInfo = _.cloneDeep(reportInfoDataImpl);
        if(_.isNull(creneauRdv) || _.isUndefined(creneauRdv)){
            return reportinfo;
        }
        if(_.isNull(rdv) || _.isUndefined(rdv)){
            return reportinfo;
        }

        const statusAfterMeeting = isPositiveValue(rdv.id) && moment().isAfter(rdv.endForm)  ;
        const statusBeforeMeeting = isPositiveValue(rdv.id) && moment().isBefore(rdv.startForm.clone().subtract(rdv.deadlineUpdate)) ;
        reportinfo = popperBusinessLogic.updateReportNormalRdv(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        reportinfo = popperBusinessLogic.updateReportPresetRdv(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        //reportinfo.subtitle = `${rdv?.id}` ;
        reportinfo.isReport = false ;
        reportinfo.timeStyle = {};
        if(creneauRdv.meetingStatus === RdvEtat.REPORT){
            reportinfo.isReport = true ;
            reportinfo.lastReportDateDebut = creneauRdv.lastReportDateDebut ;
            reportinfo.lastReportDateFin = creneauRdv.lastReportDateFin ;
            reportinfo.lastReportUserId = creneauRdv.lastReportUserId ;
            reportinfo.timeStyle = logicRacine.styleClasses.timeDeprecated;
        }

        return  reportinfo ;
    },
    updateReportNormalRdv: (logicRacine:any,
                            statusBeforeMeeting:boolean,
                          rdv:Rdv,
                          creneauRdv:CreneauRdv,
                          creneauRdvEmployee:CreneauRdvEmployee,
                          creneauRdvEnfantParent:CreneauRdvEnfantParent,
                          reportinfo:ReportInfo,
                          statusAfterMeeting:boolean): ReportInfo =>  {


        if(rdv.meetingType !== RdvType.NORMAL){
            return reportinfo ;
        }
        if(_.isNull(rdv.id) || _.isUndefined(rdv.id)){
            return reportinfo ;
        }
        if(_.isNull(creneauRdvEmployee) || _.isUndefined(creneauRdvEmployee)){
            return reportinfo ;
        }
        if(_.isNull(creneauRdvEnfantParent) || _.isUndefined(creneauRdvEnfantParent)){
            return reportinfo ;
        }
        reportinfo  = popperBusinessLogic.lireBeforeMeetingNormal(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        reportinfo  = popperBusinessLogic.lireAfterMeetingNormal(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        reportinfo.rdvRequestedBy = 'Ivory Montessori School';
        if(isPositiveValue(creneauRdvEnfantParent.parentId)){
            reportinfo.rdvRequestedBy = `Parent ${creneauRdvEnfantParent?.parentForm?.person?.nomPrenom}`;
        }

        for(const employee of logicRacine.employeesRef.current)
        {
            if(rdv.userInitor !== employee.userIdForm){
                continue ;
            }
            reportinfo.rdvRequestedBy = employee.nameForm ;
            break ;
        }

        reportinfo.title= rdv.objet;
        reportinfo.meetingStatus = rdv.meetingStatus;
        reportinfo.timeSlotStyle = logicRacine.styleClasses.eventContainerNormal;

        return reportinfo;
    },
    updateReportPresetRdv: (logicRacine:any,
                            statusBeforeMeeting:boolean,
                          rdv:Rdv,
                          creneauRdv:CreneauRdv,
                          creneauRdvEmployee:CreneauRdvEmployee,
                          creneauRdvEnfantParent:CreneauRdvEnfantParent,
                          reportinfo:ReportInfo,
                          statusAfterMeeting:boolean): ReportInfo => {

        if(rdv.meetingType !== RdvType.PRESET){
            return reportinfo ;
        }
        if(_.isNull(rdv.id) || _.isUndefined(rdv.id)){
            return reportinfo ;
        }
        if(_.isNull(creneauRdvEmployee) || _.isUndefined(creneauRdvEmployee)){
            return reportinfo ;
        }
        if(_.isNull(creneauRdvEnfantParent) || _.isUndefined(creneauRdvEnfantParent)){
            return reportinfo ;
        }

        reportinfo.parentOutcomeShow = false ;
        reportinfo.employeeOutcomeShow = false ;
        reportinfo  = popperBusinessLogic.lireBeforeMeetingPreset(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        reportinfo  = popperBusinessLogic.lireAfterMeetingPreset(logicRacine,
            statusBeforeMeeting,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            reportinfo,
            statusAfterMeeting);

        reportinfo.rdvRequestedBy = 'Ivory Montessori School';
        for(const employee of logicRacine.employeesRef.current)
        {
            if(rdv.userInitor !== employee.id){
                continue ;
            }
            reportinfo.rdvRequestedBy = employee.nameForm ;
            break ;
        }
        reportinfo.title= rdv.objet;
        reportinfo.meetingStatus = rdv.meetingStatus;
        reportinfo.timeSlotStyle = logicRacine.styleClasses.eventContainerNormal;
        const subtitle = `appointment_${_.toLower(creneauRdv.meetingStatus)}`;

        reportinfo.subtitle = logicRacine.intl.formatMessage({id:messages[subtitle]});

        return reportinfo;
    },
    lireBeforeMeetingNormal: (logicRacine:any,
                              statusBeforeMeeting:boolean,
                            rdv:Rdv,
                            creneauRdv:CreneauRdv,
                            creneauRdvEmployee:CreneauRdvEmployee,
                            creneauRdvEnfantParent:CreneauRdvEnfantParent,
                            reportinfo:ReportInfo,
                            statusAfterMeeting:boolean): ReportInfo =>{
        if(statusAfterMeeting){
            return reportinfo;
        }

        let nomPerson = ``;
        let subtitle = 'rdv_read_void';

        if(rdv.meetingStatus === RdvEtat.WAIT){
            //Employé initie le rdv
            subtitle = 'rdv_read_parent_feedback';
            reportinfo.parentStatusLibelle  = 'rdv_read_parent_noresponse_yet';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = false ;
            reportinfo.parentOutcomeShow = true ;
            reportinfo.employeeOutcomeShow = true ;

            //Parent initie le rdv
            if(creneauRdvEnfantParent.userId === rdv.userInitor){
                subtitle    = 'rdv_read_employee_feedback';
                reportinfo.parentStatusLibelle  = 'rdv_read_parent_wait_response';
                reportinfo.employeeStatusLibelle  = 'rdv_read_employee_which_outcome';
                reportinfo.employeeEditDisable = false ;
                reportinfo.parentOutcomeShow = true ;
                reportinfo.employeeOutcomeShow = true ;
            }
        }

        else if(rdv.meetingStatus === RdvEtat.REPORT){
            //Employé dernière proposition de date
            subtitle = 'rdv_read_title_employee_report';
            reportinfo.parentStatusLibelle  = 'rdv_read_parent_feedback';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = false ;
            reportinfo.parentOutcomeShow = true ;
            reportinfo.employeeOutcomeShow = true ;

            if(creneauRdv.lastReportUserId !== logicRacine.userRef.current.id)
            {
                subtitle = 'rdv_read_title_parent_report' ;
                reportinfo.parentStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeStatusLibelle  = 'rdv_read_employee_which_outcome'
                reportinfo.employeeEditDisable = false ;
                reportinfo.parentOutcomeShow = true ;
                reportinfo.employeeOutcomeShow = true ;

                if(creneauRdv.lastReportUserId !== creneauRdvEnfantParent.userId){
                    subtitle = 'rdv_read_report_feedback' ;
                    for(const employee of logicRacine.employeesRef.current)
                    {
                        if(employee.userIdForm !== creneauRdv.lastReportUserId){
                            continue ;
                        }
                        nomPerson = `${employee.nameForm} ` ;
                        subtitle = 'rdv_read_title_someone_report' ;
                        break ;
                    }
                }
            }
            /*//Parent dernière proposition de date
            if(creneauRdvEnfantParent.parentId === creneauRdv.lastReportUserId){
                subtitle = 'rdv_read_title_parent_report';
                reportinfo.parentStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeStatusLibelle  = 'rdv_read_employee_which_outcome';
                reportinfo.employeeEditDisable = false ;
                reportinfo.parentOutcomeShow = true ;
                reportinfo.employeeOutcomeShow = true ;
            }*/
        }

        else if(rdv.meetingStatus === RdvEtat.CONFIRM){
            //Employé confirmé le rendez-vous
            subtitle = 'rdv_read_title_confirm';
            reportinfo.parentStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = false ;
            reportinfo.parentOutcomeShow = false ;
            reportinfo.employeeOutcomeShow = true ;

           /* //Parent confirmé le rendez-vous
            if(creneauRdvEnfantParent.parentId === creneauRdv.lastReportUserId){
                reportinfo.subtitle = 'rdv_read_title_parent_report';
                reportinfo.parentStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeEditDisable = false ;
                reportinfo.parentOutcomeShow = false ;
                reportinfo.employeeOutcomeShow = true ;
            }*/
        }

        else if(rdv.meetingStatus === RdvEtat.CANCEL){
            //Employé a annulé le rendez-vous
            subtitle = 'rdv_read_title_employee_cancel';
            reportinfo.parentStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = true ;
            reportinfo.parentOutcomeShow = false ;
            reportinfo.employeeOutcomeShow = true ;

            //Parent a annulé le rendez-vous
            if(creneauRdvEnfantParent.meetingStatus === RdvEtat.CANCEL){
                subtitle = 'rdv_read_title_parent_cancel';
                reportinfo.parentStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeStatusLibelle  = 'rdv_read_void'
                reportinfo.employeeEditDisable = true ;
                reportinfo.parentOutcomeShow = false ;
                reportinfo.employeeOutcomeShow = false ;
            }
        }
        reportinfo.subtitle = `${nomPerson}${logicRacine.intl.formatMessage({id:messages[subtitle]})}`;
        return reportinfo ;
    },
    lireAfterMeetingNormal: ( logicRacine:any,
                              statusBeforeMeeting:boolean,
                           rdv:Rdv,
                           creneauRdv:CreneauRdv,
                           creneauRdvEmployee:CreneauRdvEmployee,
                           creneauRdvEnfantParent:CreneauRdvEnfantParent,
                           reportinfo:ReportInfo,
                           statusAfterMeeting:boolean): ReportInfo =>{


        if(statusAfterMeeting === false){
            return reportinfo;
        }


        let subtitle = 'rdv_read_title_notheld_rapport';
        if(rdv.meetingStatus === RdvEtat.CANCEL){
            //Employé a annulé le rendez-vous
            subtitle = 'rdv_read_title_employee_cancel';
            reportinfo.parentStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = true ;
            reportinfo.parentOutcomeShow = false ;
            reportinfo.employeeOutcomeShow = true ;

            //Parent a annulé le rendez-vous
            if(creneauRdvEnfantParent.meetingStatus === RdvEtat.CANCEL){
                subtitle = 'rdv_read_title_parent_cancel';
                reportinfo.parentStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeStatusLibelle  = 'rdv_read_void';
                reportinfo.employeeEditDisable = true ;
                reportinfo.parentOutcomeShow = false ;
                reportinfo.employeeOutcomeShow = false ;
            }
        }

        else if(rdv.meetingStatus === RdvEtat.CONFIRM){
            //Rapport d'un rendez-vous confirmé
            subtitle = 'rdv_read_title_confirm_rapport';
            reportinfo.parentStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = true ;
            reportinfo.parentOutcomeShow = false ;
            reportinfo.employeeOutcomeShow = true ;
        }
        else {
            //Rapport d'un rendez-vous non-confirmé et non annulé
            reportinfo.parentStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeStatusLibelle  = 'rdv_read_void';
            reportinfo.employeeEditDisable = true ;
            reportinfo.parentOutcomeShow = false ;
            reportinfo.employeeOutcomeShow = true ;
        }

        reportinfo.subtitle = logicRacine.intl.formatMessage({id:messages[subtitle]});
        return reportinfo ;
    },
    lireAfterMeetingPreset:(logicRacine:any,
                            statusBeforeMeeting:boolean,
                           rdv:Rdv,
                           creneauRdv:CreneauRdv,
                           creneauRdvEmployee:CreneauRdvEmployee,
                           creneauRdvEnfantParent:CreneauRdvEnfantParent,
                           reportinfo:ReportInfo,
                           statusAfterMeeting:boolean): ReportInfo =>{

        if(statusAfterMeeting === false){
            return reportinfo;
        }

        reportinfo.employeeOutcomeShow = true ;
        reportinfo.parentOutcomeShow = false ;

        if(rdv.meetingStatus === RdvEtat.CONFIRM){
            reportinfo.parentOutcomeShow = true ;
            //Rapport d'un rendez-vous confirmé
        }
        return reportinfo ;
    },
    lireBeforeMeetingPreset: ( logicRacine:any,
                               statusBeforeMeeting:boolean,
                            rdv:Rdv,
                            creneauRdv:CreneauRdv,
                            creneauRdvEmployee:CreneauRdvEmployee,
                            creneauRdvEnfantParent:CreneauRdvEnfantParent,
                            reportinfo:ReportInfo,
                            statusAfterMeeting:boolean): ReportInfo => {
        if(statusAfterMeeting){
            return reportinfo;
        }

        reportinfo.subtitle = 'rdv_read_title_employee_cancel';

        return reportinfo ;
    }


};
export default popperBusinessLogic;