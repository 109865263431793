import {getRequest} from "../helpers/axios_helpers";

function PeriodService() {
    return {
        getSchoolPeriod: async () => {
            return await getRequest(`/corebase/annee`);
        },
        getAllSchoolPeriod: async () => {
            const result = await getRequest(`/corebase/periodes`);
            return result._embedded !== undefined ? result._embedded.periodeDTOModelList : [];
        }
    };
}

export default PeriodService;