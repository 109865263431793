import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {Sondage, sondageDataImpl} from "../../../constants/type/Sondage";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import {SondageResponse} from "../../../constants/type/SondageResponse";

const useSondageVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];


    const [showMobileDetail, setShowMobileDetail] = useState(false);
    const [anchorElHeader, setAnchorElHeader] = useState<any>(null);
    const [expandOpenedSondage, setExpandOpenedSondage] = useState<boolean>(true);
    const [expandClosedSondage, setExpandClosedSondage] = useState<boolean>(false);
    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openEditDetailsDialogForm, setOpenEditDetailsDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [tableDetailsKeyValue, setTableDetailsKeyValue] = useState(new Date().getTime());
    const [listeDesSondages, setListeDesSondages] = useState<any[]>([]);

    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const classeRoomMapRef = useRef<Map<number, ClasseRoom>>(new Map());

    const [reponseList, setReponsesList] = useState<SondageResponse[]>([]);
    const [dataList, setDataList] = useState<Sondage[]>([]);
    const [dataListSelected, setDataListSelected] = useState<Sondage[]>([]);
    const [dataSel, setDataSel] = useState<Sondage>(_.cloneDeep(sondageDataImpl));
    const [showTableContent, setShowTableContent] = useState<boolean>(true);
    const [showDetailsTableContent, setShowDetailsTableContent] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,pageForm,
        tableKeyValue,setTableKeyValue,showTableContent,setShowTableContent,classeRoomRef,setListeDesSondages,
        showDetailsTableContent,setShowDetailsTableContent,tableDetailsKeyValue,listeDesSondages,
        setTableDetailsKeyValue,classeRoomMapRef,openEditDetailsDialogForm,setOpenEditDetailsDialogForm,
        expandOpenedSondage,setExpandOpenedSondage,expandClosedSondage,setExpandClosedSondage,
        reponseList,setReponsesList,processingSubmitStateNormBtn,setProcessingSubmitStateNormBtn,
        anchorElHeader,setAnchorElHeader,showMobileDetail,setShowMobileDetail,dispatch,dataCaches

    }
}
export default useSondageVariables;