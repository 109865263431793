import React, {useContext, useEffect, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {CircularProgress, Grid, MenuItem, TextField} from "@mui/material";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import Box from "@mui/material/Box";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    columnsGroupEnfant,
    excelGroupEnfantDataMapping,
    GroupEnfant,
    GROUPENFANT_CONSTANT
} from "../../../constants/type/GroupEnfant";
import groupEnfantStateUpdate from "./businesslogic/GroupEnfantStateUpdate";
import PapperBlock from "../../../components/ui/papperBlock/PapperBlock";
import groupEnfantBusinessLogic from "./businesslogic/GroupEnfantBusinessLogic";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import alertMessageStateUpdate from "../../alertmessage/businesslogic/AlertMessageStateUpdate";
import {GroupEnfantContext} from "./GroupEnfantPage";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

function GroupEnfantEnfantList() {
    const logic: any = useContext(GroupEnfantContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;


    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const handleSubmit = async () => {
        groupEnfantStateUpdate.processingSubmitStateNormBtn(logic, true);
        groupEnfantStateUpdate.tableDataLoading(logic, true);
        const toSends = _.map(logic.dataList, item => GROUPENFANT_CONSTANT.convertToBackEnd(logic, item));

        groupEnfantBusinessLogic.saveEdit(logic, messages, toSends).then((response:any) => {
            alertMessageStateUpdate.processingSubmitStateNormBtn(logic, false);
            alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
            alertMessageStateUpdate.tableDataLoading(logic, false);
        }).catch((e:any) => {
            alertMessageStateUpdate.processingSubmitStateNormBtn(logic, false);
            alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
            alertMessageStateUpdate.tableDataLoading(logic, false);
        });
    }
    const handleOnScolariteAnneChange = (event: any) => {
       groupEnfantStateUpdate.idScolariteAnneeSel(logic, parseInt(event.target.value));
    };
    const handleOnGroupChange = (event: any) => {
        groupEnfantStateUpdate.idGroupSel(logic, parseInt(event.target.value));
    };
    const addItem = () => {
        groupEnfantStateUpdate.openAddDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<GroupEnfant>) => {
        const data:GroupEnfant = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        groupEnfantStateUpdate.dataSel(logic, _.cloneDeep(data));
        groupEnfantStateUpdate.openEditDialogForm(logic, true);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:GroupEnfant[] = [];
        rows.map((row:any) => {
            const data:GroupEnfant = logic.dataList[row.index];
            dataSelected.push(data);
        });
        groupEnfantStateUpdate.dataListSelected(logic, dataSelected);
        groupEnfantStateUpdate.openDeleteDialogForm(logic, true);
    };



    const columns = useMemo<MRT_ColumnDef<GroupEnfant>[]>(
        // @ts-ignore
        () => [
            // @ts-ignore
            ...columnsGroupEnfant({intl:intl})
        ],
        [logic.activeLang],
    );


    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                addDisabled={logic.idScolariteAnneeSel < 1 || logic.idGroupSel < 1}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier="Children_Of_Group"
                excelDataMapping={excelGroupEnfantDataMapping({
                    rows:table.getPrePaginationRowModel().rows,
                    columns: table?.getVisibleFlatColumns(),
                    table: table,
                    intl,
                    infoSpace: ``,
                    childSpace: intl.formatMessage({id: messages.table_h_enfant}),
                    groupSpace: intl.formatMessage({id: messages.table_h_group}),
                })}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });


    useEffect(() => {
        groupEnfantBusinessLogic.fetchElevesOfGroup(logic, logic.idScolariteAnneeSel,  logic.idGroupSel);
        return () => {};
    }, [logic.idScolariteAnneeSel, logic.idGroupSel]);

    return (
        <div className={styleClasses.root}>
            <PapperBlock whiteBg noMargin displayNoTitle={true}>
                {
                    logic.contentLoaded === false &&
                    <Box sx={{
                        width: '100%',
                        height: '200px',
                        textAlign: 'center',
                        alignContent: 'center',
                        verticalAlign: 'middle'
                    }}>
                        <CircularProgress color="primary"/>
                    </Box>
                }
                {
                    logic.contentLoaded === true &&
                    <div className={styleClasses.tableContainer}>
                        <Box className={styleClasses.searchBox}>
                            <Grid container spacing={4} sx={{paddingLeft:20, paddingRight:20}}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.scolaritee_annee}/>}
                                        sx={{width: {xs: '100% !important'},}}
                                        color={"secondary"}
                                        value={logic.idScolariteAnneeSel}
                                        onChange={handleOnScolariteAnneChange}
                                        inputProps={{
                                            required: "Required field",
                                            min: 1,
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {logic.scolariteAnneesRef.current?.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                                {opt.nom}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.the_group}/>}
                                        sx={{width: {xs: '100% !important'},}}
                                        color={"secondary"}
                                        value={logic.idGroupSel}
                                        onChange={handleOnGroupChange}
                                        inputProps={{
                                            required: "Required field",
                                            min: 1,
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {logic.groupesectionsRef.current?.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                                {opt.nom}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>
                        </Box>

                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} >
                                <MaterialReactTable
                                    table={logic.tableConfigRef.current}
                                    key={logic.tableKeyValue}
                                />
                            </Grid>
                            <Grid item md={12} xs={12} sx={{paddingRight:2, marginTop:5, marginBottom:3, textAlign:'right'}}>
                                <LoadingButton
                                    loading={logic.processingSubmitStateNormBtn}
                                    color="secondary"
                                    variant="contained"
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    aria-label="Send notification"
                                    size="large"
                                    onClick={handleSubmit}
                                >
                                    <FormattedMessage id={messages.dialog_post} />
                                </LoadingButton>
                            </Grid>
                        </Grid>

                    </div>
                }
            </PapperBlock>

        </div>

    );
}

export default GroupEnfantEnfantList;