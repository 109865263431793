import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from "@mui/material/Button";
import {CONSTANT} from "../../constants";
import {Grid} from "@mui/material";
import EditMiniClubForm from "./EditMiniClubForm";
import moment from "moment";
import {dropZoneFilePreview} from "../../helpers/helpers";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";
import ClassroomService from "../../service/ClassroomService";
import {SelectChangeEvent} from "@mui/material/Select";
import MiniClubRegisteredList from "./MiniClubRegisteredList";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {TMiniClubFormValues} from "../../constants/type/miniclub/miniclubType";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import MiniClubService from "../../service/MiniClubService";
import {getErrorMessage} from "../../helpers/errorManager";
import {useSelector} from "react-redux";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            },
            '& .css-17oq3nc-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input': {
                padding: '9px 9px'
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {}
    }
})

function EditMiniClub(props:any) {
    const {locale, theme, snackbarShowMessage} = props;
    const classes = {...useStyles(), ...useStyles2()};
    const activeLang = locale.split('-')[0];
    const [startDate, setStartDate]:any = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(startDate);
    const [classroomData, setClassroomData] = useState([]);
    const [classroomDataValues, setClassroomDataValues] = useState([]);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [file, setFile] = useState(null);
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));

    const params = useLocation();
    const { data } = params.state;
    const miniClubService = MiniClubService();
    const classroomService = ClassroomService();

    const schema = yup.object().shape({
        id: yup.number(),
        title: yup.string().required(),
        prix: yup.number().required().nullable(),
        placeLimit: yup.number().required().nullable(),
        classroom: yup.array(),
    });

    const form = useForm<TMiniClubFormValues | any | undefined> ({
        defaultValues: {},
        resolver: yupResolver(schema),
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleStartDateChange = (date:any) => {
        setStartDate(date);
        setEndDate(date);
    };

    const handleEndDateChange = (date:any) => {
        setEndDate(date);
    };

    const onSubmit = async (dataForm:any) => {
        if(classroomDataValues.length > 0){

            if(startDate !== null && endDate !== null){
                try {
                    const classroomTab = classroomDataValues.map((classroom:any) => {
                        return {
                            classeId: classroom.id,
                            common: CONSTANT.common
                        }
                    })
                    const dataToSend = {
                        ...data.originalObject,
                        ...dataForm,
                        dateDebut: startDate._d.valueOf(),
                        dateFin: endDate._d.valueOf(),
                        classeMiniClubs: classroomTab,
                    }
                    await miniClubService.updateMiniClub('PUT', `/extra/miniclubs/${dataToSend.id}`, dataToSend, file);
                    snackbarShowMessage( <FormattedMessage id={messages.sb_add_success} />);
                }
                catch (error){
                    snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                }

            }
        }
    }

    const handleClassroomChange = (event: SelectChangeEvent<typeof classroomDataValues>) => {
        const value:any = event.target.value;
        setClassroomDataValues(value);
    }


    useEffect(() => {
        (async () => {
            const dataClassroomReq = await classroomService.getAllClassData();
            const dataClassroomList = dataClassroomReq._embedded !== undefined ? dataClassroomReq._embedded.classeDTOModelList : [];
            setClassroomData(dataClassroomList);

            if(data.uriPublicite !== ""){
                const photo:any = `${BASEURL_RESOURCES}/${data.uriPublicite}`
                setFileDataURL(photo);
            }

            let classroomEdit:any = [];
            if(dataClassroomList.length > 0 && data.classeMiniClubs.length > 0){
                for(let i=0; i<dataClassroomList.length; i++){
                    for (let j=0; j<data.classeMiniClubs.length; j++){
                        if(dataClassroomList[i].id === data.classeMiniClubs[j].classeId) {
                            classroomEdit.push(dataClassroomList[i]);
                        }
                    }
                }
                setClassroomDataValues(classroomEdit);
            }
        })()
    }, [activeLang]);


    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);

    }, [file]);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.subMenuTools}>
                <Button
                    className={classNames(classes.button) }
                    variant={"text"}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: 15,
                        color: "#666666"
                    }}
                    component={Link} to="/app/mini-club/list"
                    startIcon={<KeyboardBackspaceIcon />}
                >
                    <FormattedMessage id={messages.return_mini_club_list} />
                </Button>
            </div>
            <div className={classes.root}>
                <Grid container spacing={2} style={{marginBottom: 30}}>
                    <Grid item md={5} xs={12} style={{background: 'white'}}>
                        <EditMiniClubForm
                            activeLang={activeLang}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            handleStartDateChange={handleStartDateChange}
                            handleEndDateChange={handleEndDateChange}
                            classroomData={classroomData}
                            classroomDataValues={classroomDataValues}
                            handleClassroomChange={handleClassroomChange}
                            data={data}
                            handleFileChange={handleFileChange}
                            fileDataURL={fileDataURL}
                            userRef={userRef}
                            register={register}
                            //handleSubmit={handleSubmit}
                            onSubmit={handleSubmit(onSubmit)}
                            setValue={setValue}
                        />
                    </Grid>

                    <Grid item md={7} xs={12} style={{background: 'white'}}>
                        <MiniClubRegisteredList
                            data={data}
                            classroomData={classroomData}
                            activeLang={activeLang}
                        />
                    </Grid>
                </Grid>

            </div>
        </PapperBlock>
    );
};

export default withSnackbar(EditMiniClub);
