import {LivreEmprunt} from "../../../../constants/type/LivreEmprunt";
import {Livre} from "../../../../constants/type/Livre";
import _ from "lodash";
import {LivreFiltre, Pagination} from "../../../../constants/type/LivreFiltre";
import {isPositiveValue} from "../../../../helpers/helpers";

const emprunterStateUpdate:any = {
    initPagination: (logic:any, val:Livre[], pagination:Pagination) => {
        const page= _.cloneDeep(pagination);
        page.total        =  _.size(val);
        page.pageCount    = Math.ceil(page.total / page.pageSize) ;
        emprunterStateUpdate.pagination(logic, page);
        return pagination ;
    },
    pagination: (logic:any, pagination:Pagination) => logic.setPagination(pagination),
    paginer: (logic:any, val:number) => {
        const page  = _.cloneDeep(logic.pagination);
        page.page   = val;
        emprunterStateUpdate.pagination(logic, page);
    },
    livres: (logic:any, val:Livre[], filtre:LivreFiltre) => {
        let result : Livre[] = _.cloneDeep(val);
        if(isPositiveValue(filtre.livreCategorieId)){
            result = _.filter(result, {livreCategorieId:filtre.livreCategorieId});
        }

        if(filtre.etat === 'disponible'){
            result = _.filter(result, {available:true});
        }
        if(filtre.etat === 'indisponible'){
            result = _.filter(result, {available:false});
        }

        const ma_recherche = filtre.recherche.toLowerCase();
        if(_.isEmpty(ma_recherche) === false && _.size(ma_recherche) > 1){
            // @ts-ignore
            result = _.filter(result, function(item:Livre) {
                return  _.includes(item.nom.toLowerCase(), ma_recherche) ||
                    _.includes(item.auteur.toLowerCase(), ma_recherche) ||
                        _.includes(item.description?.toLowerCase(), ma_recherche);
            });
        }

        //const pagination  = emprunterStateUpdate.initPagination(logic, result, logic.pagination);
        //result = _.drop(result, pagination.page).slice(0, pagination.pageCount);
        // emprunterStateUpdate.initPagination(logic, result, logic.pagination);
        logic.setLivres(result);
    },
    livresSelected: (logic:any, val:Livre[]) => logic.setLivresSelected(val),
    livreSel: (logic:any, val:Livre) => logic.setLivreSel(val),
    livreFiltre: (logic:any, val:LivreFiltre) => logic.setLivreFiltre(val),
    search: (logic:any, val:string) => logic.setSearch(val),
    filtreCategorie: (logic:any, val:number) => {
        const livreFiltre = _.cloneDeep(logic.livreFiltre);
        livreFiltre.livreCategorieId = val;
        emprunterStateUpdate.livreFiltre(logic, livreFiltre);
    },
    filtreEtat: (logic:any, val:string) => {
        const livreFiltre = _.cloneDeep(logic.livreFiltre);
        livreFiltre.etat = val;
        emprunterStateUpdate.livreFiltre(logic, livreFiltre);
    },
    filtreRecherche: (logic:any, val:string) => {
        const livreFiltre = _.cloneDeep(logic.livreFiltre);
        livreFiltre.recherche = val;
        emprunterStateUpdate.livreFiltre(logic, livreFiltre);
    },
    livresEmprunt: (logic:any, val:LivreEmprunt[]) => logic.setLivresEmprunt(val),
    livresEmpruntSelList: (logic:any, val:LivreEmprunt[]) => logic.setLivresEmpruntSelList(val),
    livreEmpruntSel: (logic:any, val:LivreEmprunt) => logic.setLivreEmpruntSel(val),
    showListContent: (logic:any, val:boolean) => logic.setShowListContent(val),
    openLivreForEmpruntDialogForm: (logic:any, val:boolean) => logic.setOpenLivreForEmpruntDialogForm(val),
    openLivreEmpruntEditDialogForm: (logic:any, val:boolean) => logic.setOpenLivreEmpruntEditDialogForm(val),
    openLivreEmpruntDeleteDialogForm: (logic:any, val:boolean) => logic.setOpenLivreEmpruntDeleteDialogForm(val),
    processingLivreEmpruntStateNormBtn: (logic:any, val:boolean) => logic.setProcessingLivreEmpruntStateNormBtn(val),
    showTableContent: (logic:any, val:boolean) => logic.setShowTableContent(val),
    tableKeyValue: (logic:any, val:any) => logic.setTableKeyValue(val),

}

export default emprunterStateUpdate ;