import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {GroupEnfant, groupEnfantCustomOptions, groupEnfantDataImpl} from "../../../../constants/type/GroupEnfant";
import {Groupesection} from "../../../../constants/type/Groupesection";
import {ClasseRoom} from "../../../../constants/type/ClasseRoom";
import {Profils} from "../../../../constants/type/Profils";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {Fonction} from "../../../../constants/type/Fonction";
import {Child} from "../../../../constants/type/Child";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useGroupEnfantVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);

    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'GroupEnfant', updateId:2, customOptions:groupEnfantCustomOptions}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<GroupEnfant>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const groupesectionsRef = useRef<Groupesection[]>([]);
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());
    const scolariteAnneesRef = useRef<ScolariteAnnee[]>([]);
    const profilsRef = useRef<Profils[]>([]);
    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const childrenRef  = useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());

    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [enfantsOfClasse, setEnfantsOfClasse] = useState<Child[]>([]);
    const [idEnfantsOfGroup, setIdEnfantsOfGroup] = useState<number[]>([]);

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openAddDialogForm, setOpenAddDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const [dataSel, setDataSel] = useState<GroupEnfant>(_.cloneDeep(groupEnfantDataImpl));
    const [idScolariteAnneeSel, setIdScolariteAnneeSel] = useState<number>(0);
    const [idGroupSel, setIdGroupSel] = useState<number>(0);
    const [dataList, setDataList] = useState<readonly GroupEnfant[]>([]);
    const [dataListSelected, setDataListSelected] = useState<readonly GroupEnfant[]>([]);
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList, dataListSelected,setDataListSelected,contentLoaded, setContentLoaded,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,classeRoomRef,classeRoomMapRef,groupesectionsRef,
        profilsRef,scolariteAnneesRef,jobsRef,childrenRef,childrenMapRef,enfantsOfClasse,setEnfantsOfClasse,
        idEnfantsOfGroup, setIdEnfantsOfGroup,idScolariteAnneeSel,setIdScolariteAnneeSel,idGroupSel,setIdGroupSel,
        openAddDialogForm, processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn,
        setOpenAddDialogForm, dataSel, setDataSel,dataCaches,dispatch,
        tableConfigRef,
        ...logicMRT
    }
}
export default useGroupEnfantVariables;