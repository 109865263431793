import {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";

import _ from "lodash";
import {Groupesection, groupesectionDataImpl} from "../../../../constants/type/Groupesection";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useGroupVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'Group', updateId:1725856315000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<Groupesection>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const [dataList, setDataList] = useState<Groupesection[]>([]);
    const [dataListSelected, setDataListSelected] = useState<Groupesection[]>([]);
    const [dataSel, setDataSel] = useState<Groupesection>(_.cloneDeep(groupesectionDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,
        tableConfigRef,
        ...logicMRT
    }
}
export default useGroupVariables;