import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {Person, PERSON_CONSTANT, personDataImpl} from "./Person";
import {FonctionPerson} from "./FonctionPerson";
import {TeacherClasse} from "./TeacherClasse";
import {Fonction} from "./Fonction";
import {AccountEtat} from "./AccountEtat";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";
import {MyBadge} from "../../components/ui/badge/MyBadge";


export const EMPLOYEE_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:Employee) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(employeeDataImpl);
        item =  {...itemInit, ...item} ;

        // @ts-ignore
        item.person      = PERSON_CONSTANT.convertToFrontEnd(logicRacine, item.person);
        item.photoForm   = item.person?.photoForm;

        // @ts-ignore
        item.nameForm     = item.person?.nomPrenom;
        item.fonctionMapForm = new Map<number, Fonction>();
        item.fonctions?.forEach(f => {
            const fonction =  logicRacine.jobsRef.current.get(f?.fonctionId);
            if(_.isObject(fonction)){
                item.userIdForm = f?.userId;
                // @ts-ignore
                item.fonctionMapForm.set(fonction.id, fonction);
                // @ts-ignore
                item.userAccountEtat = f.userAccountEtat ?? undefined  ;
            }
        });


        return item ;
    },
    convertToBackEnd: (item:Employee) => item
};

export  const employeeDataImpl: Employee = {
    id: null,
    person: _.cloneDeep(personDataImpl),
    fonctions: [],
    teacherClasses: [],
    photoForm:'',
    nameForm:'',
    userAccountEtat: AccountEtat.PENDING_VALIDATION,
    userIdForm: null,
    common: _.cloneDeep(commonDataImp),
    fonctionMapForm: new Map<number, Fonction>(),
}


export const employeeSchema = yup.object().shape({
    id: yup.number().nullable(),
    person: yup.mixed<Person>(),
    photoForm: yup.string(),
    nameForm: yup.string(),
    fonctions: yup.array().of(yup.mixed<FonctionPerson>()).nonNullable().default([]),
    teacherClasses: yup.array().of(yup.mixed<TeacherClasse>()).nonNullable().default([]),
    userAccountEtat: yup.mixed<AccountEtat>().oneOf(Object.values(AccountEtat)).default(AccountEtat.OK),
    common: yup.mixed<Common>(),
    userIdForm: yup.number().nullable(),
    fonctionMapForm: yup.mixed<Map<number, Fonction>>().default(new Map<number, Fonction>()),
});

export type Employee = yup.InferType<typeof employeeSchema>;
