import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Grid, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {ScolariteGroup} from "../../../constants/type/ScolariteGroup";
import {NumericFormat} from "react-number-format";
import InputField from "../../../components/ui/form/InputField";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import scolariteEcheancierStateUpdate from "./businesslogic/ScolariteEcheancierStateUpdate";
import {ScolariteEcheancierContext} from "./ScolariteEcheancier";
import {
    EcheancierGroupingDetails,
    echeancierGroupingDetailsDataImpl,
    echeancierGroupingDetailsSchema
} from "../../../constants/type/EcheancierGroupingDetails";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ScolariteEcheancierDetailsEditForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierContext);
    const styleClasses = logic.styleClasses ;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(echeancierGroupingDetailsDataImpl),
        // @ts-ignore
        resolver: yupResolver(echeancierGroupingDetailsSchema),
    }));
    const {errors} = formRef.current.formState;

    
    const updateLaDate = (data:EcheancierGroupingDetails, ladate: moment.Moment):EcheancierGroupingDetails => {
        data.ladateForm = ladate;
        data.ladate = data.ladateForm?.valueOf();
        data.liredateForm =  data.ladateForm?.clone().format('L');

        return data ;
    }
    const handleLaDateChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:EcheancierGroupingDetails = _.cloneDeep(logic.dataDetailsSel);
        data = updateLaDate(data, newday);
        scolariteEcheancierStateUpdate.dataDetailsSel(logic, data);
        formRef.current.setValue('ladate', data.ladate);
        formRef.current.setValue('ladateForm', data.ladateForm);
        formRef.current.setValue('liredateForm', data.liredateForm);
    };

    const handleMontantChange = (values: any) => {
        let data:ScolariteGroup = _.cloneDeep(logic.dataDetailsSel);
        data.montant = values.floatValue;

        scolariteEcheancierStateUpdate.dataDetailsSel(logic, data);
        formRef.current.setValue('montant', data.montant);
    }

    const onSubmit = async (data: FormData) => {
        let scolaEcheancierDetails = _.cloneDeep(logic.scolaEcheancierDetails);
        // @ts-ignore
        const index = _.findIndex(scolaEcheancierDetails, {id:data.id});
        if(index >= 0){
            // @ts-ignore
            data.id = logic.scolaEcheancierDetails[index].id;
            scolaEcheancierDetails[index] = _.cloneDeep(data) ;
        } else {
            // @ts-ignore
            data.id = (scolaEcheancierDetails.length + 1) * -1;
            scolaEcheancierDetails.push(data);
        }
        scolaEcheancierDetails = _.sortBy(scolaEcheancierDetails, ['ladate'])
        // @ts-ignore
        scolariteEcheancierStateUpdate.scolaEcheancierDetails(logic, scolaEcheancierDetails);
        scolariteEcheancierStateUpdate.tableDetailsKeyValue(logic, new Date().getTime());

        const dataReset = _.cloneDeep(echeancierGroupingDetailsDataImpl);
        formRef.current.reset(dataReset);
        scolariteEcheancierStateUpdate.dataDetailsSel(logic, dataReset);
    };

    useEffect(() => {
        if(logic.openEditDetailsDialogForm === false){
            const data = _.cloneDeep(echeancierGroupingDetailsDataImpl);
            scolariteEcheancierStateUpdate.dataDetailsSel(logic, data);
            formRef.current.reset(data);
            return ;
        }
        formRef.current.reset(_.cloneDeep(logic.dataDetailsSel));
    }, [logic.openEditDetailsDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            title={<FormattedMessage id={messages.selection_edition} />}
            open={logic.openEditDetailsDialogForm}
            setOpen={logic.setOpenEditDetailsDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
                <Box  sx={{ width: '100%' }}>
                    <Grid container spacing={2}>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.schooling_echeance_label} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.dataDetailsSel.nom}
                                    inputProps={{
                                        ...formRef.current.register("nom")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.header_code} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.dataDetailsSel.code}
                                    inputProps={{
                                        ...formRef.current.register("code")
                                    }}
                                />
                            </div>
                        </Grid>


                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.schooling_period_payment} />}
                                        value={logic.dataDetailsSel.ladateForm}
                                        //@ts-ignore
                                        onChange={handleLaDateChange}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.inputField}>
                                <NumericFormat
                                    label={<FormattedMessage id={messages.schooling_amount} />}
                                    style={{alignItems:'right', alignContent:'right', textAlign:'right'}}
                                    value={logic.dataDetailsSel.montant}
                                    customInput={TextField}
                                    thousandSeparator={' '}
                                    onValueChange={(values, sourceInfo) => {
                                        handleMontantChange(values);
                                    }}/>
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.montant && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                    </Grid>

                </Box>
        </DialogFormKiller>
    );
}

export default ScolariteEcheancierDetailsEditForm ;