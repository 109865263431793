import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    Icon,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {
    LectureRdv,
    lectureRdvDataImpl,
    Rdv,
    RDV_CONSTANT,
    rdvNormalDataImpl,
    rdvSchema
} from "../../constants/type/Rdv";
import React, {useCallback, useContext, useEffect} from "react";
import {AppointmentPresetContext} from "./Appointment";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import InputField from "../../components/ui/form/InputField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import DialogForm from "../../components/ui/form/DialogFormKiller";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import _ from "lodash";
import {RdvEtat} from "../../constants/type/RdvEtat";
import colorfull from "../../data/colorfull";
import classNames from "classnames";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {Child, childDataImpl} from "../../constants/type/Child";
import {Employee, employeeDataImpl} from "../../constants/type/Employee";
import Typography from "@mui/material/Typography";
import {EnfantParent} from "../../constants/type/EnfantParent";
import rdvNormalLectureBusinessLogic, {employeeNormalToggle} from "./businesslogic/RdvNormalLectureBusinessLogic";
import {RoleUser} from "../../constants/type/RoleUser";
import {Fonction} from "../../constants/type/Fonction";
import rdvNormalBusinessLogic from "./businesslogic/RdvNormalBusinessLogic";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import rdvBusinessLogic from "./businesslogic/RdvBusinessLogic";
import {isPositiveValue} from "../../helpers/helpers";
import {getErrorMessage} from "../../helpers/errorManager";
import {creneauRdvDataImp} from "../../constants/type/CreneauRdv";
import {creneauRdvEnfantParentDataImp} from "../../constants/type/CreneauRdvEnfantParent";
import {creneauRdvEmployeeDataImp} from "../../constants/type/CreneauRdvEmployees";


const color = {
    main: colorfull[2],
    secondary: colorfull[3],
    third: colorfull[0],
    fourth: colorfull[1],
    fifth: colorfull[6],
};


const NormalForm = () => {
    const logicRacine: any = useContext(AppointmentPresetContext);
    const styleClasses = logicRacine.styleClasses ;
    const intl = useIntl() ;
    const [employeeList, setEmployeeList] = React.useState<Employee[]>([]);


    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logicRacine.rdvEdit),
        // @ts-ignore
        resolver: yupResolver(rdvSchema),
    }));
    const {errors} = formRef.current.formState;
    const disableWeekends = (date:moment.Moment) => {
        return date.day() === 0 || date.day() === 6;
    }


    const editableConfig = (item: Rdv) => {
        rdvStateUpdate.editDisableNorm(logicRacine, rdvNormalBusinessLogic.editeNormalDisableChecking(logicRacine, item));
        rdvStateUpdate.isFormDisableForCurrentUserRef(logicRacine, rdvNormalBusinessLogic.isFormDisableForCurrentUserChecking(logicRacine, item));
    }  ;

    const updateEmployeeMeetingStatus = (value: RdvEtat) => rdvNormalBusinessLogic.updateEmployeeMeetingStatus(logicRacine, rdvNormalBusinessLogic.editeNormalDisableChecking(logicRacine, value));


    const handleEmployeeMeetingStatusChange = useCallback((event: React.MouseEvent<HTMLElement>,     value: any) => {
        rdvNormalBusinessLogic.handleEmployeeMeetingStatusChange(logicRacine,  value);
            return () => {};
        }, [logicRacine.creneauRdvEmployeeNorm]
    );
    const handling = {handleEmployeeMeetingStatusChange};

    const statusBeforeMeetingViewConfig = (item: Rdv) => rdvNormalBusinessLogic.statusBeforeMeetingViewConfig(logicRacine, item)
    const statusAfterMeetingViewConfig = (item: Rdv) =>  rdvNormalBusinessLogic.statusAfterMeetingViewConfig(logicRacine,item)  ;

    const setChild = (item:Child | null) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(childDataImpl);
        }
        rdvStateUpdate.childNormSelected(logicRacine, item);
        formRef.current.setValue('enfantIdForm', item?.id);
    }
    const updateChildList = (item:Employee | null | undefined) => {
        setChild(null);
        let childs : Child[] = [] ;
        if(_.isNull(item) || _.isUndefined(item)){
            rdvStateUpdate.childNormList(logicRacine,childs);
            return ;
        }
        let isAdmin = false ;
        let isTeacher = false ;
        let classeId = 0 ;

        item.fonctionMapForm.forEach((fonction: Fonction, key: number) => {
            if(fonction.role === RoleUser.ADMIN){
                isAdmin = true ;
            }
            if(fonction.role === RoleUser.TEACHER){
                isTeacher = true ;
                classeId = item.teacherClasses[0]?.classeId ?? 0 ;
            }
        });
        if(isAdmin){
            childs = _.cloneDeep(logicRacine.childrenClasseRef.current);
            rdvStateUpdate.childNormList(logicRacine,childs);
            return ;
        }
        if(isTeacher && classeId > 0){
            childs  = _.filter(logicRacine.childrenClasseRef.current, function(item) {
                if(_.isNull(item.eleves)){
                    return false ;
                }
                // @ts-ignore
                return _.findIndex(item.eleves, function(o) { return o.classe.id == classeId; }) >= 0;
            });
            rdvStateUpdate.childNormList(logicRacine,childs);
            return ;
        }
        rdvStateUpdate.childNormList(logicRacine,childs);
    }
    const updateEmployee = (item:Employee | null) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(employeeDataImpl);
        }
        rdvStateUpdate.employeeNormSelected(logicRacine, item );
        formRef.current.setValue('employeeIdForm', item?.id, {shouldValidate: true});

        //Pas de mise à jour du choix de l'enfant si l'utilisateur n'a pas initié le RDV
        if(isPositiveValue(logicRacine.rdvEdit.id) && logicRacine.rdvEdit.userInitor !== logicRacine.userRef.current.idd){
            return ;
        }
        rdvStateUpdate.childNormDisable(logicRacine, !isPositiveValue(item?.id));
        updateChildList(item);
    }
    const handleChildChange = (event: React.SyntheticEvent, value: Child) => setChild(value);
    const handleEmployeeChange = (event: React.SyntheticEvent, value: Employee) => updateEmployee(value);






    const handleTimeChange = (event: React.MouseEvent<HTMLElement>,     value: any) => rdvNormalBusinessLogic.handleTimeChange(logicRacine, formRef, value);
    const handleDayChange = (value: moment.Moment,     context: any) => rdvNormalBusinessLogic.handleDayChange(logicRacine, formRef, value);

    const handleClickDeleteRdv = () => rdvNormalBusinessLogic.handleClickDeleteRdv(logicRacine);
    const onSubmit = async (data: FormData) => {
        rdvStateUpdate.savingStateNormBtn(logicRacine, true);
        // @ts-ignore
        const rdvToSend: Rdv = _.cloneDeep(data);
        const creneaurdvemployee = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
        creneaurdvemployee.employeeId = logicRacine.employeeNormSelected?.id ?? 0;
        creneaurdvemployee.fonctionEmployeeId = logicRacine.employeeNormSelected?.fonctions[0]?.fonctionId ?? 0;

        if(!isPositiveValue(creneaurdvemployee.employeeId)){
            const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please select a teacher` : `Veuillez sélectionner une éducatrice/un éducateur`;
            logicRacine.snackbarShowMessage(msg, 'warning');
            return ;
        }
        if(!isPositiveValue(logicRacine.childNormSelected?.id)){
            const msg = logicRacine.activeLanguageRef.current[0] === 'en' ? `Please select a child` : `Veuillez sélectionner un enfant`;
            logicRacine.snackbarShowMessage(msg, 'warning');
            return ;
        }

        // @ts-ignore
        rdvToSend.creneauRdvs[0].creneauRdvEmployees[0] = creneaurdvemployee;
        // @ts-ignore
        rdvToSend.creneauRdvs[0].creneauRdvEnfantParents[0].enfantId = logicRacine.childNormSelected?.id ?? 0;
        // @ts-ignore
        rdvToSend.creneauRdvs[0].creneauRdvEnfantParents[0].classeId = logicRacine.childNormSelected?.eleves[0]?.classe?.id ?? 0;
        /*
        if(_.isNull(logicRacine.creneauRdvEmployeeNorm) === false && _.isUndefined(logicRacine.creneauRdvEmployeeNorm) === false){
            // @ts-ignore
            rdvToSend.creneauRdvs[0].creneauRdvEmployees[0] = logicRacine.creneauRdvEmployeeNorm;
        }

        if(isPositiveValue(rdvToSend.id)){
            const creneaurdvemployee = _.cloneDeep(logicRacine.creneauRdvEmployeeNorm);
            creneaurdvemployee.employeeId = logicRacine.employeeNormSelected?.id ?? 0;
            creneaurdvemployee.fonctionEmployeeId = logicRacine.employeeNormSelected?.fonctions[0]?.fonctionId ?? 0;

            // @ts-ignore
            rdvToSend.creneauRdvs[0].creneauRdvEmployees[0] = creneaurdvemployee;
            // @ts-ignore
            rdvToSend.creneauRdvs[0].creneauRdvEnfantParents[0].enfantId = logicRacine.childNormSelected?.id ?? 0;
        }*/

        // @ts-ignore
        const toSend = RDV_CONSTANT.convertToBackEnd(rdvToSend);
        rdvBusinessLogic.saveRdv(
            logicRacine,
            messages,
            toSend).then((response:any) => {
            rdvStateUpdate.openNormalDialogForm(logicRacine, false);
            rdvStateUpdate.savingStateNormBtn(logicRacine, false);
        }).catch((e:any) => rdvStateUpdate.savingStateNormBtn(logicRacine, false));
    };


    const loadRdv = (logicRacine:any) => {

        return new Promise<boolean>(resolve =>
            rdvBusinessLogic.loadRdv(logicRacine, logicRacine.rdvEdit.id).then((data:any) => {
                const item : Rdv   = RDV_CONSTANT.convertToFrontEnd(logicRacine, data);
                if(_.isNull(item) || _.isUndefined(item)){
                    return ;
                }
                rdvStateUpdate.rdvEdit(logicRacine, item);
                // @ts-ignore
                logicRacine.eventCreneauSelectedRef.current = item.creneauRdvs[0];

                logicRacine.parentNormRef.current = null ;
                logicRacine.creneauRdvEnfantParentNormRef.current = null ;

                const dayform = item.startForm.clone().startOf('day');
                rdvStateUpdate.dayNormForm(logicRacine, dayform);


                const creneauemployer = logicRacine.eventCreneauSelectedRef.current.creneauRdvEmployees[0] ;
                const crenoparent = logicRacine.eventCreneauSelectedRef.current.creneauRdvEnfantParents[0]  ;
                let employeFound: Employee | null = null ;

                if(_.isNull(creneauemployer) === false && _.isUndefined(creneauemployer) === false){
                    const crenodayform = creneauemployer.startForm.clone().startOf('day');
                    rdvStateUpdate.crenoDayEmployeeNorm(logicRacine, crenodayform);

                    const employeeId : number | null = creneauemployer?.employeeId ?? null ;
                    if(isPositiveValue(employeeId)){
                        employeFound = _.find(logicRacine.employeesRef.current, {id:employeeId}) ?? null;
                    }
                    if(_.isNull(employeFound) &&
                        logicRacine.isTeacherRef.current &&
                        crenoparent.classeId === logicRacine.teacherRef.current?.teacherClasses[0]?.classeId){
                        employeFound = _.cloneDeep(logicRacine.teacherRef.current);
                    }

                    if(_.isObject(employeFound)){
                        updateEmployee(employeFound);
                        rdvStateUpdate.creneauRdvEmployeeNorm(logicRacine, _.cloneDeep(creneauemployer));
                    }
                }


                if(_.isNull(crenoparent) === false && _.isUndefined(crenoparent) === false){
                    rdvStateUpdate.creneauRdvEnfantParentNormRef(logicRacine, crenoparent);
                    const childId : number | null = crenoparent.enfantId ?? null ;
                    if(isPositiveValue(childId)){
                        const child = _.find(logicRacine.childrenRef.current, {id:childId});
                        setChild(child);
                        child?.enfantParents?.forEach((item:EnfantParent) => {
                            if(item.parent?.id !== logicRacine.creneauRdvEnfantParentNormRef.current?.parentId){
                                return ;
                            }
                            rdvStateUpdate.parentNormRef(logicRacine, _.cloneDeep(item.parent));
                        });

                    }
                }


                editableConfig(item);
                statusBeforeMeetingViewConfig(item);
                statusAfterMeetingViewConfig(item);
                rdvStateUpdate.startNormForm(logicRacine, item.startForm);
                rdvStateUpdate.endNormForm(logicRacine, item.endForm);
                resolve(true);
            }).catch((error:any) => {
                logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    };

    const updateEmployeeList = () => {

    }
    useEffect(() => {
        if( logicRacine.openNormalDialogForm === false){
            rdvStateUpdate.showContentNorm(logicRacine, false);
            const newrdv = _.cloneDeep(rdvNormalDataImpl);

            updateEmployee(null);
            setChild(null);
            rdvStateUpdate.parentNormRef(logicRacine, null);
            rdvStateUpdate.creneauRdvEnfantParentNormRef(logicRacine, _.cloneDeep(creneauRdvEnfantParentDataImp));

            editableConfig(newrdv);
            statusBeforeMeetingViewConfig(newrdv);
            statusAfterMeetingViewConfig(newrdv);
            rdvStateUpdate.savingStateNormBtn(logicRacine, false);
            rdvStateUpdate.startNormForm(logicRacine, newrdv.startForm);
            rdvStateUpdate.endNormForm(logicRacine, newrdv.endForm);
            rdvStateUpdate.dayNormForm(logicRacine, newrdv.startForm.clone().startOf('day'));
            formRef.current.reset(newrdv);
            return ;
        }

        if(logicRacine.newNormalRef.current){
            setEmployeeList(_.cloneDeep(logicRacine.teachersListRef.current));
            rdvStateUpdate.showContentNorm(logicRacine, true);
            rdvStateUpdate.childNormDisable(logicRacine, true);
            rdvStateUpdate.creneauRdvEmployeeNorm(logicRacine, _.cloneDeep(creneauRdvEmployeeDataImp));
            if(logicRacine.isTeacherRef.current){
                updateEmployee(logicRacine.teacherRef.current);
            }
            return ;
        }
        loadRdv(logicRacine).then(value => {
            let employees: Employee[] =  _.cloneDeep(logicRacine.teachersListRef.current);

            const crenoRdv = logicRacine.rdvEdit.creneauRdvs[0] ?? _.cloneDeep(creneauRdvDataImp);
            const crenEnfantParent = crenoRdv.creneauRdvEnfantParents[0] ?? _.cloneDeep(creneauRdvEnfantParentDataImp);
            if(isPositiveValue(crenEnfantParent?.enfantId)){
                if(isPositiveValue(crenEnfantParent.classeId)) {
                    employees = _.filter(_.cloneDeep(employees), (item) => item?.teacherClasses[0]?.classeId === crenEnfantParent.classeId );
                }
            }
            setEmployeeList(employees);
            rdvStateUpdate.showContentNorm(logicRacine, true);
        }).catch(e => rdvStateUpdate.showContentNorm(logicRacine, true));


        return () => {
            updateEmployee(null);
            setChild(null);
            rdvStateUpdate.parentNormRef(logicRacine, null);
            rdvStateUpdate.creneauRdvEnfantParentNormRef(logicRacine, null);
            formRef.current.reset(_.cloneDeep(rdvNormalDataImpl));
        };
    }, [logicRacine.openNormalDialogForm]);

    useEffect(() => {
        formRef.current.reset(logicRacine.rdvEdit);
        return () => {};
    }, [logicRacine.rdvEdit]);

    useEffect(() => {
        const rdv = logicRacine.rdvEdit ;
        const creneauRdv                = logicRacine.eventCreneauSelectedRef.current ;
        const creneauRdvEmployee        = logicRacine.creneauRdvEmployeeNorm ;
        const creneauRdvEnfantParent    = _.cloneDeep(logicRacine.creneauRdvEnfantParentNormRef.current)  ;

        let lectureRdv:LectureRdv = _.cloneDeep(lectureRdvDataImpl);
        lectureRdv = rdvNormalLectureBusinessLogic.lire(
            logicRacine,
            rdv,
            creneauRdv,
            creneauRdvEmployee,
            creneauRdvEnfantParent,
            lectureRdv);
        rdvStateUpdate.lectureRdvNorm(logicRacine, lectureRdv);
        return () => {};
    }, [logicRacine.rdvEdit, logicRacine.creneauRdvEmployeeNorm]);

    // @ts-ignore
    return (
        <DialogForm
            theme={logicRacine.theme}
            width={true}
            savingBtn={true}
            savingStateBtn={logicRacine.savingStateNormBtn}
            title={logicRacine.lectureRdvNorm.title}
            open={logicRacine.openNormalDialogForm}
            setOpen={logicRacine.setOpenNormalDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>

            {
                !logicRacine.showContentNorm &&
                <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                    <CircularProgress color="primary" />
                </Box>

            }
            {
                logicRacine.showContentNorm &&
                <Box  sx={{ width: '100%' }}>
                    {
                        isPositiveValue(logicRacine.rdvEdit.id) &&
                        <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                            <FormattedMessage id={messages[logicRacine.lectureRdvNorm.subtitle]} />
                        </div>
                    }
                    {
                        !_.isNull(logicRacine.childNormSelected) &&
                        <div className={styleClasses.inputField} style={{marginBottom:'25px'}}>
                            <div style={{width:60, margin:'auto'}}>
                                <Avatar className={styleClasses.customAvatar}
                                        src={logicRacine.childNormSelected?.childphotoForm}
                                        sx={{width:56, height:56}}
                                />
                            </div>
                        </div>
                    }
                    <div className={styleClasses.inputField}>
                        <InputField
                            label={<FormattedMessage id={messages.title_appointment} />}
                            size="small"
                            color={"secondary"}
                            width={'100% !important'}
                            disabled={logicRacine.editDisableNorm}
                            inputProps={
                                {
                                    ...formRef.current.register("objet", {
                                        required: "Required field"
                                    })
                                }
                            }
                        />
                        <div className={styleClasses.errorHelper}>
                            {errors.objet && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <div className={classNames(styleClasses.inputField, styleClasses.autoCompleteInput)}>
                                <Autocomplete
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            fontWeight: 'normal',
                                        }
                                    }}
                                    disabled={logicRacine.editDisableNorm || logicRacine.isTeacherRef.current}
                                    options={employeeList}
                                    value={logicRacine.employeeNormSelected}
                                    autoHighlight
                                    getOptionLabel={(opt:any) => opt.nameForm}
                                    isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                    // @ts-ignore
                                    groupBy={(opt:any) => logicRacine.jobsRef.current.get(opt.fonctions?.[0]?.fonctionId)?.nom ?? ''}
                                    loading={false}
                                    onChange={handleEmployeeChange}
                                    renderOption={(props, item:any) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                            <Avatar alt="avatar"  src={item.photoForm} sx={{mr: 2}}/>
                                            {item.nameForm}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={<FormattedMessage id={messages.choose_interlocutor} />}
                                            color="secondary"
                                            variant="outlined"
                                            required
                                            sx={{marginTop: 0.4, width: '100% !important'}}
                                            size="small"
                                            placeholder={logicRacine.searchPlaceholderNormRef.current}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />{/*
                                <div className={styleClasses.errorHelper}>
                                    {errors.employeeIdForm && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                </div>*/}
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div className={classNames(styleClasses.inputField, styleClasses.autoCompleteInput)}>
                                <Autocomplete
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            fontWeight: 'normal',
                                        }
                                    }}
                                    disabled={logicRacine.editDisableNorm || logicRacine.childNormDisable}
                                    options={logicRacine.childNormList}
                                    value={logicRacine.childNormSelected}
                                    autoHighlight
                                    getOptionLabel={(opt: any) => opt.childNomPrenomForm}
                                    isOptionEqualToValue={(opt: any, value: any) => opt.id === value.id}
                                    // @ts-ignore
                                    groupBy={(opt: any) => opt.eleves?.[0]?.classe.nom ?? ''}
                                    loading={false}
                                    onChange={handleChildChange}
                                    renderOption={(props, opt: any) => (
                                        <Box component="li"
                                             sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                            <Avatar alt="avatar" src={opt.childphotoForm} sx={{mr: 2}}/>
                                            {opt.childNomPrenomForm}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={<FormattedMessage id={messages.choose_child}/>}
                                            required
                                            color="secondary"
                                            variant="outlined"
                                            sx={{marginTop: 0.4, width: '100% !important'}}
                                            size="small"
                                            placeholder={logicRacine.searchPlaceholderNormRef.current}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />
                                <div className={styleClasses.errorHelper}>
                                    {errors.enfantIdForm &&
                                        <span><FormattedMessage id={messages.required_field_child}/></span>}
                                </div>
                            </div>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.classroom_child}/>}
                                    size="small"
                                    color={"secondary"}
                                    width={'100% !important'}
                                    value={logicRacine.childNormSelected?.eleves[0]?.classe?.nom ?? ``}
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div className={styleClasses.inputField}>
                        <InputField
                            label={`${intl.formatMessage({id: messages.type_message})} (550 characters max)`}
                            color={"secondary"}
                            size="small"
                            disabled={logicRacine.editDisableNorm}
                            required
                            InputProps={{
                                rows: 2,
                                multiline: true,
                                inputComponent: 'textarea',
                            }}
                            inputProps={{
                                maxLength: 550,
                                ...formRef.current.register("details")
                            }}
                        />
                        <div className={styleClasses.errorHelper}>
                            {errors.details && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={6}>
                            <div className={styleClasses.inputField} style={{width:'100%', marginTop:10}}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.day} />}
                                        value={logicRacine.dayNormForm}
                                        //@ts-ignore
                                        onChange={handleDayChange}
                                        shouldDisableDate={disableWeekends}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        minDate={moment().startOf('day')}
                                        disabled={logicRacine.editDisableNorm}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>

                        <Grid item md={5} xs={6}>
                            <div  style={{width:'100%', paddingTop:0, marginTop:0}}>
                                <span style={{fontSize:'11px', color:'gray', fontWeight:600}}><FormattedMessage id={messages.hour}  /> </span>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={moment.duration({hours:logicRacine.startNormForm?.hours() ?? 0, minutes:logicRacine.startNormForm?.minutes() ?? 0}).asMilliseconds()}
                                    exclusive
                                    onChange={handleTimeChange}
                                    aria-label="Platform"
                                    sx={{width:'100%'}}
                                    disabled={logicRacine.editDisableNorm}
                                >
                                    <ToggleButton value={logicRacine.rdvNormalTimeRef.current[0]} sx={{width:'33%'}}>
                                        {moment(logicRacine.rdvNormalTimeRef.current[0]).format('LT')}
                                    </ToggleButton>
                                    <ToggleButton value={logicRacine.rdvNormalTimeRef.current[1]} sx={{width:'34%'}}>
                                        {moment(logicRacine.rdvNormalTimeRef.current[1]).format('LT')}
                                    </ToggleButton>
                                    <ToggleButton value={logicRacine.rdvNormalTimeRef.current[2]} sx={{width:'33%'}}>
                                        {moment(logicRacine.rdvNormalTimeRef.current[2]).format('LT')}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                        </Grid>
                        <Grid item md={3} xs={6}>
                            <div className={styleClasses.inputField} style={{width:'100%', marginTop:10}}>
                                <TextField
                                    label={<FormattedMessage id={messages.deadline_rdv} />}
                                    type="number"
                                    color={"secondary"}
                                    disabled={logicRacine.editDisableNorm}
                                    sx={{width:'100%'}}
                                    inputProps={{
                                        min: 0,
                                        step: 1,
                                        ...formRef.current.register("deadlineUpdateForm")
                                    }}
                                />
                            </div>
                        </Grid>
                       {/* <Grid item md={3} xs={6}>
                            <div className={styleClasses.inputField}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.start_hour} />}
                                        value={logicRacine.startForm}
                                        onChange={logicRacine.handleStartDateChange}
                                        views={['hours', 'minutes']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        minDateTime={moment().startOf('day')}
                                        disabled={logicRacine.editDisableNorm}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <div className={styleClasses.inputField}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.end_hour} />}
                                        value={logicRacine.endForm}
                                        //@ts-ignore
                                        onChange={handleEndDateChange}
                                        views={['hours', 'minutes']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        minDateTime={logicRacine.startForm}
                                        disabled={logicRacine.editDisableNorm}
                                    />
                                </LocalizationProvider>

                                <div className={styleClasses.errorHelper}>
                                    {errors.endForm && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                </div>
                            </div>
                        </Grid>*/}
                    </Grid>
                    {
                        logicRacine.lectureRdvNorm.showDeleteBtn &&
                        <div className={styleClasses.inputField}>
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{textTransform: 'none', marginTop:0.5, marginRight: { sm: 2 } }}
                                    onClick={handleClickDeleteRdv}
                            >
                                <Icon className={styleClasses.actionIcon}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>
                        </div>
                    }
                    <MeetingParticpantsActions
                        logicRacine={logicRacine}
                        styleClasses={styleClasses}
                    />
                    {/*<Box  sx={{ width: '100%' }}>

                </Box>*/}
                    {/* <Grid container spacing={2}>




                </Grid>
                */}
                </Box>
            }

        </DialogForm>
    );
}

const MeetingParticpantsActions = (props:any) => {
    const {logicRacine, styleClasses} = props;
    if( logicRacine.lectureRdvNorm.parentOutcomeShow === false && logicRacine.lectureRdvNorm.employeeOutcomeShow === false){
        return <></>;
    }


    return  <Grid container spacing={6}>
                {
                    logicRacine.lectureRdvNorm.parentOutcomeShow &&
                    <Grid item md={logicRacine.lectureRdvNorm.employeeOutcomeShow ? 6 : 12} xs={12}>
                        <ParentMeetingAction 
                            logicRacine={logicRacine} 
                            styleClasses={styleClasses} />
                    </Grid>
                }
                {
                    logicRacine.lectureRdvNorm.employeeOutcomeShow &&
                    <Grid item md={logicRacine.lectureRdvNorm.parentOutcomeShow ? 6 : 12} xs={12}>
                        <EmployeeMeetingAction
                            {...props} />
                        <Typography className={styleClasses.titleUserInfo} variant="subtitle1">
                            <FormattedMessage id={messages[logicRacine.lectureRdvNorm.employeeStatusLibelle]} />
                        </Typography>
                        {
                            employeeNormalToggle({'component':props.logicRacine.lectureRdvNorm.employeeToggle, ...props})

                        }
                    </Grid>
                }
           </Grid>
}

const EmployeeMeetingAction = (props:any) => {
    const {logicRacine, styleClasses} = props;

    const handleEmployeeEndDateChange = (value: moment.Moment, context: any) => rdvNormalBusinessLogic.handleEmployeeEndDateChange(logicRacine, value);
    const handleCrenoDayChange = (value: moment.Moment,     context:any) => rdvNormalBusinessLogic.handleCrenoDayChange(logicRacine, value);
    const handleEmployeeCommentChange = (event:any) =>  rdvNormalBusinessLogic.handleEmployeeCommentChange(logicRacine, event.target.value);
    const handleEmployeeStartDateChange = (event: React.MouseEvent<HTMLElement>,  value: any) => rdvNormalBusinessLogic.handleEmployeeStartDateChange(logicRacine, value);



    if(_.isNull(logicRacine.employeeNormSelected) || _.isUndefined(logicRacine.employeeNormSelected)){
        return <></>;
    }
    return <Box  sx={{ width: '100%' }}>
            {
                logicRacine.statusBeforeMeetingViewNormRef.current &&
                <Grid container spacing={2} sx={{pt:3}}>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.title}>
                            <Typography variant="subtitle1">
                                {logicRacine.employeeNormSelected.person.nom} {logicRacine.employeeNormSelected.person.prenom}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField} >
                            <FormControl
                                sx={{display: { xs: 'block', sm: 'inline-block' }}}
                                size="small"
                                color={"secondary"}
                            >
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.day} />}
                                        value={logicRacine.creneauRdvEmployeeNorm.startForm}
                                        // @ts-ignore
                                        onChange={handleCrenoDayChange}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        minDate={moment()}
                                        disabled={logicRacine.isFormDisableForCurrentUserRef.current || logicRacine.statusAfterMeetingViewNormRef.current}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <span style={{fontSize:'11px', color:'gray', fontWeight:600}}><FormattedMessage id={messages.hour}  /> </span>
                            <ToggleButtonGroup
                                color="primary"
                                value={moment.duration({hours:logicRacine.creneauRdvEmployeeNorm.startForm?.hours() ?? 0, minutes:logicRacine.creneauRdvEmployeeNorm.startForm?.minutes() ?? 0}).asMilliseconds()}
                                exclusive
                                onChange={handleEmployeeStartDateChange}
                                aria-label="Platform"
                                sx={{width:'100%'}}
                                disabled={logicRacine.isFormDisableForCurrentUserRef.current || logicRacine.statusAfterMeetingViewNormRef.current}
                            >
                                <ToggleButton value={logicRacine.rdvNormalTimeRef.current[0]} sx={{width:'33%'}}>
                                    {moment(logicRacine.rdvNormalTimeRef.current[0]).format('LT')}
                                </ToggleButton>
                                <ToggleButton value={logicRacine.rdvNormalTimeRef.current[1]} sx={{width:'34%'}}>
                                    {moment(logicRacine.rdvNormalTimeRef.current[1]).format('LT')}
                                </ToggleButton>
                                <ToggleButton value={logicRacine.rdvNormalTimeRef.current[2]} sx={{width:'33%'}}>
                                    {moment(logicRacine.rdvNormalTimeRef.current[2]).format('LT')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {/*<FormControl
                                sx={{
                                    display: { xs: 'block', sm: 'inline-block' },
                                    marginBottom: { xs: 2},
                                    marginRight: { sm: 2 },
                                }}
                                size="small"
                                color={"secondary"}
                            >
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.start_day} />}
                                        value={logicRacine.creneauRdvEmployeeNorm.startForm}
                                        // @ts-ignore
                                        onChange={handleEmployeeStartDateChange}
                                        views={['hours', 'minutes']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        disabled={logicRacine.statusAfterMeetingViewNormRef.current}
                                    />
                                </LocalizationProvider>
                            </FormControl>*/}
                        </div>
                    </Grid>

                </Grid>
            }
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <div className={styleClasses.inputField}>
                        <InputField
                            label={<FormattedMessage id={messages.note} />}
                            size="small"
                            color={"secondary"}
                            width={'100% !important'}
                            placeholder={logicRacine.activeLanguageRef.current[0] === 'en' ? 'Comment' : 'Commentaire'}
                            // @ts-ignore
                            onChange={handleEmployeeCommentChange}
                            disabled={logicRacine.isFormDisableForCurrentUserRef.current}
                            value={logicRacine.creneauRdvEmployeeNorm.commentaire}
                        />
                    </div>
                </Grid>
            </Grid>
    </Box>;
}

const ParentMeetingAction = (props:any) => {
    const {logicRacine, styleClasses} = props;

    if(_.isNull(logicRacine.parentNormRef.current) || _.isUndefined(logicRacine.parentNormRef.current)){
        return <></> ;
    }
    if(_.isNull(logicRacine.parentNormRef.current.person) || _.isUndefined(logicRacine.parentNormRef.current)){
        return <></> ;
    }
    return <Box sx={{width:'100%'}}>
        <div className={styleClasses.title} style={{marginBottom:'25px'}}>
            <Typography variant="subtitle1">
                Parent {logicRacine.parentNormRef.current.person.nom} {logicRacine.parentNormRef.current.person.prenom}
            </Typography>
            <Typography component="span" variant="inherit">
                Phone: {logicRacine.parentNormRef.current.person.phone1 ?? logicRacine.parentNormRef.current.person.phone2 ?? ''}
            </Typography>
        </div>
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <div className={styleClasses.inputField}>
                    <FormControl
                        sx={{
                            display: { xs: 'block', sm: 'inline-block' },
                            marginBottom: { xs: 2},
                            marginRight: { sm: 2 },
                        }}
                        size="small"
                        color={"secondary"}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.day} />}
                                value={logicRacine.creneauRdvEnfantParentNormRef.current.startForm}
                                views={['year', 'month', 'day']}
                                slotProps={{ textField: { color: 'secondary' } }}
                                disabled
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
            </Grid>
            <Grid item md={6} xs={12}>
                <div className={styleClasses.inputField}>
                    <FormControl
                        sx={{
                            display: { xs: 'block', sm: 'inline-block' },
                            marginBottom: { xs: 2},
                            marginRight: { sm: 2 },
                        }}
                        size="small"
                        color={"secondary"}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.start_hour} />}
                                value={logicRacine.creneauRdvEnfantParentNormRef.current.startForm}
                                views={['hours', 'minutes']}
                                slotProps={{ textField: { color: 'secondary' } }}
                                disabled
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
            </Grid>
            <Grid item md={6} xs={12}>
                <div className={styleClasses.inputField}>
                    <FormControl
                        sx={{
                            display: { xs: 'block', sm: 'inline-block' },
                            marginBottom: { xs: 2},
                            marginRight: { sm: 2 },
                        }}
                        size="small"
                        color={"secondary"}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logicRacine.activeLanguageRef.current[0]}>
                            <MobileDateTimePicker
                                label={<FormattedMessage id={messages.end_hour} />}
                                value={logicRacine.creneauRdvEnfantParentNormRef.current.endForm}
                                views={['hours', 'minutes']}
                                slotProps={{ textField: { color: 'secondary' } }}
                                disabled
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
            </Grid>
        </Grid>


        <Typography className={styleClasses.titleUserInfo} variant="subtitle1">
            <FormattedMessage id={messages[logicRacine.lectureRdvNorm.parentStatusLibelle]} />
        </Typography>
    </Box> ;
}


export default NormalForm ;
