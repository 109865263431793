import React from 'react';
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import messages from "../messages/general/messages";
import {FormattedMessage} from "react-intl";

function SchoolingSubMenu(props:any) {
    const {classes, selected} = props;

    return (
        <>
            <Button
                className={classNames(classes.button, selected === 'addpayment' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                //color="secondary"
                component={Link} to="/app/schooling/payment"
                startIcon={<AddIcon/>}
            >
                <FormattedMessage id={messages.schooling_add_payment} />
            </Button>

           <Button
                className={classNames(classes.button, selected === 'paymentlist' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/payment-list"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.schooling_payment_list} />
            </Button>
            <Button
                className={classNames(classes.button, selected === 'proforma' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/proforma"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.schooling_proforma_invoice} />
            </Button>
            {/*<Button
                className={classNames(classes.button, selected === 'proforma' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/proforma"
                startIcon={<ListIcon/>}
            >
                Proforma invoice
            </Button>*/}

           {/* <Button
                className={classNames(classes.button, selected === 'scolaritelistfrais' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/scolaritelistfrais"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.schooling_other_fees_list} />
            </Button>*/}

            <Button
                className={classNames(classes.button, selected === 'scolaritedesfrais' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/scolaritedesfrais"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.class_fees} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'scolaritecheancier' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/scolaritecheancier"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.scolarite_echeancier} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'scolaritecheancierenfant' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/scolaritecheancierenfant"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.scolarite_echeancier_enfant} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'fraisoneshotenfant' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/fraisoneshotenfant"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.fraisoneshot_enfant} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'matchingfraisoneshotenfant' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/matchingfraisoneshotenfant"
                startIcon={<ListIcon/>}
            >
                <FormattedMessage id={messages.matchingfraisoneshot_enfant} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'groups' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/groups"
                startIcon={<ListIcon />}
            >
                <FormattedMessage id={messages.groups} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'groupsenfants' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/schooling/groupsenfants"
                startIcon={<ListIcon />}
            >
                <FormattedMessage id={messages.groupsenfants} />
            </Button>
        </>
    );
}

export default SchoolingSubMenu;
