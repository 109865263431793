import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material/styles";

const sondageHeaderStyles = makeStyles((theme:any) => {
    return {
        fixHeight: {},
        avatar: {},
        appBar: {
            height: 'auto',
            justifyContent: 'center',
            [`& $avatar`]: {
                width: 80,
                height: 80,
            },
            '& h2': {
                flex: 1,
                color: theme.palette.text.primary,
                '& span': {
                    color: theme.palette.text.secondary
                }
            },
        },
        appBarShift: {
            marginLeft: 0,
            width: '100%',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('lg')]: {
                zIndex: 1300
            }
        },
        coverText:{},
        cover: {
            background: theme.palette.mode === 'dark' ? alpha(theme.palette.grey[800], 0.75) : alpha(theme.palette.background.paper, 0.95),
            padding: '20px 8px',
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            [`& $avatar`]: {
                marginRight: 30,
                margin: `0 ${theme.spacing(2)}`,
                [theme.breakpoints.down('md')]: {
                    width: 50,
                    height: 50,
                    marginRight: 20
                }
            },
            '& $coverText':{
                color: theme.palette.text.secondary
            }
        },
        navIconHide: {
            marginRight: theme.spacing(1),
            paddingTop: 0,
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        statusLine: {},
        status: {
            padding: '2px 6px',
            color: theme.palette.text.secondary,
            [`& $statusLine`]: {
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: 2,
                width: 10,
                height: 10,
                border: `1px solid ${theme.palette.common.white}`
            }
        },
        online: {
            background: '#CDDC39'
        },
        buttonMore: {
            marginRight:5
        }
    }
});

export default sondageHeaderStyles ;
