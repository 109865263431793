import React from 'react';
import DialogForm from "../../components/ui/form/DialogForm";
import {Box, MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {TMiniClubProps} from "../../constants/type/miniclub/miniclubType";
import EditIcon from "@mui/icons-material/Edit";
import {IMGS} from "../../constants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import classNames from "classnames";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import InputField from "../../components/ui/form/InputField";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AddNewMiniClubForm(props:TMiniClubProps) {
    const {
        theme,
        title,
        open,
        setOpen,
        classes,
        onSubmit,
        register,
        classroomData,
        classroomDataValues,
        handleClassroomChange,
        fileDataURL,
        handleFileChange,
        activeLang,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange
    } = props;


    return (
        <DialogForm
            title={title}
            open={open}
            setOpen={setOpen}
            theme={theme}
            onSubmit={onSubmit}
        >
            <Box sx={{ width: '100%' }}>
                <div className={classes.dialogAvatar}>
                    {/*<Avatar src={''} sx={{width:100, height:100}} />*/}
                    <img
                        src={fileDataURL ? fileDataURL : IMGS.photoMenu}
                        //src={IMGS.photoMenu}
                        style={{width:100, height:90}}
                        alt={'mini-club-illustration'}
                    />
                    <div className={classes.iconButtonLabel}>
                        <label htmlFor="file-upload">
                            <div className={classes.iconButton} style={{textAlign:"center"}}>
                                <EditIcon />
                            </div>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                hidden={true}
                                onChange={(a:any) => handleFileChange(a)}
                            />
                        </label>
                    </div>
                </div>

                <div style={{marginTop:40}}> </div>
                <div className={classes.inputField}>
                    <TextField
                        label={<FormattedMessage id={messages.dialog_tab_hd_club_title}/>}
                        size="small"
                        color={"secondary"}
                        {...register("title", {
                            required: "Title is required"
                        })}
                    />
                </div>

                <div style={{ width:'100%', padding:0, margin:0, marginTop:10, marginBottom:10, marginLeft:-8}}>
                    <FormControl sx={{ width:'100%', m:1 }}>
                        <InputLabel color={"secondary"}>{<FormattedMessage id={messages.dialog_input_classroom} />}</InputLabel>
                        <Select
                            multiple
                            value={classroomDataValues}
                            onChange={handleClassroomChange}
                            input={<OutlinedInput label={<FormattedMessage id={messages.dialog_input_classroom} />} color={"secondary"} />}
                            renderValue={(selected:any) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value:any) => (
                                        <Chip sx={{fontWeight:'normal', fontSize:14}} key={value.id} label={`${value.nom}`} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            sx={{ fontWeight:400, p:0 }}
                            color={"secondary"}
                            //{...register("classroom")}
                        >
                            {classroomData.map((data:any) => {
                                return (
                                    <MenuItem
                                        key={data.id}
                                        value={data}
                                        sx={{ fontWeight:400,}}
                                    >
                                        {data.nom}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>


                <div className={classes.inputField} style={{marginBottom:15}}>
                    <TextField
                        label={<FormattedMessage id={messages.dialog_tab_hd_club_price}/>}
                        size="small"
                        color={"secondary"}
                        type={"number"}
                        {...register("prix", {
                            required: "Price is required"
                        })}
                    />
                </div>

                <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                        <MobileDateTimePicker
                            label={<FormattedMessage id={messages.start_day} />}
                            value={startDate}
                            onChange={handleStartDateChange}
                            slotProps={{ textField: { color: 'secondary' } }}
                        />
                    </LocalizationProvider>
                </div>

                <div className={classNames(classes.inputField, classes.inputFieldMarginBottom)}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLang}>
                        <MobileDateTimePicker
                            label={<FormattedMessage id={messages.end_day} />}
                            value={endDate}
                            minDate={startDate}
                            onChange={handleEndDateChange}
                            slotProps={{ textField: { color: 'secondary' } }}

                        />
                    </LocalizationProvider>
                </div>

                <div className={classes.inputField}  style={{marginBottom:20}}>
                    <TextField
                        label={<FormattedMessage id={messages.dialog_modal_club_limit}/>}
                        size="small"
                        color={"secondary"}
                        type={"number"}
                        {...register("placeLimit", {
                            required: "Limit is required"
                        })}
                    />
                </div>

                <div className={classes.inputField}>
                    <InputField
                        label={<FormattedMessage id={messages.dialog_tab_hd_club_description}/>}
                        size="small"
                        color={"secondary"}
                        InputProps={{
                            rows: 3,
                            multiline: true,
                            inputComponent: 'textarea',
                        }}
                        inputProps={{
                            ...register("details")
                        }}
                    />
                </div>
            </Box>

        </DialogForm>
    );
}

export default AddNewMiniClubForm;