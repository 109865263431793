import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import scolariteFraisGroupStateUpdate from "./ScolariteFraisGroupStateUpdate";
import {FormattedMessage} from "react-intl";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {isNumericValue, isPositiveValue} from "../../../../helpers/helpers";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {MiniClub, MINICLUB_CONSTANT} from "../../../../constants/type/MiniClub";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";

const scolariteFraisGroupBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolariteannees', 'miniclubs'];
        const convertToFrontEnd = (data:any) => {

            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);


            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsmc : MiniClub[]   = data.miniclubs.content ?? [];
            logic.miniClubRef.current  = _.sortBy(_.map(itemsmc,  item => MINICLUB_CONSTANT.convertToFrontEnd(logic, item)), ['title']);
        };

        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            scolariteFraisGroupBusinessLogic.loadScolariteGroups(logic);
        }).catch(e => {
            scolariteFraisGroupStateUpdate.tableDataLoading(logic, true);
        });
    },
    saveEdit: async (logic:any, messages:any, toSends:any[], id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = isPositiveValue(id) ? 'PUT' : 'POST';

            request(method, `/extra/scolaritedesfrais/bouquet`, toSends).then((response) => {
                const results : ScolariteGroup[] = response.data.success;
                const datas = _.cloneDeep(logic.dataList);
                results.forEach(data => {
                    // @ts-ignore
                    const dataSaved = SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, data);

                    const index = _.findIndex(datas, {id:data.id});
                    if(index >= 0){
                        datas[index] = dataSaved;
                    } else {
                        datas.push(dataSaved);
                    }
                });

                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);

                scolariteFraisGroupStateUpdate.dataList(logic, itemsfrontend) ;
                scolariteFraisGroupStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteFraisGroupStateUpdate.tableDataLoading(logic, false);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    loadScolariteGroups: (logic:any) => {
        scolariteFraisGroupStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritedesfrais`, {}).then((response) => {
                const items : ScolariteGroup[]   = response.data._embedded?.scolariteGroupDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);
                scolariteFraisGroupStateUpdate.dataList(logic, itemsfrontend) ;
                scolariteFraisGroupStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteFraisGroupStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                scolariteFraisGroupStateUpdate.tableDataLoading(logic, false);
            })
        );
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteGroup[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteGroup) => {
                if(isNumericValue(l.id)){
                    // @ts-ignore
                    ids.push(l.id);
                }
            });


            scolariteFraisGroupStateUpdate.tableDataLoading(logic, true);
            request('DELETE', `/extra/scolaritedesfrais/bouquet`, ids).then((response) => {
                // @ts-ignore
                const datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);
                scolariteFraisGroupStateUpdate.dataListSelected(logic, []);
                scolariteFraisGroupStateUpdate.dataList(logic, itemsfrontend) ;
                scolariteFraisGroupStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolariteFraisGroupStateUpdate.tableDataLoading(logic, false);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                scolariteFraisGroupStateUpdate.tableDataLoading(logic, false);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    }
}
export default scolariteFraisGroupBusinessLogic ;