import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import styles from "../config-table-jss";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {request} from "../../../helpers/axios_helpers";
import {useForm} from "react-hook-form";
import AppointmentTypeForm from "./AppointmentTypeForm";
import {CONSTANT} from "../../../constants";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {getErrorMessage} from "../../../helpers/errorManager";
import _ from "lodash";

const useStyles = makeStyles(styles);

type AppointmentDataType = {
    id: number;
    appointmentName: string;
};

type AppointmentFormValues = {
    id: number;
    appointmentName: string,
}

function AppointmentType(props:any) {
    const { theme, locale, snackbarShowMessage } = props;
    const classes = useStyles();
    const activeLanguage = locale.split('-');
    const [appointmentTypeData, setAppointmentTypeData] = useState<AppointmentDataType[]>([]);
    const [open, setOpen] = React.useState(false);
    const [stateAppointment, setStateAppointment] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const columnsHeaders:any[] = [
        <FormattedMessage id={messages.appointment_type} />,
    ];
    const columns = useMemo<MRT_ColumnDef<AppointmentDataType>[]>(
        () => [
            {
                accessorKey: 'appointmentName',
                header: columnsHeaders[0],
                size: 800,
            },
        ],
        [],
    );

    const form = useForm<AppointmentFormValues>({
        defaultValues: {
            id: 0,
            appointmentName: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
   // const {errors} = formState;

    const addAppointmentType = () => {
        setStateAppointment(true);
        setValue('appointmentName', '');
        handleClickOpen();
    }

    const editAppointmentType = (row:any) => {
        setStateAppointment(false);
        const dataAppointment: any = appointmentTypeData[row.index];
        setValue('id', dataAppointment.id);
        setValue('appointmentName', dataAppointment.appointmentName);
        handleClickOpen();
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';
        let appointData:any = {}
        request(method, url, dataToSend).then((response) => {
            if(stateAppointment) {
                appointData.id = response.data.id;
                appointData.appointmentName = data.appointmentName;
                appointData.couleur = response.data.couleur;
                appointData.common = response.data.common;
                setAppointmentTypeData([appointData, ...appointmentTypeData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {
                appointmentTypeData.find((appointment:any, index:any) => {
                    if(appointment.id === data.id) {
                        appointmentTypeData[index].appointmentName = data.appointmentName;
                        setAppointmentTypeData([...appointmentTypeData]);
                    }
                });
                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: AppointmentFormValues) => {
        const dataToSend = {
            nom: data.appointmentName,
            couleur: 'rgba(150, 145, 120, 0.7)',
            common: CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/typerdv", dataToSend);
    }

    const onSubmitEditForm = (data: AppointmentFormValues) => {
        const appointmentFind: any = appointmentTypeData.find(appointment => appointment.id === data.id);
        const dataToSend = {
            id: data.id,
            nom: data.appointmentName,
            couleur: appointmentFind.couleur,
            common: appointmentFind.common
        }

        sendData(data, 'PUT', `/corebase/typerdv/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const appointSelectedId:any = [];
        rows.map((row:any) => {
            const data = appointmentTypeData[row.index];
            dataSelected.push(data);
            appointSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/typerdv/bouquet', appointSelectedId).then((response) => {
            const appointmentList = appointmentTypeData.filter((appointData) => {
                if(!dataSelected.includes(appointData)){
                    return appointData;
                }
            });
            setAppointmentTypeData([...appointmentList]);
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    useEffect(() => {
        request("GET", "/corebase/typerdv", {}).then((response) => {
            const appointmentListRequest:any = response?.data?._embedded?.typeRdvDTOModelList;
            const dataList = appointmentListRequest.map((appointment:any) => {
                let appointmentData:any = {};
                appointmentData.id = appointment.id;
                appointmentData.appointmentName = appointment.nom;
                appointmentData = {...appointmentData, ...{ couleur: appointment.couleur, common: appointment.common }}
                return appointmentData;
            })
            setAppointmentTypeData([...dataList]);

        }).catch((error) => {
            // Error message
            console.log(error);
        })
    }, [activeLanguage[0]]);

    return (
        <div className={classes.root}>
            <MaterialReactTable
                columns={columns}
                data={appointmentTypeData}
                enableRowSelection
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing={true}
                state={{
                    density:'compact'
                }}
                localization={activeLanguage[0] === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                enableEditing
                positionActionsColumn="last"
                icons={{
                    SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                    CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                }}
                muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                renderTopToolbarCustomActions={({ table }: {table:any}) => (
                    <Box
                        className={classes.topToolbarActionsButton}
                        sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => addAppointmentType()}
                        >
                            <FormattedMessage id={messages.add_action} />
                        </Button>

                        <Button
                            //only export selected rows
                            color="error"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                            onClick={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                        >
                            <FormattedMessage id={messages.delete_action} />
                        </Button>
                    </Box>
                )}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '0.3rem'}}>
                        <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit}/>}>
                            <IconButton
                                onClick={() => editAppointmentType(row)}
                                sx={{color: 'rgba(0, 0, 0, 0.3)'}}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />

            <AppointmentTypeForm
                theme={theme}
                title={stateAppointment ? <FormattedMessage id={messages.title_appoint_type_config} /> : <FormattedMessage id={messages.edit_title_appoint_type_config} /> }
                open={open}
                setOpen={setOpen}
                classes={classes}
                register={register}
                onSubmit={stateAppointment ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />

        </div>
    );
}

export default withSnackbar(AppointmentType);
