import React from "react";
import Box from "@mui/material/Box";

function TabsPanel(props:any){
    const {theme, locale, children} = props;
    return (
        <Box sx={{ width: '100%' }}>
            {children}
        </Box>
    )
}

export default TabsPanel;
