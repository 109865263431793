import {createSlice} from "@reduxjs/toolkit";
import * as yup from "yup";
import _ from "lodash";

const TABLESTATES_KEY = "tableStates";
export const getDataTableStates = () => {
    return JSON.parse(window.localStorage.getItem(TABLESTATES_KEY) ?? `[]`);
}
export const dataTableStateSchema = yup.object().shape({
    dataTableId: yup.string(),
    etat: yup.mixed<Object>(),
    updateId: yup.number(),
});
export type DataTableState = yup.InferType<typeof dataTableStateSchema>;
export const dataTableStateImpl : DataTableState= {
    dataTableId: '',
    etat: {},
    updateId: 0,
}

export const dataTableStatesListSchema = yup.object().shape({
    tableStates: yup.array().of(yup.mixed<DataTableState>()).default([]).required(),
});
export type DataTableStatesList = yup.InferType<typeof dataTableStatesListSchema>;


const initialState = { tableStates: []} satisfies DataTableStatesList as DataTableStatesList;

const dataTableSlice = createSlice({
    name: 'dataTable',
    initialState: initialState,
    reducers:{
        clearDataTableState: (state) => {
            return initialState;
        },
        initDataTableState: (state, action) => {
            state.tableStates = action.payload;
        },
        setDataTableState:(state, action ) => {
            const datas = _.cloneDeep(state.tableStates ?? []);
            let index = _.findIndex(datas, {"dataTableId": action.payload.dataTableId});

            const data : any  = index >= 0 ? datas[index] : _.cloneDeep(dataTableStateImpl);
            data.dataTableId = action.payload.dataTableId;
            data.etat = action.payload.etat;
            data.updateId = action.payload.updateId;

            index = index >= 0 ? index : _.size(datas) ;
            datas[index] = data;

            state.tableStates = datas;
            window.localStorage.setItem(TABLESTATES_KEY, JSON.stringify(state.tableStates));
        },
    }
})

export const { clearDataTableState, initDataTableState, setDataTableState } = dataTableSlice.actions;

export default dataTableSlice.reducer;
