import React, {useEffect, useMemo, useState} from "react";
import styles from "../config/config-table-jss";
import {makeStyles} from "@mui/styles";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {Avatar} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {dropZoneFilePreview} from "../../helpers/helpers";
import {getRequest, request, uploadFileRequest} from "../../helpers/axios_helpers";
import moment from "moment";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PersonalDialogForm from "./PersonalDialogForm";
import {CONSTANT} from "../../constants";
import {getErrorMessage} from "../../helpers/errorManager";
import _ from "lodash";
import {RoleUser} from "../../constants/type/RoleUser";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../components/ui/materialReactTable/MyMaterialReactTableEvents";

const useStyles = makeStyles(styles);

export const personalCustomOptions = {
    columnVisibility : {
        'person.birthDay': false,
    }
} ;

function Personal(props:any) {
    const {theme, locale, snackbarShowMessage} = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const [tableData, setTableData] = useState<any[]>([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [jobData, setJobData] = useState<any[]>([]);
    const [employeeJob, setEmployeeJob] = useState<any>('');
    const [statePersonalForm, setStatePersonalForm] = useState(true);
    const [birthDate, setBirthDate] = useState(moment(new Date()));
    const [hireDate, setHireDate] = useState(moment(new Date()));
    const [sexe, setSexe] = useState('');
    const [open, setOpen] = useState<boolean>(false);
    const [classroomData, setClassroomData] = useState<any>([]);
    const [classroom, setClassroom] = useState<any | ''>('');
    const [classroomStatus, setClassroomStatus] = useState(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'Personal',  updateId:1725916246000, customOptions:personalCustomOptions}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<any>(mytableState), []);

    

    const schema = yup.object().shape({
        id: yup.number().nullable(),
        nom: yup.string().min(2).required(),
        prenom: yup.string().min(2).required(),
        sexe: yup.string().required(),
        email: yup.string().email().required(),
        phone1: yup.string().required(),
        note: yup.string().nullable(),
        jobNameId: yup.number().required(),
        poste: yup.string().nullable(),
        showClassroom: yup.boolean(),
        classroomEdit: yup.number()
            .when('showClassroom', {
                is: true, // alternatively: (val) => val == true
                then: (schema:any) => schema.min(1),
                otherwise: (schema:any) => schema.notRequired(),
            }),
    });


    const form = useForm({
        defaultValues:{},
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue, getValues} = form;
    const {errors} = formState;

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleBirthDateChange = (date:any) => {
        setBirthDate(date)
    };

    const handleHireDateChange = (date:any) => {
        setHireDate(date)
    };


    const handleSexeChange = (event:any) => {
        setSexe(event.target.value);
    }

    const handleEmployeeJobChange = (event:any) => {
        setClassroom('');
        setClassroomStatus(false);
        setValue('showClassroom', false);
        setValue('classroomEdit', 0);
        jobData.map((type:any) => {
            if(type.id === event?.target?.value) {
                setEmployeeJob(type);
                if(type.role === 'TEACHER') {
                    setClassroomStatus(true);
                    setValue('showClassroom', true);
                }
            }
        })
    }

    const handleClassroomChange = (event:any) => {
        setClassroom('');
        classroomData.map((classroom:any) => {
            if(classroom.id === event.target.value){
                setClassroom(classroom);
            }
        })
    }

    const addNewPersonal = () => {
        //Initialize form data
        setStatePersonalForm(true);
        setFile(null);
        setFileDataURL(null);
        setValue('prenom', '');
        setValue('nom', '');
        setBirthDate(moment(new Date()));
        setHireDate(moment(new Date()));
        setSexe('');
        setValue('email', '');
        setValue('phone1', '');
        setValue('poste', '');
        setEmployeeJob('');
        setValue('note', '');
        setValue('jobNameId', 0);
        setClassroomStatus(false);
        setValue('showClassroom', false);
        setValue('classroomEdit', 0);
        reset();

        setOpen(true);
    }

    const editPersonal = (row:MRT_Row<any>) => {
        const data:any = tableData[row.index];

        setStatePersonalForm(false);
        moment.locale(activeLang);
        setValue('id', data?.person?.id);
        setValue('prenom', data?.person?.prenom.trim());
        setValue('nom', data?.person?.nom.trim());
        setValue('sexe', data?.person?.sexe);
        setSexe(data?.person?.sexe);
        setFileDataURL(data?.person?.photoDataTable);
        setValue('email', data?.person?.email.trim());
        setValue('phone1', data?.person?.phone1.trim());
        setValue('poste', data?.person?.poste);
        setValue('note', data?.person?.note);
        setClassroom('');
        setValue('showClassroom', false);
        setValue('classroomEdit', 0);
        if(!classroomStatus){
            setClassroom('');
        }

        jobData.map((job:any) => {
            if( job.id === data?.fonctions[0]?.fonctionId){
                setValue('jobNameId', job.id);
                setEmployeeJob(job);
                setClassroomStatus(false);
                if(job.role === 'TEACHER') {
                    setClassroomStatus(true);
                    setValue('showClassroom', true);
                    classroomData.map((classroom:any) => {
                        if(data.teacherClasses.length > 0){
                            if(classroom.id === data?.teacherClasses[0]?.classeId) {
                                setClassroom(classroom);
                                setValue('classroomEdit', classroom.id);
                            }
                        }
                    });
                }
            }
        });

        let hireDateFonction:any = 0;
        moment.locale(activeLang);
        const editHireDate = data?.fonctions.length > 0 ? moment(data?.fonctions[0]?.theDate).format("L") : 0;
        const editBirthDate = moment(data?.person?.dateNaissance).format("L");

        if(data?.fonctions.length > 0){
            hireDateFonction = moment(editHireDate, activeLang === 'en' ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]);
            setHireDate(hireDateFonction);
        }
        else {
            setHireDate(moment(new Date()));
        }

        setBirthDate(moment(editBirthDate, activeLang === 'en' ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]));
        setOpen(true);
    }

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        let message:any = '';

        uploadFileRequest(method, url, dataToSend).then((response) => {
            let personalItem = response.data;

            if(statePersonalForm) {
                //const theDate = personalItem?.fonctions.length > 0 ? personalItem?.fonctions[0].theDate : 0;
                personalItem.person.birthDay = moment(personalItem?.person?.dateNaissance).format("L");
                personalItem.person.fonction = '';
                personalItem.person.photoDataTable = (personalItem?.person?.photo !== null && personalItem?.person?.photo !== '') ? `${BASEURL_RESOURCES}/${personalItem?.person?.photo}` : '';
                jobData.map((job:any) => {
                    if(personalItem?.fonctions.length > 0){
                        if( job.id === personalItem?.fonctions[0]?.fonctionId) {
                            personalItem.person.fonction = job?.jobName;
                        }
                        //personalItem.fonctions[0].theDate =  personalItem?.fonctions.length > 0 ? moment(theDate).format("L") : 0;
                    }
                });

                setTableData([personalItem, ...tableData]);
                reset();
                message = <FormattedMessage id={messages.sb_add_success} />
            }
            else {

                tableData.find((user:any, index:any) => {
                    if(user.id === data.id) {
                        tableData[index] = personalItem;
                        tableData[index].person.nom = personalItem?.person?.nom;
                        tableData[index].person.birthDay = moment(personalItem?.person?.dateNaissance).format("L");
                        tableData[index].person.photoDataTable = (personalItem?.person?.photo !== null && personalItem?.person?.photo !== '') ? `${BASEURL_RESOURCES}/${personalItem?.person?.photo}` : '';
                        jobData.map((job:any) => {
                            if(personalItem?.fonctions.length > 0){
                                if( job.id === personalItem?.fonctions[0]?.fonctionId) {
                                    tableData[index].person.fonction = job?.jobName;
                                }
                            }
                        });
                        setTableData([...tableData]);
                    }
                });

                message = <FormattedMessage id={messages.sb_edit_success} />
            }

            setOpen(false);
            snackbarShowMessage(message);

        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const onSubmit = (data: any) => {
        const fonctionId:any = employeeJob;
        let classToSend:any = [];
        const hire_date:any = hireDate;
        const birth_date:any = birthDate;

        if(classroom !== ''){
            classToSend = [{
                classeId: classroom?.id,
                dateDebut: hire_date?._d.valueOf(),
                dateFin: hire_date?._d.valueOf(),
            }]
        }

        let formData:any = new FormData();
        formData.append("photo", file);

        const userToSend = {
            person: {
                dateNaissance: birth_date?._d.valueOf(),
                nom: data?.nom.trim(),
                prenom: data?.prenom.trim(),
                sexe: data?.sexe,
                photo: '',
                email: data?.email.trim(),
                phone1: data?.phone1,
                phone2: '',
                poste: data?.poste.trim(),
                note: data?.note.trim(),
                paysPersons: [
                    {
                        paysId: 110,
                        common: CONSTANT.common,
                    }
                ],
            },
            fonctions: [
                {
                    fonctionId: fonctionId.id,
                    theDate: hire_date?._d.valueOf(),
                    common: CONSTANT.common,
                }
            ],
            teacherClasses:classToSend,
            rdvChoosable: fonctionId?.role === "TEACHER" || fonctionId?.role === "ADMIN",
            common: CONSTANT.common,
        }

        //console.log(userToSend)
        formData.append('model', new Blob([JSON.stringify(userToSend)], {
            type: "application/json"
        }));

        sendData(data, 'POST', "/corebase/employees", formData);
    }


    const onSubmitEditForm = (data: any) => {
        let classToSend:any = [];
        const fonction:any = employeeJob;
        const hire_date:any = hireDate;
        const birth_date:any = birthDate;
        const userFind:any = tableData.find((user:any) => user?.person?.id === data?.id);
        classToSend = [];

        if(classroom !== ''){
            classToSend = [{
                employeeId: userFind?.teacherClasses?.length > 0 ? userFind?.teacherClasses[0]?.employeeId : null,
                id: userFind.teacherClasses.length > 0 ? userFind?.teacherClasses[0]?.id : null,
                classeId: classroom?.id,
                dateDebut: hire_date._d.valueOf(),
                dateFin: hire_date._d.valueOf(),
                details: userFind?.teacherClasses?.length > 0 ? userFind?.teacherClasses[0]?.details : null,
                common: userFind?.teacherClasses?.length > 0 ? userFind?.teacherClasses[0]?.common : null,
            }]
        }

        let formData:any = new FormData();
        formData.append("photo", file);

        const userToSend = {
            ...userFind,
            person: {
                ...userFind.person,
                id: data?.id,
                prenom: data?.prenom.trim(),
                nom: data?.nom.trim(),
                sexe: data?.sexe,
                photo: file === null ? userFind?.person?.photo : userFind?.person?.photo,
                dateNaissance: birth_date?._d?.valueOf(),
                email: data.email.trim(),
                phone1: data.phone1,
                phone2: userFind.person?.phone2,
                cniNumero: userFind?.person?.cniNumero,
                cniPhoto: userFind?.person?.cniPhoto,
                poste: data?.poste?.trim(),
                note: data?.note,
                adresse: userFind?.person?.adresse,
                paysPersons: userFind?.person?.paysPersons,
                //...userFind,
            },
            fonctions: [
                {
                    ...userFind?.fonctions[0],
                    //id: userFind?.fonctions?.length > 0 ? userFind?.fonctions[0]?.id : null,
                    employeeId: userFind?.fonctions?.length > 0 ? userFind?.fonctions[0]?.employeeId : null,
                    fonctionId: fonction?.id,
                    theDate: hire_date?._d?.valueOf(),
                    common: userFind?.fonctions?.length > 0 ? userFind?.fonctions[0]?.common : null,
                    libelle: userFind?.fonctions?.length > 0 ? userFind?.fonctions[0]?.libelle : null,
                }
            ],
            teacherClasses: classToSend,
            rdvChoosable: userFind?.rdvChoosable,
            common: userFind?.common,
        }

        formData.append('model', new Blob([JSON.stringify(userToSend)], {
            type: "application/json"
        }));

        sendData(data, 'PUT', `/corebase/employees/${data.id}`, formData);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataPersonalSelectedId:any = [];
        rows.map((row:any) => {
            const data = tableData[row.index];
            dataSelected.push(data);
            dataPersonalSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/employees/bouquet', dataPersonalSelectedId).then((response) => {
            const userList = tableData.filter((user) => {
                if(!dataSelected.includes(user)){
                    return user;
                }
            });
            setTableData([...userList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const sendEmployeeRequest = (method: any, url: any, jobList:any) => {
        moment.locale(activeLang);
        request(method, url, {}).then((response) => {

            let userListRequest:any = response.data._embedded !== undefined ? response?.data?._embedded?.employeeDTOModelList : [];
            userListRequest = _.filter(userListRequest, item => item.id != 2);
            userListRequest = userListRequest.sort(function (a:any, b:any) {
                const firstName = a.person.nom.concat(a.person.prenom);
                const secondName = b.person.nom.concat(b.person.prenom);
                if(firstName < secondName) { return -1; }
                if(firstName > secondName) { return 1; }
                return 0;
            });

            const dataList:any = userListRequest.map((user: any) => {
                const theDate = user?.fonctions.length > 0 ? user?.fonctions[0]?.theDate : 0;

                user.person.birthDay = moment(user?.person?.dateNaissance).format("L");
                user.person.fonction = '';
                user.person.photoDataTable = (user?.person?.photo !== null && user?.person?.photo !== "") ? `${BASEURL_RESOURCES}/${user?.person?.photo}` : '';
                jobList.map((job:any) => {
                    if(user?.fonctions.length > 0){
                        if( job.id === user?.fonctions[0]?.fonctionId) {
                            user.person.fonction = job?.jobName;
                        }
                    }
                });
                return user;
            });

            tableData.push(...dataList);
            setTableData([...tableData]);
            setTableDataLoading(false);

        }).catch((error) => {
            // Error message
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'person.photoDataTable',
                header: intl.formatMessage({id: messages.table_h_person_avatar}),
                size: 80,
                Cell: ({ cell }) => (
                    <div style={{textAlign:'center', width:'110px'}}>
                        <Avatar src={cell.getValue<string>()} sx={{ textAlign:'center'}} />
                    </div>
                ),
            },
            {
                accessorKey: 'person.nom',
                header: intl.formatMessage({id: messages.table_h_child_lastname}),
            },
            {
                accessorKey: 'person.prenom',
                header: intl.formatMessage({id: messages.table_h_child_firstname}),
            },
            {
                accessorKey: 'person.birthDay',
                header: intl.formatMessage({id: messages.table_h_child_birthday}),
            },
            {
                accessorKey: 'person.email',
                header: intl.formatMessage({id: messages.table_h_person_email}),
            },
            {
                accessorKey: 'person.phone1',
                header: intl.formatMessage({id: messages.table_h_person_phone}),
            },
            {
                accessorKey: 'person.poste',
                header: intl.formatMessage({id: messages.table_h_person_job}),
            },
        ],
        [activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang])
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: tableData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addNewPersonal}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editPersonal(row)}
                styleClasses={classes}
            />
        )
    });

    useEffect(() => {
        ( async () => {

            try {
                const jobListRequest:any = await getRequest(`/corebase/fonctions`);
                let jobRequestList:any = jobListRequest._embedded !== undefined ? jobListRequest._embedded?.fonctionDTOModelList : [];
                jobRequestList   = _.filter(jobRequestList, item => item.role != RoleUser.SUPER_ADMIN);
                jobRequestList = jobRequestList.sort(function (a:any, b:any) {
                    if(a.nom < b.nom) { return -1; }
                    if(a.nom > b.nom) { return 1; }
                    return 0;
                });

                const dataList = jobRequestList.map((job:any) => {
                    let jobData:any = {};
                    jobData.id = job.id;
                    jobData.jobName = job.nom;
                    jobData.role = job.role;
                    return jobData;
                });

                setJobData([...dataList]);
                sendEmployeeRequest('GET', '/corebase/employees', dataList);

                const classListRequest:any = await getRequest(`/corebase/classes`);
                let classRequestList:any = classListRequest._embedded !== undefined ? classListRequest._embedded?.classeDTOModelList : [];
                classRequestList = classRequestList.sort(function (a:any, b:any) {
                    if(a.nom < b.nom) { return -1; }
                    if(a.nom > b.nom) { return 1; }
                    return 0;
                });

                const dataClassList:any = classRequestList.map((classroom:any) => {
                    let classData:any = {};
                    classData.id = classroom.id;
                    classData.className = classroom.nom;
                    classData = {...classData, ...{ couleur: classroom.couleur, common: classroom.common }}
                    return classData;
                })
                setClassroomData([...dataClassList]);
            }
            catch (error) {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            }

        })();

        return () => logicMRT.storeState(tableConfig.getState());
    }, []);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);


    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />

            <PersonalDialogForm
                theme={theme}
                locale={locale}
                classes={classes}
                statePersonalForm={statePersonalForm}
                open={open}
                setOpen={setOpen}
                fileDataURL={fileDataURL}
                setFileDataURL={setFileDataURL}
                birthDate={birthDate}
                hireDate={hireDate}
                handleHireDateChange={handleHireDateChange}
                sexe={sexe}
                employeeJob={employeeJob}
                jobData={jobData}
                classroomData={classroomData}
                classroom={classroom}
                classroomStatus={classroomStatus}
                handleFileChange={handleFileChange}
                handleBirthDateChange={handleBirthDateChange}
                handleSexeChange={handleSexeChange}
                handleEmployeeJobChange={handleEmployeeJobChange}
                handleClassroomChange={handleClassroomChange}
                register={register}
                errors={errors}
                onSubmit={statePersonalForm ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}

                //handleFileChange={handleFileChange}
                //onFirstNameChange={onFirstNameChange}
                //onLastNameChange={onLastNameChange}
                //onEmailChange={onEmailChange}
                //onPhoneNumberChange={onPhoneNumberChange}
                //onInformationChange={onInformationChange}
                //handleDateChange={handleDateChange}
                //jobType={jobType}
                ///handleJobTypeChange={handleJobTypeChange}
                //handlePersonalTypeChange={handlePersonalTypeChange}
                //lastName={lastName}
                //firstName={firstName}

                //email={email}
                //phoneNumber={phoneNumber}
                //personalType={personalType}
                //jobData={jobData}
                ///moreInformation={moreInformation}

                //handleGenreChange={handleGenreChange}

                //
                //classroomData={classroomData}
                //classroom={classroom}
                //handleClassroomChange={handleClassroomChange}
                //classroomStatus={classroomStatus}
            />

        </div>
    )
}

export default withSnackbar(Personal);
