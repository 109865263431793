import {FilDiscussion, FILDISCUSSION_CONSTANT, filDiscussionDataImpl} from "../../../../constants/type/FilDiscussion";
import _ from "lodash";
import {alpha} from "@mui/material/styles";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {BASEURL_RESOURCES, HTTP_MESSAGE_URL} from "../../../../config/AppUrl";
import ListItemText from "@mui/material/ListItemText";
import {Badge, Collapse, ListItemButton} from "@mui/material";
import React from "react";
import {Message} from "../../../../constants/type/Message";
import moment from "moment";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import GppBadIcon from '@mui/icons-material/GppBad';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {FormattedMessage} from "react-intl";
import messages from "../../general/messages";
import {DiscussionEtat} from "../../../../constants/type/DiscussionEtat";
import {request} from "../../../../helpers/axios_helpers";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {isPositiveValue} from "../../../../helpers/helpers";
import {pageFormImpl, setPageForm} from "../../../../features/page/pageSlice";


const spaceListBusinessLogic: any = {
    getUserSpaces: (logicRef:any) => {
        //const params = `userChat=${btoa(JSON.stringify(logicRef.userChatRef.current))}`;
        logicRef.setShowChatRoom(false);
        return new Promise<FilDiscussion>(resolve =>
            request("PUT", `${HTTP_MESSAGE_URL}/ws/chat/spaces`, logicRef.userChatRef.current).then((response) =>{
                const items: FilDiscussion[] = response.data ?? [];

                if(_.isEmpty(items)){
                    const currentSpace = _.cloneDeep(filDiscussionDataImpl);
                    spaceListBusinessLogic.updateConversationList(logicRef, currentSpace, items);
                    resolve(currentSpace);
                    return ;
                }
                const spaceItems = _.map(items, item => FILDISCUSSION_CONSTANT.convertToFrontEnd(logicRef, item));
                const orderedSpaces = _.reverse(_.sortBy(spaceItems, ['theDate']));
                const openedSpaces  = _.filter(orderedSpaces, {filEtat:DiscussionEtat.OPEN});
                let currentSpace         = _.isEmpty(openedSpaces) ? orderedSpaces[0] : openedSpaces[0];

                if(_.isObject(logicRef.pageForm)){
                    // @ts-ignore
                    if(logicRef.pageForm.cle === 'message_center' && isPositiveValue(logicRef.pageForm.id) ){
                        const dataId = logicRef.pageForm.id ;
                        logicRef.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));

                        for(const space of orderedSpaces){
                            if(space.id !== dataId){
                                continue ;
                            }
                            currentSpace = _.cloneDeep(space);
                            break ;
                        }
                    }
                }

                spaceListBusinessLogic.updateConversationList(logicRef, currentSpace, orderedSpaces);
                logicRef.setShowChatRoom(true);
                //updateConversationList: (logicRef:any, spaceList:FilDiscussion[])
               /* if(_.isEmpty(spaceItems) === false){
                    _.delay(function() {
                        spaceListBusinessLogic.handleConversationClick(logicRef, );
                    }, 250, 'later');
                }*/
                resolve(currentSpace);
            }).catch((error) =>{
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                logicRef.setShowChatRoom(true);
                resolve(_.cloneDeep(filDiscussionDataImpl));
            })
        );
    },
    handleConversationClick: (logicRef:any, data:FilDiscussion) => {
        data.totalNotifications = 0 ;
        data.lastMessage       = `` ;
        logicRef.setNewMessage(false);
        spaceListBusinessLogic.updateConversationList(logicRef, data, logicRef.spaceList);
    },
    updateConversationList: (logicRef:any, spaceSelected:FilDiscussion, spaceList:FilDiscussion[]) => {
        const newSpaceList = _.cloneDeep(spaceList) ;
        const index   = _.findIndex(newSpaceList, {'id':spaceSelected.id});
        if( index > 0){
            newSpaceList[index]        = spaceSelected;
        }
        const orderSpaces = _.reverse(_.sortBy(newSpaceList, ['theDate']));
        logicRef.setSpaceList(orderSpaces);
        logicRef.setSpace(_.cloneDeep(spaceSelected));
        spaceListBusinessLogic.listerLesConversation(logicRef, spaceSelected, orderSpaces);
        logicRef.setShowConversationList(true);
    },
    addNewConversation: (logicRef:any, newSpaces:FilDiscussion[]) => {
        if(_.isEmpty(newSpaces)){
           return ;
        }
        const spaceList = _.cloneDeep(logicRef.spaceList);
        for(const newSpace of newSpaces){
            const index   = _.findIndex(spaceList, {'id':newSpace.id});
            if( index < 0){
                spaceList.push(FILDISCUSSION_CONSTANT.convertToFrontEnd(logicRef, newSpace));
            }else {
                spaceList[index] = newSpace;
            }
        }
        const firstNewSpace = newSpaces[0];
        const currentSpace = _.isObject(firstNewSpace) ? firstNewSpace : logicRef.space;
        spaceListBusinessLogic.updateConversationList(logicRef, currentSpace, spaceList);
    },
    updateConversationState: (logicRef:any, upSpaces:FilDiscussion[]) => {
        if(_.isEmpty(upSpaces)){
            return ;
        }
        const spaceList = _.cloneDeep(logicRef.spaceList);
        upSpaces.forEach(space => {
            const indexSpace = _.findIndex(spaceList, {'id':space.id});
            spaceList[indexSpace] = space;
        });
        spaceListBusinessLogic.updateConversationList(logicRef, logicRef.space, spaceList);
    },
    removeConversation: (logicRef:any, upSpaces:FilDiscussion[]) => {
        if(_.isEmpty(upSpaces)){
            return ;
        }
        let spaceList = _.cloneDeep(logicRef.spaceList);
        _.remove(spaceList, function (iteminstore:FilDiscussion) {
            return _.findIndex(upSpaces, {'id':iteminstore.id}) >= 0 ;
        });

        const currentSpace = _.isEmpty(spaceList) ?  _.cloneDeep(filDiscussionDataImpl) : spaceList[0];
        spaceListBusinessLogic.updateConversationList(logicRef, currentSpace, spaceList);
    },
    handleExpanCollapseOpenedSpace: (logicRef:any) => {
        const etat = !logicRef.expandOpenedSpace ;
        logicRef.setExpandOpenedSpace(etat);
        if(etat){logicRef.setExpandClosedSpace(false);}
    },
    handleExpanCollapseClosedSpace: (logicRef:any) => {
        const etat = !logicRef.expandClosedSpace ;
        logicRef.setExpandClosedSpace(etat);
        if(etat){logicRef.setExpandOpenedSpace(false);}
    },
    listerLesConversation: (logicRef:any, spaceSelected:FilDiscussion, spaceList:FilDiscussion[]):any => {
        const idSpaceSelected = spaceSelected.id ;
       const openedSpaces = spaceList.filter((data:FilDiscussion) => data.filEtat === DiscussionEtat.OPEN).map((data:FilDiscussion) => {
            //const index = dataContact.indexOf(data);
           const theDateForm = moment(data.theDate) ;
            const index = data.id;
            const sndText = <>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.enfantNom}</p>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.parentNom}</p>
                <p style={{color:'ff3d00'}} className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.lastMessage}</p>
            </>;
            const connected = logicRef.usersConnectedMapRef.current.has(data.parentUuid);
            return <ListItemButton
                key={`listitembutton_${index}`}
                className={index === idSpaceSelected ? logicRef.spaceListStyles.selected : ''}
                sx={{ backgroundColor: (index === idSpaceSelected ? alpha(logicRef.theme.palette.background.paper, 0.8) : '' ) }}
                onClick={() => spaceListBusinessLogic.handleConversationClick(logicRef,data)}
            >
                <ListItemAvatar key={`listitemavatar_${index}`}>
                    <Badge key={`badge_${index}`}  color="secondary" badgeContent={data.totalNotifications} max={999}>
                        <Avatar
                            key={`avatar_${index}`}
                            alt={data.parentNom}
                            src={`${BASEURL_RESOURCES}/${data.enfantPhoto}`}
                            className={logicRef.spaceListStyles.avatar} />
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    key={`listitemtext_${index}`}
                    className={logicRef.spaceListStyles.textNotSelected}
                    primary={data.objet}
                    secondary={sndText}  />

                <div style={{textAlign:'center'}}>
{/*
                    <Badge color={connected ? "primary" : "secondary"} variant="dot" sx={{ marginRight:'10%'}} />
*/}
                    <p className={logicRef.spaceListStyles.usertimeinfo}>{theDateForm.format('LT')}</p>
                    <p className={logicRef.spaceListStyles.usertimeinfo} style={{fontSize: '0.6rem'}}>{theDateForm.format('L')}</p>
{/*
                    <p className={logicRef.spaceListStyles.usertimeinfo} style={{fontSize: '0.6rem'}}>{connected ? <FormattedMessage id={messages.online_space} /> : <FormattedMessage id={messages.offline_space} />}</p>
*/}
                </div>
            </ListItemButton>;
        });

        const closedSpaces = logicRef.spaceList.filter((data:FilDiscussion) => data.filEtat === DiscussionEtat.CLOS).map((data:FilDiscussion) => {
            //const index = dataContact.indexOf(data);
            const theDateForm = moment(data.theDate) ;
            const index = data.id;
            const sndText = <>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.enfantNom}</p>
                <p className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-10lq8ji-MuiTypography-root">{data.parentNom}</p>
            </>;

            return <ListItemButton
                key={`listitembutton_${index}`}
                className={index === idSpaceSelected ? logicRef.spaceListStyles.selected : ''}
                sx={{ backgroundColor: (index === idSpaceSelected ? alpha(logicRef.theme.palette.background.paper, 0.8) : '' ) }}
                onClick={() => spaceListBusinessLogic.handleConversationClick(logicRef,data)}
            >
                <ListItemAvatar  key={`listitemavatar_${index}`}>
                    <Badge key={`badge_${index}`} color="secondary" badgeContent={data.totalNotifications} max={999}>
                        <Avatar
                            key={`avatar_${index}`}
                            alt={data.parentNom}
                            src={`${BASEURL_RESOURCES}/${data.enfantPhoto}`}
                            className={logicRef.spaceListStyles.avatar} />
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    key={`listitemtext_${index}`}
                    className={logicRef.spaceListStyles.textNotSelected}
                    primary={data.objet}
                    secondary={sndText}  />

                <div style={{textAlign:'center'}}>
                    <p className={logicRef.spaceListStyles.usertimeinfo}>{theDateForm.format('LT')}</p>
                    <p className={logicRef.spaceListStyles.usertimeinfo} style={{fontSize: '0.6rem'}}>{theDateForm.format('L')}</p>
                </div>
            </ListItemButton>;
        });

        const openedSpaceGroup = <>
                    <ListItemButton key="openeditembtn" onClick={() => spaceListBusinessLogic.handleExpanCollapseOpenedSpace(logicRef)}>
                    <ListItemIcon>
                        <AdminPanelSettingsIcon color="success" />
                    </ListItemIcon>
                    <ListItemText  key="openedlistitemtext" primary={<FormattedMessage id={messages.opened_space} />} />
                    {logicRef.expandOpenedSpace ? <ExpandLess /> : <ExpandMore  />}
                </ListItemButton>
            <Collapse  in={logicRef.expandOpenedSpace} key="openedcollapse" timeout="auto" unmountOnExit>{openedSpaces}</Collapse>
        </> ;
        const closedSpaceGroup = <>
                <ListItemButton key="closeditembtn"  onClick={() => spaceListBusinessLogic.handleExpanCollapseClosedSpace(logicRef)}>
                <ListItemIcon>
                    <GppBadIcon color="disabled" />
                </ListItemIcon>
                <ListItemText key="closedlistitemtext" primary={<FormattedMessage id={messages.closed_space} />} />
                {logicRef.expandClosedSpace ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={logicRef.expandClosedSpace} key="closedcollapse" timeout="auto" unmountOnExit>{closedSpaces}</Collapse>
        </> ;
        const spaces = [openedSpaceGroup,closedSpaceGroup];
        logicRef.setListeDesConversations(spaces);
    },
    updateSpaceInfoFromNewMessage: (logicRef:any, data:Message) => {
        const spaceList = _.cloneDeep(logicRef.spaceList);
        const indexSpace = _.findIndex(spaceList, {'id':data.filDiscussionId});
        if(indexSpace < 0){
            return;
        }

        const spacelastupdated = spaceList[indexSpace];
        spacelastupdated.lastMessage = ``;
        spacelastupdated.totalNotifications = 0;

        if(data.filDiscussionId !== logicRef.space.id){
            spacelastupdated.lastMessage = data.message?.substring(0, 25)+`...`;
            spacelastupdated.totalNotifications += 1  ;
        }

        spacelastupdated.theDate = data.theDate ;
        spaceList[indexSpace] = spacelastupdated ;
        spaceListBusinessLogic.updateConversationList(logicRef, spacelastupdated, spaceList);
    },
}
export default spaceListBusinessLogic;