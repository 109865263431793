import React from 'react';
import Box from "@mui/material/Box";
import {Avatar, Checkbox, CircularProgress, ListItemText, Typography} from "@mui/material";
import useEventNotificationVariables from "./businesslogic/MesNotificationsVariables";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import _ from "lodash";
import mesNotificationsBusinessLogic from "./businesslogic/MesNotificationsBusinessLogic";
import alertMessageStyles from "./jss/alertmessage-jss";
import {EventNotification} from "../../constants/type/EventNotification";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import PapperBlock from '../../components/ui/papperBlock/PapperBlock';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import {isPositiveValue, stringAvatar} from "../../helpers/helpers";
import messages from "../messages/general/messages";
import {FormattedMessage, useIntl} from "react-intl";
import DeleteIcon from "@mui/icons-material/Delete";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import {updateNotification} from "../../helpers/loader_notifications";
import ListItemButton from "@mui/material/ListItemButton";
import {useNavigate} from "react-router-dom";
import mesNotificationsStateUpdate from "./businesslogic/MesNotificationsStateUpdate";
import menuMessages from "../messages/general/menuMessages";
import {setMenuOpen, setPageForm, setPageTitle} from "../../features/page/pageSlice";
import LoadingButton from "@mui/lab/LoadingButton";

const useBusinessLogic = (props: any): any => {
    const styleClasses = alertMessageStyles();

    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useEventNotificationVariables({styleClasses, ...props})
    }
};


function MesNotifications(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const styleClasses = logic.styleClasses;
    const navigate = useNavigate();
    const intl = useIntl() ;

    const isChecked = (id:number | null | undefined) => {
        if(!isPositiveValue(id)){
            return false ;
        }
        return _.findIndex(logic.dataListSelected, {id:id}) >= 0 ;
    }
    const handleToggle = (value: any) => () => {
        const currentIndex = _.findIndex(logic.dataListSelected, {id:value.id});
        if (currentIndex < 0) {
            let datas = _.cloneDeep(logic.dataListSelected);
            datas.push(value);
            mesNotificationsStateUpdate.dataListSelected(logic, datas);
        } else {
            const datas  = logic.dataListSelected.filter((item:any) => item.id !== value.id);
            mesNotificationsStateUpdate.dataListSelected(logic, datas);
        }
    };

    const handleDelete = () => {
        if(_.isEmpty(logic.dataListSelected)){
            return ;
        }
        mesNotificationsBusinessLogic.deleteDatas(logic, messages, logic.userRef.current.uuid, logic.dataListSelected);
    }

    const handleVoirPage = (item:any) => {
        updateNotification(logic.userRef.current.uuid, item.id);

        const pageForm = {cle:item.uriErpCle, id:item.dataId, metaData:item};
        dispatch(setPageForm(pageForm));

        dispatch(setMenuOpen(item.uriErpCle));
        // @ts-ignore
        dispatch(setPageTitle(menuMessages[item.uriErpCle] !== undefined ? intl.formatMessage({id:menuMessages[item.uriErpCle]}) : item.uriErpNom));
        // @ts-ignore
        navigate(item.uriErp);
        /*// @ts-ignore
        deleteNotification(logic.userRef.current.uuid, opt.id);

        const pageForm = {cle:item.uriErpCle, id:item.dataId, metaData:item};
        dispatch(setPageForm(pageForm));

        setOpen(opt.uriErpCle);
        // @ts-ignore
        setPageTitle(menuMessages[opt.uriErpCle] !== undefined ? <FormattedMessage id={menuMessages[opt.uriErpCle]} /> : opt.uriErpNom);
        // @ts-ignore
        navigate(opt.uriErp);*/
    }
    function renderRow(props: ListChildComponentProps) {
        const {index, style} = props;
        const opt: EventNotification = logic.dataList[index];
        if (!_.isObject(opt)) {
            return <></>;
        }
        return (<ListItem
            style={style}
            key={index}
            component="div"
            disablePadding
            alignItems="flex-start"
            secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={handleToggle(opt)}
                    checked={isChecked(opt.id)}
                    inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${opt.id}` }}
                />
            } >
            <ListItemButton
            onClick={()=>handleVoirPage(opt)}
            >
                <ListItemAvatar key={`avatar_${opt.id}`}>
                    <Avatar {...stringAvatar(opt.nameSender)}
                            alt={opt.nameSender}
                            src={opt.photoForm} />
                </ListItemAvatar>
                <ListItemText
                    key={`itemtxt_${opt.id}`}
                    primary={opt.title}
                    secondary={
                        <>
                            <Typography
                                sx={{ display: 'block'}}
                                component="span"
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                {opt.nameSender}
                            </Typography>
                            <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body1"
                                color="text.secondary"
                            >
                                {opt.body}
                            </Typography>
                            <Typography
                                sx={{ display: 'block' }}
                                component="span"
                                variant="body2"
                                color="gray"
                            >
                                {moment(opt.dateSent).format('lll')}
                            </Typography>
                        </>
                    }
                />
            </ListItemButton>

        </ListItem>);
    }
    React.useEffect(() => {
        moment.locale(logic.activeLanguageRef.current[0]);
        mesNotificationsBusinessLogic.initPostConstruct(logic);
    }, []);

    return (
        <div className={styleClasses.root}>
            <PapperBlock whiteBg noMargin displayNoTitle={true}>
                <div className={styleClasses.tableContainer} style={{padding:'40px'}}>
                    <h4><FormattedMessage id={messages.mes_notifications} /></h4>
                    <LoadingButton
                        loading={logic.processingStateNormBtn}
                        color="error"
                        variant="contained"
                        loadingPosition="start"
                        startIcon={<DeleteIcon />}
                        aria-label="Delete notifications"
                        size="small"
                        sx={{ textTransform: 'none', marginBottom:3, marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                        disabled={_.isEmpty(logic.dataListSelected)}
                        onClick={handleDelete}
                    >
                        <FormattedMessage id={messages.delete_action}/>
                    </LoadingButton>

                    {
                        !logic.contentLoaded &&
                        <Box  sx={{ width: '100%', height:'200px', textAlign: 'center', alignContent:'center', verticalAlign:'middle' }}>
                            <CircularProgress color="primary" />
                        </Box>
                    }
                    {
                        logic.contentLoaded &&
                        <FixedSizeList
                            height={600}
                            width='100%'
                            itemSize={110}
                            itemCount={_.size(logic.dataList)}
                            overscanCount={4}
                        >
                            {renderRow}
                        </FixedSizeList>
                    }

                </div>
            </PapperBlock>
        </div>
    );
}

export default withSnackbar(MesNotifications);
