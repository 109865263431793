import _ from "lodash";
import * as yup from "yup";


export const COURRIELRECIPIENT_CONSTANT = {
    convertToFrontEnd: (item:CourrielRecipient) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(courrielRecipientDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (item:CourrielRecipient) => item,
    convertToJson: (item:CourrielRecipient) => {
        JSON.stringify(item);
    },
};

export  const courrielRecipientDataImpl: CourrielRecipient = {
    parentNom: '',
    enfantNom: '',
    parentCourriel: '',
}

export const courrielRecipientSchema = yup.object().shape({
    parentNom: yup.string().nullable(),
    enfantNom: yup.string().nullable(),
    parentCourriel: yup.string().nullable(),
});

export type CourrielRecipient = yup.InferType<typeof courrielRecipientSchema>;
