import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import styles from "../config/config-table-jss";
import UserProfileForm from "./UserProfileForm";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import UdpatePasswordForm from "./UdpatePasswordForm";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 20,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10,
            textAlign: "right"
        }
    }
})

function UserProfile(props:any) {
    const { theme, locale, snackbarShowMessage } = props;
    const activeLanguage = locale.split('-');
    const classes = {...useStyles(), ...useStyles2()};
    const { pageTitle } = useSelector((state:any) => state.page);

    useEffect(() => {}, []);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={5} xs={12}>
                        <UserProfileForm
                            classes={classes}
                            activeLanguage={activeLanguage}
                            snackbarShowMessage={snackbarShowMessage}
                        />
                    </Grid>

                    {/* RIGHT PART */}
                    <Grid item md={7} xs={12}>
                        <UdpatePasswordForm
                            classes={classes}
                            activeLanguage={activeLanguage}
                            snackbarShowMessage={snackbarShowMessage}
                        />
                    </Grid>

                </Grid>
            </div>
        </PapperBlock>
    );
}

export default withSnackbar(UserProfile);
