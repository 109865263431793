import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {ClasseRoom} from "../../../constants/type/ClasseRoom";
import {Child, childCustomOptions, childDataImpl} from "../../../constants/type/Child";
import {AlbumPicture} from "../../../constants/type/AlbumPicture";
import {Employee} from "../../../constants/type/Employee";
import {Fonction} from "../../../constants/type/Fonction";
import {Profils} from "../../../constants/type/Profils";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const usePhotoVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'PhotosAlbum', customOptions:childCustomOptions,  updateId:1725940294000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<Child>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const teacherRef = useRef<Employee | null | undefined>(null);
    const classeIdRef = useRef<number>(0);
    const isTeacherRef = useRef<boolean>(false);

    const profilsRef  = useRef<Profils[]>([]);
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());
    const childrenRef  = useRef<Child[]>([]);
    const childrenClasseRef  = useRef<Child[]>([]);
    const [childrenList, setChildrenList] = useState<Child[]>([]);
    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const [childSel, setChildSel] = useState<Child>(_.cloneDeep(childDataImpl));
    const [showForm, setShowForm] = useState<boolean>(false);
    const [photoList, setPhotoList] = useState<AlbumPicture[]>([]);
    const [photosSelected, setPhotosSelected] = useState<AlbumPicture[]>([]);
    const [photosIdsSelected, setPhotosIdsSelected] = useState<number[]>([]);
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);
    const [showPhotoContent, setShowPhotoContent] = useState<boolean>(false);
    const [showTableContent, setShowTableContent] = useState<boolean>(true);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [openFileDeleteDialogForm, setOpenFileDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);

    const employeesRef  = useRef<Employee[]>([]);
    const employeesByFonctionRef  = useRef<Employee[]>([]);


    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,childrenList,setChildrenList,
        tableKeyValue,setTableKeyValue,classeRoomRef,childrenRef,childrenClasseRef,tableDataLoading,seTableDataLoading,
        showForm,setShowForm,photoList,setPhotoList,photosSelected,setPhotosSelected,childSel,setChildSel,
        employeesRef,employeesByFonctionRef,jobsRef,photosIdsSelected,setPhotosIdsSelected,profilsRef,
        showPhotoContent,setShowPhotoContent,openFileDeleteDialogForm,setOpenFileDeleteDialogForm,
        processingStateNormBtn,setProcessingStateNormBtn,classeRoomMapRef,teacherRef,
        classeIdRef,isTeacherRef,dataCaches, dispatch,showTableContent,setShowTableContent,
        tableConfigRef,
        ...logicMRT
    }
}
export default usePhotoVariables;