import {getRequest} from "../helpers/axios_helpers";

function ClassroomService() {
    return {
        getAllClassData: async () => {
            return await getRequest('/corebase/classes');
        },
        getAllClassDataAscByName: async () => {
            const classListRequest:any = await getRequest('/corebase/classes');
            let classList:any = classListRequest._embedded !== undefined ? classListRequest._embedded?.classeDTOModelList : [];
            return  classList.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });
        },
        getAllClassDataOrdered: async () => {
            const classListRequest:any = await getRequest(`/corebase/classes`);
            let classRequestList:any = classListRequest._embedded !== undefined ? classListRequest._embedded?.classeDTOModelList : [];
            classRequestList = classRequestList.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });

            return classRequestList.map((classroom:any) => {
                let classData:any = {};
                classData.id = classroom.id;
                classData.className = classroom.nom;
                classData = {...classData, ...{ couleur: classroom.couleur, common: classroom.common }}
                return classData;
            })
        }
    }
}

export default ClassroomService;
