import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, FormControl, Grid, MenuItem, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {BibliothequeContext} from "./Bibliotheque";
import {LIVRE_CONSTANT, livreDataImpl, livreSchema} from "../../../constants/type/Livre";
import livreStateUpdate from "./businesslogic/LivreStateUpdate";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {BASEURL_RESOURCES} from "../../../config/AppUrl";
import {dropZoneFilePreview, isPositiveValue} from "../../../helpers/helpers";
import livreBusinessLogic from "./businesslogic/LivreBusinessLogic";
import InputField from "../../../components/ui/form/InputField";
import messages from "../../messages/general/messages";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const LivreEditForm = (props:any) => {
    const logic: any = useContext(BibliothequeContext);
    const styleClasses = logic.styleClasses ;
    const photoLivreRef = React.useRef<any>();
    const [file, setFile] = useState<any>(null);
    const intl = useIntl() ;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreSchema),
    }));
    const {errors} = formRef.current.formState;

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(URL.createObjectURL(file));
        livreStateUpdate.file(logic, file);
    }
    const handleOnCategorieChange = (event: any) => {
        if(!isPositiveValue(event.target.value)){
            return ;
        }
        const idCatLivre   = _.toInteger(event.target.value);
        const catgoreiLivreSel      = _.find(logic.livreCategorieRef.current, {id:idCatLivre});
        if(_.isObject(catgoreiLivreSel) === false){
            return ;
        }
        const data = _.cloneDeep(logic.livreSel);
        data.livreCategorieId = catgoreiLivreSel.id;
        livreStateUpdate.livreSel(logic, data);
        formRef.current.setValue('livreCategorieId', catgoreiLivreSel.id);
        formRef.current.setValue('livreCategorieForm', catgoreiLivreSel);
    };
    const onSubmit = async (data: FormData) => {
        livreStateUpdate.processingLivreStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: Livre = _.cloneDeep(data);

        // @ts-ignore
        const toSend = LIVRE_CONSTANT.convertToBackEnd(logic, dataToSend);

        let formData:any = new FormData();
        if(_.isObject(logic.file)){
            formData.append("photo", logic.file);
        }

        formData.append('model', new Blob([JSON.stringify(toSend)], {
            type: "application/json"
        }));
        livreBusinessLogic.saveLivreEdit(
            logic,
            messages,
            formData,
            toSend.id).then((response:any) => {
            livreStateUpdate.openLivreEditDialogForm(logic, false);
            livreStateUpdate.processingLivreStateNormBtn(logic, false);
        }).catch((e:any) => livreStateUpdate.processingLivreStateNormBtn(logic, false));
    };

    React.useEffect(() => {
        if(logic.openLivreEditDialogForm === false){
            const data = _.cloneDeep(livreDataImpl);
            livreStateUpdate.livreSel(logic, data);
            formRef.current.reset(data);
            livreStateUpdate.file(logic, null);
            setFile(null);
            return ;
        }

        formRef.current.reset(_.cloneDeep(logic.livreSel));
        if(_.isNull(logic.livreSel.id) === false){
            photoLivreRef.current = _.isEmpty(logic.livreSel.photo) ? '' : `${BASEURL_RESOURCES}/${logic.livreSel.photo}` ;
        }
        return () => {
            formRef.current.reset(_.cloneDeep(livreDataImpl));
        };
    }, [logic.openLivreEditDialogForm]);

    useEffect(() => {
        dropZoneFilePreview(logic.file, logic.setFileDataURL);
        photoLivreRef.current = logic.fileDataURL ;
    }, [logic.file]);
    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingLivreStateNormBtn}
            title={<FormattedMessage id={messages.biblio_edit_book} />}
            open={logic.openLivreEditDialogForm}
            setOpen={logic.setOpenLivreEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
                <Box  sx={{ width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <div className={styleClasses.dialogAvatar}>
                                <img
                                    alt={logic.livreSel.nom}
                                    src={_.isNull(file) ? `${BASEURL_RESOURCES}/${logic.livreSel.photo}` : file}
                                    style={{width:90, height:120}} />
                                <div className={styleClasses.iconButtonLabel}>
                                    <label htmlFor="file-upload">
                                        <div className={styleClasses.iconButton} style={{textAlign:"center"}}>
                                            <AddAPhotoIcon />
                                        </div>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept="image/*"
                                            hidden={true}
                                            onChange={(a:any) => handleFileChange(a)}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={6} xs={6}>
                            <TextField
                                select
                                label={<FormattedMessage id={messages.biblio_catlivre} />}
                                sx={{ width: { xs: '100% !important'}, }}
                                color={"secondary"}
                                value={logic.livreSel.livreCategorieId}
                                onChange={handleOnCategorieChange}
                                required
                                inputProps={{
                                    ...formRef.current.register("livreCategorieId", {
                                        required: "Required field",
                                        min:1,
                                    })
                                }}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                                {logic.livreCategorieRef.current?.map((opt:any) => (
                                    <MenuItem key={opt.id} value={opt.id} sx={{fontWeight:400}}>
                                        {opt.nom}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div className={styleClasses.errorHelper}>
                                {errors.livreCategorieId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.table_h_langue} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.langue}
                                    inputProps={{
                                        ...formRef.current.register("langue")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.langue && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>

                        <Grid item md={6} xs={6}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.biblio_livre} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.nom}
                                    inputProps={{
                                        ...formRef.current.register("nom")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.table_h_auteur} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.auteur}
                                    inputProps={{
                                        ...formRef.current.register("auteur")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.auteur && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <div className={styleClasses.inputField}>
                                <InputField
                                    label={<FormattedMessage id={messages.donateur} />}
                                    size="small"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.donateurs}
                                    inputProps={{
                                        ...formRef.current.register("donateurs")
                                    }}
                                />
                            </div>
                        </Grid>


                        <Grid item md={4} xs={4}>
                            <div className={styleClasses.inputField}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_quantite} />}
                                    type="number"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.quantite}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        ...formRef.current.register("quantite")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.quantite && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        {/*<Grid item md={4} xs={4}>
                            <div className={styleClasses.inputField}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_prix} />}
                                    type="number"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.prix}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        ...formRef.current.register("prix")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.prix && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>*/}
                        <Grid item md={4} xs={4}>
                            <div className={styleClasses.inputField}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_empruntmaxjour} />}
                                    type="number"
                                    color={"secondary"}
                                    defaultValue={logic.livreSel.empruntMaxJour}
                                    InputProps={{
                                        min: 1,
                                        step: 1,
                                        ...formRef.current.register("empruntMaxJour")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.empruntMaxJour && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <div className={styleClasses.inputField}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_agemin} />}
                                    type="number"
                                    color={"secondary"}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        ...formRef.current.register("agemin")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.agemin && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>
                        {/*<Grid item md={4} xs={4}>
                            <div className={styleClasses.inputField}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_agemax} />}
                                    type="number"
                                    color={"secondary"}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        ...formRef.current.register("agemax")
                                    }}
                                />
                            </div>
                            <div className={styleClasses.errorHelper}>
                                {errors.agemin && <span><FormattedMessage id={messages.required_field_child}/></span>}
                            </div>
                        </Grid>*/}



                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputField
                                    label={`${intl.formatMessage({id:messages.description})} (550 characters max)`}
                                    autoFocus
                                    color={"secondary"}
                                    InputProps={{
                                        rows: 4,
                                        multiline: true,
                                        inputComponent: 'textarea',
                                    }}
                                    inputProps={{
                                        maxLength: 550,
                                        ...formRef.current.register("description")
                                    }}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                </Box>
        </DialogFormKiller>
    );
}

export default LivreEditForm ;