const lightPalette:any = {
    blueTheme: {
        palette: {
            primary: {
                light: '#e8eaf6',
                main: '#3f51b5',
                dark: '#283593',
                contrastText: '#fff',
            },
            secondary: {
                light: '#79f77d',
                main: '#43a047',
                dark: '#215623',
                contrastText: '#fff',
            },
            secondary2: {
                light: '#8c9eff',
                main: '#304ffe',
                dark: '#1a237e',
                contrastText: '#fff',
            },
        },
    },
    redTheme: {
        palette: {
            primary: {
                light: '#fce4ec',
                main: '#e91e63',
                dark: '#ad1457',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff80ab',
                main: '#ff4081',
                dark: '#880e4f',
                contrastText: '#fff',
            },
        },
    },
    imsTheme: {
        palette: {
            primary: {
                light: '#eaa5c3',
                //light:'#FCE4EC',
                main: '#d46194',
                dark: '#880E4F',
                contrastText: '#fff',
            },
            secondary: {
                light: '#a0bbd7',
                main: '#5999D7',
                dark: '#1f71c1',
                contrastText: '#fff',
            },
        },
    },
    imsThemeBlue: {
        palette: {
            primary: {
                light: '#bad9f5',
                //light:'#FCE4EC',
                main: '#5999D7',
                dark: '#3a72a8',
                contrastText: '#fff',
            },
            secondary: {
                light: '#eaa5c3',
                main: '#d46194',
                dark: '#880E4F',
                contrastText: '#fff',
            },
        },
    },

}

export default lightPalette;
