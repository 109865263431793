import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {Genre} from "./Genre";
import moment from "moment";
import {isPositiveValue} from "../../helpers/helpers";
import {BASEURL_RESOURCES} from "../../config/AppUrl";

export const SONDAGERESPONSE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:SondageResponse) => {
        const itemInit = _.cloneDeep(sondageResponseDataImpl);
        item = {...itemInit, ...item} ;

        item.lueForm         = isPositiveValue(item.lue) ? moment(item.lue) : moment();
        item.envoyerForm     = isPositiveValue(item.envoyer) ? moment(item.envoyer) : moment();
        // @ts-ignore
        item.lireEnvoyerForm = item.envoyerForm.format('LT');
        item.parentPhotoForm = `${BASEURL_RESOURCES}/${item.parentPhoto}`;
        // @ts-ignore
        return item ;
    },
    convertToBackEnd: (logic:any, item:SondageResponse) => item,
};

export  const sondageResponseDataImpl: SondageResponse = {
    id: 0,
    parentId: 0,
    sondageId: 0,
    parentUserId: 0,
    lue: null,
    envoyer: null,
    reponse: '',
    parentNom: '',
    parentSexe: Genre.FEMME,
    parentPhone: '',
    parentPhoto:'',
    parentPhotoForm:'',
    firebaseToken: '',
    firebaseResponseId: '',
    lueForm: moment(),
    envoyerForm: moment(),
    lireEnvoyerForm: '',
    common: _.cloneDeep(commonDataImp)
}

export const sondageResponseSchema = yup.object().shape({
    id: yup.number().nonNullable(),
    parentId: yup.number(),
    sondageId: yup.number(),
    parentUserId: yup.number(),
    lue: yup.number().nullable(),
    envoyer: yup.number().nullable(),
    reponse: yup.string(),
    parentNom: yup.string(),
    parentSexe: yup.mixed<Genre>().oneOf(Object.values(Genre)).default(Genre.FEMME),
    parentPhone: yup.string(),
    parentPhoto: yup.string(),
    firebaseToken: yup.string(),
    firebaseResponseId: yup.string(),
    parentPhotoForm: yup.string(),
    lueForm: yup.object(),
    envoyerForm: yup.object(),
    lireEnvoyerForm: yup.string(),
    common: yup.mixed<Common>()
});

export type SondageResponse = yup.InferType<typeof sondageResponseSchema>;