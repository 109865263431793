import {ChildStuff} from "../../../../constants/type/ChildStuff";

const childStuffStateUpdate:any = {
    childStuffList: (logicRacine:any, val:ChildStuff[]) => logicRacine.setChildStuffList(val),
    childEdit: (logicRacine:any, val:ChildStuff) => logicRacine.setChildEdit(val),
    tableDataLoading: (logicRacine:any, val:boolean) => logicRacine.setTableDataLoading(val),
    openDialog: (logicRacine:any, val:boolean) => logicRacine.setOpenDialog(val),
    savingStateBtn: (logicRacine:any, val:boolean) => logicRacine.setSavingStateBtn(val),
    tableKeyValue: (logicRacine:any, val:number) => logicRacine.setTableKeyValue(val),
}

export default childStuffStateUpdate ;