import React from "react";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../constants/type/ClasseRoom";
import {request, uploadFileRequest} from "../../../helpers/axios_helpers";
import _ from "lodash";
import {Badge, Collapse, ListItemButton} from "@mui/material";
import {alpha} from "@mui/material/styles";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import messages from "../../messages/general/messages";
import ListItemIcon from "@mui/material/ListItemIcon";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import GppBadIcon from "@mui/icons-material/GppBad";
import {Sondage, SONDAGE_CONSTANT, sondageDataImpl} from "../../../constants/type/Sondage";
import sondageStateUpdate from "./SondageStateUpdate";
import purify from "dompurify";
import {FormattedMessage} from "react-intl";
import {SondageResponse, SONDAGERESPONSE_CONSTANT} from "../../../constants/type/SondageResponse";
import {isPositiveValue} from "../../../helpers/helpers";
import {pageFormImpl, setPageForm} from "../../../features/page/pageSlice";
import {mixLoad} from "../../../helpers/loader_cache_helpers";
import {getErrorMessage} from "../../../helpers/errorManager";


const sondageBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        const requestbody = ['classes'];
        const convertToFrontEnd = (data:any) => {
            const itemscl: ClasseRoom[] = data.classes.content ?? [];
            logic.classeRoomRef.current = _.sortBy(_.map(itemscl, CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
        };
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });
            sondageBusinessLogic.loadSondages(logic).then((currentSondage:Sondage) => {

                sondageBusinessLogic.handleSondageClick(logic, currentSondage);
                if(currentSondage.terminer){
                    sondageStateUpdate.expandOpenedSondage(logic, false);
                    sondageStateUpdate.expandClosedSondage(logic, true);
                }else {
                    sondageStateUpdate.expandClosedSondage(logic, false);
                    sondageStateUpdate.expandOpenedSondage(logic, true);
                }
            });
        }).catch(error => {
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    },
    hideDetail: (logic:any) => {
        sondageStateUpdate.setShowMobileDetail(logic, false);
    },
    saveEdit: async (logic:any, messages:any, toSend:any, id:number|null):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${id}`;
            }
            uploadFileRequest(method, `/extra/sondage${params}`, toSend).then((response) => {
                // @ts-ignore
                const dataSaved : Sondage   =  SONDAGE_CONSTANT.convertToFrontEnd(logic, response.data);
                // @ts-ignore
                sondageStateUpdate.dataSel(logic, dataSaved) ;
                const datas = _.cloneDeep(logic.dataList);
                const index = _.findIndex(datas, {id:dataSaved.id});
                if(index >= 0){
                    datas[index] = dataSaved;
                } else {
                    datas.unshift(dataSaved);
                }
                const orderedSondages  = _.reverse(_.sortBy(datas, ['ladate']));
                sondageStateUpdate.dataList(logic, orderedSondages) ;
                sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                sondageStateUpdate.showTableContent(logic, true);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    handleComposeNewSondage: (logic:any) => {
        sondageStateUpdate.dataSel(logic, _.cloneDeep(sondageDataImpl));
        sondageStateUpdate.openEditDialogForm(logic, true);
    },
    loadSondages: async (logic:any) => {
        sondageStateUpdate.showTableContent(logic, false);
        return new Promise<Sondage>(resolve =>
            request("GET",  `/extra/sondage`, {}).then((response) => {
                const items : Sondage[]   = response.data._embedded?.sondageDTOModelList ?? [];
                if(_.isEmpty(items)){
                    const currentSondage = _.cloneDeep(sondageDataImpl);
                    sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                    sondageStateUpdate.showTableContent(logic, true);
                    resolve(currentSondage);
                    return ;
                }
                // @ts-ignore
                const orderedSondages  = _.reverse(_.sortBy(_.map(items,  item => SONDAGE_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']));
                const openedSondages  = _.filter(orderedSondages, {terminer:false});
                let currentSondage        = _.isEmpty(openedSondages) ? orderedSondages[0] : openedSondages[0];

                if(_.isObject(logic.pageForm)){
                    // @ts-ignore
                    if(logic.pageForm.cle === 'sondage' && isPositiveValue(logic.pageForm.id) ){
                        const dataId = logic.pageForm.id ;
                        logic.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));

                        for(const sondage of orderedSondages){
                            if(sondage.id !== dataId){
                                continue ;
                            }
                            currentSondage = _.cloneDeep(sondage);
                            break ;
                        }
                    }
                }
                sondageStateUpdate.dataList(logic, orderedSondages) ;
                sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                sondageStateUpdate.showTableContent(logic, true);

                resolve(currentSondage);
            }).catch((error) => {
                console.error(error);
                resolve(_.cloneDeep(sondageDataImpl));
                sondageStateUpdate.showTableContent(logic, true);
            })
        );
    },
    scrollToBase: (logic:any, temps:number) => {
        _.delay(function() {
            const ctn:any = document.getElementById('roomContainer');
            if(_.isNull(ctn) === false && _.isUndefined(ctn) === false){
                // @ts-ignore
                //ctn.scrollTop = ctn.scrollHeight;
                ctn.scrollTo(0, ctn.scrollHeight);
            }
        }, temps, 'later');
    },
    listerLesReponses: (logic:any, data:SondageResponse[]):any => {
        sondageBusinessLogic.scrollToBase(logic, 350);
        return logic.reponseList.map((data:SondageResponse) => sondageBusinessLogic.messageToAppend(logic, data)) ;
    },
    messageToAppend: (logic:any, data:SondageResponse) => {
        let renderHTML:any = <span dangerouslySetInnerHTML={{ __html: purify.sanitize(data.reponse ?? '') }} />;
        // @ts-ignore
        const isTo = data.id % 2 === 0 ;
        const classeStyle = isTo ? logic.chatStyles.to : logic.chatStyles.from;

        return <li className={classeStyle} key={data.id}>
            <time dateTime={data.lireEnvoyerForm}>{data.lireEnvoyerForm}</time>
            <span className="username">{data.parentNom}</span>
            <Avatar
                alt={data.parentNom}
                src={data.parentPhotoForm}
                className={logic.chatStyles.avatar} />
            <div className={logic.chatStyles.talk}>
                <p>
                    {renderHTML}
                </p>
            </div>
        </li>;
    },
    loadSondageReponses: async (logic:any, id:number) => {
        sondageStateUpdate.showTableContent(logic, false);
        return new Promise<boolean>(resolve =>{
            if(_.isNumber(id) === false){
                sondageStateUpdate.reponseList(logic, []);
                sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                sondageStateUpdate.showTableContent(logic, true);
                resolve(true);
                return ;
            }
            request("GET",  `/extra/sondage/reponses/${id}`, {}).then((response) => {
                const items : SondageResponse[]   = response.data ?? [];
                // @ts-ignore
                const itemsfrontend = _.reverse(_.sortBy(_.map(items,  item => SONDAGERESPONSE_CONSTANT.convertToFrontEnd(logic, item)), ['envoyer']));
                sondageStateUpdate.reponseList(logic, itemsfrontend);
                sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                sondageStateUpdate.showTableContent(logic, true);
                resolve(true);
            }).catch((error) => {
                console.error(error);
                resolve(false);
                sondageStateUpdate.showTableContent(logic, true);
            })
        });
    },
    listerLesSondages: (logic:any, sondages:Sondage[]):any => {
        const openedSpaces = sondages.filter((data:Sondage) => data.terminer === false).map((data:Sondage) => {
            //const index = dataContact.indexOf(data);
            const index = data.id;

            return <ListItemButton
                key={`listitembutton_${index}`}
                className={index === logic.dataSel.id ? logic.spaceListStyles.selected : ''}
                sx={{ backgroundColor: (index === logic.dataSel.id ? alpha(logic.theme.palette.background.paper, 0.8) : '' ) }}
                onClick={() => sondageBusinessLogic.handleSondageClick(logic,data)}
            >
                <ListItemAvatar key={`listitemavatar_${index}`}>
                    <Badge key={`badge_${index}`}  color="secondary" badgeContent={data.totalReponses} max={999} sx={{ml:3}} />
                </ListItemAvatar>
                <ListItemText
                    key={`listitemtext_${index}`}
                    className={logic.spaceListStyles.textNotSelected}
                    primary={data.nom}
                    secondary={data.liredateForm}
                    sx={{ml:0,pl:0}}/>
            </ListItemButton>;
        });

        const closedSpaces = logic.dataList.filter((data:Sondage) => data.terminer === true).map((data:Sondage) => {
            //const index = dataContact.indexOf(data);
            const index = data.id;


            return <ListItemButton
                key={`listitembutton_${index}`}
                className={index === logic.dataSel.id ? logic.spaceListStyles.selected : ''}
                sx={{ backgroundColor: (index === logic.dataSel.id ? alpha(logic.theme.palette.background.paper, 0.8) : '' ) }}
                onClick={() => sondageBusinessLogic.handleSondageClick(logic,data)}

            >
                <ListItemAvatar key={`listitemavatar_${index}`}>
                    <Badge key={`badge_${index}`}  color="secondary" badgeContent={data.totalReponses} max={999} />
                </ListItemAvatar>
                <ListItemText
                    key={`listitemtext_${index}`}
                    className={logic.spaceListStyles.textNotSelected}
                    primary={data.nom}
                    secondary={data.liredateForm}  />
            </ListItemButton>;
        });

        const openedSpaceGroup = <>
            <ListItemButton key="openeditembtn" onClick={() => sondageBusinessLogic.handleExpanCollapseOpenedSpace(logic)}>
                <ListItemIcon>
                    <AdminPanelSettingsIcon color="success" />
                </ListItemIcon>
                <ListItemText  key="openedlistitemtext" 
                
                primary={<FormattedMessage id={messages.open_survey_list} />}
                />
                {logic.expandOpenedSondage ? <ExpandLess /> : <ExpandMore  />}
            </ListItemButton>
            <Collapse  in={logic.expandOpenedSondage} key="openedcollapse" timeout="auto" unmountOnExit>{openedSpaces}</Collapse>
        </> ;
        const closedSpaceGroup = <>
            <ListItemButton key="closeditembtn"
                            onClick={() => sondageBusinessLogic.handleExpanCollapseClosedSpace(logic)}>
                <ListItemIcon>
                    <GppBadIcon color="disabled"/>
                </ListItemIcon>
                <ListItemText key="closedlistitemtext" 
                primary={<FormattedMessage id={messages.close_survey_list} />}
                />
                {logic.expandClosedSondage ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={logic.expandClosedSondage} key="closedcollapse" timeout="auto"
                      unmountOnExit>{closedSpaces}</Collapse>
        </>;

        const spaces = [openedSpaceGroup,closedSpaceGroup];
        sondageStateUpdate.listeDesSondages(logic, spaces);
    },
    handleSondageClick: (logic:any, data:Sondage) => {
        sondageStateUpdate.dataSel(logic, data);
        sondageBusinessLogic.loadSondageReponses(logic, data.id);
    },
    handleExpanCollapseOpenedSpace: (logic:any) => {
        const etat = !logic.expandOpenedSondage ;
        sondageStateUpdate.expandOpenedSondage(logic, etat);
        if(etat){sondageStateUpdate.expandClosedSondage(logic, false);}
    },
    handleExpanCollapseClosedSpace: (logic:any) => {
        const etat = !logic.expandClosedSondage ;
        sondageStateUpdate.expandClosedSondage(logic, etat);
        if(etat){sondageStateUpdate.expandOpenedSondage(logic, false);}
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/sondage/bouquet`, ids).then((response) => {
                // @ts-ignore
                let  datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.reverse(_.sortBy(datas, ['ladate']));

                sondageStateUpdate.dataList(logic, itemsfrontend) ;
                sondageStateUpdate.tableKeyValue(logic, new Date().getTime());
                sondageStateUpdate.showTableContent(logic, true);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                console.error(error);
                // Error message
                error.response?.data?.codeSondageResponse === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:Sondage[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:Sondage) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });
            sondageBusinessLogic.delete(logic, messages, ids);
        });
    }
}
export default sondageBusinessLogic ;