import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {setAuthToken} from "../../helpers/axios_helpers";
import {loginUser, setAppLang, setSchoolYear} from "../../features/user/userSlice";
import {getMenus} from "../../features/menu/menuSlice";
import LoginForm from "./LoginForm";
import {TConnectionFormValues} from "../../constants/type/connection/connectionType";
import AuthenticationService from "../../service/AuthenticationService";
import PeriodService from "../../service/PeriodService";
import _ from "lodash";
import {pageFormImpl, setPageForm} from "../../features/page/pageSlice";
import {clearDataCache} from "../../features/cache/remoteDBCacheSlice";

function Login(props: any) {
    const [sending, setSending] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const form = useForm<TConnectionFormValues>({
        defaultValues: {
            username: '',
            password: '',
        }
    });
    const {register, handleSubmit, formState} = form;
    const {errors} = formState;
    const appSystemLang = window.localStorage.getItem("appSystemLang");

    const periodService = PeriodService();
    const authenticationService = AuthenticationService();

    const onSubmit = async (data: TConnectionFormValues) => {
        try {
            setSending(true);
            const response = await authenticationService.authentificateUser(data);
            dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
            setAuthToken(response.data.token);
            dispatch(loginUser(response.data));
            dispatch(getMenus(response.data));
            window.localStorage.setItem("countLoadingPage", '1');

            if(appSystemLang !== undefined && appSystemLang !== null && appSystemLang !== ''){
                dispatch(setAppLang(appSystemLang));
            }
            else {
                window.localStorage.setItem("appSystemLang", 'fr-fr');
                dispatch(setAppLang('fr-fr'));
            }

            const period:any = await periodService.getSchoolPeriod();
            let schoolResult:any = period._embedded !== undefined ? period._embedded?.anneeDTOModelList : [];
            const yearSchool = schoolResult.length > 0 ? schoolResult[0] : null;
            dispatch(setSchoolYear(yearSchool));
            for(const menuOne of response.data.user.userDetails.menus){
               if(!_.isNull(menuOne.lien) && !_.isUndefined(menuOne.lien) && menuOne.lien.includes("/")){
                   navigate(menuOne.lien);
                   break ;
               }
            }

            setSending(false);
        }
        catch (error:any){
            if(error?.code === 'ERR_NETWORK'){
                setErrorMessage('Network Error');
            }
            else {
                setErrorMessage(error?.response.data.message);
            }
            setSending(false);
        }
    };

    useEffect(() => {
        dispatch(clearDataCache());
        const count:any = window.localStorage.getItem('countLoadingPage');
        const timer = setTimeout(() => {
            authenticationService.refreshOnLogout(count);
            if(appSystemLang !== undefined && appSystemLang !== null && appSystemLang !== ''){
                dispatch(setAppLang(appSystemLang));
            }
            else {
                window.localStorage.setItem("appSystemLang", 'fr-fr');
                dispatch(setAppLang('fr-fr'));
            }
        }, 50);
        return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        if(authenticationService.checkUserToken()){
            for(const menuOne of userRef.current?.userDetails?.menus){
                if(!_.isNull(menuOne.lien) && !_.isUndefined(menuOne.lien) && menuOne.lien.includes("/")){
                    navigate(menuOne.lien);
                    break ;
                }
            }
        }
    }, []);

    return (
        <LoginForm
            errorMessage={errorMessage}
            sending={sending}
            register={register}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
        />
    )
}

export default Login;
