import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {EmpruntContext} from "./Emprunt";
import livreEmpruntStateUpdate from "./businesslogic/LivreEmpruntStateUpdate";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import livreEmpruntBusinessLogic from "./businesslogic/LivreEmpruntBusinessLogic";
import messages from "../../messages/general/messages";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    LivreEmprunt,
    LIVREEMPRUNT_CONSTANT,
    livreEmpruntDataImpl,
    livreEmpruntSchema
} from "../../../constants/type/LivreEmprunt";
import {isPositiveValue} from "../../../helpers/helpers";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const LivreEmpruntEditForm = (props:any) => {
    const logic: any = useContext(EmpruntContext);
    const styleClasses = logic.styleClasses ;


    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreEmpruntDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreEmpruntSchema),
    }));
    const {errors} = formRef.current.formState;

    const updateEmpruntDate = (data:LivreEmprunt, ladate: moment.Moment):LivreEmprunt => {
        data.dateEmpruntForm = ladate;
        data.dateEmprunt = data.dateEmpruntForm.valueOf();
        data.lireDateEmpruntForm =  data.dateEmpruntForm?.clone().format('L');

        data.deadlineRetourForm =  data.dateEmpruntForm.clone().add(logic.livreEmpruntSel.livre.empruntMaxJour, 'days');
        data.deadlineRetour = data.deadlineRetourForm.valueOf();
        data.lireDeadlineRetourForm = data.deadlineRetourForm?.clone().format('L');
        return data ;
    }
    const handleOnEmpruntCheck = (event: any) => {
        let data:LivreEmprunt = _.cloneDeep(logic.livreEmpruntSel);
        data.confirmEmpruntForm = !data.confirmEmpruntForm ;
        data.dateEmprunt = null;
        data.dateEmpruntForm = null;
        data.lireDateEmpruntForm = ``;

        data.deadlineRetour = null;
        data.deadlineRetourForm = null;
        data.lireDeadlineRetourForm = ``;
        if(data.confirmEmpruntForm){
            data = updateEmpruntDate(data, moment());
        }
        livreEmpruntStateUpdate.livreEmpruntSel(logic, data);
    };

    const updateRetourDate = (data:LivreEmprunt, ladate: moment.Moment):LivreEmprunt => {
        data.dateRetourForm = ladate;
        data.dateRetour = data.dateRetourForm.valueOf();
        data.lireDateRetourForm =  data.dateRetourForm?.clone().format('L');
        return data ;
    }
    const handleOnRetourCheck = (event: any) => {
        let data = _.cloneDeep(logic.livreEmpruntSel);
        data.confirmRetourForm = !data.confirmRetourForm ;
        data.dateRetour = null;
        data.dateRetourForm = null;
        data.lireDateRetourForm = ``;
        if(data.confirmRetourForm){
            data = updateRetourDate(data, moment());
        }
        livreEmpruntStateUpdate.livreEmpruntSel(logic, data);
    };

    const handleDateRetourChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:LivreEmprunt = _.cloneDeep(logic.livreEmpruntSel);
        data = updateRetourDate(data, newday);

        livreEmpruntStateUpdate.livreEmpruntSel(logic, data);
    };
    const handleDateEmpruntChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:LivreEmprunt = _.cloneDeep(logic.livreEmpruntSel);
        data = updateEmpruntDate(data, newday);

        livreEmpruntStateUpdate.livreEmpruntSel(logic, data);
    };
    const disableWeekends = (date:moment.Moment) => {
        return date.day() === 0 || date.day() === 6;
    }

    const onSubmit = async () => {
        livreEmpruntStateUpdate.processingLivreEmpruntStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: LivreEmprunt = _.cloneDeep(logic.livreEmpruntSel);

        // @ts-ignore
        const toSend = LIVREEMPRUNT_CONSTANT.convertToBackEnd(logic, dataToSend);
        livreEmpruntBusinessLogic.saveLivreEmpruntEdit(
            logic,
            messages,
            toSend).then((response:any) => {
            livreEmpruntStateUpdate.openLivreEmpruntEditDialogForm(logic, false);
            livreEmpruntStateUpdate.processingLivreEmpruntStateNormBtn(logic, false);
        }).catch((e:any) => livreEmpruntStateUpdate.processingLivreEmpruntStateNormBtn(logic, false));
    };

    useEffect(() => {
        if(logic.openLivreEmpruntEditDialogForm === false){
            logic.livreEmpruntSel = _.cloneDeep(livreEmpruntDataImpl);
            formRef.current.reset(logic.livreEmpruntSel);
            return ;
        }

        formRef.current.reset(_.cloneDeep(logic.livreEmpruntSel));
        
        return () => {
            formRef.current.reset(_.cloneDeep(livreEmpruntDataImpl));
        };
    }, [logic.openLivreEmpruntEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingLivreEmpruntStateNormBtn}
            title={<FormattedMessage id={messages.books_loan_requested} />}
            open={logic.openLivreEmpruntEditDialogForm}
            setOpen={logic.setOpenLivreEmpruntEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: '100%' }}>
                    <Grid container spacing={4}>

                        <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_livre} />}
                                    value={logic.livreEmpruntSel.livre.nom}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_auteur} />}
                                    value={logic.livreEmpruntSel.livre.auteur}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    label={<FormattedMessage id={messages.emprunteur} />}
                                    value={logic.livreEmpruntSel.emprunteurForm}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {
                                isPositiveValue(logic.livreEmpruntSel.enfantId) &&
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField
                                        label={<FormattedMessage id={messages.table_h_enfant} />}
                                        value={logic.livreEmpruntSel.nomenfantForm}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                    />
                                </FormControl>
                            }
                            {
                                isPositiveValue(logic.livreEmpruntSel.employeeId) &&
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField
                                        label={<FormattedMessage id={messages.header_quantite} />}
                                        value={logic.livreEmpruntSel.quantite}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                    />
                                </FormControl>
                            }
                        </Grid>

                        <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_emprunt_datedemande} />}
                                    value={logic.livreEmpruntSel.lireDateDemandeForm}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <div className={styleClasses.inputField} style={{marginLeft:8}}>
                                <FormControlLabel
                                    control={<Checkbox checked={logic.livreEmpruntSel.confirmEmpruntForm} onChange={handleOnEmpruntCheck} />}
                                    label={<FormattedMessage id={messages.book_loan_valid} />}
                                />
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.day} />}
                                        value={logic.livreEmpruntSel.dateEmpruntForm}
                                        //@ts-ignore
                                        onChange={handleDateEmpruntChange}
                                        shouldDisableDate={disableWeekends}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        minDate={logic.livreEmpruntSel.dateDemandeForm?.clone().startOf('day')}
                                        maxDate={moment()?.clone().endOf('day')}
                                        disabled={!logic.livreEmpruntSel.confirmEmpruntForm}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>


                        <Grid item md={6} xs={6}>
                            <FormControl fullWidth sx={{ m: 1 }} >
                                <TextField
                                    label={<FormattedMessage id={messages.table_h_emprunt_deadline} />}
                                    value={logic.livreEmpruntSel.lireDeadlineRetourForm}
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {
                                isPositiveValue(logic.livreEmpruntSel.dateEmprunt) &&
                                <div className={styleClasses.inputField} style={{marginLeft: 8}}>
                                    <FormControlLabel
                                        control={<Checkbox checked={logic.livreEmpruntSel.confirmRetourForm}
                                                           onChange={handleOnRetourCheck}/>}
                                        label={<FormattedMessage id={messages.book_loan_retour_valid}/>}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                        <MobileDateTimePicker
                                            label={<FormattedMessage id={messages.day}/>}
                                            value={logic.livreEmpruntSel.dateRetourForm}
                                            //@ts-ignore
                                            onChange={handleDateRetourChange}
                                            shouldDisableDate={disableWeekends}
                                            views={['year', 'month', 'day']}
                                            slotProps={{textField: {color: 'secondary'}}}
                                            minDate={logic.livreEmpruntSel.dateEmpruntForm?.clone().startOf('day')}
                                            maxDate={moment()?.clone().endOf('day')}
                                            disabled={!logic.livreEmpruntSel.confirmRetourForm}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '100%',
                                                    fontWeight: 'normal',
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            }

                        </Grid>
                        <Grid item md={12} xs={12} sx={{alignContent:'center', textAlign:'center', alignItems:'center'}}>
                            <Typography
                                variant="body1"
                                color={logic.livreEmpruntSel.livre.quantiteDispo > 0 ? 'primary' : 'error'}
                                sx={{fontStyle: 'italic'}} gutterBottom>
                                {logic.livreEmpruntSel.livre.quantiteDispo} <FormattedMessage id={messages.biblio_quantitedispo} /> <FormattedMessage id={messages.en_stock} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
        </DialogFormKiller>
    );
}

export default LivreEmpruntEditForm ;