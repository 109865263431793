import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import documentStyles from "./jss/document-jss";
import React from "react";
import {Box, CircularProgress, Grid} from "@mui/material";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {documentScolaireDataImpl, documentScolaireSchema} from "../../constants/type/DocumentScolaire";
import DocumentFileToolBar from "./DocumentFileToolBar";
import DocumentFolder from "./DocumentFolder";
import DocumentFile from "./DocumentFile";
import FolderEditForm from "./FolderEditForm";
import DocumentFolderToolBar from "./DocumentFolderToolBar";
import useDocumentVariables from "./businesslogic/SchoolDocumentVariables";
import FolderDeleteForm from "./FolderDeleteForm";
import FileDeleteForm from "./FileDeleteForm";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";

const useBusinessLogic = (props: any): any => {
    const styleClasses = documentStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(documentScolaireDataImpl),
        // @ts-ignore
        resolver: yupResolver(documentScolaireSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useDocumentVariables({styleClasses, ...props})
    }
};
export const SchoolDocumentContext = React.createContext({});
function SchoolDocument(props:any){
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();

    React.useEffect(() => {
        schoolDocumentBusinessLogic.initPostConstruct(logic);
    }, []);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={logic.styleClasses.root}>
                <div className={logic.styleClasses.toolbar}>
                    <Box>
                        <SchoolDocumentContext.Provider value={logic}>
                            <DocumentFolderToolBar />
                        </SchoolDocumentContext.Provider>
                        <SchoolDocumentContext.Provider value={logic}>
                            <DocumentFileToolBar />
                        </SchoolDocumentContext.Provider>
                    </Box>
                </div>
                <Box>
                    <Grid container rowSpacing={4} sx={{pr:4,pl:4}}  spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {
                            !logic.showContent &&
                            <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                                <CircularProgress color="primary" />
                            </Box>

                        }
                        {
                            logic.showContent &&
                            <>
                                <SchoolDocumentContext.Provider value={logic}>
                                    <DocumentFolder />
                                </SchoolDocumentContext.Provider>
                                <SchoolDocumentContext.Provider value={logic}>
                                    <DocumentFile />
                                </SchoolDocumentContext.Provider>
                            </>
                        }

                    </Grid>
                </Box>

                <SchoolDocumentContext.Provider value={logic}>
                    <FolderEditForm />
                </SchoolDocumentContext.Provider>
                <SchoolDocumentContext.Provider value={logic}>
                    <FolderDeleteForm />
                </SchoolDocumentContext.Provider>
                <SchoolDocumentContext.Provider value={logic}>
                    <FileDeleteForm />
                </SchoolDocumentContext.Provider>
            </div>
        </PapperBlock>
    );
}
export default withSnackbar(SchoolDocument);