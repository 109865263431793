export const scope = 'boilerplate.components.Errors';

const messages:any = {
    BAD_REQUEST: `${scope}.BAD_REQUEST`,
    CONSTRAINT_VIOLATION: `${scope}.CONSTRAINT_VIOLATION`,
    DATA_EXIST: `${scope}.DATA_EXIST`,
    DEFAULT_ERROR: `${scope}.DEFAULT_ERROR`,
    DTO_SENT_FAILED_VALIDATION: `${scope}.DTO_SENT_FAILED_VALIDATION`,
    ERR_CONFLICT: `${scope}.ERR_CONFLICT`,
    NOT_FOUND: `${scope}.NOT_FOUND`,
    PERSON_SAME_EMAIL_ALREADY_EXISTED: `${scope}.PERSON_SAME_EMAIL_ALREADY_EXISTED`,
    RDV_NOT_FOUND: `${scope}.RDV_NOT_FOUND`,
    SERVICE_AVAILABLE_ONLY_FOR_PRESET_APPOINTMENT: `${scope}.SERVICE_AVAILABLE_ONLY_FOR_PRESET_APPOINTMENT`,
    UNIQUE_CONSTRAINT: `${scope}.UNIQUE_CONSTRAINT`,
}
export default messages;