import {deleteRequest, getRequest, postRequest} from "../helpers/axios_helpers";
import moment from "moment";
import MenuYearService from "./MenuYearService";
import _ from "lodash";
import {CONSTANT} from "../constants";
import {getFirstLastDateWeekByDate} from "../helpers/helpers";

const weekService = WeekService();
const menuYearService = MenuYearService();

function WeekService() {
    return {
        getAllWeekData: async () => {
            const weekListRequest:any = await getRequest(`/corebase/semaines`);
            return weekListRequest._embedded !== undefined ? weekListRequest._embedded.semaineDTOModelList : [];
        },
        getWeekNumberTwoDate: (startTime:number, endTime:number) => {
            const startDay = moment(startTime).format("L");
            const endDay = moment(endTime).format("L");
            const startDate:any = new Date(startDay);
            const endDate:any = new Date(endDay);

            const timeDifference = Math.abs(endDate - startDate);
            return  Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 7));
        },
        getLastWeekDisplay: async () => {
            const weekListRequest:any = await getRequest(`/corebase/semaines`);
            let weekList = weekListRequest._embedded !== undefined ? weekListRequest._embedded.semaineDTOModelList : [];
            weekList = weekList.filter((week:any) => week?.statut === true);
            weekList = weekList.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            return weekList.length > 0 ? weekList[weekList.length - 1] : null;
        },
        generateWeek: async (weekNumber:number, lastWeekDisplay:any, schoolPeriodList:any, displayWeekList:any, allCanteenWeek:any) => {
            //REMOVE ALL OLD CANTEEN MENUS, MENU DAY
            let weekWithStatusFalse = allCanteenWeek.filter((weekFalse:any) => weekFalse.statut === false);
            weekWithStatusFalse = weekWithStatusFalse.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            if(weekWithStatusFalse.length > 0){
                const allMenuJour = await menuYearService.getAllMenuJour();
                let menuJourToDeleteList = [];
                let weekFalseIdToDelete:any = [];
                for(let i=0; i<weekWithStatusFalse.length; i++){
                    const weekFalseId = weekWithStatusFalse[i]?.id;
                    weekFalseIdToDelete.push(weekWithStatusFalse[i]?.id);
                    if(allMenuJour.length > 0){
                        const menuJourWeekIdFalseList = allMenuJour.filter((menuJourItem:any) => menuJourItem.semaineId === weekFalseId);
                        menuJourToDeleteList.push(...menuJourWeekIdFalseList);
                    }
                }

                // DELETE ALL MENU DAY WITH WEEK STATUS FALSE
                if(menuJourToDeleteList.length>0){
                    let menuDayIdToDeleteList:any = [];
                    for(let i=0; i<menuJourToDeleteList.length; i++){
                        menuDayIdToDeleteList.push(menuJourToDeleteList[i]?.id);
                    }
                    await menuYearService.deleteMenuJour(menuDayIdToDeleteList);
                }

                // DELETE ALL WEEK WITH STATUS FALSE
                await weekService.deleteWeekBouquet(weekFalseIdToDelete);
            }

            //GENERATE REMAINING WEEKS
            const weekDays = getFirstLastDateWeekByDate(lastWeekDisplay.dateFin);
            let startEndDay: number = weekDays[1].toDate().getTime();
            let weekCanteenBook:any = [];

            for(let i=0; i<weekNumber; i++){
                const dayTime = moment(startEndDay).add(1, 'days').toDate().getTime();
                const weekDays = getFirstLastDateWeekByDate(dayTime);
                const startDayWeek = weekDays[0].toDate().getTime();
                startEndDay = weekDays[1].toDate().getTime();

                const dayStart:any = moment(new Date(startDayWeek)).set({hour:0,minute:0,second:0,millisecond:0});
                const dayEnd:any = moment(new Date(startEndDay)).set({hour:23,minute:59,second:59,millisecond:0}) ;
                const schoolPeriodFind = schoolPeriodList.find((period:any) => period.dateDebut <= dayStart.valueOf() && period.dateFin >= dayStart.valueOf());

                if(schoolPeriodFind !== undefined){
                    const numb = displayWeekList.length +(i+1);
                    const dataToSend = {
                        periodeId: schoolPeriodFind.id,
                        nom: `Semaine ${numb} / Week ${numb}`,
                        dateDebut: dayStart.valueOf(),
                        dateFin: dayEnd.valueOf(),
                        statut: false,
                        common: CONSTANT.common
                    }
                    weekCanteenBook.push(dataToSend);
                }
            }
            const response = await weekService.postWeekBouquet(weekCanteenBook);

            // GET NEW WEEK GENERATED MENU
            let newAllCanteenWeek = await weekService.getAllWeekData();
            newAllCanteenWeek = newAllCanteenWeek.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            let allCanteenWeekWithStatusTrue = newAllCanteenWeek.filter((canteenWeek:any) => canteenWeek?.statut === true);
            allCanteenWeekWithStatusTrue = allCanteenWeekWithStatusTrue.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            let allCanteenWeekWithStatusFalse = newAllCanteenWeek.filter((canteenWeek:any) => canteenWeek?.statut === false);
            allCanteenWeekWithStatusFalse = allCanteenWeekWithStatusFalse.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });

            const newAllMenuJour = await menuYearService.getAllMenuJour();
            //console.log(JSON.stringify(allCanteenWeekWithStatusFalse));
            if(allCanteenWeekWithStatusTrue.length > 0) {
                let menuJourCanteenWithStatusTrue:any = [];
                for(let i=0; i<allCanteenWeekWithStatusTrue.length; i++){
                    const weekTrueId = allCanteenWeekWithStatusTrue[i]?.id;
                    //weekFalseIdToDelete.push(weekWithStatusFalse[i]?.id);
                    if(newAllMenuJour.length > 0){
                        const menuJourWeekIdTrueList = newAllMenuJour.filter((menuJourItem:any) => menuJourItem.semaineId === weekTrueId);
                        menuJourCanteenWithStatusTrue.push(...menuJourWeekIdTrueList);
                    }
                }

                if(menuJourCanteenWithStatusTrue.length > 0){
                    let menuJourListTrue:any = [];
                    const groupedData = _.groupBy(menuJourCanteenWithStatusTrue, 'semaineId');
                    Object.keys(groupedData).forEach(function(key, index) {
                        const data = {
                            weekDayId: key,
                            weekDayList: groupedData[key],
                        }
                        menuJourListTrue.push(data);
                    });

                    if(menuJourListTrue.length>0){
                        let moreIndexStatus = false;
                        let resultOperation = allCanteenWeekWithStatusFalse.length % allCanteenWeekWithStatusTrue.length;
                        //let resultOperation = 34 % allCanteenWeekWithStatusTrue.length;
                        let boucleInsertion:number;
                        if(resultOperation === 0){
                            boucleInsertion = allCanteenWeekWithStatusFalse.length / allCanteenWeekWithStatusTrue.length;
                        }
                        else {
                            boucleInsertion = Math.floor(allCanteenWeekWithStatusFalse.length / allCanteenWeekWithStatusTrue.length);
                            //boucleInsertion = Math.floor(34 / allCanteenWeekWithStatusTrue.length);
                            moreIndexStatus = true;
                        }

                        let menuJourListBouquet:any = [];
                        let index:number = 0;
                        let count:number = 0;
                        let countMenuJourTrue = 0;
                        if(allCanteenWeekWithStatusFalse.length>0){
                            if(count < boucleInsertion){
                                while (count < boucleInsertion){
                                    for(let i=index; i<allCanteenWeekWithStatusFalse.length; i++){
                                        if(countMenuJourTrue === allCanteenWeekWithStatusTrue.length){
                                            index = i;
                                            count++;
                                            countMenuJourTrue = 0;
                                            break;
                                        }
                                        else {
                                            const weekDayList = menuJourListTrue[countMenuJourTrue]?.weekDayList;
                                            if(weekDayList.length>0){
                                                for(let k=0; k<weekDayList.length; k++){
                                                    const menuCanteenDay =  {
                                                        menuCantineId: weekDayList[k]?.menuCantineJours[0]?.menuCantineId,
                                                        common: CONSTANT.common,
                                                    }
                                                    const dataToSend = {
                                                        theDate: allCanteenWeekWithStatusFalse[i]?.dateFin,
                                                        semaineId: allCanteenWeekWithStatusFalse[i]?.id,
                                                        nom: '',
                                                        couleur: 'rgba(150, 145, 120, 0.7)',
                                                        jour: weekDayList[k]?.jour, //day,
                                                        common: CONSTANT.common,
                                                        menuCantineJours: [menuCanteenDay],
                                                    }
                                                    menuJourListBouquet.push(dataToSend);
                                                }
                                            }
                                        }
                                        countMenuJourTrue++;
                                    }
                                }
                            }

                            if(moreIndexStatus){
                                count = 0;
                                countMenuJourTrue = 0;
                                for(let i=index; i<allCanteenWeekWithStatusFalse.length; i++){
                                    if(countMenuJourTrue === allCanteenWeekWithStatusTrue.length){
                                        index = i;
                                        count++;
                                        countMenuJourTrue = 0;
                                        break;
                                    }
                                    else {
                                        const weekDayList = menuJourListTrue[countMenuJourTrue]?.weekDayList;
                                        if(weekDayList.length>0){
                                            for(let k=0; k<weekDayList.length; k++){
                                                const menuCanteenDay:any =  {
                                                    menuCantineId: weekDayList[k]?.menuCantineJours[0]?.menuCantineId, //menu?.id,
                                                    common: CONSTANT.common,
                                                }
                                                const dataToSend = {
                                                    theDate: allCanteenWeekWithStatusFalse[i]?.dateFin,
                                                    semaineId: allCanteenWeekWithStatusFalse[i]?.id,
                                                    nom: '',
                                                    couleur: 'rgba(150, 145, 120, 0.7)',
                                                    jour: weekDayList[k]?.jour, //day,
                                                    common: CONSTANT.common,
                                                    menuCantineJours: [menuCanteenDay],
                                                }
                                                menuJourListBouquet.push(dataToSend);
                                            }
                                        }
                                    }
                                    countMenuJourTrue++;
                                }
                            }

                            await menuYearService.postMenuJourBouquet(menuJourListBouquet);
                        }
                    }
                }
            }
        },
        postWeekBouquet: async (data:any) => {
            return await postRequest('/corebase/semaines/bouquet', data);
        },
        deleteWeekBouquet: async (data:any) => {
            return await deleteRequest('/corebase/semaines/bouquet', data);
        },
    }
}

export default WeekService;
