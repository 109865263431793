import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@mui/styles";
import Header from "./Header";
import {Fade, Typography} from "@mui/material";
import Sidebar from "./Sidebar";
import {IMGS} from "../../constants";
import {useDispatch, useSelector} from "react-redux";

import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../page/messages/general/menuMessages";
import {RoleUser} from "../../constants/type/RoleUser";
import {getPathNameAndSetTitle} from "../../helpers/helpers";
import {setMenuOpen, setPageTitle } from "../../features/page/pageSlice";
import menuMessages from "../../page/messages/general/menuMessages";
import _ from "lodash";

//import messages from "../../page/messages/general/messages";
//import {FormattedMessage} from "react-intl";


const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            display: 'flex',
            '& .MuiTable-root': {
                '& .MuiTableRow-root': {
                    '&:nth-child(even)': {
                        backgroundColor: '#f9f9f9',
                    },
                    '&:hover':{
                        backgroundColor: '#ebebeb',
                    }
                },
            },
        },
        page: {
            zIndex:1,
            background: '#fdfcfe',
            width: '100%',
            //minHeight:'100%',
            minHeight: '96vh',
            padding: theme.spacing(2),
            overflow:'hidden',
        },
        toolbar: theme.mixins.toolbar,
        hideApp: {
            display: 'none',
        },
        circularProgress: {
            position: 'fixed',
            top: 'calc(50% - 45px)',
            left: 'calc(50% - 45px)',
        },
        pageTitle: {
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                alignItems: 'flex-end',
            },
            '& h4': {
                fontWeight: 700,
                fontSize: 24,
                paddingLeft: 10,
                paddingRight: theme.spacing(1),
                textTransform: 'none',
                color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(3),
                },
            },
        },
    }
})

export default function Layout(props:any) {
    const classes = useStyles();
    const { theme, children, locale, setLocale } = props
    const [drawerPaper, setDrawerPaper] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);
    const intl = useIntl();
    let pageTitleLocalize = ``;
    let menuMsgKey = undefined;
    //const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));

    const dispatch = useDispatch();
    const {pageTitle, menuOpen} = useSelector((state:any) => state.page);
    const {menus} = useSelector((state:any) => state.menu);
    //const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    //const [pageTitle, setPageTitle] = useState(userRef.current.role === RoleUser.ADMIN || userRef.current.role === RoleUser.SUPER_ADMIN ? <FormattedMessage id={messages.dashboard} /> : <FormattedMessage id={messages.appointment} />);
    //const [open, setOpen] = useState(userRef.current.role === RoleUser.ADMIN || userRef.current.role === RoleUser.SUPER_ADMIN ?  'dashboard' : 'appointment');


    menuMsgKey = menuMessages[menuOpen] ;
    // @ts-ignore
    //pageTitleLocalize = _.isObject(menuMsgKey) ? intl.formatMessage({id:menuMsgKey}) : pageTitle ;

    useEffect(() => {
        menuMsgKey = menuMessages[menuOpen] ;
        if(!_.isObject(menuMsgKey)){
            const pathFind = getPathNameAndSetTitle(menus);
            if(pathFind !== null && pathFind !== undefined){
                const keyTitle = messages[pathFind.cle];
                dispatch(setPageTitle(intl.formatMessage({id:keyTitle})));
                dispatch(setMenuOpen((pathFind.parentKey === 'racine' || pathFind.parentKey === null) ? pathFind.cle : pathFind.parentKey));
            }
        }
    }, []);

    return(
        <div className={classes.root}>
            {/* APP BAR */}
            <Header setDrawerPaper={setDrawerPaper}
                    drawerPaper={drawerPaper}
                    mobile={mobile}
                    setMobile={setMobile}
                    theme={theme}
                    locale={locale}
                    setLocale={setLocale}
            />

            {/* SIDE BAR MENU ITEMS */}
            <Sidebar theme={theme}
                     drawerPaper={drawerPaper}
                     setDrawerPaper={setDrawerPaper}
                     mobile={mobile}
                     setMobile={setMobile}
                     setPageLoaded={setPageLoaded}
            />

            <div className={classes.page}>
                <div className={classes.toolbar}></div>

                <section>
                    <div className={classes.pageTitle}>
                        <Typography component="h4" variant="h4">
                            {
                                !_.isNull(menuMsgKey) && !_.isUndefined(menuMsgKey) &&
                                <FormattedMessage id={menuMsgKey} />
                            }
                            {
                                (_.isNull(menuMsgKey) || _.isUndefined(menuMsgKey)) &&
                                pageTitle
                            }
                        </Typography>
                    </div>
                    {!pageLoaded && (
                        <img src={IMGS.spinner} alt="spinner" className={classes.circularProgress} />
                    )}
                    <Fade in={pageLoaded} {...(pageLoaded ? { timeout: 500 } : {})}>
                        <div className={!pageLoaded ? classes.hideApp : ''}>
                            {/*Application content will load here*/}
                            {children}
                        </div>
                    </Fade>
                </section>
            </div>

        </div>
    )
}
