import React from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import livreStyles from "./jss/livre-jss";
import {livreDataImpl, livreSchema} from "../../../constants/type/Livre";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {useDispatch} from "react-redux";
import livreBusinessLogic from "./businesslogic/LivreBusinessLogic";
import LivreList from "./LivreList";
import LivreEditForm from "./LivreEditForm";
import LivreDeleteForm from "./LivreDeleteForm";
import useLivreVariables from "./businesslogic/LivreVariables";

const useBusinessLogic = (props: any): any => {
    const styleClasses = livreStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useLivreVariables({styleClasses, ...props})
    }
};

export const BibliothequeContext = React.createContext({});

function Bibliotheque(props:any){
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();

    React.useEffect(() => {
        moment.locale(logic.activeLang);
        livreBusinessLogic.initPostConstruct(logic);

        return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);

    return (
        <div className={logic.styleClasses.root}>
            <BibliothequeContext.Provider value={logic}>
                <LivreList />
            </BibliothequeContext.Provider>

            <BibliothequeContext.Provider value={logic}>
                <LivreEditForm />
            </BibliothequeContext.Provider>
            <BibliothequeContext.Provider value={logic}>
                <LivreDeleteForm />
            </BibliothequeContext.Provider>

        </div>
    );
}
export default withSnackbar(Bibliotheque);