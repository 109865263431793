import React from 'react'
import LoadingButton from "@mui/lab/LoadingButton";

function LoadingButtonForm(props:any){
    const { color, variant, textButton, icon, onClickFormCrudAction, loadingButton } = props
    const IconCrudButton = icon;
    return (
        <LoadingButton
            loading={loadingButton}
            loadingPosition="start"
            variant={variant}
            onClick={() => onClickFormCrudAction() }
            color={color}
            sx={{textTransform: 'none'}}
            size="small"
            startIcon={<IconCrudButton />}
        >
           { textButton }
        </LoadingButton>
    )
}

export default LoadingButtonForm;
