import React from 'react';
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import FolderIcon from '@mui/icons-material/Folder';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";

function SubFolderSubMenu(props:any) {
    const {classes, selected} = props;

    return (
        <>
            <Button
                className={classNames(classes.button, selected === 'subfolder' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/config/folder/subfoldertype"
                startIcon={<FolderCopyIcon />}
            >
                <FormattedMessage id={messages.sub_folder_type} />
            </Button>

            <Button
                className={classNames(classes.button, selected === 'foldertype' ? classes.active : '') }
                variant={"text"}
                sx={{
                    marginRight: 1,
                    textTransform: 'none',
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 400,
                    fontSize: 15,
                }}
                component={Link} to="/app/config/folder/foldertype"
                startIcon={<FolderIcon />}
            >
                <FormattedMessage id={messages.folder_type} />
            </Button>
        </>
    );
}

export default SubFolderSubMenu;
